import { AppState } from "../types";
import { createSelector } from "@reduxjs/toolkit";
import {
  arrayOfObjToNameList,
  arrayOfObjToYiddishNameList,
  arrayToEntityList,
  gematriya,
} from "../../lib";

const _state = (state: AppState) => state.lists;

const _statusById = createSelector(
  _state,
  (state) => arrayToEntityList(state.statuses).entities,
);

const _neighborhoodById = createSelector(
  _state,
  (state) => arrayToEntityList(state.neighborhoods).entities,
);

const _chaburahTypes = createSelector(
  _state,
  (state) => arrayToEntityList(state.chaburahTypes).entities,
);

const _chaburahs = createSelector(
  _state,
  (state) => arrayToEntityList(state.chaburahs).entities,
);
const _teachers = createSelector(
  _state,
  (state) => arrayToEntityList(state.allTeachers).entities,
);

const _months = createSelector(
  _state,
  (state) => arrayToEntityList(state.months).entities,
);
const _types = createSelector(
  _state,
  (state) => arrayToEntityList(state.types).entities,
);

const _users = createSelector(
  _state,
  (state) => arrayToEntityList(state.users).entities,
);

const _neighborhoodPrincipals = createSelector(
  _state,
  (state) => arrayToEntityList(state.neighborhoodPrincipals).entities,
);

const _semesters = createSelector(
  _state,
  (state) => arrayToEntityList(state.semesters).entities,
);

const _tracks = createSelector(
  _state,
  (state) => arrayToEntityList(state.tracks).entities,
);

const _location = createSelector(
  _state,
  (state) => arrayToEntityList(state.locations).entities,
);

const _subjects = createSelector(
  _state,
  (state) => arrayToEntityList(state.subjects).entities,
);
const _subjectsByName = createSelector(_state, (state) => ({
  ...arrayToEntityList(state.subjects, "name_hebrew").entities,
  ...arrayToEntityList(state.mesechtos, "name_hebrew").entities,
}));

const _mesechtos = createSelector(
  _state,
  (state) => arrayToEntityList(state.mesechtos).entities,
);

const _countries = createSelector(
  _state,
  (state) => arrayToEntityList(state.countries).entities,
);

const _allTeachersNames = createSelector(_state, (state) => [
  ...new Set(arrayOfObjToYiddishNameList(state.allTeachers)),
]);
const _allTeachersYiddish = createSelector(_state, (state) => [
  ...new Set(state.allTeachers.map((t) => t.name_yiddish)),
]);
const _allChaburahs = createSelector(_state, (state) =>
  arrayOfObjToNameList(state.chaburahs),
);
const _allNeighborhoods = createSelector(_state, (state) =>
  arrayOfObjToNameList(state.neighborhoods),
);
const _allPrincipals = createSelector(_state, (state) =>
  arrayOfObjToNameList(state.principals),
);
const _allSchoolBuildings = createSelector(_state, (state) =>
  arrayOfObjToNameList(state.schoolBuildings),
);
const _allTracks = createSelector(_state, (state) =>
  arrayOfObjToNameList(state.tracks),
);
const _allSchools = createSelector(_state, (state) => {
  const schoolBuildings = state.schoolBuildings;
  const schools = state.schools;
  const merge = schoolBuildings.map((building) => ({
    ...building,
    ...schools.find((school) => school.id === building.school_id),
    schoolBuildingName: building.name,
    schoolBuildingId: building.id,
  }));
  return arrayOfObjToNameList(merge);
});

const _monthsWithYearsSelect = createSelector(_state, (state) => {
  const _months = state.months;

  const monthsWithYears = _months?.map((month) => ({
    label: `${month.hebrew}` + " " + `${gematriya(month.year_id.toString())}`,
    value: month.id,
  }));
  return monthsWithYears ?? [];
});
export const listsSelectors = {
  statuses(state: AppState) {
    return state.lists.statuses;
  },

  chaburahTeachers(state: AppState) {
    return state.lists.chaburahTeachers ?? [];
  },
  neighborhoods(state: AppState) {
    return state.lists.neighborhoods;
  },
  countries(state: AppState) {
    return state.lists.countries;
  },
  optionsCountries(state: AppState) {
    return state.lists.countries.map((c) => ({
      id: c.id,
      name: c.hr_name,
    }));
  },
  optionsCountryCodes(state: AppState) {
    return state.lists.countries.map((c) => ({
      id: c.id,
      name: c.code + " - " + c.hr_name,
    }));
  },
  chaburahTypes(state: AppState) {
    return state.lists.chaburahTypes;
  },
  chaburahs(state: AppState) {
    return state.lists.chaburahs;
  },
  users(state: AppState) {
    return state.lists.users;
  },
  monthsWithYearsSelect: _monthsWithYearsSelect,
  allTeachersNames: _allTeachersNames,
  allTeachersYiddish: _allTeachersYiddish,
  allChaburahs: _allChaburahs,
  allNeighborhoods: _allNeighborhoods,
  allSchools: _allSchools,
  allSchoolBuildings: _allSchoolBuildings,
  allTracks: _allTracks,
  allPrincipals: _allPrincipals,
  statusById: _statusById,
  neighborhoodById: _neighborhoodById,
  chaburahTypesById: _chaburahTypes,
  chaburahById: _chaburahs,
  teachersById: _teachers,
  monthById: _months,
  typeById: _types,
  userById: _users,
  neighborhoodPrincipalById: _neighborhoodPrincipals,
  semesterById: _semesters,
  tracks(state: AppState) {
    return state.lists.tracks;
  },
  trackById: _tracks,
  locations(state: AppState) {
    return state.lists.locations;
  },
  locationById: _location,
  subjectById: _subjects,
  subjectByName: _subjectsByName,
  mesechtoById: _mesechtos,
  countryById: _countries,
  schools(state: AppState) {
    return state.lists.schools;
  },
  schoolBuildings(state: AppState) {
    return state.lists.schoolBuildings;
  },

  schoolBuildingsPlusSchool(state: AppState) {
    const schoolBuildings = state.lists.schoolBuildings;
    const schools = state.lists.schools;
    const merge = schoolBuildings.map((building) => ({
      ...building,
      ...schools.find((school) => school.id === building.school_id),
      schoolBuildingName: building.name,
      schoolBuildingId: building.id,
    }));
    return merge;
  },
  allTeachers(state: AppState) {
    return state.lists.allTeachers;
  },
  months(state: AppState) {
    return state.lists.months;
  },
  semesters(state: AppState) {
    return state.lists.semesters;
  },
  neighborhoodPrincipals(state: AppState) {
    return state.lists.neighborhoodPrincipals;
  },
  principals(state: AppState) {
    return state.lists.principals;
  },
  subjects(state: AppState) {
    return state.lists.subjects;
  },
  mesechtos(state: AppState) {
    return state.lists.mesechtos;
  },
};
