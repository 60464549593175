import { useState } from "react";
import { useDispatch } from "react-redux";
import { DataGridAsync, SelectionToolbar } from "../../../components";
import { useGridApiRef, GridSelectionModel } from "@mui/x-data-grid-pro";

import { useNavigate, To } from "react-router-dom";

import {
  REDEMPTION_COLUMNS,
  REDEMPTION_HIDDEN_COLUMNS,
} from "../../../constants";
import { prizeActions, prizeSelectors } from "../../../state";
import { generateVisibleColumnsModel } from "../../../lib";
import { dataGridFilterStyles } from "../../../themes";

export const Redemptions = () => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const itemButtonClick = (to: To) => {
    navigate(`/admin/prizes/redemption/${to}`);
  };

  const handleClick = (e) => {
    itemButtonClick(e.id);
  };

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const options = [
    { label: "Pending", value: false },
    { label: "Delivered", value: true },
  ];
  const [value, setValue] = useState<{ label: string; value: boolean }>();
  const [reloadVersion, setReloadVersion] = useState(0);
  const reload = () => setReloadVersion(reloadVersion + 1);

  const CustomToolbar = () =>
    SelectionToolbar({
      apiRef,
      options,
      selectionModel,
      bulkUpdateValues: () => {
        dispatch(
          prizeActions.updateBulkRedemptionStatus(
            {
              ids: selectionModel,
              status: value?.value,
            },
            reload,
          ),
        );
      },
      value,
      setValue,
      placeholder: "Search Chaburah, Pin, נעמן, Phone or Neighborhood...",
    });

  return (
    <DataGridAsync
      getRowHeight={() => "auto"}
      sx={{
        "& .MuiDataGrid-row": { cursor: "pointer" },
        "& .MuiDataGrid-cell": { paddingBottom: 1, marginTop: 1 },
      }}
      pagination={true}
      apiRef={apiRef}
      reloadVersion={reloadVersion}
      onRowClick={(e) => {
        handleClick(e);
      }}
      autoHeight
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      loadDataAction={prizeActions.getRedemptions}
      selector={prizeSelectors.redemptions}
      columns={REDEMPTION_COLUMNS}
      initialState={{
        columns: {
          columnVisibilityModel: generateVisibleColumnsModel(
            REDEMPTION_COLUMNS,
            REDEMPTION_HIDDEN_COLUMNS.map((c) => c.field),
          ),
        },
        pagination: {
          pageSize: 25,
        },
      }}
      components={{
        Toolbar: CustomToolbar,
      }}
      componentsProps={{
        toolbar: { printOptions: { disableToolbarButton: true } },
        filterPanel: {
          linkOperators: [],
        },
        panel: {
          sx: {
            ...dataGridFilterStyles,
          },
        },
      }}
    />
  );
};
