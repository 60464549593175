import React, { useCallback, useEffect, useState } from "react";
import {
  adminActions,
  listsSelectors,
  studentActions,
  studentSelectors,
} from "../../../../state";
import {
  Button,
  Card,
  Collapse,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  cardContainer,
  container,
  dataGridContainer,
  expandButton,
  manageLeftSide,
  manageRightSide,
  testContainer,
  titleContainer,
  notEnrolledContainer,
  CircleIcon,
} from "./TabManage.styles";
import TabContainer from "../../components/TabContainer";
import PointsInfo from "../General/PointsInfo";
import PointsDetails from "../General/PointsDetails";
import { Circle, Edit, ExpandMore } from "@mui/icons-material";
import MonthSelector from "../../components/MonthSelector";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box } from "@mui/system";
import ScoreDetails from "../General/ScoreDetails";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { STUDENT_TEST_ANSWERS } from "../../../../constants";
import { graySxIcon } from "../../../../constants/styles";
import { dataGridFilterStyles } from "../../../../themes";
import StudentGathering from "../../../../components/icons/StudentGathering";
import StudentCheckIn from "../../../../components/icons/StudentCheckIn";
import { getYiddishDateString } from "../../../../lib";
import Calendar from "./Calendar";
import { pointsStyle } from "../General/PointsRow/PointsRow.styles";

type TabType = "calendar" | "test";

const TabManage = ({ openAddMonthlyPointsModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const chaburahById = useSelector(listsSelectors.chaburahById);
  const teachersById = useSelector(listsSelectors.teachersById);
  const userById = useSelector(listsSelectors.userById);
  const monthsById = useSelector(listsSelectors.monthById);
  const student = useSelector(studentSelectors.studentMonthData);
  const chaburah = useSelector(studentSelectors.setChaburahMonthData);
  const [monthId, setMonthId] = useState<string>("0");
  const [currentSemesterId, setCurrentSemesterId] = useState();
  const [teacherName, setTeacherName] = useState("");
  const [menahel, setMenahel] = useState("");
  const [tab, setTab] = useState<TabType>("calendar");
  const handleTabChange = (event: React.SyntheticEvent, value: TabType) => {
    event.stopPropagation();
    setTab(value);
  };

  useEffect(() => {
    if (!!monthId && monthId !== "0" && monthId !== "null") {
      dispatch(studentActions.getStudentMonth(params.id, monthId));
    }
  }, [dispatch, monthId, params.id]);

  useEffect(() => {
    const name =
      teachersById[student?.student_months?.[0]?.teacher_id]?.name_yiddish;
    if (name) {
      setTeacherName(name);
    } else setTeacherName("");
  }, [student?.student_months, teachersById]);

  useEffect(() => {
    const name =
      userById[student?.student_months?.[0]?.principals_cache?.user_id]
        ?.name_yiddish;
    if (name) {
      setMenahel(name);
    } else setMenahel("");
  }, [student?.student_months, userById]);

  useEffect(() => {
    if (
      student?.student_months?.[0]?.chaburah_id &&
      monthId !== "0" &&
      student?.id?.toString() === params?.id?.toString()
    ) {
      dispatch(
        studentActions.getChaburahMonth(
          student?.student_months?.[0]?.chaburah_id,
          monthId,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    monthId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    student?.student_months?.[0]?.chaburah_id,
    student?.id,
    params?.id,
  ]);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleSelectMonthId = useCallback((newMonthId: number) => {
    navigate(`/admin/student/${params.id}?month_id=${newMonthId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const monthId: any = await dispatch(adminActions.getCurrentMonth());
      setCurrentSemesterId(monthsById?.[monthId]?.semester_id);
      const paramResult = searchParams.get("month_id");
      const mid = paramResult && paramResult !== "null" ? paramResult : monthId;
      if (mid) {
        setMonthId(mid);
      }
    })();
  }, [dispatch, monthsById, searchParams]);
  const hasChaburah =
    chaburah?.description || chaburahById[chaburah?.chaburah_id ?? 0]?.name;
  const chosenSemsterId = monthsById?.[+monthId]?.semester_id;
  const testProps = { student, chaburah, handleClick, open };
  const tabs = {
    test: <Test {...testProps} />,
    calendar: <Calendar monthId={monthId} studentId={params.id} />,
  };

  if (!student?.points_transactions && !chaburah) {
    return (
      <TabContainer>
        <MonthSelector
          monthId={monthId}
          onSelectMonthId={handleSelectMonthId}
        />
        <Typography>Loading...</Typography>
      </TabContainer>
    );
  } else
    return (
      <TabContainer>
        <Stack direction={"row"} alignItems={"center"}>
          <MonthSelector
            monthId={monthId}
            onSelectMonthId={handleSelectMonthId}
            chosenSemsterId={chosenSemsterId}
            currentSemesterId={currentSemesterId}
          />
          {!student?.not_enrolled && (
            <>
              <Box
                sx={{
                  border: "1px solid #DDE3EB",
                  borderRadius: 1,
                  backgroundColor: "primary.contrastText",
                  p: "10px 24px",
                  minHeight: "97px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginX: 2,
                }}
              >
                <Box>Month's Total</Box>
                <Box textAlign="center" sx={pointsStyle as any}>
                  {" "}
                  {student?.month_total}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  border: "1px solid #DDE3EB",
                  borderRadius: 1,
                  backgroundColor: "primary.contrastText",
                  p: "10px 24px",
                  paddingRight: 0,
                }}
              >
                <Stack direction={"column"} gap={2}>
                  <Box display="flex">
                    <Stack
                      direction={"row"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRight: "1px solid #DDE3EB",
                        pr: 2,
                      }}
                      gap={1}
                    >
                      {student?.student_months?.[0]?.check_in ? (
                        <StudentCheckIn
                          sx={{ display: "flex" }}
                          check_in={student?.student_months?.[0]?.check_in}
                        />
                      ) : (
                        <Circle sx={graySxIcon} />
                      )}
                      <Typography component={"span"} variant={"body2"}>
                        Monthly phone call
                      </Typography>
                      {student?.student_months?.[0]?.call_date && (
                        <>
                          <CircleIcon />
                          {getYiddishDateString(
                            student?.student_months?.[0]?.call_date,
                          )}
                        </>
                      )}
                    </Stack>
                    <Stack
                      direction={"row"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                      }}
                      gap={2}
                    >
                      {student?.student_months?.[0]?.gathering ? (
                        <StudentGathering
                          sx={{ display: "flex" }}
                          gathering={student?.student_months?.[0]?.gathering}
                        />
                      ) : (
                        <Circle sx={graySxIcon} />
                      )}
                      <Typography component={"span"} variant={"body2"}>
                        Gathering - צוזאמקום
                      </Typography>
                    </Stack>
                  </Box>
                  <Box display="flex">
                    <Box paddingTop="10px" borderTop="1px solid #DDE3EB">
                      <Tooltip
                        placement="bottom"
                        title={student?.student_months?.[0]?.monthly_note || ""}
                      >
                        <Box
                          sx={{
                            maxWidth: "500px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {monthsById[monthId]?.hebrew}{" "}
                          {`note: 
                  ${student?.student_months?.[0]?.monthly_note || ""}`}
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                </Stack>
                <IconButton
                  sx={{ marginLeft: "15px", marginBottom: "20px" }}
                  onClick={openAddMonthlyPointsModal}
                >
                  <Edit />
                </IconButton>
              </Box>
            </>
          )}
        </Stack>
        {student.not_enrolled ? (
          <Card sx={notEnrolledContainer}>
            Student not enrolled for this month
          </Card>
        ) : (
          <>
            <Stack direction={"row"} alignItems={"start"}>
              <Typography component="div" display="flex" alignItems="baseline">
                {menahel && (
                  <>
                    <Box display="inline-block" textAlign="center">
                      <Box>{menahel}</Box>
                      <Box fontSize="10px" color="#5C738E">
                        מנהל
                      </Box>
                    </Box>
                  </>
                )}
                {chaburahById[chaburah?.chaburah_id ?? 0]?.name && (
                  <>
                    {" "}
                    {menahel && <CircleIcon />}
                    <Box display="inline-block" textAlign="center">
                      <Box>{chaburahById[chaburah.chaburah_id ?? 0].name}</Box>
                      <Box fontSize="10px" color="#5C738E">
                        חבורה
                      </Box>
                    </Box>
                  </>
                )}
                {teacherName && (
                  <>
                    {hasChaburah && <CircleIcon />}
                    <Box display="inline-block" textAlign="center">
                      <Box>{teacherName}</Box>
                      <Box fontSize="10px" color="#5C738E">
                        ראש קנין
                      </Box>
                    </Box>
                  </>
                )}

                {chaburah?.description && (
                  <>
                    <CircleIcon />
                    <Box display="inline-block" textAlign="center">
                      <Box>{chaburah.description}</Box>
                      <Box fontSize="10px" color="#5C738E"></Box>
                    </Box>
                  </>
                )}
              </Typography>
            </Stack>
          </>
        )}
        {!student?.not_enrolled && (
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Calendar" value={"calendar" as TabType} />
            <Tab label="Test - בחינה" value={"test" as TabType} />
          </Tabs>
        )}
        {!student?.not_enrolled && tabs[tab]}
      </TabContainer>
    );
};
function Test({ student, chaburah, handleClick, open }) {
  const dailyPoints = {
    checkPoints: student?.daily_points
      ? student?.daily_points -
        (student?.points_transactions?.filter(
          (pt) => pt.type === "attendance_bonus",
        )[0]?.points || 0)
      : student?.points_transactions?.filter(
          (pt) => pt.type === "attendance",
        )[0]?.points || 0,
    bonus:
      student?.points_transactions?.filter(
        (pt) => pt.type === "attendance_bonus",
      )[0]?.points || 0,
    get total() {
      return this.checkPoints + this.bonus;
    },
  };

  const chazarahPoints = {
    checkPoints:
      student?.points_transactions?.filter((pt) => pt.type === "review")[0]
        ?.points || 0,
    bonus:
      student?.points_transactions?.filter(
        (pt) => pt.type === "review_bonus",
      )[0]?.points || 0,
    get total() {
      return this.checkPoints + this.bonus;
    },
  };

  const bechinaPoints = {
    checkPoints:
      student?.points_transactions?.filter((pt) => pt.type === "test")[0]
        ?.points || 0,
    bonus:
      student?.points_transactions?.filter((pt) => pt.type === "test_bonus")[0]
        ?.points || 0,
    get total() {
      return this.checkPoints + this.bonus;
    },
  };

  const totals = {
    checks:
      dailyPoints.checkPoints +
      chazarahPoints.checkPoints +
      bechinaPoints.checkPoints,
    gathering:
      student?.points_transactions.find((p) => p.type === "gathering")
        ?.points || 0,
    bonus: dailyPoints.bonus + chazarahPoints.bonus + bechinaPoints.bonus,
    additionalBonus: student?.points_transactions
      ?.filter((pt) => pt.type === "bonus")
      .reduce((prev, cur) => prev + cur.points, 0),
    get total() {
      return this.checks + this.gathering + this.bonus + this.additionalBonus;
    },
  };

  return (
    <TabContainer>
      <Stack direction={"column"} sx={cardContainer} gap={2}>
        <Stack direction={"row"} sx={titleContainer} gap={2}>
          <Stack minWidth={"25%"} justifyContent={"flex-start"}>
            <PointsInfo
              title={"טעגליכע פראגראם"}
              points={dailyPoints.total}
              outOf={
                (chaburah?.max_attendance_checks || 0) *
                  (chaburah?.attendance_multiplier || 1) +
                (chaburah?.attendance_bonus || 0)
              }
            />
          </Stack>
          <Stack minWidth={"25%"} justifyContent={"flex-start"}>
            <PointsInfo
              title={"חזרה פראגראם"}
              points={chazarahPoints.total}
              outOf={
                (chaburah?.max_review_checks || 0) *
                  (chaburah?.review_multiplier || 1) +
                  chaburah?.review_bonus || 0
              }
            />
          </Stack>
          <Stack minWidth={"25%"} justifyContent={"flex-start"}>
            <PointsInfo
              title={"בחינה"}
              points={bechinaPoints.total}
              outOf={chaburah?.max_test_points}
            />
          </Stack>
          <Stack minWidth={"25%"} justifyContent={"flex-start"}>
            <PointsInfo title={"Month's Total"} points={totals.total} />
          </Stack>
        </Stack>
        {!open && (
          <Stack direction={"row"} sx={expandButton} gap={2}>
            <Button onClick={handleClick}>
              Show more <ExpandMore sx={{ ml: 1, color: "primary" }} />
            </Button>
          </Stack>
        )}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack direction={"row"} sx={container} gap={2}>
            <Stack minWidth={"25%"} justifyContent={"flex-start"}>
              <PointsDetails
                header={"Checks"}
                outOf={
                  chaburah?.max_attendance_checks ||
                  0 * (chaburah?.attendance_multiplier || 1)
                }
                secondary={
                  student?.daily_checks ||
                  student?.student_months?.[0]?.attendance_count
                }
                points={dailyPoints.checkPoints}
              />
              <PointsDetails
                header={"Bonus"}
                points={dailyPoints.bonus}
                outOf={chaburah?.attendance_bonus}
              />
              <PointsDetails header={"Total"} points={dailyPoints.total} />
            </Stack>
            <Stack minWidth={"25%"} justifyContent={"flex-start"}>
              <PointsDetails
                header={"Checks"}
                secondary={student?.student_months?.[0]?.review_count || 0}
                points={chazarahPoints.checkPoints}
                outOf={
                  chaburah?.max_review_checks ||
                  0 * (chaburah?.review_multiplier || 1)
                }
              />
              <PointsDetails
                header={"Bonus"}
                points={chazarahPoints.bonus}
                outOf={chaburah?.review_bonus}
              />
              <PointsDetails header={"Total"} points={chazarahPoints.total} />
            </Stack>
            <Stack minWidth={"25%"} justifyContent={"flex-start"}>
              <PointsDetails
                header={"Correct Answers"}
                secondary={
                  student?.correctCount + student?.extraCreditCorrect || 0
                }
                points={bechinaPoints.checkPoints}
                outOf={chaburah?.tests?.[0]?.count_questions}
              />
              <PointsDetails
                header={"Bonus"}
                points={bechinaPoints.bonus}
                outOf={chaburah?.tests?.[0]?.max_bonus}
              />
              <PointsDetails header={"Total"} points={bechinaPoints.total} />
            </Stack>
            <Stack minWidth={"25%"} justifyContent={"flex-start"}>
              <PointsDetails header={"Subtotal"} points={totals.checks} />
              <PointsDetails
                header="Gathering points"
                points={totals.gathering}
              />
              <PointsDetails header={"Bonus"} points={totals.bonus} />
              <PointsDetails
                header={"Additional Bonus"}
                points={totals.additionalBonus}
                reason={
                  student?.points_transactions?.filter(
                    (pt) => pt.type === "bonus",
                  )[0]?.reason
                }
              />
              <PointsDetails header={"Total"} points={totals.total} />
            </Stack>
          </Stack>
        </Collapse>
      </Stack>
      <Typography variant={"h4"}>Test - בחינה</Typography>
      <Stack direction={"row"} gap={5}>
        <Box sx={manageLeftSide}>
          <Stack direction={"column"} sx={cardContainer} gap={2}>
            <Stack direction={"row"} sx={testContainer} gap={2}>
              <Typography variant={"h5"}>Test name -</Typography>
            </Stack>
            <Stack justifyContent={"flex-start"}>
              <ScoreDetails
                header={"Correct Answers"}
                subtitle={`Out of ${
                  chaburah?.tests?.[0]?.count_questions -
                    student?.extraCreditCount || 0
                } (${chaburah?.tests?.[0]?.question_value || 0} points each)`}
                secondary={student?.correctCount || 0}
                points={student?.basePoints || 0}
              />
              <ScoreDetails
                header={"Answers looked in"}
                subtitle={`-${
                  chaburah?.tests?.[0]?.question_value ||
                  0 - chaburah?.tests?.[0]?.looked_in_value ||
                  0
                } points after ${
                  chaburah?.tests?.[0]?.max_looked_in || 0
                } looked in`}
                secondary={student?.student_months?.[0]?.answers_looked_in || 0}
                points={`
                  -${student?.lookedInReduction || 0}`}
              />
              <ScoreDetails
                header={"Revised Answers"}
                subtitle={`-${
                  chaburah?.tests?.[0]?.question_value ||
                  0 - chaburah?.tests?.[0]?.revised_question_value ||
                  0
                } points after ${
                  chaburah?.tests?.[0]?.max_revised || 0
                }  revised`}
                secondary={student?.revisedCount}
                points={`
                  -${student?.revisedReduction || 0}`}
              />
              <ScoreDetails
                header={"Extra credit"}
                subtitle={`out of ${student?.extraCreditCount || 0} (${
                  chaburah?.tests?.[0]?.extra_credit_value || 0
                } points each)`}
                secondary={student?.extraCreditCorrect || 0}
                points={student?.ecPoints || 0}
              />
              <ScoreDetails
                header={"Bonus"}
                subtitle={`if over a ${chaburah?.tests?.[0]?.threshold || 0}%`}
                points={student?.bonus || 0}
              />
              <ScoreDetails
                header={"Total"}
                subtitle={""}
                points={student?.total || 0}
              />
            </Stack>
          </Stack>
        </Box>
        <Box sx={manageRightSide}>
          <Stack direction={"column"} sx={dataGridContainer} gap={2}>
            <Typography variant={"h5"}>Questions</Typography>
            <DataGridPro
              rows={student?.answers || []}
              columns={STUDENT_TEST_ANSWERS}
              initialState={{
                sorting: {
                  sortModel: [{ field: "question_number", sort: "asc" }],
                },
              }}
              componentsProps={{
                toolbar: { printOptions: { disableToolbarButton: true } },
                panel: {
                  sx: {
                    ...dataGridFilterStyles,
                  },
                },
              }}
            />
          </Stack>
        </Box>
      </Stack>
    </TabContainer>
  );
}

export default TabManage;
