import React from "react";
import { Typography } from "@mui/material";

import { addCurrencySign, MappedCurrencies } from "./utils";

import { toFloat } from "../../../lib";

import { GridFooterContainer } from "@mui/x-data-grid-pro";
import BigNumber from "bignumber.js";

export function ApplyPaymentFooter({ amount, balanceItems }) {
  const sign = MappedCurrencies[balanceItems?.[0]?.currency] ?? "";
  const remainingBalance =
    BigNumber(toFloat(amount)).minus(
      balanceItems?.reduce((prev, curr) => {
        if (curr.isSelected) {
          //curr.amount is negative number
          return prev.plus(BigNumber(Math.abs(toFloat(curr.amount))));
        }
        return prev;
      }, BigNumber(0)),
    ) ?? 0;

  return (
    <GridFooterContainer sx={{ justifyContent: "right" }}>
      <Typography
        sx={{
          mw: 200,
          mr: 3,
          color: remainingBalance < BigNumber(0) ? "#bb2018" : "#777777",
        }}
        variant="subtitle2"
      >
        {remainingBalance < BigNumber(0)
          ? "Payoff months cant be more than payment amount"
          : `Remaining ${addCurrencySign(remainingBalance.toString(), sign)} ${
              remainingBalance > BigNumber(0) ? "Will be a credit" : ""
            }`}
      </Typography>
    </GridFooterContainer>
  );
}
