import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerActions, customerSelectors } from "../../../state";
import { Grid, Box } from "@mui/material";
import { useParams } from "react-router";
import { Page, Search, StyledInputBase } from "../styles/CustomerPage.styles";
import SearchIcon from "@mui/icons-material/Search";
import { BillingCards } from "../components";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { CUSTOMER_BILLING_HISTORY } from "../../../constants";
import EditPaymentSettings from "./EditPaymentSettings/EditPaymentSettings";
import { CustomFooter, CustomToolbar } from "../../../components";
import { dataGridFilterStyles } from "../../../themes";
import CheckBox from "../../../components/CheckBox";
import { ToggleButtons } from "../../../components/buttons";
import CustomerOldSystemBilling from "./CustomerOldSystemBilling";

interface EditPaymentData {
  showModal: boolean;
  customerId: number;
  studentId: number;
  name: string;
}

export const CustomerTabBilling = ({ customerId }) => {
  const apiRef = useGridApiRef();
  const params = useParams();
  const customer = useSelector(customerSelectors.customer) as ReturnType<
    typeof customerSelectors.customer
  >;
  const [billingHistory, setBillingHistory] = useState([] as any[]);
  const dispatch = useDispatch();
  const [showPreviousZman, setShowPreviousZman] = useState(false);
  const customerBillingIsNotFiltered = useSelector(
    customerSelectors.customerBillingIsNotFiltered,
  );
  const [isOldBillingTab, setIsOldBillingTab] = useState<boolean>(false);

  useEffect(() => {
    if (customerBillingIsNotFiltered !== showPreviousZman) {
      dispatch(
        customerActions.setCustomerBillingIsNotFiltered(showPreviousZman),
      );
      dispatch(
        customerActions.getCustomerBillingHistory(customerId, showPreviousZman),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPreviousZman]);

  useEffect(() => {
    return () => {
      dispatch(customerActions.setCustomerBillingIsNotFiltered(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customer.billing_history) {
      const flattenedBillingArray = [] as any[];
      customer.billing_history?.forEach((row: any, index) => {
        const copy = { ...row };

        if (!row.transaction_id) {
          copy.transaction_id = row.id;
        }
        if (!row.currency) {
          copy.currency = row?.invoice?.currency ?? "";
        }
        copy.id = index + 1;
        flattenedBillingArray.push(copy);
      });
      setBillingHistory(flattenedBillingArray);
    }
  }, [customer.billing_history, params.id, showPreviousZman]);

  const [editPayment, setEditPayment] = useState<EditPaymentData | null>(null);

  const { v_customer_balance_detail, currency } = customer!;

  if (!customer) return null;
  return (
    <Page>
      <Box display="flex" justifyContent="space-between" width="100%">
        {isOldBillingTab ? (
          <Box></Box>
        ) : (
          <CheckBox
            label="Show previous zman billing"
            checked={showPreviousZman}
            onClick={() => {
              setShowPreviousZman(!showPreviousZman);
            }}
          />
        )}
        <Box maxWidth={360}>
          <ToggleButtons
            value={isOldBillingTab ? "1" : "0"}
            size="medium"
            onChange={(_e, v) => {
              setIsOldBillingTab(v === "1");
            }}
            toggleStyle={{
              minWidth: 180 /* marginTop: 24, marginBottom: 24  */,
            }}
            buttonOptions={[
              { label: "Current", value: "0" },
              { label: "Old", value: "1" },
            ]}
          />
        </Box>
      </Box>
      {isOldBillingTab ? (
        <CustomerOldSystemBilling />
      ) : (
        <>
          <BillingCards
            balanceDetails={v_customer_balance_detail}
            currency={currency}
          />
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
          >
            <Grid item>
              <Box>
                <Search>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => {
                      apiRef.current.setQuickFilterValues([e.target.value]);
                    }}
                  />

                  <SearchIcon
                    sx={{ color: "#B4B8C8", height: 32, paddingTop: 1.5 }}
                  />
                </Search>
              </Box>
            </Grid>
          </Grid>
          {billingHistory && (
            <DataGridPro
              autoHeight
              apiRef={apiRef}
              rows={billingHistory}
              columns={CUSTOMER_BILLING_HISTORY}
              pagination
              initialState={{
                columns: {
                  columnVisibilityModel: { id: false, created_at: false },
                },
                sorting: {
                  sortModel: [{ field: "created_at", sort: "desc" }],
                },
                pagination: {
                  pageSize: 25,
                },
              }}
              components={{
                Toolbar: CustomToolbar,
                Footer: () => CustomFooter(billingHistory?.length),
              }}
              componentsProps={{
                toolbar: {
                  printOptions: { disableToolbarButton: true },
                },
                panel: {
                  sx: {
                    ...dataGridFilterStyles,
                  },
                },
              }}
              sx={{ marginTop: "20px" }}
            />
          )}
        </>
      )}
      <EditPaymentSettings
        showModal={!!editPayment?.showModal}
        customerId={editPayment?.customerId ?? 0}
        studentId={editPayment?.studentId ?? 0}
        name={editPayment?.name ?? ""}
        onClose={() => setEditPayment(null)}
      />
    </Page>
  );
};

export default CustomerTabBilling;
