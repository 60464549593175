import { array, boolean, number, object, string } from "yup";
import { floatRegExp } from "../../../lib/regexp";
import { ChaburahType } from "./types";
import { ChaburahTeachersNeighborhoods } from "../../../state";

export interface DetailsFields {
  name: string;
  chaburah_type_id: number;
  neighborhood_id: string;
  location_id: string | null;
  priceUSD: string;
  priceGBP: string;
  active: boolean;
}

export const initDetailsState: DetailsFields = {
  name: "",
  chaburah_type_id: 0,
  neighborhood_id: "",
  location_id: null,
  priceUSD: "",
  priceGBP: "",
  active: true,
};

export const validationDetailsSchema = object().shape({
  name: string().required("Required").label("Name"),
  chaburah_type_id: number()
    .oneOf([ChaburahType.Masmidim, ChaburahType.CD])
    .required("Required")
    .label("Chaburah type"),
  neighborhood_id: string().when("chaburah_type_id", {
    is: (val: number) => val === 1,
    then: string().required("Required").label("Neighborhood"),
    otherwise: string().notRequired(),
  }),
  priceUSD: string()
    .test(
      "priceUSD",
      "${label} must be a number",
      (val = "") => !val || floatRegExp.test(val),
    )
    // .test(
    //   "priceUSD",
    //   "${label} must be no less than 0.05",
    //   (val = "") => !val || +val >= 0.05,
    // )
    .test(
      "priceUSD",
      "${label} must be no greater than 10,000",
      (val = "") => !val || +val <= 10_000,
    )
    .label("USA Price"),
  priceGBP: string()
    .test(
      "priceGBP",
      "${label} must be a number",
      (val = "") => !val || floatRegExp.test(val),
    )
    // .test(
    //   "priceGBP",
    //   "${label} must be no less than 0.05",
    //   (val = "") => !val || +val >= 0.05,
    // )
    .test(
      "priceGBP",
      "${label} must be no greater than 10,000",
      (val = "") => !val || +val <= 10_000,
    )
    .label("UK Price"),
});

export interface RoshKinyanFields {
  editGroup: boolean;
  id?: number;
  chaburah_id: number;
  chaburah_type_id: number;
  chaburah_teacher_id: string;
  chaburah_teachers_neighborhoods: ChaburahTeachersNeighborhoods[];
}

export const initRoshKinyanState: RoshKinyanFields = {
  editGroup: false,
  chaburah_id: 0,
  chaburah_type_id: 0,
  chaburah_teacher_id: "",
  chaburah_teachers_neighborhoods: [],
};

export const validationRoshKinyanSchema = object().shape({
  chaburah_teacher_id: string().when("chaburah_type_id", {
    is: (val: number) => val === ChaburahType.Masmidim,
    then: string().required("Required").label("ראש קנין / מגיד שיעור"),
    otherwise: string().notRequired(),
  }),
  chaburah_teachers_neighborhoods: array().when("chaburah_type_id", {
    is: (val: number) => val === ChaburahType.CD,
    then: array()
      .min(1)
      .of(
        object().shape({
          neighborhood_id: number().required().label("Neighborhood"),
          chaburah_teacher_id: number().required().label("Chaburah teacher"),
          default: boolean().required().label("Default"),
        }),
      )
      .label("ראש קנין / מגיד שיעור"),
    otherwise: array().nullable().notRequired(),
  }),
});

export interface CurriculumItem {
  index: number;
  semester_id: number | null;
  semester: string | null;
  subject_id: number | null;
  subject: string | null;
  isDeletable: boolean;
  startYear?: unknown;
  startSemester?: unknown;
  semesterYear?: string;
}

export interface CurriculumFields {
  trackId: string;
  yearId: string;
  semesterTypeId: string;
  semesters: CurriculumItem[] | null;
}

export const initCurriculumState: CurriculumFields = {
  trackId: "",
  yearId: "",
  semesterTypeId: "",
  semesters: null,
};

export const validationCurriculumSchema = object().shape({
  trackId: string().required("Required").label("קנין"),
  yearId: string().required("Required").label("שנה"),
  semesterTypeId: string().required("Required").label("זמן"),
  semesters: array()
    .min(1)
    .of(
      object().shape({
        semester_id: number().required("Required").label("Semester"),
        subject_id: number().required("Required").label("Subject"),
      }),
    )
    .label("Semesters"),
});
