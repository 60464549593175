import React, { FC } from "react";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import {
  bottomSectionStyle,
  btnStyle,
  topSectionStyle,
} from "./ManageUserPage.styles";
import SimplePageHeader from "../../../components/SimplePageHeader";
import { useManageUserPage } from "./useManageUserPage";
import { Button, ButtonGroup, Divider, Grid, Typography } from "@mui/material";
import { Fields, initialValues, validationSchema } from "./schema";
import { Form, Formik } from "formik";
import { DeleteIcon, FormikInput } from "../../../components";
import { FormikSelect } from "../../../components/Select";
import { getButtonVariant } from "../../../lib";
import FormikSelectMultiple from "../../../components/Select/FormikSelectMultiple";
import DeleteDialog from "../components/Modals/DeleteDialog";
import CheckBox from "../../../components/CheckBox";

const ManageUserPage: FC = () => {
  const {
    innerRef,
    pageMode,
    title,
    showModal,
    showPassword,
    handleShowPassword,
    handleBackButtonClick,
    handleSubmit,
    handleDeleteUserClick,
    handleStatusMethod,
    handleCloseModal,
    deleteFunction,
    states,
    countries,
    neighborhoods,
    roles,
    countryCodes,
  } = useManageUserPage();

  return (
    <Page>
      <TopSection style={topSectionStyle}>
        <SimplePageHeader
          title={title}
          onBackButtonClick={handleBackButtonClick}
        />
      </TopSection>

      <BottomSection style={bottomSectionStyle}>
        <Formik<Fields>
          innerRef={innerRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors }) => {
            return (
              <Form>
                <Typography variant={"h6"} sx={{ mb: 2 }}>
                  User details
                </Typography>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikInput name="last_name_yiddish" label="לעצטע נאמען" />
                  </Grid>

                  <Grid item xs={4}>
                    <FormikInput
                      name="first_name_yiddish"
                      label="ערשטע נאמען"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikInput name="first_name" label="First name" />
                  </Grid>

                  <Grid item xs={4}>
                    <FormikInput name="last_name" label="Last name" />
                  </Grid>
                </Grid>

                <Typography variant={"h6"} sx={{ mt: 7 }}>
                  Contact
                </Typography>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item md={3}>
                        <FormikSelect
                          name="home_country_code_id"
                          label={"Code"}
                          options={countryCodes}
                        />
                      </Grid>
                      <Grid item md={9}>
                        <FormikInput
                          name="home_phone"
                          label="Home phone number"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item md={3}>
                        <FormikSelect
                          name="mobile_country_code_id"
                          label={"Code"}
                          options={countryCodes}
                        />
                      </Grid>
                      <Grid item md={9}>
                        <FormikInput
                          name="mobile_phone"
                          label="Cell phone number"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikInput name="email" label="Email" />
                  </Grid>
                </Grid>

                <Typography variant={"h6"} sx={{ mt: 7 }}>
                  Address
                </Typography>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikInput name="address" label="Address line 1" />
                  </Grid>

                  <Grid item xs={4}>
                    <FormikInput name="address_2" label="Address line 2" />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikInput name="city" label="City" />
                  </Grid>

                  <Grid item xs={4}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <FormikSelect
                          name="state"
                          label={"State"}
                          options={states}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikInput name="zip" label="Zip" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikSelect
                      label="Country"
                      name="country_id"
                      options={countries}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikSelect
                      label="Neighborhood"
                      name="neighborhood_id"
                      options={neighborhoods}
                    />
                  </Grid>
                </Grid>

                <Typography variant={"h6"} sx={{ mt: 7 }}>
                  Login
                </Typography>
                <CheckBox
                  name={"add_password"}
                  label={`${pageMode === "insert" ? "Add a" : "Edit"} password`}
                  checked={showPassword}
                  onChange={handleShowPassword}
                />
                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikInput name="username" label="Username" />
                  </Grid>

                  {showPassword && (
                    <Grid item xs={4}>
                      <FormikInput name="password" label="Password" />
                    </Grid>
                  )}
                </Grid>

                <Typography variant={"h6"} sx={{ mt: 7 }}>
                  Role
                </Typography>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikSelectMultiple
                      label="Roles"
                      name="role_ids"
                      options={roles}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  sx={{ mt: 3 }}
                >
                  <Grid item xs={12}>
                    <ButtonGroup
                      sx={btnStyle(touched.active && !!errors.active)}
                    >
                      <Button
                        variant={getButtonVariant(
                          typeof values.active === "boolean" && values.active,
                        )}
                        onClick={handleStatusMethod.bind(null, true)}
                      >
                        Active
                      </Button>

                      <Button
                        variant={getButtonVariant(
                          typeof values.active === "boolean" && !values.active,
                        )}
                        onClick={handleStatusMethod.bind(null, false)}
                      >
                        Inactive
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 1.5 }} />

                <Button
                  variant="outlined"
                  sx={{ mr: 1.5, minWidth: "180px" }}
                  onClick={handleBackButtonClick}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  sx={{ mr: 1.5, minWidth: "180px" }}
                  type="submit"
                >
                  Save
                </Button>

                {pageMode === "edit" && (
                  <Button
                    variant="text"
                    sx={{ minWidth: "180px", color: "#F62527" }}
                    onClick={handleDeleteUserClick}
                  >
                    <DeleteIcon sx={{ width: "20px", mr: 1 }} />
                    delete
                  </Button>
                )}
              </Form>
            );
          }}
        </Formik>
      </BottomSection>

      <DeleteDialog
        showModal={showModal}
        name={"this"}
        subject={"user"}
        onClose={handleCloseModal}
        deleteFunction={deleteFunction}
      />
    </Page>
  );
};

export default ManageUserPage;
