import React, { FC } from "react";
import TabContainer from "../../components/TabContainer";
import { DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import { GROUP_STUDENTS_COLUMNS } from "../../../../constants";
import { gridStyles } from "./TabStudents.styles";
import { useTabStudents } from "./useTabStudents";
import { To, useNavigate } from "react-router-dom";
import { Student } from "../../../../state";
import { CustomFooter } from "../../../../components";
import { dataGridFilterStyles } from "../../../../themes";

const TabStudents: FC = () => {
  const { apiRef, data, CustomToolbar } = useTabStudents();

  const navigate = useNavigate();
  const itemButtonClick = (to: To) => {
    navigate(`/admin/student/${to}`);
  };

  const handleClick = (e: GridRowParams<Student>) => {
    const studentId = e.row.id;

    studentId && itemButtonClick(studentId.toString(10));
  };

  return (
    <TabContainer sx={{ px: 0, height: "calc(100vh - 325px)" }}>
      <DataGridPro
        apiRef={apiRef}
        sx={gridStyles}
        pagination={true}
        rowsPerPageOptions={[
          10,
          25,
          50,
          100,
          data && data?.length > 100 ? data.length : 200,
        ]}
        onRowClick={(e) => handleClick(e)}
        rows={data ?? []}
        columns={GROUP_STUDENTS_COLUMNS}
        components={{
          Toolbar: CustomToolbar,
          Footer: () => CustomFooter(data?.length || 0),
        }}
        initialState={{
          pagination: {
            pageSize: 25,
          },
        }}
        componentsProps={{
          toolbar: { printOptions: { disableToolbarButton: true } },
          panel: {
            sx: {
              ...dataGridFilterStyles,
            },
          },
        }}
      />
    </TabContainer>
  );
};

export default TabStudents;
