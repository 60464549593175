import { SxProps, Theme } from "@mui/material";

export const pointsRowStyle: SxProps<Theme> = {
  mb: "25px",

  "&:last-of-type": {
    mb: "unset",
  },

  "& > .MuiTypography-root": {
    color: "#395584",
    fontSize: "12px",
    fontWeight: 600,
    letterSpacing: "0.11px",
    lineHeight: "11px",

    "&.balance": {
      fontSize: "14px",
      lineHeight: "13px",
    },
  },
};

export const starIcon: SxProps<Theme> = {
  height: "12px",
  width: "13px",
  fill: "#F6C07F",
  mr: "5px",
};

export const pointsStyle: SxProps<Theme> = {
  color: "#F0921F",
  letterSpacing: "0.15px",
  lineHeight: "19px",
};
