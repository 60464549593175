import { useCallback, useEffect, useState } from "react";
import { gematriya, toFloat } from "../../../lib";
import {
  BalanceRow,
  customerActions,
  customerSelectors,
  listsSelectors,
} from "../../../state";
import { useDispatch, useSelector } from "react-redux";
import { addCurrencySign, MappedCurrencies, usePageMode } from "./utils";
import { GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import { GridRowId } from "@mui/x-data-grid-pro";
import { PageMode } from "../ManageGroup/types";
import BigNumber from "bignumber.js";

export default function useApplyPayment(formik, id?: string, mode?: PageMode) {
  // eslint-disable-next-line prefer-const
  let { id: paymentId, pageMode, transactionId } = usePageMode();
  if (!id) {
    id = paymentId;
  }
  if (!pageMode && mode) {
    pageMode = mode;
  }
  const [balanceItems, setBalanceItems] = useState<BalanceRow[] | null>(null);
  const [selected, setSelected] = useState<GridRowId[]>();
  const dispatch = useDispatch();
  const cp = useSelector(customerSelectors.customersPayments);

  const customerMonthBalance = useSelector(
    customerSelectors.customerMonthBalance,
  );
  const months = useSelector(listsSelectors.monthById);

  useEffect(() => {
    if (id) {
      dispatch(customerActions.getCustomerPrepayOptions(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && pageMode) {
      if (pageMode === "edit" && transactionId) {
        dispatch(
          customerActions.getCustomerMonthBalance(
            id,
            `transaction_id=${transactionId}`,
          ),
        );
      } else if (pageMode === "insert") {
        dispatch(customerActions.getCustomerMonthBalance(id));
      }
    }
  }, [dispatch, id, pageMode, transactionId]);

  const getRemainingBalance = useCallback((array) => {
    const remainingBalance = array.reduce((prev, curr) => {
      if (curr.isSelected) {
        //curr.amount is negative number
        return prev.plus(BigNumber(Math.abs(toFloat(curr.amount))));
      }
      return prev;
    }, BigNumber(0));
    return remainingBalance;
  }, []);

  useEffect(() => {
    //Handles edit mode to set remaining balance
    if (selected && pageMode === "edit") {
      const balance = getRemainingBalance(formik.values.balanceItems);
      formik.setFieldValue(
        "remainingBalance",
        BigNumber(toFloat(formik.values.amount)).minus(balance).toNumber(),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, pageMode, formik.values.balanceItems]);

  useEffect(() => {
    const nv = JSON.parse(JSON.stringify(formik.values.balanceItems ?? []));
    nv.forEach((v) => {
      v.formik = formik;
    });
    setBalanceItems([...nv]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    if (customerMonthBalance?.length && pageMode === "insert") {
      const nmb: BalanceRow[] | null = customerMonthBalance.map(
        ({ id, month_id, amount, paid, unpaid, currency }, index) => {
          const month = months[month_id]?.hebrew ?? "";
          const year = months[month_id]?.year_id ?? "";
          const balance = (toFloat(amount) + toFloat(paid)).toFixed(2);
          return {
            id,
            index,
            month: `${month} ${gematriya(year).slice(2)}`,
            total: addCurrencySign(
              amount,
              currency && MappedCurrencies[currency],
            ),
            paid: addCurrencySign(paid, currency && MappedCurrencies[currency]),
            balance: addCurrencySign(
              balance,
              currency && MappedCurrencies[currency],
            ),
            unpaid,
            amount: balance,
            currency,
            isSelected: false,
            formik: null,
          } as BalanceRow;
        },
      );
      // Pay off months table
      formik.setFieldValue("balanceItems", nmb ?? []);
      setSelected(cp?.transaction_invoices?.map((ti) => Number(ti.invoice_id)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerMonthBalance, months, pageMode, cp]);

  useEffect(() => {
    if (
      customerMonthBalance?.length &&
      pageMode === "edit" &&
      cp &&
      cp?.id === +transactionId
    ) {
      const nmb: BalanceRow[] | null = customerMonthBalance.map(
        ({ id, month_id, amount, paid, unpaid, currency }, index) => {
          const month = months[month_id]?.hebrew ?? "";
          const year = months[month_id]?.year_id ?? "";
          const balance = (toFloat(amount) + toFloat(paid)).toFixed(2);
          let _amount = balance;
          //If page mode is edit, need to get the amount that the invoice was paid
          //off with this transaction
          if (cp.transaction_invoices) {
            const invoicePayments = cp.transaction_invoices?.filter(
              (ip) => ip.invoice_id === id,
            );
            const ipAmount = invoicePayments?.reduce((prev, curr) => {
              //curr.amount is negative number
              return prev + Math.abs(toFloat(curr?.applied_amount));
            }, 0);
            _amount = toFloat(ipAmount).toFixed(2);
          } else {
            _amount = toFloat(paid).toFixed(2);
          }
          return {
            id, //Edit mode id = id?
            index,
            month: `${month} ${gematriya(year).slice(2)}`,
            total: addCurrencySign(
              amount,
              currency && MappedCurrencies[currency],
            ),
            paid: addCurrencySign(paid, currency && MappedCurrencies[currency]),
            balance: addCurrencySign(
              balance,
              currency && MappedCurrencies[currency],
            ),
            unpaid,
            amount: _amount,
            currency,
            isSelected:
              pageMode === "edit" &&
              cp.transaction_invoices?.find((s) => s.invoice_id === id)
                ? true
                : false,
            formik: null,
          } as BalanceRow;
        },
      );
      // Pay off months table
      formik.setFieldValue("balanceItems", nmb ?? []);
      setSelected(cp?.transaction_invoices?.map((ti) => Number(ti.invoice_id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerMonthBalance, months, pageMode, cp]);

  const handleSelectionPayoffChange = (selectionModel: GridSelectionModel) => {
    const b = formik.values.balanceItems;
    if (b && Array.isArray(b) && !!b.length) {
      const nb = [...b].map((item) => {
        setSelected(selectionModel);

        return {
          ...item,
          isSelected: selectionModel.includes(item.id),
          amount: item.amount,
        };
      });
      formik.setFieldValue("balanceItems", nb);

      const remainingBalance = getRemainingBalance(nb);

      if (formik.values.touchedAmount && pageMode === "insert") {
        formik.setFieldValue("amount", remainingBalance.toNumber());
        formik.setFieldValue("remainingBalance", 0);
      } else
        formik.setFieldValue(
          "remainingBalance",
          BigNumber(toFloat(formik.values.amount))
            .minus(remainingBalance)
            .toNumber(),
        );
    }
  };

  return {
    selected,
    handleSelectionPayoffChange,
    balanceItems: balanceItems ?? [],
  };
}
