import { AppState } from "../types";

export const studentSelectors = {
  studentMonthData(state: AppState) {
    return state.student.studentMonthData;
  },
  setChaburahMonthData(state: AppState) {
    return state.student.chaburahMonthData;
  },
  redemptions(state: AppState) {
    return state.student.redemptions;
  },
  webApplicationMonths(state: AppState) {
    return state.student.webApplicationDetails.months;
  },
  webApplicationLivePrice(state: AppState) {
    return state.student.webApplicationDetails.live_price;
  },
  webApplicationRemotePrices(state: AppState) {
    return state.student.webApplicationDetails.remote_prices;
  },
};
