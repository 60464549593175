import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  adminActions,
  adminSelectors,
  listsSelectors,
  UserItem,
} from "../../../state";
import AdminPages from "../index";
import { GridRowParams, MuiEvent } from "@mui/x-data-grid-pro";

export function useUsersPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [users, setUsers] = useState<UserItem[]>([]);

  const _users = useSelector(adminSelectors.users);
  const countries = useSelector(listsSelectors.countryById);

  useEffect(() => {
    dispatch(adminActions.getUsers());
  }, [dispatch]);

  useEffect(() => {
    let newUsers: typeof users = [];

    if (_users?.rows && Array.isArray(_users.rows)) {
      newUsers = _users.rows.map(({ id, active, person, roles }) => {
        const homePhone =
          person?.phone_numbers?.filter((p) => p.type === "home")?.[0]
            ?.number ?? "";

        const cellPhone =
          person?.phone_numbers?.filter((p) => p.type === "cell")?.[0]
            ?.number ?? "";

        const addr = person?.address;
        const address = `${addr?.address || ""} ${addr?.address_2 || ""} ${
          addr?.city || ""
        } ${addr?.state || ""} ${addr?.zip || ""} ${
          countries[addr?.country_id ?? 0]?.hr_name || ""
        } `;

        return {
          id,
          isActive: active,
          name: `${person?.first_name} ${person?.last_name}`,
          nameYiddish: `${person?.first_name_yiddish ?? ""} ${
            person?.last_name_yiddish ?? ""
          }`,
          homePhone,
          cellPhone,
          address,
          roles: roles.map((r) => r.role).join(", "),
          // permissions: "",
        } as UserItem;
      });
    }

    setUsers(newUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_users]);

  const handleAddUser = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    navigate(AdminPages.createUsers.path);
  };

  const handleRowClick = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    event.stopPropagation();

    navigate(AdminPages.editUsers.path.replace(":id", String(params.id)));
  };

  return {
    users,
    handleAddUser,
    handleRowClick,
  };
}
