import { SxProps, Theme } from "@mui/material";

export const iconCircle = (color?: string): SxProps<Theme> => {
  return {
    ml: 3,
    height: "11px",
    width: "11px",
    color,
  };
};

export const nameEN: SxProps<Theme> = {
  fontSize: "12px",
  lineHeight: "17px",
  color: "#000000",
  opacity: 0.79,
};
