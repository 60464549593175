import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Menu, MenuItem, Fade, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { authSelectors } from "../../../state";

interface Props {
  id: string;
  studentId?: string;
  onEditClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onDoARefundClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  fromCustomer?: boolean;
}

export const AddPrizeButton: FC<Props> = () => {
  const navigate = useNavigate();
  const isRk = useSelector(authSelectors.isRK);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        <AddIcon sx={{ marginRight: 2 }} /> ADD
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {!isRk && (
          <MenuItem onClick={() => navigate(`/admin/prizes/product`)}>
            <AddIcon sx={{ marginRight: 2 }} color="primary" />
            <Typography variant="h6">ADD PRODUCTS</Typography>
          </MenuItem>
        )}
        {/*         <MenuItem onClick={() => navigate(`/admin/customer/add-payment/${id}`)}>
          <AddIcon sx={{ marginRight: 2 }} color="primary" />
          <Typography variant="h6">ADD ORDER</Typography>
        </MenuItem> */}
        <MenuItem onClick={() => navigate(`/admin/prizes/add-redemption`)}>
          <AddIcon sx={{ marginRight: 2 }} color="primary" />
          <Typography variant="h6">ADD REDEMPTION</Typography>
        </MenuItem>
        {/*         <MenuItem onClick={() => setShow(true)}>
          <Typography sx={{ width: 24, marginRight: "16px" }}></Typography>
          <Typography variant="h6">RESERVE/CHECKOUT</Typography>
        </MenuItem> */}
      </Menu>
    </div>
  );
};
