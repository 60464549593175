import React, { FC, useState } from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import { statuses } from "../../../../constants";
import { ActionsButton } from "../../components";
import { adminSelectors, listsSelectors } from "../../../../state";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { iconCircle, nameEN } from "./NameView.styles";
import AdminPages from "../../index";
import { ModalData } from "../../components/Modals/RefundModal/types";
import RefundModal from "../../components/Modals/RefundModal";

const NameView: FC = () => {
  const student = useSelector(adminSelectors.student);

  const navigate = useNavigate();
  const _statuses = useSelector(listsSelectors.statusById);

  const [modal, setModal] = useState<ModalData | null>(null);

  const handleModalClose = (_: typeof modal) => {
    setModal(null);
  };

  const handleDoARefundClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    setModal({
      customerId: student?.customer_id!,
      name: `${student?.customer?.first_name!} ${student?.customer?.last_name}`,
      nameYiddish: `${student?.customer?.first_name_yiddish!} ${
        student?.customer?.last_name_yiddish
      }`,
    });
  };

  const {
    first_name_yiddish,
    last_name_yiddish,
    first_name,
    last_name,
    status_id,
  } = student!;

  const status = _statuses[status_id].name;

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <IconButton
          color="primary"
          sx={{ mr: 1 }}
          onClick={() => navigate(AdminPages.student.path)}
        >
          <ArrowBackIcon sx={{ color: "#8495B2" }} />
        </IconButton>

        <Box>
          <Typography variant={"h3"} sx={{ color: "#082B65" }}>
            {`${first_name_yiddish} ${last_name_yiddish}`}
            <Tooltip title={statuses[status]?.toolTip || ""}>
              <CircleIcon sx={iconCircle(statuses[status]?.color)} />
            </Tooltip>
            {status === "draft" && (
              <Box component="span" fontSize={22} ml={1}>
                DRAFT
              </Box>
            )}
          </Typography>

          <Typography
            variant={"body2"}
            sx={nameEN}
          >{`${first_name} ${last_name}`}</Typography>
        </Box>
      </Stack>

      <ActionsButton
        id={student!.customer_id.toString()}
        studentId={student!.id.toString()}
        onDoARefundClick={handleDoARefundClick}
      />

      <RefundModal modal={modal} onClose={handleModalClose} />
    </Stack>
  );
};

export default NameView;
