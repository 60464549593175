import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerActions, customerSelectors } from "../../../state";
import { Page } from "../styles/CustomerPage.styles";
import { ToggleButtons } from "../../../components/buttons";
import { OldSystemPaymentTable, OldSystemInvoicesTable } from ".";
import { CurrencyCard } from "../components/AmountCard";
import { Grid } from "@mui/material";

export const CustomerOldSystemBilling = () => {
  const dispatch = useDispatch();
  const customer = useSelector(customerSelectors.customer) as ReturnType<
    typeof customerSelectors.customer
  >;
  const [tabValue, setTabValue]: any = useState("invoices");

  const handleToggleChange = useCallback((e) => {
    if (e?.target?.value) setTabValue(e.target.value);
  }, []);

  useEffect(() => {
    if (customer?.id) {
      dispatch(customerActions.getCustomerLegacyPayments(customer.id));
      dispatch(customerActions.getCustomerLegacyCharges(customer.id));
    }
  }, [customer?.id, dispatch]);

  return (
    <Page>
      <ToggleButtons
        value={tabValue}
        size="medium"
        onChange={handleToggleChange}
        toggleStyle={{ minWidth: 180, marginBottom: 16 }}
        buttonOptions={[
          { label: "Invoices", value: "invoices" },
          { label: "payment", value: "payment" },
        ]}
      />

      {tabValue === "invoices" ? (
        <>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={2}>
              <CurrencyCard
                label="OUTSTANDING BALANCE"
                currency={customer?.currency}
                amount={customer?.balance_old?.balance}
              />
            </Grid>
          </Grid>

          <OldSystemInvoicesTable />
        </>
      ) : (
        <OldSystemPaymentTable />
      )}
    </Page>
  );
};

export default CustomerOldSystemBilling;
