import React, { FC } from "react";
import { FormikProps } from "formik";
import { StudentEnrollmentFields } from "../schema";
import TabContainer from "../../components/TabContainer";
import { container } from "./TabEnrollment.styles";
import { Stack, Typography, Box } from "@mui/material";
import { useTabEnrollment } from "./useTabEnrollment";
import Select from "../../../../components/Select";
import { textField } from "../TabGeneral/TabGeneral.styles";
import { SystemStyleObject } from "@mui/system";
import CheckBox from "../../../../components/CheckBox";
import { StudentStatusToggle } from "../StudentStatusToggle";
// import { AddRoshKinyanModal } from "../../components";

interface Props {
  formik: FormikProps<StudentEnrollmentFields>;
  customerId: string;
  showOverride: boolean;
  pageMode: string;
  onDeactivate: Function;
}

const TabEnrollment: FC<Props> = ({
  formik,
  customerId,
  showOverride,
  pageMode,
  onDeactivate,
}) => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    chaburahs,
    teachers,
    years,
    months,
    currentStudentData,
  } = useTabEnrollment(formik, customerId, pageMode);
  if (!chaburahs || !years.length) return null;

  return (
    <TabContainer sx={{ p: "32px 55px" }}>
      {pageMode === "edit" && (
        <>
          <StudentStatusToggle
            student={currentStudentData}
            studentId={currentStudentData?.id}
            onDeactivate={onDeactivate}
          />
          <Typography variant={"h6"} sx={{ mt: 3 }}>
            Switch enrollment to
          </Typography>
        </>
      )}
      <Stack sx={container}>
        <Select
          id="chaburah_id"
          name="chaburah_id"
          sx={[
            textField as SystemStyleObject,
            { width: "360px" } as SystemStyleObject,
          ]}
          width={"unset"}
          label={"חבורה"}
          placeholder={"חבורה"}
          options={chaburahs}
          value={values.chaburah_id}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.chaburah_id && !!errors.chaburah_id}
          helperText={touched.chaburah_id && errors.chaburah_id}
        />
        <Select
          id="teacher_id"
          name="teacher_id"
          sx={[
            textField as SystemStyleObject,
            { width: "360px" } as SystemStyleObject,
          ]}
          width={"unset"}
          label="ראש קנין"
          placeholder={"ראש קנין"}
          options={teachers}
          value={values.teacher_id}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.teacher_id && !!errors.teacher_id}
          helperText={touched.teacher_id && errors.teacher_id}
        />
        {/* TODO Available months should only be avlbl chaburah months */}
        <Typography variant={"h6"} sx={{ mt: 3 }}>
          Start month
        </Typography>
        <Stack direction={"row"} justifyContent={"flex-start"} gap={1.5}>
          <Select
            id="year_id"
            name="year_id"
            sx={[
              textField as SystemStyleObject,
              { width: "175px" } as SystemStyleObject,
            ]}
            width={"unset"}
            label={"שנה"}
            placeholder={"שנה"}
            options={years}
            value={values.year_id}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Select
            id="month_id"
            name="month_id"
            sx={[
              textField as SystemStyleObject,
              { width: "175px" } as SystemStyleObject,
            ]}
            width={"unset"}
            label={"חודש"}
            placeholder={"חודש"}
            options={months}
            value={values.month_id}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.month_id && !!errors.month_id}
            helperText={touched.month_id && errors.month_id}
          />
        </Stack>
        {showOverride && (
          <Box
            sx={{
              pr: 5,
              width: "553px",
              height: "64px",
              background: "rgba(187,32,24,0.16) ",
              display: "flex",
            }}
          >
            <CheckBox
              name={"override"}
              checked={values.override}
              onChange={handleChange}
              sx={{ ml: 2 }}
            />{" "}
            <Typography sx={{ mt: 1 }}>
              You are attempting to edit a month that already has points or test
              entered. I am aware that these points will be deleted.
            </Typography>
          </Box>
        )}
      </Stack>
    </TabContainer>
  );
};

export default TabEnrollment;
