import { useEffect, useState } from "react";
import {
  useGridApiRef,
  GridRowId,
  gridVisibleSortedRowIdsSelector,
} from "@mui/x-data-grid-pro";

import { useNavigate, To } from "react-router-dom";
import {
  CustomFooter,
  DataGridAsync,
  SearchableToolbar,
} from "../../../components";
import { PRODUCT_COLUMNS } from "../../../constants";
import {
  prizeActions,
  prizeSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { dataGridFilterStyles } from "../../../themes";

export const Products = () => {
  const apiref = useGridApiRef();
  const navigate = useNavigate();
  const itemButtonClick = (to: To) => {
    navigate(`/admin/prizes/product/${to}`);
  };

  const handleClick = (e) => {
    itemButtonClick(e.id);
  };
  const [visibleRows, setVisibleRows] = useState<GridRowId[]>([]);
  const vendors = useSelector(prizeSelectors.vendors);
  const dispatch = useDispatch();
  useEffect(() => {
    if (vendors.uninitialized) {
      dispatch(prizeActions.getVendors());
    }
  }, [vendors, dispatch]);

  return (
    <DataGridAsync
      getRowHeight={() => "auto"}
      sx={{
        "& .MuiDataGrid-row": { cursor: "pointer" },
        "& .MuiDataGrid-cell": { paddingBottom: 1, marginTop: 1 },
      }}
      onStateChange={(state) => {
        const newRows = gridVisibleSortedRowIdsSelector(state);
        setVisibleRows(newRows);
      }}
      loadDataAction={prizeActions.getProducts}
      selector={prizeSelectors.products}
      pagination={true}
      apiRef={apiref}
      onRowClick={(e) => {
        handleClick(e);
      }}
      autoHeight
      columns={PRODUCT_COLUMNS(vendors)}
      components={{
        Footer: () => CustomFooter(visibleRows.length),
        Toolbar: () =>
          SearchableToolbar(apiref, {
            placeholder: "Search Products, or Prize Number...",
          }),
      }}
      initialState={{
        pagination: {
          pageSize: 25,
        },
        filter: {
          filterModel: {
            items: [
              {
                columnField: "active",
                operatorValue: "is",
                value: true,
              },
            ],
          },
        },
      }}
      componentsProps={{
        toolbar: { printOptions: { disableToolbarButton: true } },
        filterPanel: {
          linkOperators: [],
        },
        panel: {
          sx: {
            ...dataGridFilterStyles,
          },
        },
      }}
    />
  );
};
