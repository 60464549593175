import React from "react";

import { Typography, Card, CardContent } from "@mui/material";
import { getCurrencySymbol, numberWithCommas } from "../../../lib";

export const CurrencyCard = ({
  currency,
  amount,
  label,
  color = "",
  checkNegative = false,
}) => {
  return (
    <Card
      sx={{
        height: 84,
        border: "1px solid #DDE3EB",
        borderRadius: "8px",
      }}
    >
      <CardContent>
        <Typography variant="h6" component="span" color={color}>
          {checkNegative && amount < 0 && "-"}
          {getCurrencySymbol(currency)}
          {numberWithCommas(Math.abs(amount))}
        </Typography>
        <Typography variant="subtitle1">{label}</Typography>
      </CardContent>
    </Card>
  );
};
