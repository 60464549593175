import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";

//TODO: adjust for project
const ROLES = {
  ADMIN: "admin",
  USER: "user",
  TEACHER: "teacher",
  PRINCIPAL: "principal",
};

const _state = (state: AppState) => state.auth;
const _roles = (state: AppState) => state.auth.roles ?? [];
const _isAdmin = createSelector(_roles, (roles) => roles.includes(ROLES.ADMIN));
const _isRK = createSelector(
  _roles,
  (roles) => roles.length === 1 && roles.includes(ROLES.TEACHER),
);
const _isLimited = createSelector(
  _roles,
  (roles) =>
    (roles.includes(ROLES.PRINCIPAL) || roles.includes(ROLES.TEACHER)) &&
    !roles.includes(ROLES.ADMIN),
);
const _isLoggedIn = createSelector(_state, (state) => !!state.userId);

const _userId = createSelector(_state, (state) => state.userId ?? 0);
const _userName = createSelector(_state, (state) => state.userName ?? "");
const _userInfo = (state: AppState) => ({
  ...state.auth.user,
  ...state.auth.user?.person,
});

export const authSelectors = {
  isAdmin: _isAdmin,
  isRK: _isRK,
  isLimited: _isLimited,
  isLoggedIn: _isLoggedIn,
  roles: _roles,
  /** Selector used for routing */
  route: createSelector(_isLoggedIn, _roles, (isLoggedIn, roles) => ({
    isAdmin: roles.includes(ROLES.ADMIN),
    isLoggedIn,
    roles,
  })),
  /** Selector used for getting the entire auth state. */
  state: createSelector(
    _isAdmin,
    _isLoggedIn,
    _roles,
    _userId,
    _userName,
    (isAdmin, isLoggedIn, roles, userId, userName) => ({
      isAdmin,
      isLoggedIn,
      roles,
      userId,
      userName,
    }),
  ),
  userId: _userId,
  userName: _userName,
  userInfo: _userInfo,
};

export const AuthSelectors = authSelectors;
