import { SxProps, Theme } from "@mui/material";

export const captionSx: SxProps<Theme> = {
  color: "#000000",
  fontSize: "16px",
  letterSpacing: "0.07px",
  lineHeight: "18px",
};

export const btnSx: SxProps<Theme> = {
  minWidth: "140px",
};

export const content: SxProps<Theme> = {
  p: "32px 40px",
};

export const container: SxProps<Theme> = {
  minWidth: "500px",
};
