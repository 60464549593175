import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const gridItem: SxProps<Theme> = {
  width: "calc(50% - 40px)",
};

export const btnStyle = (error?: boolean): SxProps<Theme> => {
  return {
    mt: 2,
    border: `1px solid ${error ? "red" : "transparent"}`,
    width: "100%",
    "& .MuiButtonGroup-grouped": {
      height: "46px",
      minWidth: "50%",
    },
  };
};

export const textField: SxProps<Theme> = {
  mt: "16px!important",
  mb: "12px!important",

  "& .MuiInputLabel-root": {
    top: -4,
  },

  "& .MuiInputBase-input": {
    py: "12.5px",
  },

  "& textarea.MuiInputBase-input": {
    py: 0,
  },
};
