import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const gridStyles: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    borderBottom: "1px solid #DDE3EB",
    borderRadius: "8px",

    "& .MuiDataGrid-row": {
      cursor: "default",
    },
  },
};
