import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const textField: SxProps<Theme> = {
  mt: "16px!important",
  mb: "12px!important",

  "& .MuiInputLabel-root": {
    top: -4,
  },

  "& .MuiInputBase-input": {
    py: "12.5px",
  },

  "& textarea.MuiInputBase-input": {
    py: 0,
  },
};

export const gridSx: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    border: "unset",
  },
};
