import React, { FC } from "react";
import TabContainer from "../../components/TabContainer";
import { Box, Button, Stack } from "@mui/material";
import { useTabNeighborhoods } from "./useTabNeighborhoods";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { SETTINGS_NEIGHBORHOODS_COLUMNS } from "../../../../constants";
import { gridSx } from "./TabNeighborhoods.styles";
import AddNeighborhood from "./AddNeighborhood";
import { dataGridFilterStyles } from "../../../../themes";

const TabNeighborhoods: FC = () => {
  const {
    modal,
    setModal,
    neighborhoods,
    handleAddNeighborhood,
    handleEditNeighborhood,
  } = useTabNeighborhoods();

  return (
    <TabContainer>
      <Stack>
        <Button
          variant={"contained"}
          sx={{ alignSelf: "flex-end" }}
          onClick={handleAddNeighborhood}
        >
          Add neighborhood
        </Button>

        <Box sx={{ mt: 2 }}>
          <DataGridPro
            sx={gridSx}
            hideFooterRowCount={true}
            hideFooterSelectedRowCount={true}
            onRowClick={handleEditNeighborhood}
            rows={neighborhoods ?? []}
            autoHeight={true}
            columns={SETTINGS_NEIGHBORHOODS_COLUMNS}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
              },
              panel: {
                sx: {
                  ...dataGridFilterStyles,
                },
              },
            }}
          />
        </Box>
      </Stack>

      <AddNeighborhood modal={modal} onClose={() => setModal(null)} />
    </TabContainer>
  );
};

export default TabNeighborhoods;
