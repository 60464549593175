import { FC } from "react";
import { FormikProps } from "formik";
import { StudentBillingFields } from "../schema";
import { PaymentSavedCardBlock } from "../../../../components";
interface Props {
  formik: FormikProps<StudentBillingFields>;
  pageMode: string;
}

const BlockCreditCard: FC<Props> = ({ formik }) => {
  return (
    <>
      <PaymentSavedCardBlock formik={formik} />
    </>
  );
};

export default BlockCreditCard;
