import { DiscountType, NewPaymentCard, PaymentMethod } from "../../../../state";
import { object, string } from "yup";

export interface Fields {
  default_payment_method: PaymentMethod;
  cards: NewPaymentCard[] | null;
  discount: string;
  discount_type: DiscountType;
  discount_id: number | null;
  end_month_id?: number | null;
  reason?: string;
}

export const initialValues: Fields = {
  default_payment_method: "cash",
  cards: null,
  discount: "",
  discount_type: "amount",
  discount_id: null,
};

export const validationSchema = object().shape({
  default_payment_method: string().required().label("Payment method"),
  discount_type: string()
    .oneOf(["amount", "percentage"])
    .when("discount", {
      is: (discount) => !!discount,
      then: string().required("Discount type required"),
    }),
});
