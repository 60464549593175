import React, { useEffect, useRef, useState } from "react";
import {
  // REACT_APP_CARDKNOX_GBP_PUBLIC_KEY_ACCOUNT,
  REACT_APP_CARDKNOX_REMOTE_PUBLIC_KEY_ACCOUNT,
  // REACT_APP_CARDKNOX_LIVE_PUBLIC_KEY_ACCOUNT,
} from "../../../config";
import { Grid, LinearProgress, TextField, Box } from "@mui/material";
import { Iframe } from "../styles/AddCardModal.styles";
import { useFormikContext } from "formik";
import { format, isValid } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import { customerSelectors, useSelector } from "../../../state";
// import { ChaburahType } from "../ManageGroup/types";
const fieldStyle = {
  border: "1px solid #b4b8c8",
  outline: "none",
  borderRadius: "4px",
  fontSize: "16px",
  padding: "5px",
  height: "56px",
  width: "100%",
  marginTop: "0px",
  boxSizing: "border-box",
};

export const CreditCardForm = ({ FormRef }) => {
  const [validCC, setValidCC] = useState(false);
  const [validCvv, setValidCvv] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const customer = useSelector(customerSelectors.customer);
  const { values, errors, setFieldValue }: any = useFormikContext();
  const interval: any = useRef(null);
  const loadedRef = useRef(false);
  const validCcRef = useRef(false);
  const validCvvRef = useRef(false);
  const ccTokenRef: any = useRef(null);
  const cvvTokenRef: any = useRef(null);
  const [date, setDate] = useState(null);
  const processorError =
    "We are having trouble connecting to the processor, please try again later.";
  //Get public keys

  useEffect(() => {
    FormRef.current = { validateForm, getCKTokens, clearTokens };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      const {
        setAccount,
        getTokens,
        setIfieldStyle,
        addIfieldKeyPressCallback,
        enableAutoFormatting,
      } = window;
      if (
        setAccount &&
        customer &&
        getTokens &&
        setIfieldStyle &&
        addIfieldKeyPressCallback &&
        enableAutoFormatting
      ) {
        setIfieldStyle("cvv", fieldStyle);
        setIfieldStyle("card-number", fieldStyle);
        const merchantToken = calculateAccount();
        setAccount(`${merchantToken}`, "kinyan_mishna", "0.1.2");
        loadedRef.current = true;
        enableAutoFormatting("-");
        addIfieldKeyPressCallback((data) => {
          const { cardNumberIsValid, cvvIsValid } = data;
          setValidCC(Boolean(cardNumberIsValid));
          setValidCvv(Boolean(cvvIsValid));
          validCcRef.current = Boolean(cardNumberIsValid);
          validCvvRef.current = Boolean(cvvIsValid);
        });
        clearInterval(interval.current);
      }
    }, 100);
    return () => {
      clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const validateForm = () => {
    if (!loadedRef.current) return processorError;
    if (!validCcRef.current || !validCvvRef.current) {
      setFormSubmitted(true);
      return "Invalid Credit Card info";
    }
    return null;
  };

  const fetchTokens = () => {
    return new Promise((resolve, reject) => {
      window.getTokens(
        resolve,
        reject,
        30000, // timeout
      );
    });
  };

  const getCKTokens = async () => {
    try {
      await fetchTokens();
      const ccToken = ccTokenRef?.current?.value;
      const cvvToken = cvvTokenRef?.current?.value;
      return { ccToken, cvvToken };
    } catch (_e) {
      throw processorError;
    }
  };

  const calculateAccount = () => {
    // ifields are currently only used for adding cards
    // all cards will be added to the main account, and charged with cross-tokenization
    // if this changes, revisit this commented out code
    // if (customer.currency === "GBP")
    //   return REACT_APP_CARDKNOX_GBP_PUBLIC_KEY_ACCOUNT;
    // let hasLive = false;
    // customer.students.forEach((student: any) => {
    //   if (
    //     student.student_months[0]?.chaburah?.chaburah_type_id ===
    //     ChaburahType.Masmidim
    //   )
    //     hasLive = true;
    // });
    // return hasLive
    //   ? REACT_APP_CARDKNOX_LIVE_PUBLIC_KEY_ACCOUNT
    //   : REACT_APP_CARDKNOX_REMOTE_PUBLIC_KEY_ACCOUNT;
    return REACT_APP_CARDKNOX_REMOTE_PUBLIC_KEY_ACCOUNT;
  };

  useEffect(() => {
    if (values?.expiration === null) setDate(values.expiration);
  }, [values?.expiration, errors]);

  const clearTokens = () => {
    const { clearIfield } = window;

    if (clearIfield) {
      clearIfield("cvv");
      clearIfield("card-number");
    }
  };
  const [loadingCC, setLoadingCC] = useState(true);
  const [loadingCV, setLoadingCV] = useState(true);
  const finishLoadingCC = () => {
    setLoadingCC(false);
  };
  const finishLoadingCV = () => {
    setLoadingCV(false);
  };

  return (
    <>
      {loadingCC && loadingCV && (
        <Box sx={{ width: "100%", mt: "8px" }}>
          <LinearProgress />
        </Box>
      )}
      <Iframe
        props={{ invalid: formSubmitted && !validCC }}
        title="cc"
        data-ifields-id="card-number"
        data-ifields-placeholder="Credit card number"
        onLoad={finishLoadingCC}
        src="https://cdn.cardknox.com/ifields/2.13.2204.1401/ifield.htm"
      />
      <Grid container spacing={2} direction="row" justifyContent="flex-start">
        <Grid item xs={6}>
          <DatePicker
            inputFormat="MM/yy"
            label="Expiration"
            value={date}
            minDate={new Date()}
            onChange={(date: any) => {
              if (date && isValid(date)) {
                const result = format(date, "MMyy");
                setFieldValue("expiration", result);
              } else {
                setFieldValue("expiration", date);
              }
              setDate(date);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <Iframe
            props={{ invalid: formSubmitted && !validCvv }}
            title="cvv"
            data-ifields-id="cvv"
            data-ifields-placeholder="CVV"
            onLoad={finishLoadingCV}
            src="https://cdn.cardknox.com/ifields/2.13.2204.1401/ifield.htm"
          />
          <input
            data-ifields-id="cvv-token"
            name="xCVV"
            type="hidden"
            ref={cvvTokenRef}
          />
          <input
            data-ifields-id="card-number-token"
            name="xCardNum"
            type="hidden"
            ref={ccTokenRef}
          />
        </Grid>
      </Grid>
    </>
  );
};
