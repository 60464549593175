import { SxProps, Theme } from "@mui/material";

export const rewardCaption: SxProps<Theme> = {
  color: "#082B65",
  lineHeight: "36px",
};

export const bonusButton: SxProps<Theme> = {
  color: "#0F0190",
  fontSize: "16px",
  fontWeight: 600,
  letterSpacing: "0.2px",
  lineHeight: "22px",
  px: "30px",
  border: "1px solid #0F0190",
  borderRadius: "3px",
};

export const activityCaption: SxProps<Theme> = {
  color: "#072556",
  letterSpacing: 0,
  lineHeight: "24px",
  m: "16px 0 14px",
};
