import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { pointsRowStyle, pointsStyle, starIcon } from "./PointsRow.styles";
import { DoubleStarIcon, RemoveIcon } from "../../../../../components";

interface PointsRowProps {
  name: string;
  value: string;
  extraClassName?: string;
}

const PointsRow: FC<PointsRowProps> = ({ name, value, extraClassName }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={pointsRowStyle}
    >
      <Typography variant={"h6"} className={extraClassName}>
        {name}
      </Typography>

      {value ? (
        <Stack direction={"row"} alignItems={"center"}>
          <DoubleStarIcon sx={starIcon} />
          <Typography variant={"body2"} component={"span"} sx={pointsStyle}>
            {value}
          </Typography>
        </Stack>
      ) : (
        <RemoveIcon />
      )}
    </Stack>
  );
};

export default PointsRow;
