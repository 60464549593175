import React, { memo, useEffect } from "react";
import { Backdrop, CircularProgress, CssBaseline, Box } from "@mui/material";
import {
  authSelectors,
  listsActions,
  uiActions,
  uiSelectors,
  useSelector,
} from "../../state";
import PermanentDrawer from "./PermanentDrawer";
import Notification from "../../components/Notification";
import { Outlet, useLocation } from "react-router-dom";
import { MainContentStyled } from "./AdminLayout.styles";
import { useMobile } from "../../themes";
import MobileDrawer from "./MobileDrawer";
import MobileTopBar from "./MobileTopBar";
import { useDispatch } from "react-redux";
import { Navigation } from "../../lib";
import { AddCardModal } from "../../pages/admin/components";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { WarningIcon } from "../../components";
import { __PROD__ } from "../../config";

export const useStyles = makeStyles<Theme>(
  (theme) => ({
    loadingBackdrop: {
      backgroundColor: "transparent",
      zIndex: theme.zIndex.modal + 100,
    },
  }),
  {
    classNamePrefix: "AdminLayout",
  },
);

export const AdminLayout = memo(function () {
  const loading = useSelector(uiSelectors.loading);
  const ismobile = useMobile();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const showAddCardModal = useSelector(uiSelectors.showAddCardModal);
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    if (!isLoggedIn) return;
    dispatch(listsActions.getStatuses());
    dispatch(listsActions.getNeighborhoods());
    dispatch(listsActions.getAllTeachers());
    dispatch(listsActions.getChaburahTypes());
    dispatch(listsActions.getMonths());
    dispatch(listsActions.getTypes());
    dispatch(listsActions.getUsers());
    dispatch(listsActions.getNeighborhoodPrincipals());
    dispatch(listsActions.getAllPrincipals());
    dispatch(listsActions.getSemesters());
    dispatch(listsActions.getTracks());
    dispatch(listsActions.getLocations());
    dispatch(listsActions.getChaburahs());
    dispatch(listsActions.getCountries());
    dispatch(listsActions.getSchools());
    dispatch(listsActions.getSchoolBuildings());
    dispatch(listsActions.getSubjects());
  }, [isLoggedIn, dispatch]);
  useEffect(() => {
    if (location.pathname === "/admin" || location.pathname === "/admin/") {
      Navigation.go("/admin/customer");
    }
  }, [location.pathname]);
  return (
    <Box>
      <CssBaseline />
      {!__PROD__ && (
        <Box
          bgcolor="#F7E4E3"
          color="#F62527"
          textAlign="center"
          fontWeight={600}
          fontSize={14}
          textTransform="uppercase"
          padding="2px"
          position="relative"
        >
          <WarningIcon
            sx={{ fontSize: "12px", verticalAlign: "middle", mr: 0.5 }}
          />
          This staging site is for testing purposes only. Do not use this site
          for live data entry.
        </Box>
      )}
      <MobileTopBar show={ismobile} />
      {ismobile ? <MobileDrawer /> : <PermanentDrawer />}
      <MainContentStyled>
        <Outlet />
      </MainContentStyled>
      <Notification />
      {loading && (
        <Backdrop open={loading} className={classes.loadingBackdrop}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
      {showAddCardModal && (
        <AddCardModal
          handleClose={() => dispatch(uiActions.setShowAddCardModal(false))}
        />
      )}
    </Box>
  );
});
