import React, { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import { rowStyle } from "./Row.styles";

interface RowProps {
  name: string;
  value: number | ReactNode;
}

const Row: FC<RowProps> = ({ name, value }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={rowStyle}
    >
      <Typography variant={"caption"}>{name}</Typography>
      {typeof value === "string" ? (
        <Typography variant={"body2"}>{value}</Typography>
      ) : (
        value
      )}
    </Stack>
  );
};

export default Row;
