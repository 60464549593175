import { SxProps, Theme } from "@mui/material";

export const textField: SxProps<Theme> = {
  mt: "16px!important",
  mb: "12px!important",

  "& .MuiInputLabel-root": {
    top: -4,
  },

  "& .MuiInputBase-input": {
    py: "12px",
    textAlign: "right",
  },

  "& textarea.MuiInputBase-input": {
    py: 0,
  },
};
