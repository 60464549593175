import { SxProps, Theme } from "@mui/material";

export const container: SxProps<Theme> = {
  border: "1px solid #DDE3EB",
  borderRadius: 2,
  backgroundColor: "primary.contrastText",
  mt: 2,
  p: "20px 35px",
};

export const framedCaption: SxProps<Theme> = {
  borderRadius: "2px",
  backgroundColor: "secondary.main",
  padding: "3px 8px",
  color: "#001943",
  fontSize: "14px",
  fontWeight: 600,
  letterSpacing: "0.15px",
  lineHeight: "17px",
  textAlign: "right",
  display: "inline-block",
};
