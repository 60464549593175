import { useState } from "react";
import { useDispatch } from "react-redux";
import { useGridApiRef, GridSelectionModel } from "@mui/x-data-grid-pro";

import {
  REDEMPTION_ITEM_COLUMNS,
  REDEMPTION_ITEM_HIDDEN_COLUMNS,
} from "../../../constants";
import { prizeActions, prizeSelectors } from "../../../state";
import { DataGridAsync, SelectionToolbar } from "../../../components";
import { generateVisibleColumnsModel } from "../../../lib";
import { dataGridFilterStyles } from "../../../themes";

export const RedemptionItems = () => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const options = [
    { label: "Pending", value: false },
    { label: "Delivered", value: true },
  ];
  const [value, setValue] = useState<{ label: string; value: boolean }>();
  const [reloadVersion, setReloadVersion] = useState(0);
  const reload = () => setReloadVersion(reloadVersion + 1);

  const CustomToolbar = () =>
    SelectionToolbar({
      apiRef,
      options,
      selectionModel,
      bulkUpdateValues: () => {
        dispatch(
          prizeActions.updateBulkRedemptionItemStatus(
            {
              ids: selectionModel,
              status: value?.value,
            },
            reload,
          ),
        );
      },
      value,
      setValue,
      placeholder:
        "Search Products, Chaburah, Pin, נעמן, Phone or Neighborhood...",
    });
  return (
    <DataGridAsync
      getRowHeight={() => "auto"}
      sx={{
        "& .MuiDataGrid-row": { cursor: "pointer" },
        "& .MuiDataGrid-cell": { paddingBottom: 1, marginTop: 1 },
      }}
      pagination={true}
      apiRef={apiRef}
      autoHeight
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      columns={REDEMPTION_ITEM_COLUMNS}
      loadDataAction={prizeActions.getRedemptionItems}
      selector={prizeSelectors.redemptionItems}
      components={{
        Toolbar: CustomToolbar,
      }}
      disableSelectionOnClick
      initialState={{
        columns: {
          columnVisibilityModel: generateVisibleColumnsModel(
            REDEMPTION_ITEM_COLUMNS,
            REDEMPTION_ITEM_HIDDEN_COLUMNS.map((c) => c.field),
          ),
        },
        pagination: {
          pageSize: 25,
        },
      }}
      componentsProps={{
        toolbar: { printOptions: { disableToolbarButton: true } },
        filterPanel: {
          linkOperators: [],
        },
        panel: {
          sx: {
            ...dataGridFilterStyles,
          },
        },
      }}
    />
  );
};
