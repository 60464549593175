import { array, number, object, string } from "yup";
export const productSchema = (prizeNumbers: number[]) =>
  object().shape({
    name: string().required("Required"),
    name_yiddish: string()
      .test(
        "name_yiddish",
        "${label} must contain Hebrew characters",
        (val = "") => /[\u0590-\u05FF `’'",.-]+/.test(val),
      )
      .label("נאמען"),
    prize_number: number()
      .test("prize_number", "prize number must be above 0", (v) => !v || v > 0)
      .test(
        "prize_number_unique",
        "prize number must be unique",
        (v) => !v || prizeNumbers.indexOf(v) === -1,
      ),
    point_value: number()
      .required("Required")
      .test("point_value", "point value must be above 0", (v) => !!v && v > 0),
    cost: number()
      .nullable()
      .typeError("Cost value must be a number.")
      .test("cost", "Cost must be above 0", (v) => !v || v > 0),
    quantity: number()
      .typeError("Quantity must be a number.")
      .test("quantity", "Quantity must be above 0", (v) => !v || v > 0),
  });
export const redemptionSchema = object().shape({
  student_id: number().required("Required"),
  redemption_products: array().of(
    object().shape({
      amount: number()
        .nullable()
        .label("Amount")
        .test("hasAmount", "Card amount", (item, context) => {
          return !context.parent.is_amount || (!!item && item > 0);
        }),
    }),
  ),
});
