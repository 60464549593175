import React from "react";
import { IconButton, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { uiActions, useAction, useSelector } from "../state";
import { RootStateOrAny } from "react-redux";
const Notification = () => {
  const notification = useSelector(
    (state: RootStateOrAny) => state.ui.notification,
  );
  const hideNotification = useAction(uiActions.hideNotification);
  const HandleClose = (action, reason) => {
    if (reason === "clickaway") return;
    hideNotification();
  };

  return (
    <Snackbar
      open={!!notification}
      onClose={HandleClose}
      autoHideDuration={
        notification?.duration === undefined ? 5000 : notification.duration
      }
      message={notification?.message}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ whiteSpace: "pre-wrap" }}
    >
      {notification?.variant && (
        <Alert
          severity={notification.variant}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={hideNotification}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {notification.message}
        </Alert>
      )}
    </Snackbar>
  );
};
export default Notification;
