import { array, object, string } from "yup";
import { intRegExp } from "../../../../lib/regexp";

export interface Fields {
  month_id: number | null;
  answers_looked_in: string | number;
  tests: {
    index: number;
    id: number;
    extra_credit: boolean;
    answer: number;
    revised: string;
    answered: string;
    question_number: number;
    question: string;
  }[];
}

export const initialValues: Fields = {
  month_id: null,
  answers_looked_in: "",
  tests: [],
};

export const validationSchema = object().shape({
  answers_looked_in: string()
    .test(
      "answers_looked_in",
      "${label} must be a number",
      (val = "") => !val || intRegExp.test(val),
    )
    .label("Answers looked in"),
  tests: array()
    .min(1)
    .of(
      object().shape({
        revised: string()
          .test(
            "revised",
            "${label} must be a number",
            (val = "") => !val || intRegExp.test(val),
          )
          .label("Revised"),
        answered: string()
          .test("answered", "${label} must be a number", (val = "") =>
            intRegExp.test(val),
          )
          .label("Answered"),
      }),
    )
    .label("שאלה"),
});
