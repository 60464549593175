import { auth, AuthState } from "./auth/state";
import { ui, UIState } from "./ui/state";
import { admin, AdminState } from "./admin/state";

import { AppStateInfo } from "./types";
import { lists, ListsState } from "./lists/state";
import { customer, CustomerState } from "./customer/state";
import { student, StudentState } from "./student/state";
import { prizes, PrizesState } from "./prizes/state";

// Export Actions, Selectors, Types

export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./ui/actions";
export * from "./ui/selectors";

export * from "./admin/actions";
export * from "./admin/selectors";

export * from "./lists/actions";
export * from "./lists/selectors";

export * from "./customer/actions";
export * from "./customer/selectors";

export * from "./student/actions";
export * from "./student/selectors";

export * from "./prizes/actions";
export * from "./prizes/selectors";

export interface AppState {
  auth: AuthState;
  ui: UIState;
  admin: AdminState;
  customer: CustomerState;
  student: StudentState;
  lists: ListsState;
  prizes: PrizesState;
}

const states: AppStateInfo[] = [
  // Export States
  auth,
  ui,
  admin,
  customer,
  student,
  lists,
  prizes,
];
export default states;
