import React, { FC } from "react";
import { Stack, Typography, Grid } from "@mui/material";
import { container, framedCaption } from "./GroupInfo.styles";
import { Group } from "../../../../state";
import InfoItem from "./InfoItem";
import TeacherRow from "./TeacherRow";
import { useGroupInfo } from "./useGroupInfo";

interface Props {
  group: Group;
}

const GroupInfo: FC<Props> = ({ group }) => {
  const {
    teachers,
    groupType,
    students_count,
    track,
    location,
    neighborhood,
    principal,
  } = useGroupInfo(group);

  return (
    <Stack direction={"row"} sx={container} gap={2}>
      <Stack direction={"column"} sx={{ minWidth: "135px" }}>
        <InfoItem caption={"Type"} value={groupType} />
        <InfoItem caption={"Students"} value={students_count} />
      </Stack>

      <Stack direction={"column"} sx={{ minWidth: "190px" }}>
        <InfoItem caption={"קנין"} value={track} />
        <InfoItem caption={"Shul"} value={location} />
      </Stack>

      <Stack direction={"column"} sx={{ minWidth: "190px" }}>
        <InfoItem caption={"Neighborhood"} value={neighborhood} />
        <InfoItem
          caption={"מנהל"}
          value={
            !!principal && (
              <Typography sx={framedCaption}>{principal}</Typography>
            )
          }
        />
      </Stack>

      {teachers && !!teachers.length && (
        <Stack direction={"column"} sx={{ flexGrow: 1 }}>
          <InfoItem
            caption={"ראש קנין"}
            value={
              <Grid container>
                {teachers.map(({ neighborhood, name }, index) => (
                  <Grid xs={6} item key={index}>
                    <TeacherRow
                      key={index}
                      teacher={name}
                      neighborhood={neighborhood}
                    />
                  </Grid>
                ))}
              </Grid>
            }
          />
        </Stack>
      )}
    </Stack>
  );
};

export default GroupInfo;
