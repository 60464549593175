import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BillingHistory,
  Customer,
  List,
  MonthBalance,
  PaymentMethodItem,
  PrepayOptions,
  BillingDetails,
  CustomersTotals,
} from "../types";

export interface CustomerState {
  customers: List<Customer>;
  customer: Customer | null;
  customerMonthBalance: MonthBalance[] | null;
  customerPaymentOptions: PrepayOptions | null;
  customerBillingDetails: BillingDetails | null;
  upcomingbill: any;
  customerBillingIsNotFiltered: boolean;
  customerPayments: any;
  customerLegacyCharges: any;
  customerLegacyPayments: any;
  customersTotals: CustomersTotals;
}

const initialState: CustomerState = {
  customers: {
    count: 0,
    rows: [],
  },
  customer: null,
  customerBillingDetails: null,
  upcomingbill: null,
  customerBillingIsNotFiltered: false,
  customerMonthBalance: null,
  customerPaymentOptions: null,
  customerPayments: null,
  customerLegacyCharges: [],
  customerLegacyPayments: [],
  customersTotals: {
    active_count: "",
    usd_balance: "",
    gdp_balance: "",
  },
};

export const customer = {
  ...createSlice({
    name: "customer",
    initialState,
    reducers: {
      setCustomers(state, { payload }: PayloadAction<List<Customer>>) {
        state.customers = payload;
      },
      setCustomersTotals(state, { payload }: PayloadAction<any>) {
        state.customersTotals = payload;
      },
      setCustomer(state, { payload }: PayloadAction<Customer>) {
        state.customer = payload;
      },
      setCustomerBillingDetails(state, { payload }: PayloadAction<any>) {
        const billingState = {
          ...payload,
          student_billing: payload.students,
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { students, ...values } = billingState;
        state.customerBillingDetails = values;
      },
      setCustomerPayments(state, { payload }: PayloadAction<any>) {
        state.customerPayments = payload;
      },
      setCustomerLegacyCharges(state, { payload }: PayloadAction<any>) {
        state.customerLegacyCharges = payload;
      },
      setCustomerLegacyPayment(state, { payload }: PayloadAction<any>) {
        state.customerLegacyPayments = payload;
      },
      setCustomerCardInfo(
        state,
        { payload }: PayloadAction<PaymentMethodItem[]>,
      ) {
        if (!state.customer) {
          console.error("setting cust payment b4 cust set");
          return;
        }
        const customer = {
          ...state.customer,
          payment_methods: payload,
        };
        state.customer = customer;
      },
      setCustomerBillingHistory(
        state,
        { payload }: PayloadAction<BillingHistory[]>,
      ) {
        if (!state.customer) {
          console.error("setting cust billing history b4 cust set");
          return;
        }
        const customer = {
          ...state.customer,
          billing_history: payload,
        };
        state.customer = customer;
      },
      setCustomerBillingNextMonth(state, { payload }) {
        state.upcomingbill = payload;
      },
      setCustomerBillingIsNotFiltered(state, { payload }) {
        state.customerBillingIsNotFiltered = payload;
      },
      setCustomerMonthBalance(
        state,
        { payload }: PayloadAction<MonthBalance[]>,
      ) {
        state.customerMonthBalance = payload;
      },
      seCustomerPaymentOptions(
        state,
        { payload }: PayloadAction<PrepayOptions>,
      ) {
        state.customerPaymentOptions = payload;
      },
      setBillingHistory(state, { payload }: PayloadAction<BillingHistory[]>) {
        if (!state.customer) {
          console.error("setting cust billing history b4 cust set");
          return;
        }
        const customer = {
          ...state.customer,
          billing_history: payload,
        };
        state.customer = customer;
      },
    },
  }),
  persist: false,
};
