import { useCallback } from "react";
import AutoComplete from "react-google-autocomplete";
import { REACT_APP_GOOGLE_API_KEY } from "../../../config";
import { ErrorMessage } from "../../admin/components/AddressForm.styles";
import { listsSelectors, useSelector } from "../../../state";
import { googlePlaceToAddress, statesAsOptions } from "../../../lib";
import { Grid, Typography } from "@mui/material";
import { FormikInput, FormikSelect } from "../../../components";

export const AddressDetails = ({ formik }) => {
  const neighborhoods = useSelector(listsSelectors.neighborhoods);
  const countries = useSelector(listsSelectors.optionsCountries);
  const { setValues, values, handleChange, errors, submitCount } = formik;
  const _spacing = 4;
  const _size = 3;

  const { address: _address, ...rest } = values;

  const handleSelectAddress = useCallback(
    (googlePlace) => {
      const addressInfo = googlePlaceToAddress(googlePlace);
      if (!addressInfo) {
        return;
      }
      const country_id = countries.find(
        (c) => c.name === addressInfo.country,
      )?.id;

      setValues({
        ...values,
        address: addressInfo.address || "",
        address_2: "",
        city: addressInfo.city || "",
        country_id: country_id || "",
        state: addressInfo.state || "",
        zip: addressInfo.zip || "",
      });
    },
    [countries, setValues, values],
  );

  const preventFormSubmitOnEnter = useCallback((e) => {
    if (e.key === "Enter") e.preventDefault();
  }, []);

  return (
    <>
      <Typography fontSize={16} fontWeight="bold">
        Address
      </Typography>
      <Grid container spacing={_spacing}>
        <Grid item md={6}></Grid>
      </Grid>
      <Grid container spacing={_spacing}>
        <Grid item md={_size}>
          <AutoComplete
            key={JSON.stringify(rest)}
            apiKey={REACT_APP_GOOGLE_API_KEY}
            id="address"
            value={values.address || ""}
            className="google-autocomplete-input"
            style={{
              padding: "10px 8px",
              zIndex: 100,
              position: "relative",
              borderRadius: 4,
              fontSize: 16,
              width: "100%",

              color: "rgba(0, 0, 0, 0.87)",
              border:
                errors?.address && submitCount > 0
                  ? "1px solid #bb2018"
                  : "1px solid #B4B8C8",
              margin: "20px 0px",
            }}
            placeholder="Address line 1*"
            onKeyDown={preventFormSubmitOnEnter}
            onPlaceSelected={handleSelectAddress}
            size="small"
            onChange={handleChange}
            options={{
              types: [],
              fields: ["address_components"],
            }}
          />
          {errors?.address && submitCount > 0 && (
            <ErrorMessage>{errors.address}</ErrorMessage>
          )}
        </Grid>
        <Grid item md={_size}>
          <FormikInput
            name="address_2"
            label="Address line 2"
            size="small"
            formik={formik}
          />
        </Grid>

        <Grid item md={2.5}>
          <FormikInput name="city" size="small" formik={formik} />
        </Grid>

        <Grid item md={2}>
          <FormikSelect
            name="state"
            label={"State"}
            options={statesAsOptions}
            textFieldProps={{ size: "small" }}
            formik={formik}
          />
        </Grid>
        <Grid item md={1.5}>
          <FormikInput name="zip" size="small" formik={formik} />
        </Grid>
      </Grid>
      <Grid container spacing={_spacing}>
        <Grid item md={_size}>
          <FormikSelect
            label="Country*"
            name="country_id"
            options={countries}
            textFieldProps={{ size: "small" }}
            formik={formik}
          />
        </Grid>
        <Grid item md={_size}>
          <FormikSelect
            label="Neighborhood"
            name="neighborhood_id"
            options={neighborhoods}
            textFieldProps={{ size: "small" }}
            formik={formik}
          />
        </Grid>
      </Grid>
    </>
  );
};
