import { useDispatch, useSelector } from "react-redux";
import {
  Group,
  listsActions,
  listsSelectors,
  TeacherItem,
} from "../../../../state";
import { useEffect, useState } from "react";

interface TeacherView {
  name: string;
  neighborhood: string;
}

function getGroupType(id: number): string {
  switch (id) {
    case 1:
      return "Masmidim";
    case 2:
      return "CD";
    default:
      return "";
  }
}

export function useGroupInfo(group: Group) {
  const {
    id,
    chaburah_type_id,
    students_count,
    track_id,
    location_id,
    neighborhood_id,
  } = group;

  const dispatch = useDispatch();

  const tracks = useSelector(listsSelectors.trackById);
  const locations = useSelector(listsSelectors.locationById);
  const neighborhoods = useSelector(listsSelectors.neighborhoodById);
  const users = useSelector(listsSelectors.userById);
  const neighborhoodPrincipal = useSelector(
    listsSelectors.neighborhoodPrincipalById,
  );

  const [teachers, setTeachers] = useState<TeacherView[] | null>(null);

  const principal =
    neighborhoodPrincipal[neighborhood_id ?? 0]?.name_yiddish ?? "";

  useEffect(() => {
    (async () => {
      if (group?.id) {
        const tchrs = await dispatch(
          listsActions.getTeachers(group.id.toString()),
        );

        if (Array.isArray(tchrs)) {
          const tv = (tchrs as unknown as TeacherItem[]).map(
            ({ neighborhood_id, chaburah_teacher: { user_id } }) => {
              return {
                name: users[user_id]?.name_yiddish ?? " ",
                neighborhood: neighborhoods[neighborhood_id ?? 0]?.name ?? "",
              } as TeacherView;
            },
          );

          setTeachers(tv);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    teachers,
    groupType: getGroupType(chaburah_type_id),
    students_count,
    track: tracks[track_id]?.name ?? "",
    location: location_id && locations[location_id]?.name,
    neighborhood: neighborhoods[neighborhood_id ?? 0]?.name ?? "",
    principal,
  };
}
