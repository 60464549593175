import React, { FC } from "react";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import { useManageGroup } from "./useManageGroup";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import {
  bottomSectionStyle,
  tabsStyle,
  topSectionStyle,
} from "./ManageGroup.styles";
import { TabType } from "./types";
import Buttons from "./Buttons";
import SimplePageHeader from "../../../components/SimplePageHeader";
import ConfirmModal from "./ConfirmModal";

const ManageGroup: FC = () => {
  const {
    pageCaption,
    isInsertMode,
    tab,
    modal,
    handleTabChange,
    TabComponent,
    handleBtnClick,
    handleBackButtonClick,
    handleModalClose,
  } = useManageGroup();

  return (
    <Page>
      <TopSection style={topSectionStyle}>
        <SimplePageHeader
          title={pageCaption}
          onBackButtonClick={handleBackButtonClick}
        />
      </TopSection>

      <BottomSection style={bottomSectionStyle}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={tabsStyle(isInsertMode)}
        >
          <Tab
            label="Details"
            value={"details" as TabType}
            disableRipple={isInsertMode}
          />
          <Tab
            label="ראש קנין"
            value={"roshKinyan" as TabType}
            disableRipple={isInsertMode}
          />
          <Tab
            label="Curriculum"
            value={"curriculum" as TabType}
            disableRipple={isInsertMode}
          />
          {/* <Tab
            label="Months"
            value={"months" as TabType}
            disableRipple={isInsertMode}
          /> */}
        </Tabs>

        <Box sx={{ width: "720px" }}>
          {TabComponent}

          <Divider sx={{ my: 1.5 }} />

          <Buttons tab={tab} onClick={handleBtnClick} />
        </Box>
      </BottomSection>

      <ConfirmModal modal={modal} onClose={handleModalClose} />
    </Page>
  );
};

export default ManageGroup;
