import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const container: SxProps<Theme> = {
  mb: "20px",

  "& > .MuiIconButton-root": {
    mr: 1,

    "& > .MuiSvgIcon-root": {
      color: "#000000",
    },
  },

  "& > .MuiTypography-h6": {
    color: "#082B65",
    letterSpacing: "0.19px",
    lineHeight: "21px",
  },
};
