import { SxProps, Theme } from "@mui/material";

export const container: SxProps<Theme> = {
  p: 2,
  mb: 4,
};

export const caption: SxProps<Theme> = {
  color: "#082B65",
  fontWeight: "bold",
  letterSpacing: "0.1px",
  lineHeight: "16px",
  mb: "12px",
};
