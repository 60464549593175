import { CSSProperties } from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const topSectionStyle: CSSProperties = {
  marginTop: 0,
  paddingTop: 24,
  height: "124px",
  borderBottom: "1px solid #DDE3EB",
};

export const bottomSectionStyle: CSSProperties = {
  marginTop: "-49px",
  paddingTop: 0,
};

export const tabsStyle: SxProps<Theme> = {
  px: 2,

  "& .MuiTabs-flexContainer": {
    "& > .MuiTab-root": {
      color: "#5C738E",
      fontSize: "16px",
      letterSpacing: "0.15px",
      lineHeight: "24px",

      "&.Mui-selected": {
        color: "#082B65",
      },
    },
  },
};
