import { object, string } from "yup";

export interface Fields {
  name: string;
  user_id: string;
}

export const initialValues: Fields = {
  name: "",
  user_id: "",
};

export const validationSchema = object().shape({
  name: string().required("Required").label("Neighborhood name"),
  user_id: string().required("Required").label("מנהל"),
});
