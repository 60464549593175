import { InputBase } from "@mui/material";
import { styled } from "@mui/styles";

export const Page = styled(`div`)(() => ({
  minWidth: "100%",
  minHeight: "100%",
}));

export const TopSection = styled(`div`)(() => ({
  height: 178,
  backgroundColor: "#F3F6F9",
  marginTop: 25,
  paddingLeft: 25,
  paddingRight: 25,
}));
export const TopSectionGroup = styled(`div`)(() => ({
  height: 164,
  backgroundColor: "#F3F6F9",
  marginTop: 25,
  paddingLeft: 25,
  paddingRight: 25,
}));
export const BottomSection = styled(`div`)(() => ({
  paddingLeft: 25,
  paddingRight: 25,
  marginBottom: 25,
  paddingTop: 64,
}));
export const MiddleSection = styled(`div`)(() => ({
  paddingLeft: 25,
  paddingRight: 25,
  marginTop: -90,
}));
export const Search = styled("div")(() => ({
  position: "relative",
  height: "48px",
  width: "400px",
  border: "1px solid #DDE3EB",
  borderRadius: "4px",
  backgroundColor: "#fff",
  marginTop: 16,
  zIndex: 1,
}));
export const WideSearch = styled("div")(() => ({
  position: "relative",
  height: "48px",
  width: "550px",
  border: "1px solid #DDE3EB",
  borderRadius: "4px",
  backgroundColor: "#fff",
  marginTop: 16,
  zIndex: 1,
}));

export const StyledInputBase = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    marginLeft: 16,
    width: "350px",
    paddingBottom: 2,
  },
}));
export const WideStyledInputBase = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    marginLeft: 16,
    width: "500px",
    paddingBottom: 2,
  },
}));
