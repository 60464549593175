import React, { useCallback, useEffect, useState } from "react";
import { deepCopy, toFloat, toInt } from "../../../lib";
import AdminPages from "../index";
import { useNavigate } from "react-router-dom";
import { Option } from "../../../components/Select/Select";
import {
  customerActions,
  customerSelectors,
  DepositType,
  listsSelectors,
  PaymentMethod,
  Prepay,
  PrepayOptions,
  PrepayPayment,
} from "../../../state";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import {
  initPaymentState,
  NewPaymentFields,
  validationPaymentSchema,
} from "./schema";
import {
  buildEditedPayment,
  buildNewPayment,
  MappedPaymentMethod,
  MappedPaymentType,
  usePageMode,
} from "./utils";
import { GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import { buildYearsOptions } from "../ChangeStudentPage/utils";
import {
  DataGridPro,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  gridPrepayPrepaymentSx,
  panelWrapper,
} from "./AddNewPaymentPage.styles";
import { PREPAY_PREPAYMENT_COLUMNS } from "../../../constants";
import useApplyPayment from "./useApplyPayment";
import { dataGridFilterStyles } from "../../../themes";

export default function useAddNewPaymentPage() {
  const apiRef = useGridApiRef();

  const { id, transactionId, pageMode } = usePageMode();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _customer = useSelector(customerSelectors.customer);
  const customerPaymentOptions = useSelector(
    customerSelectors.customerPaymentOptions,
  );

  const _semesters = useSelector(listsSelectors.semesters);
  const semesterById = useSelector(listsSelectors.semesterById);

  const [years, setYears] = useState<Option[]>([]);
  const [invoiceAmount, setInvoiceAmount] = useState<string>("");
  const [semesterTypes] = useState<Option[]>(() => {
    return [
      {
        label: "ווינטער",
        value: "winter",
      },
      {
        label: "זיממער",
        value: "summer",
      },
    ];
  });

  const [modalData, setModalData] = useState<{
    id: string;
    cardId: string;
  } | null>(null);

  const [deleteData, setDeleteData] = useState<number | null>(null);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [initialValues, setInitialValues] = useState(initPaymentState);
  const cp = useSelector(customerSelectors.customersPayments);

  const handleSubmit = async (values: NewPaymentFields) => {
    if (pageMode === "insert") {
      const data = buildNewPayment(values, _semesters);
      if (data) {
        dispatch(
          customerActions.createNewCustomerPayment(data, handleBackButtonClick),
        );
      } else {
        console.error("Missing data");
      }
    }

    if (pageMode === "edit") {
      const data = buildEditedPayment(values, _semesters);
      if (data) {
        const isOk = dispatch<
          boolean | ReturnType<typeof customerActions.updateCustomerPayment>
        >(customerActions.updateCustomerPayment(transactionId, data));

        isOk && handleBackButtonClick();
      } else {
        console.error("Missing data");
      }
    }
  };

  const formik = useFormik<NewPaymentFields>({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationPaymentSchema,
    onSubmit: handleSubmit,
  });

  const buildPrepayData = (_npo: PrepayOptions) => {
    const npo = deepCopy(_npo) as PrepayOptions;

    npo?.prepay?.forEach((pp) => {
      const prevPaid = toFloat(formik.values.prepayOptions?.prevPaid, 0);

      pp.discount =
        (formik.values.paymentMethod === "cash"
          ? npo?.discount?.prepay_discount_cash
          : npo?.discount?.prepay_discount_credit) ?? 0;
      pp.isSelected = false;
      pp.payments = [];

      let before = toFloat(pp.total) - prevPaid;
      let discount = (before * pp.discount) / 100;
      const p1: PrepayPayment = {
        id: 1,
        name: "Prepayment",
        before,
        discount,
        after: before - discount,
      };
      pp.payments.push(p1);

      before = toFloat(pp.price);
      discount = (before * pp.discount) / 100;
      const p2: PrepayPayment = {
        id: 2,
        name: "Monthly",
        before,
        discount,
        after: before - discount,
      };
      pp.payments.push(p2);

      pp.balance = pp.payments?.[0]?.after ?? 0;
    });

    return npo;
  };

  useEffect(() => {
    if (pageMode === "insert") {
      if (!_customer.id) return;
      const newValues = {
        ...initialValues,
        customer_id: _customer.id.toString(),
        currency: _customer.currency,
      };
      setInitialValues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_customer, id]);

  useEffect(() => {
    if (transactionId) {
      dispatch(customerActions.getCustomersPayments(transactionId));
    }
  }, [dispatch, transactionId]);

  useEffect(() => {
    (async () => {
      if (
        cp?.id === +transactionId &&
        pageMode === "edit" &&
        !!id &&
        !!transactionId
      ) {
        const paramsRow = cp.transaction_invoices
          ?.map((ti) => `invoice_ids=${ti.invoice_id}`)
          .join("&");
        setInvoiceAmount(toFloat(cp.amount).toFixed(2));
        await formik.setValues({
          ...formik.values,
          amount: toFloat(cp.amount).toFixed(2),
          currency: cp.currency ?? "",
          paymentType: MappedPaymentType[cp.transaction_type_id],
          date: new Date(cp.date?.replaceAll("-", "/")),
          yearId: semesterById[cp.semester_id ?? 0]?.year_id ?? "",
          semesterTypeId: semesterById[cp.semester_id ?? 0]?.name ?? "",
          paymentMethod: MappedPaymentMethod[cp.transaction_type_id],
          checkNumber: cp.identifier,
          note: cp.note,
          paramsRow: paramsRow ?? "",
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    pageMode,
    transactionId,
    cp?.id,
    cp?.note,
    cp?.amount,
    cp?.currency,
    cp?.transaction_type_id,
    cp?.date,
  ]);

  useEffect(() => {
    setYears(buildYearsOptions(_semesters));
  }, [_semesters]);

  useEffect(() => {
    // Prepayment and discount table
    formik.setFieldValue(
      "prepayOptions",
      (customerPaymentOptions && buildPrepayData(customerPaymentOptions)) ??
        null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPaymentOptions]);

  useEffect(() => {
    if (
      formik.values.paymentType === "prepayment" &&
      formik.values.prepayOptions?.prepay
    ) {
      const ids = formik.values.prepayOptions?.prepay?.reduce<number[]>(
        (acc, curr) => {
          acc.push(curr?.student?.id ?? 0);

          return acc;
        },
        [],
      );

      if (apiRef?.current && ids) {
        apiRef?.current?.setExpandedDetailPanels(ids);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.paymentType]);

  useEffect(() => {
    (async () => {
      const npo = JSON.parse(
        JSON.stringify(formik.values.prepayOptions),
      ) as PrepayOptions;

      buildPrepayData(npo);

      await formik.setFieldValue("prepayOptions", npo ?? null);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.paymentMethod]);

  const handleBackButtonClick = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();

      !!id &&
        toInt(id) &&
        navigate(AdminPages.customer.path.replace(":id", id));
    },
    [navigate, id],
  );

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteData(null);
  }, []);

  const deleteFunction = useCallback(async () => {
    if (deleteData) {
      const data = await dispatch<
        boolean | ReturnType<typeof customerActions.deleteCustomerPayment>
      >(customerActions.deleteCustomerPayment(transactionId));

      !data && handleBackButtonClick();
      handleBackButtonClick();
    }
  }, [dispatch, deleteData, transactionId, handleBackButtonClick]);

  const handlePaymentType = (
    paymentType: DepositType,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    formik.setFieldValue("paymentType", paymentType);
  };

  const handleDatePick = (date: Date | null) => {
    formik.setFieldValue("date", date);
  };
  const handleAmount = (e) => {
    formik.setFieldValue("touchedAmount", false);
    formik.setFieldValue("amount", e.target.value);
    const remainingBalance =
      formik.values.balanceItems?.reduce((prev, curr) => {
        if (curr.isSelected) {
          //curr.amount is negative number
          return prev + Math.abs(toFloat(curr.amount));
        }
        return prev;
      }, 0) ?? 0;
    formik.setFieldValue(
      "remainingBalance",
      toFloat(e.target.value) - remainingBalance,
    );
  };

  const handlePaymentMethod = (
    method: PaymentMethod,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    formik.setFieldValue("paymentMethod", method);
  };

  const handleBtnClick = useCallback(
    async (
      type: "cancel" | "save" | "delete",
      e: React.MouseEvent<HTMLButtonElement>,
    ) => {
      e.stopPropagation();

      switch (type) {
        case "save":
          await formik.submitForm();
          break;

        case "cancel":
          handleBackButtonClick(e);
          break;

        case "delete":
          !!transactionId &&
            toInt(transactionId) &&
            setDeleteData(+transactionId);
          break;
      }
    },
    [formik, handleBackButtonClick, transactionId],
  );

  const handleSelectionPrepaymentChange = (
    selectionModel: GridSelectionModel,
  ) => {
    const b = formik.values.prepayOptions;

    if (b && Array.isArray(b?.prepay) && !!b.prepay.length) {
      b.prepay = [...b.prepay].map((item) => {
        return {
          ...item,
          discount: b.discount?.prepay_discount_credit ?? 0,
          isSelected: selectionModel.includes(item.student.id ?? 0),
        } as Prepay;
      });

      formik.setFieldValue("prepayOptions", b);
    }
  };

  const handleCloseAddCardModal = useCallback(
    async (e?: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();

      setShowAddCardModal(false);

      //await getCardsList();
    },
    [],
  );

  const handleCloseModal = useCallback(() => {
    setModalData(null);
  }, []);

  const setModalCard = useCallback((customer_id, cardId) => {
    setModalData({
      id: customer_id,
      cardId,
    });
  }, []);

  const deleteCardFunction = useCallback(() => {
    if (modalData) {
      dispatch(
        customerActions.deleteCustomerCard(modalData.id, modalData.cardId),
      );
    }
  }, [dispatch, modalData]);

  const getDetailPanelHeight = React.useCallback(
    (): number | "auto" => "auto",
    [],
  );

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams<Prepay>) => (
      <Box sx={panelWrapper}>
        <DataGridPro
          sx={gridPrepayPrepaymentSx}
          disableSelectionOnClick={true}
          hideFooterSelectedRowCount={true}
          hideFooterRowCount={true}
          rows={row.payments ?? []}
          autoHeight={true}
          columns={PREPAY_PREPAYMENT_COLUMNS}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
            panel: {
              sx: {
                ...dataGridFilterStyles,
              },
            },
          }}
        />
      </Box>
    ),
    [],
  );
  const { selected, handleSelectionPayoffChange, balanceItems } =
    useApplyPayment(formik);

  return {
    apiRef,
    pageMode,
    modalData,
    formik,
    balanceItems,
    years,
    invoiceAmount,
    semesterTypes,
    handleBackButtonClick,
    handlePaymentType,
    handleDatePick,
    handleAmount,
    handlePaymentMethod,
    handleBtnClick,
    handleSelectionPayoffChange,
    selected,
    handleSelectionPrepaymentChange,
    showAddCardModal,
    handleCloseAddCardModal,
    handleCloseModal,
    deleteCardFunction,
    getDetailPanelHeight,
    getDetailPanelContent,
    setModalCard,
    deleteData,
    handleCloseDeleteModal,
    deleteFunction,
    cardType: cp?.card_type || "",
    chargedCard: cp?.masked
      ? "****" + cp.masked.substring(cp.masked.length - 4)
      : undefined,
  };
}
