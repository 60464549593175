import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { caption, container } from "./Section.styles";

interface Props {
  name: string;
}

const Section: FC<Props> = ({ name, children }) => {
  return (
    <Box sx={container}>
      <Typography variant={"body2"} sx={caption}>
        {name}
      </Typography>

      <Box>{children}</Box>
    </Box>
  );
};

export default Section;
