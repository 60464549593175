import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const container: SxProps<Theme> = {
  width: "720px",
  pb: 2,
  borderBottom: "1px solid #D5D8EC",
};

export const textFieldStyles: SxProps<Theme> = {
  width: "calc(100% - 40px)",
  mt: "16px!important",
  mb: "12px!important",

  "& .MuiInputLabel-root": {
    top: -4,
  },

  "& .MuiInputBase-input": {
    py: "12.5px",
  },

  "& textarea.MuiInputBase-input": {
    py: 0,
  },
};

export const gridStyles: SxProps<Theme> = {
  maxWidth: "800px",
};

export const btnStyle = (error?: boolean): SxProps<Theme> => {
  return {
    border: `1px solid ${error ? "red" : "transparent"}`,
    "& .MuiButtonGroup-grouped": {
      minWidth: "140px",
    },
  };
};
