import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";

interface PointsCardProps {
  header: string;
  secondary?: string;
  // secondaryPercent?: string;
  points: any;
  subtitle?: string;
}

const ScoreDetails: FC<PointsCardProps> = ({
  header,
  points,
  secondary,
  // secondaryPercent,
  subtitle,
}) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-around"}
      sx={{ mb: "15px", ml: "25px", mr: "40px" }}
    >
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        sx={{ mb: "15px", width: "180px" }}
      >
        <Stack direction={"row"} alignItems={"center"} sx={{ width: "180px" }}>
          <Typography variant={"body1"}>{header}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} sx={{ width: "180px" }}>
          {subtitle && <Typography variant={"caption"}>{subtitle}</Typography>}
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: "40px" }}
      >
        {secondary && <Typography variant={"body1"}>{secondary}</Typography>}
      </Stack>
      {/* <Stack direction={"row"} alignItems={"center"} sx={{ width: "40px" }}>
        {secondaryPercent && (
          <Typography variant={"body1"}>{`${secondaryPercent}%`}</Typography>
        )}{" "}
      </Stack> */}

      <Stack direction={"row"} alignItems={"center"} sx={{ width: "40px" }}>
        <Typography variant={"h6"} component={"span"} sx={{ color: "#F0921F" }}>
          {points}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ScoreDetails;
