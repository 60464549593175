import { AddNewCustomerPage } from "./AddNewCustomerPage";
import EditCustomerPage from "./EditCustomerPage";
import { CustomersPage } from "./CustomersPage";
import { StudentsPage } from "./StudentsPage";
import { BillingPage } from "./BillingPage";
import { AddProduct } from "./PrizesPage/AddProduct";
import { AddRedemption } from "./PrizesPage/AddRedemption";
import PrizesPage from "./PrizesPage/PrizesPage";
import SettingsPage from "./SettingsPage/index";
import { StudentMonthsPage } from "./StudentMonthsPage";
import StudentPage from "./StudentPage";
import GroupPage from "./GroupPage/index";
import ProfilePage from "./ProfilePage/index";
import ChangeStudentPage from "./ChangeStudentPage";
import GroupDetails from "./GroupDetails";
import ManageGroup from "./ManageGroup";
import AddNewPaymentPage from "./AddNewPaymentPage";
import CustomerPage from "./CustomerPage";
import ManageUserPage from "./ManageUserPage";
import UsersPage from "./UsersPage/index";
import GroupSettingsPage from "./GroupSettingsPage";

export const AdminPages = {
  dashboard: {
    anon: false,
    path: "/admin/customer",
    title: "Customers",
    view: CustomersPage,
  },
  customer: {
    anon: false,
    path: "/admin/customer/:id",
    title: "Customer",
    view: CustomerPage,
  },
  addPayment: {
    anon: false,
    path: "/admin/customer/add-payment/:id",
    title: "Customer",
    view: AddNewPaymentPage,
  },
  editPayment: {
    anon: false,
    path: "/admin/customer/edit-payment/:id",
    title: "Customer",
    view: AddNewPaymentPage,
  },
  addNewCustomer: {
    anon: false,
    path: "/admin/customer/add-new",
    title: "Add New Customer",
    view: AddNewCustomerPage,
  },
  editCustomer: {
    anon: false,
    path: "/admin/customer/edit-customer/:id",
    title: "Edit Customer",
    view: EditCustomerPage,
  },
  student: {
    anon: false,
    path: "/admin/student",
    title: "Students",
    view: StudentsPage,
  },
  studentPage: {
    anon: false,
    path: "/admin/student/:id",
    title: "Student page",
    view: StudentPage,
  },
  addNewStudent: {
    anon: false,
    path: "/admin/student/add-new/:id",
    title: "Add a student",
    view: ChangeStudentPage,
  },
  addNewStudentFromCustomer: {
    anon: false,
    path: "/admin/student/add-new/:id/from-customer",
    title: "Add a student",
    view: ChangeStudentPage,
  },
  editStudent: {
    anon: false,
    path: "/admin/student/edit-student/:id",
    title: "Edit Student",
    view: ChangeStudentPage,
  },
  studentMonth: {
    anon: false,
    path: "/admin/student-month",
    title: "Student months",
    view: StudentMonthsPage,
  },
  groups: {
    anon: false,
    path: "/admin/groups",
    title: "Groups",
    view: GroupPage,
  },
  groupDetails: {
    anon: false,
    path: "/admin/groups/:id",
    title: "Group details",
    view: GroupDetails,
  },
  editGroup: {
    anon: false,
    path: "/admin/edit-group/:id",
    title: "Edit group",
    view: ManageGroup,
  },
  createGroup: {
    anon: false,
    path: "/admin/create-group",
    title: "Add a group",
    view: ManageGroup,
  },
  groupSettings: {
    anon: false,
    path: "/admin/groups-settings",
    title: "Group Settings",
    view: GroupSettingsPage,
  },
  billing: {
    anon: false,
    path: "/admin/billing",
    title: "Billing",
    view: BillingPage,
    //TODO: set up page role checks
    roles: ["admin"],
  },
  prizes: {
    anon: false,
    path: "/admin/prizes",
    title: "Products",
    view: PrizesPage,
    roles: ["admin"],
  },
  redemptions: {
    anon: false,
    path: "/admin/prizes?tab=redemptions",
    title: "Redemptions",
    view: PrizesPage,
  },
  addNewProduct: {
    anon: false,
    path: "/admin/prizes/product",
    title: "Add a product",
    view: AddProduct,
  },
  editProduct: {
    anon: false,
    path: "/admin/prizes/product/:id",
    title: "Edit product",
    view: AddProduct,
  },
  addRedemption: {
    anon: false,
    path: "/admin/prizes/add-redemption",
    title: "Add a redemption",
    view: AddRedemption,
  },
  editRedemption: {
    anon: false,
    path: "/admin/prizes/redemption/:id",
    title: "Edit a redemption",
    view: AddRedemption,
  },
  settings: {
    anon: false,
    path: "/admin/settings",
    title: "Settings",
    view: SettingsPage,
    roles: ["admin"],
  },
  users: {
    anon: false,
    path: "/admin/users",
    title: "Users",
    view: UsersPage,
    roles: ["admin"],
  },
  createUsers: {
    anon: false,
    path: "/admin/users/create",
    title: "Create User",
    view: ManageUserPage,
    roles: ["admin"],
  },
  editUsers: {
    anon: false,
    path: "/admin/users/:id",
    title: "Edit User",
    view: ManageUserPage,
    roles: ["admin"],
  },
  profile: {
    anon: false,
    path: "/admin/profile",
    title: "Edit Profile",
    view: ProfilePage,
  },
};

export default AdminPages;
