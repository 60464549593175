import React, { FC, useEffect, useState } from "react";
import { FormikProps } from "formik";
import { NewPaymentFields } from "../schema";
import { InputAdornment, TextField } from "@mui/material";
import { textField } from "./EditAmountField.styles";
import { DollarSignIcon, PoundSignIcon } from "../../../../components";
import { floatRegExp } from "../../../../lib/regexp";
import { negStringToPositiveNum, toFloat } from "../../../../lib";
import BigNumber from "bignumber.js";

interface Props {
  index: number;
  value: string;
  balance: string;
  total: string;
  formik: FormikProps<NewPaymentFields> | null;
  disabled: boolean;
  params?: any;
  currency?: string;
}

const EditAmountField: FC<Props> = ({
  index,
  value,
  total,
  balance,
  formik,
  disabled,
  params,
  currency,
}) => {
  const [val, setVal] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    const myArr = formik?.values.paramsRow;
    const numbers = myArr?.match(/\d+/g);
    const arrOfNum = numbers?.map((str) => {
      return Number(str);
    });
    setVal(value);

    if (arrOfNum?.includes(params.id)) {
      setIsError(toFloat(value) > negStringToPositiveNum(total));
    } else {
      setIsError(toFloat(value) > toFloat(balance));
      setIsDisabled(disabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, total, params?.row.isSelected]);

  useEffect(() => {
    const res = val === "" || floatRegExp.test(val);
    setIsError(!res);
  }, [val]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (formik) {
      const b = (formik.values.balanceItems ?? []).map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            amount: val,
            currency,
          };
        } else return item;
      });
      formik.setFieldValue("balanceItems", b);
      const remainingBalance =
        b?.reduce((prev, curr) => {
          if (curr.isSelected) {
            //curr.amount is negative number
            return prev.plus(BigNumber(Math.abs(toFloat(curr.amount))));
          }
          return prev;
        }, BigNumber(0)) ?? 0;
      setTimeout(() => {
        formik.setFieldValue(
          "remainingBalance",
          BigNumber(toFloat(formik.values.amount))
            .minus(remainingBalance)
            .toNumber(),
        );
      }, 0);
    }
  };

  return (
    <TextField
      sx={textField}
      id={`balanceItems[${index}].amount`}
      name={`balanceItems[${index}].amount`}
      label="Amount"
      fullWidth={true}
      value={val}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {currency === "USD" && (
              <DollarSignIcon sx={{ fill: "#B4B8C8", height: 16, width: 16 }} />
            )}

            {currency === "GBP" && (
              <PoundSignIcon sx={{ fill: "#B4B8C8", height: 16, width: 16 }} />
            )}
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={handleAmountChange}
      onBlur={handleBlur}
      error={isError || toFloat(formik?.values.remainingBalance) < 0}
      disabled={isDisabled}
    />
  );
};

export default EditAmountField;
