import React, { FC } from "react";
import { useEnterTestModal } from "./useEnterTestModal";
import { TestModalData } from "./types";
import { Transition } from "../../components/Modals/Transition";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { Fields, initialValues, validationSchema } from "./schema";
import {
  btnSx,
  captionSx,
  container,
  content,
  gridTestsSx,
} from "./EnterTestModal.styles";
import { FormikInput } from "../../../../components";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { TEST_COLUMNS } from "../../../../constants";

export interface Props {
  modal: TestModalData | null;
  onClose?: () => void;
}

const EnterTestModal: FC<Props> = ({ modal, onClose }) => {
  const { innerRef, open, handleSubmit, handleClose } = useEnterTestModal({
    modal,
    onClose,
  });

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "700px",
        },
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose?.()}
    >
      <DialogTitle>Enter test</DialogTitle>

      <Formik<Fields>
        innerRef={innerRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, submitForm }) => {
          return (
            <Form>
              <DialogContent sx={content}>
                <Typography variant={"h6"}>Questions</Typography>

                <Grid container sx={container}>
                  <Grid item xs={12} height={"500px"} mb={2}>
                    <DataGridPro
                      sx={gridTestsSx}
                      rowHeight={74}
                      disableSelectionOnClick={true}
                      hideFooterRowCount={true}
                      hideFooter={true}
                      rows={values.tests ?? []}
                      columns={TEST_COLUMNS}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "question", sort: "asc" }],
                        },
                      }}
                      componentsProps={{
                        toolbar: {
                          printOptions: { disableToolbarButton: true },
                        },
                        panel: {
                          sx: {
                            "& .MuiDataGrid-filterForm": {
                              alignItems: "center",
                            },
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant={"subtitle1"} sx={captionSx}>
                      Additional options
                    </Typography>

                    <FormikInput
                      name="answers_looked_in"
                      label="Answers looked in"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <Divider
                variant="middle"
                sx={{ border: "1px solid #DDE3EB", mb: 2 }}
              />

              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={btnSx}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color={"primary"}
                  sx={btnSx}
                  onClick={submitForm}
                >
                  save
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default React.memo(EnterTestModal, (prevProps, nextProps) => {
  return (
    prevProps.modal?.chaburahId === nextProps.modal?.chaburahId &&
    prevProps.modal?.monthId === nextProps.modal?.monthId &&
    prevProps.onClose === nextProps.onClose
  );
});
