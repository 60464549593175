import React, { useEffect, memo } from "react";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import {
  RootStyle,
  LeftPanel,
  RightPanel,
  OutletPanel,
  boxLeft,
  boxRight,
} from "./AuthLayout.styles";
import { useSelector } from "react-redux";
import { authSelectors, uiSelectors } from "../../state";
import { Navigation } from "../../lib";
import { Backdrop, Box, CircularProgress, useTheme } from "@mui/material";
import logo from "../../assets/img/logo.png";
import { ElementLeft, ElementRight } from "../../components";

export const AuthLayout = memo(function AuthLayout() {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const loading = useSelector(uiSelectors.loading);

  const theme = useTheme();

  useEffect(() => {
    if (isLoggedIn) {
      Navigation.go("/admin/customer");
    }
  }, [isLoggedIn]);

  return (
    <RootStyle>
      <LeftPanel>
        <Box sx={boxLeft(theme)}>
          <ElementLeft />
        </Box>

        <Box sx={boxRight(theme)}>
          <ElementRight />
        </Box>

        <img src={logo} style={{ height: 160 }} alt="logo" />
      </LeftPanel>

      <RightPanel>
        <OutletPanel>
          <Outlet />
        </OutletPanel>
      </RightPanel>

      <Notification />

      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </RootStyle>
  );
});
