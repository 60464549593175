import React, { FC } from "react";
import { ButtonType, TabType } from "../types";
import { Button, Stack } from "@mui/material";
import { useGetPageMode } from "../utils";

interface Props {
  tab: TabType;
  onClick: (type: ButtonType, e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Buttons: FC<Props> = ({ tab, onClick }) => {
  const { pageMode } = useGetPageMode();

  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-start"}
      gap={"28px"}
      sx={{ p: "8px 16px 0" }}
    >
      {pageMode === "insert" && (
        <>
          {tab === "details" && (
            <Button
              variant={"contained"}
              sx={{ minWidth: "180px" }}
              onClick={onClick.bind(null, "next")}
            >
              next
            </Button>
          )}

          {(["roshKinyan", "curriculum"] as Array<TabType>).includes(tab) && (
            <>
              <Button
                variant={"outlined"}
                sx={{ minWidth: "180px" }}
                onClick={onClick.bind(null, "back")}
              >
                back
              </Button>

              <Button
                variant={"contained"}
                sx={{ minWidth: "180px" }}
                onClick={onClick.bind(null, "continue")}
              >
                continue
              </Button>
            </>
          )}
        </>
      )}

      {pageMode === "edit" && tab !== "roshKinyan" && (
        <Button
          variant={"contained"}
          sx={{ minWidth: "180px" }}
          onClick={onClick.bind(null, "save")}
        >
          save
        </Button>
      )}
    </Stack>
  );
};

export default React.memo(Buttons);
