import React, { FC } from "react";
import {
  BottomSection,
  Page,
  TopSectionGroup,
} from "../styles/GridCardsSearch.styles";
import { authSelectors, useSelector } from "../../../../src/state";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { addGroupBtn, gridStyles, sectionStyles } from "./GroupPage.styles";
import { GROUPS_COLUMNS } from "../../../constants";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useGroupPage } from "./useGroupPage";
import { CustomFooter, CustomToolbar } from "../../../components";
import { dataGridFilterStyles } from "../../../themes";

const GroupPage: FC = () => {
  const { apiRef, handleAddGroup, handleRowClick, data } = useGroupPage();
  const isLimited = useSelector(authSelectors.isLimited);

  return (
    <Page>
      <TopSectionGroup>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item xs={6}>
            <Typography variant="h3">Groups - חבורות</Typography>
          </Grid>

          <Grid item xs={6}>
            {!isLimited && (
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Button
                  variant={"contained"}
                  sx={addGroupBtn}
                  onClick={handleAddGroup}
                >
                  Add group
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      </TopSectionGroup>

      <BottomSection style={sectionStyles}>
        <DataGridPro
          apiRef={apiRef}
          sx={gridStyles}
          pagination={true}
          rowsPerPageOptions={[
            10,
            25,
            50,
            100,
            data?.length > 100 ? data.length : 200,
          ]}
          onRowClick={(e) => handleRowClick(e)}
          rows={data}
          columns={GROUPS_COLUMNS}
          initialState={{
            pagination: {
              pageSize: 25,
            },
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: "status",
                    operatorValue: "isAnyOf",
                    value: ["Active"],
                  },
                ],
              },
            },
          }}
          components={{
            Toolbar: CustomToolbar,
            Footer: () => CustomFooter(data?.length || 0),
          }}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
            panel: {
              sx: {
                ...dataGridFilterStyles,
              },
            },
          }}
        />
      </BottomSection>
    </Page>
  );
};

export default GroupPage;
