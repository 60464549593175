import React, { FC, useEffect, useState } from "react";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import {
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import AdminPages from "../index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import TabGeneral from "./TabGeneral";
import { tabsRoot, tabsWrapper, topSection } from "./ChangeStudentPage.styles";
import { useFormik } from "formik";
import {
  initStudentBillingState,
  initStudentEnrollmentState,
  initStudentGeneralState,
  StudentBillingFields,
  StudentEnrollmentFields,
  StudentGeneralFields,
  validationStudentBillingSchema,
  validationStudentGeneralSchema,
} from "./schema";
import TabEnrollment from "./TabEnrollment";
import TabBilling from "./TabBilling";
import Buttons from "./Buttons";
import { ButtonType, TabType } from "./types";
import {
  buildCustomerOption,
  buildNewStudentData,
  buildStudentBillingCreditData,
  buildStudentEnrollmentData,
  buildStudentEditGeneralData,
  useGetCurrentPageMode,
} from "./utils";
import { formatDateISO, Navigation, toInt, useLocation } from "../../../lib";
import {
  adminActions,
  adminSelectors,
  customerActions,
  customerSelectors,
  listsSelectors,
} from "../../../state";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "../../../components/Select/Select";
import { studentStatuses } from "../../../constants";
import ReactivateStudentModal from "./ReactivateStudentModal";
import useBillingInfo from "./useBillingInfo";

function getTab(direction: "prev" | "next", tabs: TabType): TabType {
  switch (tabs) {
    case "general":
      return direction === "prev" ? "general" : "enrollment";
    case "enrollment":
      return direction === "prev" ? "general" : "billing";
    case "billing":
      return direction === "prev" ? "enrollment" : "billing";
  }
}

const ChangeStudentPage: FC<any> = ({ setReloadCounter, reloadCounter }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const tabFromQuery = location?.query?.tab;
  const { pageMode, id, fromCustomer } = useGetCurrentPageMode();
  const student = useSelector(adminSelectors.student);
  const currentMonth = useSelector(adminSelectors.currentMonthId);
  const [showOverride, setShowOverride] = useState(false);
  const [reactivating, setReactivating] = useState(false);
  const [readyToReactivate, setReadyToReactivate] = useState(false);

  const [tab, setTab] = useState<TabType>(
    (tabFromQuery as TabType) || "general",
  );
  const [reactivateOpen, setReactivateOpen] = useState(false);

  if (tabFromQuery) {
    navigate(`${location.pathname}`, { replace: true });
  }

  const _monthById = useSelector(listsSelectors.monthById);
  const paymentMethods = useSelector(
    customerSelectors.customer_payment_methods,
  );

  if (!id) {
    console.error("Missing id in params");
    return null;
  }

  const handleTabChange = (event: React.SyntheticEvent, value: TabType) => {
    event.stopPropagation();
    if (pageMode === "edit") {
      setTab(value);
    }
  };

  const onDeactivate = () => {
    setTab("general");
  };

  let data;
  if (fromCustomer) {
    data = useSelector(customerSelectors.customer);
  } else data = useSelector(adminSelectors.student);

  const handleGeneralSubmit = async (values: StudentGeneralFields) => {
    if (pageMode === "edit") {
      const { shouldNotNavigate } = values;
      const data = buildStudentEditGeneralData(values, id);
      const result = await dispatch(adminActions.updateStudent(id, data));
      !!result &&
        !shouldNotNavigate &&
        Navigation.go(AdminPages.studentPage.path.replace(":id", id));
    }

    if (pageMode === "insert") {
      const data = buildStudentEnrollmentData(values);
      dispatch(adminActions.createStudent(data));
      setTab(getTab("next", tab));
    }
  };

  const formikGeneral = useFormik<StudentGeneralFields>({
    initialValues: initStudentGeneralState,
    enableReinitialize: true,
    validationSchema: validationStudentGeneralSchema,
    onSubmit: handleGeneralSubmit,
  });

  const handleEnrollmentSubmit = async (values: StudentEnrollmentFields) => {
    dispatch(adminActions.setEnrollmentChaburahId(values.chaburah_id));
    if (pageMode === "edit") {
      const { shouldNotNavigate } = values;
      const data = buildStudentEnrollmentData(values);
      const isOk: any = await dispatch(
        adminActions.updateStudentEnrollment(id, data),
      );
      if (
        isOk?.message ===
        "Validation fails for You are attempting to edit a month that already has points entered. Select override to allow"
      ) {
        setShowOverride(true);
      } else {
        isOk === true && !shouldNotNavigate && navigate(`/admin/student/${id}`);
      }
      if (isOk) {
        await dispatch(adminActions.getStudent(student?.id || 0));
      }
      return isOk;
    }

    if (pageMode === "insert") {
      const data = buildNewStudentData(formikGeneral.values, values);
      const result: any = await dispatch(
        adminActions.createStudentAndEnrollment(data),
      );
      if (data.draft) {
        !!result &&
          Navigation.go(AdminPages.studentPage.path.replace(":id", result.id));
      } else {
        if (result?.id) {
          await dispatch(adminActions.getStudent(result.id));
        }
        !!result && setTab(getTab("next", tab));
      }
    }
  };

  useEffect(() => {
    if (tab === "billing" && ((fromCustomer && id) || student?.customer_id)) {
      const customer_id = fromCustomer ? +id : student?.customer_id;
      if (customer_id)
        dispatch(customerActions.getCustomerPaymentMethods(customer_id));
    }
  }, [dispatch, fromCustomer, id, student?.customer_id, tab]);

  const formikEnrollment = useFormik<StudentEnrollmentFields>({
    initialValues: initStudentEnrollmentState,
    enableReinitialize: true,
    onSubmit: handleEnrollmentSubmit,
  });

  const handleBillingSubmit = async (values: StudentBillingFields) => {
    if (!student?.id) return;
    const {
      default_payment_method,
      payment_method_id,
      amount,
      date,
      note,
      discount,
      terms,
    } = values;

    if (pageMode === "edit" && !isDraft) {
      // TODO: discount needs to pass in ID to edit it
      const data = buildStudentBillingCreditData(values);
      const isOk: any = await dispatch(
        adminActions.updateStudentBilling(id, data),
      );
      isOk && navigate(AdminPages.student.path);
      return;
    }

    const d:
      | {
          discount?: string | null;
          discount_type?: string;
          id?: null | number;
          reason?: null | string;
        }
      | any = discount ? values : {};
    if (pageMode === "insert" || isDraft) {
      const data = {
        default_payment_method,
        payment_method_id,
        amount: typeof amount === "string" ? parseFloat(amount) : amount,
        date: date ? formatDateISO(date) : null,
        note,
        discount: d.discount ? parseFloat(d.discount) : 0,
        discount_type: d.discount_type,
        reason: d.reason ?? "",
        end_month_id: d.end_month_id ? +d.end_month_id : null,
        credit_add: +(formikBilling.values?.credit_add || 0),
        credit_use: billingInfo.appliedCredit || undefined,
        terms,
      };

      dispatch(adminActions.addStudentBillingCredit(student.id, data));
    }
  };
  //
  const formikBilling = useFormik<StudentBillingFields>({
    initialValues: initStudentBillingState,
    enableReinitialize: true,
    validationSchema: validationStudentBillingSchema,
    onSubmit: handleBillingSubmit,
  });
  const billingInfo = useBillingInfo(formikBilling);
  const formHasNoEnrollement =
    !formikEnrollment.values.chaburah_id ||
    +formikEnrollment.values.chaburah_id === 18;

  const handleBtnClick = async (
    type: ButtonType,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    switch (type) {
      case "back":
        setTab(getTab("prev", tab));

        if (tab === "general") {
          navigate(AdminPages.student.path);
        }
        break;
      case "saveAndContinue":
        switch (tab) {
          case "general":
            formikGeneral.setFieldValue("shouldNotNavigate", true);
            await formikGeneral.submitForm();
            formikEnrollment.setFieldValue("shouldNotNavigate", false);
            setTab(getTab("next", tab));
            break;
          case "enrollment": {
            formikEnrollment.setFieldValue("shouldNotNavigate", true);
            const ok = await formikEnrollment.submitForm();
            formikEnrollment.setFieldValue("shouldNotNavigate", false);
            if (ok && !formHasNoEnrollement) {
              setTab(getTab("next", tab));
            }
            break;
          }
        }
        break;
      case "continue":
        switch (tab) {
          case "general":
            if (pageMode === "edit") {
              await formikGeneral.submitForm();
            } else setTab(getTab("next", tab));
            break;
          case "enrollment":
            await formikEnrollment.submitForm();
            break;
        }
        break;

      case "skip":
        setTab(getTab("next", tab));
        break;

      case "continue-submit":
        await formikEnrollment.submitForm();
        setTab(getTab("next", tab));
        break;

      case "cashCancel":
      case "creditBack":
        setTab("enrollment");
        break;

      case "cashSave":
        await formikBilling.submitForm();
        break;

      case "creditSave":
        await formikBilling.submitForm();
        break;
      case "saveDraft":
        switch (tab) {
          case "general":
          case "enrollment":
            formikGeneral.setFieldValue("draft", true);
            await formikEnrollment.submitForm();
            break;
        }
        break;
      case "reactivate":
        if (readyToReactivate) {
          formikGeneral.setFieldValue("shouldNotNavigate", true);
          await formikGeneral.submitForm();
          formikGeneral.setFieldValue("shouldNotNavigate", false);
          dispatch(adminActions.getStudent(student?.id || 0));
          setReactivateOpen(true);
        }
        break;
    }
  };
  useEffect(() => {
    dispatch(adminActions.getCurrentMonth());
  }, [dispatch]);

  useEffect(() => {
    if (fromCustomer && toInt(id)) {
      dispatch(customerActions.getCustomer(+id));
    } else if (!!id && toInt(id)) {
      dispatch(adminActions.getStudent(toInt(id)));
    }
  }, [dispatch, pageMode, id, fromCustomer]);
  const existingEnrollmentData =
    student?.student_months?.[0] || student?.future_changes?.[0];
  const chaburah_id = existingEnrollmentData?.chaburah_id ?? "";
  const hasNoEnrollement = !chaburah_id || chaburah_id === 18;

  useEffect(() => {
    (async () => {
      if (!data) return;
      if (pageMode === "edit") {
        const {
          customer_id,
          customer,
          status_id,
          first_name_yiddish,
          last_name_yiddish,
          first_name,
          last_name,
          grade,
          birth_month_id,
          birth_month_day,
          bar_mitzva,
          school_building,
          student_siyums,
          student_notes,
          month_join,
          referral,
          discounts,
          completed,
          default_payment_method,
        } = data!;

        // General
        const year = _monthById[birth_month_id]?.year_id ?? 0;

        const referring_student_id = referral?.referring_student_id ?? "";

        let _referring_student;

        // getting data for referring student
        // I think BE has to return this data
        if (referring_student_id) {
          const student: any = await dispatch(
            adminActions.getStudentData(referring_student_id),
          );

          if (student) {
            const name_yiddish = student
              ? `${student.first_name_yiddish} ${student.last_name_yiddish}`
              : "";

            const name_english = customer
              ? `${student.first_name} ${student.last_name}`
              : "";

            const homePhone =
              student?.phone_numbers?.filter((numberObj) => {
                return numberObj.type === "home";
              })?.[0].number ?? "";

            _referring_student = {
              value: referring_student_id.toString(),
              label: [name_english, homePhone, name_yiddish]
                .filter(Boolean)
                .join(" - "),
            } as Option;
          }
        }

        await formikGeneral.setValues({
          ...formikGeneral.values,
          // General
          customer_id: customer_id.toString(),
          _customer: buildCustomerOption(customer),
          first_name_yiddish,
          last_name_yiddish,
          first_name,
          last_name,
          completed,
          school: school_building?.school?.id.toString() ?? "",
          school_building_id: school_building?.id?.toString() ?? "",
          grade: grade?.toString(),
          year: year.toString(),
          birth_month_id: birth_month_id?.toString() ?? "",
          birth_month_day: birth_month_day?.toString() ?? "",
          bar_mitzva,
          registration: !!student_siyums?.[0]?.registration,
          transportation: !!student_siyums?.[0]?.transportation,
          _student_siyums: student_siyums,
          // TODO: I am not sure if it is correct seems like it does not belong here. see details page
          // notes: student_notes.length ? student_notes.split(","),
          _notes: student_notes,
          status_id,
          referring_student_id: referring_student_id.toString(),
          _referring_student,
        });

        // Enrollment
        const month_id = month_join || "";

        const year_id = _monthById[month_id]?.year_id ?? "";

        await formikEnrollment.setValues({
          ...formikEnrollment.values,
          chaburah_id: chaburah_id.toString(),
          month_id: month_id.toString(),
          year_id: year_id.toString(),
        });

        // Billing
        await formikBilling.setValues({
          ...formikBilling.values,
          default_payment_method,
          discounts,
          paymentMethods: paymentMethods ?? null,
        });
      } else if (pageMode === "insert") {
        const {
          id,
          customer_id,
          customer,
          last_name_yiddish,
          last_name,
          first_name,
          first_name_yiddish,
        } = data!;
        if (!id && !customer_id) {
          return;
        }
        await formikBilling.setValues({
          ...formikBilling.values,
          paymentMethods: paymentMethods ?? null,
        });
        formikGeneral.setValues({
          ...formikGeneral.values,
          customer_id: fromCustomer ? id?.toString() : customer_id?.toString(),
          _customer: buildCustomerOption(
            fromCustomer
              ? {
                  id,
                  customer_id,
                  last_name_yiddish,
                  last_name,
                  first_name,
                  first_name_yiddish,
                }
              : customer,
          ),
          last_name_yiddish,
          last_name,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // If pageMode not set yet wait...
  if (pageMode === "") {
    console.error(`PageMode not set yet`);
    return null;
  }

  if (!_monthById) return null;
  const isDraft = student?.status_id === studentStatuses.DRAFT;
  const isBeingAddedToCurrentMonth =
    +formikEnrollment.values.month_id == currentMonth;

  return (
    <Page>
      <TopSection style={topSection}>
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <IconButton
            color="primary"
            sx={{ mr: 1 }}
            onClick={() => {
              if (fromCustomer) {
                navigate(AdminPages.customer.path.replace(":id", id));
              } else {
                navigate(AdminPages.studentPage.path.replace(":id", id));
              }
            }}
          >
            <ArrowBackIcon sx={{ color: "#8495B2" }} />
          </IconButton>

          <Typography variant={"h3"} sx={{ color: "#082B65" }}>
            {pageMode === "insert" ? "Add a student" : "Edit student"}
          </Typography>
        </Stack>

        <Box sx={tabsWrapper}>
          <Tabs value={tab} sx={tabsRoot} onChange={handleTabChange}>
            {fromCustomer && (
              <Tab
                label="Customer"
                value={"customer" as TabType}
                disableRipple={true}
              />
            )}
            <Tab
              label="Student"
              value={"general" as TabType}
              disableRipple={pageMode === "insert"}
            />
            <Tab
              label="Enrollment"
              value={"enrollment" as TabType}
              disableRipple={pageMode === "insert"}
            />
            {(pageMode === "insert" || isDraft) &&
              (hasNoEnrollement ? (
                <Tooltip title="Student enrollment is required in order to access the billing tab">
                  <Box>
                    <Tab
                      disabled={true}
                      label="Billing"
                      value={"billing" as TabType}
                      disableRipple={pageMode === "insert"}
                    />
                  </Box>
                </Tooltip>
              ) : (
                <Tab
                  label="Billing"
                  value={"billing" as TabType}
                  disableRipple={pageMode === "insert"}
                />
              ))}
          </Tabs>
        </Box>
      </TopSection>

      <BottomSection style={{ padding: 0, marginBottom: 0 }}>
        {tab === "general" && (
          <TabGeneral
            formik={formikGeneral}
            studentId={student?.id}
            setReactivating={setReactivating}
            reactivating={reactivating}
            setReadyToReactivate={setReadyToReactivate}
          />
        )}
        {tab === "enrollment" && (
          <TabEnrollment
            showOverride={showOverride}
            formik={formikEnrollment}
            customerId={formikGeneral.values.customer_id}
            pageMode={pageMode}
            onDeactivate={onDeactivate}
          />
        )}
        {tab === "billing" && (pageMode !== "edit" || isDraft) && (
          <TabBilling
            formik={formikBilling}
            billingInfo={billingInfo}
            startMonth={formikEnrollment.values.month_id}
            pageMode={"insert"}
            onDeactivate={onDeactivate}
            isCurrentMonth={isBeingAddedToCurrentMonth}
            hasEnrollement={!hasNoEnrollement}
          />
        )}
        {reactivateOpen && (
          <ReactivateStudentModal
            open={reactivateOpen}
            onClose={() => {
              setReactivateOpen(false);
              setReloadCounter(reloadCounter + 1);
            }}
          />
        )}
        <Buttons
          tab={tab}
          paymentMethod={formikBilling.values.default_payment_method}
          onClick={handleBtnClick}
          isDraft={isDraft}
          continueDisabled={
            (!formikGeneral.values.first_name_yiddish ||
              !formikGeneral.values.last_name_yiddish ||
              (!!formikGeneral.values.school &&
                !formikGeneral.values.school_building_id)) &&
            tab === "general"
          }
          pageMode={pageMode}
          reactivating={reactivating}
        />
      </BottomSection>
    </Page>
  );
};

export default () => {
  const [reloadCounter, setReloadCounter] = useState(0);
  return (
    <ChangeStudentPage
      key={reloadCounter}
      reloadCounter={reloadCounter}
      setReloadCounter={setReloadCounter}
    />
  );
};
