import { mixed, object } from "yup";

export interface Fields {
  user_id: number | null;
  neighborhood_id: number | null;
  default: boolean;
  chaburah_id: number;
  editGroup: boolean;
  id?: number | undefined;
}

export const initialValues: Fields = {
  user_id: 0,
  neighborhood_id: null,
  default: false,
  chaburah_id: 0,
  editGroup: false,
};

export const validationSchema = object().shape({
  user_id: mixed()
    .test("user_id", "${label} is required", (val) => !!val)
    .label("ראש קנין / מגיד שיעור"),
  neighborhood_id: mixed()
    .test("neighborhood_id", "${label} is required", (val) => !!val)
    .label("Neighborhood"),
});
