import { Box, debounce } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { RefreshGridButton } from "./RefreshGridButton";
import { ExportHistoryButton } from "./ExportHistoryButton";
import SearchIcon from "@mui/icons-material/Search";
import {
  Search,
  StyledInputBase,
} from "../../pages/admin/styles/GridCardsSearch.styles";
import { useCallback } from "react";
export function CustomToolbar(
  showRefresh = false,
  monthId = null,
  refreshAction: "getStudents" | "getStudentsMonths" | null = null,
  csvExport: "student" | "billing" | null = null,
) {
  return (
    <GridToolbarContainer>
      {csvExport && <ExportHistoryButton report={csvExport} />}
      {showRefresh && typeof showRefresh !== "object" && (
        <RefreshGridButton monthId={monthId} refreshAction={refreshAction} />
      )}
      <GridToolbarFilterButton />
      <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}
export type SearchableToolbarOptions = {
  placeholder?: string;
};

export function SearchableToolbar(
  apiRef,
  options: SearchableToolbarOptions = {},
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((apiRef, v) => {
      apiRef.current.setQuickFilterValues([v]);
    }, 300),
    [],
  );
  const { placeholder } = options;
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          display: "flex",
        }}
      >
        <Box>
          <Search>
            <StyledInputBase
              placeholder={placeholder || "Search…"}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                debouncedSearch(apiRef, e.target.value);
              }}
            />
            <SearchIcon
              sx={{ color: "#B4B8C8", height: 32, paddingTop: 1.5 }}
            />
          </Search>
        </Box>
        <Box>
          <GridToolbarFilterButton />
          <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        </Box>
      </Box>
    </GridToolbarContainer>
  );
}
