import React, { useCallback, useEffect, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { adminActions, adminSelectors, listsSelectors } from "../../state";
import {
  BottomSection,
  Page,
  Search,
  StyledInputBase,
  TopSection,
} from "./styles/GridCardsSearch.styles";
import SearchIcon from "@mui/icons-material/Search";
import NeighborhoodSelector, {
  NeighborhoodFilter,
} from "./components/NeighborhoodSelector";
import { StudentsGrid } from "../../components/tables/StudentsGrid";

export const StudentsPage = () => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const [chaburahFilter, setChaburahFilter] =
    useState<NeighborhoodFilter>("all");

  const students = useSelector(adminSelectors.students);
  const studentsLive = useSelector(adminSelectors.studentsLive);
  const studentsRemote = useSelector(adminSelectors.studentsRemote);
  const neighborhoods = useSelector(listsSelectors.neighborhoodById);
  const [rows, setRows]: any = useState([]);

  const getStudentsByChaburah = useCallback(
    (ch: typeof chaburahFilter) => {
      switch (ch) {
        case "masmidim":
          return studentsLive;
        case "cd":
          return studentsRemote;
        default:
          return students;
      }
    },
    [students, studentsLive, studentsRemote],
  );

  useEffect(() => {
    const _rows = getStudentsByChaburah(chaburahFilter);
    setRows(_rows);
  }, [getStudentsByChaburah, chaburahFilter]);

  useEffect(() => {
    dispatch(adminActions.getStudents(neighborhoods));
    dispatch(adminActions.getCurrentMonth());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Page>
      <TopSection>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item xs={6}>
            <Typography variant="h3">Students - תלמידים</Typography>
          </Grid>

          <Grid item container xs={6} justifyContent="flex-end">
            <NeighborhoodSelector
              neighborhoodFilter={chaburahFilter}
              onSelect={setChaburahFilter}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} direction="row" justifyContent="flex-start">
          <Grid item>
            <Box>
              <Search>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    apiRef.current.setQuickFilterValues([e.target.value]);
                  }}
                />
                <SearchIcon
                  sx={{ color: "#B4B8C8", height: 32, paddingTop: 1.5 }}
                />
              </Search>
            </Box>
          </Grid>
        </Grid>
      </TopSection>

      <BottomSection
        style={{ height: "calc(100vh - 140px)", paddingTop: "32px" }}
      >
        <StudentsGrid apiRef={apiRef} rows={rows} />
      </BottomSection>
    </Page>
  );
};
