import { Box, Button, Typography } from "@mui/material";
import StatusIcon from "../../../components/icons/StatusIcon";
import { adminActions, useDispatch } from "../../../state";
import { FC } from "react";
interface Props {
  student;
  studentId;
  handleReactivateClick?: undefined | Function;
  onDeactivate?: undefined | Function;
  hideButton?: boolean;
}

export const StudentStatusToggle: FC<Props> = ({
  student,
  studentId,
  handleReactivateClick = undefined,
  onDeactivate = undefined,
  hideButton = false,
}) => {
  const dispatch = useDispatch();
  return (
    <Box
      display="inline-flex"
      bgcolor="rgba(141, 152, 194,.16)"
      alignItems="center"
      paddingRight={2}
      paddingLeft={2}
      marginBottom={1}
      borderRadius={"5px"}
    >
      <Box marginRight={2}>
        <StatusIcon status_id={student?.status_id} />
      </Box>
      <Typography variant={"h6"}>
        {student?.first_name_yiddish} {student?.last_name_yiddish} -{" "}
        {student?.first_name} {student?.last_name}
      </Typography>
      {!hideButton && (
        <Button
          variant="outlined"
          sx={{
            marginLeft: "16px",
            fontSize: "12px",
            height: "24px",
          }}
          onClick={async () => {
            if (student?.status_id === 3 && handleReactivateClick) {
              handleReactivateClick(true);
            } else {
              if (!studentId) {
                return;
              }
              const { first_name_yiddish, last_name_yiddish } = student;
              await dispatch(
                adminActions.updateStudent(studentId, {
                  status_id: 3,
                  first_name_yiddish,
                  last_name_yiddish,
                }),
              );
              dispatch(adminActions.getStudent(studentId));
              if (onDeactivate) {
                onDeactivate();
              }
            }
          }}
        >
          {student?.status_id === 3 && handleReactivateClick
            ? "Reactivate"
            : "deactivate"}
        </Button>
      )}
    </Box>
  );
};
