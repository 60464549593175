import { AppState } from "../types";
export function getCustomerName(customer) {
  const { first_name, last_name, first_name_yiddish, last_name_yiddish } =
    customer;

  return `${first_name || ""} ${last_name} - ${last_name_yiddish}\u200E ${
    first_name_yiddish || ""
  }`;
}

export const customerSelectors = {
  customerName(state: AppState) {
    if (!state.customer.customer) {
      return "";
    }
    return getCustomerName(state.customer.customer);
  },
  customer(state: AppState) {
    return {
      first_name_yiddish: "",
      last_name_yiddish: "",
      first_name: "",
      last_name: "",
      email: "",
      roles: [],
      active: false,
      completed: false,
      status_id: 0,
      address: "",
      currency: "",
      address_id: null,
      is_customer: true,
      notes: "",
      phone_numbers: [],
      terms: false,
      balance_old: {
        id: null,
        person_id: null,
        balance: null,
      },
      students: [
        {
          id: 0,
          first_name_yiddish: "",
          last_name_yiddish: "",
          first_name: "",
          last_name: "",
          status_id: null,
          payment_method: { processor_payment_method_id: 0 },
        },
      ],
      v_customer_balance_detail: {
        balance: "",
        charged: "",
        paid: "",
        person_id: null,
      },
      ...state.customer.customer,
    };
  },
  customer_payment_methods(state: AppState) {
    return state.customer?.customer?.payment_methods ?? [];
  },
  customerMonthBalance(state: AppState) {
    return state.customer?.customerMonthBalance ?? [];
  },
  customersPayments(state: AppState) {
    return state.customer?.customerPayments;
  },
  customerLegacyCharges(state: AppState) {
    return state.customer?.customerLegacyCharges;
  },
  customerLegacyPayments(state: AppState) {
    return state.customer?.customerLegacyPayments;
  },
  customerBillingDetails(state: AppState) {
    return state.customer?.customerBillingDetails;
  },
  customerPaymentOptions(state: AppState) {
    return state.customer?.customerPaymentOptions;
  },
  customers(state: AppState) {
    return state.customer.customers.rows;
  },
  customersTotals(state: AppState) {
    return state.customer.customersTotals;
  },
  student_billing(state: AppState) {
    return state.customer.customerBillingDetails?.student_billing;
  },
  customerBillingIsNotFiltered(state: AppState) {
    return state.customer.customerBillingIsNotFiltered;
  },
  customerUpcomingBill(state: AppState) {
    return state.customer.upcomingbill;
  },
};
