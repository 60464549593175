import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const btnPanel: SxProps<Theme> = {
  mt: 3,

  "& > .MuiButton-root": {
    minWidth: "180px",
    mr: 3,

    "&:last-of-type": {
      mr: 0,
    },
  },
};
