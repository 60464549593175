import * as React from "react";
import { styled } from "@mui/material/styles";
import { SwipeableDrawer, Box, IconButton } from "@mui/material";
import Menu from "./Menu";
import { uiActions, uiSelectors, useDispatch, useSelector } from "../../state";
import { CloseIcon } from "../../components";
import logo from "../../assets/img/logo.png";

const Drawer = styled(SwipeableDrawer)({
  "& .MuiDrawer-paperAnchorLeft": {
    width: "260px",
  },
});

export default function MobileDrawer() {
  const dispatch = useDispatch();
  const menuOpen = useSelector(uiSelectors.menuOpen);

  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };

  return (
    <Box>
      <Drawer
        anchor="left"
        open={menuOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "primary.main",
          },
        }}
      >
        <Box sx={{ 
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          height: 72,
          gap: 1,

         }}
        >
          <IconButton
            color="secondary"
            aria-label="close drawer"
            onClick={handleDrawerClose}
            sx={{ p: 3 }}
          >
            <CloseIcon />
          </IconButton>
        <img src={logo} style={{ height: 48 }} alt="logo" />
        </Box>
        <Menu />
      </Drawer>
    </Box>
  );
}