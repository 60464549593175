import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(() => ({
  position: "relative",
  height: "48px",
  width: "400px",
  border: "1px solid #DDE3EB",
  borderRadius: "4px",
  backgroundColor: "#fff",
  marginTop: 16,
  zIndex: 100,
}));

const StyledInputBase = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    marginLeft: 16,
    width: "350px",
    paddingBottom: 2,
  },
}));

export const GridSearch = () => {
  return (
    <Box>
      <Search>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
        />
        <SearchIcon sx={{ color: "#B4B8C8", height: 32, paddingTop: 1.5 }} />
      </Search>
    </Box>
  );
};
