/**
 * @file This file is used to normalize environment variables and provide
 * intellisense/autocomplete for them. Import your settings from this file
 * instead of directly from `process.env`.
 */

/** Common environment names. */
const Environments = {
  development: "development",
  production: "production",
};

/** Name of the current environment. */
const NODE_ENV = process.env.NODE_ENV || Environments.development;
/** Host name used to detect production mode. */
const REACT_APP_PROD_HOSTNAME = process.env.REACT_APP_PROD_HOSTNAME;
const REACT_APP_STAGING_HOSTNAME = process.env.REACT_APP_STAGING_HOSTNAME;
/** True if the app is in development mode. */
const __DEV__ = NODE_ENV === Environments.development;
/**
 * True if the app is in production mode.
 * NOTE: We don't use Environments.production to test for this
 * because Create-React-App uses "production" for every other non-development
 * environment.
 */
const __STAGING__ = window.location.hostname === REACT_APP_STAGING_HOSTNAME;
const __PROD__ = window.location.hostname === REACT_APP_PROD_HOSTNAME;
const COLLAPSIBLE_DRAWER = process.env.COLLAPSIBLE_DRAWER || true;
/** Base URL of the API. */
const REACT_APP_API_URL = "/api/V1";
const REACT_APP_BASE_URL = __DEV__
  ? "http://localhost:3305"
  : __STAGING__
  ? "https://kinyan-staging-3pmcm.ondigitalocean.app"
  : "https://kinyenmishna.com";
/** URL to the company website. */
const REACT_APP_COMPANY_SITE_URL = process.env.REACT_APP_COMPANY_SITE_URL;
/** DSN URL for the error telemetry API. */
const REACT_APP_ERRORS_DSN = process.env.REACT_APP_ERRORS_DSN;
/** True to report errors during development. */
const REACT_APP_ERRORS_DEV = process.env.REACT_APP_ERRORS_DEV === "true";
/** Key to store redux state under localStorage. */
const REACT_APP_PERSIST_KEY = process.env.REACT_APP_PERSIST_KEY;
/** Site title string. */
const REACT_APP_SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
/** Package version string from the package.json file. */
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
const REACT_APP_MUI_LICENSE = process.env.REACT_APP_MUI_LICENSE;

const REACT_APP_CARDKNOX_GBP_PUBLIC_KEY_ACCOUNT = __PROD__
  ? process.env.REACT_APP_CARDKNOX_GBP_PUBLIC_KEY_ACCOUNT
  : "ifields_bitbeandevf5a2771c393444a6a3030978c24";
const REACT_APP_CARDKNOX_REMOTE_PUBLIC_KEY_ACCOUNT = __PROD__
  ? process.env.REACT_APP_CARDKNOX_REMOTE_PUBLIC_KEY_ACCOUNT
  : "ifields_bitbeandevf5a2771c393444a6a3030978c24";
const REACT_APP_CARDKNOX_LIVE_PUBLIC_KEY_ACCOUNT = __PROD__
  ? process.env.REACT_APP_CARDKNOX_LIVE_PUBLIC_KEY_ACCOUNT
  : "ifields_bitbeandevf5a2771c393444a6a3030978c24";

const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const REACT_APP_BASE_API_URL =
  "https://kinyan-staging-3pmcm.ondigitalocean.app";

export {
  __DEV__,
  __PROD__,
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_COMPANY_SITE_URL,
  REACT_APP_ERRORS_DSN,
  REACT_APP_ERRORS_DEV,
  REACT_APP_PERSIST_KEY,
  REACT_APP_PROD_HOSTNAME,
  REACT_APP_SITE_TITLE,
  REACT_APP_VERSION,
  COLLAPSIBLE_DRAWER,
  REACT_APP_MUI_LICENSE,
  REACT_APP_BASE_API_URL,
  REACT_APP_CARDKNOX_REMOTE_PUBLIC_KEY_ACCOUNT,
  REACT_APP_CARDKNOX_LIVE_PUBLIC_KEY_ACCOUNT,
  REACT_APP_CARDKNOX_GBP_PUBLIC_KEY_ACCOUNT,
  REACT_APP_GOOGLE_API_KEY,
};
