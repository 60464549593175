import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  Checkbox,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditIcon } from "../../../../components";
import {
  CalendarDay,
  adminActions,
  adminSelectors,
  listsSelectors,
  studentActions,
} from "../../../../state";
import TabContainer from "../../components/TabContainer";
import ReactRouterPrompt from "react-router-prompt";
import { isDeepEqual } from "../../ManageGroup/utils";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { formattedYiddishDateTimeString, assert } from "../../../../lib";

export default function Calendar({ studentId, monthId }) {
  const months = useSelector(listsSelectors.monthById);
  const monthData = months[monthId];
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    if (+monthId)
      dispatch(
        adminActions.getStudentCalendar({ id: studentId, month_id: monthId }),
      );
  }, [dispatch, monthId, studentId]);
  const calendar = useSelector(adminSelectors.studentCalendar);
  const unsortedWeeks =
    calendar?.data?.reduce((prev, curr) => {
      if (!assert(curr, ["week_num", "weekday", "weekday.english"])) {
        return prev;
      }

      prev[curr.week_num!] = {
        ...(prev[curr.week_num!] || []),
        [curr.weekday!.english!]: curr,
      };

      return prev;
    }, {}) || [];
  const weeks = Object.keys(unsortedWeeks)
    .sort((a, b) => +a - +b)
    .map((key) => unsortedWeeks[key]);

  const [data, setData] = useState<any>({});
  const [initialState, setInitialState] = useState<any>({});
  useEffect(() => {
    const initalData = calendar?.data?.reduce((p, d) => {
      if (!assert(d, ["id"])) {
        return p;
      }
      p[d.id!] = d[
        calendar?.read_only ? "students_daily_bks" : "students_dailies"
      ]?.[0] || { day_id: d.id };
      return p;
    }, {});
    setData(initalData);
    setInitialState(initalData);
  }, [calendar]);
  const submit = async () => {
    const days = Object.values<any>(data)
      .filter((d: any) =>
        [d.on_schedule, d.shiur, d.review].some((v) => !!v || v === 0),
      )
      .map(({ on_schedule, shiur, review, day_id }) => ({
        on_schedule,
        shiur,
        review,
        day_id,
      }));
    if (days.length) {
      const success: any = await dispatch(
        adminActions.updateStudentCalendar(studentId, {
          days,
        }),
      );
      if (success) {
        dispatch(
          adminActions.getStudentCalendar({
            id: studentId,
            month_id: monthId,
          }),
        );
        dispatch(studentActions.getStudentMonth(studentId, monthId));
      }
    }
  };
  return (
    <TabContainer sx={{ width: "1167px" }}>
      <ReactRouterPrompt when={!isDeepEqual(data, initialState)}>
        {({ isActive, onConfirm }) => (
          <Dialog open={isActive}>
            <DialogTitle>Do you want to save your changes?</DialogTitle>
            <Box padding="25px" paddingY={5} fontWeight={600}>
              Your changes will be lost if you don’t save them.
            </Box>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={onConfirm}>
                Dont save
              </Button>

              <Button
                variant="contained"
                color={"primary"}
                onClick={async (e) => {
                  await submit();
                  onConfirm(e);
                }}
              >
                Save changes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </ReactRouterPrompt>
      {!weeks.length ? (
        <Box
          minHeight={300}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <CalendarMonthIcon sx={{ fontSize: "163px", color: "#8D98C2" }} />
          </Box>
          <Typography color="#082B65" fontWeight={600}>
            No calendar data
          </Typography>
        </Box>
      ) : (
        <>
          <Box paddingBottom="15px" display="flex" alignItems="center">
            <Typography variant={"h4"}>Calendar</Typography>
            {!edit ? (
              <Tooltip
                title={calendar?.read_only ? "This month is read only" : ""}
              >
                <Button
                  onClick={() => {
                    setEdit(true);
                  }}
                  disabled={calendar?.read_only}
                  size="small"
                  sx={{ fontSize: "16px !important", marginLeft: 2 }}
                >
                  <EditIcon sx={{ height: 16, paddingRight: 1 }} />
                  edit
                </Button>
              </Tooltip>
            ) : (
              <Box
                bgcolor="rgba(31, 93, 195, .16)"
                padding={1}
                display="flex"
                alignItems="center"
                borderRadius={1}
                marginLeft={2}
              >
                <Typography fontSize={14} marginRight={4} fontStyle="italic">
                  You are editing the calendar...
                </Typography>
                <Button
                  onClick={() => {
                    submit();
                    setEdit(false);
                  }}
                  size="small"
                  variant="contained"
                  sx={{ fontSize: 12, background: "#1F5DC3", height: 24 }}
                >
                  Save changes
                </Button>
              </Box>
            )}
          </Box>
          <Box display="flex" marginBottom={1}>
            <Box width="162px" minWidth="162px" textAlign="center">
              שבת קודש
            </Box>
            <Box width="162px" minWidth="162px" textAlign="center">
              פרייטאג
            </Box>
            <Box width="162px" minWidth="162px" textAlign="center">
              דאנערשטאג
            </Box>
            <Box width="162px" minWidth="162px" textAlign="center">
              מיטוואך
            </Box>
            <Box width="162px" minWidth="162px" textAlign="center">
              דינסטאג
            </Box>
            <Box width="162px" minWidth="162px" textAlign="center">
              מאנטאג
            </Box>
            <Box width="162px" minWidth="162px" textAlign="center">
              זינטאג
            </Box>
          </Box>
          <Box>
            {weeks.map((w: any, i) => {
              const above = weeks[i - 1];

              const days = [
                "Shabbos",
                "Friday",
                "Thursday",
                "Wednesday",
                "Tuesday",
                "Monday",
                "Sunday",
              ];
              return (
                <Box
                  display="flex"
                  key={i}
                  justifyContent={
                    i + 1 === weeks.length ? "flex-end" : "flex-start"
                  }
                >
                  {days.map((d, index) => {
                    const lastOnTheRight = w[d] && !w[days[index + 1] || "hi"];
                    return (
                      <Day
                        key={index}
                        day={w[d]}
                        data={data}
                        setData={setData}
                        edit={edit}
                        monthData={monthData}
                        style={{
                          borderTop: above?.[d] ? "0px" : undefined,
                          borderRight: !lastOnTheRight ? "0px" : undefined,
                          width: lastOnTheRight ? 163 : 162,
                          minWidth: lastOnTheRight ? 163 : 162,
                          height: above?.[d] || !w[d] ? 128 : 129,
                        }}
                      />
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </TabContainer>
  );
}

function Day({
  day,
  style = {},
  data,
  setData,
  edit,
  monthData,
}: {
  day: CalendarDay;
  style: any;
  data: any;
  setData: Function;
  edit: boolean;
  monthData: any;
}) {
  if (!assert(day, ["edate"])) {
    return <></>;
  }
  const userById = useSelector(listsSelectors.userById);
  const onChange = (e) => {
    if (!assert(day, ["id"])) {
      return;
    }
    const {
      target: { name },
    } = e;
    setData({
      ...data,
      [day.id!]: {
        ...data[day.id!],
        [name]: data[day.id!]?.[name] ? 0 : 1,
      },
    });
  };

  const {
    on_schedule = 0,
    shiur = 0,
    review = 0,
    day_total,
    by_student,
    created_by,
    check_time_status,
    shiur_checked_at,
  } = data?.[day?.id || ""] || {};
  const checkedIn =
    shiur !== 1
      ? undefined
      : check_time_status === "on_time"
      ? "Checked in that day"
      : check_time_status === "late_1"
      ? "Checked in that week"
      : undefined;
  const checkboxColor =
    shiur !== 1
      ? "default"
      : check_time_status === "on_time"
      ? "success"
      : check_time_status === "late_1"
      ? "warning"
      : "default";
  const checkboxSx = {
    "&.Mui-disabled": {
      color:
        shiur !== 1
          ? "rgba(0, 0, 0, 0.26)"
          : check_time_status === "on_time"
          ? "rgba(18, 142, 86, .5)"
          : check_time_status === "late_1"
          ? "rgba(243, 169, 79, .5)"
          : "rgba(0, 0, 0, 0.26)",
    },
  };

  return (
    <Box
      height="128px"
      width="162px"
      border={`${day ? "1px solid #E4E7F2" : undefined}`}
      bgcolor={"#fff"}
      padding={1}
      {...style}
    >
      {day && (
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box fontSize={12}>
                {format(new Date(day.edate!.replaceAll("-", "/")), "MMM d")}
              </Box>
              <Box display="flex">
                {day.heDateParts_m !== monthData?.hebrew && (
                  <Typography marginRight={0.5}>
                    {" "}
                    {day.heDateParts_m}
                  </Typography>
                )}
                <Typography color="#072556" fontWeight={600}>
                  {day.heDateParts_d}
                </Typography>
              </Box>
            </Box>
            {day.weekday_id === 7 && day.name_no_parsha?.startsWith("פרשת") && (
              <Box
                textAlign="right"
                color="#072556"
                fontWeight={600}
                fontSize={14}
              >
                {day.name_no_parsha}
              </Box>
            )}
            {day.name_no_parsha !== day.heb_name &&
              !day.name_no_parsha.startsWith("פרשת") && (
                <Box
                  sx={{
                    bgcolor: (theme) =>
                      day.name_no_parsha.startsWith("ראש חודש")
                        ? theme.palette.primary.main
                        : theme.palette.warning.main,
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  {day.name_no_parsha}
                </Box>
              )}
            {day.actions && (
              <Tooltip
                placement="bottom"
                title={day.actions.map((a, i) => (
                  <Box key={i} sx={{ direction: "rtl" }}>
                    <Box component="span">
                      {" "}
                      {a?.hebrew} {a.tag}{" "}
                    </Box>
                    <Box component="span" sx={{ marginRight: "2px" }}>
                      {a.points}
                    </Box>
                    <Box component="span">points</Box>
                  </Box>
                ))}
              >
                <Box
                  fontSize={14}
                  textAlign="center"
                  bgcolor="#ECEEF5"
                  paddingTop={0.5}
                  paddingBottom={0.5}
                  color="rgba(0,0,0,0.87)"
                  fontWeight={600}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  sx={{ direction: "rtl" }}
                >
                  {day.actions.map((a, i) => (
                    <Box component="span" key={i}>
                      <Box component="span">
                        {" "}
                        {a?.hebrew} {a.tag}{" "}
                      </Box>
                      <Box component="span" sx={{ marginRight: "2px" }}>
                        {a.points}
                      </Box>
                      <Box component="span">points</Box>
                    </Box>
                  ))}
                </Box>
              </Tooltip>
            )}
          </Box>
          {!day.chaburah_off && (
            <Box textAlign="right">
              <Tooltip
                title={
                  !created_by && !by_student ? (
                    "שמירת הזמן"
                  ) : (
                    <BulletList field={"שמירת הזמן"} />
                  )
                }
                placement="top"
              >
                <Box component="span">
                  <Checkbox
                    checked={!!on_schedule}
                    name="on_schedule"
                    onChange={onChange}
                    disabled={!edit}
                    color={checkboxColor}
                    sx={checkboxSx}
                  />
                </Box>
              </Tooltip>
              <Tooltip
                title={
                  !created_by && !by_student ? (
                    "חזרת המשנה"
                  ) : (
                    <BulletList field={"חזרת המשנה"} />
                  )
                }
                placement="top"
              >
                <Box component="span">
                  <Checkbox
                    checked={!!review}
                    name="review"
                    onChange={onChange}
                    disabled={!edit}
                    color={checkboxColor}
                    sx={checkboxSx}
                  />
                </Box>
              </Tooltip>
              <Tooltip
                placement="top"
                title={
                  <>
                    {(!created_by && !by_student) || !shiur ? (
                      <>טעגליכע שיעור</>
                    ) : (
                      <BulletList field={"טעגליכע שיעור"} />
                    )}
                  </>
                }
              >
                <Box component="span">
                  <Checkbox
                    checked={!!shiur}
                    name="shiur"
                    onChange={onChange}
                    disabled={!edit}
                    color={checkboxColor}
                    sx={checkboxSx}
                  />
                </Box>
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
  function BulletList({ field }) {
    return (
      <ul
        style={{
          margin: "0px",
          paddingLeft: "15px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        <li>{field}</li>
        {(by_student || created_by) && (
          <li>
            Completed by:
            {created_by ? " " + userById[created_by].name_yiddish : " Student"}
          </li>
        )}
        {shiur_checked_at && (
          <li>{formattedYiddishDateTimeString(shiur_checked_at)}</li>
        )}
        {!!day_total && <li>{day_total} total points earned</li>}
        {checkedIn && <li>{checkedIn}</li>}
      </ul>
    );
  }
}
