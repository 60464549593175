import { SxProps, Theme } from "@mui/material";

export const container: SxProps<Theme> = {
  border: "1px solid #DDE3EB",
  borderRadius: 1,
  backgroundColor: "primary.contrastText",
  mt: 2,
  p: "24px 48px",

  "& > div": {
    pr: 2,
    borderRight: "1px solid #DDE3EB",

    "&:last-of-type": {
      borderRight: "unset",
    },
  },
};

export const pinStyle: SxProps<Theme> = {
  fontSize: "15px",
  color: "rgba(0,0,0,0.87)",
  mb: 2,

  "> span": {
    fontWeight: "bold",
    ml: 1,
  },
};

export const phoneStyle: SxProps<Theme> = {
  color: "rgba(0,0,0,0.87)",
  mb: 0.5,

  "> span": {
    color: "#000000",
    ml: 1,
  },
};

export const iconStyle: SxProps<Theme> = {
  height: "15px",
  width: "15px",
  color: "#97A7C1",
  mt: "3px",
};

export const pointsCaption: SxProps<Theme> = {
  color: "#031128",
  fontSize: "15px",
  letterSpacing: "0.16px",
  mb: "26px",
};
