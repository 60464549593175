import { SxProps, Theme } from "@mui/material";

export const container: SxProps<Theme> = {
  border: "1px solid #DDE3EB",
  borderRadius: 1,
  backgroundColor: "primary.contrastText",
  p: "18px",
  mb: 2,
};

export const noteStyle: SxProps<Theme> = {
  color: "#000000",
  letterSpacing: 0,
  lineHeight: "19px",
};

export const toolBtnStyle: SxProps<Theme> = {
  backgroundColor: "secondary.main",
  borderRadius: 0.5,

  "& > .MuiSvgIcon-root": {
    color: "#082B65",
    height: "10px",
    width: "10px",
  },
};
