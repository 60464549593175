import { object, string } from "yup";
import { floatRegExp } from "../../../../../lib/regexp";

export interface Fields {
  transaction_id: string;
  amount: string;
  note: string;
  cardsNumbers: Record<string, string>;
}

export const initialValues: Fields = {
  transaction_id: "",
  amount: "",
  note: "",
  cardsNumbers: {},
};

export const validationSchema = object().shape({
  transaction_id: string().required("Required").label("Transaction"),
  amount: string()
    .required("Required")
    .test("amount", "${label} must be a number", (val = "") =>
      floatRegExp.test(val),
    )
    .test(
      "amount",
      "${label} must be no less than 0.05",
      (val = "0") => +val >= 0.05 || val === "",
    )
    .test(
      "amount",
      "${label} must be no greater than 100,000",
      (val = "0") => +val <= 100_000,
    )
    .label("Amount"),
});
