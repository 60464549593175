import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import AdminPages from "../index";
import { PageMode } from "../../../state";

export function usePageMode() {
  const [pageMode, setPageMode] = useState<PageMode>("");
  const [id, setId] = useState<string>("");

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (location.pathname === AdminPages.createUsers.path) {
      setId("");
      setPageMode("insert");
    } else if (
      params?.id &&
      AdminPages.editUsers.path.replace(":id", params.id) === location.pathname
    ) {
      setId(params.id);
      setPageMode("edit");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { pageMode, id };
}
