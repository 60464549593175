import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Building,
  ChaburahType,
  Chaburahs,
  GenericUser,
  Location,
  Month,
  Neighborhood,
  School,
  SemesterItem,
  Status,
  SubjectItem,
  Track,
  Type,
  Countries,
  NeighborhoodPrincipals,
  Principals,
  TeacherList,
  ChaburahTeacher,
} from "../types";

export interface ListsState {
  statuses: Status[];
  neighborhoods: Neighborhood[];
  allTeachers: TeacherList[];
  chaburahTypes: ChaburahType[];
  chaburahs: Chaburahs[];
  countries: Countries[];
  months: Month[];
  types: Type[];
  users: GenericUser[];
  neighborhoodPrincipals: NeighborhoodPrincipals[];
  principals: Principals[];
  semesters: SemesterItem[];
  tracks: Track[];
  locations: Location[];
  schools: School[];
  schoolBuildings: Building[];
  subjects: SubjectItem[];
  mesechtos: SubjectItem[];
  chaburahTeachers: ChaburahTeacher[];
}

const initialState: ListsState = {
  statuses: [],
  neighborhoods: [],
  allTeachers: [],
  chaburahTypes: [],
  chaburahs: [],
  months: [],
  types: [],
  users: [],
  neighborhoodPrincipals: [],
  principals: [],
  semesters: [],
  tracks: [],
  locations: [],
  schools: [],
  schoolBuildings: [],
  countries: [],
  subjects: [],
  mesechtos: [],
  chaburahTeachers: [],
};

export const lists = {
  ...createSlice({
    name: "lists",
    initialState,
    reducers: {
      setStatuses(state, { payload }: PayloadAction<Status[]>) {
        state.statuses = payload;
      },
      setNeighborhoods(state, { payload }: PayloadAction<Neighborhood[]>) {
        state.neighborhoods = payload;
      },
      setAllTeachers(state, { payload }: PayloadAction<TeacherList[]>) {
        state.allTeachers = payload;
      },
      setChaburahTypes(state, { payload }: PayloadAction<ChaburahType[]>) {
        state.chaburahTypes = payload;
      },
      setMonths(state, { payload }: PayloadAction<Month[]>) {
        state.months = payload;
      },
      setChaburahs(state, { payload }: PayloadAction<Chaburahs[]>) {
        state.chaburahs = payload;
      },
      setCountries(state, { payload }: PayloadAction<Countries[]>) {
        state.countries = payload;
      },
      setChaburahTeachers(
        state,
        { payload }: PayloadAction<ChaburahTeacher[]>,
      ) {
        state.chaburahTeachers = payload;
      },

      setTypes(state, { payload }: PayloadAction<Type[]>) {
        state.types = payload;
      },
      setUsers(state, { payload }: PayloadAction<GenericUser[]>) {
        state.users = payload;
      },
      setNeighborhoodPrincipals(
        state,
        { payload }: PayloadAction<NeighborhoodPrincipals[]>,
      ) {
        state.neighborhoodPrincipals = payload;
      },
      setAllPrincipals(state, { payload }: PayloadAction<Principals[]>) {
        state.principals = payload;
      },
      setSemesters(state, { payload }: PayloadAction<SemesterItem[]>) {
        state.semesters = payload;
      },
      setTracks(state, { payload }: PayloadAction<Track[]>) {
        state.tracks = payload;
      },
      setLocations(state, { payload }: PayloadAction<Location[]>) {
        state.locations = payload;
      },
      setSchools(state, { payload }: PayloadAction<School[]>) {
        state.schools = payload;
      },
      setSchoolBuildings(state, { payload }: PayloadAction<Building[]>) {
        state.schoolBuildings = payload;
      },
      setSubjects(state, { payload }: PayloadAction<SubjectItem[]>) {
        state.subjects = payload?.filter((p) => p.type === "subject") ?? [];
      },
      setMesechtos(state, { payload }: PayloadAction<SubjectItem[]>) {
        state.mesechtos = payload.filter((p) => p.type === "mesechto");
      },
    },
  }),
  persist: true,
};
