import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  adminActions,
  GroupCurriculum,
  listsSelectors,
} from "../../../../state";
import { toInt } from "../../../../lib";

export function useTabCurriculum() {
  const params = useParams();
  const dispatch = useDispatch();

  const semesters = useSelector(listsSelectors.semesterById);
  const subjects = useSelector(listsSelectors.subjectById);

  const [data, setData] = useState<GroupCurriculum[] | null>(null);

  useEffect(() => {
    (async () => {
      if (!!params.id && toInt(params.id)) {
        const cc = await dispatch(
          adminActions.getChaburahCurriculum(params.id),
        );

        if (cc) {
          const ncc = (cc as unknown as GroupCurriculum[]).map((c) => {
            return {
              ...c,
              __list: {
                semesters,
                subjects,
              },
            };
          });

          setData(ncc);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.id]);

  return {
    data,
  };
}
