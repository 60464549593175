export const onboardingOptions = [
  { label: "Not Started", value: "Not_Started" },
  { label: "Left Message", value: "Left_Message" },
  {
    label: "Scheduled in person",
    value: "Scheduled_in_Person",
  },
  { label: "Reschedule", value: "Reschedule" },
  {
    label: "Sent Welcome Package",
    value: "Sent_Welcome_Package",
  },
  {
    label: "Phone Onboarding",
    value: "Scheduled_on_Phone",
  },
  { label: "2nd Onboarding", value: "2nd_Onboarding" },
  {
    label: "2nd Onboarding Done",
    value: "2nd_Onboarding_Done",
  },
];

export const studentStatuses = {
  ACTIVE: 1,
  ACTIVE_VERIFIED: 2,
  INACTIVE: 3,
  DRAFT: 4,
  PENDING: 5,
};

export const onboardingStatus = onboardingOptions.reduce((prev, curr) => {
  prev[curr.value] = curr.label;
  return prev;
}, {});
export const onboardingStatuses = Object.keys(onboardingStatus);
export const onboardingStatusLabels: string[] = Object.values(onboardingStatus);
