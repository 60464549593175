import React, { FC } from "react";
import { adminSelectors, listsSelectors } from "../../../../state";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {
  container,
  iconStyle,
  phoneStyle,
  pinStyle,
  pointsCaption,
} from "./General.styles";
import {
  LocalPhoneIcon,
  LocationOnIcon,
  OpenInNewIcon,
} from "../../../../components";
import { useNavigate } from "react-router-dom";
import AdminPages from "../../index";
import Row from "./Row";
import PointsRow from "./PointsRow";
import { numsWithCommasWithNeg } from "../../../../lib";

const General: FC = () => {
  const navigate = useNavigate();
  const student = useSelector(adminSelectors.student);
  const _neighborhoods = useSelector(listsSelectors.neighborhoodById);

  const handleCustomerClick = (
    id: number,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    navigate(AdminPages.customer.path.replace(":id", id.toString(10)));
  };
  if (!student?.customer) {
    console.error("student is missing customer");
    return null;
  }
  const {
    pin,
    customer: {
      id: customerId,
      phone_numbers = [],
      first_name,
      last_name,
      first_name_yiddish,
      last_name_yiddish,
      address,
    },
    cache_point,
    student_months,
  } = student!;

  const homePhone =
    phone_numbers?.filter((numberObj) => {
      return numberObj.type === "home";
    })?.[0]?.number ?? "";

  const cellPhone =
    phone_numbers?.filter((numberObj) => {
      return numberObj.type === "cell";
    })?.[0]?.number ?? "";

  const neighborhood = _neighborhoods[address?.neighborhood_id]?.name ?? "";

  const chaburaType = student_months[0]?.v_chaburah?.type ?? "";
  const chaburaName = student_months[0]?.v_chaburah?.name ?? "";
  const chaburaLocation = student_months[0]?.v_chaburah?.location ?? "";
  let chaburaTypeName = "";

  switch (chaburaType) {
    case "live":
      chaburaTypeName = "Masmidim";
      break;
    case "remote":
      chaburaTypeName = "CD";
      break;
  }

  return (
    <Stack direction={"row"} sx={container} gap={2}>
      {/*Phones and address*/}
      <Stack flexGrow={1}>
        <Typography variant={"body1"} sx={pinStyle}>
          Pin
          <Typography component={"span"} variant={"body2"}>
            {pin}
          </Typography>
        </Typography>

        <Stack direction={"row"}>
          <LocalPhoneIcon sx={iconStyle} />

          <Box sx={{ ml: "12px" }}>
            <Typography variant={"body2"} sx={phoneStyle}>
              Phone
              <Typography component={"span"} variant={"inherit"}>
                {homePhone}
              </Typography>
            </Typography>
            <Typography variant={"body2"}>Cell {cellPhone}</Typography>
          </Box>
        </Stack>

        <Stack direction={"row"} sx={{ mt: "10px" }}>
          <LocationOnIcon sx={iconStyle} />

          <Box sx={{ ml: "12px" }}>
            <Typography variant={"body2"} sx={phoneStyle}>
              {`${address?.address || ""} ${address?.city || ""} ${
                address?.state || ""
              } ${address?.zip || ""}`}
            </Typography>
            <Typography variant={"body2"}>{neighborhood}</Typography>
          </Box>
        </Stack>
      </Stack>

      {/*Chabura*/}
      <Stack minWidth={"30%"} justifyContent={"space-between"}>
        <Row name={"Type"} value={chaburaTypeName} />
        <Row name={"חבורה"} value={chaburaName} />
        <Row name={"כתה"} value={student?.grade_yiddish} />
        <Row name={"Shul"} value={chaburaLocation} />
        <Row
          name={"Customer"}
          value={
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Typography variant={"subtitle2"}>{`${
                  first_name_yiddish || ""
                } ${last_name_yiddish}`}</Typography>
                <Typography variant={"subtitle2"}>{`${
                  first_name || ""
                } ${last_name}`}</Typography>
              </Box>

              <IconButton
                color="primary"
                sx={{ backgroundColor: "secondary.main", borderRadius: 0.5 }}
                onClick={handleCustomerClick.bind(null, customerId)}
              >
                <OpenInNewIcon
                  sx={{ color: "#082B65", height: "10px", width: "10px" }}
                />
              </IconButton>
            </Stack>
          }
        />
      </Stack>

      {/*Points*/}
      <Stack minWidth={"25%"} justifyContent={"flex-start"}>
        <Typography variant={"h6"} sx={pointsCaption}>
          Points
        </Typography>

        <PointsRow
          name={"Total earned"}
          value={numsWithCommasWithNeg(cache_point?.earned) ?? ""}
        />
        <PointsRow
          name={"Total used"}
          value={numsWithCommasWithNeg(cache_point?.used) ?? ""}
        />
        <PointsRow
          name={"Balance"}
          value={numsWithCommasWithNeg(cache_point?.balance) ?? ""}
          extraClassName={"balance"}
        />
      </Stack>
    </Stack>
  );
};

export default General;
