import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  listsSelectors,
  SubjectItem,
  uiActions,
  uiSelectors,
} from "../../../../state";
import { Props } from "./TabCurriculum";
import React, { useEffect, useState } from "react";
import { CurriculumItem } from "../schema";
import { gematriya } from "../../../../lib";
import { Option } from "../../../../components/Select/Select";
import { buildYearsOptions } from "../../ChangeStudentPage/utils";
import { useGetPageMode } from "../utils";

export function useTabCurriculum({ formik }: Props) {
  const dispatch = useDispatch();
  const { pageMode } = useGetPageMode();
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } =
    formik;

  const control = useSelector(uiSelectors.control);
  const months = useSelector(listsSelectors.months);
  const tracksList = useSelector(listsSelectors.tracks);
  const monthId = useSelector(adminSelectors.currentMonthId);
  const _monthById = useSelector(listsSelectors.monthById);
  const [rows, setRows] = useState(values.semesters);
  const [years, setYears] = useState<Option[]>([]);
  const [semesterTypes] = useState<Option[]>(() => {
    return [
      {
        label: "ווינטער",
        value: "winter",
      },
      {
        label: "זיממער",
        value: "summer",
      },
    ];
  });
  const [tracks, setTracks] = useState<Option[]>([]);

  useEffect(() => {
    dispatch(adminActions.getCurrentMonth());
  }, [dispatch]);

  //needed to figure out on load which ones are deletable
  useEffect(() => {
    if (values.semesters?.length && monthId) {
      const _rows = values.semesters.map((s) => ({
        ...s,
        isDeletable:
          !!s.semester_id && s.semester_id > _monthById[monthId].semester_id,
      }));
      setRows(_rows);
    } else {
      setRows([]);
    }
  }, [values.semesters, monthId, _monthById]);

  const handleAddZman = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const arr = JSON.parse(
      JSON.stringify(values.semesters ?? []),
    ) as CurriculumItem[];

    if (arr?.length) {
      const arrayEnd = arr.at(-1);
      const semester_id = arrayEnd?.semester_id! + 1;
      let semesterYear = arrayEnd?.semesterYear || "1";
      const startYear = arrayEnd?.startYear;
      const startSemester = arrayEnd?.startSemester;
      const newArr = arr.map((a) => ({
        ...a,
        isDeletable:
          a.semester_id &&
          monthId &&
          a.semester_id > _monthById[monthId].semester_id,
      }));
      if (startSemester === "winter" && arr.length % 2 === 0) {
        semesterYear = (parseInt(semesterYear) + 1).toString();
      } else if (startSemester === "summer" && arr.length % 2 !== 0) {
        semesterYear = (parseInt(semesterYear) + 1).toString();
      }
      newArr.push({
        index: arr.length,
        semester_id,
        startYear,
        startSemester,
        semesterYear,
        semester: `${gematriya(semesterYear.toString().slice(2))} ${
          startSemester === "summer"
            ? arr.length % 2 === 0
              ? "זיממער"
              : "ווינטער"
            : arr.length % 2 === 0
            ? "ווינטער"
            : "זיממער"
        }`,
        subject_id: null,
        subject: null,
        isDeletable: monthId && semester_id > _monthById[monthId].semester_id,
      });

      setFieldValue?.("semesters", newArr);
    }
  };

  useEffect(() => {
    const t = tracksList.map(
      ({ id, name }) => ({ label: name, value: id.toString() } as Option),
    );

    setTracks(t);
    setYears(buildYearsOptions(months));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (control?.type === "remove") {
      const arr = JSON.parse(
        JSON.stringify(values.semesters ?? []),
      ) as CurriculumItem[];

      const newArr = arr
        .filter((a) => a.index !== control.id)
        .map((a, index) => ({
          ...a,
          index,
          isDeletable:
            a.semester_id &&
            monthId &&
            a.semester_id > _monthById[monthId].semester_id,
        }));

      dispatch(uiActions.setControl(null));

      setFieldValue?.("semesters", newArr);
    }

    if (control?.type === "edit") {
      const arr = JSON.parse(
        JSON.stringify(values.semesters ?? []),
      ) as CurriculumItem[];

      const { id, name_hebrew } = control.value as SubjectItem;

      arr[control.id].subject_id = id;
      arr[control.id].subject = name_hebrew;

      dispatch(uiActions.setControl(null));

      setFieldValue?.("semesters", arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, control, monthId]);

  return {
    values,
    touched,
    errors,
    years,
    semesterTypes,
    tracks,
    rows,
    handleAddZman,
    handleChange,
    handleBlur,
    pageMode,
  };
}
