import React, { FC, useState } from "react";
import { Box, Button, Fade, Menu, MenuItem, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { AddIcon, EditIcon } from "../../../../components";
import { useNavigate } from "react-router-dom";
import AddBonusPoints from "../../components/Modals/AddBonusPoints";

type MenuTypes = "points" | "test";

interface Props {
  onAddMonthlyPoints?: () => void;
  onEnterTest?: () => void;
  studentId: number;
}

const Toolbar: FC<Props> = ({ onAddMonthlyPoints, onEnterTest, studentId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [bonusPointsModal, setBonusPointsModal] = useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleBonusPointsModalClose = () => {
    setBonusPointsModal(null);
  };

  const handleMenuClick = (
    menu: MenuTypes,
    e: React.MouseEvent<HTMLLIElement>,
  ) => {
    e.stopPropagation();

    handleClose();

    switch (menu) {
      case "points":
        onAddMonthlyPoints?.();
        break;

      case "test":
        onEnterTest?.();
        break;
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClick}
        color="secondary"
        disableElevation={true}
        sx={{
          minWidth: "40px",
          minHeight: "40px",
          marginLeft: "32px",
        }}
      >
        <MoreHorizIcon fontSize="small" sx={{ mr: -0.5, ml: -0.5 }} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleMenuClick.bind(null, "points")}>
          <EditIcon sx={{ marginRight: 2 }} color="primary" />
          <Typography variant="h6" textTransform={"uppercase"}>
            Enter monthly points
          </Typography>
        </MenuItem>

        <MenuItem onClick={handleMenuClick.bind(null, "test")}>
          <EditIcon sx={{ marginRight: 2 }} color="primary" />
          <Typography variant="h6" textTransform={"uppercase"}>
            Enter test
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(`/admin/student/edit-student/${studentId}?tab=enrollment`)
          }
        >
          <EditIcon sx={{ marginRight: 2 }} color="primary" />
          <Typography variant="h6" textTransform={"uppercase"}>
            Enter student path
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setBonusPointsModal({
              studentId,
            });
          }}
        >
          <AddIcon sx={{ marginRight: 2 }} color="primary" />
          <Typography variant="h6" textTransform={"uppercase"}>
            Add bonus points
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(`/admin/prizes/add-redemption?id=${studentId}`)
          }
        >
          <AddIcon sx={{ marginRight: 2 }} color="primary" />
          <Typography variant="h6" textTransform={"uppercase"}>
            Add redemption
          </Typography>
        </MenuItem>
      </Menu>
      <AddBonusPoints
        modal={bonusPointsModal}
        onClose={handleBonusPointsModalClose}
      />
    </Box>
  );
};

export default React.memo(Toolbar);
