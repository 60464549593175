import React, { FC } from "react";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import SimplePageHeader from "../../../components/SimplePageHeader";
import { useEditCustomerPage } from "./useEditCustomerPage";
import {
  bottomSectionStyle,
  btnStyle,
  topSectionStyle,
  ErrorMessageStyle,
} from "./EditCustomerPage.styles";
import { Fields, initialValues, validationSchema } from "./schema";
import { ErrorMessage, Form, Formik } from "formik";
import { Button, ButtonGroup, Divider, Grid, Typography } from "@mui/material";
import { FormikInput } from "../../../components";
import { FormikSelect } from "../../../components/Select";
import { getButtonVariant } from "../../../lib";
import CheckBox from "../../../components/CheckBox";
import CustomerExistsModal from "../components/Modals/CustomerExistsModal";
import { AddressForm } from "../components/AddressForm";

const EditCustomerPage: FC = () => {
  const {
    innerRef,
    countryCodes,
    handleBackButtonClick,
    handleSubmit,
    validatePhone,
    showModal,
    setShowModal,
    data,
    isCurrencyDisabled,
  } = useEditCustomerPage();

  return (
    <Page>
      <TopSection style={topSectionStyle}>
        <SimplePageHeader
          title={"Edit customer"}
          onBackButtonClick={handleBackButtonClick}
        />
      </TopSection>

      <BottomSection style={bottomSectionStyle}>
        <Formik<Fields>
          innerRef={innerRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, handleChange, setFieldValue }) => {
            return (
              <Form>
                <Typography variant={"h6"} sx={{ mb: 2 }}>
                  Customer details
                </Typography>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikInput name="last_name_yiddish" label="לעצטע נאמען" />
                  </Grid>

                  <Grid item xs={4}>
                    <FormikInput
                      name="first_name_yiddish"
                      label="ערשטע נאמען"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikInput name="first_name" label="First name" />
                  </Grid>

                  <Grid item xs={4}>
                    <FormikInput name="last_name" label="Last name" />
                  </Grid>
                </Grid>

                <Typography variant={"h6"} sx={{ mt: 7 }}>
                  Contact
                </Typography>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item md={3}>
                        <FormikSelect
                          name="home_country_code_id"
                          label={"Code"}
                          options={countryCodes}
                        />
                      </Grid>
                      <Grid item md={9}>
                        <FormikInput name="home_phone" onBlur={validatePhone} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item md={3}>
                        <FormikSelect
                          name="mobile_country_code_id"
                          label={"Code"}
                          options={countryCodes}
                        />
                      </Grid>
                      <Grid item md={9}>
                        <FormikInput
                          name="mobile_phone"
                          onBlur={validatePhone}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  spacing={10}
                >
                  <Grid item xs={4}>
                    <FormikInput name="email" label="Email" />
                  </Grid>
                </Grid>

                <AddressForm
                  spacing={10}
                  isCurrencyDisabled={isCurrencyDisabled}
                />

                <Grid container direction="row">
                  <Grid item xs={8}>
                    <FormikInput
                      rows={3}
                      multiline={true}
                      name="notes"
                      label="Notes"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="start">
                  <Grid item xs={12}>
                    <ButtonGroup
                      sx={btnStyle(touched.status_id && !!errors.status_id)}
                    >
                      <Button
                        variant={getButtonVariant(values.status_id === 1)}
                        disabled={values.status_id !== 1}
                      >
                        Active
                      </Button>

                      <Button
                        variant={getButtonVariant(values.status_id === 4)}
                        disabled={values.status_id !== 4}
                      >
                        Draft
                      </Button>

                      <Button
                        variant={getButtonVariant(values.status_id === 3)}
                        disabled={values.status_id !== 3}
                      >
                        Inactive
                      </Button>
                    </ButtonGroup>

                    <CheckBox
                      name={"completed"}
                      label={"Customer completed"}
                      checked={values.completed}
                      onChange={handleChange}
                      sx={{ ml: 3 }}
                    />
                  </Grid>
                  <ErrorMessage
                    component={ErrorMessageStyle}
                    name="status_id"
                  />
                </Grid>

                <Divider sx={{ my: 1.5 }} />

                <Button
                  variant="outlined"
                  sx={{ mr: 1.5, minWidth: "180px" }}
                  onClick={handleBackButtonClick}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  sx={{ mr: 1.5, minWidth: "180px" }}
                  type="submit"
                >
                  Save
                </Button>
                <CustomerExistsModal
                  showModal={showModal}
                  data={data}
                  onClose={() => {
                    setFieldValue("home_phone", "");
                    setFieldValue("mobile_phone", "");
                    setShowModal(false);
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </BottomSection>
    </Page>
  );
};

export default EditCustomerPage;
