import {
  AppThunk,
  CurriculumSemester,
  EditedChaburah,
  EditedCurriculum,
  EditedRoshKinyan,
  Group,
  GroupCurriculum,
  GroupValues,
  IndividualStudentsCheck,
  IndividualStudentsRewards,
  MonthlyValues,
  Neighborhood,
  NeighborhoodWithPrincipals,
  NewChaburah,
  AddCredit,
  NewNeighborhoodWithPrincipals,
  NewRefund,
  NewRoshKinyan,
  NewStudentAndEnrollment,
  NewStudentBilling,
  NewUser,
  Refund,
  StudentDetails,
  StudentEnrollmentEdit,
  StudentGeneralEdit,
  StudentHistory,
  StudentMonths,
  StudentReward,
  UpdatedPaymentData,
  UpdateGroupValues,
  User,
  Note,
  EditedTests,
  StudentHistoryDetails,
  OnboardingStudentEdit,
} from "..";
import { Navigation } from "../../lib";
import AdminPages from "../../pages/admin";
import { authClient, customerActions, uiActions } from "../states";
import { admin } from "./state";
import { AxiosRequestConfig } from "axios";
import { studentActions } from "../student/actions";

const { actions } = admin;

export const adminActions = {
  ...actions,
  getUsers(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users`);
      if (status === 200) {
        dispatch(actions.setUsers(data));
      } else {
        dispatch(uiActions.showError("Get users failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getUser(id: string): AppThunk<Promise<User | boolean>> {
    return async (dispatch): Promise<User | boolean> => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to get user"));
        return false;
      } else {
        return data;
      }
    };
  },
  createUser(values: NewUser): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/users`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("user saved"));
        dispatch(adminActions.getUsers());
      } else {
        if (data.message === "Validation fails for Invalid home phone format") {
          dispatch(
            uiActions.showError(
              "failed to save user Invalid home phone number",
            ),
          );
        } else if (
          data.message === "Validation fails for Invalid cellphone format"
        ) {
          dispatch(
            uiActions.showError("failed to save user Invalid cellphone number"),
          );
        } else {
          dispatch(uiActions.showError("failed to add user", data.message));
        }
        dispatch(uiActions.setLoading(false));
        return data;
      }
      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  updateUser(id: number, values: NewUser): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.put(`/users/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("user updated"));
        dispatch(adminActions.getUsers());
      } else {
        if (data.message === "Validation fails for Invalid home phone format") {
          dispatch(
            uiActions.showError(
              "failed to update user Invalid home phone number",
            ),
          );
        } else if (
          data.message === "Validation fails for Invalid cellphone format"
        ) {
          dispatch(
            uiActions.showError(
              "failed to update user Invalid cellphone number",
            ),
          );
        } else {
          dispatch(uiActions.showError("failed to update user", data.message));
        }
        dispatch(uiActions.setLoading(false));
        return data;
      }
      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  deleteUser(id: string | number): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(`/users/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("User successfully deleted"));
        dispatch(adminActions.getUsers());
      } else {
        dispatch(uiActions.showError("Failed to delete user", data.message));
      }
      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  updateProfile(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(`/profile`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Profile updated"));
        Navigation.go(AdminPages.dashboard.path);
      } else {
        dispatch(uiActions.showError("Profile update failed", data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getStudents(neighborhoods: Record<any, Neighborhood>): AppThunk {
    return async (dispatch, getState) => {
      const { students } = getState().admin;
      dispatch(uiActions.setCacheUpdateLoading(true));
      dispatch(actions.setCacheUpdatedAt(null));
      if (students.length === 0) {
        dispatch(uiActions.setLoading(true));
      }
      const { data, status, headers } = await authClient.get(`/students`);
      if (status === 200) {
        data.rows = data.map((s) => {
          return {
            ...s,
            __list: {
              neighborhoods,
            },
          };
        });
        dispatch(actions.setStudents(data));
        dispatch(actions.setCacheUpdatedAt(headers.students_updated_at));
      } else {
        dispatch(uiActions.showError("Get students failed"));
      }
      dispatch(uiActions.setLoading(false));
      dispatch(uiActions.setCacheUpdateLoading(false));
    };
  },
  getStudent(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(actions.setStudent(null));
      if (!id) {
        return;
      }
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<StudentDetails>(
        `/students/${id}`,
      );
      if (status === 200) {
        dispatch(actions.setStudent(data));
      } else {
        dispatch(uiActions.showError("Get student failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getStudentCalendar({ id, month_id }) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `students/${id}/calendar?month_id=${month_id}`,
      );
      if (status === 200) {
        dispatch(actions.setStudentCalendar(data));
      } else {
        dispatch(uiActions.showError("Get student calendar failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateStudentCalendar(id, payload) {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `students/${id}/calendar`,
        payload,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("Calendar updated"));
        dispatch(uiActions.setLoading(false));
        return true;
      } else {
        dispatch(uiActions.showError("Calendar update failed", data.message));
        dispatch(uiActions.setLoading(false));
        return false;
      }
    };
  },
  getStudentData(id: number): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/students/${id}`);

      if (status !== 200) {
        dispatch(uiActions.showError("Get students data failed"));
      }

      return data;
    };
  },
  getStudentHistory(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      // TODO: back-end has to return List<StudentHistory>
      const { data, status } = await authClient.get<StudentHistory[]>(
        `/students/${id}/history`,
      );

      if (status === 200) {
        dispatch(actions.setStudentHistory({ count: 0, rows: data }));
      } else {
        dispatch(uiActions.showError("Get student history failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getStudentHistoryDetails(id: number, semester_id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<StudentHistoryDetails[]>(
        `/students/${id}/history_details?semester_id=${semester_id}`,
      );

      if (status === 200) {
        dispatch(actions.setStudentHistoryDetails({ count: 0, rows: data }));
      } else {
        dispatch(uiActions.showError("Get student history details failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getStudentRewards(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      // TODO: back-end has to return List<StudentReward>
      const { data, status } = await authClient.get<StudentReward[]>(
        `/students/${id}/rewards`,
      );

      if (status === 200) {
        dispatch(actions.setStudentRewards({ count: 0, rows: data }));
      } else {
        dispatch(uiActions.showError("Get student rewards failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteStudentReward(studentId, rewardId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(
        `/students/${studentId}/rewards/${rewardId}`,
      );
      if (status === 204) {
        dispatch(adminActions.getStudentRewards(studentId));
      } else {
        dispatch(
          uiActions.showError("Delete student reward failed", data?.message),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getStudentsMonths(
    monthId?: string,
    getAll: boolean = true,
    isRecursive: boolean = false,
  ): AppThunk {
    return async (dispatch, getState) => {
      const { studentMonths } = getState().admin;
      dispatch(uiActions.setCacheUpdateLoading(true));
      dispatch(actions.setCacheUpdatedAt(null));
      if (!isRecursive) {
        dispatch(uiActions.setLoading(true));
      }
      //ToDo: would be good to check if the studentMonths[0].monthId === monthId
      //but studentMonths objects don't have monthId
      if (!studentMonths || studentMonths.length === 0) {
        getAll = false;
      }

      const config: AxiosRequestConfig = {
        params: {
          month_id: monthId || null,
          all: getAll,
        },
      };
      const { data, status, headers } = await authClient.get<StudentMonths[]>(
        `/students/monthly`,
        config,
      );

      if (status === 200) {
        dispatch(actions.setStudentMonths(data));
        dispatch(actions.setCacheUpdatedAt(headers.students_updated_at));
        if (getAll === false && !isRecursive) {
          dispatch(adminActions.getStudentsMonths(monthId, true, true));
        }
      } else if (status === 404) {
        dispatch(uiActions.showError("Student not enrolled for this month"));
      } else {
        dispatch(uiActions.showError("Get Chaburah months failed"));
      }
      dispatch(uiActions.setLoading(false));
      dispatch(uiActions.setCacheUpdateLoading(false));
    };
  },
  updateStudentMonths(values, monthId): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/students/monthly`,
        values,
      );
      if (status === 204) {
        dispatch(adminActions.getStudentsMonths(monthId));
      } else {
        dispatch(uiActions.showError("Failed to update student", data.message));
      }
      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  getBillingHistory(params: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/billing/${params}`);
      if (status !== 200) {
        dispatch(
          uiActions.showError("billing transaction failed", data.message),
        );
      }
      dispatch(uiActions.setLoading(false));
      return data;
    };
  },
  getBillingFilterValues(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/billing/filter_values");
      if (status !== 200) {
        dispatch(uiActions.showError("billing filter values failed"));
      }
      dispatch(uiActions.setLoading(false));
      return data;
    };
  },

  setCardDefault(customerId, paymentMethodId, revision): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/processor_transactions/${customerId}/set_default`,
        {
          paymentMethodId,
          revision,
        },
      );

      if (status === 204) {
        dispatch(customerActions.getCustomerCardInfo(customerId));
      } else if (status === 500) {
        dispatch(uiActions.showError(data.message));
      } else {
        dispatch(uiActions.showError("update card failed", data.message));
      }

      dispatch(uiActions.setLoading(false));
      return data;
    };
  },

  createStudent(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(
        `/students/enrollment`,
        values,
      );
      if (status === 200) {
        dispatch(actions.setStudent(data));
        dispatch(customerActions.getCustomer(values.customer_id));
        dispatch(uiActions.showSuccess("student saved successfully "));
        dispatch(uiActions.setLoading(false));
        return data;
      } else {
        dispatch(uiActions.showError("failed to save student", data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCurrentMonth(): AppThunk<Promise<number>> {
    return async (dispatch): Promise<number> => {
      const { data, status } = await authClient.get(`/current-month`);
      if (status === 200) {
        dispatch(actions.setCurrentMonth(data));
        return data;
      } else {
        dispatch(uiActions.showError("Get current month failed"));
      }
      return data;
    };
  },
  refreshCache(
    refreshAction: "getStudents" | "getStudentsMonths",
    monthId,
    neighborhoods,
  ): AppThunk {
    return async (dispatch) => {
      const url = monthId
        ? `/students/clear-cache?month_id=${monthId}`
        : "/students/clear-cache";
      const { status } = await authClient.get(url);
      if (status === 204) {
        switch (refreshAction) {
          case "getStudents":
            dispatch(adminActions.getStudents(neighborhoods));
            break;

          case "getStudentsMonths":
            dispatch(adminActions.getStudentsMonths(monthId));
            break;
        }
      } else {
        dispatch(uiActions.showError("Refresh Failed"));
      }
    };
  },
  updateStudent(
    id: string,
    _data: StudentGeneralEdit | OnboardingStudentEdit | { status_id: number },
  ): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { status, data } = await authClient.put(`/students/${id}`, _data);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Student updated"));
      } else if (status === 200) {
        dispatch(uiActions.showWarning(data.message, null));
      } else {
        dispatch(uiActions.showError("Failed to update student", data.message));
      }

      dispatch(uiActions.setLoading(false));

      return (status === 204 || status === 200) as any;
    };
  },
  updateStudentEnrollment(id: string, form: StudentEnrollmentEdit): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const {
        chaburah_id,
        month_id,
        override,
        teacher_id,
        set_call_date,
        is_reactivate,
        ..._rest
      } = form;
      const { data, status } = await authClient.put(
        `/students/${id}/enrollment`,
        {
          chaburah_id,
          month_id,
          override,
          teacher_id,
          set_call_date,
          is_reactivate,
        },
      );
      if (status === 204) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showSuccess("Student enrollment updated"));
      } else if (status === 200) {
        dispatch(uiActions.showMessage(data.message));
      } else {
        dispatch(
          uiActions.showError(
            "Failed to update student enrollment",
            data?.message,
          ),
        );
        dispatch(uiActions.setLoading(false));
      }
      dispatch(uiActions.setLoading(false));
      return (status === 204 || status === 200) as any;
    };
  },
  addStudentBillingCredit(id: number, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(
        `/students/${id}/billing`,
        values,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("Student billing(credit) updated"));
        Navigation.go(
          `${AdminPages.studentPage.path.replace(":id", id.toString())}`,
        );
      } else {
        dispatch(
          uiActions.showError(
            "Failed to update student billing(credit)",
            data?.message,
          ),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  createStudentAndEnrollment(values: NewStudentAndEnrollment): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { status, data } = await authClient.post(
        `/students/enrollment`,
        values,
      );
      if (status === 200) {
        dispatch(actions.setStudent(data));
        dispatch(customerActions.getCustomer(values.customer_id));
        dispatch(uiActions.showSuccess("Student enrollment created"));
        dispatch(uiActions.setLoading(false));
        return data;
      } else {
        dispatch(
          uiActions.showError(
            "Failed to create student enrollment",
            data.message,
          ),
        );
      }

      dispatch(uiActions.setLoading(false));
      return (status === 200) as any;
    };
  },

  updateStudentBilling(
    id: number | string,
    _data: UpdatedPaymentData,
  ): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/students/${id}/billing`,
        _data,
      );
      if (status === 204) {
        dispatch(
          uiActions.showSuccess(
            `Student billing updated(${_data.default_payment_method})`,
          ),
        );
      } else {
        dispatch(
          uiActions.showError(
            `Failed to update student's billing(${_data.default_payment_method})`,
            data?.message,
          ),
        );
      }

      dispatch(uiActions.setLoading(false));

      return (status === 204) as any;
    };
  },
  createStudentBilling(id: string, _data: NewStudentBilling): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { status, data } = await authClient.post(
        `/students/${id}/billing`,
        _data,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("Student billing created"));
      } else {
        dispatch(
          uiActions.showError(
            "failed to create student billing",
            data?.message,
          ),
        );
      }

      dispatch(uiActions.setLoading(false));

      return (status === 204) as any;
    };
  },
  getGroups(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<Group[]>(`/chaburahs`);

      if (status === 200) {
        dispatch(actions.setGroups(data));
      } else {
        dispatch(uiActions.showError("Get groups failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createChaburah(_data: NewChaburah): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any = await authClient.post<NewChaburah>(
        `/chaburahs`,
        _data,
      );

      if (status === 204) {
        dispatch(uiActions.showSuccess("chaburah has been created"));
        dispatch(adminActions.getGroups);
        Navigation.go(AdminPages.groups.path);
      } else {
        dispatch(
          uiActions.showError("failed to create chaburah", data?.message),
        );
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  updateChaburah(
    id: string,
    _data: EditedChaburah,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any = await authClient.put<EditedChaburah>(
        `/chaburah_edit/${id}`,
        _data,
      );

      if (status === 204) {
        dispatch(uiActions.showSuccess("chaburah has been updated"));
      } else {
        dispatch(
          uiActions.showError("Failed to update chaburah", data?.message),
        );
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  updateTeacher(_data: EditedRoshKinyan): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any = await authClient.put<EditedRoshKinyan>(
        `/chaburahs/teacher`,
        _data,
      );

      if (status === 200) {
        dispatch(uiActions.showSuccess("teacher has been updated"));
      } else {
        dispatch(
          uiActions.showError("failed to update teacher", data?.message),
        );
      }

      dispatch(uiActions.setLoading(false));

      return status === 200;
    };
  },
  updateCurriculum(
    chaburah_id: string,
    _data: EditedCurriculum,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any = await authClient.put<EditedCurriculum>(
        `/curriculum/${chaburah_id}`,
        _data,
      );

      if (status === 204) {
        dispatch(uiActions.showSuccess("curriculum has been updated"));
      } else {
        dispatch(
          uiActions.showError("failed to update curriculum", data.message),
        );
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  getChaburahStudents(chaburahId: string): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/chaburah/${chaburahId}`);
      if (status !== 200) {
        dispatch(uiActions.showError("Get group students failed"));
      }
      return data;
    };
  },
  getChaburahCurriculum(
    chaburahId: string,
  ): AppThunk<Promise<GroupCurriculum[]>> {
    return async (dispatch): Promise<GroupCurriculum[]> => {
      const { data, status } = await authClient.get(
        `/curriculum/${chaburahId}`,
      );
      if (status !== 200) {
        dispatch(uiActions.showError("Get group curriculum failed"));
      }
      return data;
    };
  },
  getChaburahValuesByMonth(
    chaburahId: string,
    monthId: string,
  ): AppThunk<Promise<GroupValues | null>> {
    return async (dispatch): Promise<GroupValues | null> => {
      const { data, status } = await authClient.get(
        `/chaburah/${chaburahId}/monthly?month_id=${monthId}`,
      );

      if (status === 404) {
        dispatch(uiActions.showError("No chaburah data for this month"));
        return null;
      }
      if (status !== 200) {
        dispatch(uiActions.showError("Get group values failed"));
        return null;
      }
      return data;
    };
  },
  updateChaburahValuesByMonth(
    id: number,
    values: UpdateGroupValues,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.put(
        `/chaburah/${id}/monthly`,
        values,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("Group updated"));
      } else if (status === 400) {
        dispatch(uiActions.showError(data.message));
      } else {
        dispatch(uiActions.showError("failed to update group", data?.message));
      }
      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  getChaburahEditData(chaburahId: string): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/chaburah_edit/${chaburahId}`,
      );
      if (status === 200) {
        dispatch(actions.setEditChaburahData(data));
      } else {
        dispatch(uiActions.showError("Get group data for editing failed"));
      }
    };
  },
  addCredit(_data: AddCredit, customerId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(
        `/customers/${customerId}/add_credit`,
        _data,
      );
      if (status === 204) {
        dispatch(customerActions.getCustomer(customerId));
        dispatch(customerActions.getCustomerBillingHistory(customerId));
      } else {
        dispatch(
          uiActions.showError("failed to create credit ", data?.message),
        );
      }
      dispatch(uiActions.setLoading(false));
      return (status === 204) as any;
    };
  },
  addNote(note: Note, studentId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(
        `/students/${studentId}/notes`,
        note,
      );
      if (status === 204) {
        dispatch(adminActions.getStudent(studentId));
      } else {
        dispatch(uiActions.showError("failed to create note", data?.message));
      }
      dispatch(uiActions.setLoading(false));
      return (status === 204) as any;
    };
  },
  deleteNote(studentId, noteId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(
        `/students/${studentId}/notes/${studentId}?note_id=${noteId}`,
      );
      if (status === 204) {
        dispatch(adminActions.getStudent(studentId));
      } else {
        dispatch(uiActions.showError("failed to delete note", data.message));
      }
      dispatch(uiActions.setLoading(false));
      return (status === 204) as any;
    };
  },
  updateNote(note: Note, studentId, noteId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/students/${studentId}/notes/${studentId}?note_id=${noteId}`,
        note,
      );
      if (status === 204) {
        dispatch(adminActions.getStudent(studentId));
      } else {
        dispatch(uiActions.showError("failed to update note", data?.message));
      }
      dispatch(uiActions.setLoading(false));
      return (status === 204) as any;
    };
  },
  getNeighborhoodWithPrincipals(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<
        NeighborhoodWithPrincipals[]
      >(`/neighborhoods`);

      if (status === 200) {
        dispatch(actions.setNeighborhoodWithPrincipals(data));
      } else {
        dispatch(uiActions.showError("Get neighborhoods failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  createNeighborhoodWithPrincipals(
    _data: NewNeighborhoodWithPrincipals,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any =
        await authClient.post<NewNeighborhoodWithPrincipals>(
          `/neighborhoods`,
          _data,
        );

      if (status === 204) {
        dispatch(uiActions.showSuccess("Neighborhood has been created"));
      } else {
        dispatch(
          uiActions.showError("Failed to create neighborhood", data?.message),
        );
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  updateNeighborhoodWithPrincipals(
    id: string | number,
    _data: NewNeighborhoodWithPrincipals,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any =
        await authClient.put<NewNeighborhoodWithPrincipals>(
          `/neighborhoods/${id}`,
          _data,
        );

      if (status === 204) {
        dispatch(uiActions.showSuccess("Neighborhood has been updated"));
      } else {
        dispatch(
          uiActions.showError("Failed to update neighborhood", data.message),
        );
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  deleteNeighborhoodWithPrincipals(
    id: string | number,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data } = await authClient.delete(`/neighborhoods/${id}`);

      if (status === 204) {
        dispatch(
          uiActions.showSuccess("Neighborhood with principal has been deleted"),
        );
      } else {
        dispatch(
          uiActions.showError(
            "failed to delete neighborhood with principal",
            data.message,
          ),
        );
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },

  createRoshKinyan(user: {
    user_id: number;
  }): AppThunk<Promise<NewRoshKinyan | null>> {
    return async (dispatch): Promise<NewRoshKinyan | null> => {
      dispatch(uiActions.setLoading(true));

      const { data, status }: any = await authClient.post<NewRoshKinyan>(
        `/chaburahs/teacher`,
        user,
      );

      dispatch(uiActions.setLoading(false));

      if (status === 200) {
        return data;
      }

      dispatch(
        uiActions.showError("failed to create rosh kinyan", data?.message),
      );

      return null;
    };
  },
  getCurriculumValuesByMonth(
    id: string,
  ): AppThunk<Promise<CurriculumSemester[] | null>> {
    return async (dispatch): Promise<CurriculumSemester[] | null> => {
      dispatch(uiActions.setLoading(true));

      const { data, status }: any = await authClient.get<CurriculumSemester[]>(
        `/curriculum/${id}/monthly`,
      );

      dispatch(uiActions.setLoading(false));

      if (status === 200) {
        return data;
      }

      dispatch(
        uiActions.showError("Failed to create rosh kinyan", data.message),
      );

      return null;
    };
  },
  createIndividualStudentsCheck(
    id: number,
    check: IndividualStudentsCheck,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any =
        await authClient.put<IndividualStudentsCheck>(
          `/students/${id}/checks`,
          check,
        );
      dispatch(uiActions.setLoading(false));

      if (status === 204) {
        dispatch(studentActions.getStudentMonth(id, check.month_id));
      } else {
        dispatch(
          uiActions.showError("failed to create student's check", data.message),
        );
      }
      return status === 204;
    };
  },
  createIndividualStudentsRewards(
    id: number,
    check: IndividualStudentsRewards,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any =
        await authClient.post<IndividualStudentsRewards>(
          `/students/${id}/rewards`,
          check,
        );

      dispatch(uiActions.setLoading(false));

      if (status !== 204) {
        dispatch(
          uiActions.showError(
            "Failed to create student's rewards",
            data.message,
          ),
        );
      }

      return status === 204;
    };
  },
  updateMonthlyValuesForAllChaburah(
    month_id: number,
    values: MonthlyValues,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/monthly_settings/${month_id}`,
        values,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("Month values updated"));
      } else {
        dispatch(
          uiActions.showError("failed to update month values", data?.message),
        );
      }
      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  getChaburahMonthDates(month_id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      // force rerender
      dispatch(actions.setChaburahMonthDates(null));
      const { data, status } = await authClient.get(
        `/monthly_settings/${month_id}`,
      );
      if (status === 200) {
        dispatch(actions.setChaburahMonthDates(data));
      } else {
        dispatch(uiActions.showError("Get dates failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRefundableTransactions(
    person_id: number,
  ): AppThunk<Promise<Refund[] | null>> {
    return async (dispatch): Promise<Refund[] | null> => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.get<Refund[]>(
        `/customers/${person_id}/refund`,
      );

      if (status !== 200) {
        dispatch(uiActions.showError("failed to get refundable transactions"));
        return null;
      }

      dispatch(uiActions.setLoading(false));

      return data;
    };
  },
  createRefund(
    person_id: number,
    _data: NewRefund,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any = await authClient.put<NewRefund>(
        `/customers/${person_id}/refund`,
        _data,
      );

      if (status === 204) {
        dispatch(uiActions.showSuccess("refund updated"));
      } else {
        dispatch(uiActions.showError("failed to update refund", data?.message));
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  updateIndividualStudentTest(
    studentId: number,
    _data: EditedTests,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));

      const { status, data }: any = await authClient.put<EditedTests>(
        `/students/${studentId}/test`,
        _data,
      );

      if (status === 204) {
        dispatch(uiActions.showSuccess("Test updated successfully!"));
        dispatch(studentActions.getStudentMonth(studentId, _data.month_id));
      } else {
        dispatch(
          uiActions.showError("Failed to update student test", data.message),
        );
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  getCsvData(type: "student" | "billing"): AppThunk<Promise<string>> {
    return async (dispatch): Promise<string> => {
      const { data, status } =
        type === "student"
          ? await authClient.get(`/student-report`)
          : await authClient.get(`/billing/report`);
      if (status === 200) {
        return data;
      } else {
        dispatch(uiActions.showError("Get report failed"));
      }
      return data;
    };
  },
};
