import React from "react";
import { Grid } from "@mui/material";
import { CurrencyCard } from "./AmountCard";

export const BillingCards = ({ balanceDetails, currency }) => {
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-between"
      sx={{ width: "100%", mt: 1 }}
    >
      <Grid item sx={{ width: "33.33%" }}>
        <CurrencyCard
          currency={currency}
          amount={balanceDetails?.charged}
          label="TOTAL CHARGES"
        />
      </Grid>
      <Grid item sx={{ width: "33.33%" }}>
        <CurrencyCard
          currency={currency}
          amount={balanceDetails?.applied}
          label="TOTAL CHARGES PAID"
        />
      </Grid>
      <Grid item sx={{ width: "33.33%" }}>
        <CurrencyCard
          color={balanceDetails?.open_balance >= 0 ? "#128E56" : "#BB2018"}
          currency={currency}
          amount={balanceDetails?.open_balance}
          label="OPEN BALANCE"
        />
      </Grid>
    </Grid>
  );
};
