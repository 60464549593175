import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Calander,
  Group,
  List,
  monthDates,
  NeighborhoodWithPrincipals,
  Student,
  StudentDetails,
  StudentHistory,
  StudentHistoryDetails,
  StudentMonths,
  StudentReward,
  User,
} from "../types";

export interface AdminState {
  users: List<User>;
  students: Student[];
  editChaburahData: any;
  student: StudentDetails | null;
  studentCalendar: Calander | undefined;
  studentHistory: List<StudentHistory>;
  studentHistoryDetails: List<StudentHistoryDetails>;
  studentRewards: List<StudentReward>;
  studentMonths: StudentMonths[];
  groups: Group[] | null;
  settings: {
    neighborhoods: NeighborhoodWithPrincipals[] | null;
  };
  activeBonus: StudentReward | null;
  activeEnrollmentId?: string | number;
  currentMonth?: number | null;
  cacheUpdatedAt?: string | null;
  monthDates?: monthDates | null;
}

const initialState: AdminState = {
  users: {
    count: 0,
    rows: [],
  },
  activeBonus: null,
  students: [],
  student: null,
  studentHistory: {
    count: 0,
    rows: [],
  },
  studentHistoryDetails: {
    count: 0,
    rows: [],
  },
  studentRewards: {
    count: 0,
    rows: [],
  },
  studentCalendar: undefined,
  editChaburahData: [],
  studentMonths: [],
  groups: null,
  settings: {
    neighborhoods: null,
  },
  activeEnrollmentId: "",
  currentMonth: null,
  cacheUpdatedAt: "",
  monthDates: null,
};

export const admin = {
  ...createSlice({
    name: "admin",
    initialState,
    reducers: {
      setUsers(state, { payload }: PayloadAction<List<User>>) {
        state.users = payload;
      },
      setCurrentMonth(state, { payload }: PayloadAction<number>) {
        state.currentMonth = payload;
      },
      setCacheUpdatedAt(state, { payload }: PayloadAction<string | null>) {
        state.cacheUpdatedAt = payload;
      },
      setEnrollmentChaburahId(state, { payload }: PayloadAction<any>) {
        state.activeEnrollmentId = payload;
      },
      setEditChaburahData(state, { payload }: PayloadAction<any>) {
        state.editChaburahData = payload;
      },
      setStudents(state, { payload }: PayloadAction<Student[]>) {
        state.students = payload;
      },
      setStudent(state, { payload }: PayloadAction<StudentDetails | null>) {
        state.student = payload;
      },
      setStudentCalendar(state, { payload }: PayloadAction<any>) {
        state.studentCalendar = payload;
      },
      setActiveBonus(state, { payload }: PayloadAction<any>) {
        state.activeBonus = payload;
      },
      setStudentHistory(
        state,
        { payload }: PayloadAction<List<StudentHistory>>,
      ) {
        state.studentHistory = payload;
      },
      setStudentHistoryDetails(
        state,
        { payload }: PayloadAction<List<StudentHistoryDetails>>,
      ) {
        state.studentHistoryDetails = payload;
      },
      setStudentRewards(
        state,
        { payload }: PayloadAction<List<StudentReward>>,
      ) {
        state.studentRewards = payload;
      },
      setStudentMonths(state, { payload }: PayloadAction<StudentMonths[]>) {
        state.studentMonths = payload;
      },
      setGroups(state, { payload }: PayloadAction<Group[]>) {
        state.groups = payload;
      },
      setChaburahMonthDates(
        state,
        { payload }: PayloadAction<monthDates | null>,
      ) {
        state.monthDates = payload;
      },
      setNeighborhoodWithPrincipals(
        state,
        { payload }: PayloadAction<NeighborhoodWithPrincipals[] | null>,
      ) {
        state.settings.neighborhoods = payload;
      },
    },
  }),
  persist: false,
};
