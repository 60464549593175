import React from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { uiSelectors, useSelector } from "../../state";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";

function _MainLayout() {
  const loading = useSelector(uiSelectors.loading);

  return (
    <>
      <Container maxWidth={false} disableGutters={true}>
        <div>
          <Outlet />
        </div>
      </Container>
      <Notification />
      {loading && (
        <Backdrop open={loading}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </>
  );
}

export const MainLayout = React.memo(_MainLayout);
