import { SxProps, Theme } from "@mui/material";

export const detailsLeftSide: SxProps<Theme> = {
  flexGrow: 1,
  borderRight: "1px solid #DDE3EB",
};

export const detailsRightSide: SxProps<Theme> = {
  width: "30%",
};

export const detailsNotesCaption: SxProps<Theme> = {
  color: "#000000",
  fontSize: "14px",
  fontWeight: "bold",
  letterSpacing: "0.1px",
  lineHeight: "16px",
  mb: "16px",
};
