import React, { FC } from "react";
import TabContainer from "../../components/TabContainer";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StudentGeneralFields } from "../schema";
import { FormikProps } from "formik";
import { btnStyle, container, gridItem, textField } from "./TabGeneral.styles";
import Select from "../../../../components/Select";
import { SystemStyleObject, Theme } from "@mui/system";
import { useTabGeneral } from "./useTabGeneral";
import BarMitzva from "../BarMitzva";
import CheckBox from "../../../../components/CheckBox";
import {
  getButtonVariant,
  getHebrewBirthday,
  isDateValid,
} from "../../../../lib";
import Autocomplete from "../../../../components/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers";
import { authSelectors, useSelector } from "../../../../state";
import { Link } from "react-router-dom";
import { LaunchIcon } from "../../../../components";
import { StudentStatusToggle } from "../StudentStatusToggle";

interface Props {
  formik: FormikProps<StudentGeneralFields>;
  studentId;
  setReactivating;
  reactivating;
  setReadyToReactivate;
}

const TabGeneral: FC<Props> = ({
  formik,
  studentId,
  setReactivating,
  reactivating,
  setReadyToReactivate,
}) => {
  const {
    customer,
    values,
    handleStudentStatus,
    handleChange,
    setFieldValue,
    handleBlur,
    schools,
    errors,
    grades,
    touched,
    setTouched,
    months,
    years,
    days,
    schoolBuildings,
    school,
    setSchool,
    pageMode,
    englishBirthday,
    setEnglishBirthday,
    students,
    student,
    handleSelectStudent,
    handleSearchStudent,
  } = useTabGeneral(formik);
  const isAdmin = useSelector(authSelectors.isAdmin);

  if (!schoolBuildings.length) return null;
  return (
    <TabContainer sx={{ p: "32px 55px" }}>
      <Box sx={{ ...container, width: 820 }}>
        {pageMode === "insert" && customer ? (
          <Typography variant={"h6"} sx={{ mb: 4 }}>
            Add a student for - {customer.label}
          </Typography>
        ) : (
          <>
            <StudentStatusToggle
              student={values}
              studentId={studentId}
              handleReactivateClick={setReactivating}
            />
            {reactivating && (
              <Box color="#F0921F" fontSize={16} padding={2} paddingLeft={0}>
                Before reactivating a student, please review the student profile
                and confirm that all student information is up to date.
              </Box>
            )}
          </>
        )}

        <Typography variant={"h6"}>Student details</Typography>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          width="100%"
        >
          <Grid item sx={gridItem}>
            <TextField
              sx={textField}
              id="first_name_yiddish"
              name="first_name_yiddish"
              label="ערשטע נאמען"
              fullWidth={true}
              value={values.first_name_yiddish}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.first_name_yiddish && !!errors.first_name_yiddish}
              helperText={
                touched.first_name_yiddish && errors.first_name_yiddish
              }
            />
          </Grid>

          <Grid item sx={gridItem}>
            <TextField
              sx={textField}
              id="last_name_yiddish"
              name="last_name_yiddish"
              label="לעצטע נאמען"
              fullWidth={true}
              value={values.last_name_yiddish}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.last_name_yiddish && !!errors.last_name_yiddish}
              helperText={touched.last_name_yiddish && errors.last_name_yiddish}
            />
          </Grid>

          <Grid item sx={gridItem}>
            <TextField
              sx={textField}
              id="first_name"
              name="first_name"
              label="First name"
              fullWidth={true}
              value={values.first_name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.first_name && !!errors.first_name}
              helperText={touched.first_name && errors.first_name}
            />
          </Grid>

          <Grid item sx={gridItem}>
            <TextField
              sx={textField}
              id="last_name"
              name="last_name"
              label="Last name"
              fullWidth={true}
              value={values.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.last_name && !!errors.last_name}
              helperText={touched.last_name && errors.last_name}
            />
          </Grid>
          <Grid item sx={gridItem}>
            <Autocomplete
              options={schools}
              value={school ?? ""}
              onChange={(e, value: any) => {
                setSchool(value);
                setTouched({ ...touched, school_building_id: true });
                setFieldValue("school_building_id", "");
                setTimeout(() => {
                  setFieldValue("school", value?.value);
                }, 0);
              }}
              placeholder={"School"}
              noOptionsText={"No schools..."}
              error={touched.school && !!errors.school}
              sx={{ width: "calc(100% - 40px)" }}
            />
          </Grid>

          <Grid item sx={gridItem}>
            <Select
              id="school_building_id"
              name="school_building_id"
              label={"Building"}
              sx={textField}
              options={schoolBuildings}
              value={values.school_building_id}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.school_building_id && !!errors.school_building_id}
              required={!!school}
              helperText={
                touched.school_building_id && errors.school_building_id
              }
            />
          </Grid>

          <Grid item sx={gridItem}>
            <Select
              id="grade"
              name="grade"
              label={"Grade"}
              sx={textField}
              options={grades}
              value={values.grade}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.grade && !!errors.grade}
              helperText={touched.grade && errors.grade}
            />
          </Grid>
        </Grid>

        <Typography variant={"h6"} sx={{ mt: 3 }}>
          Hebrew birthday
        </Typography>

        <Stack direction={"row"} justifyContent={"flex-start"} gap={1.5}>
          <Select
            id="year"
            name="year"
            sx={[
              textField as SystemStyleObject,
              { width: "175px" } as SystemStyleObject,
            ]}
            width={"unset"}
            label={"שנה"}
            placeholder={"שנה"}
            options={years}
            value={values.year || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.year && !!errors.year}
            helperText={touched.year && errors.year}
          />

          <Select
            id="birth_month_id"
            name="birth_month_id"
            sx={[
              textField as SystemStyleObject,
              { width: "175px" } as SystemStyleObject,
            ]}
            width={"unset"}
            label={"חודש"}
            placeholder={"חודש"}
            options={months}
            value={values.birth_month_id || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.birth_month_id && !!errors.birth_month_id}
            helperText={touched.birth_month_id && errors.birth_month_id}
          />

          <Select
            id="birth_month_day"
            name="birth_month_day"
            sx={[
              textField as SystemStyleObject,
              { width: "175px" } as SystemStyleObject,
            ]}
            width={"unset"}
            label={"יום"}
            placeholder={"יום"}
            options={days}
            value={values.birth_month_day || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.birth_month_day && !!errors.birth_month_day}
            helperText={touched.birth_month_day && errors.birth_month_day}
          />

          <DatePicker
            label="English birthday"
            value={englishBirthday?.date || ""}
            onChange={(date: any) => {
              if (isDateValid(date)) {
                setEnglishBirthday({
                  ...getHebrewBirthday(date),
                  date,
                });
              }
            }}
            renderInput={({ error: _e, ...params }) => (
              <TextField {...params} sx={{ ...textField, width: 200 }} />
            )}
          />
        </Stack>

        {pageMode === "edit" && <BarMitzva str={values.bar_mitzva} />}

        <Typography variant={"h6"} sx={{ mt: 6 }}>
          Other details
        </Typography>

        {pageMode === "edit" && (
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <CheckBox
              name={"registration"}
              label={"Siyum registration"}
              checked={values.registration}
              onChange={handleChange}
            />

            <CheckBox
              name={"transportation"}
              label={"Transportation"}
              checked={values.transportation}
              onChange={handleChange}
            />
          </Stack>
        )}

        {pageMode === "insert" && (
          <TextField
            sx={[
              textField as SystemStyleObject<Theme>,
              { width: "100%", pr: 5 } as SystemStyleObject<Theme>,
            ]}
            rows={2}
            multiline={true}
            id="notes"
            name="notes"
            label="Notes"
            fullWidth={true}
            value={values.notes}
            onChange={handleChange}
          />
        )}

        {pageMode === "edit" && (
          <>
            <Typography variant={"h6"} sx={{ mt: 5, mb: "11px" }}>
              Student's status
            </Typography>

            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={"32px"}
            >
              {values.status_id != 3 && (
                <ButtonGroup
                  sx={btnStyle(touched.status_id && !!errors.status_id)}
                >
                  <Button
                    variant={getButtonVariant(values.status_id === 1)}
                    onClick={handleStudentStatus.bind(null, 1)}
                    disabled={values.status_id === 4 && !isAdmin}
                  >
                    Active
                  </Button>

                  <Button
                    variant={getButtonVariant(values.status_id === 4)}
                    disabled={values.status_id !== 4}
                  >
                    Draft
                  </Button>

                  <Button
                    variant={getButtonVariant(values.status_id === 3)}
                    onClick={handleStudentStatus.bind(null, 3)}
                  >
                    Inactive
                  </Button>
                </ButtonGroup>
              )}

              <CheckBox
                name={"completed"}
                label={"Student completed"}
                checked={values.completed}
                onChange={handleChange}
              />
            </Stack>
          </>
        )}
        <Typography variant={"h6"} sx={{ mt: 3 }}>
          Referred by
        </Typography>
        <Box display="inline-flex" alignItems="center">
          <Autocomplete
            options={students}
            value={student}
            onChange={handleSelectStudent}
            onInputChange={handleSearchStudent}
            placeholder={"Type to search for a student"}
            noOptionsText={"No students..."}
            error={
              touched.referring_student_id && !!errors.referring_student_id
            }
            sx={{ pr: 2, width: "380px" }}
          />
          {student && (
            <Link
              to={`/admin/student/${student?.value}`}
              target="_blank"
              rel="noopener"
            >
              {" "}
              <LaunchIcon />
            </Link>
          )}
        </Box>
        {reactivating && (
          <Box
            bgcolor={"rgba(240,146,31,0.16)"}
            padding={2}
            borderRadius="3px"
            maxWidth={550}
          >
            <CheckBox
              onClick={(e) => {
                setReadyToReactivate(e.target.checked);
              }}
              sx={{ color: "#F0921F" }}
              labelSx={{
                "& .MuiTypography-root": {
                  fontSize: "16px",
                  lineHeight: "16px",
                  paddingLeft: 1,
                },
              }}
              label="I have reviewed the student profile and confirm that all student information is up to date."
            />
          </Box>
        )}
      </Box>
    </TabContainer>
  );
};

export default TabGeneral;
