import React, { FC } from "react";
import { ModalData } from "./types";
import { Transition } from "../Transition";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useAddBonusPoints } from "./useAddBonusPoints";
import { Form, Formik } from "formik";
import { Fields, initialValues, validationSchema } from "./schema";
import { btnSx, caption, container, content } from "./AddBonusPoints.styles";
import { FormikInput } from "../../../../../components";

export interface Props {
  modal: ModalData | null;
  onClose?: (data: ModalData | null) => void;
}

const AddBonusPoints: FC<Props> = ({ modal, onClose }) => {
  const { innerRef, open, handleClose, handleSubmit } = useAddBonusPoints({
    modal,
    onClose,
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose?.(null)}
    >
      <DialogTitle>
        {modal?.mode === "edit" ? "Edit bonus points" : "Add bonus points"}
      </DialogTitle>

      <Formik<Fields>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={innerRef}
      >
        {({ submitForm }) => {
          return (
            <Form>
              <DialogContent sx={content}>
                <Typography variant={"subtitle2"} sx={caption}>
                  How many bonus points would you like to add?
                </Typography>

                <Grid container sx={container}>
                  <Grid item xs={4}>
                    <FormikInput name="points" label="Points" />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikInput name="reason" label="Reason" />
                  </Grid>
                </Grid>
              </DialogContent>

              <Divider
                variant="middle"
                sx={{ border: "1px solid #DDE3EB", mb: 2 }}
              />

              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={btnSx}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color={"primary"}
                  sx={btnSx}
                  onClick={submitForm}
                >
                  {modal?.mode === "edit" ? "Save Changes" : "Add"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddBonusPoints;
