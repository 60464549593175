import React from "react";
import { Field } from "formik";
import { useSelector } from "react-redux";
import { listsSelectors } from "../../state";
import { labelValueMonthGenerator } from "../../lib";
import CustomSelect from "./CustomSelect";

interface CustomSelectProps {
  options: any;
  isMulti?: boolean;
}

export const MonthsYearSelect = ({
  options,
  isMulti = true,
}: CustomSelectProps) => {
  const _months = useSelector(listsSelectors.monthById);

  return (
    <Field
      className="custom-select"
      name="months"
      options={labelValueMonthGenerator(_months, options, "month_id")}
      component={CustomSelect}
      placeholder="Months"
      isMulti={isMulti}
    />
  );
};

export default MonthsYearSelect;
