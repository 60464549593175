import { array, object, string, date } from "yup";
import {
  BalanceRow,
  Currency,
  DepositType,
  PaymentMethod,
  PrepayOptions,
} from "../../../state";
import { floatRegExp } from "../../../lib/regexp";
import { toFloat } from "../../../lib";

export interface NewPaymentFields {
  customer_id: string;
  amount: string;
  currency: Currency | string;
  paymentType: DepositType;
  payment_for: string | null;
  yearId: string;
  semesterTypeId: string;
  date: Date | null;
  balanceItems: BalanceRow[] | null;
  paymentMethod: PaymentMethod;
  prepayOptions: PrepayOptions | null;
  checkNumber: string | null;
  payment_method_id: number | null;
  note: string | null;
  remainingBalance: number;
  touchedAmount: boolean;
  paramsRow: string | null;
}

export const initPaymentState: NewPaymentFields = {
  customer_id: "",
  amount: "",
  currency: "",
  paymentType: "regular",
  payment_for: "",
  yearId: "",
  semesterTypeId: "",
  date: new Date(),
  balanceItems: null,
  paymentMethod: "cash",
  prepayOptions: null,
  checkNumber: "",
  payment_method_id: null,
  note: "",
  remainingBalance: 0,
  touchedAmount: true,
  paramsRow: "",
};

export const validationPaymentSchema = object().shape({
  // customer_id: string().required("Required").label("Customer"),
  amount: string()
    .required("Required")
    .test("amount", "${label} must be a number", (val = "") =>
      floatRegExp.test(val),
    )
    .test(
      "amount",
      "${label} must be no less than 0.05",
      (val = "0") => +val >= 0.05 || val === "",
    )
    .test(
      "amount",
      "${label} must be no greater than 100,000",
      (val = "0") => +val <= 100_000,
    )
    .label("Deposit amount"),
  date: date().label("Enter valid date").required("Enter date").label("Date"),
  yearId: string()
    .when(["paymentType"], {
      is: (paymentType: DepositType) => paymentType === "prepayment",
      then: string().required(),
    })
    .label("שנה"),
  semesterTypeId: string()
    .when(["paymentType"], {
      is: (paymentType: DepositType) => paymentType === "prepayment",
      then: string().required(),
    })
    .label("זמן"),
  payment_for: string()
    .when(["paymentType"], {
      is: (paymentType: DepositType) => paymentType === "other",
      then: string().required(),
    })
    .label("Payment for"),
  balanceItems: array()
    .nullable(true)
    .test(
      "payoffLessThanAmount",
      "Payoffs must be less than or equal to amount",
      (items, context) => {
        return (
          !items ||
          items
            .filter((b) => b.isSelected)
            .reduce((prev, curr) => prev + Math.abs(toFloat(curr.amount)), 0) <=
            context.parent.amount
        );
      },
    )
    .of(
      object().shape({
        amount: string()
          .test("amount", "${label} must be a number", (val = "") => {
            return val === "" || floatRegExp.test(val);
          })
          .test(
            "amountNotMoreThanBalance",
            "Payoffs must be less than or equal to balance",
            (val, context) => {
              return (
                Math.abs(
                  toFloat(context.parent?.total?.replace(/^\D+/g, "")),
                ) >= +(val || 0)
              );
            },
          )
          .label("Amount"),
      }),
    ),
});
