import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { ToggleButtons } from "../../../components/buttons";
import { FormikSelect } from "../../../components";
import {
  gematriya,
  getCurrencySymbol,
  getHebrewBirthday,
  isDateValid,
} from "../../../lib";
import Autocomplete from "../../../components/Autocomplete";
import { useEffect, useState } from "react";
import {
  Month,
  listsActions,
  listsSelectors,
  studentSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import {
  buildMonthsOptions,
  buildYearsOptions,
} from "../../admin/ChangeStudentPage/utils";
import { Option } from "../../../components/Select/Select";
import Select from "../../../components/Select";
import { DatePicker } from "@mui/x-date-pickers";
import { MonthsAutocomplete } from "../../admin/components/MonthsAutocomplete";

const DAYS_LIST = Array<Option>(30)
  .fill({} as Option)
  .map(
    (_, index) =>
      ({
        label: gematriya((index + 1).toString()),
        value: (index + 1).toString(),
      } as Option),
  );
export const StudentDetails = ({
  formik,
  customerFormik,
  englishBirthday,
  setEnglishBirthday,
}) => {
  const [schools, setSchools] = useState<Option[]>([]);
  const [schoolBuildings, setSchoolBuildings] = useState<Option[]>([]);
  const [grades, setGrades] = useState<Option[]>([]);
  const [years, setYears] = useState<Option[]>([]);
  const [months, setMonths] = useState<Option[]>([]);
  const [enrollementYearOptions, setEnrollementYearOptions] = useState<
    Option[]
  >([]);
  const [days, setDays] = useState<Option[]>([]);
  const [school, setSchool]: any = useState();
  const _neighborhoods = useSelector(listsSelectors.neighborhoodById);
  const _schools = useSelector(listsSelectors.schools);
  const _schoolBuildings = useSelector(listsSelectors.schoolBuildings);
  const _months: Month[] = useSelector(listsSelectors.months);
  const enrollementMonths = useSelector(studentSelectors.webApplicationMonths);
  const _monthById = useSelector(listsSelectors.monthById);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setTouched,
    setFieldValue,
  } = formik;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listsActions.getNeighborhoods());
    dispatch(listsActions.getSchools());
    dispatch(listsActions.getSchoolBuildings());
    dispatch(listsActions.getMonths());
  }, [dispatch]);

  useEffect(() => {
    // Schools
    const schools = _schools.map(({ id, name, neighborhood_id }) => {
      const neighborhood = _neighborhoods[neighborhood_id]?.name ?? "";
      const isHebrewNeighborhood = /^[\u0590-\u05FF `’'",.-]*$/.test(
        neighborhood,
      );
      return {
        label: isHebrewNeighborhood
          ? `${neighborhood}  -  ${name}`
          : `${name}  -  ${neighborhood}`,
        value: id.toString(),
      } as Option;
    });

    // Grades
    const grades = Array<Option>(12)
      .fill({} as Option)
      .map(
        (_, index) =>
          ({
            label: gematriya((index + 1).toString()),
            value: (index + 1).toString(),
          } as Option),
      );

    setSchools(schools);
    setGrades(grades);
    setYears(buildYearsOptions(_months));
  }, [values._customer, _months, _schools, _neighborhoods]);

  useEffect(() => {
    setEnrollementYearOptions(buildYearsOptions(enrollementMonths));
  }, [enrollementMonths]);

  useEffect(() => {
    if (enrollementYearOptions && enrollementYearOptions.length) {
      setFieldValue("year_id", enrollementYearOptions[0].value);
    }
  }, [enrollementYearOptions, setFieldValue]);

  useEffect(() => {
    const sb = _schoolBuildings
      .filter((b) => {
        if (values.school) {
          return b.school_id.toString() === values.school;
        } else {
          return true;
        }
      })
      .map((sb) => ({ label: sb.name, value: sb.id.toString() } as Option));

    setSchoolBuildings(sb);
  }, [values.school, _schoolBuildings]);

  useEffect(() => {
    setMonths(buildMonthsOptions(_months, values.year));
  }, [_months, values.year]);
  useEffect(() => {
    if (englishBirthday?.year) {
      setFieldValue("year", englishBirthday?.year.toString());
      setEnglishBirthday((b) => ({ ...b, year: undefined }));
    }
  }, [englishBirthday, setEnglishBirthday, setFieldValue]);
  useEffect(() => {
    if (englishBirthday?.month) {
      setFieldValue(
        "birth_month_id",
        months.find((m) => m.label === englishBirthday?.month)?.value,
      );
      setTimeout(() => {
        setEnglishBirthday((b) => ({ ...b, month: undefined }));
      }, 0);
    }
  }, [months, englishBirthday?.month, setFieldValue, setEnglishBirthday]);
  useEffect(() => {
    if (englishBirthday?.day) {
      setFieldValue("birth_month_day", englishBirthday?.day.toString());
      setEnglishBirthday((b) => ({ ...b, day: undefined }));
    }
  }, [englishBirthday, days, setFieldValue, setEnglishBirthday]);

  useEffect(() => {
    const days = _monthById[values.birth_month_id]?.day_count ?? 0;
    if (days) {
      setDays(DAYS_LIST.slice(0, days));
    }
  }, [_monthById, values.birth_month_id]);

  return (
    <Box>
      <Typography fontSize={26} fontWeight="bold">
        תלמיד
      </Typography>
      <Typography variant={"h6"}>Student details</Typography>

      <Grid container spacing={4}>
        <Grid item md={3} /* sx={gridItem} */>
          <TextField
            id="first_name_yiddish"
            name="first_name_yiddish"
            label="*ערשטע נאמען"
            fullWidth={true}
            value={values.first_name_yiddish}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.first_name_yiddish && !!errors.first_name_yiddish}
            helperText={touched.first_name_yiddish && errors.first_name_yiddish}
            size="small"
          />
        </Grid>

        <Grid item md={3}>
          <TextField
            id="last_name_yiddish"
            name="last_name_yiddish"
            label="*לעצטע נאמען"
            fullWidth={true}
            value={values.last_name_yiddish}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.last_name_yiddish && !!errors.last_name_yiddish}
            helperText={touched.last_name_yiddish && errors.last_name_yiddish}
            size="small"
          />
        </Grid>

        <Grid item md={3}>
          <TextField
            id="first_name"
            name="first_name"
            label="First name*"
            fullWidth={true}
            value={values.first_name}
            onChange={(e) => {
              if (e.target.value.length === 1) {
                setFieldValue("first_name", e.target.value.toUpperCase());
              } else {
                setFieldValue("first_name", e.target.value);
              }
            }}
            onBlur={handleBlur}
            error={touched.first_name && !!errors.first_name}
            helperText={touched.first_name && errors.first_name}
            size="small"
          />
        </Grid>

        <Grid item md={3}>
          <TextField
            id="last_name"
            name="last_name"
            label="Last name*"
            fullWidth={true}
            value={values.last_name}
            onChange={(e) => {
              if (e.target.value.length === 1) {
                setFieldValue("last_name", e.target.value.toUpperCase());
              } else {
                setFieldValue("last_name", e.target.value);
              }
            }}
            onBlur={handleBlur}
            error={touched.last_name && !!errors.last_name}
            helperText={touched.last_name && errors.last_name}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <Autocomplete
            options={schools}
            value={school ?? ""}
            onChange={(e, value: any) => {
              setSchool(value);
              setTouched({ ...touched, school_building_id: true });
              setFieldValue("school_building_id", "");
              setTimeout(() => {
                setFieldValue("school", value?.value);
              }, 0);
            }}
            placeholder={"תלמוד תורה"}
            noOptionsText={"No schools..."}
            error={touched.school && !!errors.school}
            size="small"
          />
        </Grid>

        <Grid item md={3}>
          <Select
            id="school_building_id"
            name="school_building_id"
            label={"בנין"}
            size="small"
            options={schoolBuildings}
            value={values.school_building_id}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.school_building_id && !!errors.school_building_id}
            required={!!school}
            helperText={touched.school_building_id && errors.school_building_id}
          />
        </Grid>

        <Grid item md={3}>
          <Select
            id="grade"
            name="grade"
            label={"*כיתה"}
            size="small"
            options={grades}
            value={values.grade}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.grade && !!errors.grade}
            helperText={touched.grade && errors.grade}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={9} paddingRight={1}>
          <TextField
            name="notes"
            multiline
            size="small"
            id="notes"
            label="Notes"
            fullWidth={true}
            value={values.notes}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.notes && !!errors.notes}
            helperText={touched.nots && errors.notes}
          />
        </Grid>
      </Grid>
      <Typography variant={"h6"} sx={{ mt: 3 }}>
        יום הולדת
      </Typography>
      <Typography fontSize={14} color="#5C738E">
        You can enter your son’s English birthday and we will convert it to his
        Yiddish birthday.
      </Typography>

      <Grid container spacing={4}>
        <Grid item md={3}>
          <Select
            id="year"
            name="year"
            fullWidth
            label={"*שנה"}
            placeholder={"שנה"}
            options={years}
            value={values.year || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.year && !!errors.year}
            helperText={touched.year && errors.year}
            size="small"
          />
        </Grid>
        <Grid item md={3}>
          <Select
            id="birth_month_id"
            name="birth_month_id"
            fullWidth
            label={"*חודש"}
            placeholder={"חודש"}
            options={months}
            value={values.birth_month_id || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.birth_month_id && !!errors.birth_month_id}
            helperText={touched.birth_month_id && errors.birth_month_id}
            size="small"
          />
        </Grid>
        <Grid item md={3}>
          <Select
            id="birth_month_day"
            name="birth_month_day"
            fullWidth
            label={"*יום"}
            placeholder={"יום"}
            options={days}
            value={values.birth_month_day || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.birth_month_day && !!errors.birth_month_day}
            helperText={touched.birth_month_day && errors.birth_month_day}
            size="small"
          />
        </Grid>
        <Grid item md={3}>
          <DatePicker
            label="English date"
            value={englishBirthday?.date || null}
            onChange={(date: any) => {
              if (isDateValid(date)) {
                setEnglishBirthday({
                  ...getHebrewBirthday(date),
                  date,
                });
              }
            }}
            renderInput={({ error: _e, ...params }) => (
              <TextField {...params} size="small" />
            )}
          />
        </Grid>
      </Grid>
      <Typography fontSize={26} fontWeight="bold">
        Enrollment
      </Typography>
      <Typography fontSize={14} color="#5C738E">
        Choose your currency and start month to view the program options and
        pricing
      </Typography>
      <Typography fontSize={14} color="#5C738E">
        ווען מען שרייבט איין צוויי קינדער און מער באקומט מען א $5 דיסקאונט
      </Typography>

      <Grid container paddingTop={2}>
        <Grid item md={3}>
          <Typography variant={"h6"}>Currency</Typography>
          <Box paddingY="1px">
            <FormikSelect
              name="currency"
              label={"Currency"}
              formik={customerFormik}
              textFieldProps={{ size: "small" }}
              options={[
                { name: "$ - USD", id: "USD" },
                { name: "£ - GBP", id: "GBP" },
              ]}
            />
          </Box>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid container item md={8}>
          <Typography variant={"h6"} width="100%">
            Start month
          </Typography>
          <Grid item md={5}>
            <MonthsAutocomplete
              value={values.month_id}
              setFieldValue={setFieldValue}
              label="Month"
              months={enrollementMonths}
              error={touched.month_id && !!errors.month_id}
              autocompleteProps={{
                size: "small",
                sx: { width: "100%" },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography variant={"h6"}>חבורות</Typography>
      <Typography fontSize={14} color="#5C738E">
        צייכענט אן אין וועלכע חבורה איר שרייבט איין{" "}
      </Typography>
      <Box paddingTop={2} paddingBottom={4}>
        <ToggleButtons
          value={values.chaburah_type_id.toString()}
          size="medium"
          onChange={(_e, v) => {
            setFieldValue("chaburah_type_id", +v);
          }}
          toggleStyle={{
            minWidth: 180,
          }}
          buttonOptions={[
            { label: "Live course", value: "1" },
            { label: "Cd course", value: "2" },
          ]}
        />
      </Box>
      {values.chaburah_type_id === 1 ? (
        <>
          <Typography variant={"h6"}>Choose a live course</Typography>
          <Typography fontSize={14} color="#5C738E" paddingBottom={2}>
            וואו מ'קומט לערנען לעבעדיג מיט א חבורה און געלונגענער מגי"ש{" "}
          </Typography>

          <RadioGroup
            onChange={(e, v) => {
              setFieldValue("location", v);
            }}
          >
            <Grid container>
              <Grid item md={4} textAlign="right">
                <LiveCourseRadio
                  value="ביהמ“ד מישקאלץ"
                  subtitle="(בעדפארד/קליימער געגענט)"
                />
              </Grid>
              <Grid item md={4} textAlign="right">
                <LiveCourseRadio
                  value="ביהמ“ד סוואליווע"
                  subtitle="(מורטל געגענט)"
                />
              </Grid>
              <Grid item md={4} textAlign="right">
                <LiveCourseRadio
                  value="ביהמ“ד מגד יהודא"
                  subtitle="(בעדפארד/פען געגענט)"
                />
              </Grid>
            </Grid>
            <Grid container paddingTop={1}>
              <Grid item md={4} textAlign="right">
                <LiveCourseRadio
                  value="בארא פארק"
                  subtitle="(בית המדרש מתמידים)"
                />
              </Grid>
              <Grid item md={4} textAlign="right">
                <LiveCourseRadio
                  value="קרית יואל"
                  subtitle="(בית המדרש מתמידים)"
                />
              </Grid>
              <Grid item md={4} textAlign="right">
                <LiveCourseRadio value="מאנסי" subtitle="(בית המדרש מתמידים)" />
              </Grid>
            </Grid>
          </RadioGroup>
        </>
      ) : (
        <>
          {" "}
          <Typography variant={"h6"}>Choose a CD course</Typography>
          <Grid container>
            <Grid item md={4} textAlign="right">
              <Box paddingTop="20px" textAlign="right">
                <CDCoarseTitle
                  track={"קנין מועד"}
                  customerFormik={customerFormik}
                />
                <Box fontSize={14} color="#5C738E">
                  מינוט א טאג <Box display="inline-flex">15</Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4} textAlign="right">
              <Box paddingTop="20px" textAlign="right">
                <CDCoarseTitle
                  track={"קנין סדרים"}
                  customerFormik={customerFormik}
                />

                <Typography fontSize={14} color="#5C738E">
                  א האלבע שעה א טאג
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} textAlign="right">
              <Box paddingTop="20px" textAlign="right">
                <CDCoarseTitle
                  track={"קנין ששה סדרים"}
                  customerFormik={customerFormik}
                />
                <Typography fontSize={14} color="#5C738E">
                  א שעה א טאג
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <RadioGroup
            onChange={(e, v) => {
              setFieldValue("subject", v);
              switch (v) {
                case "קנין מועד חלק א׳":
                  setFieldValue("kinyan", "קנין מועד");
                  break;
                case "חבורת סדר מועד":
                case "סדר זרעים":
                  setFieldValue("kinyan", "קנין סדרים");
                  break;
                case "חבורת סדר זרעים":
                case "חבורת סדר נשים":
                case "סדר מועד":
                case "חבורת סדר נזיקין":
                  setFieldValue("kinyan", "קנין ששה סדרים");
                  break;

                default:
                  break;
              }
            }}
            value={values.subject || ""}
          >
            <Grid container textAlign="right">
              <Grid item md={4} paddingRight={1}>
                <FormControlLabel
                  value="קנין מועד חלק א׳"
                  control={<Radio />}
                  label="קנין מועד חלק א׳"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={4} paddingRight={1}>
                <FormControlLabel
                  value="חבורת סדר מועד"
                  control={<Radio />}
                  label="חבורת סדר מועד"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="סדר זרעים"
                  control={<Radio />}
                  label={
                    <Box textAlign="right">
                      <Typography fontSize={14} color="#5C738E">
                        אלו שכבר למדו סדר מועד
                      </Typography>
                      חבורת סדר זרעים
                    </Box>
                  }
                  labelPlacement="start"
                />
              </Grid>
              {values.month_id &&
              enrollementMonths?.find((m) => m.id === +values.month_id)
                ?.semester === "summer" ? (
                <Grid item md={4} paddingRight={1}>
                  <FormControlLabel
                    value="סדר מועד"
                    control={<Radio />}
                    label="חבורת סדר מועד"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="חבורת סדר נזיקין"
                    control={<Radio />}
                    label={
                      <Box textAlign="right">
                        <Typography fontSize={14} color="#5C738E">
                          אלו שכבר למדו סדר מועד
                        </Typography>
                        חבורת סדר נזיקין
                      </Box>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              ) : (
                <Grid item md={4} paddingRight={1}>
                  <FormControlLabel
                    value="חבורת סדר זרעים"
                    control={<Radio />}
                    label="חבורת סדר זרעים"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="חבורת סדר נשים"
                    control={<Radio />}
                    label={
                      <Box textAlign="right">
                        <Typography fontSize={14} color="#5C738E">
                          אלו שכבר למדו סדר זרעים
                        </Typography>
                        חבורת סדר נשים
                      </Box>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}
            </Grid>
          </RadioGroup>
        </>
      )}
    </Box>
  );
};

function LiveCourseRadio({ value, subtitle }) {
  const livePrice = useSelector(studentSelectors.webApplicationLivePrice);
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={
        <Box textAlign="right">
          <Box display="inline-flex" alignItems="center">
            <Box paddingRight={0.5} fontSize={12}>
              {getCurrencySymbol(livePrice?.currency || "")}
              {!livePrice?.price
                ? ""
                : Math.abs(parseInt(livePrice?.price)) || ""}
            </Box>
            <Box>{value}</Box>
          </Box>
          <Typography fontSize={14} color="#5C738E">
            {subtitle}
          </Typography>
        </Box>
      }
      labelPlacement="start"
    />
  );
}

function CDCoarseTitle({ track, customerFormik }) {
  const remotePrices = useSelector(studentSelectors.webApplicationRemotePrices);
  const currency = customerFormik.values.currency || "USD";
  const price = remotePrices?.find(
    (p) => p.track === track && p.currency === currency,
  )?.price;
  return (
    <Box display="inline-flex" alignItems="center">
      <Box paddingRight={0.5} fontSize={12}>
        {getCurrencySymbol(customerFormik.values.currency || "")}
        {!price ? "" : Math.abs(parseInt(price)) || ""}
      </Box>
      {track}
    </Box>
  );
}
