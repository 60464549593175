import React, { useCallback, useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { listsSelectors } from "../../../state";
import { FormikSelect } from "../../../components/Select";
import { googlePlaceToAddress, statesAsOptions } from "../../../lib/utils";
import { FormikInput } from "../../../components";
import AutoComplete from "react-google-autocomplete";
import { useFormikContext } from "formik";
import { REACT_APP_GOOGLE_API_KEY } from "../../../config";
import { ErrorMessage } from "./AddressForm.styles";

interface addressProps {
  spacing?: number;
  isCurrencyDisabled?: boolean;
}

export const AddressForm = (props: addressProps) => {
  const neighborhoods = useSelector(listsSelectors.neighborhoods);
  const countries = useSelector(listsSelectors.optionsCountries);
  const { setValues, values, handleChange, errors, submitCount } =
    useFormikContext<any>();
  const [_spacing, setSpacing] = useState(4);
  const [_size, setSize] = useState(4);

  useEffect(() => {
    if (props?.spacing) {
      setSpacing(props.spacing);
      setSize(4);
    }
  }, [props.spacing]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { address, ...rest } = values;

  const handleSelectAddress = useCallback(
    (googlePlace) => {
      const addressInfo = googlePlaceToAddress(googlePlace);
      if (!addressInfo) {
        return;
      }
      const country_id = countries.find(
        (c) => c.name === addressInfo.country,
      )?.id;

      setValues({
        ...values,
        address: addressInfo.address || "",
        address_2: "",
        city: addressInfo.city || "",
        country_id: country_id || "",
        state: addressInfo.state || "",
        zip: addressInfo.zip || "",
      });
    },
    [countries, setValues, values],
  );

  const preventFormSubmitOnEnter = useCallback((e) => {
    if (e.key === "Enter") e.preventDefault();
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
        Address
      </Typography>
      <Grid container spacing={_spacing}>
        <Grid item md={6}></Grid>
      </Grid>
      <Grid container spacing={_spacing}>
        <Grid item md={_size}>
          <AutoComplete
            key={JSON.stringify(rest)}
            apiKey={REACT_APP_GOOGLE_API_KEY}
            id="address"
            value={values.address || ""}
            // className={clsx(classes.autoComplete, {
            //   [classes.error]: errors?.address && submitCount > 0,
            // })}
            style={{
              padding: "16px 8px",
              zIndex: 100,
              position: "relative",
              borderRadius: 4,
              fontSize: 16,
              width: "100%",
              color: "rgba(0, 0, 0, 0.87)",
              border:
                errors?.address && submitCount > 0
                  ? "1px solid #bb2018"
                  : "1px solid #B4B8C8",
              margin: "20px 0px",
            }}
            placeholder="Address line 1"
            onKeyDown={preventFormSubmitOnEnter}
            onPlaceSelected={handleSelectAddress}
            onChange={handleChange}
            options={{
              types: [],
              fields: ["address_components"],
            }}
          />
          {errors?.address && submitCount > 0 && (
            <ErrorMessage>{errors.address}</ErrorMessage>
          )}
        </Grid>
        <Grid item md={_size}>
          <FormikInput name="address_2" label="Address line 2" />
        </Grid>
      </Grid>
      <Grid container spacing={_spacing}>
        <Grid item md={_size}>
          <FormikInput name="city" />
        </Grid>
        <Grid item md={_size}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <FormikSelect
                name="state"
                label={"State"}
                options={statesAsOptions}
              />
            </Grid>
            <Grid item md={6}>
              <FormikInput name="zip" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={_spacing}>
        <Grid item md={_size}>
          <FormikSelect label="Country" name="country_id" options={countries} />
        </Grid>
        <Grid item md={_size}>
          <FormikSelect
            name="currency"
            label={"Currency"}
            disabled={props.isCurrencyDisabled}
            options={[
              { name: "$ - USD", id: "USD" },
              { name: "£ - GBP", id: "GBP" },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={_spacing}>
        <Grid item md={_size}>
          <FormikSelect
            label="Neighborhood"
            name="neighborhood_id"
            options={neighborhoods}
          />
        </Grid>
      </Grid>
    </>
  );
};
