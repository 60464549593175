import { object } from "yup";
import { yup } from "../../../../../lib";

export interface Fields {
  studentId: number;
  review_count: string;
  attendance_count: string;
  gathering: "attended" | "reached" | "called" | "" | "unset" | null;
  check_in: "reached" | "called" | "" | "unset" | null;
  call_date?: string | null | undefined;
  monthly_note: string | null | undefined;
  max_check_in: number | null;
  max_review_count: number | null;
}

export const initialValues: Fields = {
  studentId: 0,
  review_count: "",
  attendance_count: "",
  gathering: "",
  check_in: "",
  monthly_note: "",
  max_check_in: null,
  max_review_count: null,
};

export const validationSchema = object().shape({
  review_count: yup
    .number()
    .nullable()
    .min(0)
    .max(yup.ref("max_review_count"), ({ max }) =>
      !max
        ? `Max chazurah checks must be configured in the ‘group settings’ before assigning chazurah checks to a student`
        : `Chazurah checks must be less than or equal to ${max}`,
    ),
  attendance_count: yup
    .number()
    .nullable()
    .min(0)
    .max(yup.ref("max_check_in"), ({ max }) =>
      !max
        ? `Max attendance checks must be configured in the ‘group settings’ before assigning attendance checks to a student`
        : `Attendance checks must be less than or equal to ${max}`,
    ),
});
