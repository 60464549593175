import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { authSelectors, useSelector } from "../../../../../src/state";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import AdminPages from "../../index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { backBtnStyle, btnStyle } from "./Header.styles";
import { useParams } from "react-router";
import { toInt } from "../../../../lib";

type ButtonType = "back" | "edit"; // | "settings";

interface Props {
  groupName: string;
}

const Header: FC<Props> = ({ groupName }) => {
  const isLimited = useSelector(authSelectors.isLimited);
  const navigate = useNavigate();
  const params = useParams();

  const handleButtonClick = (
    btnType: ButtonType,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    switch (btnType) {
      case "back":
        navigate(AdminPages.groups.path);
        break;

      case "edit":
        !!params.id &&
          toInt(params.id) &&
          navigate(AdminPages.editGroup.path.replace(":id", params.id));
        break;

        // case "settings":
        // put some logic here
        break;
    }
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <IconButton
          color="primary"
          sx={backBtnStyle}
          onClick={handleButtonClick.bind(null, "back")}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography variant={"h3"}>{groupName}</Typography>
      </Stack>

      <Stack direction={"row"} gap={3}>
        {!isLimited && (
          <Button
            variant={"contained"}
            sx={btnStyle}
            onClick={handleButtonClick.bind(null, "edit")}
          >
            edit
          </Button>
        )}

        {/* <Button
          variant={"contained"}
          sx={btnStyle}
          onClick={handleButtonClick.bind(null, "settings")}
        >
          settings
        </Button> */}
      </Stack>
    </Stack>
  );
};

export default React.memo(Header);
