import React, { useEffect, useRef, useState } from "react";
import { FormikProps } from "formik/dist/types";
import { Fields } from "./schema";
import { Props } from "./EnterTestModal";
import { useDispatch } from "react-redux";
import { FormikHelpers } from "formik";
import { adminActions, EditedTests, GroupValues } from "../../../../state";
import { arrayToEntityList, gematriya, toInt } from "../../../../lib";

export function useEnterTestModal({ modal, onClose }: Props) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(!!modal);
  const innerRef = useRef<FormikProps<Fields>>(null);

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    onClose ? onClose() : setOpen(false);
  };

  const handleSubmit = async (
    { tests, answers_looked_in }: Fields,
    { setSubmitting }: FormikHelpers<Fields>,
  ) => {
    const data: EditedTests = {
      month_id: modal?.monthId!,
      test_answers: tests.map((t) => {
        return [t.answered, t.revised].filter(Boolean).join(",");
      }),
      answers_looked_in: toInt(answers_looked_in),
    };

    const result = await dispatch<
      boolean | ReturnType<typeof adminActions.updateIndividualStudentTest>
    >(adminActions.updateIndividualStudentTest(modal?.studentId!, data));

    setSubmitting(false);

    if (result) {
      onClose?.();
    }
  };

  useEffect(() => {
    (async () => {
      innerRef?.current?.resetForm();
      if (modal?.chaburahId && modal?.monthId) {
        const cv = (await dispatch(
          adminActions.getChaburahValuesByMonth(
            modal.chaburahId!.toString(),
            modal.monthId!.toString(),
          ),
        )) as unknown as GroupValues | null;
        let answers;
        if (modal.studentAnswers?.length) {
          answers = arrayToEntityList(
            modal.studentAnswers,
            "question_number",
          )?.entities;
        }
        if (cv?.tests?.[0]?.test_questions?.length) {
          const data: Fields = {
            month_id: modal.monthId,
            answers_looked_in: modal?.lookedIn ?? "",
            tests: cv?.tests?.[0]?.test_questions
              ?.sort((a, b) => a.question_number - b.question_number)
              .map(({ id, extra_credit, answer, question_number }, index) => {
                return {
                  index,
                  id,
                  extra_credit,
                  answer: answer,
                  revised: answers
                    ? answers[question_number]?.revised ?? ""
                    : "",
                  answered: answers
                    ? answers[question_number]?.given ?? ""
                    : "",
                  question_number,
                  question: `שאלה ${gematriya(question_number.toString())}`,
                };
              }),
          };
          innerRef?.current?.setValues(data);
        }
      }
    })();
  }, [dispatch, modal]);

  useEffect(() => {
    setOpen(!!modal);
  }, [modal]);

  return {
    innerRef,
    open,
    handleClose,
    handleSubmit,
  };
}
