import React, { FC, useEffect, useState } from "react";
import { Transition } from "../Transition";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useAddMonthlyPoints } from "./useAddMonthlyPoints";
import { ModalData } from "./types";
import { Fields, initialValues, validationSchema } from "./schema";
import { Form, Formik } from "formik";
import { btnSx, container, content } from "./AddMonthlyPoints.styles";
import { FormikInput } from "../../../../../components";
import Select from "../../../../../components/Select";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getFormattedYiddishDate,
  getYiddishDateString,
} from "../../../../../lib";
import { format, isValid } from "date-fns";
import { listsSelectors, useSelector } from "../../../../../state";

export interface Props {
  modal: ModalData | null;
  onClose?: (data: ModalData | null) => void;
}

const AddMonthlyPoints: FC<Props> = ({ modal, onClose }) => {
  const [yiddishDate, setYiddishDate] = useState<string>();
  const { innerRef, open, handleClose, handleSubmit } = useAddMonthlyPoints({
    modal,
    onClose,
  });
  const months = useSelector(listsSelectors.monthById);
  const month = months[modal?.month_id || 0];
  const nextMonth = months[(modal?.month_id || 0) + 1];
  useEffect(() => {
    if (modal?.call_date) {
      setYiddishDate(getYiddishDateString(modal.call_date, true));
    }
  }, [modal]);

  useEffect(() => {
    if (!open) {
      setYiddishDate("");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose?.(null)}
    >
      <DialogTitle display="flex" justifyContent="space-between">
        <span>{months[modal?.month_id || 0]?.hebrew} Details</span>
        <span>
          {modal?.first_name_yiddish} {modal?.last_name_yiddish}
        </span>
      </DialogTitle>

      <Formik<Fields>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={innerRef}
      >
        {({ values, setFieldValue, submitForm }) => {
          return (
            <Form>
              <DialogContent sx={content}>
                <Grid container sx={container} spacing={3}>
                  <Grid item xs={6}>
                    <Select
                      options={[
                        { label: "‏‏‎ ‎", value: "unset" },
                        { label: "Attended", value: "attended" },
                        {
                          label: "Reached after the Gathering",
                          value: "reached",
                        },
                        { label: "Wasn’t Reached", value: "called" },
                      ]}
                      label="Gathering"
                      value={values.gathering}
                      onChange={(e) => {
                        setFieldValue("gathering", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikInput name="review_count" label="Chazurah checks" />
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      options={[
                        { label: "‏‏‎‎", value: "unset" },

                        {
                          label: "Reached",
                          value: "reached",
                        },
                        { label: "Wasn’t Reached", value: "called" },
                      ]}
                      label="Phone call"
                      value={values.check_in}
                      onChange={(e) => {
                        setFieldValue("check_in", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box>
                        <DatePicker
                          componentsProps={{
                            actionBar: {
                              actions: ["clear", "accept", "cancel"],
                            },
                          }}
                          inputFormat="MM/dd/yyyy"
                          label="Date"
                          value={values.call_date || null}
                          minDate={new Date(month?.start_date)}
                          maxDate={new Date(nextMonth?.start_date)}
                          onChange={(date: any) => {
                            if (date && isValid(date)) {
                              setFieldValue(
                                "call_date",
                                format(date, "yyyy/MM/dd"),
                              );

                              setYiddishDate(
                                getFormattedYiddishDate(date, true),
                              );
                            } else {
                              setFieldValue("call_date", null);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} sx={{ margin: "0" }} />
                          )}
                        />
                      </Box>
                      <Box>
                        <Typography textAlign="right">{yiddishDate}</Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/*    <Grid item xs={6}>
                    <FormikInput
                      name="attendance_count"
                      label="Attendance checks"
                    />
                  </Grid> */}

                  <Grid
                    item
                    xs={12}
                    sx={{
                      "& > label:first-of-type": {
                        mr: 8,
                      },
                    }}
                  >
                    <FormikInput name="monthly_note" label="Note" multiline />
                  </Grid>
                </Grid>
              </DialogContent>

              <Divider
                variant="middle"
                sx={{ border: "1px solid #DDE3EB", mb: 2 }}
              />

              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={btnSx}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color={"primary"}
                  sx={btnSx}
                  onClick={submitForm}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddMonthlyPoints;
