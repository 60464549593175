import { useDropzone } from "react-dropzone";
import { IconButton } from "@mui/material";
import { FileUploadIcon, DeleteIcon } from "./icons";
import { formatBytes } from "../lib";

export function FileUpload(props) {
  const {
    disabled,
    maxSize,
    minSize,
    multiple,
    onChange,
    onClear,
    thumbnailUrl,
    thumbnailName,
    fileSize,
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onChange,
    maxFiles: multiple ? 0 : 1,
    multiple,
    disabled,
    maxSize,
    minSize,
  });

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          height: "122px",
          width: "calc(100% - 40px)",
          border: "1px solid #B4B8C8",
          color: "#3A3B3F",
          paddingRight: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "3px",
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        <div style={{ opacity: "50%" }}>
          <div>
            <FileUploadIcon />
          </div>
          <div>Drag and drop or click to select</div>
          <div>files</div>
        </div>
      </div>
      {thumbnailUrl && (
        <div
          style={{
            borderTop: "1px solid #B4B8C8",
            borderBottom: "1px solid #B4B8C8",
            width: "calc(100% - 40px)",
            paddingTop: "10px",
            paddingBottom: "10px",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: "14px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img height="40px" src={thumbnailUrl} />
              <span style={{ marginLeft: "15px" }}>{thumbnailName}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {fileSize && <span>{formatBytes(fileSize)}</span>}
              <IconButton onClick={onClear}>
                <DeleteIcon style={{ opacity: "50%", height: "18px" }} />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
