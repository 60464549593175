import React from "react";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  Chip,
  Tooltip,
} from "@mui/material";
import { getCurrencySymbol, numberWithCommas } from "../../../lib";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import AdminPages from "..";
import { statuses, statusValues } from "../../../constants";

export const CustomerCard = ({ customer, isLimited }) => {
  const navigate = useNavigate();
  function getHomeNumber(phone_numbers) {
    const object = phone_numbers?.filter((numberObj: any) => {
      return numberObj.type === "home";
    });
    return object?.[0]?.number || "";
  }
  function getCellNumber(phone_numbers) {
    const object = phone_numbers?.filter((numberObj: any) => {
      return numberObj.type === "cell";
    });
    return object?.[0]?.number || "";
  }
  const { address, v_customer_balance_detail, currency, students } = customer!;

  return (
    <Card
      sx={{ mt: 2, mb: 2, border: "1px solid #DDE3EB", borderRadius: "8px" }}
    >
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item sx={{ pl: 2, pr: 2 }} xs={3.5}>
            <Grid container direction="column" justifyContent="flex-start">
              <Grid item>
                <PhoneIcon
                  sx={{
                    minHeight: "15px",
                    color: "#97A7C1",
                    mr: 1,
                    mb: -1,
                  }}
                />
                <Typography display="inline" variant="body1">
                  {getHomeNumber(customer?.phone_numbers)}
                </Typography>
              </Grid>
              <Grid item sx={{ pl: 4 }}>
                <Typography display="inline" variant="body1">
                  {getCellNumber(customer?.phone_numbers)}
                </Typography>
              </Grid>
              <Grid item sx={{ pt: 1.5 }}>
                <LocationOnIcon
                  sx={{
                    minHeight: "15px",
                    color: "#97A7C1",
                    mpr: 1,
                    mb: -1,
                  }}
                />
                <Typography display="inline" variant="body1">
                  {`${address?.address || ""} ${address?.address_2 || ""}`}
                </Typography>
                <Typography variant="body1" sx={{ ml: 4 }}>
                  {`${address?.city || ""} ${address?.state || ""} ${
                    address?.zip || ""
                  }`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={5}
            alignItems="center"
            sx={{
              pl: 2,
              pr: 2,
              minHeight: 100,
              borderLeft: "2px solid #DDE3EB",
              borderRight: "2px solid #DDE3EB",
            }}
          >
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>Students</Typography>
            </Grid>
            {students?.map((student, index) => (
              <Grid item key={index} xs={12} alignItems="center">
                <Tooltip
                  title={
                    statuses[statusValues[student.status_id]]?.toolTip || ""
                  }
                >
                  <CircleIcon
                    sx={{
                      height: 8,
                      width: 8,
                      color: statuses[statusValues[student.status_id]]?.color,
                    }}
                  />
                </Tooltip>
                <Chip
                  sx={{
                    borderRadius: 0,
                    margin: "2.5px 16px 2.5px 8px",
                    backgroundColor: "secondary.main",
                    color: "primary.main",
                    minHeight: "25px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    minWidth: "150px",
                  }}
                  onClick={() => {
                    navigate(
                      AdminPages.studentPage.path.replace(
                        ":id",
                        student.id.toString(10),
                      ),
                    );
                  }}
                  key={Math.random().toString(8)}
                  label={
                    student.first_name_yiddish + " " + student.last_name_yiddish
                  }
                />
                <Typography display="inline" variant="body2">
                  {student.student_months?.[0]?.chaburah.name}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Grid
            item
            container
            xs={3.5}
            sx={{ pl: 2, pr: 2 }}
            alignItems="center"
          >
            {!isLimited && (
              <Grid
                item
                container
                direction="column"
                xs={6}
                alignItems="center"
              >
                <Typography
                  display="inline"
                  variant="body1"
                  sx={{ fontWeight: "bold" }}
                >
                  Open balance
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ fontWeight: "regular" }}
                  display="inline"
                  color={
                    v_customer_balance_detail?.open_balance >= 0
                      ? "#128E56"
                      : "#BB2018"
                  }
                >
                  {v_customer_balance_detail?.open_balance < 0 && "-"}
                  {getCurrencySymbol(currency)}
                  {numberWithCommas(v_customer_balance_detail?.open_balance)}
                </Typography>
              </Grid>
            )}
            {!isLimited && (
              <Grid
                item
                container
                direction="column"
                xs={6}
                alignItems="center"
              >
                <Typography
                  display="inline"
                  variant="body1"
                  sx={{ fontWeight: "bold" }}
                >
                  Credit
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ fontWeight: "regular" }}
                  display="inline"
                  color={"#128E56"}
                >
                  {v_customer_balance_detail?.balance < 0 && "-"}
                  {getCurrencySymbol(currency)}
                  {numberWithCommas(v_customer_balance_detail?.balance)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
