import {
  AppThunk,
  Building,
  ChaburahType,
  GenericUser,
  Location,
  Chaburahs,
  Month,
  School,
  SemesterItem,
  SubjectItem,
  Track,
  Type,
  Countries,
  NeighborhoodPrincipals,
  Principals,
  TeacherItem,
  ChaburahTeacher,
} from "..";
import { authClient, uiActions } from "../states";
import { lists } from "./state";

const { actions } = lists;

export const listsActions = {
  ...actions,
  getStatuses(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/lists/statuses`);
      if (status === 200) {
        dispatch(actions.setStatuses(data));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  getNeighborhoods(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/lists/neighborhoods`);
      if (status === 200) {
        dispatch(actions.setNeighborhoods(data));
      } else uiActions.showError("Failed to get neighborhoods list");
      dispatch(uiActions.setLoading(false));
    };
  },
  getAllTeachers(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/lists/all_teachers`);
      if (status === 200) {
        dispatch(actions.setAllTeachers(data));
      } else uiActions.showError("Failed to get teachers list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getChaburahTypes(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<ChaburahType[]>(
        `/lists/chaburah_types`,
      );
      if (status === 200) {
        dispatch(actions.setChaburahTypes(data));
      } else uiActions.showError("Failed to get chaburah types list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getMonths(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<Month[]>(`/lists/months`);
      if (status === 200) {
        dispatch(actions.setMonths(data));
      } else uiActions.showError("Failed to get months list");
      dispatch(uiActions.setLoading(false));
    };
  },
  getTypes(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<Type[]>(`/lists/types`);
      if (status === 200) {
        dispatch(actions.setTypes(data));
      } else uiActions.showError("Failed to get types list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getUsers(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<GenericUser[]>(
        `/lists/users`,
      );
      if (status === 200) {
        dispatch(actions.setUsers(data));
      } else uiActions.showError("Failed to get users list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getNeighborhoodPrincipals(): AppThunk {
    //returns all  who are a principal
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<NeighborhoodPrincipals[]>(
        `/lists/neighborhood_principals`,
      );
      if (status === 200) {
        dispatch(actions.setNeighborhoodPrincipals(data));
      } else uiActions.showError("Failed to get neighborhood principals list");
      dispatch(uiActions.setLoading(false));
    };
  },
  getAllPrincipals(): AppThunk {
    //returns all  who are eligible to be a principal (for select a principal dropdown...)
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<Principals[]>(
        `/lists/principals`,
      );
      if (status === 200) {
        dispatch(actions.setAllPrincipals(data));
      } else uiActions.showError("Failed to get principals list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getSemesters(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<SemesterItem[]>(
        `/lists/semesters`,
      );
      if (status === 200) {
        dispatch(actions.setSemesters(data));
      } else uiActions.showError("Failed to get semesters list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getTracks(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<Track[]>(`/lists/tracks`);
      if (status === 200) {
        dispatch(actions.setTracks(data));
      } else uiActions.showError("Failed to get tracks list");
      dispatch(uiActions.setLoading(false));
    };
  },
  getLocations(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<Location[]>(
        `/lists/locations`,
      );
      if (status === 200) {
        dispatch(actions.setLocations(data));
      } else uiActions.showError("Failed to get locations list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getChaburahs(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<Chaburahs[]>(
        `/lists/chaburahs`,
      );
      if (status === 200) {
        dispatch(actions.setChaburahs(data));
      } else uiActions.showError("Failed to get chaburahs list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getCountries(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<Countries[]>(
        `/lists/country_codes`,
      );
      if (status === 200) {
        dispatch(actions.setCountries(data));
      } else uiActions.showError("Failed to get countries list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getSchools(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<School[]>(`/lists/schools`);
      if (status === 200) {
        dispatch(actions.setSchools(data));
      } else uiActions.showError("Failed to get schools list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getSchoolBuildings(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<Building[]>(
        `/lists/school_buildings`,
      );
      if (status === 200) {
        dispatch(actions.setSchoolBuildings(data));
      } else uiActions.showError("Failed to get school buildings list");
      dispatch(uiActions.setLoading(false));
    };
  },

  getCustomerByName(q: string): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `lists/customer_search?q=${encodeURI(q)}`,
      );

      if (status !== 200) {
        dispatch(uiActions.showError("Get customer list failed"));
      }

      return data;
    };
  },

  getStudentByName(q: string): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `lists/student_search?q=${encodeURI(q)}`,
      );

      if (status !== 200) {
        dispatch(uiActions.showError("Get student list failed"));
      }

      return data;
    };
  },

  getTeacher(id: string, chaburah_id: string): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get<ChaburahTeacher[]>(
        `/chaburah-neighborhood-teacher?person_id=${id}&chaburah_id=${chaburah_id}`,
      );

      if (status === 404) {
        dispatch(
          actions.setChaburahTeachers([
            { id: -1, user_id: -1, chaburah_id: +chaburah_id },
          ]),
        );
      } else if (status === 200) {
        dispatch(actions.setChaburahTeachers(data));
      } else {
        dispatch(actions.setChaburahTeachers([]));
        dispatch(uiActions.showError("Get teacher failed"));
      }
    };
  },

  getTeachers(chaburah_id: string): AppThunk<Promise<TeacherItem[]>> {
    return async (dispatch) => {
      const { data, status } = await authClient.get<TeacherItem[]>(
        `/lists/teachers?&chaburah_id=${chaburah_id}`,
      );

      if (status !== 200) {
        dispatch(uiActions.showError("Get teachers list failed"));
      }

      return data;
    };
  },

  getSubjects(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get<SubjectItem[]>(
        `/lists/subjects`,
      );
      if (status === 200) {
        dispatch(actions.setSubjects(data));
        dispatch(actions.setMesechtos(data));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};
