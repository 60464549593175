import React, { FC } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { amountStyle, formControl, gridItem } from "./TabBilling.styles";
import { DollarSignIcon, TodayIcon } from "../../../../components";
import { SystemStyleObject, Theme } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { textField } from "../TabGeneral/TabGeneral.styles";
import { StudentBillingFields } from "../schema";
import { FormikProps } from "formik";
import { isValid } from "date-fns";

interface Props {
  formik: FormikProps<StudentBillingFields>;
  pageMode: string;
}

const BlockCash: FC<Props> = ({ formik, pageMode }) => {
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } =
    formik;

  // const handleDepositType = (
  //   method: DepositType,
  //   e: React.MouseEvent<HTMLButtonElement>,
  // ) => {
  //   e.stopPropagation();
  //   setFieldValue("deposit_type", method);
  // };

  return (
    <>
      {pageMode === "insert" && (
        <>
          <Typography variant={"h6"} sx={{ my: 2 }}>
            Add a deposit
          </Typography>{" "}
          <Box sx={amountStyle}>
            <Typography variant={"subtitle1"} className={"caption"}>
              Amount
            </Typography>

            <TextField
              id="amount"
              name="amount"
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DollarSignIcon />
                  </InputAdornment>
                ),
              }}
              error={touched.amount && !!errors.amount}
              helperText={touched.amount && errors.amount}
            />
          </Box>{" "}
          <Typography variant={"h6"} sx={{ mt: 5, mb: 0 }}>
            {/*Payment type*/}
            Date
          </Typography>{" "}
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            width="600px"
          >
            {/*<Grid item sx={gridItem}>*/}
            {/*  <ButtonGroup sx={btnStyle}>*/}
            {/*    <Button*/}
            {/*      variant={getButtonVariant(values.deposit_type === "regular")}*/}
            {/*      onClick={handleDepositType.bind(null, "regular")}*/}
            {/*    >*/}
            {/*      Regular*/}
            {/*    </Button>*/}

            {/*    <Button*/}
            {/*      variant={getButtonVariant(values.deposit_type === "prepayment")}*/}
            {/*      onClick={handleDepositType.bind(null, "prepayment")}*/}
            {/*    >*/}
            {/*      Prepayment*/}
            {/*    </Button>*/}
            {/*  </ButtonGroup>*/}
            {/*</Grid>*/}

            <Grid
              item
              xs={6}
              sx={[
                gridItem as SystemStyleObject<Theme>,
                formControl as SystemStyleObject<Theme>,
              ]}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  InputProps={{
                    sx: {
                      "& > .MuiInputBase-input": {
                        py: "12.5px",
                      },
                    },
                  }}
                  className={"datePicker"}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: TextFieldProps) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete={"off"}
                        // fullWidth={true}
                      />
                    );
                  }}
                  components={{
                    OpenPickerIcon: () => <TodayIcon />,
                  }}
                  onChange={(value) => {
                    if (value && isValid(value)) {
                      setFieldValue("date", value);
                    } else {
                      setFieldValue("date", new Date());
                    }
                  }}
                  value={values.date}
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              xs={6}
              sx={[
                gridItem as SystemStyleObject<Theme>,
                { mb: 6, mt: 2 } as SystemStyleObject<Theme>,
              ]}
            >
              <TextField
                sx={textField}
                id="checkNumber"
                name="checkNumber"
                label="Check number (optional)"
                // fullWidth={true}
                value={values.checkNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.checkNumber && !!errors.checkNumber}
                helperText={touched.checkNumber && errors.checkNumber}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default BlockCash;
