import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { AddIcon } from "../../../components";
export function AddCreditField({ setFieldValue }) {
  const [open, setOpen] = useState(false);
  return (
    <Box mt={0.5}>
      {!open ? (
        <Button onClick={() => setOpen(true)}>
          <AddIcon /> add credit
        </Button>
      ) : (
        <Box
          sx={() => ({
            border: `1px solid rgba(50, 142, 218, .16)`,
            paddingX: 2,
            borderRadius: "5px",
          })}
        >
          <Box
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: 16,
              pt: 2,
            })}
          >
            Add credit
          </Box>
          <Box>
            <TextField
              fullWidth
              onChange={(e) => {
                setFieldValue("credit_add", e.target.value);
              }}
              name="amount"
              label="Credit amount"
            />
          </Box>
          <Box>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Credit note"
              onChange={(e) => {
                setFieldValue("credit_note", e.target.value);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
