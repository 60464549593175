import React from "react";
import { useSelector } from "react-redux";
import { customerSelectors } from "../../../state";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { CUSTOMER_LEGACY_INVOICES } from "../../../constants";
import { CustomFooter } from "../../../components";

function _OldSystemInvoicesTable() {
  const apiRef = useGridApiRef();
  const invoices = useSelector(customerSelectors.customerLegacyCharges);

  return (
    <>
      {invoices && (
        <DataGridPro
          autoHeight
          apiRef={apiRef}
          rows={invoices}
          columns={CUSTOMER_LEGACY_INVOICES}
          initialState={{
            columns: {
              columnVisibilityModel: { id: false, created_at: false },
            },
            pagination: {
              pageSize: 25,
            },
            sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
          }}
          components={{ Footer: () => CustomFooter(invoices?.length) }}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
          }}
        />
      )}
    </>
  );
}

export const OldSystemInvoicesTable = React.memo(_OldSystemInvoicesTable);
