import React, { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Transition } from "../../admin/components/Modals/Transition";

export interface Props {
  onClose: () => void;
  onSubmit: () => void;
}

const SuccessModal: FC<Props> = ({ onClose, onSubmit }) => {
  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose()}
    >
      <DialogTitle>Thank you</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Thank you for your application. Kinyan Mishna will review the
          application and will reach out with any questions.
        </DialogContentText>
      </DialogContent>

      <Divider variant="middle" sx={{ border: "1px solid #DDE3EB", mb: 2 }} />

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          sx={{ minWidth: "140px" }}
          onClick={onClose}
        >
          Close
        </Button>

        <Button
          variant="contained"
          color={"primary"}
          sx={{ minWidth: "140px" }}
          onClick={onSubmit}
        >
          Enroll another student
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessModal;
