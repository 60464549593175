import React from "react";
import { useSelector } from "react-redux";
import { listsSelectors } from "../../state";

export const TransactionType = ({ transaction_type_id }) => {
  const _types = useSelector(listsSelectors.typeById);
  if (!transaction_type_id) {
    return null;
  }
  const transactionType = _types[transaction_type_id]?.type ?? "";
  const formatted =
    transactionType === "payment_credit"
      ? "Payment CC"
      : transactionType.charAt(0).toUpperCase() +
        transactionType.slice(1).replace(/_/g, " ");

  return <>{`${formatted}`}</>;
};
