import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { FormikProps } from "formik/dist/types";
import { Props } from "./AddBonusPoints";
import { Fields } from "./schema";
import { FormikHelpers } from "formik";
import {
  adminActions,
  adminSelectors,
  IndividualStudentsRewards,
} from "../../../../../state";
import { toInt } from "../../../../../lib";

export function useAddBonusPoints({ modal, onClose }: Props) {
  const dispatch = useDispatch();

  const activeBonus = useSelector(adminSelectors.activeBonus);
  const [open, setOpen] = useState<boolean>(!!modal);

  const innerRef = useRef<FormikProps<Fields>>(null);

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    dispatch(adminActions.setActiveBonus(null));
    onClose ? onClose(null) : setOpen(false);
  };

  useEffect(() => {
    !modal && innerRef?.current?.resetForm();

    if (modal && activeBonus) {
      if (modal.mode === "edit") {
        innerRef.current?.setValues({
          ...innerRef.current.values,
          reason: activeBonus?.reason!,
          points: activeBonus?.points!,
          id: activeBonus?.id!,
        });
      }
    }

    setOpen(!!modal);
  }, [modal, activeBonus]);

  const handleSubmit = async (
    { points, reason, id }: Fields,
    { setSubmitting }: FormikHelpers<Fields>,
  ) => {
    const reward: IndividualStudentsRewards = {
      id: id,
      points: toInt(points),
      reason,
    };

    const result = await dispatch<
      boolean | ReturnType<typeof adminActions.createIndividualStudentsRewards>
    >(adminActions.createIndividualStudentsRewards(modal!.studentId, reward));
    dispatch(adminActions.getStudent(modal!.studentId));

    setSubmitting(false);

    if (result) {
      const data: typeof modal = {
        ...modal!,
        ...reward,
      };

      onClose?.(data);
    }
  };

  useEffect(() => {
    !modal && innerRef?.current?.resetForm();

    setOpen(!!modal);
  }, [modal]);

  return {
    innerRef,
    open,
    handleClose,
    handleSubmit,
  };
}
