import { object, string } from "yup";
import { intRegExp } from "../../../../lib/regexp";

export interface Fields {
  currMonth: string;
  max_review_checks: string;
  review_bonus: string;
  max_attendance_checks: string;
  attendance_bonus: string;
  charge_date: Date | null;
  test_date: Date | null;
  start_date: string | Date | null;
  end_date: string | undefined;
}

export const initialValues: Fields = {
  currMonth: "",
  max_review_checks: "",
  review_bonus: "",
  max_attendance_checks: "",
  attendance_bonus: "",
  charge_date: null,
  test_date: null,
  start_date: null,
  end_date: "",
};

export const validationSchema = object().shape({
  max_review_checks: string()
    .test(
      "max_review_checks",
      "${label} must be an integer number",
      (val = "") => !val || intRegExp.test(val),
    )
    .test(
      "max_review_checks",
      "${label} must be no less than 0",
      (val = "") => +val >= 0,
    )
    .test(
      "max_review_checks",
      "${label} must be no greater than 10,000",
      (val = "") => +val <= 10_000,
    )
    .label("Max checks"),
  review_bonus: string()
    .test(
      "review_bonus",
      "${label} must be an integer number",
      (val = "") => !val || intRegExp.test(val),
    )
    .test(
      "review_bonus",
      "${label} must be no less than 0",
      (val = "") => +val >= 0,
    )
    .test(
      "review_bonus",
      "${label} must be no greater than 10,000",
      (val = "") => +val <= 10_000,
    )
    .label("Bonus Points"),
  max_attendance_checks: string()
    .test(
      "max_attendance_checks",
      "${label} must be an integer number",
      (val = "") => !val || intRegExp.test(val),
    )
    .test(
      "max_attendance_checks",
      "${label} must be no less than 0",
      (val = "") => +val >= 0,
    )
    .test(
      "max_attendance_checks",
      "${label} must be no greater than 10,000",
      (val = "") => +val <= 10_000,
    )
    .label("Max checks"),
  attendance_bonus: string()
    .test(
      "attendance_bonus",
      "${label} must be an integer number",
      (val = "") => !val || intRegExp.test(val),
    )
    .test(
      "attendance_bonus",
      "${label} must be no less than 0",
      (val = "") => +val >= 0,
    )
    .test(
      "attendance_bonus",
      "${label} must be no greater than 10,000",
      (val = "") => +val <= 10_000,
    )
    .label("Bonus Points"),
});
