import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toInt } from "../../../../lib";
import {
  adminActions,
  adminSelectors,
  listsSelectors,
  StudentReward,
  studentActions,
  studentSelectors,
  uiActions,
  uiSelectors,
} from "../../../../state";
import TabContainer from "../../components/TabContainer";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  activityCaption,
  bonusButton,
  rewardCaption,
} from "./TabRewards.styles";
import {
  STUDENTS_REDEMPTIONS_COLUMNS,
  STUDENT_REWARD_COLUMNS,
} from "../../../../constants";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddBonusPoints from "../../components/Modals/AddBonusPoints";
import { useNavigate } from "react-router-dom";
import { dataGridFilterStyles } from "../../../../themes";

const TabRewards: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState<any>(null);
  const handleModalClose = () => {
    setModal(null);
  };
  useEffect(() => {
    !!params.id &&
      toInt(params.id) &&
      dispatch(adminActions.getStudentRewards(toInt(params.id)));
    dispatch(studentActions.getStudentRedemptions(toInt(params.id)));
  }, [dispatch, params.id]);

  const redemptions = useSelector(studentSelectors.redemptions);
  const control = useSelector(uiSelectors.control);

  // This file has a lot of commented out code do to the
  // reward activity that was requested to be removed for the time being

  const [data, setData] = useState<StudentReward[]>([]);
  const studentRewards = useSelector(adminSelectors.studentRewards);
  const months = useSelector(listsSelectors.monthById);

  useEffect(() => {
    if (studentRewards) {
      const ns = studentRewards.map((s) => {
        return {
          ...s,
          __list: {
            months,
          },
        } as StudentReward;
      });

      setData(ns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentRewards]);

  useEffect(() => {
    switch (control?.type) {
      case "edit":
        setModal({
          mode: control.type,
          studentId: toInt(params.id),
        });
        break;
    }

    control && dispatch(uiActions.setControl(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control]);

  if (!studentRewards) {
    return null;
  }

  return (
    <TabContainer>
      <Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant={"h3"} sx={rewardCaption}>
            Rewards
          </Typography>
          <Button
            variant={"outlined"}
            sx={bonusButton}
            onClick={() => {
              setModal({
                studentId: toInt(params.id),
              });
            }}
          >
            Add bonus points
          </Button>
          <AddBonusPoints modal={modal} onClose={handleModalClose} />
        </Stack>

        <Typography variant={"h6"} sx={activityCaption}>
          Activity
        </Typography>

        <Box height={"400px"} flexGrow={1}>
          <DataGridPro
            pagination={true}
            rowsPerPageOptions={[
              10,
              25,
              50,
              100,
              data?.length > 100 ? data.length : 200,
            ]}
            rows={data}
            columns={STUDENT_REWARD_COLUMNS}
            initialState={{
              pagination: {
                pageSize: 25,
              },
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }],
              },
            }}
            componentsProps={{
              toolbar: { printOptions: { disableToolbarButton: true } },
              panel: {
                sx: {
                  ...dataGridFilterStyles,
                },
              },
            }}
          />
        </Box>
        <Stack
          // marginTop={"30px"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant={"h6"} sx={activityCaption}>
            Redemptions
          </Typography>
          <Button
            variant={"outlined"}
            sx={bonusButton}
            onClick={() =>
              navigate(`/admin/prizes/add-redemption?id=${params.id}`)
            }
          >
            New redemption
          </Button>
          <AddBonusPoints modal={modal} onClose={handleModalClose} />
        </Stack>

        <Box marginTop={"8px"} height={"400px"} flexGrow={1}>
          <DataGridPro
            pagination={true}
            rowsPerPageOptions={[
              10,
              25,
              50,
              100,
              redemptions?.length > 100 ? redemptions.length : 200,
            ]}
            rows={redemptions}
            columns={STUDENTS_REDEMPTIONS_COLUMNS}
            onRowClick={(e) => {
              navigate(`/admin/prizes/redemption/${e.id}`);
            }}
            initialState={{
              pagination: {
                pageSize: 25,
              },
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }],
              },
            }}
            componentsProps={{
              toolbar: { printOptions: { disableToolbarButton: true } },
              panel: {
                sx: {
                  ...dataGridFilterStyles,
                },
              },
            }}
          />
        </Box>
      </Stack>
    </TabContainer>
  );
};

export default TabRewards;
