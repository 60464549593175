import { PhoneMissed } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { CheckCircleIcon } from ".";

export default function StudentCheckIn({ check_in, sx = {} }) {
  if (!check_in) {
    return <></>;
  }
  let tooltip = "";
  let Icon;
  let color;

  switch (check_in) {
    case "reached":
      tooltip = "Student was reached";
      Icon = CheckCircleIcon;
      color = "#00A202";
      break;
    case "called":
      tooltip = "Student wasn't reached";
      Icon = PhoneMissed;
      color = "#BB2018";
      break;

    default:
      break;
  }

  return (
    <Tooltip title={tooltip}>
      <Box sx={sx}>
        <Icon htmlColor={color} />
      </Box>
    </Tooltip>
  );
}
