import React, { useEffect, useState } from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

import { useDispatch, useSelector } from "react-redux";
import { adminActions, listsSelectors } from "../../state";
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  Box,
  IconButton,
  Typography,
} from "@mui/material";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  Page,
  TopSection,
  BottomSection,
  BottomRight,
  BottomLeft,
  Search,
  StyledInputBase,
} from "./styles/BillingPage.styles";
import { Formik, Form, Field } from "formik";
import { MonthsYearSelect, FormikSelect } from "../../components/Select";
import SearchIcon from "@mui/icons-material/Search";
import {
  optionsGenerator,
  optionsZmanGenerator,
  optionsYearGenerator,
  numberWithCommas,
} from "../../lib";
import { BILLING_HISTORY } from "../../constants";
import { CustomFooter, CustomToolbar } from "../../components";
import { dataGridFilterStyles } from "../../themes";

export const BillingPage = () => {
  const apiref = useGridApiRef();
  const dispatch = useDispatch();
  const _zman = useSelector(listsSelectors.semesters);
  const [options, setOptions] = useState<any>();
  const [billingRows, setBillingRows] = useState<any>([]);
  const _monthById = useSelector(listsSelectors.monthById);

  useEffect(() => {
    (async () => {
      const filterValues = await dispatch(
        adminActions.getBillingFilterValues(),
      );

      if (filterValues) {
        setOptions(filterValues);
      }
    })();
  }, [dispatch]);
  const [currMonth, setCurrMonth] = useState<number>();
  const [currYear, setCurrYear] = useState<number>();

  useEffect(() => {
    (async () => {
      const monthId: any = await dispatch(adminActions.getCurrentMonth());
      if (monthId) {
        const yearId = _monthById[monthId]?.year_id ?? 0;
        setCurrMonth(monthId);
        setCurrYear(yearId);
        const bllng = await dispatch(
          adminActions.getBillingHistory(`?month_id=${monthId}`),
        );
        if (bllng) {
          setBillingRows(bllng);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // useEffect(() => {
  //   (async () => {})();
  // }, [currMonth]);
  // const [reportsSection, setReportsSection] = useState(true);
  const [timeSection, setTimeSection] = useState(true);
  // const [programSection, setProgramSection] = useState(true);
  const [locationSection, setLocationSection] = useState(true);
  // const [accountSection, setAccountSection] = useState(true);
  // const [schoolSection, setSchoolSection] = useState(true);
  // const [barMitzvahSection, setBarMitzvahSection] = useState(true);

  const [pageSize, setPageSize] = useState(10);
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    let monthQuery = "";
    if (values.months?.length) {
      values.months.map(
        (month) => (monthQuery = monthQuery + `&month_id=${month}`),
      );
    }
    const queryString = await `${
      values.inactive === true ? `&inactive=true` : ""
    }${values.year && `&year_id=${values.year}`}${
      values.zman && `&semester_id=${values.zman}`
    }${values.chaburah && `&chaburah_id=${values.chaburah}`}${
      values.kinyan && `&track_id=${values.kinyan}`
    }${values.menahel && `&principal_id=${values.menahel}`}${
      values.shul && `&location_id=${values.shul}`
    }${values.neighborhood && `&neighborhood_id=${values.neighborhood}`}${
      values.country && `&country_id=${values.country}`
    }${values.city && `&city=${values.city}`}${
      values.state && `&state=${values.state}`
    }${values.months && monthQuery}`;

    const query = queryString.replace(queryString[0], "?");
    const billing = await dispatch(adminActions.getBillingHistory(query));
    setBillingRows(billing);
    setSubmitting(false);
  };

  return (
    <Page>
      <TopSection>
        <Typography variant={"h3"} sx={{ color: "#082B65", mb: 4, pt: 3 }}>
          Billing
        </Typography>
      </TopSection>

      <BottomSection>
        <BottomLeft>
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                year: currYear,
                zman: "",
                months: currMonth?.toString(),
                chaburah: "",
                kinyan: "",
                menahel: "",
                rosh_kinyan: "",
                shul: "",
                city: "",
                state: "",
                country: "",
                neighborhood: "",
                school: "",
                report: "",
                inactive: false,
              }}
              onSubmit={handleSubmit}
            >
              <Form>
                <label>
                  <Field type="checkbox" name="inactive" />
                  Show inactive
                </label>
                <Divider sx={{ mt: 3, mb: 2 }} />
                {/* <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2, mb: 2 }}
                >
                  <Typography variant="body1" sx={{ color: "#222C64" }}>
                    Saved Reports
                  </Typography>
                  <IconButton
                    disabled={true}
                    onClick={() => setReportsSection(!reportsSection)}
                  >
                    {reportsSection ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </Grid>
                <Collapse in={reportsSection}>
                  <FormikSelect name="report" options={countries} />
                </Collapse>
                <Divider /> */}
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2, mb: 2 }}
                >
                  <Typography variant="body1" sx={{ color: "#222C64" }}>
                    Time
                  </Typography>
                  <IconButton onClick={() => setTimeSection(!timeSection)}>
                    {timeSection ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </Grid>
                <Collapse in={timeSection}>
                  <FormikSelect
                    name="year"
                    options={optionsYearGenerator(
                      options?.by_time?.year || [],
                      "year_id",
                    )}
                  />
                  <FormikSelect
                    name="zman"
                    options={optionsZmanGenerator(
                      _zman,
                      options?.by_time?.semester || [],
                      "semester_id",
                    )}
                  />

                  <Box sx={{ mb: 2 }}>
                    <MonthsYearSelect
                      options={options?.by_time?.month || []}
                      isMulti={true}
                    />
                  </Box>
                </Collapse>
                <Divider />
                {/* <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                   sx={{ mt: 2, mb: 2 }}
                >
                  <Typography variant="body1" sx={{ color: "#222C64" }}>
                    Program
                  </Typography>
                  <IconButton
                    disabled={true}
                    onClick={() => setProgramSection(!programSection)}
                  >
                    {programSection ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </Grid>
                <Collapse in={programSection}>
                  <FormikSelect
                    label="חבורה"
                    name="chaburah"
                    options={optionsGenerator(
                      options?.by_program?.chaburah || [],
                      "chaburah_id",
                    )}
                  />
                  <FormikSelect
                    label="קנין"
                    name="kinyan"
                    options={optionsGenerator(
                      options?.by_program?.track || [],
                      "track_id",
                      "track",
                    )}
                  />
                  <FormikSelect
                    label="מנהל"
                    name="menahel"
                    options={optionsGenerator(
                      options?.by_program?.principal || [],
                      "principal_id",
                    )}
                  />
                  <FormikSelect
                    label="ראש קנין"
                    name="rosh_kinyan"
                    options={optionsGenerator(
                      options?.by_program?.teacher || [],
                      "user_id",
                    )}
                  />
                </Collapse>
                <Divider /> */}
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2, mb: 2 }}
                >
                  <Typography variant="body1" sx={{ color: "#222C64" }}>
                    Location
                  </Typography>
                  <IconButton
                    onClick={() => setLocationSection(!locationSection)}
                  >
                    {locationSection ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </Grid>
                <Collapse in={locationSection}>
                  {/* <FormikSelect
                    disabled={true}
                    name="shul"
                    options={optionsGenerator(
                      options?.by_location?.location || [],
                      "location_id",
                      "location",
                    )}
                  /> */}
                  <FormikSelect
                    name="city"
                    options={optionsGenerator(
                      options?.by_location?.city || [],
                      "city",
                    )}
                  />
                  <FormikSelect
                    name="state"
                    options={optionsGenerator(
                      options?.by_location?.state || [],
                      "state",
                    )}
                  />
                  <FormikSelect
                    name="country"
                    options={optionsGenerator(
                      options?.by_location?.country || [],
                      "country_id",
                      "hr_name",
                    )}
                  />
                  <FormikSelect
                    name="neighborhood"
                    options={optionsGenerator(
                      options?.by_location?.neighborhood || [],
                      "neighborhood_id",
                      "neighborhood",
                    )}
                  />
                </Collapse>
                <Divider />
                {/* <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2, mb: 2 }}

                >
                  <Typography variant="body1" sx={{ color: "#222C64" }}>
                    Account
                  </Typography>
                  <IconButton
                    disabled={true}
                    onClick={() => setAccountSection(!accountSection)}
                  >
                    {accountSection ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </Grid>
                <Collapse in={accountSection}>
                  <FormikSelect name="report" options={countries} />
                </Collapse>
                <Divider /> */}
                {/* <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2, mb: 2 }}

                >
                  <Typography variant="body1" sx={{ color: "#222C64" }}>
                    School
                  </Typography>
                  <IconButton
                    disabled={true}
                    onClick={() => setSchoolSection(!schoolSection)}
                  >
                    {schoolSection ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </Grid>
                <Collapse in={schoolSection}>
                  <FormikSelect name="school" options={countries} />
                </Collapse>
                <Divider /> */}
                {/* <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2, mb: 2 }}

                >
                  <Typography variant="body1" sx={{ color: "#222C64" }}>
                    Bar Mitzvah
                  </Typography>
                  <IconButton
                    disabled={true}
                    onClick={() => setBarMitzvahSection(!barMitzvahSection)}
                  >
                    {barMitzvahSection ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </Grid>
                <Collapse in={barMitzvahSection}>
                  <FormikSelect name="report" options={countries} />
                </Collapse>
                <Divider /> */}

                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 1.5, mb: 1.5, width: "100%" }}
                  type="submit"
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ mb: 1.5, width: "100%" }}
                  type="reset"
                >
                  Reset
                </Button>
              </Form>
            </Formik>
          </div>
        </BottomLeft>

        <BottomRight>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-between"
            sx={{ width: "100%", mt: 1 }}
          >
            <Grid item sx={{ width: "33.33%" }}>
              <Card
                sx={{
                  height: 100,
                  border: "1px solid #DDE3EB",
                  borderRadius: "8px",
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="span">
                    ${" "}
                    {numberWithCommas(
                      Math.abs(billingRows?.totals?.charged_USD),
                    ) || ""}
                  </Typography>
                  <Typography variant="h6">
                    £{" "}
                    {numberWithCommas(
                      Math.abs(billingRows?.totals?.charged_GBP),
                    ) || ""}
                  </Typography>
                  <Typography variant="subtitle1">{"TOTAL CHARGES"}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sx={{ width: "33.33%" }}>
              <Card
                sx={{
                  height: 100,
                  border: "1px solid #DDE3EB",
                  borderRadius: "8px",
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="span">
                    $ {numberWithCommas(billingRows?.totals?.applied_USD)}
                  </Typography>
                  <Typography variant="h6">
                    £ {numberWithCommas(billingRows?.totals?.applied_GBP)}
                  </Typography>
                  <Typography variant="subtitle1">
                    {"TOTAL CHARGES PAID"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sx={{ width: "33.33%" }}>
              <Card
                sx={{
                  height: 100,
                  border: "1px solid #DDE3EB",
                  borderRadius: "8px",
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="span">
                    $ {numberWithCommas(billingRows?.totals?.due_USD)}
                  </Typography>
                  <Typography variant="h6">
                    £ {numberWithCommas(billingRows?.totals?.due_GBP)}
                  </Typography>
                  <Typography variant="subtitle1">{"TOTAL DUE"}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
          >
            <Grid item>
              <Box>
                <Search>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => {
                      apiref.current.setQuickFilterValues([e.target.value]);
                    }}
                  />

                  <SearchIcon
                    sx={{ color: "#B4B8C8", height: 32, paddingTop: 1.5 }}
                  />
                </Search>
              </Box>
            </Grid>
          </Grid>
          {billingRows && (
            <DataGridPro
              apiRef={apiref}
              autoHeight
              rows={billingRows?.data || []}
              columns={BILLING_HISTORY}
              components={{
                Toolbar: () => CustomToolbar(false, null, null, "billing"),
                Footer: () => CustomFooter(billingRows?.data?.length),
              }}
              pagination
              rowsPerPageOptions={[
                10,
                25,
                50,
                100,
                billingRows?.data?.length > 100 && billingRows.data.length,
              ]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              initialState={{
                columns: {
                  columnVisibilityModel: { id: false, created_at: false },
                },
                pagination: {
                  pageSize: 25,
                },
                sorting: {
                  sortModel: [{ field: "created_at", sort: "asc" }],
                },
              }}
              componentsProps={{
                toolbar: {
                  printOptions: { disableToolbarButton: true },
                },
                panel: {
                  sx: {
                    ...dataGridFilterStyles,
                  },
                },
              }}
              sx={{ marginTop: "20px" }}
            />
          )}
        </BottomRight>
      </BottomSection>
    </Page>
  );
};
