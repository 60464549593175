import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormikProps } from "formik/dist/types";
import { Props } from "./AddNeighborhood";
import { Fields } from "./schema";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  listsSelectors,
  NewNeighborhoodWithPrincipals,
} from "../../../../../state";
import { FormikHelpers } from "formik";
import { SelectOption } from "../../../../../components/Select";

export function useAddNeighborhood({ modal, onClose }: Props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(!!modal);

  const [deleteData, setDeleteData] = useState<number | null>(null);

  const innerRef = useRef<FormikProps<Fields>>(null);

  const [neighborhoodPrincipals, setNeighborhoodPrincipals] = useState<
    SelectOption[]
  >([]);

  const _neighborhoodPrincipals = useSelector(listsSelectors.principals);

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    onClose ? onClose(null) : setOpen(false);
  };

  const handleSubmit = async (
    { name, user_id }: Fields,
    { setSubmitting }: FormikHelpers<Fields>,
  ) => {
    if (modal?.mode === "insert") {
      const data: NewNeighborhoodWithPrincipals = {
        name,
        user_id: +user_id,
      };

      const isOk = await dispatch<
        | boolean
        | ReturnType<typeof adminActions.createNeighborhoodWithPrincipals>
      >(adminActions.createNeighborhoodWithPrincipals(data));

      if (isOk) {
        dispatch(adminActions.getNeighborhoodWithPrincipals());

        onClose?.({
          ...modal,
          name,
          user_id,
        });
      }
    }

    if (modal?.mode === "edit") {
      const data: NewNeighborhoodWithPrincipals = {
        name,
        user_id: +user_id,
      };

      const isOk = await dispatch<
        | boolean
        | ReturnType<typeof adminActions.updateNeighborhoodWithPrincipals>
      >(adminActions.updateNeighborhoodWithPrincipals(modal?.id!, data));

      if (isOk) {
        dispatch(adminActions.getNeighborhoodWithPrincipals());

        onClose?.({
          ...modal,
          name,
          user_id,
        });
      }
    }

    setSubmitting(false);
  };

  const deleteFunction = useCallback(async () => {
    if (deleteData) {
      const isOk = await dispatch<
        | boolean
        | ReturnType<typeof adminActions.deleteNeighborhoodWithPrincipals>
      >(adminActions.deleteNeighborhoodWithPrincipals(deleteData));

      if (isOk) {
        dispatch(adminActions.getNeighborhoodWithPrincipals());

        onClose?.(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData]);

  const handleDelete = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      !!modal?.id && setDeleteData(modal?.id);
    },
    [modal],
  );

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteData(null);
  }, []);

  useEffect(() => {
    !modal && innerRef?.current?.resetForm();

    if (modal) {
      const data: Fields = {
        ...innerRef?.current?.initialValues!,
        name: "",
        user_id: "",
      };

      if (modal.mode === "insert") {
        innerRef.current?.setValues({
          ...data,
        });
      }

      if (modal.mode === "edit") {
        innerRef.current?.setValues({
          ...data,
          name: modal.name ?? "",
          user_id: modal.user_id ?? "",
        });
      }
    }

    setOpen(!!modal);
  }, [modal]);

  useEffect(() => {
    if (_neighborhoodPrincipals) {
      const np = _neighborhoodPrincipals.map(({ id, name }) => ({
        id: id.toString(),
        name: name,
      }));

      setNeighborhoodPrincipals(np);
    }
  }, [_neighborhoodPrincipals]);

  return {
    innerRef,
    open,
    neighborhoodPrincipals,
    handleSubmit,
    handleClose,
    handleDelete,
    deleteData,
    handleCloseDeleteModal,
    deleteFunction,
  };
}
