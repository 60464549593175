import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "../lib";
import Autocomplete from "./Autocomplete";
import { Option } from "./Select/Select";
export function AutocompleteAsync({
  placeholder,
  noOptionsText,
  getter,
  value,
  onChange,
  onClear,
  optionSetter = ({ id, name, ...rest }) =>
    ({
      label: name,
      value: id,
      ...rest,
    } as unknown as Option),
  ...autoCompleteProps
}) {
  const [items, setItems] = useState<Option[]>([]);
  const [search, setSearch] = useState<string>("");
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((event: React.SyntheticEvent, search: string) => {
      event?.stopPropagation();
      search ? setSearch(search) : setItems([]);
    }, 300),
    [],
  );
  useEffect(() => {
    (async () => {
      if (search) {
        const data = await dispatch(getter(search));

        if (data) {
          const options = data.rows.map(optionSetter);

          setItems(options);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <Autocomplete
      options={items}
      value={value}
      onChange={(e, v, reason) => {
        if (reason === "clear") {
          onClear();
        } else {
          onChange(v);
        }
      }}
      onInputChange={handleSearch}
      placeholder={placeholder}
      noOptionsText={noOptionsText}
      {...autoCompleteProps}
    />
  );
}
