import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useFormik } from "formik";

import Select, { Option } from "../../../components/Select/Select";
import { DeleteIcon, FileUpload } from "../../../components";
import { toInt } from "../../../lib";
import { prizeSelectors, prizeActions, Product } from "../../../state";
import AdminPages from "..";
import { productSchema } from "./schema";

import { Page, TopSection } from "../styles/GridCardsSearch.styles";
import { textFieldStyles, gridStyles } from "../styles/PrizesPages.styles";

export const AddProduct: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;
  const isEdit = !!id;

  useEffect(() => {
    dispatch(prizeActions.getVendors());
    dispatch(prizeActions.getAllPrizeNumbers());
    if (isEdit) {
      dispatch(prizeActions.getProduct(id));
    }
  }, [dispatch, id, isEdit]);
  const product = useSelector(prizeSelectors.product);
  let allPrizeNumbers = useSelector(prizeSelectors.prizeNumbers);
  if (isEdit) {
    allPrizeNumbers = allPrizeNumbers?.filter(
      (p) => p !== product?.prize_number,
    );
  }

  const formik = useFormik<Product>({
    initialValues: {
      cost: "",
      currency: "USD",
      name_yiddish: "",
      name: "",
      point_value: undefined,
      prize_number: undefined,
      unassigned_count: undefined,
      sku_upc: "",
      vendor_id: undefined,
      active: true,
    },
    enableReinitialize: true,
    validationSchema: productSchema(allPrizeNumbers),
    onSubmit: () => {
      const { vendor_id, unassigned_count, prize_number, cost, ...rest } =
        values;
      dispatch(
        prizeActions.addProduct({
          ...rest,
          cost: cost || undefined,
          vendor_id: toInt(vendor_id) || undefined,
          unassigned_count: toInt(unassigned_count),
          prize_number: toInt(prize_number),
        }),
      );
    },
  });
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    touched,
    errors,
  } = formik;
  useEffect(() => {
    if (isEdit && product) setValues(product);
  }, [isEdit, product, setValues]);

  const vendors = useSelector(prizeSelectors.vendors);

  const vendorOptions = vendors.rows.map(
    (sb) => ({ label: sb.name, value: `${sb.id}` } as Option),
  );
  const submitSuccess = useSelector(prizeSelectors.productSubmitSuccess);
  useEffect(() => {
    if (submitSuccess) {
      dispatch(prizeActions.setProductSubmit(false));
      navigate(AdminPages.prizes.path);
    }
  }, [submitSuccess, dispatch, navigate]);

  const handleBtnClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await formik.submitForm();
  };

  return (
    <Page>
      <TopSection
        style={{
          marginTop: "0px",
          paddingTop: "15px",
          paddingBottom: "15px",
          height: "auto",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <IconButton
            color="primary"
            sx={{ mr: 1 }}
            onClick={() => {
              navigate(AdminPages.prizes.path);
            }}
          >
            <ArrowBackIcon sx={{ color: "#8495B2" }} />
          </IconButton>

          <Typography variant={"h3"} sx={{ color: "#082B65" }}>
            {isEdit ? "Edit" : "Add"} product
          </Typography>
        </Stack>
      </TopSection>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        width="100%"
        sx={{
          maxWidth: "900px",
          paddingLeft: "50px",
          paddingRight: "50px",
          marginBottom: "25px",
        }}
      >
        <Grid item sm={12}>
          <TextField
            sx={textFieldStyles}
            name="name_yiddish"
            label="נאמען"
            fullWidth={true}
            value={values.name_yiddish}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name_yiddish && !!errors.name_yiddish}
            helperText={touched.name_yiddish && errors.name_yiddish}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            sx={textFieldStyles}
            name="name"
            label="Product name"
            fullWidth={true}
            value={values.name || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            type="number"
            sx={textFieldStyles}
            name="prize_number"
            label="Prize number"
            fullWidth={true}
            value={`${values.prize_number}`}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.prize_number && !!errors.prize_number}
            helperText={touched.prize_number && errors.prize_number}
          />
        </Grid>
        <Grid item sm={6}>
          <ButtonGroup
            aria-label="outlined primary button group"
            sx={{ mt: "16px" }}
          >
            <Button
              sx={{ width: "180px", height: "48px" }}
              variant={!values.is_amount ? "contained" : "outlined"}
              onClick={() => {
                setFieldValue("is_amount", false);
              }}
            >
              Prize
            </Button>
            <Button
              sx={{ width: "180px", height: "48px" }}
              variant={values.is_amount ? "contained" : "outlined"}
              onClick={() => {
                setFieldValue("is_amount", true);
              }}
            >
              Gift card
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item sm={6}>
          <Select
            id="vendor"
            options={vendorOptions}
            sx={textFieldStyles}
            name="vendor_id"
            label="Vendor"
            fullWidth={true}
            value={values.vendor_id ? `${values.vendor_id}` : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.vendor_id && !!errors.vendor_id}
            helperText={touched.vendor_id && errors.vendor_id}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            sx={textFieldStyles}
            name="sku_upc"
            label="SKU or UPC"
            fullWidth={true}
            value={values.sku_upc || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.sku_upc && !!errors.sku_upc}
            helperText={touched.sku_upc && errors.sku_upc}
          />
        </Grid>
        <Grid item sm={2}>
          <ButtonGroup
            aria-label="outlined primary button group"
            sx={{ mt: "16px" }}
          >
            <Button
              sx={{ height: "48px" }}
              size="large"
              variant={values.currency === "USD" ? "contained" : "outlined"}
              onClick={() => {
                setFieldValue("currency", "USD");
              }}
            >
              $
            </Button>
            <Button
              sx={{ height: "48px" }}
              size="large"
              variant={values.currency === "GBP" ? "contained" : "outlined"}
              onClick={() => {
                setFieldValue("currency", "GBP");
              }}
            >
              £
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item sm={4}>
          <TextField
            sx={textFieldStyles}
            name="cost"
            label={`${
              values.is_amount ? "Cost per " + values.currency : "Cost"
            }`}
            fullWidth={true}
            value={values.cost || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.cost && !!errors.cost}
            helperText={touched.cost && errors.cost}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            type="number"
            sx={textFieldStyles}
            name="point_value"
            label={`${
              values.is_amount ? "Points per " + values.currency : "Points"
            }`}
            fullWidth={true}
            value={`${values.point_value}`}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.point_value && !!errors.point_value}
            helperText={touched.point_value && errors.point_value}
          />
        </Grid>

        <Grid item sm={6}>
          <TextField
            sx={textFieldStyles}
            name="unassigned_count"
            label="Quantity"
            fullWidth={true}
            value={values.unassigned_count || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.unassigned_count && !!errors.unassigned_count}
            helperText={touched.unassigned_count && errors.unassigned_count}
          />
        </Grid>
        <Grid item sm={12} sx={{ marginBottom: "10px" }}>
          <Typography>Photo upload</Typography>
        </Grid>
        <Grid item sm={12}>
          <FileUpload
            onChange={(f) => {
              setFieldValue("image", f?.[0]);
            }}
            thumbnailUrl={
              values.image
                ? URL.createObjectURL(values.image)
                : values.image_link
            }
            thumbnailName={values.image?.path || values.image_url}
            fileSize={values.image?.size}
            onClear={() => {
              setFieldValue("image_link", "");
              setFieldValue("image_url", "");
              setFieldValue("image", "");
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <ButtonGroup
            aria-label="outlined primary button group"
            sx={{ mt: "16px" }}
          >
            <Button
              sx={{ width: "140px" }}
              // size="large"
              variant={values.active ? "contained" : "outlined"}
              onClick={() => {
                setFieldValue("active", true);
              }}
            >
              Active
            </Button>
            <Button
              sx={{ width: "140px" }}
              // size="large"
              variant={!values.active ? "contained" : "outlined"}
              onClick={() => {
                setFieldValue("active", false);
              }}
            >
              Inactive
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item sm={12}>
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          width="100%"
          sx={gridStyles}
        >
          <Grid item sm={2} sx={textFieldStyles}>
            <Button
              sx={{ width: "180px" }}
              variant="outlined"
              onClick={() => {
                navigate(AdminPages.prizes.path);
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item sm={isEdit ? 2 : 9} sx={textFieldStyles}>
            <Button
              sx={{ width: "180px" }}
              variant="contained"
              onClick={handleBtnClick}
            >
              Save
            </Button>
          </Grid>

          {isEdit && (
            <Grid item sm={6} sx={{ display: "flex", alignItems: "center" }}>
              {" "}
              <Button
                sx={{ color: "#B20000" }}
                variant="text"
                onClick={() => {
                  dispatch(prizeActions.deleteProduct(values.id));
                  navigate(AdminPages.prizes.path);
                }}
              >
                <DeleteIcon sx={{ marginRight: "5px" }} /> Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};
