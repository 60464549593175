import { styled } from "@mui/styles";
import { InputBase, Tab } from "@mui/material";

export const Page = styled(`div`)(() => ({
  minWidth: "100%",
  minHeight: "100%",
}));

export const TopSection = styled(`div`)(() => ({
  height: 239,
  backgroundColor: "#F3F6F9",
  marginTop: 25,
  paddingLeft: 25,
  paddingRight: 25,
}));
export const MiddleSection = styled(`div`)(() => ({
  marginTop: -80,
  paddingLeft: 25,
  paddingRight: 25,
}));
export const BottomSection = styled(`div`)(() => ({
  paddingRight: 25,
  paddingLeft: 12,
  marginBottom: 25,
  marginTop: 160,
}));
export const TabStyled = styled(Tab)(() => ({
  color: "#082B65",
  fontFamily: "Roboto",
  fontSize: "16px",
}));
export const Search = styled("div")(() => ({
  position: "relative",
  height: "48px",
  width: "400px",
  border: "1px solid #DDE3EB",
  borderRadius: "4px",
  backgroundColor: "#fff",
  marginTop: 16,
  marginBottom: -64,
  zIndex: 1,
}));

export const StyledInputBase = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    marginLeft: 16,
    width: "350px",
    paddingBottom: 2,
  },
}));
