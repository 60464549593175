import { SxProps, Theme } from "@mui/material";

export const container: SxProps<Theme> = {
  borderLeft: "1px solid #DDE3EB",
  padding: "6px 0 6px 16px",
  mb: 2,

  flexGrow: 1,

  "&:last-child": {
    mb: 0,
  },

  "& > .caption": {
    color: "#5C738E",
    fontSize: "12px",
    fontWeight: 600,
    letterSpacing: "0.09px",
    lineHeight: "17px",
    mb: 0.5,
  },
};

export const valueStyles: SxProps<Theme> = {
  color: "#000000",
  fontSize: "13px",
  letterSpacing: 0,
  lineHeight: "14px",
};
