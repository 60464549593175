import React, { FC } from "react";
import { Button, Stack } from "@mui/material";
import { button } from "../ChangeStudentPage.styles";
import { ButtonType, TabType } from "../types";
import { PaymentMethod } from "../../../../state";

interface Props {
  tab: TabType;
  paymentMethod: PaymentMethod;
  onClick: (type: ButtonType, e: React.MouseEvent<HTMLButtonElement>) => void;
  continueDisabled: boolean;
  pageMode?: string;
  isDraft?: boolean;
  reactivating;
}

const Buttons: FC<Props> = ({
  continueDisabled = false,
  tab,
  paymentMethod,
  onClick,
  pageMode,
  isDraft,
  reactivating,
}) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-start"}
      gap={"28px"}
      sx={{ px: "55px", paddingBottom: 2 }}
    >
      {(["general", "enrollment"] as Array<TabType>).includes(tab) && (
        <>
          {pageMode !== "edit" && tab !== "billing" && (
            <Button
              variant={"outlined"}
              sx={button}
              onClick={onClick.bind(null, "back")}
            >
              back
            </Button>
          )}

          {pageMode === "insert" ? (
            <Button
              variant={"contained"}
              sx={button}
              onClick={onClick.bind(null, "saveDraft")}
              disabled={continueDisabled}
            >
              Save as Draft
            </Button>
          ) : (
            isDraft && (
              <Button
                variant={"contained"}
                sx={button}
                onClick={onClick.bind(null, "saveAndContinue")}
                disabled={continueDisabled}
              >
                Continue
              </Button>
            )
          )}

          <Button
            variant={"contained"}
            sx={button}
            onClick={onClick.bind(
              null,
              reactivating && tab === "general" ? "reactivate" : "continue",
            )}
            disabled={continueDisabled}
          >
            {pageMode === "edit"
              ? reactivating && tab === "general"
                ? "continue reactivation"
                : "save"
              : "continue"}
          </Button>

          {/* {tab === "enrollment" && (
            <Button
              variant={"text"}
              sx={button}
              onClick={onClick.bind(null, "skip")}
            >
              skip
            </Button>
          )} */}
        </>
      )}

      {tab === "billing" && paymentMethod === "cash" && (
        <>
          {pageMode !== "edit" && tab !== "billing" && (
            <Button
              variant={"outlined"}
              sx={button}
              onClick={onClick.bind(null, "cashCancel")}
            >
              back
            </Button>
          )}

          <Button
            variant={"contained"}
            sx={button}
            onClick={onClick.bind(null, "cashSave")}
          >
            save
          </Button>
        </>
      )}

      {tab === "billing" && paymentMethod === "credit" && (
        <>
          {pageMode !== "edit" && tab !== "billing" && (
            <Button
              variant={"outlined"}
              sx={button}
              onClick={onClick.bind(null, "creditBack")}
            >
              back
            </Button>
          )}

          <Button
            variant={"contained"}
            sx={button}
            onClick={onClick.bind(null, "creditSave")}
          >
            save & charge card
          </Button>
        </>
      )}
    </Stack>
  );
};

export default Buttons;
