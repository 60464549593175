import React, { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { container } from "./SemesterView.styles";

interface Props {
  semester: string;
  onBackBtnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SemesterView: FC<Props> = ({ semester, onBackBtnClick }) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      sx={container}
    >
      <IconButton color="primary" onClick={onBackBtnClick}>
        <ArrowBackIcon />
      </IconButton>

      <Typography variant={"h6"} display={"inline"}>
        {semester}
      </Typography>
    </Stack>
  );
};

export default SemesterView;
