import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { CSSProperties } from "react";

export const topSectionStyle: CSSProperties = {
  marginTop: 0,
  paddingTop: 24,
  height: "124px",
  borderBottom: "1px solid #DDE3EB",
};

export const bottomSectionStyle: CSSProperties = {
  marginTop: "-49px",
  paddingTop: 0,
};

export const tabsStyle = (isInsertMode: boolean): SxProps<Theme> => {
  return {
    px: 2,

    "& .MuiTab-root": {
      cursor: isInsertMode ? "default" : "cursor",
    },

    "& .MuiTabs-flexContainer": {
      "& > .MuiTab-root": {
        color: "#5C738E",
        fontSize: "16px",
        letterSpacing: "0.15px",
        lineHeight: "24px",

        "&.Mui-selected": {
          color: "#082B65",
        },
      },
    },
  };
};

export const contentWrapper: SxProps<Theme> = {
  width: "720px",
  pb: "24px",
  borderBottom: "1px solid #D5D8EC",
};

export const gridStyle: SxProps<Theme> = {
  mb: "32px",
  width: "100%",

  "&:last-child": {
    mb: 0,
  },
};
