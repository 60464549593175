import React from "react";
import { Button } from "@mui/material";
import { adminActions, useDispatch } from "../../state";

interface ButtonProps {
  report: "student" | "billing";
}

const _fetchReport = ({ report }: ButtonProps) => {
  const dispatch = useDispatch();
  const handleFetch = async () => {
    return await dispatch(adminActions.getCsvData(report));
  };
  const handleButtonClick = () => {
    handleFetch()
      .then((response) => {
        const csvData = response; // Assuming the API response contains the CSV string
        downloadCSV(csvData);
      })
      .catch((error) => {
        console.error("Error fetching CSV data:", error);
      });
  };

  const downloadCSV = (csvData) => {
    const date = new Date().toISOString();
    const link = document.createElement("a");
    link.href = `data:text/csv;charset=utf-8,\ufeff${encodeURIComponent(
      csvData,
    )}`;
    link.download = `${report}_${date}.csv`;
    link.click();
  };

  return (
    <Button
      sx={{ minWidth: 40, alignItems: "center", height: 15 }}
      onClick={handleButtonClick}
    >
      <p>Download CSV</p>
    </Button>
  );
};

export const ExportHistoryButton = React.memo(_fetchReport);
