import React, { FC } from "react";
import { SelectOption } from "./FormikSelect";
import { useFormikContext } from "formik";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  label: string;
  name: string;
  options: SelectOption[];
}

const FormikSelectMultiple: FC<Props> = ({ label, name, options }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext<any>();

  const handleChange = (e: SelectChangeEvent) => {
    const {
      target: { value },
    } = e;

    setFieldValue(name, value);
  };

  const id = `id-${name}-${label}`;

  return (
    <FormControl sx={{ my: 2, width: "100%" }}>
      <InputLabel id={id}>{label}</InputLabel>

      <Select
        labelId={id}
        fullWidth
        multiple
        value={values[name]}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          options
            .filter((r) => selected.includes(r.id as any))
            .map((r) => r.name)
            .join(", ")
        }
        MenuProps={MenuProps}
        error={touched[name] && Boolean(errors[name])}
      >
        {options.map(({ id, name: _name }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={values[name]?.indexOf(id) > -1} />
            <ListItemText primary={_name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormikSelectMultiple;
