import { SxProps, Theme } from "@mui/material";

export const wrapper: SxProps<Theme> = {
  width: "100vw",
  height: "100vh",
  background: "linear-gradient(316.28deg, #003395 0%, #00175C 100%)",
  overflow: "hidden",
  position: "relative",
};

export const boxLeft: SxProps<Theme> = {
  position: "absolute",
  left: 0,
  top: 0,
};

export const boxRight: SxProps<Theme> = {
  position: "absolute",
  right: 0,
  top: 0,
};

export const content: SxProps<Theme> = {
  minWidth: "360px",
  maxWidth: "400px",
  margin: "10% auto 0",

  "> .img": {
    height: "100px",
    width: "187px",
    alignSelf: "center",
    mb: "68px",
  },

  "> .code": {
    color: "#FFFFFF",
    fontSize: "128px",
    fontWeight: 100,
    letterSpacing: "1.37px",
    lineHeight: "175px",
    textAlign: "center",
    mb: "20px",
  },

  "> .status": {
    color: "#FFFFFF",
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "0.28px",
    lineHeight: "36px",
    textAlign: "center",
    mb: "30px",
  },

  "> .message": {
    color: "#FFFFFF",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "22px",
    textAlign: "center",
  },
};
