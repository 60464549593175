import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddIcon } from "../../../components";

import { useFormik } from "formik";
import {
  customerActions,
  getCustomerName,
  studentActions,
  uiActions,
  useDispatch,
} from "../../../state";
import CheckBox from "../../../components/CheckBox";
import { AddCardModal } from "../../admin/components";
import {
  buildCustomerData,
  buildStudentData,
  customerInitialState,
  studentInitialState,
  studentValidation,
  customerValidation,
} from "./utils";
import { StudentDetails } from "./StudentDetails";
import { CustomerDetails } from "./CustomerDetails";
import logo from "../../../assets/img/logo.png";
import SuccessModal from "./SuccesModal";
export const ApplicationForm = () => {
  const dispatch = useDispatch();

  const [addCardOpen, setAddCardOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [englishBirthday, setEnglishBirthday] = useState<{
    month?: string;
    year?: number;
    day?: number;
    date?: Date | "" | undefined;
  }>({});

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue =
        "Are you sure you want to leave? Your progress will not be saved.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    dispatch(studentActions.getWebApplicationDetails());
  }, [dispatch]);
  const formikCustomer = useFormik<typeof customerInitialState>({
    initialValues: customerInitialState,
    enableReinitialize: true,
    validationSchema: customerValidation,
    onSubmit: async () => {
      return true;
    },
  });
  const formikStudent = useFormik<typeof studentInitialState>({
    initialValues: studentInitialState,
    enableReinitialize: true,
    validationSchema: studentValidation,
    onSubmit: async () => {
      return true;
    },
  });
  const resetStudent = () => {
    setEnglishBirthday({});
    formikStudent.setValues(studentInitialState);
    formikStudent.setTouched({});
    formikCustomer.setFieldValue("cardData", "");
  };
  const resetForm = () => {
    resetStudent();
    formikCustomer.setValues(customerInitialState);
    formikCustomer.setTouched({});
  };
  const submitForm = async () => {
    const customerSubmit = await formikCustomer.submitForm();
    const studentSubmit = await formikStudent.submitForm();

    if (customerSubmit && studentSubmit) {
      const success: any = await dispatch(
        customerActions.applicationFormSubmit({
          customer: buildCustomerData(formikCustomer.values),
          student: buildStudentData(formikStudent.values as any),
        }),
      );
      if (success) {
        setConfirmationOpen(true);
      }
    }
  };
  if (!formikStudent || !formikCustomer) {
    return null;
  }

  return (
    <Box>
      <Box
        fontWeight="bold"
        color="#082B65"
        fontSize={26}
        paddingY={3}
        borderBottom="1px solid #DDE3EB"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Box display="inline-flex" alignItems="center">
          {" "}
          <Box paddingRight={1}>
            <img src={logo} style={{ width: 75 }} alt="logo" />
          </Box>
          Application Form
        </Box>
      </Box>

      <Box display="flex" width="100%" justifyContent="center" paddingY={3}>
        <Box
          sx={{
            width: "954px",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            padding: 3,
            boxShadow:
              "0 2px 12px 0 rgba(218,223,246,0.3), 0 2px 24px 0 rgba(249,250,252,0.5)",
          }}
        >
          {formikCustomer && <CustomerDetails formik={formikCustomer} />}
          <StudentDetails
            formik={formikStudent}
            customerFormik={formikCustomer}
            englishBirthday={englishBirthday}
            setEnglishBirthday={setEnglishBirthday}
          />
          <Typography fontSize={26} fontWeight="bold" paddingTop={4}>
            Payment
          </Typography>
          <Typography fontSize={14} color="#5C738E">
            *קרעדיט קארד וואס וועט געטשארזשט ווערן חודש'ליך
          </Typography>
          <Box paddingY={2}>
            <Button
              variant="outlined"
              onClick={() => {
                setAddCardOpen(true);
              }}
              sx={{
                borderColor: formikCustomer.errors?.cardData
                  ? "#bb2018"
                  : "unset",
              }}
              disabled={!!formikCustomer.values.cardData}
            >
              <AddIcon sx={{ marginRight: 2 }} />{" "}
              {`${formikCustomer.values.cardData ? "Card added" : "Add card"}`}
            </Button>
          </Box>
          <Box mt={4} display="flex" justifyContent="end">
            <Box
              textAlign="justify"
              sx={{ direction: "rtl" }}
              paddingRight={2}
              fontSize={16}
              fontWeight="bold"
              color={
                formikCustomer.touched.terms && !formikCustomer.values.terms
                  ? "#bb2018"
                  : undefined
              }
            >
              איך באשטעטיג אז מיין קארד וועט ווערן געטשארדזט יעדע "פערטן" מאנטאג
              (צווישן כ'-כה לחודש לויט אידישע חדשים), פארן קומעדיגע חודש, ביז
              איך וועל אריינרופן דאס צו אפשטעלן. אויב וויל מען אפשטעלן די קארטל,
              מוז דאס ווערן געמאלדן ביז כ' לחודש, אויב מ'רופט נאך כ' לחודש וועלן
              מיר דאס נישט קענען ריפאנדען.
            </Box>
            <CheckBox
              checked={formikCustomer.values.terms}
              error={
                formikCustomer.touched.terms && !formikCustomer.values.terms
              }
              onChange={() => {
                formikCustomer.setFieldValue(
                  "terms",
                  !formikCustomer.values.terms,
                );
              }}
            />
          </Box>
          <Box paddingRight="51px">
            <Typography
              fontSize={16}
              fontWeight="bold"
              paddingTop={4}
              sx={{ direction: "rtl" }}
            >
              איר וועט ווערן געטשארדזט די ערשטע פעימענט 1-2 טעג נאכן אריינשיקן
              די אפליקעישן
            </Typography>
            <Typography
              fontSize={16}
              fontWeight="bold"
              paddingTop={4}
              paddingBottom={4}
              sx={{ direction: "rtl" }}
            >
              ווען מען שיקט צוריק דעם אויסגעפילטן אפליקעישאן, ערווארט צו באקומען
              א קאנפירמעישאן אז מיר האבן ערהאלטן אייער אפליקעישאן, און אזוי אויך
              די צייט ווען אייער אינגל וועט באקומען זיין ערשטע פעקעדזש{" "}
            </Typography>
          </Box>
          {confirmationOpen && (
            <SuccessModal
              onSubmit={() => {
                resetStudent();
                setConfirmationOpen(false);
              }}
              onClose={() => {
                resetForm();
                setConfirmationOpen(false);
              }}
            />
          )}

          {addCardOpen && (
            <AddCardModal
              name={getCustomerName(formikCustomer.values)}
              handleClose={() => {
                setAddCardOpen(false);
              }}
              submit={(data) => {
                formikCustomer.setFieldValue("cardData", data);
                setAddCardOpen(false);
                dispatch(uiActions.showSuccess("Card successfully added"));
              }}
            />
          )}
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingTop={3}
            borderTop="1px solid #DDE3EB"
          >
            <Button
              onClick={resetForm}
              variant="outlined"
              sx={{ width: 180, marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={submitForm}>
              Submit Application
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
