import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerSelectors, customerActions } from "../../../state";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router";
import { Page } from "../styles/CustomerPage.styles";
import { PaymentCards } from "../components";
import { toInt } from "../../../lib";
import EditPaymentSettings from "./EditPaymentSettings/EditPaymentSettings";
import { PaymentSavedCardBlock } from "../../../components";
import { status } from "../../../constants";
import CheckBox from "../../../components/CheckBox";

interface EditPaymentData {
  showModal: boolean;
  customerId: number;
  studentId: number;
  name: string;
}

export const CustomerTabPayments = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const customer = useSelector(customerSelectors.customer) as ReturnType<
    typeof customerSelectors.customer
  >;
  const billingInfo = useSelector(customerSelectors.student_billing);
  const [editPayment, setEditPayment] = useState<EditPaymentData | null>(null);
  const { currency } = customer!;

  const handleEditPaymentClick = (
    data: EditPaymentData,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    setEditPayment(data);
  };

  useEffect(() => {
    !!params.id &&
      dispatch(customerActions.getCustomerBillingDetails(+params.id));
  }, [params.id, dispatch]);

  const handleCloseEditPaymentSettings = () => {
    !!params.id &&
      dispatch(customerActions.getCustomerBillingDetails(+params.id));
    setEditPayment(null);
  };

  if (!customer) return null;
  return (
    <Page>
      <>
        {billingInfo && (
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            sx={{ width: "100%" }}
          >
            {customer?.students
              ?.filter((s) => s.status_id !== status.INACTIVE)
              ?.map((student, index) => {
                return (
                  <PaymentCards
                    student={student}
                    key={index}
                    index={index}
                    currency={currency}
                    billingDetails={billingInfo?.filter(
                      (billingDetail) => billingDetail.id === student.id,
                    )}
                    cardData={customer.payment_methods?.filter(
                      (card) =>
                        card.id ==
                        billingInfo?.filter(
                          (billingDetail) => billingDetail.id == student.id,
                        )?.[0]?.payment_method?.processor_payment_method_id,
                    )}
                    onEditPaymentClick={handleEditPaymentClick.bind(null, {
                      showModal: true,
                      customerId: toInt(params?.id),
                      studentId: student.id,
                      name: `${student.first_name} ${student.last_name}`,
                    })}
                  />
                );
              })}
          </Grid>
        )}
        <Box display="flex" justifyContent="end">
          <Box maxWidth={720} mt={4} display="flex" justifyContent="end">
            <Box
              textAlign="justify"
              sx={{ direction: "rtl" }}
              paddingRight={2}
              fontSize={16}
              color={!customer.terms ? "#bb2018" : undefined}
              fontWeight={500}
            >
              איך באשטעטיג אז מיין קארד וועט ווערן געטשארדזט יעדע "פערטן" מאנטאג
              (צווישן כ'-כה לחודש לויט אידישע חדשים), פארן קומעדיגע חודש, ביז
              איך וועל אריינרופן דאס צו אפשטעלן. אויב וויל מען אפשטעלן די קארטל,
              מוז דאס ווערן געמאלדן ביז כ' לחודש, אויב מ'רופט נאך כ' לחודש וועלן
              מיר דאס נישט קענען ריפאנדען.
            </Box>
            <CheckBox
              size="medium"
              error={!customer.terms}
              disabled={customer.terms}
              checked={customer.terms}
              onChange={() => {
                dispatch(customerActions.acceptTerms(customer?.id));
              }}
            />
          </Box>
        </Box>
      </>

      <EditPaymentSettings
        showModal={!!editPayment?.showModal}
        customerId={editPayment?.customerId ?? 0}
        studentId={editPayment?.studentId ?? 0}
        name={editPayment?.name ?? ""}
        onClose={handleCloseEditPaymentSettings}
      />
      <Box
        sx={{
          m: 2,
          mr: 5,
        }}
      >
        <PaymentSavedCardBlock defaultView={true} />
      </Box>
    </Page>
  );
};

export default CustomerTabPayments;
