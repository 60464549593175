import { object, string } from "yup";
import { intRegExp } from "../../../../../lib/regexp";

export interface Fields {
  id: number | null;
  points: number | string;
  reason: string;
}

export const initialValues: Fields = {
  id: null,
  points: "",
  reason: "",
};

export const validationSchema = object().shape({
  points: string()
    .test("points", "${label} must be an integer number", (val = "") =>
      intRegExp.test(val),
    )
    .test("points", "${label} must be no less than 0", (val = "") => +val > 0)
    .test(
      "points",
      "${label} must be no greater than 10,000",
      (val = "") => +val <= 10_000,
    )
    .label("Points"),
});
