import { useEffect, useState } from "react";
import { List, AppState } from "../state";
import {
  DataGridPro,
  DataGridProProps,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridState,
} from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { stringify } from "query-string";
import { CustomFooter } from "../components";

interface DataGridAsyncProps
  extends Omit<DataGridProProps, "rows">,
    React.RefAttributes<HTMLDivElement> {
  loadDataAction: any;
  selector: (state: AppState) => List<any>;
  reloadVersion?: number;
}

export function DataGridAsync(props: DataGridAsyncProps) {
  const { loadDataAction, selector } = props;
  const dispatch = useDispatch();
  const data = useSelector(selector);
  let dataCancelController: AbortController;

  const { components, reloadVersion, columns, initialState, ...otherProps } =
    props;
  const [tableStateParams, setTableStateParams] = useState("");

  useEffect(() => {
    const filters = initialState?.filter?.filterModel?.items?.length || 0;
    const sort = initialState?.sorting?.sortModel?.length || 0;
    if ((filters > 0 || sort > 0) && tableStateParams === "") {
      return;
    }
    if (dataCancelController) {
      dataCancelController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dataCancelController = new AbortController();
    dispatch(loadDataAction(tableStateParams, dataCancelController.signal));
  }, [
    dispatch,
    loadDataAction,
    tableStateParams,
    reloadVersion,
    initialState?.filter?.filterModel?.items?.length,
    initialState?.sorting?.sortModel?.length,
  ]);

  let { rowsPerPageOptions } = props;

  if (!rowsPerPageOptions) {
    rowsPerPageOptions =
      data.count > 100 ? [10, 25, 50, 100, data.count] : [10, 25, 50, 100, 200];
  }

  return (
    <DataGridPro
      {...otherProps}
      rowsPerPageOptions={rowsPerPageOptions}
      initialState={initialState}
      components={{ ...components, Footer: () => CustomFooter(data.count) }}
      columns={columns.map((c) => setFilterOptions(c))}
      sortingMode="server"
      filterMode="server"
      paginationMode="server"
      onStateChange={(s, _e, _d) => {
        return setTableStateParams(buildParams(s));
      }}
      rows={data.rows}
      rowCount={data.count}
    />
  );
}

type SortingModel = GridState["sorting"]["sortModel"][0];

interface QueryParams {
  sort?: SortingModel["sort"];
  field?: SortingModel["field"];
  filter?: string;
  range?: number[];
  q?: string;
  linkOperator?: string;
}

export const buildParams = (state: GridState) => {
  const queryParams: QueryParams = {};
  if (state.sorting?.sortModel?.length) {
    queryParams.sort = state.sorting.sortModel[0].sort;
    queryParams.field = state.sorting.sortModel[0].field;
  }
  if (state.filter.filterModel.items.length) {
    queryParams.linkOperator = state.filter.filterModel.linkOperator || "and";
    queryParams.filter = JSON.stringify(
      state.filter.filterModel.items.map(
        ({ columnField, operatorValue, value }) => {
          let v = value;
          switch (state.columns.lookup[columnField].type) {
            case "boolean":
              if (value === "true" || value === true) v = true;
              else if (value === "false" || value === false) v = false;
              break;
            case "number":
              v = parseFloat(value);
              break;
          }
          return {
            columnField,
            operatorValue,
            value: v,
          };
        },
      ),
    );
  }
  if (state.filter.filterModel.quickFilterValues?.length) {
    queryParams.q = state.filter.filterModel.quickFilterValues[0];
  }
  queryParams.range = [state.pagination.page, state.pagination.pageSize];
  return stringify(queryParams);
};
const numberFilters = [">", "<", "=", "!="];
const stringFilters = ["contains", "equals", "startsWith"];
const dateFilters = ["is", "onOrAfter", "before"];
const selectionFilters = ["is"];
const booleanFilters = ["is"];

const setFilterOptions = (column) => {
  let filters;
  let defaultFilters;
  switch (column.type) {
    case "number":
      filters = numberFilters;
      defaultFilters = getGridNumericOperators();
      break;
    case "date":
    case "dateTime":
      filters = dateFilters;
      defaultFilters = getGridDateOperators();
      break;
    case "singleSelect":
      filters = selectionFilters;
      defaultFilters = getGridSingleSelectOperators();
      break;
    case "boolean":
      filters = booleanFilters;
      defaultFilters = getGridBooleanOperators();
      break;
    case "string":
    default:
      filters = stringFilters;
      defaultFilters = getGridStringOperators();
      break;
  }
  return {
    ...column,
    filterOperators: defaultFilters.filter((o) =>
      filters.some((f) => f === o.value),
    ),
  };
};
