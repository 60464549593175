import React, { useEffect, useState } from "react";
import {
  DataGridPro,
  useGridApiRef,
  GridRowId,
  gridVisibleSortedRowIdsSelector,
} from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, customerActions, customerSelectors } from "../../state";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate, To } from "react-router-dom";
import { CUSTOMERS_COLUMNS } from "../../constants";
import {
  BottomSection,
  Page,
  TopSection,
  Search,
  StyledInputBase,
} from "./styles/GridCardsSearch.styles";
import SearchIcon from "@mui/icons-material/Search";
import { AdminCards } from "./components";
import AdminPages from ".";
import { numberWithCommas, numberWithCommasNoZeros } from "../../lib";
import { CustomFooter, CustomToolbar } from "../../components";
import { dataGridFilterStyles } from "../../themes";

export const CustomersPage = () => {
  const apiref = useGridApiRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hideColumns = {
    first_name: false,
    last_name: false,
    last_name_yiddish: false,
    first_name_yiddish: false,
    country: false,
    address: false,
    address_2: false,
    city: false,
    state: false,
    zip: false,
    email: false,
    notes: false,
    completed: false,
  };
  const customers = useSelector(customerSelectors.customers);
  const itemButtonClick = (to: To) => {
    navigate(`/admin/customer/${to}`);
  };
  const isAdmin = useSelector(authSelectors.isAdmin);
  const isLimited = useSelector(authSelectors.isLimited);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const totals = useSelector(customerSelectors.customersTotals);

  useEffect(() => {
    if (!isLoggedIn) return;
    dispatch(customerActions.getCustomers());
    dispatch(customerActions.getCustomersTotal());
    if (!isLimited) {
      setTimeout(() => {
        dispatch(customerActions.getCustomers(true));
      }, 1500);
    }
  }, [dispatch, isLimited, isLoggedIn]);

  const handleClick = (e) => {
    itemButtonClick(e.id);
  };
  const [visibleRows, setVisibleRows] = useState<GridRowId[]>([]);

  return (
    <Page>
      <TopSection>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h3">Customers</Typography>
          </Grid>
          <Grid item>
            {!isLimited && (
              <Button
                variant={"contained"}
                color="primary"
                onClick={() => navigate(AdminPages.addNewCustomer.path)}
              >
                Add Customer
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" justifyContent="flex-start">
          <Grid item>
            <AdminCards
              title={numberWithCommasNoZeros(totals?.active_count)}
              title2={""}
              caption={"Total active customers"}
            />
          </Grid>
          <Grid item>
            {isAdmin && (
              <AdminCards
                title={
                  "$" +
                    numberWithCommas(Math.abs(parseInt(totals?.usd_balance))) ||
                  ""
                }
                title2={
                  "£" +
                    numberWithCommas(Math.abs(parseInt(totals?.gdp_balance))) ||
                  ""
                }
                caption={"Total open balances"}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" justifyContent="flex-start">
          <Grid item>
            <Box>
              <Search>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    apiref.current.setQuickFilterValues([e.target.value]);
                  }}
                />
                <SearchIcon
                  sx={{ color: "#B4B8C8", height: 32, paddingTop: 1.5 }}
                />
              </Search>
            </Box>
          </Grid>
        </Grid>
      </TopSection>
      <BottomSection>
        <DataGridPro
          getRowHeight={() => "auto"}
          sx={{
            "& .MuiDataGrid-row": { cursor: "pointer" },
            "& .MuiDataGrid-cell": { paddingBottom: 1, marginTop: 1 },
          }}
          onStateChange={(state) => {
            const newRows = gridVisibleSortedRowIdsSelector(state);
            setVisibleRows(newRows);
          }}
          pagination={true}
          rowsPerPageOptions={[
            10,
            25,
            50,
            100,
            visibleRows?.length > 100 ? visibleRows.length : 150,
          ]}
          apiRef={apiref}
          onRowClick={(e) => {
            handleClick(e);
          }}
          autoHeight
          rows={customers}
          columns={CUSTOMERS_COLUMNS.filter((x) =>
            !isLimited ? true : x.field !== "balance",
          )}
          initialState={{
            columns: {
              columnVisibilityModel: hideColumns,
            },
            pagination: {
              pageSize: 25,
            },
            sorting: {
              sortModel: [{ field: "fullName", sort: "asc" }],
            },
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: "status",
                    operatorValue: "isAnyOf",
                    value: ["Active"],
                  },
                ],
              },
            },
          }}
          components={{
            Toolbar: CustomToolbar,
            Footer: () => CustomFooter(visibleRows.length),
          }}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
            panel: {
              sx: {
                ...dataGridFilterStyles,
              },
            },
          }}
        />
      </BottomSection>
    </Page>
  );
};
