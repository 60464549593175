import {
  Typography,
  Stack,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChargeCard } from ".";
import { rowToolBtnStyle } from "../../constants/styles";
import { savedCards } from "../../pages/admin/AddNewPaymentPage/AddNewPaymentPage.styles";
import DeleteDialog from "../../pages/admin/components/Modals/DeleteDialog";
import {
  adminActions,
  customerActions,
  customerSelectors,
  uiActions,
  useDispatch,
  useSelector,
} from "../../state";
import { AddIcon, CheckCircleIcon, DeleteIcon } from "../icons";

interface Props {
  formik?: any;
  payment_method_id?: number | null;
  setPayment_method_id?: (cardId: number | null) => void;
  defaultView?: boolean;
  isDisabled?: boolean;
}

export const PaymentSavedCardBlock = ({
  formik,
  payment_method_id,
  setPayment_method_id,
  defaultView = false,
  isDisabled = false,
}: Props) => {
  const cards = useSelector(customerSelectors.customer_payment_methods);
  const dispatch = useDispatch();
  const params = useParams();

  const handleSetDefault = (id, cardId, revision) => {
    dispatch(adminActions.setCardDefault(id, cardId, revision));
  };

  const [modalData, setModalData] = useState<{
    customer_id: number;
    cardId: string;
    maskedNumber: string;
  } | null>(null);

  const [cardIdToUse, setCardIdToUse] = useState<{
    id: string | undefined;
    payment_method_id: number | undefined;
  } | null>(null);

  const handleDeleteCard = useCallback(() => {
    if (modalData) {
      dispatch(
        customerActions.deleteCustomerCard(
          modalData.customer_id,
          modalData.cardId,
        ),
      );
    }
  }, [dispatch, modalData]);

  useEffect(() => {
    if (formik && formik.setFieldValue) {
      formik.setFieldValue("payment_method_id", cardIdToUse?.payment_method_id);
    }

    if (setPayment_method_id && cardIdToUse?.payment_method_id) {
      setPayment_method_id(cardIdToUse.payment_method_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardIdToUse]);

  useEffect(() => {
    if (
      payment_method_id &&
      payment_method_id !== cardIdToUse?.payment_method_id
    ) {
      setCardIdToUse({ payment_method_id, id: undefined });
    }
    //Only update on load so that it does not end up in loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_method_id]);

  useEffect(() => {
    //if its default view or no payment method default was passed in, show cardknox default card
    if (defaultView || !payment_method_id) {
      const { id, payment_method_id: paymentMethodId } =
        cards?.filter((c) => c.default)?.[0] || {};

      !!id &&
        setCardIdToUse({
          id,
          payment_method_id: paymentMethodId,
        });
    }
  }, [payment_method_id, cards, defaultView]);

  return (
    <>
      <Typography variant={"h6"} sx={{ mb: 2 }}>
        Saved cards
      </Typography>

      <Stack justifyContent={"center"} sx={savedCards(!!cards.length)}>
        {!cards.length && (
          <Typography variant={"subtitle1"}>No cards on file.</Typography>
        )}

        {!!cards.length && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {cards.map((r, idx) => (
                <TableRow
                  key={idx}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {r.cardType}
                  </TableCell>

                  <TableCell>{r.maskedNumber}</TableCell>

                  <TableCell>
                    {defaultView ? (
                      <>
                        {r.default ? (
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            sx={{ m: -2, color: "#128e56" }}
                          >
                            <CheckCircleIcon />
                            <Typography variant="h6" color="#128e56">
                              DEFAULT
                            </Typography>
                          </Stack>
                        ) : (
                          <Button
                            onClick={() =>
                              !isDisabled &&
                              handleSetDefault(params.id, r.id, r.revision)
                            }
                            variant="text"
                            sx={{ m: -2 }}
                            disabled={isDisabled}
                          >
                            Set as default
                          </Button>
                        )}
                      </>
                    ) : (
                      <ChargeCard
                        isDisabled={isDisabled}
                        setUseCard={() => {
                          if (isDisabled) return;

                          setCardIdToUse({
                            id: r.id,
                            payment_method_id: r.payment_method_id,
                          });
                        }}
                        useCard={
                          !isDisabled &&
                          ((cardIdToUse?.id && cardIdToUse?.id === r.id) ||
                            (cardIdToUse?.payment_method_id &&
                              cardIdToUse?.payment_method_id ===
                                r.payment_method_id))
                        }
                      />
                    )}
                  </TableCell>

                  <TableCell>
                    <IconButton
                      disabled={isDisabled}
                      onClick={() => {
                        !!params?.id &&
                          !isDisabled &&
                          setModalData({
                            customer_id: +params.id,
                            cardId: r.id,
                            maskedNumber: r.maskedNumber,
                          });
                      }}
                      color="primary"
                      sx={rowToolBtnStyle}
                    >
                      <DeleteIcon sx={{ m: -2 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Stack>

      <Button
        variant={"outlined"}
        sx={{ width: "154px", mt: 4, mb: 2 }}
        onClick={() =>
          !isDisabled && dispatch(uiActions.setShowAddCardModal(true))
        }
        disabled={isDisabled}
      >
        <AddIcon sx={{ mr: 1 }} /> Add card
      </Button>

      <DeleteDialog
        showModal={!!modalData}
        name={modalData?.maskedNumber ?? ""}
        subject={"card"}
        onClose={() => setModalData(null)}
        deleteFunction={handleDeleteCard}
      />
    </>
  );
};
