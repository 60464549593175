import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { adminActions, GroupStudents, listsSelectors } from "../../../../state";
import { Search, StyledInputBase } from "../../styles/GridCardsSearch.styles";
import SearchIcon from "@mui/icons-material/Search";
import { toInt } from "../../../../lib";

export function useTabStudents() {
  const params = useParams();
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  const [data, setData] = useState<GroupStudents[] | null>(null);

  const neighborhoods = useSelector(listsSelectors.neighborhoodById);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Search style={{ marginRight: "auto" }}>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => {
              apiRef.current.setQuickFilterValues([e.target.value]);
            }}
          />
          <SearchIcon sx={{ color: "#B4B8C8", height: 32, paddingTop: 1.5 }} />
        </Search>

        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      if (!!params.id && toInt(params.id)) {
        const gs = await dispatch(adminActions.getChaburahStudents(params.id));

        if (gs) {
          const ngs = (gs as unknown as GroupStudents[]).map((g) => {
            return {
              ...g,
              __list: {
                neighborhoods,
              },
            };
          });

          setData(ngs);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.id]);

  return {
    data,
    apiRef,
    CustomToolbar,
  };
}
