import React, { FC } from "react";
import TabContainer from "../../components/TabContainer";
import { FormikProps } from "formik";
import { RoshKinyanFields } from "../schema";
import { Button, Grid, Typography } from "@mui/material";
import { ChaburahType } from "../types";
import Select from "../../../../components/Select";
import { gridSx, textField } from "./TabRoshKinyan.styles";
import { ROSH_KINYAN_COLUMNS } from "../../../../constants";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AddIcon } from "../../../../components";
import { useTabRoshKinyan } from "./useTabRoshKinyan";
import AddRoshKinyan from "./AddRoshKinyan";
import { dataGridFilterStyles } from "../../../../themes";

interface Props {
  formik: FormikProps<RoshKinyanFields>;
}

const TabRoshKinyan: FC<Props> = ({ formik }) => {
  const {
    values,
    handleChange,
    handleBlur,
    users,
    touched,
    errors,
    modal,
    handleAddNewRoshKinyan,
    handleModalClose,
    handleEditChaburahRoshKinyan,
    pageMode,
  } = useTabRoshKinyan(formik);

  return (
    <TabContainer sx={{ minHeight: "450px" }}>
      <Typography variant={"h6"} mb={2}>
        ראש קנין / מגיד שיעור
      </Typography>

      <Grid container direction="row" justifyContent="space-between">
        {values.chaburah_type_id === ChaburahType.Masmidim && (
          <Grid container>
            <Grid item xs={6}>
              <Select
                name="chaburah_teacher_id"
                label={"ראש קנין / מגיד שיעור"}
                sx={textField}
                options={users ?? []}
                value={values.chaburah_teacher_id}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.chaburah_teacher_id && !!errors.chaburah_teacher_id
                }
                helperText={
                  touched.chaburah_teacher_id && errors.chaburah_teacher_id
                }
              />
            </Grid>
            <Grid item xs={12}>
              {pageMode === "edit" && (
                <Button
                  variant={"contained"}
                  sx={{ minWidth: "180px", mt: 2 }}
                  onClick={handleEditChaburahRoshKinyan}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
        )}

        {values.chaburah_type_id === ChaburahType.CD && (
          <Grid item xs={10}>
            <DataGridPro
              sx={gridSx}
              getRowId={(row) => row.index}
              disableSelectionOnClick={true}
              hideFooterRowCount={true}
              hideFooter={true}
              rows={values.chaburah_teachers_neighborhoods ?? []}
              autoHeight={true}
              columns={ROSH_KINYAN_COLUMNS}
              componentsProps={{
                toolbar: {
                  printOptions: { disableToolbarButton: true },
                },
                panel: {
                  sx: {
                    ...dataGridFilterStyles,
                  },
                },
              }}
            />

            <Button
              variant={"outlined"}
              sx={{ mt: 2 }}
              onClick={handleAddNewRoshKinyan}
            >
              <AddIcon />
              rosh kinyan
            </Button>
          </Grid>
        )}
      </Grid>

      <AddRoshKinyan modal={modal} onClose={handleModalClose} />
    </TabContainer>
  );
};

export default TabRoshKinyan;
