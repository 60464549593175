import { Action, Slice, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
// Local
import { AppState } from "./states";
import { FormikProps } from "formik";
import { NewPaymentFields } from "../pages/admin/AddNewPaymentPage/schema";
import { components, operations } from "./swaggerSchema";
export type { AppState };

export interface AppStateInfo extends Slice {
  persist?: boolean;
  preload?: boolean;
  purge?: boolean;
}
/**
 * Type to return from async actions (redux-thunk).
 * `R` describes the return value of the thunk.
 * `E` describes the extra argument type given to the action thunk, e.g.
 * `(dispatch, getState, extraArgument) => {}`
 */
export type AppThunk<R = Promise<void>, E = unknown> = ThunkAction<
  R,
  AppState,
  E,
  Action<string>
>;
/**
 * Dispatch function for this application (AppState).
 * `E` describes the extra argument type given to the action thunk, e.g.
 * `(dispatch, getState, extraArgument) => {}`
 */
export type AppThunkDispatch<E = unknown> = ThunkDispatch<
  AppState,
  E,
  Action<string>
>;

export type FormErrors = Record<string, any>;

export interface ListState<T> {
  items: T[];
  // items: Record<string | number, T>;
  // order: any[];
  page: number;
  pagesTotal: number;
  total: number;
}

export interface User {
  id: number;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  login_attempts: number;
  last_login: string;
  last_failed_login?: string;
  person: Person;
  person_id: number;
  username: string;
  password: string;
  active: boolean;
  forgot_password_token: string | null;
  forgot_password_created_at: string | null;
  roles: Role[];
}

interface Person {
  first_name?: string;
  last_name?: string;
  id: number;
  first_name_yiddish: null | string;
  last_name_yiddish: null | string;
  address_id: number | null;
  email: null | string;
  notes: null;
  active: boolean;
  is_customer: boolean | null;
  currency: string | null;
  completed: boolean;
  address: Address | null;
  phone_numbers: PhoneNumber[];
}

// Student
export interface Student {
  birth_month_day: number;
  birth_month_id: number;
  created_by: number;
  customer: StudentCustomer;
  customer_id: number;
  default_payment_method: string;
  first_name: string;
  first_name_yiddish: string;
  grade: number;
  grade_yiddish: string;
  id: number;
  last_name: string;
  last_name_yiddish: string;
  payment_method_id: null;
  pin: number;
  school_building_id: number;
  status_id: number;
  student_months: StudentMonth[];
  track: string;
  type: string;
  max_month_id: number;
  __list: {
    neighborhoods: Record<any, Neighborhood>;
  };
}

export interface StudentMonths extends Student {
  cache_point: CachePoint;
  monthly_points: MonthlyPoints;
}

export interface CachePoint {
  balance: string;
}

export interface MonthlyPoints {
  test: number;
  attendance: number;
  review: number;
  total: number;
}

export interface StudentCustomer {
  first_name_yiddish: string;
  last_name_yiddish: string;
  id: number;
  address: Address;
  phone_numbers: PhoneNumber[];
}

export interface Address {
  id: number;
  address: string;
  address_2: string;
  city: string;
  state: string;
  neighborhood_id: number;
  zip: string;
  country_id: number;
}

export interface PhoneNumber {
  id: number;
  person_id: number;
  country_code_id: number;
  number: string;
  type: string;
}

export interface StudentMonth {
  [x: string]: any;
  id: number;
  student_id: number;
  month_id: number;
  chaburah_id: number;
  answers_looked_in: null;
  test_answers: null;
  attendance_count: number;
  review_count: number;
  check_in: null;
  gathering: boolean;
  price: string;
  chaburah: Chaburah;
  v_chaburah?: StudentDetailsChaburah;
  v_rosh_kinyan: StudentDetailsRoshKinyan;
}

export interface StudentMonthDetails extends Omit<StudentMonth, "chaburah"> {
  v_chaburah: StudentDetailsChaburah;
}

export interface Chaburah {
  id: number;
  name: string;
  chaburah_type_id: number;
  track_id: number;
  location_id: number | null;
  neighborhood_id: number | null;
  active: boolean;
}

export interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  pin: number;
  first_name_yiddish: string;
  last_name_yiddish: string;
  school_building_id: number;
  grade: number;
  birth_month_id: number;
  birth_month_day: number;
  status_id: number;
  default_payment_method: string;
  created_at: string;
  updated_at?: any;
  deleted_at?: any;
  created_by: number;
  payment_methods?: PaymentMethodItem[];
  billing_history?: BillingHistory[];
}

export interface CustomersTotals {
  active_count: string;
  usd_balance: string;
  gdp_balance: string;
}

export interface BillingHistory {
  id: number;
  transaction_id?: number;
  invoice_id?: number;
  applied_amount?: string;
  description: string;
  created_at: string;
  updated_at?: any;
  deleted_at?: any;
  invoice?: Invoice;
  person_id?: number;
  student_id?: any;
  identifier?: any;
  date?: string;
  transaction_type_id?: number;
  amount?: string;
  currency?: string;
  note?: string;
  month_id?: number;
  invoice_items?: Invoiceitem[];
  paid?: number;
}

interface Invoiceitem {
  id: number;
  invoice_id: number;
  student_id?: number;
  date: string;
  type: string;
  amount: string;
  description: string;
}

interface Invoice {
  id: number;
  month_id: number;
  person_id: number;
  currency: string;
}

export interface StudentBilling {
  id: number;
  default_payment_method?: string;
  payment_method_id?: number;
  payment_method?: { processor_payment_method_id: string };
  discounts: StudentBillingDiscount[];
}

export interface StudentBillingDiscount {
  id: number;
  student_id: number;
  discount: number;
  discount_type: string;
  reason: string;
  start_date: string;
  end_date?: string;
  is_prepay: boolean;
}
export interface StudentBillingDiscount {
  id: number;
  student_id: number;
  discount: number;
  discount_type: string;
  reason: string;
  start_date: string;
  end_date?: string;
  is_prepay: boolean;
}

export interface StudentDetails {
  grade_yiddish: string;
  id: number;
  customer_id: number;
  first_name: string;
  last_name: string;
  pin: number;
  completed: boolean;
  first_name_yiddish: string;
  last_name_yiddish: string;
  school_building_id: number;
  grade: number;
  birth_month_id: number;
  birth_month_day: number;
  status_id: number;
  default_payment_method: PaymentMethod;
  payment_method_id: null;
  created_by: number;
  customer: StudentDetailsCustomer;
  discounts: Discount[];
  student_months: StudentMonthDetails[];
  cache_point: StudentDetailsPoint;
  student_siyums: StudentSiyum[];
  student_notes: StudentNote[];
  school_building: SchoolBuilding;
  student_status_logs: StudentStatusLog[];
  future_changes?: Changes[];
  referral: Referral;
  bar_mitzva: string;
  meet_time: string | null;
  onboarded: boolean | null;
  onboarding: string | null;
  month_join: number | null;
  do_not_call: boolean;
  away: boolean;
}

export interface StudentDetailsCustomer {
  id: number;
  first_name: string;
  last_name: string;
  first_name_yiddish: string;
  last_name_yiddish: string;
  address: Address;
  phone_numbers: PhoneNumber[];
}

export interface Discount {
  id: number | null;
  discount: string | null;
  discount_type: "percentage" | "amount";
  reason: string | null;
}

export interface StudentDetailsChaburah {
  id: number;
  neighborhood_id: number;
  name: string;
  active: boolean;
  location: string;
  type: string;
  track: string;
  neighborhood: string;
}
export interface StudentDetailsRoshKinyan {
  id: number;
  person_id: number;
  chaburah_id: number;
  user_id: number;
  first_name_yiddish: string;
  last_name_yiddish: string;
  neighborhood_id: string;
  teachers_email: string;
}

export interface StudentDetailsPoint {
  student_id: number;
  earned: string;
  used: string;
  balance: string;
}

export interface StudentSiyum {
  id: number | null;
  student_id: number;
  semester_id: number | null;
  registration: boolean;
  transportation: boolean;
}

export interface StudentNote {
  id: number;
  note: string;
  note_type: string;
  item_id: number;
  created_by: number;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

export interface SchoolBuilding {
  id: number;
  school_id: number;
  name: string;
  school: School;
}

export interface School {
  id: number;
  name: string;
  neighborhood_id: number;
}
export interface Changes {
  chaburah_id: number;
  month_id: number;
  "chaburah.name": string;
  teacher_id: number | null;
}

export interface StudentStatusLog {
  id: number;
  student_id: number;
  status_id: number;
  created_at: string;
  created_by: number;
  // dictionaries (lists)
  __list: {
    users: Record<any, GenericUser>;
  };
}

export interface Referral {
  id: number;
  referring_student_id: number;
  referred_student_id: number;
}

export type List<ListType> = {
  count: number;
  rows: ListType[];
  uninitialized?: boolean;
};

export interface Status {
  id: number;
  name: "active" | "inactive" | "draft" | "pending";
}

export interface Neighborhood {
  id: number;
  name: string;
}

export interface ChaburahType {
  id: number;
  name: "live" | "remote";
}
export interface Chaburahs {
  id: number;
  name: string;
}

export interface Month {
  id: number;
  name: string;
  hebrew: string;
  year_id: number;
  day_count: number;
  semester_id: number;
}
export interface Type {
  id: number;
  type: string;
}

export interface GenericUser {
  id: number;
  name: string;
  name_yiddish: string;
  role_id?: any;
}
export interface NeighborhoodPrincipals {
  id: number;
  name: string;
  name_yiddish: string;
}
export interface Principals {
  id: number;
  user_id: number;
  name: string;
  name_yiddish: string;
}
// Student History
export interface StudentHistory {
  id: number;
  student_id: string;
  year_id: number;
  type: string;
  chaburah_months: {
    description: string;
    chaburah: {
      id: number;
      name: string;
      chaburah_type_id: number;
      track_id: number;
      location_id?: any;
      neighborhood_id?: any;
      active: boolean;
      chaburah_semester_subjects: {
        chaburah_id: number;
        mesechto?: any;
        subject: {
          id: number;
          subject_name: string;
          name_hebrew: string;
          is_default: number;
        };
      };
      __list?: {
        tracks: Record<any, Track>;
        chaburahTypes: Record<any, ChaburahType>;
        locations: Record<any, Location>;
        // for calculating grade in "Grade" column in table
        extraData: {
          grade: number;
          firstYearId: number;
        };
      };
    };
  };
}

export interface StudentHistoryDetails {
  check_in: any;
  gathering: any;
  month_id: number;
  price: string;
  monthly_note?: string | null | undefined;
  student_chaburah_months: {
    month_id: number;
    description: string;
    chaburah: {
      name: string;
    };
  };
  history_points: {
    id: number;
    student_id: number;
    month_id: number;
    points: number;
    type: string;
    reason?: any;
    prize_id?: any;
  };
}

export interface Semester {
  id: number;
  year_id: number;
  type: string;
}

export interface Subject {
  id: number;
  subject_name: string;
  name_hebrew: string;
  is_default: number;
}

export interface StudentHistoryChaburah extends Chaburah {
  chaburah_months: ChaburahMonth[];
  student_months: StudentHistoryMonth[];
}

export interface ChaburahMonth {
  month_id: number;
  description: string;
}

export interface StudentHistoryMonth {
  month_id: number;
  price: string;
  points_transactions: PointsTransaction[];
}

export interface PointsTransaction {
  id: number;
  student_id: number;
  month_id: number;
  points: number;
  type: string;
  reason: null | string;
  prize_id: null | number;
}

// Semester item
export interface SemesterItem {
  id: number;
  name: string;
  year_id: number;
}

// Track item
export interface Track {
  id: number;
  name: string;
}

// Location item
export interface Location {
  id: number | string;
  name: string;
}
export interface Countries {
  id: number;
  country_name: string;
  hr_name: string;
  code: string;
}

// Student reward
export interface StudentReward {
  id: number | null;
  student_id: number;
  month_id: number;
  points: number;
  type:
    | "attendance"
    | "attendance_bonus"
    | "review"
    | "review_bonus"
    | "test"
    | "test_bonus"
    | "bonus"
    | "redemption";
  reason: string | null;
  prize_id: number | null;
  created_at: string;
  // dictionaries (lists)
  __list?: {
    // for calculating date in "For" column in table
    months: Record<any, Month>;
  };
}

export interface StudentSemesterHistoryMonth {
  check_in: boolean | undefined | null;
  gathering: boolean | undefined | null;
  id: number;
  month: string;
  active: boolean;
  subjectName: string;
  monthDescription: string;
  price: string | number;
  attendance: string | null;
  review: string | null;
  test: string | null;
  bonus: string | null;
  total: number;
  monthly_note?: string | null | undefined;
}

export interface Building {
  id: number;
  name: string;
  school_id: number;
}

export interface CustomerSearchItem {
  id: number;
  name: string;
  last_name: string;
  last_name_yiddish: string;
}

export type StudentSearchItem = Omit<GenericUser, "name_yiddish">;

export interface Teacher {
  id: number;
  user_id: number;
  chaburah_id: number;
}
export interface TeacherList {
  id: number;
  name: string;
  name_yiddish: string;
}

export interface StudentGeneralEdit {
  customer_id: number;
  first_name: string;
  last_name: string;
  first_name_yiddish: string;
  last_name_yiddish: string;
  school_building_id?: number | null;
  grade: number;
  birth_month_id: number;
  birth_month_day: number;
  status_id: number;
  completed: boolean;
  meet_time: string | null | undefined;
  onboarded: boolean | null;
  onboarding: string | null;
  referring_student_id: number | null;
  student_siyums: {
    id?: number;
    student_id: number;
    semester_id: number | null;
    registration: boolean;
    transportation: boolean;
  }[];
  student_notes: {
    id?: number;
    note: string;
    note_type?: string;
    item_id?: number;
  }[];
}
export interface OnboardingStudentEdit {
  id: number;
  meet_time: string | null;
  onboarded: boolean | null;
  onboarding: string | null;
  first_name: string;
  last_name: string;
  first_name_yiddish: string;
  last_name_yiddish: string;
}

export interface PaymentMethodItem {
  id: string;
  maskedNumber: string;
  cardType: string;
  exp: string;
  default?: boolean;
  payment_method_id?: number;
  revision?: number;
}

export interface StudentEnrollmentEdit {
  teacher_id: number;
  chaburah_id: number;
  month_id: number;
  override?: boolean;
  draft?: boolean;
  set_call_date?: boolean;
  is_reactivate?: boolean;
}

interface DiscountEdit extends Omit<Discount, "id" | "discount"> {
  discount: number;
}

export interface StudentBillingCreditEdit {
  default_payment_method: string;
  payment_method_id: number;
  discounts: DiscountEdit[];
}

// Adding new student and his enrollment
export interface NewStudentAndEnrollment {
  customer_id: number;
  teacher_id: number | null;
  first_name: string | null;
  last_name: string | null;
  first_name_yiddish: string;
  last_name_yiddish: string;
  school_building_id: number | null;
  grade: number | null;
  birth_month_id: number | null;
  birth_month_day: number | null;
  notes: string | null;
  chaburah_id: number;
  month_id: number | null;
  referring_student_id: number | null;
  draft?: boolean;
}

export type PaymentMethod = "cash" | "credit";

export type DepositType = "regular" | "prepayment" | "other";

export type DiscountType = "percentage" | "amount";

export interface NewStudentBilling {
  default_payment_method: PaymentMethod;
  payment_method_id: number | null;
  amount: number | null;
  date: string | null;
  note: string | null;
  discount: number | null;
  discount_type: Discount["discount_type"];
  reason: string;
  identifier: string | null;
  start_date: string | null;
  end_date: string | null;
}

export interface Group {
  id: number;
  name: string;
  chaburah_type_id: number;
  track_id: number;
  location_id: number | null;
  neighborhood_id: number | null;
  active: boolean;
  students_count: number;
  // dictionaries (lists)
  __list?: {
    neighborhoods: Record<any, Neighborhood>;
    tracks: Record<any, Track>;
    locations: Record<any, Location>;
  };
  prices: [];
}

export interface GroupStudents {
  status_id: number;
  completed: boolean;
  id: number;
  customer_id: number;
  first_name: string;
  last_name: string;
  pin: string;
  first_name_yiddish: string;
  last_name_yiddish: string;
  school_building_id: number;
  grade: number | null;
  birth_month_id: number;
  birth_month_day: number;
  default_payment_method: string;
  payment_method_id: null;
  created_by: number;
  customer: GroupCustomer;
  student_months: StudentMonth[];
  points_transactions: any[];
  cache_point: CachePoint | null;
  grade_yiddish?: string;
  // dictionaries (lists)
  __list?: {
    neighborhoods: Record<any, Neighborhood>;
  };
  prices: [];
}

export interface GroupCustomer {
  id: number;
  first_name_yiddish: string;
  last_name_yiddish: string;
  address: GroupCustomerAddress;
  phone_numbers: PhoneNumber[];
}

export interface GroupCustomerAddress {
  id: number;
  address: string;
  address_2: string;
  city: string;
  state: string;
  neighborhood_id: number | null;
  zip: string;
  country_id: number;
}

export interface TeacherItem {
  id: number;
  neighborhood_id: number;
  chaburah_teacher: ChaburahTeacher;
  default: boolean;
}

export interface ChaburahTeacher {
  id: number;
  user_id: number;
  chaburah_id: number | null;
}

export interface GroupCurriculum {
  chaburah_id: number;
  semester_id: number;
  subject_id: number | null;
  mesechta_id: number | null;
  __list?: {
    semesters: Record<any, SemesterItem>;
    subjects: Record<any, SubjectItem>;
  };
}

export interface SubjectItem {
  id: number;
  type: "mesechto" | "subject";
  subject_name: string;
  name_hebrew: string;
}

export interface GroupItem {
  // Details tab
  id: number;
  name: string;
  chaburah_type_id: number;
  track_id: number;
  location_id: number | null;
  neighborhood_id: number | null;
  active: boolean;
  prices: GroupPrice[];
  chaburah_semester_subjects: ChaburahSemesterSubject[];
  // those fields were not present in a structure on Swagger
  // Rosh Kinyan tab
  chaburah_teacher_id: number | null;
  chaburah_teachers_neighborhoods:
    | {
        chaburah_teachers_id?: number;
        user_id?: number;
        neighborhood_id?: number;
        default?: boolean;
      }[]
    | null;
  // Curriculum tab
  curriculum:
    | {
        startYear?: unknown;
        startSemester?: unknown;
        semesterYear: string;
        id: number | null;
        semester_id: number;
        type: "mesechto" | "subject";
      }[]
    | null;
}

export interface ChaburahSemesterSubject {
  semester_id: number;
}

export type Currency = "USD" | "GBP";

export interface GroupPrice {
  id: number;
  price: string;
  currency: Currency;
}

export interface MonthBalance {
  id: number;
  month_id: number;
  person_id: number;
  currency: Currency | null;
  created_at: string;
  updated_at: string | null;
  deleted_at: null;
  invoice_items: InvoiceItem[];
  amount: string;
  paid: number;
  description: string | null;
  transaction_type_id: number;
  unpaid: number;
}

export type InvoiceItemType = "fee" | "discount" | "other";

export interface InvoiceItem {
  id: number;
  invoice_id: number;
  student_id: number | null;
  date: string;
  type: InvoiceItemType;
  amount: string;
  description: string;
}

export interface BalanceRow {
  id: number;
  index: number;
  month: string;
  total: string;
  paid: string;
  balance: string;
  amount: string;
  isSelected: boolean;
  currency: string;
  //
  formik: FormikProps<NewPaymentFields> | null;
}

export interface EditedCustomerPayment
  extends Omit<
    NewCustomerPayment,
    "customer_id" | "default_payment_method" | "payment_method_id"
  > {}

export interface NewCustomerPayment {
  customer_id: number;
  amount: number;
  payment_type: DepositType;
  semester_id: number | null;
  date: string | null;
  default_payment_method: PaymentMethod | null;
  payment_method_id: number | null;
  identifier: string | null;
  note: string | null;
  payment_details: PaymentDetail[] | null;
  prepay_details: PrepayDetails[] | null;
}

export interface PaymentDetail {
  invoice_id: number;
  month?: string;
  amount: string | null;
}
export interface AddCredit {
  amount: string | number;
  note: string | null;
  payment_details: PaymentDetail[] | undefined;
}
export interface Note {
  note: string | null;
}

export interface PrepayDetails {
  student_id: number;
}

export interface NewPaymentCard extends PaymentMethodItem {
  personId: string;
  _callBackFunc?: (
    type: "default" | "delete",
    personId: string,
    cardId: string,
  ) => void;
}

export interface SaveCard {
  form: {
    card_token: string;
    cvv_token: string;
    expiration: string;
    default: boolean;
  };
}

export interface BillingDetails {
  id: number;
  last_name_yiddish: string;
  student_billing: BillingDetailsStudent[];
}

export interface PaymentMethodObject {
  processor_payment_method_id: string | null;
}

export interface BillingDetailsStudent {
  id: number;
  default_payment_method: PaymentMethod;
  payment_method_id: number | null;
  payment_method: PaymentMethodObject | null;
  discounts: {
    id: number;
    student_id: number;
    discount: number;
    discount_type: DiscountType;
    reason: string;
    start_date: string;
    end_month_id?: number | null;
    month_end?: number;
    end_date: string | null;
    is_prepay: boolean;
  }[];
}

export interface UpdatedPaymentData {
  default_payment_method: PaymentMethod | null;
  payment_method_id: number | null;
  discounts:
    | {
        id: number | null;
        discount: number;
        discount_type: DiscountType;
        end_month_id?: number | null;
        reason?: string | null;
      }[]
    | null;
}

export interface BillingDetails {
  id: number;
  last_name_yiddish: string;
  students: BillingDetailsStudent[];
}

export interface BillingDetailsStudent {
  id: number;
  default_payment_method: PaymentMethod;
  payment_method_id: number | null;
  payment_method: PaymentMethodObject | null;
  discounts: {
    id: number;
    student_id: number;
    discount: number;
    discount_type: DiscountType;
    reason: string;
    start_date: string;
    end_month_id?: number | null;
    month_end?: number;
    end_date: string | null;
    is_prepay: boolean;
  }[];
}

export interface PrepayOptions {
  semesterId: number;
  discount: {
    prepay_discount_cash: number;
    prepay_discount_credit: number;
  };
  prevPaid: string | null;
  prepay: Prepay[] | null;
}

export interface Prepay {
  price: string;
  total: string;
  student: {
    id?: number;
    first_name: string;
    last_name: string;
  };
  //
  discount?: number;
  balance?: number;
  isSelected?: boolean;
  payments?: PrepayPayment[];
}

export interface PrepayPayment {
  id: number;
  name: string;
  before: number;
  discount: number;
  after: number;
}

export interface NewUser {
  person_id: number | null;
  first_name_yiddish: string;
  last_name_yiddish: string;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  address_id: number | null;
  address: string | null;
  address_2: string | null;
  city: string | null;
  state: string | null;
  neighborhood_id: number | null;
  zip: string | null;
  active: boolean | null;
  country_id: number | null;
  home_phone: string | null;
  home_phone_id: number | null;
  home_country_code_id: number | null;
  mobile_phone: string | null;
  mobile_phone_id: number | null;
  mobile_country_code_id: number | null;
  username: string;
  password: string;
  role_ids: number[];
}

export interface Role {
  id: number;
  role: string;
  internal_name: string;
  user_roles: UserRoles;
}

export interface UserRoles {
  role_id: number;
}

export interface UserItem {
  id: number;
  isActive: boolean;
  name: string;
  nameYiddish: string;
  homePhone: string;
  cellPhone: string;
  address: string;
  roles: string;
  // permissions: string;
}

export interface GroupValues {
  id: number;
  chaburah_id: number;
  month_id: number;
  semester_id: number | null;
  description: string | null;
  max_review_checks: number | null;
  review_bonus: number | null;
  review_threshold: number | null;
  review_multiplier: number | null;
  max_attendance_checks: number | null;
  attendance_bonus: number | null;
  attendance_threshold: number | null;
  attendance_multiplier: number | null;
  start_date: string | null;
  end_date: string | null;
  charge_date: string | null;
  tests: Test[] | null;
  max_test_points: string | null;
}

export interface Test {
  id: number;
  chaburah_month_id: number;
  test_date: string | null;
  count_questions: number | null;
  max_bonus: number | null;
  threshold: number | null;
  question_value: number | null;
  extra_credit_value: number | null;
  looked_in_value: number | null;
  max_looked_in: number | null;
  revised_question_value: number | null;
  max_revised: number;
  name: string | null;
  test_questions: TestQuestion[] | null;
}

export interface TestQuestion {
  id: number;
  test_id: number;
  question_number: number;
  answer: number;
  extra_credit: boolean;
}

export interface Question extends Omit<TestQuestion, "answer"> {
  index: number;
  answer: string;
  question_number: number;
  question: string;
}

export interface UpdateGroupValues {
  id: number;
  description: string;
  max_review_checks: number | null;
  review_bonus: number | null;
  max_attendance_checks: number | null;
  attendance_bonus: number | null;
  start_date?: string | null;
  end_date?: string | null;
  charge_date: string | Date | null;
  tests: UpdatedTest[] | null;
}

export interface UpdatedTest {
  id: number;
  chaburah_month_id: number;
  test_date: string | null;
  count_questions: number | null;
  max_bonus: number | null;
  threshold: number | null;
  question_value: number | null;
  extra_credit_value: number | null;
  looked_in_value: number | null;
  max_looked_in: number | null;
  revised_question_value: number | null;
  max_revised: number | null;
  name: string | null;
  test_questions: UpdateTestQuestion[] | null;
}

export interface UpdateTestQuestion {
  test_id: number;
  question_number: number;
  answer: number;
  extra_credit: boolean;
}

export interface NeighborhoodWithPrincipals {
  id: number;
  neighborhood: string;
  principals?: Principals;
  users:
    | {
        id: number;
        person_id: number;
        username: string;
        password: string;
        active: boolean;
        login_attempts: string | null;
        last_login: string | null;
        last_failed_login: string | null;
        forgot_password_token: string | null;
        forgot_password_created_at: string | null;
        person: Principals;
      }[]
    | null;
}

export interface Principals {
  id: number;
  first_name: string | null;
  last_name: string | null;
  first_name_yiddish: string;
  last_name_yiddish: string;
  address_id: number | null;
  email: string | null;
  notes: string | null;
  active: boolean | null;
  is_customer: boolean | null;
  currency: string | null;
  completed: boolean | null;
}

export interface NewNeighborhoodWithPrincipals {
  name: string;
  user_id: number;
}

export interface ChaburahTeachersNeighborhoods {
  id?: number | undefined;
  index?: number | null;
  chaburah_teacher_id: number;
  user_id: number;
  user_name: string;
  neighborhood_id: number;
  neighborhood: string;
  default: boolean;
}

export interface NewRoshKinyan {
  id: number;
  user_id: number;
  chaburah_id: number | null;
  user: {
    id: number;
    person: {
      id: number;
      first_name: string;
      last_name: string;
      first_name_yiddish: string;
      last_name_yiddish: string;
      address_id: number | null;
      email: string;
      notes: string | null;
      active: boolean;
      is_customer: boolean;
      currency: string;
      completed: boolean;
    };
  };
}

export interface CurriculumSemester {
  track_id: number;
  semester_number: number;
  subject_id: number | null;
  mesechta_id: number | null;
  start_year?: number;
  start_semester?: string;
  subject: {
    id: number;
    subject_name: string;
    name_hebrew: string;
    is_default: number;
  } | null;
  mesechto: {
    id: number;
    subject_name: string;
    name_hebrew: string;
    is_default: number;
  } | null;
}

export interface NewChaburah {
  name: string;
  chaburah_type_id: number;
  neighborhood_id: number | null;
  location_id?: number | null;
  track_id: number;
  price_usd: number | null;
  price_gbp: number | null;
  semester_id: number;
  active: boolean;
  chaburah_teacher_id: number | null;
  chaburah_teachers_neighborhoods:
    | {
        neighborhood_id: number;
        chaburah_teachers_id: number;
        default: boolean;
      }[]
    | null;
  curriculum:
    | {
        id: number | null;
        semester_id: number;
        type: "mesechto" | "subject";
      }[]
    | null;
}

export const Season = {
  winter: "ווינטער",
  summer: "זיממער",
};

export interface IndividualStudentsCheck {
  month_id: number;
  review_count: number;
  attendance_count: number;
  gathering: "attended" | "reached" | "called" | "" | "unset" | null;
  check_in: "reached" | "called" | "" | "unset" | null;
  monthly_note: string | null | undefined;
  call_date: string | null | undefined;
}

export interface IndividualStudentsRewards {
  id: number | null;
  points: number;
  reason: string;
}

export interface MonthlyValues {
  max_review_checks: number | null;
  review_bonus: number | null;
  max_attendance_checks: number | null;
  attendance_bonus: number | null;
  charge_date: string | null;
  test_date: string | null;
  start_date: string | null;
}

export interface EditedChaburah {
  name: string;
  location_id: number | null;
  active: boolean;
  price_usd: number | null;
  price_gbp: number | null;
}

export interface EditedRoshKinyan {
  user_id: number | null;
  neighborhood_id: number | null;
  chaburah_id: number | null;
  default: boolean;
}

export interface EditedCurriculum {
  start_semester_id: number;
  curriculum: {
    semester_id: number;
    id: number;
    type: "mesechto" | "subject";
  }[];
}

export interface Refund {
  id: number;
  person_id: number;
  student_id: number | null;
  identifier: string | null;
  date: string;
  transaction_type_id: number | null;
  amount: string;
  currency: string;
  description: null | string;
  note: null | string;
  transaction_response: {
    id: number;
    transaction_id: number;
    response: {
      xExp: string;
      xMID: string;
      xTID: string;
      xDate: string;
      xName: string;
      xBatch: string;
      xError: string;
      xToken: string;
      xRefNum: string;
      xResult: string;
      xStatus: string;
      xAuthCode: string;
      xCardType: string;
      xCurrency: string;
      xAvsResult: string;
      xCvvResult: string;
      xErrorCode: string;
      xAuthAmount: string;
      xEntryMethod: string;
      xAvsResultCode: string;
      xCvvResultCode: string;
      xMaskedCardNumber: string;
    } | null;
  } | null;
}

export interface NewRefund {
  transaction_id: number;
  amount: number;
  note: string;
}

export interface EditedTests {
  month_id: number;
  test_answers: string[];
  answers_looked_in: number;
}

export interface CustomersPayment {
  id: number;
  person_id: number;
  identifier: string | null;
  date: string;
  transaction_type_id: number;
  semester_id: number | null;
  amount: string;
  currency: string | null;
  description: string | null;
  note: string | null;
  transaction_invoices:
    | {
        id: number;
        transaction_id: number;
        invoice_id: number;
        applied_amount: string;
        description: string;
      }[]
    | null;
}

export type PageMode = "" | "edit" | "insert";

export type ButtonControl = "insert" | "edit" | "remove";

export type Product = {
  id?: number;
  name: string;
  name_yiddish: string;
  sku_upc?: string;
  prize_number?: number;
  unassigned_count?: number;
  vendor_id?: number;
  image_url?: string;
  cost?: string;
  currency?: "USD" | "GBP";
  point_value?: number;
  active?: boolean;
  image?: any;
  image_link?: string;
  is_amount?: boolean;
};

export type ArrayElement<ArrayType> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type RedemptionItem = ArrayElement<
  operations["getRedemptionItems"]["responses"]["200"]["content"]["application/json"]
>;
export type Redemption = ArrayElement<
  operations["getRedemptions"]["responses"]["200"]["content"]["application/json"]["rows"]
>;
export type RedemptionProduct = ArrayElement<Redemption["redemption_products"]>;
export type Vendor = ArrayElement<
  operations["getVendorsList"]["responses"]["200"]["content"]["application/json"]
>;
export type monthDates =
  components["responses"]["MonthlyDatesRes"]["content"]["application/json"];

export type Calander =
  components["responses"]["CalendarRes"]["content"]["application/json"];

// If backend adds name_no_parsha we can remove it from here
export type CalendarDay = ArrayElement<Calander["data"]> & {
  name_no_parsha: string;
};
