import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const checkBox: SxProps<Theme> = {
  ml: "-5px",

  "& .MuiCheckbox-root": {
    p: 0.5,

    "& > .MuiSvgIcon-root": {
      height: "16px",
      width: "16px",
    },
  },

  "& > .MuiTypography-root": {
    color: "#000000",
    fontSize: "13px",
    letterSpacing: 0,
    lineHeight: "14px",
    userSelect: "none",
  },
};
