import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import qs from "query-string";
import { KEY_PREFIX } from "redux-persist";
import { REACT_APP_API_URL, REACT_APP_PERSIST_KEY } from "../../config";
import { auth, AuthState } from "./state";
import { getQueryParam, Navigation } from "../../lib";
import { AppThunk } from "../types";
import { uiActions } from "../ui/actions";
const { actions } = auth;

interface AuthResponse {
  user?: { id: number; roles: string[]; username: string };
  token?: string;
  id?: number;
}

export const authActions = {
  ...actions,
  /** @param {any} [authResponseData] Response data to load. Optional. */
  load(authResponseData: AuthResponse): AppThunk {
    let authStateFromResponse: AuthState;
    if (authResponseData) {
      const { user, token } = authResponseData;
      if (user) {
        authStateFromResponse = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.username,
        };
      }
    }
    return async (dispatch, getState) => {
      let authState: AuthState;
      if (authStateFromResponse) {
        authState = authStateFromResponse;
        dispatch(actions.setAuthState(authState));
      } else {
        authState = getState().auth;
      }
      createApiClient(authState);
    };
  },
  login(values: {
    username: string;
    password: string;
  }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        const {
          data: { user, token },
        } = await axios.post(`/auth/login`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        dispatch(uiActions.setLoading(false));

        const authState: AuthState = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.username,
          user,
        };
        createApiClient(authState);
        dispatch(actions.setAuthState(authState));
        Navigation.replace(getQueryParam("after") || "/admin/customer");
        return true;
      } catch (e) {
        dispatch(
          uiActions.showError("Invalid username or password. Please try again"),
        );
        dispatch(uiActions.setLoading(false));
        return false;
      }
    };
  },
  logout(): AppThunk {
    return async (dispatch) => {
      logout(dispatch);
    };
  },
  recoverPassword(values: { email: string }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      await logout(dispatch);
      await axios.post(`/auth/password/forgot`, values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      dispatch(uiActions.showSuccess("Password reset email sent"));
    };
  },
  resetPassword(values: { newPassword: string; token: string }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      try {
        await axios.put(`/auth/password/reset`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        await logout(dispatch);
      } catch (e) {
        dispatch(uiActions.showError("Oops Something went wrong"));
      }
    };
  },
  confirmAccount(values: {
    email: string;
    newPassword: string;
    token: string;
  }): AppThunk {
    return async (_dispatch) => {
      await axios.put(`/auth/confirm-account`, values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      // dispatch(actions.setAuthState(undefined));
    };
  },
};

/** Client for making authenticated API calls. */
export const authClient = {
  delete(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.delete(url, config));
  },
  download(_url: string, _config?: AxiosRequestConfig) {
    return Promise.reject("TODO: Implement apiDownload.");
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.get<T>(url, config));
  },
  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.post<T>(url, data, config));
  },
  put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.put<T>(url, data, config));
  },
};

/** Connection used to make authorized, authenticated API calls. */
export let apiClient: AxiosInstance;

function createApiClient(state: AuthState) {
  const config: AxiosRequestConfig = {
    baseURL: REACT_APP_API_URL,
    headers: {},
  };
  if (!config.headers) {
    throw new Error("Invalid Api Config");
  }
  if (state && state.token) {
    config.headers.Authorization = `Bearer ${state.token}`;
  }
  config.headers["Content-Type"] = "application/json";
  apiClient = axios.create(config);
}

/**
 * @param promise
 */
async function handleAuthResponse<T = any>(
  promise: Promise<AxiosResponse<T>>,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  promise = promise.catch((err) => {
    return err.response;
  });
  const res = await promise;
  if (error) {
    console.error({ error, res });
  }

  if (res?.status) {
    if (res.status === 401) {
      redirectToLogin();
    }
  }
  return res;
}

export function redirectToLogin() {
  window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
  window.location.href =
    "/auth/login?after=" +
    encodeURIComponent(window.location.pathname + window.location.search);
}

function logout(dispatch) {
  // NOTE: We could do  window.localStorage.clear(); but other JS might be
  // using localStorage, so just remove the key that our Redux app saves.
  window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
  dispatch(actions.setAuthState(undefined));
}

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
function serializeParams(params: Record<string, any>) {
  if (params instanceof URLSearchParams) {
    return params.toString();
  }
  const formattedParams = {};
  const keys = Object.keys(params);
  const { length } = keys;
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    let value = params[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (Object.prototype.toString.call(value) === "[object Date]") {
      // Format Dates...
      value = value.toISOString();
    } else if (value !== null && typeof value === "object") {
      // Format objects and arrays...
      value = JSON.stringify(value);
    }
    formattedParams[key] = value;
  }
  // URLSearchParams does not handle arrays...
  // return new URLSearchParams(formattedParams).toString();
  return qs.stringify(formattedParams);
}
axios.defaults.paramsSerializer = serializeParams;

// #region Types

/** Return value for API call wrappers. */
export type ApiCall<T = any> = AppThunk<Promise<AxiosResponse<T>>>;

// #endregion
