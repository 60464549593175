import React, { FC, useState } from "react";
import {
  getGridNumericOperators,
  GridAlignment,
  GridColDef,
} from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import {
  gematriya,
  getCurrencySymbol,
  getYiddishDateString,
  history,
  numberWithCommas,
  numsWithCommasWithNeg,
  openInNewTab,
  toInt,
  transactionTypeLookup,
  useFormikContext,
} from "../lib";
import { TextField as FormikTextField } from "formik-mui";
import {
  adminActions,
  BalanceRow,
  ButtonControl,
  customerSelectors,
  listsSelectors,
  Status,
  StudentReward,
  StudentSemesterHistoryMonth,
  uiActions,
} from "../state";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleIcon,
  DeleteIcon,
  DoubleStarIcon,
  EditIcon,
  LaunchIcon,
  DotIcon,
  CheckIcon,
  CloseIcon,
  ErrorIcon,
} from "../components";

import { format, isBefore, isEqual, isValid } from "date-fns";
import AdminPages from "../pages/admin";
import {
  ChodeshYear,
  ChodeshYearFormatted,
} from "../components/columns/ChodeshYear";
import { TransactionType } from "../components/columns/TransactionType";
import EditAmountField from "../pages/admin/AddNewPaymentPage/EditAmountField";
import {
  rowToolBtnStyle,
  sxCheckButton,
  sxCheckIcon,
  sxCheckText,
  sxIconGS,
  sxIconPoints,
  sxName,
  sxPoints,
  sxPointsGS,
  sxText,
} from "./styles";
import { InvoiceModal } from "../pages/admin/components/Modals/InvoiceModal";
import { addCurrencySign } from "../pages/admin/AddNewPaymentPage/utils";
import EditAnswerField from "../pages/admin/GroupSettingsPage/EditAnswerField";
import Select from "../components/Select";
import { Option } from "../components/Select/Select";
import { Field } from "formik";
import { Fields } from "../pages/admin/StudentPage/EnterTestModal/schema";
import { useNavigate } from "react-router-dom";
import { DeletePaymentButton } from "../components/buttons";
import { REACT_APP_BASE_URL } from "../config";
import StudentGathering from "../components/icons/StudentGathering";
import StudentCheckIn from "../components/icons/StudentCheckIn";

export const statusValues = {
  1: "active",
  3: "inactive",
  4: "draft",
  5: "pending",
};

const STATUS = ["Active", "Inactive", "Draft", "Pending"];
const CUSTOMER_STATUS = ["Active", "Inactive", "Draft"];
const GROUP_STATUS = ["Active", "Inactive"];
const PAYMENT_METHOD = ["cash", "credit"];
const GRADE_YIDDISH = ["ג׳", "ד׳", "ה׳", "ו׳", "ז׳", "ח׳"];
const TRANSACTIONS = [
  "payment_cash",
  "payment_credit",
  "reversed_payment",
  "invoice",
  "refund",
  "from_prepayment",
  "applied_payment",
  "failed_payment",
  "failed_refund",
  "cash_prepayment",
  "credit_prepayment",
  "credit",
];

export const statuses: Record<
  Status["name"],
  { toolTip: string; color: string }
> = {
  inactive: {
    toolTip: "Inactive",
    color: "#BB2018",
  },
  active: {
    toolTip: "Active",
    color: "#128E56",
  },
  draft: {
    toolTip: "Draft",
    color: "#000000",
  },
  pending: {
    toolTip: "Pending",
    color: "#4989D5",
  },
};

const MappedTypes: Record<StudentReward["type"], string> = {
  attendance: "Attendance",
  attendance_bonus: "Attendance bonus",
  bonus: "Bonus",
  review: "Review",
  test: "Test",
  redemption: "Redemption",
  test_bonus: "Test bonus",
  review_bonus: "Review bonus",
};

export function getLaunchLink(
  type: "customers" | "students" | "student_months",
  id,
  monthId: any = undefined,
) {
  let url = "";
  switch (type) {
    case "students":
      url = monthId
        ? `${REACT_APP_BASE_URL}/admin/student/${id}?month_id=${monthId}`
        : `${REACT_APP_BASE_URL}/admin/student/${id}`;
      break;
    case "student_months":
      url = `${REACT_APP_BASE_URL}/admin/student/${id}?month_id=${monthId}`;
      break;
    case "customers":
      url = `${REACT_APP_BASE_URL}/admin/customer/${id}`;
      break;
    default:
      url = `${REACT_APP_BASE_URL}/admin`;
  }
  const handleClick = (e) => {
    e.stopPropagation();
  };
  return (
    <Button
      key={id}
      onClick={handleClick}
      href={url}
      target="_blank"
      rel="noopener"
    >
      <LaunchIcon />
    </Button>
  );
}

function buildYearAndMonthString(type: string, yearId: number) {
  let semesterType;

  switch (type) {
    case "winter":
      semesterType = "ווינטער";
      break;
    case "summer":
      semesterType = "זיממער";
      break;
    default:
      semesterType = "";
  }

  let year = "";

  const y = +yearId.toString(10).slice(2);
  year = gematriya(y.toString());

  return `${semesterType} ${year}`;
}

///////////////////////////Functions
function getFullName(params) {
  return (
    <div>
      <div>
        {params.row.first_name_yiddish} {params.row.last_name_yiddish}
      </div>

      <div>
        {params.row.first_name} {params.row.last_name}
      </div>
    </div>
  );
}
function getFullNameYiddish(params) {
  return `${params.row.first_name_yiddish} ${params.row.last_name_yiddish}`;
}
function getFullNameEnglish(params) {
  return `${params.row.first_name} ${params.row.last_name}`;
}
function getFullNameValue(params) {
  return `${params.row.last_name_yiddish || ""} ${
    params.row.first_name_yiddish || ""
  } ${params.row.first_name || ""} ${params.row.last_name || ""}`;
}
function FormatAddress(address, key: string | undefined = undefined) {
  const optionalProps = { key };

  return (
    <Box
      {...optionalProps}
      sx={{
        whiteSpace: "pre-wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      {address?.address || ""} {address?.address_2 || ""} {address?.city || ""}{" "}
      {address?.state || ""}
      {address?.zip || ""}
    </Box>
  );
}
function getFullAddress(params) {
  const address = params.row?.address;
  return FormatAddress(address, `student-${params.row.id}`);
}
function getFullAddressValue(params) {
  const address = params.row?.address;
  return `${address?.address || ""} ${address?.address_2 || ""} ${
    address?.city || ""
  } ${address?.state || ""} ${address?.zip || ""} ${address?.country || ""} `;
}
function getFullCustomerAddress(params) {
  const address = params.row?.customer?.address;
  return (
    <Box
      key={`student-${params.row.id}`}
      sx={{
        whiteSpace: "pre-wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      {address?.address || ""} {address?.address_2 || ""} {address?.city || ""}{" "}
      {address?.state || ""}
      {address?.zip || ""}
    </Box>
  );
}
function getFullCustomerAddressValue(params) {
  const address = params.row?.customer?.address;
  return `${address?.address || ""} ${address?.address_2 || ""} ${
    address?.city || ""
  } ${address?.state || ""} ${address?.zip || ""} ${address?.country || ""} `;
}
function getStudentAddress(params) {
  const { address, address_2, city, state, zip, id } = params.row;
  return (
    <Box
      key={`student-${id}`}
      sx={{
        whiteSpace: "pre-wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      {address || ""} {address_2 || ""} {city || ""} {state || ""}
      {zip || ""}
    </Box>
  );
}
function getStudentAddressValue(params) {
  const { address, address_2, city, state, zip, country_name } = params.row;
  return `${address || ""} ${address_2 || ""} ${city || ""} ${state || ""} ${
    zip || ""
  } ${country_name || ""} `;
}
function getStudents(params) {
  return (
    <Box
      key={`student-${params.row.id}`}
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        whiteSpace: "nowrap",
        maxWidth: "100%",
        maxHeight: "inherit",
        overflowY: "auto",
      }}
    >
      {params.row?.v_students_alls?.map((student: any) => {
        return (
          <Chip
            sx={{
              borderRadius: 0,
              margin: "2.5px 5px",
              backgroundColor: "secondary.main",
              color: "primary.main",
              minHeight: "25px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `${AdminPages.studentPage.path.replace(
                  ":id",
                  student.id.toString(10),
                )}`,
              );
            }}
            key={Math.random().toString(8)}
            label={student.first_name_yiddish + " " + student.last_name_yiddish}
          />
        );
      })}
    </Box>
  );
}

function getStudentsValue(params) {
  return params.row?.students
    ?.map((student: any) => {
      return `${student.first_name_yiddish} ${student.last_name_yiddish}`;
    })
    .join(", ");
}

function getCharges(params) {
  const { transaction_type_id, amount, currency, applied_amount } = params.row;
  if (applied_amount) return "";
  if (transactionTypeLookup[transaction_type_id] === "invoice") {
    return (
      <Typography variant="body1" color={"#BB2018"}>
        {getCurrencySymbol(currency)}
        {numberWithCommas(amount)}
      </Typography>
    );
  } else if (transactionTypeLookup[transaction_type_id] === "failed_refund") {
    return (
      <Typography
        variant="body1"
        color={"#dd8f8b"}
        sx={{
          opacity: 0.5,
          textDecoration: "line-through",
        }}
      >
        {getCurrencySymbol(currency)}
        {numberWithCommas(amount)}
      </Typography>
    );
  } else return "";
}
//TODO: refactor to remove duplicate code
function getChargesFormatted(v) {
  const { transaction_type_id, amount, currency, applied_amount } =
    v.api.getRow(v.id);
  if (applied_amount) return "";
  if (transactionTypeLookup[transaction_type_id] === "invoice") {
    return `${getCurrencySymbol(currency)} ${numberWithCommas(amount)}`;
  } else if (transactionTypeLookup[transaction_type_id] === "failed_refund") {
    return `Failed transaction | ${getCurrencySymbol(
      currency,
    )} ${numberWithCommas(amount)}`;
  } else return "";
}
function getChargesValue(v) {
  const { transaction_type_id, amount, applied_amount } = v.api.getRow(v.id);
  if (applied_amount) return "";
  if (
    transactionTypeLookup[transaction_type_id] === "invoice" ||
    transactionTypeLookup[transaction_type_id] === "failed_refund"
  ) {
    return Math.abs(amount);
  } else return "";
}
function getDeductions(params) {
  const { transaction_type_id, amount, currency, applied_amount } = params.row;
  if (
    applied_amount ||
    transactionTypeLookup[transaction_type_id] === "applied_payment" ||
    transactionTypeLookup[transaction_type_id] === "from_prepayment" ||
    transactionTypeLookup[transaction_type_id] === "credit" ||
    transactionTypeLookup[transaction_type_id] === "refund"
  ) {
    return (
      <Typography variant="body1">
        {getCurrencySymbol(currency)}
        {numberWithCommas(applied_amount ? applied_amount : amount)}
      </Typography>
    );
  } else return "";
}
function getDeductionsValue(params) {
  const { transaction_type_id, amount, applied_amount } = params.row;
  if (
    applied_amount ||
    transactionTypeLookup[transaction_type_id] === "applied_payment" ||
    transactionTypeLookup[transaction_type_id] === "from_prepayment" ||
    transactionTypeLookup[transaction_type_id] === "credit" ||
    transactionTypeLookup[transaction_type_id] === "refund"
  ) {
    return applied_amount ? applied_amount : amount;
  } else return "";
}
function getPaymentsValue(params) {
  const { transaction_type_id, amount, applied_amount } = params.row;
  if (applied_amount) return "";
  if (
    transactionTypeLookup[transaction_type_id] === "payment_cash" ||
    transactionTypeLookup[transaction_type_id] === "payment_credit" ||
    transactionTypeLookup[transaction_type_id] === "credit_prepayment"
  ) {
    return amount;
  } else if (
    transactionTypeLookup[transaction_type_id] === "failed_payment" ||
    transactionTypeLookup[transaction_type_id] === "reversed_payment"
  ) {
    return amount;
  } else return "";
}
function getLegacyPayments(params) {
  const { failure_reason, failure_amount, amount, currency } = params.row;
  return (
    <Typography
      variant="body1"
      sx={{
        opacity: failure_reason ? 0.5 : "",
        textDecoration: failure_reason ? "line-through" : "",
      }}
      color={"#128E56"}
    >
      {getCurrencySymbol(currency)}
      {numberWithCommas(failure_reason ? failure_amount : amount)}
    </Typography>
  );
}
function getPayments(params) {
  const { transaction_type_id, amount, currency, applied_amount } = params.row;
  if (applied_amount) return "";
  if (
    transactionTypeLookup[transaction_type_id] === "payment_cash" ||
    transactionTypeLookup[transaction_type_id] === "payment_credit" ||
    transactionTypeLookup[transaction_type_id] === "credit_prepayment"
  ) {
    return (
      <Typography variant="body1" color={"#128E56"}>
        {getCurrencySymbol(currency)}
        {numberWithCommas(amount)}
      </Typography>
    );
  } else if (
    transactionTypeLookup[transaction_type_id] === "failed_payment" ||
    transactionTypeLookup[transaction_type_id] === "reversed_payment"
  ) {
    return (
      <Typography
        variant="body1"
        color={"#128E56"}
        sx={{
          opacity: 0.5,
          textDecoration: "line-through",
        }}
      >
        {getCurrencySymbol(currency)}
        {numberWithCommas(amount)}
      </Typography>
    );
  } else return "";
}

const EditPaymentButton: FC<{
  transaction_id: number;
  transaction_type_id: number;
}> = ({ transaction_id, transaction_type_id }) => {
  const navigate = useNavigate();

  const _customer = useSelector(customerSelectors.customer);

  const handelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    navigate(
      `${AdminPages.editPayment.path}?transaction-id=:transaction_id`
        .replace(":id", _customer?.id?.toString() ?? "")
        .replace(":transaction_id", transaction_id.toString()),
    );
  };
  if (
    transactionTypeLookup[transaction_type_id] === "payment_cash" ||
    transactionTypeLookup[transaction_type_id] === "payment_credit" ||
    transactionTypeLookup[transaction_type_id] === "cash_prepayment" ||
    transactionTypeLookup[transaction_type_id] === "credit_prepayment" ||
    transactionTypeLookup[transaction_type_id] === "credit"
  ) {
    return (
      <IconButton color="primary" sx={rowToolBtnStyle} onClick={handelClick}>
        <EditIcon />
      </IconButton>
    );
  }

  return null;
};

function getTransactionButton(params) {
  const { transaction_type_id, transaction_id, linked_invoice_id } = params.row;
  if (linked_invoice_id?.id) {
    return (
      <>
        {transactionTypeLookup[transaction_type_id] === "payment_cash" ||
        transactionTypeLookup[transaction_type_id] === "cash_prepayment" ? (
          <DeletePaymentButton
            transaction_id={transaction_id}
            linked_invoice_id={linked_invoice_id.id}
          />
        ) : null}
      </>
    );
  } else {
    return (
      <EditPaymentButton
        transaction_id={transaction_id}
        transaction_type_id={transaction_type_id}
      />
    );
  }
}

function getBalance(params) {
  if (params.row?.v_customer_balance === undefined)
    return (
      <Skeleton variant="rectangular" animation="wave" width={82} height={32} />
    );
  const balance = parseInt(params.row?.v_customer_balance?.balance) || 0;
  return (
    <Typography variant="body1" color={balance >= 0 ? "#128E56" : "#BB2018"}>
      {balance < 0 && "-"}
      {getCurrencySymbol(params.row?.currency)}
      {numberWithCommas(balance)}
    </Typography>
  );
}

function getBalanceFormat(params) {
  const { currency, v_customer_balance } = params.api.getRow(params.id);
  if (v_customer_balance === undefined) return;
  const balance = v_customer_balance?.balance || 0;
  return ` ${parseInt(balance).toFixed(2)}${getCurrencySymbol(currency)}`;
}
function getBalanceValue(params) {
  if (params.row?.v_customer_balance === undefined) return;
  return params.row?.v_customer_balance?.balance || 0;
}

function getCustomerStatus(params) {
  const status = params.row.status_id;
  return (
    <Tooltip title={statuses[statusValues[status]]?.toolTip}>
      <CircleIcon
        sx={{
          mr: -1,
          height: 8,
          width: 8,
          color: statuses[statusValues[status]]?.color,
        }}
      />
    </Tooltip>
  );
}
function getCustomerStatusValue(params) {
  const status = params.row.status_id;
  return statuses[statusValues[status]]?.toolTip;
}

function getStudentStatusValue(params) {
  return statuses[statusValues[params.row.status_id]]?.toolTip;
}

function getBooleanStatusFromId(params) {
  return params.row.status_id === 1 || params.row.status_id === 5;
}

const StudentStatus = (params) => {
  return (
    <Tooltip
      title={statuses[statusValues[params.row.status_id]]?.toolTip || ""}
    >
      <CircleIcon
        sx={{
          mr: -1,
          height: 8,
          width: 8,
          color: statuses[statusValues[params.row.status_id]]?.color,
        }}
      />
    </Tooltip>
  );
};

function getNumberFormat(v, type: string) {
  const { phone_numbers } = v.api.getRow(v.id);
  const phone = phone_numbers?.filter(
    (numberObj: any) => numberObj.type === type,
  );
  const isFirstNumberZero =
    phone[0]?.number && phone[0]?.number?.toString()[0] === "0";

  return `${
    phone[0]?.number ? (isFirstNumberZero ? "'" : "") + phone[0]?.number : ""
  }`;
}
function getStudentNumberFormat(v, type: string) {
  const { customer } = v.api.getRow(v.id);
  const phone = customer?.phone_numbers?.filter(
    (numberObj: any) => numberObj.type === type,
  );
  const isFirstNumberZero =
    phone[0]?.number && phone[0]?.number?.toString()[0] === "0";

  return `${
    phone[0]?.number ? (isFirstNumberZero ? "'" : "") + phone[0]?.number : ""
  }`;
}
function vGetStudentNumberFormat(v, type: "cell" | "home") {
  const { home_phone_number, cell_phone_number } = v.api.getRow(v.id);
  const number = type === "home" ? home_phone_number : cell_phone_number;
  const isFirstNumberZero = number && number.toString()[0] === "0";

  return `${number ? (isFirstNumberZero ? "'" : "") + number : ""}`;
}
function getNumber(params, type) {
  const object = params.row?.phone_numbers?.filter(
    (numberObj: any) => numberObj.type === type,
  );

  return object[0]?.number;
}
function getAddress(params) {
  return params.row?.address?.address || "";
}
function getAddress2(params) {
  return params.row?.address?.address_2 || "";
}
function getCity(params) {
  return params.row?.address?.city || "";
}
function getState(params) {
  return params.row?.address?.state || "";
}
function getZip(params) {
  return `'"${params.row?.address?.zip}"'` || "";
}

function getCountry(params) {
  return params.row?.address?.country_code?.hr_name || "";
}

function getStudentHomeNumber(params) {
  const object = params.row?.customer?.phone_numbers?.filter((numberObj) => {
    return numberObj.type === "home";
  });

  return object[0]?.number;
}

function _formatDate(date) {
  return date && isValid(new Date(date))
    ? format(new Date(date), "MM/dd/yy")
    : null;
}

function getStudentDateJoined(params) {
  const date = params.row.student_status_date?.first_active_date;
  return _formatDate(date);
}

function sortDate(a, b) {
  const dateOne = new Date(a);
  const dateTwo = new Date(b);
  if (isEqual(dateOne, dateTwo)) return 0;
  return isBefore(dateOne, dateTwo) ? 1 : -1;
}
function sortDateCheckInvalid(a, b) {
  let dateOne = new Date(a);
  let dateTwo = new Date(b);
  if (!isValid(dateOne)) dateOne = new Date(1950, 1);
  if (!isValid(dateTwo)) dateTwo = new Date(1950, 1);
  if (isEqual(dateOne, dateTwo)) return 0;
  return isBefore(dateOne, dateTwo) ? 1 : -1;
}
function getStudentDateRejoined(params) {
  const date =
    params.row.student_status_date?.last_reactivate ||
    params.row.student_status_date?.last_active_date;
  return _formatDate(date);
}
function getStudentDateLeft(params) {
  const date = params.row.student_status_date?.last_inactive_date;
  return _formatDate(date);
}
function getStudentDateChecked(params) {
  const date = params.row.last_check_in;
  return _formatDate(date);
}

export function getStudentStartMonth(params) {
  return params.row.start_months_cache?.month_start;
}

export function getStudentRejoinedMonth(params) {
  return params.row.start_months_cache?.month_return;
}

function getStudentCellNumber(params) {
  const object = params.row?.customer?.phone_numbers?.filter((numberObj) => {
    return numberObj?.type === "cell" || "";
  });

  return object[0]?.number || "";
}

const getPaymentMethod = (params) => {
  return params.row.payment_method === "CC"
    ? "Credit Card"
    : params.row.payment_method;
};
const getGrade = (params) => {
  return params.row.grade_yiddish || "";
};

function getPin(params) {
  return params.row?.pin || "";
}
function getPinFormat(v) {
  const { pin } = v.api.getRow(v.id);
  const isFirstNumberZero = pin[0] === "0";
  return `${(isFirstNumberZero ? "'" : "") + pin}`;
}
function getZipAddressFormat(v) {
  const { address } = v.api.getRow(v.id);
  const isFirstNumberZero = address?.zip[0] === "0";
  return `${(isFirstNumberZero ? "'" : "") + address?.zip}`;
}
function getZipFormat(v) {
  const { zip } = v.api.getRow(v.id);
  const isFirstNumberZero = zip[0] === "0";
  return `${(isFirstNumberZero ? "'" : "") + zip}`;
}

function getStudentCustomerValue(params) {
  const { first_name_yiddish = "", last_name_yiddish = "" } =
    params.row.customer;
  return `${first_name_yiddish} ${last_name_yiddish}`;
}

function vGetStudentCustomerValue(params) {
  const { customer_first_name_yiddish = "", customer_last_name_yiddish = "" } =
    params.row;
  return `${customer_first_name_yiddish} ${customer_last_name_yiddish}`;
}

function getStudentCustomer(params) {
  const { first_name_yiddish, last_name_yiddish, id } = params.row.customer;
  return (
    <Chip
      sx={{
        borderRadius: 0,
        margin: "2.5px 5px",
        backgroundColor: "secondary.main",
        color: "primary.main",
        minHeight: "25px",
        fontSize: "14px",
        fontWeight: "bold",
      }}
      onClick={(e) => {
        e.stopPropagation();
        history.push(
          `${AdminPages.customer.path.replace(":id", id.toString(10))}`,
        );
      }}
      label={first_name_yiddish + " " + last_name_yiddish}
    />
  );
}

function vGetStudentCustomer(params) {
  const {
    customer_first_name_yiddish,
    customer_last_name_yiddish,
    customer_id,
  } = params.row;
  return (
    <Chip
      sx={{
        borderRadius: 0,
        margin: "2.5px 5px",
        backgroundColor: "secondary.main",
        color: "primary.main",
        minHeight: "25px",
        fontSize: "14px",
        fontWeight: "bold",
      }}
      onClick={(e) => {
        e.stopPropagation();
        history.push(
          `${AdminPages.customer.path.replace(
            ":id",
            customer_id.toString(10),
          )}`,
        );
      }}
      label={customer_first_name_yiddish + " " + customer_last_name_yiddish}
    />
  );
}
function customerBilling(params) {
  const { name, person_id } = params.row;
  return (
    <Chip
      sx={{
        borderRadius: 0,
        margin: "2.5px 5px",
        backgroundColor: "secondary.main",
        color: "primary.main",
        minHeight: "25px",
        fontSize: "14px",
        fontWeight: "bold",
      }}
      onClick={() => {
        history.push(
          `${AdminPages.customer.path.replace(":id", person_id.toString(10))}`,
        );
      }}
      label={name}
    />
  );
}

function getStudentTest(params) {
  return params.row.monthly_points?.test ?? "";
}

function getStudentReview(params) {
  return params.row.monthly_points?.review ?? "";
}

function getStudentAttendance(params) {
  return params.row.monthly_points?.attendance ?? "";
}

function getStudentTotal(params) {
  return params.row.monthly_points?.total ?? "";
}

function getStudentGatheringValue(params) {
  const { gathering } = params.row.student_months?.[0] || {};
  return gathering === "attended"
    ? "Attended"
    : gathering === "reached"
    ? "Reached after the gathering"
    : gathering === "called"
    ? "Wasn't reached"
    : " ";
}

function getStudentGathering(params) {
  const { gathering } = params.row.student_months[0];
  return getGathering({ row: { gathering } });
}

function getStudentCheckIn(params) {
  const { check_in } = params.row.student_months[0];

  return getCheckIn({ row: { check_in } });
}

function getStudentCheckInValue(params) {
  const { check_in } = params.row.student_months?.[0] || {};
  return check_in === "reached"
    ? "Reached"
    : check_in === "called"
    ? "Wasn't reached"
    : " ";
}

function getStudentCustomerAddress(params) {
  return params.row.customer?.address?.address || "";
}
function getStudentCustomerAddress_two(params) {
  return params.row.customer?.address?.address_2 || "";
}
function getStudentCustomerCity(params) {
  return params.row.customer?.address?.city || "";
}
function getStudentCustomerState(params) {
  return params.row.customer?.address?.state || "";
}
function getStudentCustomerZip(params) {
  return params.row.customer?.address?.zip || "";
}

function getStudentPointsValue(params) {
  return params.row.cache_point?.balance ?? "";
}

function getStudentPoints(params) {
  const value = params.row.cache_point?.balance ?? "";

  if (!value) {
    return "";
  }

  return (
    <>
      <DoubleStarIcon sx={sxIconPoints} />
      <Typography variant={"subtitle1"} sx={sxPoints}>
        {value}
      </Typography>
    </>
  );
}

function getStudentLogStatus(params) {
  const { status_id } = params.row;

  // TODO: Check if it is correct
  switch (status_id) {
    case 1:
      return "Joined";
    case 3:
      return "Left";
    case 4:
      return "Saved as Draft";
    case 5:
      return "Pending";
    default:
      return "";
  }
}

function getStudentLogEnteredOn(params) {
  const { created_at } = params.row;
  if (!created_at) {
    return "";
  }
  // TODO: formatting and add Hebrew date
  return format(new Date(created_at), "MMM dd, yyyy");
}
function formatBillingDate(params) {
  const { created_at } = params.row;
  if (!created_at) {
    return "";
  }
  const date = format(new Date(created_at), "MM/dd/yy - p");
  return date;
}
function formatBillingDateValue(params) {
  const { created_at } = params.row;
  if (!created_at) {
    return "";
  } else return created_at;
}
function formatDateValue(params) {
  const { date } = params.row;
  if (!date) {
    return "";
  } else return date;
}
export function formatDate(params) {
  const { date } = params.row;
  if (!date) {
    return "";
  }
  const formattedDate = format(new Date(date), "MM/dd/yy - p");
  return formattedDate;
}

const getStudentLogEnteredBy = (params) => {
  const value = params.row.created_by;
  return params.row.__list?.users[value]?.name_yiddish ?? "";
};

function getStudentHistorySemester(params) {
  return (
    <>
      <Typography variant={"body2"} sx={sxText}>
        {buildYearAndMonthString(
          params.row?.type ?? "",
          params.row?.year_id ?? 0,
        )}
      </Typography>
      {params.row.timePeriod &&
        (params.row.timePeriod === "Current" ||
          params.row.timePeriod === "Future") && (
          <Typography
            fontSize={11}
            marginLeft={2}
            color={params.row.timePeriod === "Future" ? "#737373" : "#F0921F"}
          >
            {params.row.timePeriod}
          </Typography>
        )}
    </>
  );
}

function getStudentHistorySemesterValue(params) {
  return buildYearAndMonthString(
    params.row?.type ?? "",
    params.row?.year_id ?? 0,
  );
}

function getStudentHistoryGrade(valueOnly: boolean, params) {
  const current_grade = params.row.__list?.extraData?.grade ?? 0; // grade from Student
  const current_year = params.row.__list?.extraData?.firstYearId ?? 0; // rows[0].semester.year_id always for all rows

  const grade_year = current_year - current_grade;
  const grade_row = params.row.year_id - grade_year;

  const value =
    !!grade_row && toInt(grade_row) !== 0
      ? gematriya(grade_row.toString())
      : "";

  if (valueOnly) {
    return value;
  }

  return (
    <Typography variant={"body2"} sx={sxText}>
      {value}
    </Typography>
  );
}
function getStudentHistoryChaburaName(valueOnly: boolean, params) {
  const value = params.row.chaburah_months[0].chaburah.name;

  if (valueOnly) {
    return value;
  }

  return (
    <Typography variant={"body2"} sx={sxText}>
      {value}
    </Typography>
  );
}

function getStudentHistoryTrack(valueOnly: boolean, params) {
  const { track_id } = params.row.chaburah_months[0].chaburah;
  const value = params.row.__list?.tracks[track_id]?.name || "";

  if (valueOnly) {
    return value;
  }

  return (
    <Typography variant={"body2"} sx={sxText}>
      {value}
    </Typography>
  );
}

function getStudentHistoryChaburaType(params) {
  const { chaburah_type_id } = params.row.chaburah_months[0].chaburah;
  const name = params.row.__list?.chaburahTypes[chaburah_type_id]?.name;

  let value = "";

  switch (name) {
    case "live":
      value = "Mismidim";
      break;
    case "remote":
      value = "CD";
      break;
  }

  return value;
}

function getStudentHistoryLocation(valueOnly: boolean, params) {
  const { location_id } = params.row.chaburah_months[0].chaburah;

  if (!location_id) {
    return "";
  }
  const value = params.row.__list?.locations[location_id]?.name ?? "";
  if (valueOnly) {
    return value;
  }

  return (
    <Typography variant={"body2"} sx={sxText}>
      {value}
    </Typography>
  );
}

function getStudentHistoryCurriculum(valueOnly: boolean, params) {
  const { name_hebrew } =
    params.row.chaburah_months[0].chaburah.chaburah_semester_subjects[0]
      .subject;
  const value = name_hebrew ?? "";

  if (valueOnly) {
    return value;
  }

  return (
    <Typography variant={"body2"} sx={sxText}>
      {value}
    </Typography>
  );
}
function getGathering(params) {
  const { gathering } = params.row;
  return <StudentGathering gathering={gathering} />;
}

function getCheckIn(params) {
  const { check_in } = params.row;
  return <StudentCheckIn check_in={check_in} />;
}

function getStudentRewardsDate(params) {
  const { created_at } = params.row;

  if (!created_at) {
    return "";
  }

  return format(new Date(created_at), "M/d/yyyy");
}
function getStudentRewardsDateValue(params) {
  const { created_at } = params.row;

  return created_at;
}

function getStudentRewardsFor(params) {
  const { month_id, type } = params.row;

  const months = params.row.__list?.months;

  const month = months?.[month_id]?.name ?? "";
  const year = (months?.[month_id]?.year_id ?? 0).toString(10).slice(2);
  const mappedType = MappedTypes[type] ?? "";

  return `${month} ‘${year} - ${mappedType}`;
}

function getStudentRewardsReason(params) {
  const { reason } = params.row;

  return reason ?? "";
}

function getStudentRewardsAmount(params) {
  const { points } = params.row;

  return points ?? "";
}

function getStudentSemesterHistory(
  field: keyof StudentSemesterHistoryMonth,
  valueOnly: boolean,
  params,
) {
  const value = params.row?.[field];
  switch (field) {
    case "month":
      if (valueOnly) {
        return value;
      }

      return (
        <Stack
          direction={"row"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Typography variant={"body2"}>{value}</Typography>

          <CircleIcon
            sx={{
              height: 8,
              width: 8,
              color: params.row.active ? "success.main" : "error.main",
            }}
          />
        </Stack>
      );
    case "total":
      return value || "";
    default:
      return value ?? "";
  }
}

function getStudentSavedCardType(params) {
  return params.row?.cardType ?? "";
}

function getStudentSavedCardNumber(params) {
  return params.row?.maskedNumber ?? "";
}

function getGroupNameValue(params) {
  return params.row.name ?? "";
}
function getGroupStatusValue(params) {
  return params.row.active ? "Active" : "Inactive";
}

function getGroupName(params) {
  return params.row.name ?? "";
}
function getGroupStatus(params) {
  return (
    <div>
      <CircleIcon
        sx={{
          mr: 1,
          height: 8,
          width: 8,
          color: params.row.active ? "#128E56" : "#BB2018",
        }}
      />
    </div>
  );
}

function getGroupStudentsCountValue(params) {
  return params.row.students_count ?? "";
}

function getGroupTypeValue(params) {
  switch (params.row.chaburah_type_id) {
    case 1:
      return "Masmidim";
    case 2:
      return "CD";
    default:
      return "";
  }
}

function getGroupTrackValue(params) {
  const trackId = params.row.track_id ?? 0;

  return params.row.__list?.tracks?.[trackId].name ?? "";
}

function getGroupLocationValue(params) {
  const location_id = params.row.location_id ?? 0;

  return params.row.__list?.locations?.[location_id]?.name ?? "";
}

function getGroupNeighborhoodValue(params) {
  const neighborhood_id = params.row.neighborhood_id ?? 0;

  return params.row.__list?.neighborhoods?.[neighborhood_id]?.name ?? "";
}

function getGroupStudentName(params) {
  const {
    status_id,
    first_name,
    last_name,
    first_name_yiddish,
    last_name_yiddish,
  } = params.row;

  const _statuses = useSelector(listsSelectors.statusById);
  const status = _statuses[status_id].name;

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Tooltip title={statuses[status]?.toolTip || ""}>
        <CircleIcon
          sx={{
            mr: 1,
            height: 8,
            width: 8,
            color: statuses[status]?.color,
          }}
        />
      </Tooltip>

      <Box>
        <Typography
          variant={"body2"}
          sx={sxName}
        >{`${first_name_yiddish} ${last_name_yiddish}`}</Typography>
        <Typography
          variant={"body2"}
          sx={sxName}
        >{`${first_name} ${last_name}`}</Typography>
      </Box>
    </Stack>
  );
}

function getGroupStudentNameValue(params) {
  const { first_name_yiddish, last_name_yiddish } = params.row;

  return `${first_name_yiddish} ${last_name_yiddish}`;
}

function getGroupStudentPhone(params) {
  const object = params.row?.customer?.phone_numbers?.filter(
    (numberObj: any) => {
      return numberObj.type === "home";
    },
  );

  return object[0]?.number ?? "";
}

function getGroupStudentNeighborhood(params) {
  const id = params.row.customer?.address?.neighborhood_id ?? 0;

  return params?.row?.__list?.neighborhoods[id]?.name || "";
}

function getGroupStudentTeacherValue(params) {
  const rosh_kinyan = params.row.student_months?.[0]?.v_rosh_kinyan;

  return `${rosh_kinyan?.first_name_yiddish ?? ""} ${
    rosh_kinyan?.last_name_yiddish ?? ""
  }`;
}

export const getRoshKinyan = (params) => {
  if (
    params?.row?.student_months[0]?.v_rosh_kinyan?.first_name_yiddish ||
    params?.row?.student_months[0]?.v_rosh_kinyan?.last_name_yiddish
  ) {
    return `${
      params?.row?.student_months[0]?.v_rosh_kinyan?.first_name_yiddish || ""
    } ${params?.row?.student_months[0].v_rosh_kinyan?.last_name_yiddish || ""}`;
  } else return;
};

function getGroupStudentTeacher(params) {
  const rosh_kinyan = params.row.student_months?.[0]?.v_rosh_kinyan;

  const value = `${rosh_kinyan?.first_name_yiddish ?? ""} ${
    rosh_kinyan?.last_name_yiddish ?? ""
  }`;

  if (!value.trim()) {
    return "";
  }

  return (
    <Chip
      sx={{
        borderRadius: 0,
        margin: "2.5px 5px",
        backgroundColor: "secondary.main",
        color: "primary.main",
        minHeight: "25px",
        fontSize: "14px",
        fontWeight: "bold",
      }}
      label={value}
    />
  );
}

function getGroupStudentCustomerValue(params) {
  const { first_name_yiddish = "", last_name_yiddish = "" } =
    params.row.customer;

  return `${first_name_yiddish} ${last_name_yiddish}`;
}

function getGroupStudentCustomer(params) {
  const {
    first_name_yiddish = "",
    last_name_yiddish = "",
    id,
  } = params.row.customer;

  return (
    <Chip
      sx={{
        borderRadius: 0,
        margin: "2.5px 5px",
        backgroundColor: "secondary.main",
        color: "primary.main",
        minHeight: "25px",
        fontSize: "14px",
        fontWeight: "bold",
      }}
      onClick={(e) => {
        e.stopPropagation();
        history.push(
          `${AdminPages.customer.path.replace(":id", id.toString(10))}`,
        );
      }}
      label={`${first_name_yiddish} ${last_name_yiddish}`}
    />
  );
}

function getGroupStudentCustomerAddress(params) {
  const { address } = params.row.customer;

  return `${address?.address || ""} ${address?.address_2 || ""} ${
    address?.city || ""
  } ${address?.state || ""} ${address?.zip || ""}`;
}

function getGroupStudentPoints(params) {
  const value = params.row.cache_point?.balance ?? "";

  if (!value) {
    return "";
  }

  return (
    <>
      <DoubleStarIcon sx={sxIconGS} />
      <Typography variant={"subtitle1"} sx={sxPointsGS}>
        {value}
      </Typography>
    </>
  );
}

function getGroupStudentPointsValue(params) {
  return params.row.cache_point?.balance ?? "";
}

function getGroupCurriculumSemesterValue(params) {
  const semester_id = params.row.semester_id ?? "";

  const name = params.row.__list?.semesters[semester_id]?.name ?? "";
  const year_id = params.row.__list?.semesters[semester_id]?.year_id ?? 0;

  return buildYearAndMonthString(name, year_id);
}

function getGroupCurriculumSubjectValue(params) {
  const subject_id = params.row.subject_id ?? "";

  return params.row.__list?.subjects[subject_id]?.name_hebrew ?? "";
}

function getPayoffAmount(params) {
  const { index, balance, isSelected, amount, formik, total, currency } =
    params.row;
  let newAmount = "";
  let newBalance = "";
  if (!formik) return null;
  if (amount.charAt(0) === "-") {
    newAmount = amount.substring(1) ?? "";
  } else newAmount = amount;
  if (balance.slice(0, 2) === "-$" || balance.slice(0, 2) === "-£") {
    newBalance = balance.substring(2) ?? "";
  } else
    newBalance =
      balance.slice(0, 1) === "-" ||
      balance.slice(0, 1) === "$" ||
      balance.slice(0, 1) === "£"
        ? balance.substring(1)
        : balance;

  return (
    <EditAmountField
      currency={currency}
      index={index}
      value={newAmount}
      total={total}
      balance={newBalance}
      formik={formik}
      disabled={!isSelected}
      params={params}
    />
  );
}

function getPayoffFieldValue(field: keyof BalanceRow, params) {
  if (params.row[field].charAt(0) === "-") {
    return params.row[field].substring(1) ?? "";
  } else return params.row[field] ?? "";
}

function getUserFullNameEN(params) {
  const { isActive, name } = params.row;

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <CircleIcon
        sx={{
          mr: 1,
          height: 8,
          width: 8,
          color: isActive ? "#128E56" : "#BB2018",
        }}
      />

      <Typography variant={"body2"} sx={sxName}>
        {name}
      </Typography>
    </Stack>
  );
}

// prize getters

function getPrizeName(product) {
  return (
    <Box sx={{ display: "inline-flex" }}>
      <Box sx={{ marginRight: "10px" }}>
        <img height="32px" src={product?.image_link} />
      </Box>
      <Box>
        <Box>{product?.name || ""}</Box>
        <Box>{product?.name_yiddish || ""}</Box>
      </Box>
    </Box>
  );
}
const getPrizeNameValue = (product) =>
  `${product?.name || ""} ${product?.name_yiddish || ""}`;
function getPrizeCost(params) {
  const cost = params.row?.cost;
  const symbol = getCurrencySymbol(params.row?.currency);
  return cost ? symbol + cost : "";
}
function getPrizePointsValue(row) {
  return (
    <Box sx={{ color: "#F0921F" }}>
      {numsWithCommasWithNeg(row.point_value) || ""}
    </Box>
  );
}

function GetVendor(params, vendors) {
  const vendor = params.row.vendor_id;
  if (!vendor) {
    return "";
  }
  return vendors.rows.find((v) => v.id === vendor)?.name;
}
function getDateOnly(params) {
  const { row, field } = params;
  return _formatDate(row[field]);
}

///////////////////////////fields
const status_id = {
  field: "status",
  headerName: "",
  minWidth: 1,
  maxWidth: 10,
  renderCell: getCustomerStatus,
  valueGetter: getCustomerStatusValue,
  type: "singleSelect",
  valueOptions: CUSTOMER_STATUS,
};

export const student_status_id = {
  field: "status",
  headerName: "",
  minWidth: 1,
  maxWidth: 10,
  valueGetter: getStudentStatusValue,
  renderCell: StudentStatus,
  type: "singleSelect",
  valueOptions: STATUS,
};

export const pin = {
  field: "pin",
  headerName: "Pin",
  minWidth: 50,
  renderCell: getPin,
  valueGetter: getPin,
  valueFormatter: getPinFormat,
};

const student_customer_address = {
  field: "address_one",
  headerName: "Address",
  valueGetter: getStudentCustomerAddress,
  renderCell: getStudentCustomerAddress,
  flex: 1.6,
};
const student_customer_address_two = {
  field: "address_two",
  headerName: "Address 2",
  valueGetter: getStudentCustomerAddress_two,
  renderCell: getStudentCustomerAddress_two,
  flex: 1,
};
const student_customer_city = {
  field: "city",
  headerName: "City",
  valueGetter: getStudentCustomerCity,
  renderCell: getStudentCustomerCity,
  flex: 1,
};
const student_customer_state = {
  field: "state",
  headerName: "State",
  valueGetter: getStudentCustomerState,
  renderCell: getStudentCustomerState,
  flex: 1,
};
const student_customer_zip = {
  field: "zip",
  headerName: "Zip",
  valueGetter: getStudentCustomerZip,
  renderCell: getStudentCustomerZip,
  flex: 1,
};
const address_field = {
  field: "address",
  headerName: "Address",
  flex: 1.6,
};
const address_two_field = {
  field: "address_2",
  headerName: "Address 2",
  flex: 1,
};
const city_field = {
  field: "city",
  headerName: "City",
  flex: 1,
};
const state_field = {
  field: "state",
  headerName: "State",
  flex: 1,
};
const zip_field = {
  field: "zip",
  headerName: "Zip",
  flex: 1,
  valueFormatter: getZipFormat,
};
const country_name_field = {
  field: "country_name",
  headerName: "Country",
  flex: 1,
};
export const defaultPaymentMethod = {
  field: "default_payment_method",
  headerName: "Payment Method",
  flex: 1,
  type: "singleSelect",
  valueOptions: PAYMENT_METHOD,
};
const paymentMethod = {
  field: "payment_method",
  headerName: "Payment Method",
  valueGetter: getPaymentMethod,
  renderCell: getPaymentMethod,
  flex: 1,
  valueOptions: PAYMENT_METHOD,
};

export const grade_yiddish = {
  field: "grade_yiddish",
  headerName: "Grade",
  flex: 1,
  valueGetter: getGrade,
  type: "singleSelect",
  valueOptions: GRADE_YIDDISH,
};

const student_points = {
  field: "point_balance",
  headerName: "Points balance",
  valueGetter: getStudentPointsValue,
  renderCell: getStudentPoints,
  flex: 1,
  type: "number",
};

export const fullName = {
  field: "fullName",
  headerName: "Name",
  flex: 1.6,
  renderCell: getFullName,
  valueGetter: getFullNameValue,
};
export const fullNameYiddish = {
  field: "fullNameYiddish",
  headerName: "נאמען",
  flex: 1.6,
  renderCell: getFullNameYiddish,
  valueGetter: getFullNameYiddish,
};
export const fullNameEnglish = {
  field: "fullNameEnglish",
  headerName: "Full name",
  flex: 1.6,
  renderCell: getFullNameEnglish,
  valueGetter: getFullNameEnglish,
};
const students = {
  field: "students",
  headerName: "Students",
  flex: 3,
  renderCell: getStudents,
  valueGetter: getStudentsValue,
};
export const first_name = {
  field: "first_name",
  headerName: "First",
  flex: 1,
};

export const last_name = {
  field: "last_name",
  headerName: "Last",
  flex: 1,
};
export const first_name_yiddish = {
  field: "first_name_yiddish",
  headerName: "ערשטע",
  flex: 1,
};

export const last_name_yiddish = {
  field: "last_name_yiddish",
  headerName: "לעצטע",
  flex: 1,
};
const identifier = {
  field: "identifier",
  headerName: "Card Used",
  flex: 1,
};
const homePhone = {
  field: "homePhone",
  headerName: "Home",
  flex: 1,
  renderCell: (e) => getNumber(e, "home"),
  valueGetter: (e) => getNumber(e, "home"),
  valueFormatter: (e) => getNumberFormat(e, "home"),
};
const cellPhone = {
  field: "cellPhone",
  headerName: "Cell",
  flex: 1,
  renderCell: (e) => getNumber(e, "cell"),
  valueGetter: (e) => getNumber(e, "cell"),
  valueFormatter: (e) => getNumberFormat(e, "cell"),
};

const student_home_phone = {
  field: "homePhone",
  headerName: "Home phone",
  flex: 1,
  renderCell: getStudentHomeNumber,
  valueGetter: getStudentHomeNumber,
  valueFormatter: (e) => getStudentNumberFormat(e, "home"),
};

const student_cell_phone = {
  field: "cellPhone",
  headerName: "Cell phone",
  flex: 1,
  renderCell: getStudentCellNumber,
  valueGetter: getStudentCellNumber,
  valueFormatter: (e) => getStudentNumberFormat(e, "cell"),
};

export const v_student_home_phone = {
  field: "home_phone_number",
  headerName: "Home phone",
  flex: 1,
  valueFormatter: (e) => vGetStudentNumberFormat(e, "home"),
};

export const v_student_cell_phone = {
  field: "cell_phone_number",
  headerName: "Cell phone",
  flex: 1,
  valueFormatter: (e) => vGetStudentNumberFormat(e, "cell"),
};

const student_customer = {
  field: "customer",
  headerName: "Customer",
  resizable: true,
  renderCell: getStudentCustomer,
  valueGetter: getStudentCustomerValue,
  flex: 1.6,
};
export const v_student_customer = {
  field: "customer",
  headerName: "Customer",
  resizable: true,
  renderCell: vGetStudentCustomer,
  valueGetter: vGetStudentCustomerValue,
  flex: 1.6,
};

const student_test = {
  field: "test",
  headerName: "Test",
  renderCell: getStudentTest,
  valueGetter: getStudentTest,
  flex: 1,
  type: "boolean",
};

const student_review = {
  field: "review",
  headerName: "חזרה פראגראם",
  renderCell: getStudentReview,
  valueGetter: getStudentReview,
  flex: 1,
  type: "boolean",
};

const student_attendance = {
  field: "attendance",
  headerName: "טעגליכע פראגראם",
  renderCell: getStudentAttendance,
  valueGetter: getStudentAttendance,
  flex: 1,
  type: "boolean",
};

const student_total = {
  field: "total",
  headerName: "Total",
  renderCell: getStudentTotal,
  valueGetter: getStudentTotal,
  flex: 1,
  type: "number",
};
const monthly_note = (openAddMonthlyPointsModal) => ({
  field: "monthly_note",
  headerName: "Monthly note",
  valueGetter: (params) => params.row.student_months?.[0].monthly_note,
  width: 180,
  align: "right" as GridAlignment,
  renderCell: (params) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <Tooltip
          placement="bottom"
          title={params.row.student_months?.[0].monthly_note}
        >
          <Box
            sx={{
              maxWidth: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginRight: "8px",
            }}
          >
            {params.row.student_months?.[0].monthly_note}
          </Box>
        </Tooltip>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            openAddMonthlyPointsModal(params.row);
          }}
        >
          <EditIcon />
        </IconButton>
      </Box>
    );
  },
});
const student_gathering = {
  field: "gathering",
  headerName: "Gathering",
  type: "singleSelect",
  align: "center",
  headerAlign: "center",
  valueOptions: [
    { value: " ", label: "Empty" },
    "Attended",
    "Reached after the gathering",
    "Wasn't reached",
  ],
  renderCell: getStudentGathering,
  valueGetter: getStudentGatheringValue,
  flex: 1,
};

const student_check_in = {
  field: "checkIn",
  headerName: "Phone call",
  align: "center",
  headerAlign: "center",
  type: "singleSelect",
  valueOptions: [{ value: " ", label: "Empty" }, "Reached", "Wasn't reached"],
  renderCell: getStudentCheckIn,
  valueGetter: getStudentCheckInValue,
  flex: 1,
};
const do_not_call = {
  field: "do_not_call",
  headerName: "Do not call",
  align: "center",
  headerAlign: "center",
  type: "boolean",
  flex: 1,
};
const do_not_call_summer = {
  field: "away",
  headerName: "Do not call summer",
  align: "center",
  headerAlign: "center",
  type: "boolean",
  flex: 1,
};
const call_date = {
  field: "call_date",
  headerName: "Phone call date",
  type: "date",
  valueGetter: (params) =>
    params.row.student_months?.[0].call_date?.replaceAll("-", "/"),
  valueFormatter: (params) => {
    if (!params.value) {
      return "";
    }
    return getYiddishDateString(params.value);
  },
  flex: 1,
};
const call_date_yiddish = {
  field: "call_date_yiddish",
  headerName: "Phone call yiddish",
  type: "date",
  align: "center",
  valueGetter: (params) =>
    params.row.student_months?.[0].call_date?.replaceAll("-", "/"),
  valueFormatter: (params) => {
    if (!params.value) {
      return "";
    }
    return getYiddishDateString(params.value, true);
  },
  flex: 1,
};

const full_address = {
  field: "full_address",
  headerName: "Full Address",
  flex: 1.6,
  renderCell: getFullAddress,
  valueGetter: getFullAddressValue,
};
const full_customer_address = {
  field: "full_customer_address",
  headerName: "Full Address",
  flex: 1.6,
  renderCell: getFullCustomerAddress,
  valueGetter: getFullCustomerAddressValue,
};
const full_student_address = {
  field: "full_student_address",
  headerName: "Full Address",
  flex: 1.6,
  renderCell: getStudentAddress,
  valueGetter: getStudentAddressValue,
};
const address = {
  field: "address",
  headerName: "Address",
  flex: 1,
  renderCell: getAddress,
  valueGetter: getAddress,
};
const address_2 = {
  field: "address_2",
  headerName: "Address 2",
  flex: 1,
  renderCell: getAddress2,
  valueGetter: getAddress2,
};
const city = {
  field: "city",
  headerName: "City",
  flex: 0.75,
  renderCell: getCity,
  valueGetter: getCity,
};
const state = {
  field: "state",
  headerName: "State",
  flex: 0.5,
  renderCell: getState,
  valueGetter: getState,
};
const zip = {
  field: "zip",
  headerName: "Zip",
  flex: 1,
  renderCell: getZip,
  valueGetter: getZip,
  valueFormatter: getZipAddressFormat,
};
const country = {
  field: "country",
  headerName: "Country",
  flex: 1,
  renderCell: getCountry,
  valueGetter: getCountry,
};
const balance = {
  field: "balance",
  headerName: "Balance",
  flex: 1,
  renderCell: getBalance,
  valueGetter: getBalanceValue,
  valueFormatter: getBalanceFormat,
  filterOperators: getGridNumericOperators(),
  sortComparator: (a, b) => (parseInt(a) < parseInt(b) ? -1 : 1),
};
export const email = {
  field: "email",
  headerName: "Email",
  flex: 2,
};
const notes = {
  field: "notes",
  headerName: "Notes",
  flex: 3,
};
const completed = {
  field: "completed",
  headerName: "Completed",
  type: "boolean",
  valueGetter: (params) => !!params.row.completed,
  renderCell: (params) =>
    params.row.completed ? <CheckIcon sx={{ color: "#128E56" }} /> : "",
};

// const bar_mitzvah = {
//   field: "bar_mitzvah",
//   headerName: "Bar Mitzvah",
//   flex: 1,
// };
// const birth_date = {
//   field: "birth_date",
//   headerName: "Date of Birth",
//   flex: 1,
// };
export const date_joined = {
  field: "date_joined",
  headerName: "Date Joined",
  type: "date",
  renderCell: getStudentDateJoined,
  valueGetter: getStudentDateJoined,
  sortComparator: sortDate,
  flex: 1,
};
export const date_rejoined = {
  field: "date_rejoined",
  headerName: "Date Rejoined",
  type: "date",
  renderCell: getStudentDateRejoined,
  valueGetter: getStudentDateRejoined,
  flex: 1,
  sortComparator: sortDate,
};
export const date_left = {
  field: "date_left",
  headerName: "Date Left",
  type: "date",
  renderCell: getStudentDateLeft,
  valueGetter: getStudentDateLeft,
  flex: 1,
  sortComparator: sortDate,
};
export const last_check_in = {
  field: "last_check_in",
  headerName: "Last check in",
  type: "date",
  renderCell: getStudentDateChecked,
  valueGetter: getStudentDateChecked,
  flex: 1,
  sortComparator: sortDateCheckInvalid,
};
// const siyum = {
//   field: "siyum",
//   headerName: "Siyum",
//   flex: 1,
// };
// const registration = {
//   field: "registration",
//   headerName: "Registration",
//   flex: 1,
// };

const student_log_status_id = {
  field: "action",
  headerName: "Action",
  renderCell: getStudentLogStatus,
  flex: 1,
  valueGetter: getStudentLogStatus,
};

const student_log_entered_on = {
  field: "entered_on",
  headerName: "Entered on",
  renderCell: getStudentLogEnteredOn,
  flex: 1,
  valueGetter: getStudentLogEnteredOn,
};

const student_log_entered_by = {
  field: "entered_by",
  headerName: "Entered by",
  flex: 1,
  renderCell: getStudentLogEnteredBy,
  valueGetter: getStudentLogEnteredBy,
};

const student_history_semester = {
  field: "semester",
  headerName: "זמן",
  renderCell: getStudentHistorySemester,
  flex: 1,
  valueGetter: getStudentHistorySemesterValue,
  sortComparator: (_v1, _v2, p1, p2) => {
    return +p1.id - +p2.id;
  },
};

const student_history_grade = {
  field: "grade",
  headerName: "כתה",
  flex: 1,
  renderCell: getStudentHistoryGrade.bind(null, false),
  valueGetter: getStudentHistoryGrade.bind(null, true),
};

const student_history_chabura_name = {
  field: "chabura_name",
  headerName: "חבורה",
  flex: 1,
  renderCell: getStudentHistoryChaburaName.bind(null, false),
  valueGetter: getStudentHistoryChaburaName.bind(null, true),
};

const student_history_siyum = {
  field: "siyum.registration",
  headerName: "Siyum",
  align: "center" as GridAlignment,
  valueGetter: (params) => (params.row.siyum?.registration ? "yes" : "no"),
  renderCell: (params) => {
    return params.row.siyum?.registration ? (
      <CheckIcon sx={{ color: "#737373" }} />
    ) : (
      <CloseIcon sx={{ color: "#737373" }} />
    );
  },
};
const student_history_transportation = {
  field: "siyum.transportation",
  headerName: "Transportation",
  align: "center" as GridAlignment,
  valueGetter: (params) => (params.row.siyum?.transportation ? "yes" : "no"),
  renderCell: (params) => {
    return params.row.siyum?.transportation ? (
      <CheckIcon sx={{ color: "#737373" }} />
    ) : (
      <CloseIcon sx={{ color: "#737373" }} />
    );
  },
};

const student_history_track = {
  field: "track",
  headerName: "קנין",
  renderCell: getStudentHistoryTrack.bind(null, false),
  valueGetter: getStudentHistoryTrack.bind(null, true),
  flex: 1,
};

const student_chabura_type = {
  field: "type",
  headerName: "Type",
  renderCell: getStudentHistoryChaburaType,
  flex: 1,
  valueGetter: getStudentHistoryChaburaType,
};

const student_location = {
  field: "location",
  headerName: "Shul",
  flex: 1,
  renderCell: getStudentHistoryLocation.bind(null, false),
  valueGetter: getStudentHistoryLocation.bind(null, true),
};

const student_curriculum = {
  field: "curriculum",
  headerName: "לימוד",
  flex: 1,
  renderCell: getStudentHistoryCurriculum.bind(null, false),
  valueGetter: getStudentHistoryCurriculum.bind(null, true),
};

const student_rewards_date = {
  field: "date",
  headerName: "Date",
  renderCell: getStudentRewardsDate,
  flex: 1,
  valueGetter: getStudentRewardsDateValue,
};

const student_rewards_for = {
  field: "for",
  headerName: "For",
  renderCell: getStudentRewardsFor,
  flex: 1,
  valueGetter: getStudentRewardsFor,
};

const student_rewards_reason = {
  field: "reason",
  headerName: "Reason",
  renderCell: getStudentRewardsReason,
  flex: 1,
  valueGetter: getStudentRewardsReason,
};

const student_rewards_amount = {
  field: "amount",
  headerName: "Amount",
  renderCell: getStudentRewardsAmount,
  flex: 1,
  valueGetter: getStudentRewardsAmount,
};
const charges = {
  field: "charges",
  headerName: "Charges",
  renderCell: getCharges,
  valueFormatter: getChargesFormatted,
  valueGetter: getChargesValue,
  flex: 1,
  type: "number",
};

const deductions = {
  field: "deductions",
  headerName: "Deductions/Discounts",
  renderCell: getDeductions,
  valueGetter: getDeductionsValue,
  flex: 1,
  type: "number",
};
const paid = {
  field: "paid",
  headerName: "Paid",
  renderCell: getPayments,
  valueGetter: getPaymentsValue,
  flex: 1,
  type: "number",
};
const paymentStatus = {
  field: "fully_applied",
  headerName: "Status",
  valueGetter: (params) => {
    const {
      fully_applied,
      applied,
      unapplied_amount,
      paid,
      unpaid,
      transaction_type_id,
    } = params.row;

    const status =
      transaction_type_id === 5 ||
      transaction_type_id === 8 ||
      transaction_type_id === 9
        ? ""
        : fully_applied
        ? "Applied"
        : applied
        ? "Partially Applied"
        : unapplied_amount
        ? "Unapplied"
        : paid && !unpaid
        ? "Paid"
        : paid && unpaid
        ? "Partially Paid"
        : unpaid && !paid
        ? "Unpaid"
        : "";
    return status;
  },
  renderCell: (params) => {
    const { value } = params;
    const { unpaid, unapplied_amount, currency } = params.row;
    const tooltip =
      unpaid && value === "Partially Paid" && parseFloat(unpaid) < 0
        ? `${
            currency === "USD" ? "$" : currency === "GBP" ? "£" : ""
          }${Math.abs(unpaid)} unpaid`
        : unapplied_amount &&
          value === "Partially Applied" &&
          parseFloat(unapplied_amount) > 0
        ? `${
            currency === "USD" ? "$" : currency === "GBP" ? "£" : ""
          }${unapplied_amount} unapplied`
        : "";
    let color = "";
    let background = "";

    switch (value) {
      case "Applied":
        color = "#128E56";
        background = "rgba(18, 142, 86,.12)";

        break;

      case "Partially Applied":
        color = "#F0921F";
        background = "rgba(240, 146, 31,.12)";
        break;
      case "Unapplied":
        color = "#BB2018";
        background = "rgba(187, 32, 24,.12)";
        break;
      case "Paid":
        color = "#128E56";
        background = "rgba(18, 142, 86,.12)";
        break;
      case "Partially Paid":
        color = "#F0921F";
        background = "rgba(240, 146, 31,.12)";
        break;
      case "Unpaid":
        color = "#BB2018";
        background = "rgba(187, 32, 24,.12)";
        break;

      default:
        break;
    }
    return (
      <Tooltip title={tooltip}>
        <Box display="inline-flex">
          <Box
            color={color}
            bgcolor={background}
            padding="2px"
            paddingRight="5px"
            paddingLeft="5px"
            borderRadius="3px"
          >
            {" "}
            <Box>{value}</Box>
          </Box>
          {tooltip && (
            <ErrorIcon sx={{ color: "#E6EAF0", marginLeft: "5px" }} />
          )}
        </Box>
      </Tooltip>
    );
  },
};
const legacyPaymentAmount = {
  field: "amount",
  headerName: "Paid",
  renderCell: getLegacyPayments,
  flex: 1,
  type: "number",
};

const editPayment = {
  field: "editPayment",
  headerName: "",
  renderCell: getTransactionButton,
  width: 60,
  resizable: false,
  sortable: false,
  editable: false,
  hideable: false,
  disableColumnMenu: true,
};

const id = {
  field: "id",
  headerName: "id",
  flex: 1,
};
const created_at = {
  field: "created_at",
  headerName: "created_at",
  flex: 1,
};
const getTransactionDetails = (params) => ({
  line1:
    params.row.transaction_type_id !== 8
      ? params.row.transaction_type_id === 12
        ? params.row.description + " " + (params.row.note || "")
        : params.row.description
      : params.row.note || "",
  line2:
    params.row.transaction_type_id !== 8 && params.row.masked
      ? `${
          (params.row.card_type || "") +
          "****" +
          params.row.masked.substring(params.row.masked.length - 4)
        }${
          params.row.transaction_type_id === 5
            ? " Reason: " + (params.row.note || "")
            : ""
        }`
      : "",
});
export const getDescription = {
  field: "description",
  headerName: "Description",
  renderCell: (params) => {
    const description = getTransactionDetails(params);
    return (
      <Tooltip title={params.value || ""} placement="bottom-start">
        <Box>
          <Box>{description.line1 || ""}</Box>
          {description.line2 && <Box>{description.line2}</Box>}
        </Box>
      </Tooltip>
    );
  },
  valueGetter: (params) => {
    const description = getTransactionDetails(params);
    return [description.line1, description.line2].filter((d) => !!d).join(" ");
  },
  flex: 1,
};
export const legacyDescription = {
  field: "description",
  headerName: "Description",
  renderCell: ({ row }) => {
    const { description, failure_reason } = row;
    return (
      <Tooltip
        title={failure_reason ?? description ?? ""}
        placement="bottom-start"
      >
        <span>{`${description ?? ""}` + " " + `${failure_reason ?? ""}`}</span>
      </Tooltip>
    );
  },
  valueGetter: (params) => params.row.description,
  flex: 1,
};
const billingDate = {
  field: "date",
  headerName: "Date",
  renderCell: formatBillingDate,
  valueGetter: formatBillingDateValue,
  flex: 1,
  type: "date",
};
const date = {
  field: "date",
  headerName: "Date",
  renderCell: formatDate,
  valueGetter: formatDateValue,
  flex: 1,
  type: "date",
};
const type = {
  field: "type",
  headerName: "Type",
  flex: 1,
};
const chodesh = {
  field: "transaction_months",
  headerName: "חודש",
  renderCell: ({ row }) => <ChodeshYear month_id={row.month_id} />,
  valueGetter: ({ row }) => <ChodeshYear month_id={row.month_id} />,
  valueFormatter: ({ value }) => ChodeshYearFormatted(value),
  flex: 1,
};
const transactionType = {
  field: "transaction_type_id",
  headerName: "Type",
  renderCell: ({ row }) => (
    <>
      <TransactionType transaction_type_id={row.transaction_type_id} />
      {transactionTypeLookup[row.transaction_type_id] === "invoice" && (
        <InvoiceModal
          invoice={row.invoice_items}
          month={row.month_id}
          total={row.amount}
        />
      )}
    </>
  ),
  valueGetter: ({ row }) => transactionTypeLookup[row.transaction_type_id],
  type: "singleSelect",
  valueOptions: TRANSACTIONS,
  flex: 1,
};
const legacyTransactionType = {
  field: "transaction_type_id",
  headerName: "Invoice Details",
  renderCell: ({ row }) => (
    <>
      <TransactionType transaction_type_id={row.transaction_type_id} />
      {transactionTypeLookup[row.transaction_type_id] === "invoice" && (
        <InvoiceModal
          invoice={row.invoice_items_olds}
          month={row.month_id}
          total={row.amount}
        />
      )}
    </>
  ),
  valueGetter: ({ row }) => transactionTypeLookup[row.transaction_type_id],
  type: "singleSelect",
  valueOptions: TRANSACTIONS,
  flex: 1,
};
const customer = {
  field: "name",
  headerName: "Customer",
  renderCell: customerBilling,
  flex: 1,
};

const StudentRewardsControl = (params) => {
  const dispatch = useDispatch();

  const { id } = params.row;

  const handleRowButtonClick = (
    type: ButtonControl,
    id: number,
    event: React.SyntheticEvent,
  ) => {
    event.stopPropagation();

    switch (type) {
      case "edit":
        dispatch(adminActions.setActiveBonus(params.row));
        dispatch(uiActions.setControl({ type, id }));
        break;

      case "remove":
        dispatch(
          adminActions.deleteStudentReward(
            params.row.student_id,
            params.row.id,
          ),
        );
        break;
    }
  };

  if (!id || params.row.type !== "bonus") {
    return null;
  }

  return (
    <>
      <IconButton
        color="primary"
        sx={rowToolBtnStyle}
        onClick={handleRowButtonClick.bind(null, "edit", id)}
      >
        <EditIcon />
      </IconButton>

      <IconButton
        color="primary"
        sx={rowToolBtnStyle}
        onClick={handleRowButtonClick.bind(null, "remove", id)}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
};

const student_rewards_control = {
  field: "control",
  headerName: "",
  renderCell: StudentRewardsControl,
  resizable: false,
  sortable: false,
  editable: false,
  hideable: false,
  disableColumnMenu: true,
};
export const launch_icon = {
  field: "action",
  headerName: "",
  renderCell: (rowData) => getLaunchLink("students", rowData.id),
  valueGetter: (rowData) => `${REACT_APP_BASE_URL}/admin/student/${rowData.id}`,
  resizable: false,
  sortable: false,
  editable: false,
  hideable: false,
  disableColumnMenu: true,
};

const student_semester_history_month = {
  field: "month",
  headerName: "חודש",
  width: 120,
  maxWidth: 150,
  renderCell: getStudentSemesterHistory.bind(null, "month", false),
  valueGetter: getStudentSemesterHistory.bind(null, "month", true),
};

const student_semester_history_subject_name = {
  field: "subject_name",
  headerName: "חבורה",
  width: 250,
  renderCell: getStudentSemesterHistory.bind(null, "subjectName", false),
  valueGetter: getStudentSemesterHistory.bind(null, "subjectName", true),
};

const student_semester_history_group = {
  field: "month_description",
  headerName: "לימוד",
  renderCell: getStudentSemesterHistory.bind(null, "monthDescription", false),
  valueGetter: getStudentSemesterHistory.bind(null, "monthDescription", true),
};

const student_semester_history_price = {
  field: "price",
  headerName: "Price",
  renderCell: getStudentSemesterHistory.bind(null, "price", false),
  valueGetter: getStudentSemesterHistory.bind(null, "price", true),
};

const student_semester_history_phone_call = {
  field: "check_in",
  headerName: "Phone call",
  align: "center" as GridAlignment,
  renderCell: getCheckIn,
};
const student_semester_history_gathering = {
  field: "gathering",
  headerName: "Gathering - צוזאמקום",
  align: "center" as GridAlignment,
  renderCell: getGathering,
};
const student_semester_history_note = {
  field: "monthly_note",
  headerName: "Note",
  align: "center" as GridAlignment,
  renderCell: (params) => {
    const note = params.row.monthly_note || "";

    return (
      <Box>
        <Tooltip title={note}>
          <Box
            sx={{
              maxWidth: "80px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginRight: "8px",
            }}
          >
            {note}
          </Box>
        </Tooltip>
      </Box>
    );
  },
};
const student_semester_history_review = {
  field: "review",
  headerName: "חזרה פראגראם",
  renderCell: getStudentSemesterHistory.bind(null, "review", false),
  valueGetter: getStudentSemesterHistory.bind(null, "review", true),
};

const student_semester_history_attendance = {
  field: "attendance",
  headerName: "Attendance",
  renderCell: getStudentSemesterHistory.bind(null, "attendance", false),
  valueGetter: getStudentSemesterHistory.bind(null, "attendance", true),
};

const student_semester_history_test = {
  field: "test",
  headerName: "Test",
  renderCell: getStudentSemesterHistory.bind(null, "test", false),
  valueGetter: getStudentSemesterHistory.bind(null, "test", true),
};

const student_semester_history_bonus = {
  field: "bonus",
  headerName: "Bonus",
  renderCell: getStudentSemesterHistory.bind(null, "bonus", false),
  valueGetter: getStudentSemesterHistory.bind(null, "bonus", true),
};

const student_semester_history_total = {
  field: "total",
  headerName: "Total",
  renderCell: getStudentSemesterHistory.bind(null, "total", false),
  valueGetter: getStudentSemesterHistory.bind(null, "total", true),
};
const extra_credit = {
  field: "extra_credit",
  headerName: "Extra Credit",
  flex: 1,
  renderCell: (params) =>
    !params.row.extra_credit ? (
      <></>
    ) : (
      <CheckCircleIcon
        sx={{ height: "25px", width: "25px", fill: "#00175C" }}
      />
    ),
};
const answer = {
  field: "answer",
  headerName: "Correct Answer",
  flex: 1,
  renderCell: (params) => (
    <Typography variant="h6">{params.row.answer}</Typography>
  ),
};
const revised = {
  field: "revised",
  headerName: "Revised",
  flex: 1,
  renderCell: (params) => (
    <Typography
      variant="h6"
      color={params.row.revised != params.row.answer ? "error" : "primary"}
    >
      {params.row.revised}
    </Typography>
  ),
};
const given = {
  field: "given",
  headerName: "Answered",
  flex: 1,
  renderCell: (params) => (
    <Typography
      variant="h6"
      color={
        !params.row.revised && params.row.given != params.row.answer
          ? "error"
          : "primary"
      }
    >
      {params.row.given}
    </Typography>
  ),
};
const question_number = {
  field: "question_number",
  headerName: "שאלה",
  flex: 1,
  align: "right" as "right",
  renderCell: (params) => (
    <Typography variant="h6">
      {`שאלה ${gematriya(params.row.question_number)}`}
    </Typography>
  ),

  valueSetter: (params) => params.row.question_number,
};

const student_saved_card_type = {
  field: "cardType",
  headerName: "",
  flex: 1,
  renderCell: getStudentSavedCardType,
  valueGetter: getStudentSavedCardType,
};

const student_saved_card_number = {
  field: "maskedNumber",
  headerName: "",
  flex: 1,
  renderCell: getStudentSavedCardNumber,
  valueGetter: getStudentSavedCardNumber,
};

const group_status: GridColDef = {
  field: "status",
  headerName: "",
  minWidth: 1,
  maxWidth: 10,
  renderCell: getGroupStatus,
  valueGetter: getGroupStatusValue,
  type: "singleSelect",
  valueOptions: GROUP_STATUS,
};
const group_name: GridColDef = {
  field: "name",
  headerName: "חבורה",
  flex: 1,
  renderCell: getGroupName,
  valueGetter: getGroupNameValue,
};

const group_type: GridColDef = {
  field: "type",
  headerName: "Type",
  flex: 1,
  renderCell: getGroupTypeValue,
  valueGetter: getGroupTypeValue,
  type: "singleSelect",
  valueOptions: ["Masmidim", "CD"],
};

const group_track: GridColDef = {
  field: "track",
  headerName: "קנין",
  flex: 1,
  renderCell: getGroupTrackValue,
  valueGetter: getGroupTrackValue,
};

const group_location: GridColDef = {
  field: "location",
  headerName: "Shul",
  flex: 1,
  renderCell: getGroupLocationValue,
  valueGetter: getGroupLocationValue,
};

const group_neighborhood: GridColDef = {
  field: "neighborhood",
  headerName: "Neighborhood",
  flex: 1,
  renderCell: getGroupNeighborhoodValue,
  valueGetter: getGroupNeighborhoodValue,
};

const group_students: GridColDef = {
  field: "students",
  headerName: "Students",
  flex: 1,
  renderCell: getGroupStudentsCountValue,
  valueGetter: getGroupStudentsCountValue,
};

const group_student_name: GridColDef = {
  field: "name",
  headerName: "Name",
  flex: 1,
  renderCell: getGroupStudentName,
  valueGetter: getGroupStudentNameValue,
};

const group_student_pin: GridColDef = {
  field: "pin",
  headerName: "Pin",
  flex: 1,
  renderCell: getPin,
  valueGetter: getPin,
};

const group_student_phone: GridColDef = {
  field: "phone",
  headerName: "Phone",
  flex: 1,
  renderCell: getGroupStudentPhone,
  valueGetter: getGroupStudentPhone,
};

const group_student_neighborhood = {
  field: "neighborhood",
  headerName: "Neighborhood",
  renderCell: getGroupStudentNeighborhood,
  valueGetter: getGroupStudentNeighborhood,
  flex: 1,
};

const group_student_teacher = {
  field: "teacher",
  headerName: "ראש קנין",
  renderCell: getGroupStudentTeacher,
  valueGetter: getGroupStudentTeacherValue,
  flex: 1,
};

const group_student_customer = {
  field: "customer",
  headerName: "Customer",
  renderCell: getGroupStudentCustomer,
  valueGetter: getGroupStudentCustomerValue,
  flex: 1,
};

const group_student_address = {
  field: "address",
  headerName: "Address",
  renderCell: getGroupStudentCustomerAddress,
  valueGetter: getGroupStudentCustomerAddress,
  flex: 1,
};

const group_student_points = {
  field: "points",
  headerName: "Points",
  renderCell: getGroupStudentPoints,
  valueGetter: getGroupStudentPointsValue,
  flex: 1,
  type: "number",
};

const group_curriculum_semester = {
  field: "semester",
  headerName: "זמן",
  renderCell: getGroupCurriculumSemesterValue,
  valueGetter: getGroupCurriculumSemesterValue,
};

const group_curriculum_subject = {
  field: "subject",
  headerName: "לימוד",
  renderCell: getGroupCurriculumSubjectValue,
  valueGetter: getGroupCurriculumSubjectValue,
};

const payoff_month = {
  field: "month",
  headerName: "חודש",
  flex: 1,
  renderCell: getPayoffFieldValue.bind(null, "month"),
  valueGetter: getPayoffFieldValue.bind(null, "month"),
};

const payoff_total = {
  field: "total",
  headerName: "Total",
  minWidth: 80,
  renderCell: getPayoffFieldValue.bind(null, "total"),
  valueGetter: getPayoffFieldValue.bind(null, "total"),
};

const payoff_paid = {
  field: "paid",
  headerName: "Paid",
  minWidth: 80,
  renderCell: getPayoffFieldValue.bind(null, "paid"),
  valueGetter: getPayoffFieldValue.bind(null, "paid"),
};

const payoff_balance = {
  field: "balance",
  headerName: "Balance",
  minWidth: 80,
  renderCell: getPayoffFieldValue.bind(null, "balance"),
  valueGetter: getPayoffFieldValue.bind(null, "balance"),
};

const payoff_amount: GridColDef = {
  field: "amount",
  headerName: "",
  width: 140,
  disableColumnMenu: true,
  sortable: false,
  renderCell: getPayoffAmount,
  valueGetter: getPayoffFieldValue.bind(null, "amount"),
};

const new_payment_card_type = {
  field: "cardType",
  headerName: "",
  width: 80,
  renderCell: (params) => params.row?.cardType ?? "",
  valueGetter: (params) => params.row?.cardType ?? "",
};

const new_payment_card_number = {
  field: "maskedNumber",
  headerName: "",
  width: 140,
  renderCell: (params) => params.row?.maskedNumber ?? "",
  valueGetter: (params) => params.row?.maskedNumber ?? "",
};

const NewPmtDefault = (params) => {
  const { id, default: _def, _callBackFunc } = params.row;
  const formik = useFormikContext();
  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      width={"100%"}
    >
      {_def ? (
        <>
          <CheckCircleIcon sx={sxCheckIcon} />
          <Typography sx={sxCheckText}>Charging this card</Typography>
        </>
      ) : (
        <Button
          variant={"text"}
          onClick={() => formik.setFieldValue("payment_method_id", id)}
          sx={sxCheckButton}
        >
          Charge this card
        </Button>
      )}
    </Stack>
  );
};

const new_payment_default = {
  field: "default",
  headerName: "",
  flex: 1,
  renderCell: NewPmtDefault,
};

const new_payment_remove = {
  field: "remove",
  headerName: "",
  width: 60,
  renderCell: (params) => {
    const { id, personId, default: _default, _callBackFunc } = params.row;

    if (_default) return null;

    return (
      <IconButton
        color="primary"
        sx={rowToolBtnStyle}
        onClick={() => _callBackFunc && _callBackFunc("delete", personId, id)}
      >
        <DeleteIcon />
      </IconButton>
    );
  },
};

const prepayment_student_name = {
  field: "name",
  headerName: "Students",
  flex: 1,
  renderCell: (params) => {
    const { first_name = "", last_name = "" } = params.row.student;

    return `${first_name} ${last_name}`;
  },
  valueGetter: (params) => {
    const { first_name = "", last_name = "" } = params.row.student;

    return `${first_name} ${last_name}`;
  },
};

const user_active = {
  field: "active",
  headerName: "Active",
  renderCell: (params) => {
    return params.row.isActive ? "1" : "0";
  },
  valueGetter: (params) => {
    return params.row.isActive ? "1" : "0";
  },
};

const user_name_english = {
  field: "name",
  headerName: "Name",
  flex: 1.5,
  renderCell: getUserFullNameEN,
  valueGetter: (params) => {
    return params.row.name ?? "";
  },
};

const user_name_yiddish = {
  field: "name_yiddish",
  headerName: "נאמען",
  flex: 1,
  renderCell: (params) => {
    return params.row.nameYiddish ?? "";
  },
  valueGetter: (params) => {
    return params.row.nameYiddish ?? "";
  },
};

const user_home_phone = {
  field: "home_phone",
  headerName: "Home phone",
  flex: 1,
  renderCell: (params) => {
    return params.row.homePhone ?? "";
  },
  valueGetter: (params) => {
    return params.row.homePhone ?? "";
  },
};

const user_cell_phone = {
  field: "cell_phone",
  headerName: "Cell phone",
  flex: 1,
  renderCell: (params) => {
    return params.row.cellPhone ?? "";
  },
  valueGetter: (params) => {
    return params.row.cellPhone ?? "";
  },
};

const user_address = {
  field: "address",
  headerName: "Address",
  flex: 1,
  renderCell: (params) => {
    return params.row.address ?? "";
  },
  valueGetter: (params) => {
    return params.row.address ?? "";
  },
};

const user_roles = {
  field: "roles",
  headerName: "Roles",
  flex: 1,
  renderCell: (params) => {
    return params.row.roles ?? "";
  },
  valueGetter: (params) => {
    return params.row.roles ?? "";
  },
};

const prepayment_student_balance = {
  field: "balance",
  headerName: "Balance",
  renderCell: (params) => {
    return addCurrencySign(params.row.balance ?? 0, "$");
  },
  valueGetter: (params) => {
    return params.row.balance ?? 0;
  },
};

const prepay_prepayment_name = {
  field: "name",
  headerName: "",
  flex: 1,
  renderCell: (params) => {
    return params.row.name ?? "";
  },
  valueGetter: (params) => {
    return params.row.name ?? "";
  },
};

const prepay_prepayment_before = {
  field: "before",
  headerName: "Before",
  renderCell: (params) => {
    return addCurrencySign(params.row.before ?? 0, "$");
  },
  valueGetter: (params) => {
    return params.row.before ?? 0;
  },
};

const prepay_prepayment_discount = {
  field: "discount",
  headerName: "Discount",
  renderCell: (params) => {
    return addCurrencySign(params.row.discount ?? 0, "$");
  },
  valueGetter: (params) => {
    return params.row.discount ?? 0;
  },
};

const prepay_prepayment_after = {
  field: "after",
  headerName: "After",
  renderCell: (params) => {
    return addCurrencySign(params.row.after ?? 0, "$");
  },
  valueGetter: (params) => {
    return params.row.after ?? 0;
  },
};

const group_test_answer = {
  field: "answer",
  headerName: "",
  flex: 1,
  disableColumnMenu: true,
  resizable: false,
  sortable: false,
  renderCell: (params) => {
    const { index } = params.row;
    return <EditAnswerField index={index} />;
  },
};

const group_test_name = {
  field: "name",
  headerName: "שאלה ",
  width: 120,
  renderCell: (params) => {
    return params.row.question ?? "";
  },
  valueGetter: (params) => {
    return params.row.question_number ?? "";
  },
};

const settings_neighborhoods_name = {
  field: "name",
  headerName: "Name",
  flex: 1,
  renderCell: (params) => {
    return params.row.neighborhood ?? "";
  },
  valueGetter: (params) => {
    return params.row.neighborhood ?? "";
  },
};

const settings_neighborhoods_principal = {
  field: "principal",
  headerName: "מנהל",
  flex: 1,
  renderCell: (params) => {
    const first_name = params.row.principals?.first_name ?? "";
    const last_name = params.row.principals?.last_name ?? "";

    return first_name || last_name ? `${first_name} ${last_name}` : "";
  },
  valueGetter: (params) => {
    return params.row.neighborhood ?? "";
  },
};

const rosh_kinyan_name = {
  field: "name",
  headerName: "נאמען",
  width: 190,
  renderCell: (params) => {
    return params.row.user_name ?? "";
  },
  valueGetter: (params) => {
    return params.row.user_name ?? "";
  },
};

const rosh_kinyan_neighborhood = {
  field: "neighborhood",
  headerName: "Neighborhood",
  flex: 1,
  renderCell: (params) => {
    return params.row.neighborhood ?? "";
  },
  valueGetter: (params) => {
    return params.row.neighborhood ?? "";
  },
};

const rosh_kinyan_control = {
  field: "control",
  headerName: "",
  width: 100,
  resizable: false,
  sortable: false,
  editable: false,
  hideable: false,
  disableColumnMenu: true,
  renderCell: (params) => {
    const dispatch = useDispatch();

    const id = params.row.index ?? -1;

    const handleRowButtonClick = (
      type: ButtonControl,
      id: number,
      event: React.SyntheticEvent,
    ) => {
      event.stopPropagation();

      switch (type) {
        case "edit":
          dispatch(uiActions.setControl({ type, id }));
          break;

        case "remove":
          dispatch(uiActions.setControl({ type, id }));
          break;
      }
    };

    return (
      <>
        <IconButton
          color="primary"
          sx={rowToolBtnStyle}
          onClick={handleRowButtonClick.bind(null, "edit", id)}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          color="primary"
          sx={rowToolBtnStyle}
          onClick={handleRowButtonClick.bind(null, "remove", id)}
        >
          <DeleteIcon />
        </IconButton>
      </>
    );
  },
};

const curriculum_semester = {
  field: "semester",
  headerName: "זמן",
  flex: 1,
  renderCell: (params) => {
    return params.row.semester ?? "";
  },
  valueGetter: (params) => {
    return params.row.semester ?? "";
  },
};

const curriculum_subject = {
  field: "subject",
  headerName: "לימוד",
  flex: 1,
  renderCell: (params) => {
    const dispatch = useDispatch();

    const subjects = useSelector(listsSelectors.subjects);
    const subjectById = useSelector(listsSelectors.subjectById);

    const [options] = useState<Option[]>(() => {
      return subjects
        .filter((s) => s.type === "subject")
        .map((s) => {
          return {
            label: s.name_hebrew,
            value: s.id.toString(),
          } as Option;
        });
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;

      dispatch(
        uiActions.setControl({
          type: "edit",
          id: params.row.index,
          value: subjectById[value],
        }),
      );
    };

    return (
      <Select
        label={"לימוד"}
        placeholder={"לימוד"}
        options={options}
        value={params.row.subject_id ?? ""}
        onChange={handleChange}
        error={!params.row.subject_id}
      />
    );
  },
  valueGetter: (params) => {
    return params.row.subject ?? "";
  },
};

const curriculum_control = {
  field: "control",
  headerName: "",
  width: 60,
  resizable: false,
  sortable: false,
  editable: false,
  hideable: false,
  disableColumnMenu: true,
  renderCell: (params) => {
    const dispatch = useDispatch();

    const id = params.row.index ?? -1;

    const handleRowButtonClick = (
      type: ButtonControl,
      id: number,
      event: React.SyntheticEvent,
    ) => {
      event.stopPropagation();

      if (type === "remove") {
        dispatch(uiActions.setControl({ type, id }));
      }
    };

    if (!params.row.isDeletable) {
      return null;
    }

    return (
      <IconButton
        color="primary"
        sx={rowToolBtnStyle}
        onClick={handleRowButtonClick.bind(null, "remove", id)}
      >
        <DeleteIcon />
      </IconButton>
    );
  },
};

const test_extra_credit = {
  field: "extra_credit",
  headerName: "Extra Credit",
  flex: 1,
  renderCell: (params) => {
    if (!params.row.extra_credit) return "";

    return (
      <CheckCircleIcon
        sx={{ height: "16px", width: "16px", fill: "#2470bb" }}
      />
    );
  },
};

const test_answer = {
  field: "answer",
  headerName: "Correct Answer",
  flex: 1,
  renderCell: (params) => params.row.answer ?? "",
};

const TestField: FC<{ index: number; name: string }> = ({ index, name }) => {
  const { touched, errors } = useFormikContext<Fields>();

  const isError =
    touched?.tests?.[index]?.[name] && Boolean(errors?.tests?.[index]?.[name]);

  return (
    <Field
      sx={{
        ".MuiFormHelperText-root": {
          display: "none",
        },
      }}
      component={FormikTextField}
      fullWidth
      name={`tests[${index}][${name}]`}
      type={"text"}
      error={isError}
    />
  );
};

const test_revised = {
  field: "revised",
  headerName: "Revised",
  flex: 1,
  renderCell: (params) => {
    const { index } = params.row;

    return <TestField index={index} name={"revised"} />;
  },
};

const test_answered = {
  field: "answered",
  headerName: "Answered",
  flex: 1,
  renderCell: (params) => {
    const { index } = params.row;

    return <TestField index={index} name={"answered"} />;
  },
};

const test_question = {
  field: "question",
  headerName: "שאלה",
  flex: 1,
  align: "right",
  renderCell: (params) => params.row.question ?? "",
};

// prize fields
const productFields = (vendors) => ({
  status: {
    field: "active",
    headerName: "",
    type: "boolean",
    maxWidth: 10,
    renderCell: (params) => {
      return (
        <CircleIcon
          sx={{
            height: 8,
            width: 8,
            color: params.row.active ? "success.main" : "error.main",
          }}
        />
      );
    },
  },
  prizeNumber: {
    field: "prize_number",
    headerName: "Prize number",
    type: "number",
    flex: 1,
    valueGetter: (params) => params.row?.prize_number || "",
    renderCell: (params) => params.row?.prize_number || "",
  },
  name: {
    field: "name",
    headerName: "Product",
    flex: 4,
    renderCell: (params) => getPrizeName(params.row),
    valueGetter: (params) => getPrizeNameValue(params.row),
  },
  points: {
    field: "point_value",
    headerName: "Points Cost",
    type: "number",
    flex: 1,
    renderCell: (params) => getPrizePointsValue(params.row),
    valueGetter: (params) => numsWithCommasWithNeg(params.row?.point_value),
  },
  cost: {
    field: "cost",
    headerName: "Cost",
    flex: 1,
    type: "number",
    renderCell: getPrizeCost,
    valueGetter: getPrizeCost,
  },
  vendor: {
    field: "vendor_id",
    headerName: "Vendor",
    type: "number",
    flex: 2,
    renderCell: (params) => GetVendor(params, vendors),
  },
  total: {
    field: "unassigned",
    headerName: "Quantity",
    type: "number",
    flex: 1,
    valueGetter: (params) => params.row?.unassigned || 0,
  },
});

export const PRODUCT_COLUMNS: (v) => GridColDef[] = (vendors) =>
  Object.values(productFields(vendors));

// redemption fields

const RenderOpenStudent = (studentDetails) => (
  <Chip
    sx={{
      borderRadius: 0,
      margin: "2.5px 5px",
      backgroundColor: "secondary.main",
      color: "primary.main",
      minHeight: "25px",
      fontSize: "14px",
      fontWeight: "bold",
    }}
    onClick={(e) => {
      e.stopPropagation();
      openInNewTab(
        `${AdminPages.studentPage.path.replace(
          ":id",
          studentDetails.id.toString(10),
        )}`,
      );
    }}
    key={Math.random().toString(8)}
    label={
      studentDetails.first_name_yiddish + " " + studentDetails.last_name_yiddish
    }
  />
);
const redemptionFields = {
  date: {
    field: "created_at",
    headerName: "Date",
    type: "date",
    valueGetter: getDateOnly,
  },
  id: {
    field: "id",
    headerName: "Redemption ID",
    type: "number",
    renderCell: (params) => params.row.id,
    hidden: true,
  },
  points: {
    field: "point_value",
    headerName: "Points Redeemed",
    type: "number",
    sortable: false,
    renderCell: (params) => getPrizePointsValue(params.row),
    valueGetter: (params) => numsWithCommasWithNeg(params.row?.point_value),
  },
  studentPin: {
    field: "v_students_all.pin",
    headerName: "Pin",
    type: "number",
    sortable: true,
    valueGetter: (params) => params.row.v_students_all?.pin,
  },
  studentStatus: {
    field: "v_students_all.status_id",
    headerName: "Student status",
    valueGetter: (params) =>
      getStudentStatusValue({ row: params.row.v_students_all }),
  },
  studentName: {
    field: "v_students_all.last_name_yiddish",
    headerName: "Name",
    sortable: true,
    flex: 2,
    renderCell: (params) => RenderOpenStudent(params.row.v_students_all),
    valueGetter: (params) =>
      getFullNameValue({ row: params.row.v_students_all }),
  },
  chaburah: {
    field: "v_students_all.chaburah_name",
    headerName: "חבורה",
    flex: 2,
    sortable: true,
    valueGetter: (params) => params.row?.v_students_all?.chaburah_name,
  },
  pointsBalance: {
    field: "v_students_all.balance",
    headerName: "Points Balance",
    type: "number",
    valueGetter: (params) => params.row?.v_students_all?.balance,
    hidden: true,
  },
  customerName: {
    field: "v_students_all.customer_last_name",
    headerName: "Customer",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.v_students_all?.customer_last_name_yiddish || ""} ${
        params.row.v_students_all?.customer_first_name_yiddish || ""
      } ${params.row.v_students_all?.customer_first_name || ""} ${
        params.row.v_students_all?.customer_last_name || ""
      }`;
    },
    renderCell: (params) => (
      <div>
        <div>
          {params.row.v_students_all.customer_first_name_yiddish}{" "}
          {params.row.v_students_all.customer_last_name_yiddish}
        </div>

        <div>
          {params.row.v_students_all.customer_first_name}{" "}
          {params.row.v_students_all.customer_last_name}
        </div>
      </div>
    ),
  },
  homePhone: {
    field: "v_students_all.home_phone_number",
    sortable: true,
    headerName: "Phone",
    valueGetter: (params) => {
      return `${params.row.v_students_all?.home_phone_number || ""}`;
    },
  },
  cellPhone: {
    field: "v_students_all.cell_phone_number",
    sortable: true,
    headerName: "Cellphone",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.v_students_all?.cell_phone_number || ""}`;
    },
  },
  fullAddress: {
    field: "full_address",
    headerName: "Full address",
    sortable: false,
    hidden: true,
    renderCell: (params) => {
      return FormatAddress(params.row.v_students_all);
    },
    valueGetter: (params) =>
      getFullAddressValue({
        row: { address: params.row.v_students_all },
      }),
  },
  neighborhood: {
    field: "v_students_all.neighborhood",
    sortable: true,
    headerName: "Neighborhood",
    flex: 3,
    valueGetter: (params) => params.row.v_students_all?.neighborhood,
  },
  menahel: {
    field: "v_students_all.menahel",
    sortable: true,
    headerName: "מנהל",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.v_students_all?.menahel}`;
    },
  },
  roshKinyan: {
    field: "v_students_all.rosh_kinyan",
    sortable: true,
    headerName: "ראש קנין",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.v_students_all?.rosh_kinyan || ""}`;
    },
  },

  quantity: {
    field: "quantity",
    headerName: "Items",
    type: "number",
    sortable: false,
  },

  status: {
    field: "delivered_status",
    headerName: "Status",
    flex: 2,
    sortable: true,
    type: "singleSelect",
    valueOptions: ["Delivered", "Partially delivered", "Pending"],
    renderCell: (params) => {
      const { delivered_status } = params.row;
      return <StatusCell status={delivered_status} />;
    },
  },
  actions: {
    field: "actions",
    type: "actions",
    renderCell: (params) => (
      <IconButton
        onClick={() => {
          openInNewTab(`/admin/prizes/redemption/${params.row.id}`);
        }}
      >
        {" "}
        <LaunchIcon />
      </IconButton>
    ),
  },
};

const StatusCell = ({ status }) => {
  let color = "";
  switch (status) {
    case "Delivered":
      color = "#128E56";
      break;
    case "Partially Delivered":
      color = "#9FB4CB";
      break;
    case "Pending":
    default:
      color = "#F0921F";
      break;
  }

  return (
    <Box display="inline-flex" alignItems="center" paddingRight="10px">
      <Box
        color={color}
        fontSize="12px"
        display="inline-flex"
        marginRight="10px"
      >
        <DotIcon fontSize="inherit" />
      </Box>{" "}
      <Box>{status}</Box>
    </Box>
  );
};
const getStudentRedemptionStatus = (params) => {
  let { delivered_count } = params.row;
  const { redemption_products } = params.row;

  delivered_count = parseInt(delivered_count);
  let status = "Pending";
  if (delivered_count > 0) {
    if (delivered_count < redemption_products.length) {
      status = "Partially delivered";
    } else if (delivered_count === redemption_products.length) {
      status = "Delivered";
    }
  }
  return status;
};

export const STUDENTS_REDEMPTIONS_COLUMNS = [
  { ...redemptionFields.id, headerName: "ID" },
  redemptionFields.date,
  { headerName: "For", flex: 3, field: "for" },
  redemptionFields.quantity,
  { ...redemptionFields.points, flex: 2 },
  {
    field: "delivered_count",
    headerName: "Status",
    flex: 2,
    sortable: true,
    type: "singleSelect",
    valueOptions: ["Delivered", "Partially delivered", "Pending"],
    valueGetter: (params) => getStudentRedemptionStatus(params),
    renderCell: (params) => {
      return <StatusCell status={getStudentRedemptionStatus(params)} />;
    },
  },
];
const redemptionItemFields = {
  date: {
    field: "redemption.created_at",
    sortable: true,
    headerName: "Date",
    type: "date",
    valueGetter: (params) => _formatDate(params.row.redemption?.created_at),
  },
  redemption: {
    field: "redemption_id",
    headerName: "Redemption",
    type: "number",
    hidden: true,
    renderCell: (params) => params.row.redemption_id,
  },
  prizeStatus: {
    field: "product.active",
    headerName: "Prize status",
    type: "boolean",
    valueGetter: (params) => !!params.row.product?.active,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.product?.active ? "Active" : "Inactive"}>
          <CircleIcon
            sx={{
              height: 8,
              width: 8,
              color: params.row.product?.active ? "success.main" : "error.main",
            }}
          />
        </Tooltip>
      );
    },
  },
  prize: {
    field: "product.prize_number",
    headerName: "Prize number",
    sortable: true,
    type: "number",
    valueGetter: (params) => params.row.product.prize_number,
    renderCell: (params) => (
      <Button
        variant="outlined"
        size="small"
        sx={{ fontSize: "13px" }}
        onClick={() => {
          openInNewTab(`/admin/prizes/redemption/${params.row.redemption_id}`);
        }}
      >
        {params.row.product.prize_number}
      </Button>
    ),
  },

  products: {
    field: "product.name_yiddish",
    sortable: true,
    headerName: "Product",
    flex: 2,
    renderCell: (params) => getPrizeName(params.row.product),
    valueGetter: (params) => getPrizeNameValue(params.row.product),
  },
  points: {
    field: "point_value",
    headerName: "Points Redeemed",
    type: "number",
    renderCell: (params) => getPrizePointsValue(params.row),
    valueGetter: (params) => numsWithCommasWithNeg(params.row?.point_value),
  },
  studentPin: {
    field: "redemption->v_students_all.pin",
    headerName: "Pin",
    type: "number",
    sortable: true,
    valueGetter: (params) => params.row.redemption?.v_students_all?.pin,
  },
  studentStatus: {
    field: "redemption->v_students_all.status_id",
    headerName: "Student status",
    // boolean display better, if we change this, we would need styling
    type: "boolean",
    valueGetter: (params) =>
      getBooleanStatusFromId({ row: params.row.redemption.v_students_all }),
    renderCell: (params) =>
      StudentStatus({ row: params.row.redemption.v_students_all }),
  },

  studentName: {
    field: "redemption->v_students_all.last_name_yiddish",
    headerName: "Name",
    flex: 2,
    sortable: true,
    renderCell: (params) =>
      RenderOpenStudent(params.row.redemption.v_students_all),
    valueGetter: (params) =>
      getFullNameValue({ row: params.row.redemption.v_students_all }),
  },
  chaburah: {
    field: "redemption->v_students_all.chaburah_name",
    headerName: "חבורה",
    sortable: true,
    flex: 2,
    valueGetter: (params) =>
      params.row.redemption?.v_students_all?.chaburah_name,
  },
  pointsBalance: {
    field: "redemption->v_students_all.balance",
    headerName: "Points balance",
    type: "number",
    valueGetter: (params) => params.row?.redemption?.v_students_all?.balance,
    hidden: true,
  },
  customerNameYiddish: {
    field: "redemption->v_students_all.customer_last_name_yiddish",
    headerName: "Customer נאמען",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.customer_first_name_yiddish} ${params.row.redemption?.v_students_all?.customer_last_name_yiddish}`;
    },
  },
  homePhone: {
    field: "redemption->v_students_all.home_phone_number",
    sortable: true,
    headerName: "Home phone",
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.home_phone_number || ""}`;
    },
  },
  cellPhone: {
    field: "redemption->v_students_all.cell_phone_number",
    sortable: true,
    headerName: "Cell phone",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.cell_phone_number || ""}`;
    },
  },
  fullAddress: {
    field: "full_address",
    headerName: "Full address",
    sortable: false,
    hidden: true,
    renderCell: (params) => {
      return FormatAddress(params.row.redemption.v_students_all);
    },
    valueGetter: (params) =>
      getFullAddressValue({
        row: { address: params.row.redemption.v_students_all },
      }),
  },

  neighborhood: {
    field: "redemption->v_students_all.neighborhood",
    headerName: "Neighborhood",
    sortable: true,
    flex: 3,
    valueGetter: (params) => params.row.redemption.v_students_all?.neighborhood,
  },
  status: {
    field: "delivered",
    headerName: "Redemption status",
    type: "singleSelect",
    valueOptions: ["Delivered", "Pending"],
    flex: 2,
    renderCell: (params) => {
      const { delivered } = params.row;

      let status = "";
      if (delivered) {
        status = "Delivered";
      } else {
        status = "Pending";
      }
      return <StatusCell status={status} />;
    },
    valueGetter: (params) => {
      const { delivered } = params.row;
      return delivered ? "Delivered" : "Pending";
    },
  },
  menahel: {
    field: "redemption->v_students_all.menahel",
    sortable: true,
    headerName: "מנהל",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.menahel}`;
    },
  },
  roshKinyan: {
    field: "redemption->v_students_all.rosh_kinyan",
    sortable: true,
    headerName: "ראש קנין",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.rosh_kinyan || ""}`;
    },
  },
  schoolBuilding: {
    field: "redemption->v_students_all.school_building_name",
    sortable: true,
    headerName: "School building",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.school_building_name}`;
    },
  },
  school: {
    field: "redemption->v_students_all.school_name",
    sortable: true,
    headerName: "School",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.school_name}`;
    },
  },
  amount: {
    field: "amount",
    headerName: "Amount",
    type: "number",
    hidden: true,
    valueGetter: (params) => params.row.amount,
  },
  nameYiddish: {
    field: "redemption->v_students_all._full_name_yiddish",
    headerName: "נאמען",
    filterable: false,
    sortable: false,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all.first_name_yiddish} ${params.row.redemption?.v_students_all?.last_name_yiddish}`;
    },
  },

  address: {
    field: "redemption->v_students_all.address",
    headerName: "Address",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.address || ""}`;
    },
  },
  address_2: {
    field: "redemption->v_students_all.address_2",
    headerName: "Address 2",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.address_2 || ""}`;
    },
  },
  city: {
    field: "redemption->v_students_all.city",
    headerName: "City",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.city || ""}`;
    },
  },
  state: {
    field: "redemption->v_students_all.state",
    headerName: "State",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.state || ""}`;
    },
  },
  zip: {
    field: "redemption->v_students_all.zip",
    headerName: "Zip",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.zip || ""}`;
    },
  },
  country: {
    field: "redemption->v_students_all.country",
    headerName: "Country",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.country || ""}`;
    },
  },
  email: {
    field: "redemption->v_students_all.email",
    sortable: true,
    headerName: "Email",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.email || ""}`;
    },
  },
  grade: {
    field: "redemption->v_students_all.grade",
    sortable: true,
    headerName: "Grade",
    hidden: true,
    valueGetter: (params) => {
      const grade = params.row.redemption.v_students_all?.grade;
      const val = grade ? gematriya(grade.toString()) : "";
      return val;
    },
  },
  monthStart: {
    field: "redemption->v_students_all.month_start",
    headerName: "Date joined",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.month_start || ""}`;
    },
  },

  monthReturn: {
    field: "redemption->v_students_all.month_return",
    headerName: "Date rejoined",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all?.month_return || ""}`;
    },
  },
  firstName: {
    field: "redemption->v_students_all.first_name",
    headerName: "First name",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption?.v_students_all?.first_name}`;
    },
  },
  lastName: {
    field: "redemption->v_students_all.last_name",
    headerName: "First name",
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption?.v_students_all?.last_name}`;
    },
  },

  lastNameYiddish: {
    field: "redemption->v_students_all.last_name_yiddish_",
    headerName: "לעצטע",
    filterable: false,
    sortable: true,
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption?.v_students_all?.last_name_yiddish}`;
    },
  },
  firstNameYiddish: {
    field: "redemption->v_students_all.first_name_yiddish",
    headerName: "ערשטע",
    hidden: true,
    sortable: true,
    valueGetter: (params) => {
      return `${params.row.redemption.v_students_all.first_name_yiddish}`;
    },
  },
  paymentMethod: {
    field: "redemption->v_students_all.default_payment_method",
    headerName: "Payment Method",
    flex: 1,
    type: "singleSelect",
    hidden: true,
    valueOptions: PAYMENT_METHOD,
  },
  track: {
    field: "redemption->v_students_all.track",
    headerName: "קנין",
    hidden: true,
    valueGetter: (params) => {
      return `${params.row.redemption?.v_students_all?.track}`;
    },
    sortable: true,
  },
};

export const REDEMPTION_ITEM_COLUMNS: GridColDef[] =
  Object.values(redemptionItemFields);
export const REDEMPTION_ITEM_HIDDEN_COLUMNS: GridColDef[] =
  REDEMPTION_ITEM_COLUMNS.filter((c: any) => c.hidden);

export const REDEMPTION_COLUMNS: GridColDef[] = Object.values(redemptionFields);
export const REDEMPTION_HIDDEN_COLUMNS: GridColDef[] =
  REDEMPTION_COLUMNS.filter((r: any) => r.hidden);

export const CUSTOMERS_COLUMNS: GridColDef[] = [
  status_id,
  fullName,
  first_name,
  last_name,
  last_name_yiddish,
  first_name_yiddish,
  homePhone,
  cellPhone,
  full_address,
  country,
  students,
  address,
  address_2,
  city,
  state,
  zip,
  completed,
  email,
  balance,
  notes,
  {
    ...launch_icon,
    renderCell: (rowData) => getLaunchLink("customers", rowData.id),
    valueGetter: (rowData) =>
      `${REACT_APP_BASE_URL}/admin/customer/${rowData.id}`,
  },
];

export const STUDENT_ADDRESS_FIELDS: GridColDef[] = [
  full_student_address,
  address_field,
  address_two_field,
  city_field,
  state_field,
  zip_field,
  country_name_field,
];

export const STUDENT_MONTH_COLUMNS: (p) => GridColDef[] = (
  openAddMonthlyPointsModal,
) => [
  student_status_id,
  pin,
  fullName,
  fullNameYiddish,
  fullNameEnglish,
  first_name,
  last_name,
  last_name_yiddish,
  first_name_yiddish,
  student_customer,
  student_home_phone,
  student_cell_phone,
  // student_chaburah,
  // student_kinyan,
  // rosh_kinyan,
  grade_yiddish,
  // student_neighborhood,
  student_test,
  student_review,
  student_attendance,
  student_total,
  student_points,
  student_gathering,
  student_check_in,
  call_date,
  call_date_yiddish,
  do_not_call,
  do_not_call_summer,
  monthly_note(openAddMonthlyPointsModal),
  full_customer_address,
  student_customer_address,
  student_customer_address_two,
  student_customer_city,
  student_customer_state,
  student_customer_zip,
  defaultPaymentMethod,
];

export const STUDENT_LOGS_COLUMNS: GridColDef[] = [
  student_log_status_id,
  student_log_entered_on,
  student_log_entered_by,
];

export const STUDENT_HISTORY_COLUMNS: GridColDef[] = [
  student_history_semester,
  student_history_grade,
  student_history_chabura_name,
  student_history_track,
  student_chabura_type,
  student_location,
  student_history_siyum,
  student_history_transportation,
  student_curriculum,
];

export const STUDENT_REWARD_COLUMNS: GridColDef[] = [
  student_rewards_date,
  student_rewards_for,
  student_rewards_reason,
  student_rewards_amount,
  student_rewards_control,
];

export const STUDENT_SEMESTER_HISTORY_COLUMNS: GridColDef[] = [
  student_semester_history_month,
  student_semester_history_note,
  student_semester_history_subject_name,
  student_semester_history_group,
  student_semester_history_phone_call,
  student_semester_history_gathering,
  student_semester_history_price,
  student_semester_history_review,
  student_semester_history_attendance,
  student_semester_history_test,
  student_semester_history_bonus,
  student_semester_history_total,
];
export const CUSTOMER_BILLING_HISTORY: GridColDef[] = [
  id,
  billingDate,
  chodesh,
  transactionType,
  getDescription,
  charges,
  deductions,
  paid,
  paymentStatus,
  editPayment,
  created_at,
];
export const CUSTOMER_LEGACY_INVOICES: GridColDef[] = [
  id,
  date,
  chodesh,
  legacyTransactionType,
  getDescription,
  paymentMethod,
  charges,
];
export const CUSTOMER_LEGACY_PAYMENT: GridColDef[] = [
  id,
  date,
  type,
  paymentMethod,
  identifier,
  legacyDescription,
  legacyPaymentAmount,
];
export const BILLING_HISTORY: GridColDef[] = [
  id,
  billingDate,
  chodesh,
  transactionType,
  customer,
  getDescription,
  charges,
  deductions,
  paid,
  created_at,
];

export const STUDENT_TEST_ANSWERS: GridColDef[] = [
  extra_credit,
  answer,
  revised,
  given,
  question_number,
];

export const STUDENT_SAVED_CARDS: GridColDef[] = [
  student_saved_card_type,
  student_saved_card_number,
];

export const GROUPS_COLUMNS: GridColDef[] = [
  group_status,
  group_name,
  group_type,
  group_track,
  group_location,
  group_neighborhood,
  group_students,
];

export const GROUP_STUDENTS_COLUMNS: GridColDef[] = [
  group_student_name,
  group_student_pin,
  group_student_phone,
  group_student_neighborhood,
  group_student_teacher,
  group_student_customer,
  group_student_address,
  group_student_points,
];

export const GROUP_CURRICULUM_COLUMNS: GridColDef[] = [
  group_curriculum_semester,
  group_curriculum_subject,
];

export const PAYOFF_COLUMNS: GridColDef[] = [
  payoff_month,
  payoff_total,
  payoff_paid,
  payoff_balance,
  payoff_amount,
];

export const NEW_PAYMENT_CARDS: GridColDef[] = [
  new_payment_card_type,
  new_payment_card_number,
  new_payment_default,
  new_payment_remove,
];

export const EDIT_PAYMENT_SETTINGS_COLUMNS: GridColDef[] = [
  new_payment_card_type,
  new_payment_card_number,
  new_payment_default,
  new_payment_remove,
];

export const PREPAYMENT_COLUMNS: GridColDef[] = [
  prepayment_student_name,
  prepayment_student_balance,
];

export const PREPAY_PREPAYMENT_COLUMNS: GridColDef[] = [
  prepay_prepayment_name,
  prepay_prepayment_before,
  prepay_prepayment_discount,
  prepay_prepayment_after,
];

export const USERS_LIST_COLUMNS: GridColDef[] = [
  user_name_english,
  user_name_yiddish,
  user_home_phone,
  user_cell_phone,
  user_address,
  user_roles,
  user_active,
  // user_permissions,
];

export const GROUP_TEST_COLUMNS: GridColDef[] = [
  group_test_answer,
  group_test_name,
];

export const SETTINGS_NEIGHBORHOODS_COLUMNS: GridColDef[] = [
  settings_neighborhoods_name,
  settings_neighborhoods_principal,
];

export const ROSH_KINYAN_COLUMNS: GridColDef[] = [
  rosh_kinyan_name,
  rosh_kinyan_neighborhood,
  rosh_kinyan_control,
];

export const CURRICULUM_COLUMNS: GridColDef[] = [
  curriculum_semester,
  curriculum_subject,
  curriculum_control,
];

export const TEST_COLUMNS: GridColDef[] = [
  test_extra_credit,
  test_answer,
  test_revised,
  test_answered,
  test_question,
];
