import { boolean, object, string } from "yup";
import { capitalizeFirstLetterOfEachWOrd, normalizePhone } from "../../../lib";

export const studentValidation = object().shape({
  first_name_yiddish: string()
    .required("Required")
    .test(
      "first_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("ערשטע נאמען"),
  last_name_yiddish: string()
    .required("Required")
    .test(
      "last_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("לעצטע נאמען"),
  first_name: string().required("Required"),
  last_name: string().required("Required"),
  school_building_id: string().when("school", {
    is: (v) => !!v,
    then: string().required("Building is required"),
  }),
  grade: string().required(),
  birth_month_id: string().required("required"),
  year: string().required("required"),
  birth_month_day: string().required("required"),
  month_id: string().required("required"),
});

const intOrUndefined = (val) => {
  return val ? parseInt(val) : undefined;
};

export const customerValidation = object().shape({
  first_name_yiddish: string()
    .required("Required")
    .test(
      "first_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("ערשטע נאמען"),
  last_name_yiddish: string()
    .required("Required")
    .test(
      "last_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("לעצטע נאמען"),
  last_name: string().required("required"),
  home_phone: string()
    .required("required")
    .test(
      "home_phone",
      "Invalid phone number",
      (v, context) =>
        !!normalizePhone(+context.parent.home_country_code_id, v || "", "home"),
    ),
  home_country_code_id: string().required("required").typeError("required"),
  mobile_phone: string()
    .required("required")
    .test(
      "mobile_phone",
      "Invalid phone number",
      (v, context) =>
        !!normalizePhone(
          +context.parent.mobile_country_code_id,
          v || "",
          "cell",
        ),
    ),
  mobile_country_code_id: string().when("mobile_phone", {
    is: (val) => val?.length > 0,
    then: string().required("required").typeError("required"),
    otherwise: string().nullable().notRequired(),
  }),
  email: string().required("required"),
  state: string().when("country_id", {
    is: (val) => val === "1" || val === "2",
    then: string().required("required").typeError("required"),
    otherwise: string().notRequired(),
  }),
  address: string().required("required"),
  city: string().required("required"),
  zip: string().nullable().label("Zip"),
  country_id: string().required("required"),
  currency: string().required("required"),
  neighborhood_id: string().nullable().required("required"),
  terms: boolean().required().oneOf([true], "You must accept terms"),
  cardData: object().required("required"),
});

export function buildStudentData(values) {
  const {
    school_building_id,
    grade,
    birth_month_id,
    birth_month_day,
    month_id,
    year_id,
    school,
    chaburah_type_id,
    location,
    year: _year,
    subject,
    ...restValues
  } = values;

  return {
    ...restValues,
    school_building_id: intOrUndefined(school_building_id),
    grade: intOrUndefined(grade),
    birth_month_id: intOrUndefined(birth_month_id),
    birth_month_day: intOrUndefined(birth_month_day),
    year_id: intOrUndefined(year_id),
    school: intOrUndefined(school),
    month_id: intOrUndefined(month_id),
    notes: values.notes?.trim() || null,
    chaburah_type_id,
    subject: chaburah_type_id === 2 ? subject : undefined,
    location: chaburah_type_id === 1 ? location : undefined,
  };
}
export function buildCustomerData(values) {
  return {
    ...values,
    email: values.email || null,
    first_name: capitalizeFirstLetterOfEachWOrd(values.first_name) || null,
    last_name: capitalizeFirstLetterOfEachWOrd(values.last_name) || null,
    city: capitalizeFirstLetterOfEachWOrd(values.city) || null,
    home_phone: values.home_phone || null,
    mobile_phone: values.mobile_phone || null,
    address_2: values.address_2 || null,
    state: values.state || null,
    zip: values.zip.toUpperCase(),
  };
}
export const customerInitialState = {
  first_name_yiddish: "",
  last_name_yiddish: "",
  first_name: "",
  last_name: "",
  home_phone: "",
  home_country_code_id: 1,
  mobile_phone: "",
  mobile_country_code_id: 1,
  email: "",
  address: "",
  address_2: "",
  city: "",
  state: "NY",
  zip: "",
  country_id: 1,
  neighborhood_id: "",
  currency: "",
  terms: false,
  cardData: "",
};

export const studentInitialState = {
  first_name_yiddish: "",
  last_name_yiddish: "",
  first_name: "",
  last_name: "",
  school: "",
  school_building_id: "",
  grade: "",
  year: "",
  birth_month_id: "",
  birth_month_day: "",
  month_id: "",
  year_id: "",
  chaburah_type_id: 1,
  notes: "",
  location: "",
  subject: "",
};
