import AdminPages from "../index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  adminActions,
  adminSelectors,
  GroupCurriculum,
  GroupValues,
  listsSelectors,
  Question,
  Season,
  UpdateGroupValues,
  UpdateTestQuestion,
} from "../../../state";
import { Option } from "../../../components/Select/Select";
import { gematriya } from "../../../lib";
import { Fields } from "./schema";
import { FormikHelpers, FormikProps } from "formik";
import { format } from "date-fns";

interface MonthItem {
  id: number;
  name: string;
  group: string;
}

export function useGroupSettingsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const innerRef = useRef<FormikProps<Fields>>(null);
  const currMonthId = useRef<number | null>(null);

  const _groups = useSelector(adminSelectors.groups);
  const _months = useSelector(listsSelectors.months);
  const semesters = useSelector(listsSelectors.semesterById);

  const [groups, setGroups] = useState<Option[]>([]);
  const [groupId, setGroupId] = useState<string>("");
  const [monthId, setMonthId] = useState<string>("");
  const [months, setMonths] = useState<MonthItem[]>([]);

  const handleBackButtonClick = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      navigate(AdminPages.groups.path);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSelectGroup = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      setGroupId(e.target.value ?? "");
    },
    [],
  );

  const handleSelectMonth = (
    id: string,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation();
    setMonthId(id);
  };

  const handleSubmit = async (
    {
      id,
      description,
      test_questions,
      max_review_checks,
      review_bonus,
      max_attendance_checks,
      attendance_bonus,
      charge_date,
      test_id,
      chaburah_month_id,
      test_date,
    }: Fields,
    { setSubmitting }: FormikHelpers<Fields>,
  ) => {
    const data: UpdateGroupValues = {
      id,
      description: description!,
      max_review_checks: max_review_checks ? +max_review_checks : null,
      review_bonus: review_bonus ? +review_bonus : null,
      max_attendance_checks: max_attendance_checks
        ? +max_attendance_checks
        : null,
      attendance_bonus: attendance_bonus ? +attendance_bonus : null,
      charge_date: charge_date
        ? typeof charge_date === "string"
          ? charge_date.replace("/", "-")
          : format(charge_date, "yyyy-MM-dd")
        : null,
      tests: [],
    };

    if (test_questions?.length) {
      data.tests = [
        {
          id: test_id!,
          //if chaburah month id returns nill maybe try data.id
          chaburah_month_id: chaburah_month_id!,
          test_date: test_date ? format(test_date, "yyyy-MM-dd") : null,
          count_questions: null,
          max_bonus: null,
          threshold: null,
          question_value: null,
          extra_credit_value: null,
          looked_in_value: null,
          max_looked_in: null,
          revised_question_value: null,
          max_revised: null,
          name: null,
          test_questions:
            test_questions?.map((t) => {
              return {
                test_id: t.test_id,
                answer: +t.answer,
                question_number: +t.question_number,
                extra_credit: t.extra_credit,
              } as UpdateTestQuestion;
            }) ?? null,
        },
      ];
    }

    const isOk = await dispatch<
      boolean | ReturnType<typeof adminActions.updateChaburahValuesByMonth>
    >(adminActions.updateChaburahValuesByMonth(data.id, data));

    setSubmitting(false);

    isOk && handleBackButtonClick();
  };

  const handleAddNewTest = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const test_questions = JSON.parse(
      JSON.stringify(innerRef.current?.values.test_questions ?? []),
    ) as Question[];

    const test_id = innerRef.current?.values.test_id!;

    const question_number = test_questions.length
      ? Math.max(...test_questions.map((q) => q.question_number)) + 1
      : 1;

    test_questions.push({
      index: test_questions.length,
      extra_credit: false,
      answer: "",
      test_id,
      id: -question_number, // Grid needs unique id
      question_number,
      question: `שאלה ${gematriya(question_number.toString())}`,
    });

    innerRef.current?.setFieldValue("test_questions", test_questions);
  };

  useEffect(() => {
    (async () => {
      const monthId = await dispatch<
        number | ReturnType<typeof adminActions.getCurrentMonth>
      >(adminActions.getCurrentMonth());

      if (monthId) {
        currMonthId.current = monthId as unknown as number;
      }

      dispatch(adminActions.getGroups());
    })();
  }, [dispatch]);

  useEffect(() => {
    if (_groups) {
      const ng = _groups.map((g) => {
        return {
          label: g.name,
          value: g.id.toString(),
        } as Option;
      });
      setGroups(ng);
      setGroupId(ng?.[0]?.value ?? "");
    }
  }, [_groups]);

  useEffect(() => {
    if (groupId) {
      (async () => {
        setMonths([]);
        const cc = await dispatch(adminActions.getChaburahCurriculum(groupId));

        const tempMonthItems: MonthItem[] = [];
        (cc as unknown as GroupCurriculum[]).forEach((gc) => {
          const m = _months
            .filter((mi) => mi.semester_id === gc.semester_id)
            .map((mi) => {
              const year = mi.name === "Tishrei" ? mi.year_id - 1 : mi.year_id;
              return {
                id: mi.id,
                // name: `${mi.hebrew} (${mi.name} ${mi.year_id}:${mi.id}:${mi.semester_id})`,
                name: mi.hebrew,
                group: `${gematriya(year.toString()).slice(2)} ${
                  Season[semesters[mi.semester_id].name] ?? ""
                }`,
              } as MonthItem;
            });

          tempMonthItems.push(...m);
        });

        let currGroup = "";
        const monthList: typeof tempMonthItems = [];

        for (const monthItem of tempMonthItems) {
          if (monthItem.group !== currGroup) {
            currGroup = monthItem.group;

            monthList.push({
              id: 0,
              name: currGroup,
              group: "",
            });
          }

          monthList.push({
            id: monthItem.id,
            name: monthItem.name,
            group: "",
          });
        }

        setMonths(monthList);

        let newMonthId = "";
        if (
          currMonthId.current &&
          monthList.some((m) => m.id === currMonthId.current)
        ) {
          newMonthId = currMonthId.current.toString();
        } else {
          newMonthId = monthList?.[1]?.id.toString() ?? "";
        }

        if (monthId === newMonthId) {
          getChaburahMonthSettings();
        } else {
          // monthid useeffect will getChaburahMonthSettings
          setMonthId(newMonthId);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    getChaburahMonthSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthId]);

  const getChaburahMonthSettings = async () => {
    if (groupId && monthId) {
      const cc = await dispatch(
        adminActions.getChaburahValuesByMonth(groupId, monthId),
      );

      if (cc as unknown as GroupValues) {
        const {
          id,
          description,
          max_review_checks,
          review_bonus,
          max_attendance_checks,
          attendance_bonus,
          charge_date,
          tests,
        } = cc as unknown as GroupValues;
        const data: Fields = {
          id,
          currMonth:
            months.filter((m) => m.id.toString() === monthId)?.[0]?.name ?? "",
          description: description ?? "",
          max_review_checks: max_review_checks?.toString() ?? "",
          review_bonus: review_bonus?.toString() ?? "",
          max_attendance_checks: max_attendance_checks?.toString() ?? "",
          attendance_bonus: attendance_bonus?.toString() ?? "",
          charge_date: charge_date ? charge_date.replace("-", "/") : null,
          test_date: tests?.[0]?.test_date
            ? new Date(tests?.[0]?.test_date.replace("-", "/"))
            : null,
          test_id: tests?.[0]?.id ?? null,
          chaburah_month_id: tests?.[0]?.chaburah_month_id ?? null,
          test_questions:
            tests?.[0]?.test_questions
              ?.sort((a, b) => a.question_number - b.question_number)
              ?.map(
                (
                  { id, test_id, answer, extra_credit, question_number },
                  index,
                ) => {
                  return {
                    id,
                    index,
                    test_id,
                    answer: String(answer),
                    extra_credit,
                    question_number,
                    question: `שאלה ${gematriya(question_number.toString())}`,
                  } as Question;
                },
              ) ?? [],
        };

        innerRef?.current?.resetForm();
        innerRef?.current?.setValues(data);
      } else {
        innerRef?.current?.resetForm();
      }
    }
  };

  return {
    innerRef,
    groups,
    groupId,
    months,
    monthId,
    handleBackButtonClick,
    handleSelectGroup,
    handleSelectMonth,
    handleSubmit,
    handleAddNewTest,
  };
}
