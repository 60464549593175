import React, { FC, useState, useCallback, useMemo } from "react";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import {
  bottomSectionStyle,
  topSectionStyle,
} from "../SettingsPage/SettingsPage.styles";
import SimplePageHeader from "../../../components/SimplePageHeader";
import { Tab, Tabs, Grid } from "@mui/material";
import { Products } from "./Products";
import { Redemptions } from "./Redemptions";
import { RedemptionItems } from "./RedemptionItems";
import { AddPrizeButton } from "../components/AddPrizeButton";
import { useLocation } from "../../../lib";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../state";

type TabType = "products" | "redemptions" | "redemptionItems";

const PrizesPage: FC = () => {
  const isLimited = useSelector(authSelectors.isLimited);
  const hideForRK = isLimited ? { display: "none" } : {};
  const location = useLocation();
  const tabFromQuery = location?.query?.tab;
  const navigate = useNavigate();

  const [tab, setTab] = useState<TabType>(
    (tabFromQuery as TabType) || "products",
  );

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, value: TabType) => {
      event.stopPropagation();
      navigate(`${location.pathname}?tab=${value}`);
      setTab(value);
    },
    [location.pathname, navigate],
  );

  const TabComponent = useMemo(() => {
    switch (tab) {
      case "products":
        return <Products />;

      case "redemptions":
        return <Redemptions />;
      case "redemptionItems":
        return <RedemptionItems />;
    }
  }, [tab]);

  return (
    <Page>
      <TopSection style={topSectionStyle}>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <SimplePageHeader title={"Prizes"} />
          </Grid>
          <Grid item>
            <AddPrizeButton
              id={""}
              onEditClick={() => {}}
              fromCustomer={true}
              onDoARefundClick={() => {}}
            />
          </Grid>
        </Grid>
      </TopSection>

      <BottomSection style={bottomSectionStyle}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Products" sx={hideForRK} value={"products" as TabType} />
          <Tab label="Redemptions" value={"redemptions" as TabType} />
          <Tab label="Redemption Items" value={"redemptionItems" as TabType} />
        </Tabs>

        {TabComponent}
      </BottomSection>
    </Page>
  );
};

export default PrizesPage;
