import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const container: SxProps<Theme> = {
  width: "720px",
  pb: "60px",
  borderBottom: "1px solid #D5D8EC",
};

export const btnStyle: SxProps<Theme> = {
  "& .MuiButtonGroup-grouped": {
    minWidth: "140px",
  },
};

export const amountStyle: SxProps<Theme> = {
  width: "600px",
  border: "1px solid #DDE3EB",
  borderRadius: 1,
  backgroundColor: "primary.contrastText",
  p: "16px 24px",

  "& > .MuiTypography-root.caption": {
    color: "#B4B8C8",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
  },

  "& > .MuiFormControl-root": {
    mb: 0,

    "& .MuiInputBase-root": {
      "& > .MuiInputAdornment-root": {
        "& .MuiSvgIcon-root": {
          height: "32px",
          width: "32px",
          fill: "#222C64",
        },
      },

      "& > .MuiInputBase-input": {
        color: "#222C64",
        fontSize: "32px",
        letterSpacing: "2.43px",
        lineHeight: "38px",
        textAlign: "right",
        py: 0,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },

      "&.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "error.main",
        },
      },
    },
  },
};

export const gridItem: SxProps<Theme> = {
  width: "calc(50% - 20px)",
};

// dirty hack - could not change styles directly
export const formControl: SxProps<Theme> = {
  "& .MuiFormControl-root": {
    my: 0,
  },
};

export const savedCards = (hasCards: boolean): SxProps<Theme> => {
  return {
    minHeight: hasCards ? "unset" : "152px",
    mb: "25px",
    borderTop: "1px solid #D5D8EC",
    borderBottom: "1px solid #D5D8EC",

    "& > .MuiTypography-root": {
      color: "rgba(0,0,0,0.78)",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "0.17px",
      lineHeight: "22px",
      textAlign: "center",
    },
  };
};

export const gridSx: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    border: "unset",

    "& > .MuiDataGrid-columnHeaders": {
      display: "none",
    },
    "& .MuiDataGrid-virtualScroller": {
      marginTop: "0!important",
    },
  },
};
