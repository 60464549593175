import React, { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import {
  pointsRowStyle,
  pointsStyle,
  pointsRowLines,
} from "./PointsDetails.styles";

interface PointsCardProps {
  header: string;
  secondary?: string;
  points: string;
  reason?: string;
  outOf?: any;
}

const PointsDetails: FC<PointsCardProps> = ({
  header,
  points,
  secondary,
  outOf,
  reason,
}) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={pointsRowLines}
    >
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        sx={pointsRowStyle}
      >
        {reason ? (
          <>
            <Tooltip title={reason || ""} placement="bottom">
              <Typography variant={"body1"}>{header}</Typography>
            </Tooltip>
          </>
        ) : (
          <Typography variant={"body1"}>{header}</Typography>
        )}
        {outOf && (
          <Typography variant={"caption"}>{`Out of ${outOf}`}</Typography>
        )}
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        {secondary && <Typography variant={"body1"}>{secondary}</Typography>}
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography variant={"body2"} component={"span"} sx={pointsStyle}>
          {points}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PointsDetails;
