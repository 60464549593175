import { useEffect, useState } from "react";
import { useFormik } from "../../../lib";
import {
  useSelector,
  adminSelectors,
  customerSelectors,
  adminActions,
  listsSelectors,
  useDispatch,
  customerActions,
} from "../../../state";
import { useTabEnrollment } from "./TabEnrollment/useTabEnrollment";
import {
  StudentBillingFields,
  StudentEnrollmentFields,
  StudentGeneralFields,
  initStudentBillingState,
  initStudentEnrollmentState,
  initStudentGeneralState,
  validationStudentBillingSchema,
  validationStudentEnrollmentSchema,
  validationStudentGeneralSchema,
} from "./schema";
import { buildCustomerOption } from "./utils";
import { Option } from "../../../components/Select/Select";
import { getYiddishDate, yiddishDays } from "../../../lib";

export function useReactivateStudentModal() {
  const dispatch = useDispatch();
  const student = useSelector(adminSelectors.student);
  const currentMonth = useSelector(adminSelectors.currentMonthId);
  const [yiddishDate, setYiddishDate] = useState<string>();
  const formikEnrollment = useFormik<StudentEnrollmentFields>({
    initialValues: initStudentEnrollmentState,
    enableReinitialize: true,
    validationSchema: validationStudentEnrollmentSchema,
    onSubmit: () => {},
  });
  const formikGeneral = useFormik<StudentGeneralFields>({
    initialValues: initStudentGeneralState,
    enableReinitialize: true,
    validationSchema: validationStudentGeneralSchema,
    onSubmit: () => {},
  });

  const enrollmentHooks = useTabEnrollment(
    formikEnrollment,
    formikGeneral.values.customer_id,
    "edit",
  );
  const formikBilling = useFormik<StudentBillingFields>({
    initialValues: initStudentBillingState,
    enableReinitialize: true,
    validationSchema: validationStudentBillingSchema,
    onSubmit: () => {},
  });
  const onboardingFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      meet_time: student?.meet_time,
      onboarded: student?.onboarded || null,
      onboarding: student?.onboarding || "Not_Started",
    },
    onSubmit: () => {},
  });
  const paymentMethods = useSelector(
    customerSelectors.customer_payment_methods,
  );
  const _monthById = useSelector(listsSelectors.monthById);
  const customerId = formikGeneral.values.customer_id;
  useEffect(() => {
    (async () => {
      if (!student) return;

      const {
        customer_id,
        customer,
        status_id,
        first_name_yiddish,
        last_name_yiddish,
        first_name,
        last_name,
        grade,
        birth_month_id,
        birth_month_day,
        bar_mitzva,
        school_building,
        student_siyums,
        student_notes,
        student_months,
        referral,
        discounts,
        completed,
        default_payment_method,
      } = student!;

      // General
      const year = _monthById[birth_month_id]?.year_id ?? 0;

      const referring_student_id = referral?.referring_student_id ?? "";

      let _referring_student;

      // getting data for referring student
      // I think BE has to return this data
      if (referring_student_id) {
        const student: any = await dispatch(
          adminActions.getStudentData(referring_student_id),
        );

        if (student) {
          const name_yiddish = student
            ? `${student.first_name_yiddish} ${student.last_name_yiddish}`
            : "";

          const name_english = customer
            ? `${student.first_name} ${student.last_name}`
            : "";

          const homePhone =
            student?.phone_numbers?.filter((numberObj) => {
              return numberObj.type === "home";
            })?.[0].number ?? "";

          _referring_student = {
            value: referring_student_id.toString(),
            label: [name_english, homePhone, name_yiddish]
              .filter(Boolean)
              .join(" - "),
          } as Option;
        }
      }

      await formikGeneral.setValues({
        ...formikGeneral.values,
        // General
        customer_id: customer_id.toString(),
        _customer: buildCustomerOption(customer),
        first_name_yiddish,
        last_name_yiddish,
        first_name,
        last_name,
        completed,
        school: school_building?.school?.id.toString() ?? "",
        school_building_id: school_building?.id?.toString() ?? "",
        grade: grade?.toString(),
        year: year.toString(),
        birth_month_id: birth_month_id?.toString() ?? "",
        birth_month_day: birth_month_day?.toString() ?? "",
        bar_mitzva,
        registration: !!student_siyums?.[0]?.registration,
        transportation: !!student_siyums?.[0]?.transportation,
        _student_siyums: student_siyums,
        _notes: student_notes,
        status_id,
        referring_student_id: referring_student_id.toString(),
        _referring_student,
      });

      // Enrollment
      const chaburah_id = student_months?.[0]?.chaburah_id ?? "";
      const month_id = student_months?.[0]?.month_id ?? currentMonth;
      const year_id =
        _monthById[month_id]?.year_id ?? _monthById[currentMonth || 0]?.year_id;

      await formikEnrollment.setValues({
        ...formikEnrollment.values,
        chaburah_id: chaburah_id.toString(),
        month_id: month_id?.toString(),
        year_id: year_id?.toString(),
      });

      // Billing
      await formikBilling.setValues({
        ...formikBilling.values,
        default_payment_method,
        discounts,
        paymentMethods: paymentMethods ?? null,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  useEffect(() => {
    (async () => {
      if (customerId) {
        await dispatch(customerActions.getCustomer(customerId));
        await dispatch(customerActions.getCustomerPaymentMethods(+customerId));
        await dispatch(customerActions.getCustomerBillingDetails(+customerId));
      }
    })();
  }, [customerId, dispatch]);

  useEffect(() => {
    if (onboardingFormik.values.meet_time) {
      const { weekday, parsha, time } = getYiddishDate(
        new Date(onboardingFormik.values.meet_time),
      );
      setYiddishDate(`${time} ${parsha} - ${yiddishDays[weekday]}`);
    }
  }, [onboardingFormik.values.meet_time]);

  const customer = useSelector(customerSelectors.customer) as ReturnType<
    typeof customerSelectors.customer
  >;
  const billingInfo = useSelector(customerSelectors.student_billing);
  const upcomingBill = useSelector(customerSelectors.customerUpcomingBill);

  const studentsBillingInfo = billingInfo?.filter(
    (billingDetail) => billingDetail.id === student?.id,
  );
  useEffect(() => {
    formikBilling.setFieldValue(
      "default_payment_method",
      studentsBillingInfo?.[0]?.default_payment_method,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsBillingInfo?.[0]?.default_payment_method]);
  useEffect(() => {
    if (studentsBillingInfo?.[0]?.payment_method_id) {
      formikBilling.setFieldValue(
        "payment_method_id",
        studentsBillingInfo?.[0]?.payment_method_id,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsBillingInfo?.[0]?.payment_method_id]);

  const [invoiceMulti, setInvoiceMulti] = useState<number | undefined>();
  useEffect(() => {
    (async () => {
      if (!student?.customer_id) return;

      const _invoiceMulti = (
        (await dispatch(
          customerActions.getCustomerBillingHistory(
            student?.customer_id,
            false,
          ),
        )) as any
      )
        ?.map((b) => {
          const multi = b.invoice_items?.find(
            (i) =>
              i.student_id === student.id &&
              i.description === "Multi Student Discount",
          );

          return b.month_id === student.month_join && multi ? multi : undefined;
        })
        ?.find((m) => !!m)?.amount;
      setInvoiceMulti(+_invoiceMulti);
    })();
  }, [dispatch, student?.customer_id, student?.id, student?.month_join]);

  const studentsUpcomingFees = upcomingBill?.fees?.find(
    (f) => f.id === student?.id,
  );
  const chaburahPrice = (+studentsUpcomingFees?.own_price || 0) * -1;
  const passedCredit = +upcomingBill?.credit || 0;
  const addedCredit = +(formikBilling.values?.credit_add || 0);
  const credit = passedCredit + addedCredit;
  const multi = studentsUpcomingFees?.multi || 0;
  const { paid, unpaid } = upcomingBill || {};
  let discount = 0;
  let discountReason = "Discount";
  let discountType = "amount";
  let studentsSubtotal = chaburahPrice;
  let _unpaid;

  if (paid) {
    studentsSubtotal = 0;
  } else if (
    unpaid &&
    Math.abs(unpaid) + (invoiceMulti || 0) < Math.abs(studentsSubtotal)
  ) {
    studentsSubtotal = Math.abs(unpaid);
    _unpaid = Math.abs(unpaid);
    if (!invoiceMulti) {
      studentsSubtotal -= multi;
    }
  } else {
    studentsSubtotal -= multi;
  }

  if (studentsUpcomingFees?.discount?.discount) {
    discount = studentsUpcomingFees?.discount?.discount;
    discountReason = studentsUpcomingFees?.discount?.reason;

    if (studentsUpcomingFees?.discount?.discount_type === "percentage") {
      studentsSubtotal -= studentsSubtotal * (discount / 100);
      discountType = "percentage";
    } else {
      studentsSubtotal = studentsSubtotal - discount;
    }
  } else if (formikBilling.values.discount) {
    discount = formikBilling.values.discount;

    if (formikBilling.values.discount_type === "percentage") {
      discountType = "percentage";
      studentsSubtotal -= studentsSubtotal * (discount / 100);
    } else {
      studentsSubtotal = studentsSubtotal - discount;
    }
  }
  if (studentsSubtotal < 0) {
    studentsSubtotal = 0;
  }

  const appliedCredit = studentsSubtotal > credit ? credit : studentsSubtotal;
  const studentsTotalDue = studentsSubtotal - appliedCredit;
  const remainingCredit = credit - appliedCredit;

  const customersTotalDue = upcomingBill?.fees?.reduce((prev, curr) => {
    return (
      prev +
      +curr.own_price * -1 -
      (curr.multi || 0) -
      (curr?.discount?.discount || 0)
    );
  }, 0);
  const cardData = customer?.payment_methods?.filter(
    (card) =>
      card.id ===
      billingInfo?.filter(
        (billingDetail) => billingDetail.id === student?.id,
      )?.[0]?.payment_method?.processor_payment_method_id,
  );

  return {
    formikEnrollment,
    formikGeneral,
    enrollmentHooks,
    formikBilling,
    onboardingFormik,
    student,
    yiddishDate,
    setYiddishDate,
    customersTotalDue,
    remainingCredit,
    appliedCredit,
    studentsTotalDue,
    chaburahPrice,
    cardData,
    credit,
    upcomingBill,
    studentsUpcomingFees,
    studentsBillingInfo,
    discount,
    discountReason,
    discountType,
    multi,
    unpaid: _unpaid,
    paid,
  };
}
