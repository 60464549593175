import { SxProps, Theme } from "@mui/material";

export const captionSx: SxProps<Theme> = {
  color: "#000000",
  fontSize: "16px",
  letterSpacing: "0.07px",
  lineHeight: "18px",
};

export const btnSx: SxProps<Theme> = {
  minWidth: "140px",
};

export const content: SxProps<Theme> = {
  p: "32px 40px",
};

export const container: SxProps<Theme> = {
  minWidth: "700px",
};

export const btnStyle: SxProps<Theme> = {
  "& .MuiButtonGroup-grouped": {
    height: "48px",
    minWidth: "140px",
  },
};

export const dscField: SxProps<Theme> = {
  mt: "0!important",
  "& > .MuiInputBase-root": {
    pr: 0,
  },
};

export const dscSelector: SxProps<Theme> = {
  height: "54px",
  "& > .MuiButton-root": {
    height: "100%",
    border: "unset",

    "&.MuiButton-contained": {
      "& .dollar-icon": {
        fill: "white",
      },
    },

    "&.MuiButton-outlined": {
      "& .dollar-icon": {
        fill: "#00175C",
      },
    },
  },
};

export const savedCards = (hasCards: boolean): SxProps<Theme> => {
  return {
    minHeight: hasCards ? "unset" : "152px",
    mb: "25px",
    borderTop: "1px solid #D5D8EC",
    borderBottom: "1px solid #D5D8EC",

    "& > .MuiTypography-root": {
      color: "rgba(0,0,0,0.78)",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "0.17px",
      lineHeight: "22px",
      textAlign: "center",
    },
  };
};

export const gridSx: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    border: "unset",

    "& > .MuiDataGrid-columnHeaders": {
      display: "none",
    },
    "& .MuiDataGrid-virtualScroller": {
      marginTop: "0!important",
    },
  },
};
