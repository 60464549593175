import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import {
  DialogContentText,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Transition } from "./Transition";
import { listsSelectors, uiActions } from "../../../../state";
import Select from "../../../../components/Select";
import { textField } from "../../ChangeStudentPage/TabGeneral/TabGeneral.styles";
import { SystemStyleObject } from "@mui/system";
interface RoshKinyan {
  rosh_kinyan_id: number;
  neighborhood_id: number;
}

export const AddRoshKinyanModal = (
  { open, handleClose, neighborhood_id },
  formik: FormikProps<RoshKinyan>,
) => {
  const dispatch = useDispatch();
  // const { values, handleBlur, handleChange, errors, touched, setValues } =
  //   formik;
  // const roshKinyan = useFormik<RoshKinyan>({
  //   initialValues: {
  //     rosh_kinyan_id: 61,
  //     neighborhood_id: neighborhood_id,
  //   },
  //   validationSchema: Yup.object().shape({
  //     rosh_kinyan_id: Yup.string().required("נא לבחור רושק קינין"),
  //   }),
  //   onSubmit: (values) => {
  //     dispatch(uiActions.setLoading(true));
  //   },
  // });
  const FormRef: any = useRef(null);
  const _roshKinyans = useSelector(listsSelectors.userById);
  const _neighborhoods = useSelector(listsSelectors.neighborhoodById);

  const [roshKinyans, setRoshKinyans] = useState<any[]>([]);
  const [neighborhoods, setNeighborhoods] = useState<any[]>([]);

  useEffect(() => {
    const neighborhoodsSelectValues = Object.keys(_neighborhoods).map((key) => {
      return {
        value: _neighborhoods[key].id,
        label: _neighborhoods[key].name,
      };
    }, []);
    setNeighborhoods(neighborhoodsSelectValues);
  }, [_neighborhoods]);

  useEffect(() => {
    const filteredRoshKinyans = _roshKinyans?.reduce((acc, curr) => {
      if (curr.role_id[3]) {
        acc.push({
          ...curr,
          value: curr.id,
          label: `${
            curr.name_yiddish === "null" || curr.name_yiddish === "null null"
              ? ""
              : curr.name_yiddish + " - "
          } ${
            curr.name === "null" || curr.name === "null null" ? "" : curr.name
          }`,
        });
      }
      return acc;
    }, []);
    setRoshKinyans(filteredRoshKinyans);
  }, [_roshKinyans]);

  const handleSubmit = async (values: any) => {
    try {
      const validationError = FormRef.current.validateForm();
      if (validationError) {
        dispatch(uiActions.showError(validationError));
      } else {
        const { ccToken, cvvToken } = await FormRef.current.getCKTokens();
        (values.card_token = ccToken), (values.cvv_token = cvvToken);
        //dispatch action to save cards
      }
    } catch (error) {
      //handle Error
    }
  };

  const validateSchema = Yup.object().shape({
    expiration: Yup.string().required("Required"),
  });

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Add a ראש קנין / מגיד שיעור"}</DialogTitle> */}
        <DialogTitle>{`No ראש קנין found for ${neighborhood_id}`}</DialogTitle>

        <DialogContentText>
          Please assign a ראש קנין for {neighborhood_id} to continue
        </DialogContentText>
        <Formik
          initialValues={{
            rosh_kinyan_id: 0,
            neighborhood_id: neighborhood_id,
          }}
          onSubmit={handleSubmit}
          validationSchema={validateSchema}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <DialogContent>
                  <Select
                    id="rosh_kinyan_id"
                    name="rosh_kinyan_id"
                    sx={[
                      textField as SystemStyleObject,
                      { width: "360px" } as SystemStyleObject,
                    ]}
                    width={"unset"}
                    label={"ראש קנין / מגיד שיעור"}
                    placeholder={"ראש קנין / מגיד שיעור"}
                    options={roshKinyans}
                    value={formik.values?.rosh_kinyan_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.rosh_kinyan_id &&
                      !!formik.errors?.rosh_kinyan_id
                    }
                    helperText={
                      formik.touched?.rosh_kinyan_id &&
                      formik.errors?.rosh_kinyan_id
                    }
                  />
                  <Select
                    id="neighborhood_id"
                    name="neighborhood_id"
                    sx={[
                      textField as SystemStyleObject,
                      { width: "360px" } as SystemStyleObject,
                    ]}
                    width={"unset"}
                    label={"Neighborhood"}
                    placeholder={"Neighborhood"}
                    options={neighborhoods}
                    value={formik.values?.neighborhood_id || neighborhood_id}
                    disabled={neighborhood_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched?.neighborhood_id &&
                      !!formik.errors?.neighborhood_id
                    }
                    helperText={
                      formik.touched?.neighborhood_id &&
                      formik.errors?.neighborhood_id
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    Add
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};
