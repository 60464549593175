import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ErrorMessage = styled(Typography)(() => ({
  color: "#bb2018",
  fontFamily: "Noto Sans",
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: 0.09,
  textAlign: "left",
  marginTop: -13,
  marginRight: 14,
  marginBottom: 0,
  marginLeft: 14,
}));
