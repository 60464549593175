import React, { FC } from "react";
import { TabType, useGroupDetails } from "./useGroupDetails";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import Header from "./Header";
import GroupInfo from "./GroupInfo";
import { Box, Tab, Tabs } from "@mui/material";
import { tabsStyle } from "./GroupDetails.styles";
import TabStudents from "./TabStudents";
import TabCurriculum from "./TabCurriculum";

const GroupDetails: FC = () => {
  const { group, tab, handleTabChange } = useGroupDetails();

  if (!group) {
    return null;
  }

  return (
    <Page>
      <TopSection style={{ marginTop: 0, paddingTop: 24, height: "150px" }}>
        <Header groupName={group.name} />
      </TopSection>

      <Box style={{ marginTop: -80, padding: "0px 24px" }}>
        <GroupInfo group={group} />
      </Box>

      <BottomSection style={{ paddingTop: 24 }}>
        <Box sx={{ borderBottom: "1px solid #DDE3EB" }}>
          <Tabs value={tab} onChange={handleTabChange} sx={tabsStyle}>
            <Tab label="Students" value={"students" as TabType} />
            <Tab label="Curriculum" value={"curriculum" as TabType} />
          </Tabs>
        </Box>

        {tab === "students" && <TabStudents />}
        {tab === "curriculum" && <TabCurriculum />}
      </BottomSection>
    </Page>
  );
};

export default GroupDetails;
