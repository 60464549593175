import { number, object, string } from "yup";

export interface Fields {
  first_name_yiddish: string;
  last_name_yiddish: string;
  first_name: string | null;
  last_name: string | null;
  home_phone_id: number | null;
  home_phone: string | null;
  home_country_code_id: number | null;
  mobile_phone_id: number | null;
  mobile_phone: string | null;
  mobile_country_code_id: number | null;
  email: string | null;
  address_id: number | null;
  address: string;
  address_2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country_id: number | null;
  currency: string | null;
  neighborhood_id: number;
  status_id: number;
  notes: string | null;
  completed: boolean;
  canDeactivate: boolean;
}

export const initialValues: Fields = {
  first_name_yiddish: "",
  last_name_yiddish: "",
  first_name: "",
  last_name: "",
  home_phone_id: null,
  home_phone: "",
  home_country_code_id: null,
  mobile_phone_id: null,
  mobile_phone: "",
  mobile_country_code_id: null,
  email: "",
  address_id: null,
  address: "",
  address_2: "",
  city: "",
  state: "",
  zip: "",
  country_id: null,
  currency: "",
  neighborhood_id: 0,
  status_id: 0,
  notes: "",
  completed: false,
  canDeactivate: true,
};
export const validationSchema = object().shape({
  first_name_yiddish: string()
    .required("Required")
    .test(
      "first_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("ערשטע נאמען"),
  last_name_yiddish: string()
    .required("Required")
    .test(
      "last_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("לעצטע נאמען"),
  last_name: string().required("Required"),
  home_phone: string().required("required"),
  home_country_code_id: string().required("required").typeError("required"),
  mobile_country_code_id: string().when("mobile_phone", {
    is: (val) => val?.length > 0,
    then: string().required("required").typeError("required"),
    otherwise: string().nullable().notRequired(),
  }),
  email: string().nullable().email().label("Email"),
  address: string().required().label("Address"),
  zip: string().nullable().label("Zip"),
  neighborhood_id: number()
    .required()
    .test("neighborhood_id", "${label} is not selected", (val = 0) => !!val)
    .label("Neighborhood"),
  // active: boolean()
  //   .required("Required")
  //   .when("canDeactivate", {
  //     is: false,
  //     then: boolean().oneOf(
  //       [true],
  //       "Can not deactivate customer with active students",
  //     ),
  //   })
  //   .test(
  //     "active",
  //     "${label} is not selected",
  //     (val) => typeof val === "boolean",
  //   )
  //   .label("Status"),
});
