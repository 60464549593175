import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { btnSx, captionSx, container, content } from "./AddCredit.styles";
import { Transition } from "../Transition";
import { Fields, initialValues, validationSchema } from "./schema";
import { Form, Formik } from "formik";
import { FormikInput } from "../../../../../components";
import { useAddCredit } from "./useAddCredit";
import { useDispatch, useSelector } from "react-redux";
import { customerActions, customerSelectors } from "../../../../../state";
import useApplyPayment from "../../../AddNewPaymentPage/useApplyPayment";
import { ApplyPaymentFooter } from "../../../AddNewPaymentPage/ApplyPaymentFooter";
import { gridPayoffSx } from "../../../AddNewPaymentPage/AddNewPaymentPage.styles";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { dataGridFilterStyles } from "../../../../../themes";

interface CreditData {
  amount: number;
  note: string;
}

export interface Props {
  showModal: boolean;
  id?: string;
  onClose?: () => void;
  addFunction?: (data: CreditData) => void;
}

const AddCredit: FC<Props> = ({ showModal, onClose, id }) => {
  const { innerRef, open, handleSubmit, handleClose } = useAddCredit({
    showModal,
    onClose,
    id,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      if (id) {
        dispatch(customerActions.getCustomer(id));
      }
    })();
  }, [dispatch, id]);

  // there is some ref in columns file (not relevant to PAYOFF_COLUMNS) that crashes
  // at import time (wonder why this issue isn't consistent).
  // For work around we are importing after load
  const [payoffColumns, setColumns] = useState<GridColDef<any, any, any>[]>([]);
  const getColumns = async () => {
    const columns = await import("../../../../../constants/columns");
    setColumns(columns.PAYOFF_COLUMNS);
  };
  useEffect(() => {
    getColumns();
  }, []);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{"Add a credit"}</DialogTitle>

      <Formik<Fields>
        innerRef={innerRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => {
          const { submitForm } = formik;
          const customerName = useSelector(customerSelectors.customerName);
          const { selected, handleSelectionPayoffChange, balanceItems } =
            useApplyPayment(formik, id, "insert");

          const customFooter = () => (
            <ApplyPaymentFooter
              amount={formik.values.amount}
              balanceItems={balanceItems}
            />
          );

          return (
            <Form>
              <DialogContent sx={content}>
                <Typography variant={"subtitle2"} sx={captionSx}>
                  Add a credit for {customerName}
                </Typography>

                <Grid container sx={container}>
                  <Grid item xs={8}>
                    <FormikInput
                      onChange={(e) => {
                        formik.setFieldValue("touchedAmount", false);
                        formik.setFieldValue("amount", e.target.value);
                      }}
                      name="amount"
                      label="Credit amount"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikInput name="note" />
                  </Grid>
                  <>
                    <Grid item xs={12}>
                      <Typography variant={"h6"} sx={{ mt: 5, mb: 2 }}>
                        Pay off months
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <DataGridPro
                        sx={gridPayoffSx}
                        rowHeight={74}
                        disableSelectionOnClick={true}
                        checkboxSelection={true}
                        hideFooterSelectedRowCount={true}
                        hideFooterRowCount={true}
                        rows={balanceItems}
                        autoHeight={true}
                        columns={payoffColumns}
                        onSelectionModelChange={handleSelectionPayoffChange}
                        selectionModel={selected}
                        components={{
                          Footer: customFooter,
                        }}
                        componentsProps={{
                          toolbar: {
                            printOptions: { disableToolbarButton: true },
                          },
                          panel: {
                            sx: {
                              ...dataGridFilterStyles,
                            },
                          },
                        }}
                      />
                    </Grid>
                  </>
                </Grid>
              </DialogContent>

              <Divider
                variant="middle"
                sx={{ border: "1px solid #DDE3EB", mb: 2 }}
              />

              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={btnSx}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color={"primary"}
                  sx={btnSx}
                  onClick={submitForm}
                >
                  Add credit
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddCredit;
