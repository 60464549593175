import React from "react";
import { Typography, Box, Button } from "@mui/material";
import {
  adminActions,
  adminSelectors,
  listsSelectors,
  uiSelectors,
  useAction,
  useSelector,
} from "../../state";
import { RefreshIcon } from "../icons";
import { format } from "date-fns";

interface ButtonProps {
  refreshAction: "getStudents" | "getStudentsMonths" | null;
  monthId?: string | null;
}
const _RefreshGridButton = ({ refreshAction, monthId = null }: ButtonProps) => {
  const refreshGrid = useAction(adminActions.refreshCache);
  const cacheUpdatedAt = useSelector(adminSelectors.cacheUpdatedAt);
  const neighborhoods = useSelector(listsSelectors.neighborhoodById);
  const cacheUpdateLoading = useSelector(uiSelectors.cacheUpdateLoading);
  const handleRefresh = () => {
    refreshGrid(refreshAction, monthId, neighborhoods);
  };

  return (
    <Box>
      <Typography
        color="primary"
        sx={{ display: "flex", alignItems: "center" }}
      >
        {cacheUpdatedAt &&
          `last updated at ${format(new Date(cacheUpdatedAt), "Pp")}`}
        <Button
          onClick={handleRefresh}
          disabled={cacheUpdateLoading}
          sx={{ minWidth: 40, alignItems: "center", height: 0 }}
        >
          <RefreshIcon />
        </Button>
      </Typography>
    </Box>
  );
};

export const RefreshGridButton = React.memo(_RefreshGridButton);
