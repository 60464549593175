import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface WebApplicationDetails {
  live_price?: LivePrice;
  remote_prices: RemotePrice[];
  months: WebApplicationMonth[];
}

export interface LivePrice {
  price: string;
  currency: string;
}

export interface RemotePrice {
  price: string;
  currency: string;
  track_id: number;
  track: string;
}

export interface WebApplicationMonth {
  id: number;
  year_id: number;
  hebrew: string;
  semester: "summer" | "winter";
}
export interface StudentState {
  studentMonthData: any;
  chaburahMonthData: any;
  redemptions;
  webApplicationDetails: WebApplicationDetails;
}

const initialState: StudentState = {
  studentMonthData: {},
  chaburahMonthData: {},
  redemptions: [],
  webApplicationDetails: {
    live_price: undefined,
    remote_prices: [],
    months: [],
  },
};

export const student = {
  ...createSlice({
    name: "student",
    initialState,
    reducers: {
      setStudentMonthData(state, { payload }: PayloadAction<any>) {
        state.studentMonthData = payload;
      },
      setChaburahMonthData(state, { payload }: PayloadAction<any>) {
        state.chaburahMonthData = payload;
      },
      setStudentRedemptions(state, { payload }) {
        state.redemptions = payload;
      },
      setWebApplicationDetails(state, { payload }) {
        state.webApplicationDetails = payload;
      },
    },
  }),
  persist: false,
};
