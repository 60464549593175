import { styled } from "@mui/styles";
import { InputBase } from "@mui/material";

export const Page = styled(`div`)(() => ({
  minWidth: "100%",
  minHeight: "100%",
}));

export const TopSection = styled(`div`)(() => ({
  height: 100,
  backgroundColor: "#F3F6F9",
  paddingLeft: 25,
  paddingRight: 25,
}));
export const BottomSection = styled(`div`)(() => ({
  paddingLeft: 25,
  paddingRight: 25,
  marginBottom: 25,
  width: "100%",
  height: "100%",
}));
export const BottomLeft = styled(`div`)(() => ({
  width: 250,
  position: "absolute",
  top: 121,
  left: 250,
  overflow: "auto",
}));
export const Search = styled("div")(() => ({
  position: "relative",
  height: "48px",
  width: "400px",
  border: "1px solid #DDE3EB",
  borderRadius: "4px",
  backgroundColor: "#fff",
  marginTop: 16,
  marginBottom: -64,
  zIndex: 1,
}));
export const StyledInputBase = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    marginLeft: 16,
    width: "350px",
    paddingBottom: 2,
  },
}));

export const BottomRight = styled(`div`)(() => ({
  paddingLeft: 25,
  paddingRight: 25,
  marginBottom: 25,
  position: "absolute",
  top: 121,
  right: 0,
  left: 500,
}));
