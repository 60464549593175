import { object, string } from "yup";

export const customerInitialState = {
  first_name_yiddish: "",
  last_name_yiddish: "",
  first_name: "",
  last_name: "",
  home_phone: "",
  home_country_code_id: 1,
  mobile_phone: "",
  mobile_country_code_id: "",
  email: "",
  address: "",
  address_2: "",
  city: "",
  state: "NY",
  zip: "",
  country_id: 1,
  neighborhood_id: "",
  notes: "",
  status_id: 4,
  currency: "",
  completed: false,
};

export const customerValidationSchema = object().shape({
  first_name_yiddish: string()
    .required("Required")
    .test(
      "first_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("ערשטע נאמען"),
  last_name_yiddish: string()
    .required("Required")
    .test(
      "last_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("לעצטע נאמען"),
  last_name: string().required("required"),
  home_phone: string().required("required"),
  home_country_code_id: string().required("required").typeError("required"),
  mobile_country_code_id: string().when("mobile_phone", {
    is: (val) => val?.length > 0,
    then: string().required("required").typeError("required"),
    otherwise: string().nullable().notRequired(),
  }),
  state: string().when("country_id", {
    is: (val) => val === "1" || val === "2",
    then: string().required("required").typeError("required"),
    otherwise: string().notRequired(),
  }),
  address: string().required("required"),
  city: string().required("required"),
  zip: string().nullable().label("Zip"),
  country_id: string().required("required"),
  currency: string().required("required"),
  neighborhood_id: string().nullable().required("required"),
});

export const studentInitialState = {
  first_name_yiddish: "",
  last_name_yiddish: "",
  first_name: "",
  last_name: "",
  school_building_id: null,
  grade: null,
  birth_month_id: null,
  birth_month_day: null,
  status_id: null,
  default_payment_method: "",
  created_by: null,
  completed: false,
  notes: null,
  referred_by: null,
};

export const studentValidationSchema = object().shape({
  first_name_yiddish: string().required("required"),
  last_name_yiddish: string().required("required"),
});

export function addStudentEnrollmentData(values) {
  const {
    customer_id,
    first_name,
    last_name,
    first_name_yiddish,
    last_name_yiddish,
    school_building_id,
    grade,
    birth_month_id,
    birth_month_day,
    notes,
    chaburah_id,
    month_id,
    referring_student_id,
  } = values;

  return {
    customer_id: parseInt(customer_id),
    first_name: first_name || null,
    last_name: last_name || null,
    first_name_yiddish: first_name_yiddish,
    last_name_yiddish: last_name_yiddish,
    school_building_id: parseInt(school_building_id) || null,
    grade: parseInt(grade) || null,
    birth_month_id: parseInt(birth_month_id),
    birth_month_day: parseInt(birth_month_day),
    notes: notes || null,
    chaburah_id: parseInt(chaburah_id),
    month_id: parseInt(month_id),
    referring_student_id: referring_student_id || null,
  };
}
