import { object, string, array } from "yup";
import { floatRegExp } from "../../../../../lib/regexp";
import { BalanceRow } from "../../../../../state";
import { toFloat } from "../../../../../lib";

export interface Fields {
  amount: string;
  note: string;
  balanceItems: BalanceRow[] | null;
  touchedAmount: boolean;
}

export const initialValues: Fields = {
  amount: "",
  note: "",
  balanceItems: [],
  touchedAmount: true,
};

export const validationSchema = object().shape({
  amount: string()
    .required("Required")
    .test("amount", "${label} must be a number", (val = "") =>
      floatRegExp.test(val),
    )
    .test(
      "amount",
      "${label} must be no less than 0.05",
      (val = "0") => +val >= 0.05 || val === "",
    )
    .test(
      "amount",
      "${label} must be no greater than 10,000",
      (val = "0") => +val <= 10_000,
    )
    .label("Amount"),
  note: string().required("Required"),
  balanceItems: array()
    .nullable(true)
    .test(
      "payoffLessThanAmount",
      "Payoffs must be less than or equal to amount",
      (items, context) => {
        return (
          !items ||
          items
            .filter((b) => b.isSelected)
            .reduce((prev, curr) => prev + Math.abs(toFloat(curr.amount)), 0) <=
            context.parent.amount
        );
      },
    )
    .of(
      object().shape({
        amount: string()
          .test("amount", "${label} must be a number", (val = "") => {
            return val === "" || floatRegExp.test(val);
          })
          .label("Amount"),
      }),
    ),
});
