import React, { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../components";
import { useSelector } from "react-redux";
import { adminSelectors, listsSelectors, Month } from "../../../state";
import { monthCaption } from "../styles/MonthSelector.styles";
import { toInt } from "../../../lib";
import { MonthsAutocomplete } from "./MonthsAutocomplete";

interface Props {
  monthId: string;
  onSelectMonthId: (monthId: number) => void;
  currentSemesterId?: number | undefined;
  chosenSemsterId?: number | undefined;
}

const MonthSelector: FC<Props> = ({
  monthId,
  onSelectMonthId,
  currentSemesterId,
  chosenSemsterId,
}) => {
  const months = useSelector(listsSelectors.monthById);
  const currentMonthId = useSelector(adminSelectors.currentMonthId);
  const monthData = months[monthId] as Month;

  const handleArrowBtnClick = (
    type: "prev" | "next",
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    let newMonthId = toInt(monthId);

    if (!newMonthId) {
      return;
    }

    switch (type) {
      case "prev":
        newMonthId = months[newMonthId - 1] ? newMonthId - 1 : newMonthId;
        break;
      case "next":
        newMonthId = months[newMonthId + 1] ? newMonthId + 1 : newMonthId;
        break;
    }

    onSelectMonthId && !!newMonthId && onSelectMonthId(newMonthId);
  };

  if (!monthData) {
    return null;
  }

  const handleChange = (value) => {
    onSelectMonthId(value);
  };

  const { name, year_id } = monthData;

  return (
    <Stack direction={"row"} flexGrow={1} alignItems={"center"}>
      <IconButton
        color="primary"
        onClick={handleArrowBtnClick.bind(null, "prev")}
      >
        <ChevronLeftIcon />
      </IconButton>

      <IconButton
        color="primary"
        onClick={handleArrowBtnClick.bind(null, "next")}
      >
        <ChevronRightIcon />
      </IconButton>
      <Typography variant={"h4"} sx={monthCaption}>
        {`${name} ’${year_id.toString(10).slice(2)} - `}
      </Typography>
      <MonthsAutocomplete
        value={monthId}
        onChange={(_e, v) => {
          if (v) {
            handleChange(v.value);
          }
        }}
        startMonthId={171}
        endMonthId={Number(currentMonthId) + 12}
        autocompleteProps={{
          fullWidth: true,
          sx: {
            maxWidth: 225,
            "& input": {
              color: "#00175C",
              fontSize: 22,
              fontWeight: "bold",
              paddingLeft: "0px !important",
            },
            "& fieldset": { border: "none" },
          },
        }}
      />
      {currentSemesterId &&
        chosenSemsterId &&
        chosenSemsterId !== currentSemesterId && (
          <div
            style={{
              display: "inline-block",
              color: "#F0921F",
              backgroundColor: "#FEE9D0",
              fontSize: 13,
              borderRadius: "3px",
              padding: "5px",
            }}
          >
            {chosenSemsterId > currentSemesterId ? "Future " : "Past "}זמן
          </div>
        )}
    </Stack>
  );
};

export default React.memo(MonthSelector);
