import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  authSelectors,
  customerActions,
  customerSelectors,
} from "../../../state";
import { Grid, Typography, Tabs, IconButton, Tooltip } from "@mui/material";
import {
  BottomSection,
  Page,
  TopSection,
  TabStyled,
  MiddleSection,
} from "../styles/CustomerPage.styles";
import { CustomerCard, ActionsButton } from "../components";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import { toInt } from "../../../lib";
import AdminPages from "../index";
import CustomerTabBilling from "./CustomerTabBilling";
import CustomerTabPayments from "./CustomerTabPayments";
import { ModalData } from "../components/Modals/RefundModal/types";
import RefundModal from "../components/Modals/RefundModal";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const CustomerPage = () => {
  const params = useParams();
  const isLimited = useSelector(authSelectors.isLimited);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [modal, setModal] = useState<ModalData | null>(null);
  const customer = useSelector(customerSelectors.customer) as ReturnType<
    typeof customerSelectors.customer
  >;

  const getCustomerData = useCallback(
    async (id) => {
      await dispatch(customerActions.getCustomer(+id));
      dispatch(customerActions.getCustomerBillingHistory(id));
      dispatch(customerActions.getCustomerCardInfo(id));
    },
    [dispatch],
  );

  useEffect(() => {
    if (params?.id) {
      getCustomerData(params.id);
    }
  }, [dispatch, getCustomerData, params.id]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleDoARefundClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    setModal({
      customerId: customer?.id!,
      name: `${customer?.first_name!} ${customer?.last_name}`,
      nameYiddish: `${customer?.first_name_yiddish!} ${
        customer?.last_name_yiddish
      }`,
    });
  };

  const handleModalClose = (_: typeof modal) => {
    setModal(null);
  };

  const {
    first_name_yiddish,
    last_name_yiddish,
    first_name,
    last_name,
    status_id,
  } = customer!;

  const handleEditClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();

      !!toInt(params?.id) &&
        navigate(AdminPages.editCustomer.path.replace(":id", params.id!));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params?.id],
  );
  if (!customer) return;
  const circleColor =
    status_id === 3 ? "#BB2018" : status_id === 4 ? "#000000" : "#128E56";
  const toolTipText =
    status_id === 3 ? "Inactive" : status_id === 4 ? "Draft" : "Active";
  return (
    <Page>
      <TopSection>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h3">
              <IconButton
                color="primary"
                onClick={() => navigate("/admin/customer")}
              >
                <ArrowBackIcon />
              </IconButton>
              {`${
                first_name_yiddish?.startsWith("ר' ") ||
                first_name_yiddish?.startsWith("ר ")
                  ? first_name_yiddish
                  : "ר' " + first_name_yiddish
              } ${last_name_yiddish} `}
              <Tooltip title={toolTipText}>
                <CircleIcon
                  sx={{
                    color: circleColor,
                    width: "12px",
                    pt: 1,
                  }}
                />
              </Tooltip>
            </Typography>

            <Typography variant="subtitle1" sx={{ marginLeft: 5.5 }}>{`${
              first_name || ""
            } ${last_name} `}</Typography>
          </Grid>

          <Grid item>
            <ActionsButton
              id={params?.id ?? ""}
              onEditClick={handleEditClick}
              fromCustomer={true}
              onDoARefundClick={handleDoARefundClick}
            />
          </Grid>
        </Grid>
      </TopSection>

      <MiddleSection>
        <CustomerCard customer={customer} isLimited={isLimited} />

        {!isLimited && (
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            style={{ borderBottom: "1px solid #DDE3EB" }}
          >
            <Grid item>
              <Tabs value={value} onChange={handleChange}>
                <TabStyled label="BILLING HISTORY" {...a11yProps(0)} />
                <TabStyled label="PAYMENT METHODS" {...a11yProps(1)} />
              </Tabs>
            </Grid>
          </Grid>
        )}
      </MiddleSection>
      {!isLimited && (
        <BottomSection style={{ marginTop: 24 }}>
          {value === 0 && <CustomerTabBilling customerId={params.id} />}
          {value === 1 && <CustomerTabPayments />}
        </BottomSection>
      )}

      <RefundModal modal={modal} onClose={handleModalClose} />
    </Page>
  );
};

export default CustomerPage;
