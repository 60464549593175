import React, { FC } from "react";
import TabContainer from "../../components/TabContainer";
import { FormikProps } from "formik";
import { DetailsFields } from "../schema";
import { container } from "../../ChangeStudentPage/TabEnrollment/TabEnrollment.styles";
import {
  Button,
  ButtonGroup,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { btnStyle, gridItem, textField } from "./TabDetails.styles";
import Select from "../../../../components/Select";
import { gridStyle } from "../ManageGroup.styles";
import { useTabDetails } from "./useTabDetails";
import { getButtonVariant } from "../../../../lib";
import { ChaburahType } from "../types";

interface Props {
  formik: FormikProps<DetailsFields>;
}

const TabDetails: FC<Props> = ({ formik }) => {
  const {
    pageMode,
    values,
    handleChange,
    handleChaburahType,
    handleBlur,
    handleActive,
    neighborhoods,
    touched,
    errors,
    locations,
  } = useTabDetails(formik);

  return (
    <TabContainer>
      <Stack sx={container}>
        <Typography variant={"h6"} sx={{ mt: 4 }}>
          Group details
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={gridStyle}
        >
          <Grid item sx={gridItem}>
            <TextField
              sx={textField}
              name="name"
              label="Name"
              fullWidth={true}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
            />
          </Grid>

          <Grid item sx={gridItem}>
            <ButtonGroup
              sx={btnStyle(
                touched.chaburah_type_id && !!errors.chaburah_type_id,
              )}
              disabled={pageMode === "edit"}
            >
              <Button
                variant={getButtonVariant(
                  values.chaburah_type_id === ChaburahType.Masmidim,
                )}
                onClick={handleChaburahType.bind(null, ChaburahType.Masmidim)}
              >
                Masmidim
              </Button>

              <Button
                variant={getButtonVariant(
                  values.chaburah_type_id === ChaburahType.CD,
                )}
                onClick={handleChaburahType.bind(null, ChaburahType.CD)}
              >
                CD
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        {values.chaburah_type_id === ChaburahType.Masmidim && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            sx={gridStyle}
          >
            <Grid item sx={gridItem}>
              <Select
                name="neighborhood_id"
                label={"Neighborhood"}
                sx={textField}
                options={neighborhoods}
                value={values.neighborhood_id}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.neighborhood_id && !!errors.neighborhood_id}
                helperText={touched.neighborhood_id && errors.neighborhood_id}
                disabled={pageMode === "edit"}
              />
            </Grid>

            <Grid item sx={gridItem}>
              <Select
                name="location_id"
                label={"Shul (optional)"}
                allowEmptyItem={true}
                placeholder={" "}
                sx={textField}
                options={locations}
                value={values.location_id}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.location_id && !!errors.location_id}
                helperText={touched.location_id && errors.location_id}
              />
            </Grid>
          </Grid>
        )}

        <Typography variant={"h6"}>Pricing</Typography>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={gridStyle}
        >
          <Grid item sx={gridItem}>
            <TextField
              sx={textField}
              name="priceUSD"
              label="USA Price"
              fullWidth={true}
              value={
                Math.abs(parseInt(values.priceUSD)) >= 0
                  ? Math.abs(parseInt(values.priceUSD))
                  : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              error={touched.priceUSD && !!errors.priceUSD}
              helperText={touched.priceUSD && errors.priceUSD}
            />
          </Grid>

          <Grid item sx={gridItem}>
            <TextField
              sx={textField}
              name="priceGBP"
              label="UK Price"
              fullWidth={true}
              value={
                Math.abs(parseInt(values.priceGBP)) >= 0
                  ? Math.abs(parseInt(values.priceGBP))
                  : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
              error={touched.priceGBP && !!errors.priceGBP}
              helperText={touched.priceGBP && errors.priceGBP}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={gridStyle}
        >
          <Grid item sx={gridItem}>
            <ButtonGroup sx={btnStyle(touched.active && !!errors.active)}>
              <Button
                variant={getButtonVariant(values.active)}
                onClick={handleActive.bind(null, true)}
              >
                Active
              </Button>

              <Button
                variant={getButtonVariant(!values.active)}
                onClick={handleActive.bind(null, false)}
              >
                Inactive
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Stack>
    </TabContainer>
  );
};

export default TabDetails;
