import React from "react";
import { Tooltip } from "@mui/material";
import { statusValues, statuses } from "../../constants";
import CircleIcon from "@mui/icons-material/Circle";

export default function StautsIcon({ status_id }) {
  return (
    <Tooltip title={statuses[statusValues[status_id]]?.toolTip}>
      <CircleIcon
        sx={{
          mr: -1,
          height: 8,
          width: 8,
          color: statuses[statusValues[status_id]]?.color,
        }}
      />
    </Tooltip>
  );
}
