import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { CSSProperties } from "react";

export const exportBtn: SxProps<Theme> = {
  mr: 3,
  px: 5,

  "& > .MuiSvgIcon-root": {
    mr: 1,
  },
};

export const addGroupBtn: SxProps<Theme> = {
  px: 5,
};

export const gridStyles: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    "& > .MuiDataGrid-columnHeaders": {
      backgroundColor: "white",
    },

    "& .MuiDataGrid-row": {
      cursor: "pointer",
    },
  },
};

export const sectionStyles: CSSProperties = {
  height: "calc(100vh - 110px)",
  paddingTop: 4,
};
