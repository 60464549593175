import React, { FC } from "react";
import { Box, Button } from "@mui/material";
import { btnPanel } from "./Buttons.styles";
import { PageMode, PaymentMethod } from "../../../../state";
import { DeleteIcon } from "../../../../components";

interface Props {
  pageMode: PageMode;
  paymentMethod: PaymentMethod;
  handleBtnClick: (
    type: "cancel" | "save" | "delete",
    e: React.MouseEvent<HTMLButtonElement>,
  ) => void;
}

const Buttons: FC<Props> = ({
  pageMode,
  paymentMethod: _paymentMethod,
  handleBtnClick,
}) => {
  return (
    <Box sx={btnPanel}>
      <Button
        variant={"outlined"}
        onClick={handleBtnClick.bind(null, "cancel")}
      >
        cancel
      </Button>

      <Button variant={"contained"} onClick={handleBtnClick.bind(null, "save")}>
        {_paymentMethod === "credit" && pageMode !== "edit"
          ? "Charge card & save"
          : "save"}
      </Button>

      {pageMode === "edit" && (
        <Button
          variant="text"
          sx={{ color: "#F62527" }}
          disabled={_paymentMethod === "credit"}
          onClick={handleBtnClick.bind(null, "delete")}
        >
          <DeleteIcon sx={{ width: "20px", mr: 1 }} />
          delete
        </Button>
      )}
    </Box>
  );
};

export default React.memo(Buttons);
