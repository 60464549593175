import { useDispatch, useSelector } from "react-redux";
import { FormikProps } from "formik";
import { StudentEnrollmentFields } from "../schema";
import React, { useCallback, useEffect, useState } from "react";
import { Option } from "../../../../components/Select/Select";
import {
  adminActions,
  listsActions,
  adminSelectors,
  listsSelectors,
  StudentSearchItem,
} from "../../../../state";
import {
  AutocompleteChangeReason,
  AutocompleteValue,
  debounce,
} from "@mui/material";

import { buildMonthsOptions, buildYearsOptions } from "../utils";

export const useTabEnrollment = (
  formik: FormikProps<StudentEnrollmentFields>,
  customerId: string,
  pageMode: string,
) => {
  const dispatch = useDispatch();

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setValues,
    setFieldValue,
    setTouched,
  } = formik;
  const _months = useSelector(listsSelectors.months);
  const _monthById = useSelector(listsSelectors.monthById);
  const users = useSelector(listsSelectors.userById);
  const _groups = useSelector(adminSelectors.activeGroups);

  const [chaburahs, setChaburahs] = useState<Option[] | undefined>([]);
  const [teachers, setTeachers] = useState<Option[] | undefined>([]);

  const [years, setYears] = useState<Option[]>([]);
  const [months, setMonths] = useState<Option[]>([]);

  const [searchStudent, setSearchStudent] = useState<string>("");
  const [students, setStudents] = useState<Option[]>([]);
  const [student, setStudent] = useState<Option | null>(null);
  const chaburahTeachers = useSelector(listsSelectors.chaburahTeachers);
  const currentStudentData = useSelector(adminSelectors.student);

  useEffect(() => {
    dispatch(adminActions.getGroups());
  }, [dispatch]);

  useEffect(() => {
    setYears(buildYearsOptions(_months));
    const d = _groups?.map(
      ({ id, name }) => ({ value: id.toString(), label: name } as Option),
    );
    setChaburahs(d);

    if (values._referring_student) {
      setStudent(values._referring_student as Option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_groups, _months]);

  useEffect(() => {
    if (!values.month_id) {
      (async () => {
        const monthId: any = await dispatch(adminActions.getCurrentMonth());
        let month = await _monthById[monthId + 1];
        const targetDay = new Date();
        targetDay.setDate(targetDay.getDate() + 10);

        if (targetDay < new Date(month.start_date)) {
          month = await _monthById[monthId];
        }
        const yearId = month.year_id;
        setValues({
          ...values,
          year_id: yearId.toString(),
          month_id: month.id,
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_months]);
  useEffect(() => {
    (async () => {
      if (searchStudent) {
        const u = await dispatch(listsActions.getStudentByName(searchStudent));

        if (u) {
          const co = (u as unknown as StudentSearchItem[]).map(
            ({ id, name }) => ({ label: name, value: id } as unknown as Option),
          );

          setStudents(co);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStudent]);

  useEffect(() => {
    const prevMonthId = values.month_id;
    setValues({ ...values, month_id: "" });
    setMonths(buildMonthsOptions(_months, values.year_id));
    if (prevMonthId) {
      if (_monthById[prevMonthId]?.year_id?.toString() === values.year_id) {
        setValues({ ...values, month_id: prevMonthId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.year_id, _months, values.month_id]);

  useEffect(() => {
    if (values.chaburah_id && customerId) {
      dispatch(listsActions.getTeacher(customerId, values.chaburah_id));
    }
  }, [values.chaburah_id, customerId, dispatch]);

  useEffect(() => {
    if (chaburahTeachers?.length) {
      //handles 404
      if (chaburahTeachers[0].id === -1) {
        setTeachers([
          { value: "-1", label: "NO ROSH KINYAN - PLEASE ADD ONE" },
        ]);
      } else {
        setTeachers([
          ...chaburahTeachers.map((t) => ({
            label: users[t.user_id]?.name_yiddish ?? " ",
            value: t.id?.toString(),
          })),
        ]);
      }
    } else {
      setTeachers([]);
    }
  }, [chaburahTeachers, users]);

  useEffect(() => {
    if (teachers?.length) {
      const existingEnrollmentData =
        currentStudentData?.student_months?.[0] ||
        currentStudentData?.future_changes?.[0];

      setValues({
        ...values,
        teacher_id:
          pageMode === "edit" &&
          values.chaburah_id?.toString() ===
            existingEnrollmentData?.chaburah_id?.toString()
            ? existingEnrollmentData?.teacher_id
            : teachers[0]?.value,
      });
    } else {
      setValues({ ...values, teacher_id: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teachers, pageMode, currentStudentData]);

  useEffect(() => {
    if (values._referring_student) {
      setStudent(values._referring_student as Option);
    }
  }, [values._referring_student]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchStudent = useCallback(
    debounce((event: React.SyntheticEvent, search: string) => {
      event?.stopPropagation();
      search ? setSearchStudent(search) : setStudents([]);
    }, 300),
    [],
  );

  const handleSelectStudent = (
    event: React.SyntheticEvent,
    selection: AutocompleteValue<Option, boolean, boolean, boolean>,
    reason: AutocompleteChangeReason,
  ) => {
    event?.stopPropagation();

    if (reason === "selectOption" && !!selection) {
      const option = selection as Option;

      setSearchStudent(option.label);
      setStudent(option);

      setValues({
        ...values,
        referring_student_id: option.value,
        _referring_student: option,
      });
    }

    if (reason === "clear") {
      setStudent(null);
      setStudents([]);
      setSearchStudent("");

      setValues({
        ...values,
        referring_student_id: "",
        _referring_student: undefined,
      });
    }
  };

  const handleSelectMonth = (
    event: React.SyntheticEvent,
    selection: AutocompleteValue<Option, boolean, boolean, boolean>,
    reason: AutocompleteChangeReason,
  ) => {
    event?.stopPropagation();

    if (reason === "selectOption" && !!selection) {
      const option = selection as Option;

      setValues({
        ...values,
        month_id: option.value,
        year_id: _monthById[option.value].year_id.toString(),
      });
    }

    if (reason === "clear") {
      setValues({
        ...values,

        month_id: "",
        year_id: "",
      });
    }
  };

  return {
    values,
    setFieldValue,
    handleSelectMonth,
    handleBlur,
    handleChange,
    teachers,
    errors,
    touched,
    chaburahs,
    student,
    students,
    years,
    months,
    handleSelectStudent,
    currentStudentData,
    chaburahTeachers,
    handleSearchStudent,
    setTouched,
  };
};
