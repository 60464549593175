import {
  Currency,
  DepositType,
  EditedCustomerPayment,
  NewCustomerPayment,
  PageMode,
  PaymentDetail,
  PaymentMethod,
  PrepayDetails,
  SemesterItem,
} from "../../../state";
import { NewPaymentFields } from "./schema";
import { format } from "date-fns";
import { toInt } from "../../../lib";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useParams } from "react-router";
import AdminPages from "../index";

export const MappedCurrencies: Record<Currency, string> = {
  USD: "$",
  GBP: "£",
};

export const MappedMode: Record<PageMode, string> = {
  edit: "Edit",
  insert: "Add",
  "": "",
};

export const MappedPaymentType: Record<number, DepositType> = {
  1: "regular",
  2: "regular",
  10: "prepayment",
  11: "prepayment",
  12: "regular",
};

export const MappedPaymentMethod: Record<number, PaymentMethod> = {
  1: "cash",
  2: "credit",
};

export function addCurrencySign(n: string | number, sign: null | string = "") {
  const value = typeof n === "number" ? n.toFixed(2).toString() : n;

  return value.charAt(0) === "-"
    ? value.replace("-", `-${sign}`)
    : `${sign}${value}`;
}

export function buildNewPayment(
  {
    paymentType,
    paymentMethod,
    customer_id,
    amount,
    date,
    checkNumber,
    note,
    balanceItems,
    payment_method_id,
    yearId,
    semesterTypeId,
    prepayOptions,
    payment_for,
  }: NewPaymentFields,
  semesters: SemesterItem[],
): NewCustomerPayment | null {
  const res: NewCustomerPayment | null = {
    payment_method_id: paymentMethod === "credit" ? payment_method_id : null,
    customer_id: +customer_id,
    amount: +amount,
    payment_type: paymentType,
    semester_id: null,
    date: date ? format(new Date(date), "yyyy-MM-dd") : null,
    default_payment_method: paymentMethod,
    identifier: null,
    note,
    payment_for,
    payment_details: balanceItems
      ?.filter((b) => b.isSelected)
      .map(
        (b) =>
          (({
            invoice_id: b.id,
            month: b.month,
            amount: Math.abs(Number(b.amount)).toString(),
          } as PaymentDetail) ?? null),
      ),
    prepay_details: null,
  } as NewCustomerPayment;

  if (paymentType === "regular" && paymentMethod === "cash") {
    res.identifier = checkNumber;
  }

  if (paymentType === "prepayment") {
    const sl = semesters.filter(
      (s) => s.year_id.toString() === yearId && s.name === semesterTypeId,
    );

    res.semester_id = toInt(sl?.[0]?.id) || null;

    const ids = prepayOptions?.prepay
      ?.filter((po) => po.isSelected && po.student?.id)
      .map((po) => ({ student_id: po.student.id } as PrepayDetails));

    res.prepay_details =
      !!ids && Array.isArray(ids) && !!ids.length ? ids : null;
  }

  return res;
}

export function buildEditedPayment(
  {
    paymentType,
    paymentMethod,
    amount,
    date,
    checkNumber,
    note,
    balanceItems,
    yearId,
    semesterTypeId,
    prepayOptions,
  }: NewPaymentFields,
  semesters: SemesterItem[],
): EditedCustomerPayment {
  const res: EditedCustomerPayment = {
    amount: +amount,
    payment_type: paymentType,
    semester_id: null,
    date: date ? format(new Date(date), "yyyy-MM-dd") : null,
    identifier: paymentMethod === "cash" ? checkNumber : null,
    note,
    payment_details: null,
    prepay_details: null,
  };

  if (paymentType === "prepayment") {
    const sl = semesters.filter(
      (s) => s.year_id.toString() === yearId && s.name === semesterTypeId,
    );

    res.semester_id = toInt(sl?.[0]?.id) || null;
  }

  res.payment_details =
    balanceItems
      ?.filter((b) => b.isSelected && !!b.amount)
      .map(
        (b) =>
          (({
            invoice_id: b.id,
            month: b.month,
            amount: b.amount,
          } as PaymentDetail) ?? null),
      ) ?? null;

  if (!res.payment_details?.length) {
    res.payment_details = null;
  }

  const ids = prepayOptions?.prepay
    ?.filter((po) => po.isSelected && po.student?.id)
    .map((po) => ({ student_id: po.student.id } as PrepayDetails));

  res.prepay_details = !!ids && Array.isArray(ids) && !!ids.length ? ids : null;

  return res;
}

export function usePageMode() {
  const [searchParams] = useSearchParams();

  const [pageMode, setPageMode] = useState<PageMode>("");
  const [id, setId] = useState<string>("");
  const [transactionId, setTransactionId] = useState<string>("");

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const pAdd = AdminPages.addPayment.path
      .replace(":id", "\\d{1,}")
      .replace(/\//g, "\\$&");

    const pEdit = AdminPages.editPayment.path
      .replace(":id", "\\d{1,}")
      .replace(/\//g, "\\$&");

    let re = new RegExp(pAdd, "gi");

    if (params?.id && re.test(location.pathname)) {
      setId(params.id);
      setPageMode("insert");

      return;
    }

    re = new RegExp(pEdit, "gi");

    if (params?.id && re.test(location.pathname)) {
      setId(params.id);
      setTransactionId(searchParams.get("transaction-id") ?? "");
      setPageMode("edit");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { pageMode, id, transactionId };
}
