import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataGridPro,
  GridColDef,
  GridSelectionModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
  GridRowId,
  gridVisibleSortedRowIdsSelector,
} from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { useDispatch } from "../../state";
import { useNavigate, useSearchParams } from "react-router-dom";
import NeighborhoodSelector, {
  NeighborhoodFilter,
} from "./components/NeighborhoodSelector";
import {
  adminActions,
  adminSelectors,
  listsSelectors,
  studentActions,
} from "../../state";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  BottomSection,
  Page,
  Search,
  StyledInputBase,
  TopSection,
} from "./styles/GridCardsSearch.styles";
import SearchIcon from "@mui/icons-material/Search";
import {
  getLaunchLink,
  getRoshKinyan,
  launch_icon,
  STUDENT_MONTH_COLUMNS,
} from "../../constants";
import MonthSelector from "./components/MonthSelector";
import AdminPages from "./index";
import { CustomFooter } from "../../components";
import { RefreshGridButton } from "../../components/tables/RefreshGridButton";
import { REACT_APP_BASE_URL } from "../../config";
import { dataGridFilterStyles } from "../../themes";
import AddMonthlyPoints from "./components/Modals/AddMonthlyPoints";
import Select from "../../components/Select";
import { DatePicker } from "@mui/x-date-pickers";
import { format, isValid } from "date-fns";

export const StudentMonthsPage = () => {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const hideColumns = {
    fullNameYiddish: false,
    fullNameEnglish: false,
    first_name: false,
    last_name: false,
    last_name_yiddish: false,
    first_name_yiddish: false,
    kinyan: false,
    call_date_yiddish: false,
    menahel: false,
    rosh_kinyan: false,
    grade_yiddish: false,
    school_building_id: false,
    school: false,
    defaultPaymentMethod: false,
    address_one: false,
    address_two: false,
    city: false,
    state: false,
    zip: false,
    full_customer_address: false,
    do_not_call: false,
    away: false,
  };
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [monthlyPointsModal, setMonthlyPointsModal] = useState<any>(null);

  const [searchParams] = useSearchParams();
  const [rows, setRows]: any = useState([]);

  const [chaburahFilter, setChaburahFilter] =
    useState<NeighborhoodFilter>("all");

  const [monthId, setMonthId] = useState<string>("0");
  const itemButtonClick = (e) => {
    navigate(`/admin/student/${e.id}?month_id=${monthId}`);
  };
  const students = useSelector(adminSelectors.studentMonth);
  const studentsLive = useSelector(adminSelectors.studentMonthsLive);
  const studentsRemote = useSelector(adminSelectors.studentMonthsRemote);
  const _schoolBuildings = useSelector(listsSelectors.schoolBuildings);

  const _neighborhoodPrincipals = useSelector(
    listsSelectors.neighborhoodPrincipalById,
  );

  const _schoolBuildingsPlusSchool = useSelector(
    listsSelectors.schoolBuildingsPlusSchool,
  );
  const _allTeachersNames = useSelector(listsSelectors.allTeachersNames);
  const _allChaburahs = useSelector(listsSelectors.allChaburahs);
  const _allNeighborhoods = useSelector(listsSelectors.allNeighborhoods);
  const _allSchools = useSelector(listsSelectors.allSchools);
  const _allSchoolBuildings = useSelector(listsSelectors.allSchoolBuildings);
  const _allPrincipals = useSelector(listsSelectors.allPrincipals);
  const _allTracks = useSelector(listsSelectors.allTracks);
  const neighborhoods = useSelector(listsSelectors.neighborhoodById);
  const userById = useSelector(listsSelectors.userById);

  const handleMonthlyPointsModalClose = (_data: typeof monthlyPointsModal) => {
    setMonthlyPointsModal(null);
  };
  const openAddMonthlyPointsModal = useCallback(
    async (studentDetails) => {
      const chaburah: any = await dispatch(
        studentActions.getChaburahMonth(
          studentDetails.student_months[0].chaburah_id,
          monthId,
        ),
      );
      const { first_name_yiddish, last_name_yiddish } = studentDetails;
      setMonthlyPointsModal({
        studentId: studentDetails.id,
        month_id: studentDetails.student_months[0].month_id,
        review_count: studentDetails.student_months[0].review_count,
        attendance_count: studentDetails.student_months[0].attendance_count,
        gathering: studentDetails.student_months[0].gathering,
        monthly_note: studentDetails.student_months[0].monthly_note,
        check_in: studentDetails.student_months[0].check_in,
        call_date: studentDetails.student_months[0].call_date,
        max_check_in: chaburah?.max_attendance_checks,
        max_review_count: chaburah?.max_review_checks,
        first_name_yiddish,
        last_name_yiddish,
      });
    },
    [dispatch, monthId],
  );
  const addedColumns: GridColDef[] = useMemo(() => {
    const getSchoolBuildingFromId = (params) => {
      const sb =
        _schoolBuildings.filter(
          (b) => b?.school_id === params.row?.school_building_id,
        )[0]?.name || "";
      return sb;
    };
    const getMenahel = (params) => {
      return (
        userById[params.row.student_months?.[0]?.principals_cache?.user_id]
          ?.name || ""
      );
    };
    const getStudentChabura = (params) => {
      return params?.row?.student_months[0]?.v_chaburah?.name || "";
    };
    const getKinyan = (params) => {
      return params?.row?.student_months[0]?.v_chaburah?.track || "";
    };
    const getSchoolFromId = (params) => {
      const sb =
        _schoolBuildingsPlusSchool.filter(
          (b) => b?.schoolBuildingId === params.row?.school_building_id,
        )[0]?.name || "";
      return sb;
    };
    const getStudentNeighborhood = (params) => {
      const id = params.row.customer?.address?.neighborhood_id;
      return neighborhoods[id]?.name || "";
    };

    return [
      ...STUDENT_MONTH_COLUMNS(openAddMonthlyPointsModal),

      {
        field: "chaburah",
        headerName: "חבורה",
        renderCell: getStudentChabura,
        valueGetter: getStudentChabura,
        flex: 2,
        type: "singleSelect",
        valueOptions: _allChaburahs,
      },
      {
        field: "kinyan",
        headerName: "קנין",
        renderCell: getKinyan,
        valueGetter: getKinyan,
        flex: 1.5,
        type: "singleSelect",
        valueOptions: _allTracks,
      },
      {
        field: "neighborhood",
        headerName: "Neighborhood",
        renderCell: getStudentNeighborhood,
        valueGetter: getStudentNeighborhood,
        flex: 1,
        type: "singleSelect",
        valueOptions: _allNeighborhoods,
      },

      {
        field: "rosh_kinyan",
        headerName: "ראש קנין",
        renderCell: getRoshKinyan,
        valueGetter: getRoshKinyan,
        flex: 1.5,
        type: "singleSelect",
        valueOptions: _allTeachersNames,
      },
      {
        field: "school_building_id",
        headerName: "School building",
        valueGetter: getSchoolBuildingFromId,
        renderCell: getSchoolBuildingFromId,
        flex: 1,
        type: "singleSelect",
        valueOptions: _allSchoolBuildings,
      },
      {
        field: "school",
        headerName: "School",
        valueGetter: getSchoolFromId,
        renderCell: getSchoolFromId,
        flex: 1,
        type: "singleSelect",
        valueOptions: _allSchools,
      },
      {
        field: "menahel",
        headerName: "מנהל",
        renderCell: getMenahel,
        valueGetter: getMenahel,
        flex: 1.5,
        type: "singleSelect",
        valueOptions: _allPrincipals,
      },
      {
        ...launch_icon,
        renderCell: (rowData) =>
          getLaunchLink("student_months", rowData.id, monthId),
        valueGetter: (rowData) =>
          `${REACT_APP_BASE_URL}/admin/student/${rowData.id}?monthId=${monthId}`,
      },
    ];
  }, [
    _allChaburahs,
    _allNeighborhoods,
    _allPrincipals,
    _allSchoolBuildings,
    _allSchools,
    _allTeachersNames,
    _allTracks,
    _schoolBuildings,
    _schoolBuildingsPlusSchool,
    monthId,
    neighborhoods,
    openAddMonthlyPointsModal,
    userById,
  ]);

  const bulkUpdateValues = (action) => {
    const valuesArray: any = [];
    const selectedArray = getStudentsByChaburah(chaburahFilter).filter(
      (filtered) => selectionModel.includes(filtered.id),
    );
    selectedArray.map((row) => {
      if (field === "phoneCallDate") {
        valuesArray.push({
          id: row.student_months[0].id,
          call_date: value ? value.replaceAll("/", "-") : value,
          student_id: row.student_months[0].student_id,
          month_id: row.student_months[0].month_id,
        });
      } else if (action === "Phone call - Student was reached") {
        valuesArray.push({
          id: row.student_months[0].id,
          student_id: row.student_months[0].student_id,
          month_id: row.student_months[0].month_id,
          check_in: "reached",
        });
      } else if (action === "Phone call - Student wasn't reached") {
        valuesArray.push({
          id: row.student_months[0].id,
          student_id: row.student_months[0].student_id,
          month_id: row.student_months[0].month_id,
          check_in: "called",
        });
      } else if (action === "Gathering - Student attended") {
        valuesArray.push({
          id: row.student_months[0].id,
          student_id: row.student_months[0].student_id,
          month_id: row.student_months[0].month_id,
          gathering: "attended",
        });
      } else if (
        action === "Gathering - Student was reached after the gathering"
      ) {
        valuesArray.push({
          id: row.student_months[0].id,
          student_id: row.student_months[0].student_id,
          month_id: row.student_months[0].month_id,
          gathering: "reached",
        });
      } else if (action === "Gathering - Student wasn't reached") {
        valuesArray.push({
          id: row.student_months[0].id,
          student_id: row.student_months[0].student_id,
          month_id: row.student_months[0].month_id,
          gathering: "called",
        });
      } else if (action === "No phone call") {
        valuesArray.push({
          id: row.student_months[0].id,
          student_id: row.student_months[0].student_id,
          month_id: row.student_months[0].month_id,
          check_in: null,
        });
      } else if (action === "No Gathering") {
        valuesArray.push({
          id: row.student_months[0].id,
          student_id: row.student_months[0].student_id,
          month_id: row.student_months[0].month_id,
          gathering: null,
        });
      }
    });
    dispatch(
      adminActions.updateStudentMonths({ students: valuesArray }, monthId),
    );
  };
  const getStudentsByChaburah = useCallback(
    (ch: typeof chaburahFilter) => {
      switch (ch) {
        case "masmidim":
          return studentsLive;
        case "cd":
          return studentsRemote;
        default:
          return students;
      }
    },
    [students, studentsLive, studentsRemote],
  );

  useEffect(() => {
    const _rows = getStudentsByChaburah(chaburahFilter);
    setRows(_rows);
  }, [chaburahFilter, getStudentsByChaburah]);

  const handleSelectMonthId = useCallback((newMonthId: number) => {
    navigate(`${AdminPages.studentMonth.path}?month_id=${newMonthId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const monthId: any = await dispatch(adminActions.getCurrentMonth());
      const mid = searchParams.get("month_id") || monthId;
      if (mid) {
        setMonthId(mid);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, monthId]);

  useEffect(() => {
    const mid = searchParams.get("month_id");
    if (mid) {
      setMonthId(mid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("month_id")]);

  useEffect(() => {
    if (monthId !== "0") dispatch(adminActions.getStudentsMonths(monthId));
  }, [dispatch, monthId]);

  const [inputValue, setInputValue] = useState("");
  const [field, setField] = useState("gathering");

  const options = useMemo(
    () =>
      field === "gathering"
        ? [
            "Gathering - Student attended",
            "Gathering - Student was reached after the gathering",
            "Gathering - Student wasn't reached",
            "No Gathering",
          ]
        : [
            "Phone call - Student was reached",
            "Phone call - Student wasn't reached",

            "No phone call",
          ],
    [field],
  );
  const [value, setValue] = useState<string | null>(options[0]);

  const months = useSelector(listsSelectors.monthById);
  const month = months[monthId || 0];
  const nextMonth = months[(+monthId || 0) + 1];

  useEffect(() => {
    if (field === "phoneCallDate") {
      setValue(null);
    } else {
      setValue(options[0]);
    }
  }, [field, options]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ mb: 0 }}>
        <Box sx={{ justifyContent: "left", width: "100%", display: "flex" }}>
          <MonthSelector
            monthId={monthId}
            onSelectMonthId={handleSelectMonthId}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RefreshGridButton
              monthId={monthId}
              refreshAction="getStudentsMonths"
            />
            <GridToolbarFilterButton />
            <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
          </Box>
        </Box>
        {selectionModel.length ? (
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#ebedf3",
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ ml: 3, mr: 4, color: "primary.main", fontWeight: "600" }}
              >
                {`Update ${selectionModel.length}`}
              </Typography>
              <Typography
                sx={{
                  mr: 3,
                  color: "#5C738E",
                  fontWeight: "600",
                }}
              >
                Field
              </Typography>
              <Select
                options={[
                  { label: "Gathering", value: "gathering" },
                  { label: "Phone call", value: "phoneCall" },
                  { label: "Phone call date", value: "phoneCallDate" },
                ]}
                value={field}
                width={"300px"}
                sx={{
                  width: "300px",
                  backgroundColor: "white",
                  "& MuiFormControl-root": { width: 300 },
                }}
                onChange={(e) => {
                  setField(e.target.value);
                }}
              />
              <Typography
                sx={{
                  mr: 3,
                  ml: 3,
                  color: "#5C738E",
                  fontWeight: "600",
                }}
              >
                to
              </Typography>
              {field !== "phoneCallDate" ? (
                <Autocomplete
                  value={value}
                  onChange={(event: any, newValue: string | null) => {
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={options}
                  sx={{ width: 300, mr: 3 }}
                  renderInput={(params) => <TextField {...params} label="" />}
                />
              ) : (
                <DatePicker
                  componentsProps={{
                    actionBar: {
                      actions: ["clear", "accept", "cancel"],
                    },
                  }}
                  inputFormat="MM/dd/yyyy"
                  label="Date"
                  value={value}
                  minDate={new Date(month?.start_date)}
                  maxDate={new Date(nextMonth?.start_date)}
                  onChange={(date: any) => {
                    if (date && isValid(date)) {
                      setValue(format(date, "yyyy/MM/dd"));
                      // If we impliment showing the yidish date on the toolbar we will
                      // setYiddishDate(getFormattedYiddishDate(date, true));
                    } else {
                      setValue(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: 300, mr: 3, backgroundColor: "white" }}
                    />
                  )}
                />
              )}
              <Button
                sx={{ width: 140, height: 48, mr: 3 }}
                variant={"contained"}
                onClick={() => {
                  bulkUpdateValues(inputValue);
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  }
  const [visibleRows, setVisibleRows] = useState<GridRowId[]>([]);

  return (
    <Page>
      <TopSection>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item xs={3}>
            <Typography variant="h3">Student months</Typography>
          </Grid>

          <Grid item xs={3}>
            <NeighborhoodSelector
              neighborhoodFilter={chaburahFilter}
              onSelect={setChaburahFilter}
            />
          </Grid>

          <Grid item xs={3} />
        </Grid>

        <Grid container spacing={2} direction="row" justifyContent="center">
          <Grid item xs={3}>
            <Box>
              <Search>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    apiRef.current.setQuickFilterValues([e.target.value]);
                  }}
                />
                <SearchIcon
                  sx={{ color: "#B4B8C8", height: 32, paddingTop: 1.5 }}
                />
              </Search>
            </Box>
          </Grid>
        </Grid>
      </TopSection>

      <BottomSection
        style={{ height: "calc(100vh - 140px)", paddingTop: "32px" }}
      >
        <DataGridPro
          pagination={true}
          rowsPerPageOptions={[
            10,
            25,
            50,
            100,
            rows?.length > 100 && rows.length,
          ]}
          apiRef={apiRef}
          sx={{
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
          onRowClick={(e) => {
            itemButtonClick(e);
          }}
          onStateChange={(state) => {
            const newRows = gridVisibleSortedRowIdsSelector(state);
            setVisibleRows(newRows);
          }}
          rows={rows}
          disableSelectionOnClick={true}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          columns={addedColumns}
          initialState={{
            columns: {
              columnVisibilityModel: hideColumns,
            },
            sorting: {
              sortModel: [{ field: "fullName", sort: "asc" }],
            },
            pagination: {
              pageSize: 25,
            },
          }}
          components={{
            Toolbar: CustomToolbar,
            Footer: () => CustomFooter(visibleRows.length),
          }}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
            panel: {
              sx: {
                ...dataGridFilterStyles,
              },
            },
          }}
        />
        <AddMonthlyPoints
          modal={monthlyPointsModal}
          onClose={handleMonthlyPointsModalClose}
        />
      </BottomSection>
    </Page>
  );
};
