import React from "react";
import { Typography } from "@mui/material";
import { GridFooterContainer, GridPagination } from "@mui/x-data-grid";

export function CustomFooter(results: number) {
  return (
    <GridFooterContainer>
      <Typography sx={{ ml: 3, color: "#000000" }} variant="subtitle2">
        {`${results ?? 0} results`}
      </Typography>
      <GridPagination />
    </GridFooterContainer>
  );
}
