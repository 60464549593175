import { AppState } from "../types";

export const prizeSelectors = {
  products(state: AppState) {
    return state.prizes.products;
  },
  product(state: AppState) {
    return state.prizes.product;
  },
  productSubmitSuccess(state: AppState) {
    return state.prizes.productSubmit.success;
  },
  redemptionSubmitSuccess(state: AppState) {
    return state.prizes.redemptionSubmit.success;
  },
  redemptions(state: AppState) {
    return state.prizes.redemptions;
  },

  redemption() {
    return (state: AppState) => {
      return state.prizes.redemption;
    };
  },
  redemptionItems(state: AppState) {
    return state.prizes.redemptionItems;
  },
  vendors(state: AppState) {
    return state.prizes.vendors;
  },
  prizeNumbers(state: AppState) {
    return state.prizes.prizeNumbers;
  },
};
