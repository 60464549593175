import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  pointsRowStyle,
  pointsStyle,
  starIcon,
  titleStyle,
} from "./PointsInfo.styles";
import { DoubleStarIcon, RemoveIcon } from "../../../../../components";

interface PointsCardProps {
  title: string;
  points: any;
  outOf?: any;
}

const PointsRow: FC<PointsCardProps> = ({ title, points, outOf }) => {
  return (
    <Stack
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={pointsRowStyle}
    >
      <Typography variant={"h6"} sx={titleStyle}>
        {title}
      </Typography>

      {points ? (
        <Stack direction={"row"} alignItems={"center"}>
          <DoubleStarIcon sx={starIcon} />
          <Typography variant={"body2"} component={"span"} sx={pointsStyle}>
            {points}
          </Typography>
        </Stack>
      ) : (
        <RemoveIcon />
      )}
      {outOf && (
        <Typography variant={"caption"}>{`Out of ${outOf}`}</Typography>
      )}
    </Stack>
  );
};

export default PointsRow;
