import React, { FC } from "react";
import { IconButton, Stack } from "@mui/material";
import { DeleteIcon, FormikInput } from "../../../../components";
import { removeBtnStyle, textField } from "./EditAnswerField.styles";
import { useFormikContext } from "formik";
import CheckBox from "../../../../components/CheckBox";
import { Fields } from "../schema";
import { Question } from "../../../../state";

interface Props {
  index: number;
}

const EditAnswerField: FC<Props> = ({ index }) => {
  const { values, handleChange, setFieldValue } = useFormikContext<Fields>();
  const handleRemoveTest = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const tq = JSON.parse(
      JSON.stringify(values.test_questions ?? []),
    ) as Question[];

    const ntq = tq.filter((t) => t.index !== index);

    ntq.forEach((t, index) => {
      t.index = index;
    });

    setFieldValue("test_questions", ntq);
  };

  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      {values?.test_questions?.length === index + 1 ? (
        <IconButton
          color="primary"
          sx={removeBtnStyle}
          onClick={handleRemoveTest}
        >
          <DeleteIcon />
        </IconButton>
      ) : (
        <IconButton sx={removeBtnStyle}></IconButton>
      )}

      <CheckBox
        name={`test_questions[${index}].extra_credit`}
        label={"Extra credit"}
        checked={!!values.test_questions?.[index]?.extra_credit}
        onChange={handleChange}
      />

      <FormikInput
        sx={textField}
        name={`test_questions[${index}].answer`}
        label="Correct answer"
      />
    </Stack>
  );
};

export default EditAnswerField;
