import React, { FC } from "react";
import { Box, CardMedia, Stack, Typography } from "@mui/material";
import { boxLeft, boxRight, content, wrapper } from "./NotFoundPage.styles";
import logo from "../../../assets/img/logo.png";
import { ElementLeft, ElementRight } from "../../../components";

const NotFoundPage: FC = () => {
  return (
    <Box sx={wrapper}>
      <Box sx={boxLeft}>
        <ElementLeft />
      </Box>

      <Box sx={boxRight}>
        <ElementRight />
      </Box>

      <Stack sx={content}>
        <CardMedia image={logo} className={"img"} />
        <Typography variant={"h3"} className={"code"}>
          404
        </Typography>
        <Typography variant={"h3"} className={"status"}>
          Page not found
        </Typography>
        <Typography variant={"body2"} className={"message"}>
          דברי תורה עניים במקום אחד ועשירים במקום אחר
        </Typography>
      </Stack>
    </Box>
  );
};

export default NotFoundPage;
