import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const gridStyles: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    "& .MuiDataGrid-row": {
      cursor: "pointer",
    },
  },
};
