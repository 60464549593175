import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  debounce,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Page, TopSection } from "../styles/GridCardsSearch.styles";
import { flattenWithoutPrefix, toInt, useLocation } from "../../../lib";
import Autocomplete from "../../../components/Autocomplete";
import { Option } from "../../../components/Select/Select";
import {
  adminActions,
  adminSelectors,
  prizeSelectors,
  listsActions,
  prizeActions,
  Redemption as RedemptionParent,
  RedemptionProduct as RedemptionProductParent,
  StudentSearchItem,
} from "../../../state";
import {
  AutocompleteAsync,
  AddIcon,
  DeleteIcon,
  WarningIcon,
  PrintIcon,
} from "../../../components";
import { numsWithCommasWithNeg, formatNumber } from "../../../lib";
import AdminPages from "..";
import { redemptionSchema } from "./schema";
import { Navigation } from "../../../lib";

interface Redemption extends RedemptionParent {
  redemption_products: redemptionProduct[];
  displayName?: string;
}

type redemptionProduct = RedemptionProductParent & {
  label?: string;
  value?: string;
  name?: string;
  image_link?: string | undefined;
  no_points: boolean;
  originalAmount?: number | undefined;
  originalProductId?: number | undefined;
  originalNoPoints?: boolean | undefined;
};

export function AddRedemption() {
  const [students, setStudents] = useState<Option[]>([]);
  const [searchStudent, setSearchStudent] = useState<string>("");
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const student = useSelector(adminSelectors.student);
  const redemptionSubmitSuccess = useSelector(
    prizeSelectors.redemptionSubmitSuccess,
  );
  const id = params?.id;
  const isEdit = !!id;
  const redemption = useSelector(prizeSelectors.redemption());
  const originalTotal = redemption?.point_value || 0;
  const location = useLocation();
  const studentFromQuery = location?.query?.id;
  const calculateProductPoints = (p) =>
    p.no_points
      ? 0
      : !!p.originalAmount &&
        p.originalAmount === p.amount &&
        p.product_id === p.originalProductId &&
        p.point_value
      ? p.point_value
      : !!p.product_id && p.product_id === p?.originalProductId
      ? ((p.point_value || 0) / (p.originalAmount || 1) ||
          p.product?.point_value ||
          0) * (p.amount || 1)
      : (p.point_value || 0) * (p.amount || 1);

  useEffect(() => {
    if (isEdit) {
      dispatch(prizeActions.getRedemption(id));
    } else {
      dispatch(adminActions.setStudent(null));
      dispatch(prizeActions.setRedemption(undefined));
    }
  }, [dispatch, id, isEdit]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchStudent = useCallback(
    debounce((event: React.SyntheticEvent, search: string) => {
      event?.stopPropagation();
      search ? setSearchStudent(search) : setStudents([]);
    }, 300),
    [],
  );
  useEffect(() => {
    (async () => {
      let results;
      setStudents([]);
      if (searchStudent) {
        results = await dispatch(listsActions.getStudentByName(searchStudent));
      }
      if (results) {
        const co = (results as unknown as StudentSearchItem[]).map(
          ({ id, name }) => ({ label: name, value: id } as unknown as Option),
        );

        setStudents(co);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStudent]);

  const handleSelectStudent = (_e, v) => {
    setStudent(v?.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const productInitialState: redemptionProduct = {
    delivered: false,
    amount: undefined,
    no_points: false,
  };

  const initialState = useMemo(
    () => ({
      redemption_products: [productInitialState],
      student_id: undefined,
      displayName: "",
    }),
    [productInitialState],
  );
  const formik = useFormik<Redemption>({
    initialValues: initialState,
    enableReinitialize: true,
    validationSchema: redemptionSchema,
    onSubmit: () => {
      const { redemption_products, ...rest } = values;
      const items = redemption_products?.map(
        ({ delivered, amount, id, product_id, no_points }) => ({
          delivered,
          amount,
          product_id,
          id,
          no_points,
        }),
      );

      dispatch(
        prizeActions.addRedemption({
          ...rest,
          items,
        }),
      );
    },
  });
  const {
    values,
    setFieldValue,
    touched,
    errors,
    setValues,
    setTouched,
    handleBlur,
  } = formik;

  const setStudent = useCallback(
    (id) => {
      dispatch(adminActions.getStudent(id));
      setFieldValue("student_id", id);
    },
    [dispatch, setFieldValue],
  );
  useEffect(() => {
    if (studentFromQuery) {
      setStudent(+studentFromQuery);
      navigate(`${location.pathname}`, { replace: true });
    }
  }, [location.pathname, navigate, setStudent, studentFromQuery]);

  useEffect(() => {
    if (shouldNavigate && redemptionSubmitSuccess) {
      dispatch(prizeActions.setRedemptionSubmit(false));
      navigate(`${AdminPages.prizes.path}?tab=redemptions`);
    } else if (redemptionSubmitSuccess) {
      dispatch(prizeActions.setRedemptionSubmit(false));
      setValues(initialState);
      setStudent("");
      setTouched({});
    }
  }, [
    dispatch,
    initialState,
    navigate,
    redemptionSubmitSuccess,
    setStudent,
    setTouched,
    setValues,
    shouldNavigate,
  ]);

  useEffect(() => {
    if (isEdit && redemption) {
      setValues({
        ...redemption,
        redemption_products: (redemption.redemption_products?.map((p) => {
          const { id, point_value, ...productRest } = p;

          return {
            ...prizeOptionSetter(flattenWithoutPrefix(productRest)),
            point_value,
            no_points: !point_value,
            originalAmount: p.amount,
            originalProductId: p.product_id,
            originalNoPoints: !point_value,
            id,
          };
        }) || []) as redemptionProduct[],
      });
      setStudent(redemption.v_students_all?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, redemption]);

  useEffect(() => {
    if (student) {
      const {
        first_name,
        last_name,
        first_name_yiddish,
        last_name_yiddish,
        pin,
        customer: { phone_numbers = [] } = {},
      } = student;
      setFieldValue(
        "displayName",
        `${first_name} ${last_name}  - Pin: ${pin} -  ${
          phone_numbers[0]?.number || ""
        } - ${first_name_yiddish} ${last_name_yiddish}  `,
      );
    } else {
      setFieldValue("displayName", "");
    }
  }, [setFieldValue, student]);

  const setProductFields = (index, newValues) => {
    const v = [...(values.redemption_products || [])];
    v[index] = { ...v[index], ...newValues };
    setFieldValue(`redemption_products`, v);
  };

  const prizeOptionSetter = (pr) => {
    const { id, delivered, ...prizeRest } = pr;
    const { name, name_yiddish, prize_number, active } = prizeRest;
    let label = [prize_number, name_yiddish, name].join(" - ");
    if (!active) {
      label += " - inactive";
    }

    return {
      label,
      value: id,
      product_id: id,
      ...prizeRest,
      delivered: !!delivered,
      product: prizeRest,
    };
  };

  const originalBalance =
    parseInt(student?.cache_point.balance || "0") +
    parseInt(originalTotal || "0");

  const total: number =
    values.redemption_products?.reduce(
      (p, c) => p + calculateProductPoints(c),
      0,
    ) || 0;

  const balance = originalBalance - total;

  return (
    <Page>
      <TopSection
        style={{
          marginTop: "0px",
          paddingTop: "15px",
          paddingBottom: "15px",
          height: "auto",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <IconButton
            color="primary"
            sx={{
              mr: 1,
              "@media print": {
                display: "none",
              },
            }}
            onClick={() => {
              Navigation.history.back();
            }}
          >
            <ArrowBackIcon sx={{ color: "#8495B2" }} />
          </IconButton>

          <Typography variant={"h3"} sx={{ color: "#082B65" }}>
            <Box
              component="span"
              sx={{
                "@media print": {
                  display: "none",
                },
              }}
            >
              {" "}
              {isEdit ? "Edit" : "Add"}
            </Box>{" "}
            <Box
              component="span"
              sx={{
                "@media print": {
                  textTransform: "capitalize",
                  paddingLeft: "20px",
                },
              }}
            >
              redemption
            </Box>
          </Typography>
          <IconButton
            sx={{ marginLeft: "16px" }}
            onClick={() => {
              window.print();
            }}
          >
            <PrintIcon color="primary" />
          </IconButton>
        </Stack>
      </TopSection>
      <Box
        sx={{
          maxWidth: "900px",
          paddingLeft: "50px",
          paddingRight: "50px",
          marginBottom: "25px",
        }}
      >
        <Box>
          <Autocomplete
            disabled={isEdit}
            options={students}
            filterOptions={(x) => x}
            value={values.displayName || ""}
            onChange={handleSelectStudent}
            onInputChange={handleSearchStudent}
            placeholder={"Student"}
            noOptionsText={"Search students"}
            error={touched.student_id && !!errors.student_id}
            onBlur={handleBlur}
            id={`student`}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={1.5}>
            <Typography fontSize={14}>No points</Typography>
          </Grid>
          <Grid item sm={5} fontSize={14}>
            <Typography fontSize={14}>Product</Typography>
          </Grid>
          <Grid
            item
            sm={1}
            fontSize={14}
            sx={{
              "@media print": {
                marginLeft: "240px",
              },
            }}
          >
            <Typography
              sx={{
                "@media print": {
                  fontSize: "11px",
                },
              }}
              fontSize={14}
            >
              Picture
            </Typography>
          </Grid>
          <Grid item sm={1.5}>
            <Typography
              sx={{
                "@media print": {
                  fontSize: "11px",
                },
              }}
              fontSize={14}
            >
              Amount
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography
              sx={{
                "@media print": {
                  fontSize: "11px",
                  marginLeft: "35px",
                },
              }}
              fontSize={14}
            >
              Points
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography
              sx={{
                "@media print": {
                  fontSize: "11px",
                },
              }}
              fontSize={14}
            >
              Delivered
            </Typography>
          </Grid>
        </Grid>

        {values.redemption_products?.map((p, i) => {
          const amountError = (errors?.redemption_products?.[i] as any)?.amount;
          const amountTouched = !!touched?.redemption_products?.[i].amount;
          return (
            <Grid container spacing={2} alignItems="center" key={i}>
              <Grid item sm={1.5}>
                <Checkbox
                  checked={p.no_points || false}
                  size="small"
                  onChange={(q) => {
                    setProductFields(i, { no_points: q.target.checked });
                  }}
                  onBlur={handleBlur}
                  id={`${i}_no_points`}
                  disabled={!!p.originalProductId && p.originalNoPoints}
                />
              </Grid>
              <Grid item sm={5}>
                <AutocompleteAsync
                  sx={{
                    "@media print": {
                      width: "300px",
                      "& .MuiOutlinedInput-root": {
                        paddingRight: "8px !important",
                        fontSize: "14px",
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        display: "none",
                      },
                    },
                  }}
                  getter={prizeActions.searchProducts}
                  value={p.label || ""}
                  optionSetter={prizeOptionSetter}
                  onChange={(selection) => {
                    setProductFields(i, selection);
                  }}
                  onBlur={handleBlur}
                  disabled={!!p.originalProductId}
                  id={`${i}_product_id`}
                  onClear={() => {
                    setProductFields(i, {
                      product_id: undefined,
                      point_value: undefined,
                      label: "",
                    });
                  }}
                  placeholder={"Product"}
                  noOptionsText={"Search products"}
                />
              </Grid>
              <Grid item sm={1}>
                <img width="60px" src={p?.image_link} />
              </Grid>
              <Grid item sm={1.5} maxWidth="100px" minWidth="100px">
                {p.product?.is_amount && (
                  <TextField
                    value={p.amount || ""}
                    type="number"
                    size="small"
                    onChange={(q) => {
                      setProductFields(i, { amount: toInt(q.target.value) });
                    }}
                    error={amountTouched && !!errors && amountError}
                    helperText={amountTouched && !!errors && amountError}
                    onBlur={handleBlur}
                    id={`${i}_amount`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {p.product?.currency === "GBP" ? "£" : "$"}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                sm={1}
                sx={{
                  "@media print": {
                    minWidth: "50px",
                  },
                }}
              >
                <Typography
                  sx={{
                    "@media print": {
                      fontSize: "14px",
                    },
                  }}
                >
                  {calculateProductPoints(p)}
                </Typography>
              </Grid>

              <Grid item sm={1} textAlign="right">
                <Checkbox
                  checked={p.delivered || false}
                  size="small"
                  onBlur={handleBlur}
                  id={`${i}_delivered`}
                  onChange={(q) => {
                    setProductFields(i, { delivered: q.target.checked });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={1}
                sx={{
                  "@media print": {
                    display: "none",
                  },
                }}
              >
                <IconButton
                  disabled={i === 0 && values.redemption_products.length === 1}
                  onClick={() => {
                    setFieldValue(
                      `redemption_products`,
                      values.redemption_products.filter((rp, ind) => ind !== i),
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
        <Box sx={{ marginTop: "10px" }}>
          <Button
            variant="outlined"
            onClick={() => {
              setFieldValue("redemption_products", [
                ...(values.redemption_products || []),
                productInitialState,
              ]);
            }}
          >
            <AddIcon sx={{ marginRight: "15px" }} /> Product
          </Button>
        </Box>
        <Box
          sx={{
            border: "1px solid #B4B8C8",
            borderRadius: "4px",
            marginTop: "30px",
            maxWidth: "400px",
            padding: "20px",
            fontSize: "13px",
            paddingRight: "40px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="20px"
          >
            <Typography>Student's current balance</Typography>
            <Typography>{formatNumber(originalBalance) || 0}</Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="20px"
          >
            <Typography>Redemption total</Typography>
            <Typography>{numsWithCommasWithNeg(total) || 0}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ color: balance < 0 ? "#BB2018" : undefined }}>
              Student's new balance
            </Typography>
            <Typography sx={{ color: balance < 0 ? "#BB2018" : undefined }}>
              {formatNumber(balance) || 0}
            </Typography>
          </Box>
        </Box>
        {balance < 0 &&
          (!isEdit
            ? !!Object.keys(touched).length
            : balance < parseInt(student?.cache_point.balance || "0")) && (
            <Box
              bgcolor="rgba(187,32,24,0.16)"
              borderRadius="3px"
              padding="8px"
              marginTop="24px"
              sx={{
                "@media print": {
                  display: "none",
                },
              }}
            >
              <Box display="inline-flex">
                <WarningIcon sx={{ color: "#BB2018", marginRight: "16px" }} />
                <Box>
                  <Typography fontSize="14px">
                    Insufficient points! Submitting this redemption will result
                    in the student having a negative{" "}
                  </Typography>
                  <Typography fontSize="14px">
                    balance. Are you sure you want to continue?
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          width="100%"
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        >
          <Grid item sm={12}>
            <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          </Grid>
          <Grid item sm={3}>
            <Button
              sx={{ width: "210px" }}
              variant="outlined"
              onClick={() => {
                navigate(`${AdminPages.prizes.path}?tab=redemptions`);
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item sm={3}>
            <Button
              sx={{ width: "210px" }}
              variant="contained"
              onClick={async () => {
                await formik.submitForm();
                setShouldNavigate(true);
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid item sm={5}>
            {isEdit ? (
              <Button
                sx={{ color: "#B20000" }}
                variant="text"
                onClick={() => {
                  dispatch(prizeActions.deleteRedemption(values.id));
                  navigate(`${AdminPages.prizes.path}?tab=redemptions`);
                }}
              >
                <DeleteIcon sx={{ marginRight: "5px" }} /> Delete
              </Button>
            ) : (
              <Button
                sx={{ width: "210px" }}
                variant="contained"
                onClick={async () => {
                  await formik.submitForm();
                }}
              >
                Save & add another
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}
