import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const container: SxProps<Theme> = {
  "& .MuiTypography-root": {
    py: "1px",
    fontSize: "16px",
    color: "#5C738E",
    letterSpacing: 0,
    lineHeight: "17px",
  },
};
