import { styled } from "@mui/material/styles";
import { SxProps, Theme } from "@mui/material";

export const RootStyle = styled(`div`)(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
  backgroundSize: "100%",
  display: "flex",
  height: "100vh",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

export const LeftPanel = styled(`div`)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: 30,
  display: "flex",
  justifyContent: "center",
  position: "relative",
  "& img": {
    height: "auto",
    maxWidth: "66%",
  },
  [theme.breakpoints.up("md")]: {
    height: "100vh",
    width: "66%",
    paddingTop: 120,
    "& img": {
      height: 120,
    },
  },
}));

export const RightPanel = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 8,
  marginBottom: 100,
  maxWidth: "calc(100vw - 8px)",
  [theme.breakpoints.up("md")]: {
    height: "auto",
    minWidth: 520,
    width: "34%",
  },
}));
export const OutletPanel = styled(`div`)(() => ({
  width: 360,
}));

export const boxLeft = (theme: Theme): SxProps<Theme> => {
  return {
    position: "absolute",
    left: 0,
    top: 0,

    "> .MuiSvgIcon-root": {
      [theme.breakpoints.down("xl")]: {
        height: "471px",
        width: "225px",
      },

      [theme.breakpoints.down("lg")]: {
        height: "369px",
        width: "175px",
      },

      [theme.breakpoints.down("md")]: {
        height: "250px",
        width: "120px",
      },

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  };
};

export const boxRight = (theme: Theme): SxProps<Theme> => {
  return {
    position: "absolute",
    right: 0,
    top: 0,

    "> .MuiSvgIcon-root": {
      [theme.breakpoints.down("xl")]: {
        height: "700px",
        width: "246px",
      },

      [theme.breakpoints.down("lg")]: {
        height: "600px",
        width: "209px",
      },

      [theme.breakpoints.down("md")]: {
        height: "400px",
        width: "140px",
      },

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  };
};
