import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  listsSelectors,
  NewRoshKinyan,
} from "../../../../../state";
import { FormikProps } from "formik/dist/types";
import { Fields } from "./schema";
import { Props } from "./AddRoshKinyan";
import { FormikHelpers } from "formik";
import { SelectOption } from "../../../../../components/Select";

export function useAddRoshKinyan({ modal, onClose }: Props) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(!!modal);
  const [users, setUsers] = useState<SelectOption[]>([]);
  const [neighborhoods, setNeighborhoods] = useState<SelectOption[]>([]);

  const innerRef = useRef<FormikProps<Fields>>(null);

  const _users = useSelector(listsSelectors.users);
  const _userById = useSelector(listsSelectors.userById);
  const _neighborhoods = useSelector(listsSelectors.neighborhoods);
  const _neighborhoodById = useSelector(listsSelectors.neighborhoodById);

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    onClose ? onClose(null) : setOpen(false);
  };

  const handleSubmit = async (
    values: Fields,
    { setSubmitting }: FormikHelpers<Fields>,
  ) => {
    const { user_id, neighborhood_id, default: _default, editGroup } = values;
    let result;
    if (editGroup) {
      //TODO: backend not saving default
      result = await dispatch(adminActions.updateTeacher(values));
    } else {
      const user: { user_id: number } = {
        user_id: user_id!,
      };

      result = await dispatch<
        NewRoshKinyan | null | ReturnType<typeof adminActions.createRoshKinyan>
      >(adminActions.createRoshKinyan(user));
    }

    setSubmitting(false);

    if (result) {
      const data: typeof modal = {
        ...modal!,
        chaburah_teacher_id: (result as unknown as NewRoshKinyan).id,
        user_id: user_id!,
        user_name: _userById[user_id!].name_yiddish,
        neighborhood_id: neighborhood_id!,
        neighborhood: _neighborhoodById[neighborhood_id!].name,
        default: _default!,
      };

      onClose?.(data);
    }
  };

  useEffect(() => {
    const u = _users
      .filter(
        (u) => u.role_id[3] || u.id === innerRef?.current?.values?.user_id,
      )
      .map(
        ({ id, name_yiddish }) => ({ name: name_yiddish, id } as SelectOption),
      );

    const n = _neighborhoods.map(
      ({ id, name }) => ({ name, id } as SelectOption),
    );

    setUsers(u);
    setNeighborhoods(n);
  }, [_users, _neighborhoods]);

  useEffect(() => {
    !modal && innerRef?.current?.resetForm();

    if (modal?.mode === "edit") {
      innerRef.current?.setValues({
        ...innerRef.current.values,
        editGroup: modal.editGroup,
        chaburah_id: modal.chaburah_id!,
        user_id: modal.user_id!,
        neighborhood_id: modal.neighborhood_id!,
        default: modal.default!,
        id: modal.id,
      });
    } else if (modal?.mode === "insert") {
      innerRef.current?.setValues({
        ...innerRef.current.values,
        editGroup: modal.editGroup,
        chaburah_id: modal.chaburah_id!,
      });
    }

    setOpen(!!modal);
  }, [modal]);

  return {
    innerRef,
    open,
    users,
    neighborhoods,
    handleClose,
    handleSubmit,
  };
}
