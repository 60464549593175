import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormikHelpers, FormikProps } from "formik";
import { Fields } from "./schema";
import {
  customerActions,
  customerSelectors,
  listsSelectors,
} from "../../../state";
import AdminPages from "../index";
import { capitalizeFirstLetterOfEachWOrd, toInt } from "../../../lib";
import { useParams } from "react-router";
import { Customer, UpdatedCustomer } from "./types";
import { status } from "../../../constants";

export function useEditCustomerPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const innerRef = useRef<FormikProps<Fields>>(null);
  const customer = useSelector(customerSelectors.customer);
  const countryCodes = useSelector(listsSelectors.optionsCountryCodes);
  const [data, setData] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const validatePhone = async (e) => {
    const validate: any = await dispatch(
      customerActions.getCustomersValidate(e.target.value, params.id),
    );
    if (validate?.id) {
      setData(validate);
      setShowModal(true);
    }
  };
  const handleBackButtonClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    !!params.id &&
      toInt(params.id) &&
      navigate(AdminPages.customer.path.replace(":id", params.id));
  };

  const handleSubmit = async (
    {
      last_name_yiddish,
      first_name_yiddish,
      first_name,
      last_name,
      home_phone_id,
      home_country_code_id,
      home_phone,
      mobile_phone_id,
      mobile_country_code_id,
      mobile_phone,
      email,
      address_id,
      address,
      address_2,
      city,
      state,
      zip,
      country_id,
      currency,
      neighborhood_id,
      status_id,
      notes,
      completed,
    }: Fields,
    { setSubmitting, setErrors }: FormikHelpers<Fields>,
  ) => {
    const data: UpdatedCustomer = {
      first_name_yiddish,
      last_name_yiddish,
      first_name: capitalizeFirstLetterOfEachWOrd(first_name) || null,
      last_name: capitalizeFirstLetterOfEachWOrd(last_name) || null,
      home_phone_id,
      home_country_code_id,
      home_phone: home_phone || null,
      mobile_phone_id,
      mobile_country_code_id,
      mobile_phone: mobile_phone || null,
      address_id,
      address,
      address_2: address_2 || null,
      city: capitalizeFirstLetterOfEachWOrd(city) || null,
      state: state || null,
      zip: zip?.toUpperCase() ?? null,
      country_id,
      currency: currency || null,
      email: email || null,
      neighborhood_id,
      status_id,
      notes: notes?.trim() || null,
      completed,
    };

    const isOk: any = await dispatch(
      customerActions.updateCustomer(params.id!, data),
    );
    if (isOk?.message === "Validation fails for Invalid home phone format") {
      setErrors({
        home_country_code_id: "Invalid format for the country selected.",
      });
    } else if (
      isOk?.message === "Validation fails for Invalid cellphone format"
    ) {
      setErrors({ mobile_phone: "Invalid format for the country selected." });
    } else {
      isOk && handleBackButtonClick();
    }
    setSubmitting(false);
  };

  // not used?
  // const handleStatusMethod = (
  //   status: number,
  //   e: React.MouseEvent<HTMLButtonElement>,
  // ) => {
  //   e.stopPropagation();

  //   innerRef?.current?.setFieldValue("status_id", status);
  // };

  useEffect(() => {
    !!params.id && dispatch(customerActions.getCustomer(+params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    if (customer) {
      const {
        first_name_yiddish,
        last_name_yiddish,
        first_name,
        last_name,
        phone_numbers,
        email,
        address_id,
        address,
        currency,
        completed,
        status_id,
        notes,
      } = customer as unknown as Customer;

      const fields: Fields = {
        first_name_yiddish: first_name_yiddish ?? "",
        last_name_yiddish: last_name_yiddish ?? "",
        first_name,
        last_name,
        home_phone_id:
          phone_numbers?.filter((p) => p.type === "home")?.[0]?.id ?? 0,
        home_country_code_id:
          phone_numbers?.filter((p) => p.type === "home")?.[0]
            ?.country_code_id ?? 0,
        home_phone:
          phone_numbers?.filter((p) => p.type === "home")?.[0]?.number ?? "",
        mobile_phone_id:
          phone_numbers?.filter((p) => p.type === "cell")?.[0]?.id ?? 0,
        mobile_country_code_id:
          phone_numbers?.filter((p) => p.type === "cell")?.[0]
            ?.country_code_id ?? 0,
        mobile_phone:
          phone_numbers?.filter((p) => p.type === "cell")?.[0]?.number ?? "",
        email,
        address_id,
        address: address?.address ?? "",
        address_2: address?.address_2 ?? "",
        city: address?.city ?? "",
        state: address?.state ?? "",
        zip: address?.zip ?? "",
        country_id: address?.country_id ?? 0,
        currency: currency ?? "",
        neighborhood_id: address?.neighborhood_id ?? 0,
        notes: notes ?? "",
        status_id,
        completed,
        canDeactivate: !customer.students?.find(
          (s) =>
            s.status_id === status.ACTIVE ||
            s.status_id === status.DRAFT ||
            s.status_id === status.PENDING,
        ),
      };

      innerRef?.current?.setValues(fields);
    }
  }, [customer]);
  const totalCharged = customer?.v_customer_balance_detail?.charged;
  const totalPayed = customer?.v_customer_balance_detail?.paid;
  const isCurrencyDisabled =
    !!customer?.students?.length ||
    (!!totalCharged && +totalCharged < 0) ||
    (!!totalPayed && +totalPayed > 0);

  return {
    innerRef,
    countryCodes,
    handleBackButtonClick,
    validatePhone,
    showModal,
    setShowModal,
    data,
    handleSubmit,
    isCurrencyDisabled,
    // handleStatusMethod,
  };
}
