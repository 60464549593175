import { useCallback, useEffect, useMemo, useState } from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Person,
  People,
  Feed,
  School,
  Settings,
  ExpandLess,
  ExpandMore,
  EmojiEvents,
  Link,
} from "@mui/icons-material";
import AdminPages from "../../pages/admin";
import { To, useLocation, useNavigate } from "react-router-dom";
import { Box, Collapse, ListItemButton, ListItemText } from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import { useMobile } from "../../themes";
import {
  authSelectors,
  uiActions,
  useDispatch,
  useSelector,
} from "../../state";

const ROOT = "<ROOT>";

interface MenuItem {
  label: string;
  parent: string;
  Icon?: any;
  to: string;
  hiddenLinks?: string[];
  nested?: MenuItem[];
  hide?: boolean;
  outsideLink?: boolean;
}

function getSideBarItems(isAdmin) {
  const mi: MenuItem[] = [
    {
      label: "Customers",
      parent: ROOT,
      Icon: Person,
      to: AdminPages.dashboard.path,
    },
    {
      label: "Students",
      parent: ROOT,
      Icon: School,
      to: AdminPages.student.path,
      nested: [
        {
          label: "Student months",
          parent: AdminPages.student.path,
          to: AdminPages.studentMonth.path,
        },
      ],
    },
    {
      label: "Groups",
      parent: ROOT,
      Icon: People,
      to: AdminPages.groups.path,
      hiddenLinks: [
        AdminPages.createGroup.path,
        AdminPages.editGroup.path.replace(":id", ""),
      ],
      nested: [
        {
          label: "Group settings",
          parent: AdminPages.groups.path,
          to: AdminPages.groupSettings.path,
        },
      ],
    },
    {
      label: "Billing",
      parent: ROOT,
      Icon: Feed,
      to: AdminPages.billing.path,
      hide: !isAdmin,
    },
    {
      label: "Prizes",
      parent: ROOT,
      Icon: EmojiEvents,
      to: isAdmin ? AdminPages.prizes.path : AdminPages.redemptions.path,
    },
    {
      label: "Settings",
      parent: ROOT,
      Icon: Settings,
      to: AdminPages.settings.path,
      hide: !isAdmin,
    },
    {
      label: "Resources",
      parent: ROOT,
      Icon: Link,
      to: "https://kinyenmishna.sharepoint.com/:f:/s/readyfiles/EgngWhKPaMdPhQ3VhXK6ii0Bb_JEcs0dALDkVe8Kh5jWJw?e=nMIphI",
      outsideLink: true,
    },
  ];
  buildFlatMenuList(mi);
  return mi;
}

const flatMenuList: { [key: string]: MenuItem } = {};

const buildFlatMenuList = (arr: MenuItem[]) => {
  if (arr) {
    arr.forEach((m) => {
      flatMenuList[m.to] = m;
      buildFlatMenuList(m.nested ?? []);
    });
  }
};

interface SelectedMenus {
  [key: string]: boolean;
}

export default function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const isAdmin = useSelector(authSelectors.isAdmin);

  const mi = useMemo(() => getSideBarItems(isAdmin), [isAdmin]);

  const [openStates, setOpenStates] = useState<SelectedMenus>({});

  const itemButtonClick = (to: To, outsideLink) => {
    if (isMobile) {
      dispatch(uiActions.setMenuOpen(false));
    }
    if (outsideLink) {
      window.open(to as string, "_blank");
    } else {
      navigate(to);
    }
  };

  const isMenuSelected = useCallback(
    (to: string, hiddenLinks: string[] = []) => {
      return (
        location.pathname === to ||
        location.pathname.includes(to + "/") ||
        location.pathname + location.search === to ||
        hiddenLinks.includes(to) ||
        hiddenLinks.some((s) => location.pathname.includes(s))
      );
    },
    [location.pathname, location.search],
  );

  useEffect(() => {
    if (
      !!flatMenuList[location.pathname] &&
      flatMenuList[location.pathname].parent !== ROOT
    ) {
      setOpenStates((old) => ({
        ...old,
        [flatMenuList[location.pathname].parent]: true,
      }));

      return;
    }

    Object.keys(flatMenuList).forEach((key) => {
      if (
        flatMenuList[key].parent !== ROOT &&
        flatMenuList?.[key]?.hiddenLinks?.some((s) =>
          location.pathname.includes(s),
        )
      ) {
        setOpenStates((old) => ({
          ...old,
          [flatMenuList?.[key]?.parent]: true,
        }));
      }
    });
  }, [location.pathname]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box>
        <List>
          {mi
            ?.filter((m) => !m.hide)
            .map(
              ({ label, Icon, to, nested, hiddenLinks, outsideLink }, idx) => {
                // @ts-ignore
                return (
                  <div key={idx}>
                    {nested ? (
                      <>
                        <ListItemButton
                          onClick={() => itemButtonClick(to, outsideLink)}
                          key={label}
                          selected={isMenuSelected(to, hiddenLinks)}
                        >
                          <ListItemIcon
                            sx={{
                              color: isMenuSelected(to, hiddenLinks)
                                ? "primary.contrastText"
                                : "text.disabled",
                            }}
                          >
                            <Icon />
                          </ListItemIcon>
                          <ListItemText
                            primary={label}
                            primaryTypographyProps={{
                              variant: "h6",
                              sx: {
                                ml: -3,
                                color: isMenuSelected(to, hiddenLinks)
                                  ? "primary.contrastText"
                                  : "text.disabled",
                              },
                            }}
                          />

                          {openStates[to] ? (
                            <ExpandLess
                              sx={{
                                color: isMenuSelected(to, hiddenLinks)
                                  ? "primary.contrastText"
                                  : "text.disabled",
                              }}
                              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                                e.stopPropagation();
                                setOpenStates({
                                  ...openStates,
                                  [to]: !openStates[to],
                                });
                              }}
                            />
                          ) : (
                            <ExpandMore
                              sx={{
                                color: isMenuSelected(to, hiddenLinks)
                                  ? "primary.contrastText"
                                  : "text.disabled",
                              }}
                              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                                e.stopPropagation();
                                setOpenStates({
                                  ...openStates,
                                  [to]: !openStates[to],
                                });
                              }}
                            />
                          )}
                        </ListItemButton>

                        <Collapse
                          in={openStates[to]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {nested.map(
                              ({ label, to, hiddenLinks, outsideLink }) => {
                                return (
                                  <ListItemButton
                                    onClick={() =>
                                      itemButtonClick(to, outsideLink)
                                    }
                                    key={label}
                                    selected={isMenuSelected(to, hiddenLinks)}
                                    sx={{ pl: 9 }}
                                  >
                                    <ListItemText
                                      primary={label}
                                      primaryTypographyProps={{
                                        variant: "h6",
                                        sx: {
                                          ml: -3,
                                          color: isMenuSelected(to, hiddenLinks)
                                            ? "primary.contrastText"
                                            : "text.disabled",
                                        },
                                      }}
                                    />
                                  </ListItemButton>
                                );
                              },
                            )}
                          </List>
                        </Collapse>
                      </>
                    ) : (
                      <ListItemButton
                        onClick={() => itemButtonClick(to, outsideLink)}
                        key={label}
                        selected={isMenuSelected(to, hiddenLinks)}
                      >
                        <ListItemIcon
                          sx={{
                            color: isMenuSelected(to, hiddenLinks)
                              ? "primary.contrastText"
                              : "text.disabled",
                          }}
                        >
                          <Icon />
                        </ListItemIcon>
                        <ListItemText
                          primary={label}
                          primaryTypographyProps={{
                            variant: "h6",
                            sx: {
                              ml: -3,
                              color: isMenuSelected(to, hiddenLinks)
                                ? "primary.contrastText"
                                : "text.disabled",
                            },
                          }}
                        />
                      </ListItemButton>
                    )}
                  </div>
                );
              },
            )}
        </List>
      </Box>
      <Box>
        <List>
          <ProfileMenu />
        </List>
      </Box>
    </Box>
  );
}
