import React, { FC } from "react";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import {
  bottomSectionStyle,
  tabsStyle,
  topSectionStyle,
} from "./SettingsPage.styles";
import SimplePageHeader from "../../../components/SimplePageHeader";
import { TabType } from "./types";
import { Tab, Tabs } from "@mui/material";
import { useSettingsPage } from "./useSettingsPage";

const SettingsPage: FC = () => {
  const { tab, TabComponent, handleTabChange } = useSettingsPage();

  return (
    <Page>
      <TopSection style={topSectionStyle}>
        <SimplePageHeader title={"Settings"} />
      </TopSection>

      <BottomSection style={bottomSectionStyle}>
        <Tabs value={tab} onChange={handleTabChange} sx={tabsStyle}>
          <Tab label="Neighborhoods" value={"neighborhoods" as TabType} />
          <Tab label="Months" value={"months" as TabType} />
        </Tabs>

        {TabComponent}
      </BottomSection>
    </Page>
  );
};

export default SettingsPage;
