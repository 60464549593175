import { SxProps, Theme } from "@mui/material";

export const container: SxProps<Theme> = {
  minWidth: "500px",
};

export const content: SxProps<Theme> = {
  p: "32px 40px",
};

export const btnSx: SxProps<Theme> = {
  minWidth: "140px",
};
