import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import AdminPages from "../index";
import { PageMode } from "./types";

export function useGetPageMode() {
  const params = useParams();
  const location = useLocation();

  const pageMode: PageMode =
    location.pathname ===
    AdminPages.editGroup.path.replace(":id", params?.id ?? "")
      ? "edit"
      : "insert";

  return { pageMode, id: params.id };
}

export const isDeepEqual = (param1, param2) => {
  if (param1 === undefined && param2 === undefined) return true;
  else if (param1 === undefined || param2 === undefined) return false;
  else if (param1?.constructor !== param2?.constructor) return false;

  if (param1?.constructor === Array) {
    if (param1.length !== param2.length) return false;
    for (let i = 0; i < param1.length; i++) {
      if (
        param1[i]?.constructor === Array ||
        param1[i]?.constructor === Object
      ) {
        if (!isDeepEqual(param1[i], param2[i])) return false;
      } else if (param1[i] !== param2[i]) return false;
    }
  } else if (param1?.constructor === Object) {
    if (Object.keys(param1).length !== Object.keys(param2).length) return false;
    for (let i = 0; i < Object.keys(param1).length; i++) {
      const key = Object.keys(param1)[i];
      if (
        param1[key]?.constructor === Array ||
        param1[key]?.constructor === Object
      ) {
        if (!isDeepEqual(param1[key], param2[key])) return false;
      } else if (param1[key] !== param2[key]) return false;
    }
  } else if (param1?.constructor == String || param1?.constructor == Number) {
    return param1 === param2;
  }
  return true;
};
