import { SxProps, Theme } from "@mui/material";

export const pointsRowLines: SxProps<Theme> = {
  mb: "15px",
  ml: "25px",
  mr: "40px",

  "&:last-of-type": {
    mb: "unset",
  },
};
export const pointsRowStyle: SxProps<Theme> = {
  mb: "15px",

  "&:last-of-type": {
    mb: "unset",
  },
};
export const titleStyle: SxProps<Theme> = {
  mb: "10px",
};

export const starIcon: SxProps<Theme> = {
  height: "24px",
  width: "24px",
  fill: "#F6C07F",
  mr: "5px",
  mb: "4px",
};

export const pointsStyle: SxProps<Theme> = {
  color: "#F0921F",
  fontFamily: "Noto Sans",
  fontSize: "18px",
  fontWeight: "600",
  letterSpacing: "0",
  lineHeight: "24px",
  mb: "4px",
};
