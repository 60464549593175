import { HomePage } from "./HomePage";
import { NotAuthorizedPage } from "./NotAuthorizedPage";
import NotFoundPage from "./NotFoundPage";
import { ApplicationForm } from "./application/ApplicationForm";

export const MainPages = {
  home: {
    anon: false,
    path: "/",
    title: "Home",
    type: "PAGE_HOME",
    view: HomePage,
  },
  notAuthorized: {
    anon: true,
    path: "unauthorized",
    title: "Not Authorized",
    type: "PAGE_NOT_AUTHORIZED",
    view: NotAuthorizedPage,
  },
  path: {
    anon: true,
    path: "apply",
    title: "Apply",
    type: "PAGE_APPLICATION",
    view: ApplicationForm,
  },
  notFound: {
    anon: true,
    path: "*",
    title: "Page Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFoundPage,
  },
};
