import React, { useCallback, useEffect, useRef, useState } from "react";
import AdminPages from "../index";
import { useNavigate } from "react-router-dom";
import { Fields } from "./schema";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  listsSelectors,
  NewUser,
  PageMode,
  User,
} from "../../../state";
import { FormikHelpers, FormikProps } from "formik";
import {
  capitalizeFirstLetterOfEachWOrd,
  statesAsOptions,
  toInt,
} from "../../../lib";
import { usePageMode } from "./utils";

const roles = [
  { id: 1, name: "Admin" },
  { id: 2, name: "User" },
  { id: 3, name: "Teacher" },
  { id: 4, name: "Principal" },
];

const titles: Record<PageMode, string> = {
  "": "",
  edit: "Edit user",
  insert: "Add a new user",
};

export function useManageUserPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pageMode, id } = usePageMode();

  const innerRef = useRef<FormikProps<Fields>>(null);

  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const neighborhoods = useSelector(listsSelectors.neighborhoods);
  const countries = useSelector(listsSelectors.optionsCountries);
  const countryCodes = useSelector(listsSelectors.optionsCountryCodes);

  const handleBackButtonClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    navigate(AdminPages.users.path);
  };

  const handleDeleteUserClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setShowModal(true);
  };

  const handleSubmit = async (
    {
      mode,
      last_name_yiddish,
      first_name_yiddish,
      first_name,
      last_name,
      home_phone_id,
      home_country_code_id,
      home_phone,
      mobile_phone_id,
      mobile_country_code_id,
      mobile_phone,
      email,
      address_id,
      address,
      address_2,
      city,
      state,
      zip,
      country_id,
      neighborhood_id,
      username,
      person_id,
      password,
      role_ids,
      active,
    }: Fields,
    { setSubmitting, setErrors }: FormikHelpers<Fields>,
  ) => {
    const data = {
      first_name_yiddish,
      last_name_yiddish,
      first_name: capitalizeFirstLetterOfEachWOrd(first_name),
      last_name: capitalizeFirstLetterOfEachWOrd(last_name),
      home_phone_id,
      home_country_code_id,
      home_phone,
      mobile_country_code_id,
      mobile_phone_id,
      mobile_phone,
      email,
      address,
      address_2,
      city,
      state,
      zip: zip?.toUpperCase(),
      country_id,
      neighborhood_id,
      username,
      person_id,
      role_ids,
      active,
    } as NewUser;
    if (showPassword && password) {
      data.password = password;
    }
    let isOk;
    if (mode === "insert") {
      isOk = await dispatch(adminActions.createUser(data));
    }

    if (mode === "edit") {
      data.address_id = address_id;
      isOk = await dispatch(adminActions.updateUser(+id, data));
    }
    if (isOk.message === "Validation fails for Invalid home phone format") {
      setErrors({ home_phone: "Invalid format for the country selected." });
    } else if (
      isOk.message === "Validation fails for Invalid cellphone format"
    ) {
      setErrors({ mobile_phone: "Invalid format for the country selected." });
    } else if (
      isOk.message === "Validation fails for Cannot add duplicate user" ||
      isOk.message === "Validation fails for username must be unique"
    ) {
      setErrors({ username: "Username already exists" });
    } else {
      isOk === true && handleBackButtonClick();
    }
    setSubmitting(false);
  };

  const handleStatusMethod = (
    status: boolean,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    innerRef?.current?.setFieldValue("active", status);
  };

  const handleShowPassword = () => {
    innerRef?.current?.setFieldValue("password", "");
    innerRef?.current?.setFieldValue("add_password", !showPassword);
    setShowPassword(!showPassword);
  };

  const deleteFunction = useCallback(async () => {
    if (toInt(id)) {
      const isOk = await dispatch<
        boolean | ReturnType<typeof adminActions.deleteUser>
      >(adminActions.deleteUser(id));

      isOk && handleBackButtonClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    (async () => {
      if (pageMode === "edit" && !!toInt(id)) {
        const data = await dispatch<
          boolean | User | ReturnType<typeof adminActions.getUser>
        >(adminActions.getUser(id));

        if (data) {
          const {
            person: {
              first_name_yiddish,
              last_name_yiddish,
              first_name = "",
              last_name = "",
              address_id,
              address,
              phone_numbers,
              email,
            },
            username,
            roles,
            active,
            person_id,
          } = data as User;

          const x: Fields = {
            add_password: false,
            mode: "edit",
            first_name_yiddish: first_name_yiddish ?? "",
            last_name_yiddish: last_name_yiddish ?? "",
            first_name,
            last_name,
            home_country_code_id:
              phone_numbers?.filter((p) => p.type === "home")?.[0]
                ?.country_code_id ?? 0,
            home_phone_id:
              phone_numbers?.filter((p) => p.type === "home")?.[0]?.id ?? null,
            home_phone:
              phone_numbers?.filter((p) => p.type === "home")?.[0]?.number ??
              "",
            mobile_country_code_id:
              phone_numbers?.filter((p) => p.type === "cell")?.[0]
                ?.country_code_id ?? 0,
            mobile_phone_id:
              phone_numbers?.filter((p) => p.type === "cell")?.[0]?.id ?? null,
            mobile_phone:
              phone_numbers?.filter((p) => p.type === "cell")?.[0]?.number ??
              "",
            email,
            address_id: address_id,
            address: address?.address ?? "",
            address_2: address?.address_2 ?? "",
            city: address?.city ?? "",
            state: address?.state ?? "",
            zip: address?.zip ?? "",
            country_id: address?.country_id,
            neighborhood_id: address?.neighborhood_id || null,
            username,
            person_id,
            password: "",
            role_ids: roles?.map((r) => r.id) ?? [],
            active,
          };
          setShowPassword(false);
          innerRef?.current?.setValues(x);
        }
      }

      if (pageMode === "insert") {
        innerRef?.current?.setFieldValue("mode", "insert" as PageMode);
        innerRef?.current?.setFieldValue("add_password", true);
      }
    })();
  }, [pageMode, id, dispatch]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return {
    innerRef,
    pageMode,
    title: titles[pageMode],
    states: statesAsOptions,
    countries,
    neighborhoods,
    roles,
    countryCodes,
    showModal,
    showPassword,
    handleShowPassword,
    handleBackButtonClick,
    handleSubmit,
    handleDeleteUserClick,
    handleStatusMethod,
    handleCloseModal,
    deleteFunction,
  };
}
