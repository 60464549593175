import { CSSProperties } from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const topSectionStyle: CSSProperties = {
  marginTop: 0,
  paddingTop: 24,
  height: "125px",
  borderBottom: "1px solid #DDE3EB",
};

export const bottomSectionStyle: CSSProperties = {
  marginTop: "-61px",
  marginBottom: 0,
  padding: "24px 55px",
};

export const controlsSx: SxProps<Theme> = {
  ml: "150px",
};

export const groupsStyles: SxProps<Theme> = {
  width: "320px",
  mt: "0!important",
  mb: "0!important",
  backgroundColor: "primary.contrastText",

  "& .MuiInputLabel-root": {
    top: -4,
  },

  "& .MuiInputBase-input": {
    py: "12.5px",
  },

  "& textarea.MuiInputBase-input": {
    py: 0,
  },
};

export const contentStyles: SxProps<Theme> = {
  boxSizing: "border-box",
  backgroundColor: "primary.contrastText",
  border: "1px solid #DDE3EB",
  maxHeight: "calc(100vh - 112px)",
};

export const monthListStyles: SxProps<Theme> = {
  width: "255px",
  borderRight: "1px solid #DDE3EB",
  overflowY: "auto",
};

export const dataStyles: SxProps<Theme> = {
  p: "25px 50px",
  flexGrow: 1,
  overflowY: "auto",
};

export const groupCaptionStyles: SxProps<Theme> = {
  borderBottom: "1px solid #DDE3EB",
  py: "8px",
  mb: 0,

  "& .MuiTypography-root": {
    pl: 2,
    color: "#4C4C4C",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "15px",
  },
};

export const monthCaptionStyles: SxProps<Theme> = {
  p: "16px 30px",
  borderBottom: "1px solid #DDE3EB",

  "&:hover": {
    backgroundColor: "#082B65",
    "& .MuiTypography-root": {
      color: "primary.contrastText",
    },
  },

  "& .MuiTypography-root": {
    color: "#000000",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "17px",
  },

  "&.Mui-selected": {
    backgroundColor: "#082B65",

    "&:hover": {
      backgroundColor: "#003286",
      "& .MuiTypography-root": {
        color: "primary.contrastText",
      },
    },

    "& .MuiTypography-root": {
      color: "primary.contrastText",
    },
  },
};

export const fieldCaptionStyles: SxProps<Theme> = {
  mt: 2,
  color: "#020F25",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0.17px",
  lineHeight: "18px",
};

export const gridTestsSx: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    border: "unset",
  },
};
