import React, { forwardRef } from "react";
import {
  FormControl,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";

export interface Option {
  label: string;
  value: string;
}

type Props = {
  placeholder?: string;
  options?: Option[];
  width?: string;
  isLoading?: boolean;
  allowEmptyItem?: boolean;
} & TextFieldProps;

const Select = forwardRef<HTMLDivElement, Props>(
  (
    {
      options,
      placeholder,
      width = "100%",
      allowEmptyItem = false,
      isLoading = false,
      ...props
    },
    ref,
  ) => (
    <FormControl sx={{ width }}>
      <TextField ref={ref} select {...props}>
        <MenuItem
          value=""
          style={{ display: allowEmptyItem ? "block" : "none", height: "36px" }}
        >
          <span>{placeholder || "Select..."}</span>
        </MenuItem>

        {isLoading && <MenuItem>{"Loading..."}</MenuItem>}

        {!isLoading &&
          options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    </FormControl>
  ),
);

export default Select;
