import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  Chip,
  IconButton,
} from "@mui/material";
import { Transition } from "./Transition";
import {
  DataGridPro,
  GridColDef,
  GridFooterContainer,
} from "@mui/x-data-grid-pro";
import { formatDate, getDescription } from "../../../../constants";
import { customerSelectors } from "../../../../state";
import { ChodeshYear } from "../../../../components/columns/ChodeshYear";
import { getCurrencySymbol, numberWithCommas, history } from "../../../../lib";
import AdminPages from "../..";

export const InvoiceModal = ({ invoice, month, total }) => {
  const customer = useSelector(customerSelectors.customer);
  const { first_name_yiddish, last_name_yiddish, currency } = customer!;

  function CustomFooter() {
    return (
      <GridFooterContainer>
        <Typography sx={{ ml: "85%", color: "#000000" }} variant="body1">
          {`Total ${getCurrencySymbol(currency)}${numberWithCommas(total)}`}
        </Typography>
      </GridFooterContainer>
    );
  }

  function getStudent(params) {
    const studentArray = customer.students.filter(
      (stdnt) => stdnt.id === params.row.student_id,
    );
    if (studentArray.length) {
      return (
        <Box
          key={`student-${params.row.student_id}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
        >
          <Chip
            sx={{
              borderRadius: 0,
              margin: "2.5px 5px",
              backgroundColor: "secondary.main",
              color: "primary.main",
              minHeight: "25px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
            onClick={() => {
              history.push(
                `${AdminPages.studentPage.path.replace(
                  ":id",
                  studentArray?.[0]?.id.toString(10),
                )}`,
              );
            }}
            key={Math.random().toString(8)}
            label={
              studentArray?.[0]?.first_name_yiddish +
              " " +
              studentArray?.[0]?.last_name_yiddish
            }
          />
        </Box>
      );
    }
  }
  function getAmount(params) {
    const { type, amount } = params.row;
    return (
      <Typography
        variant="body1"
        color={
          type === "fee" ? "#BB2018" : type === "discount" ? "#128E56" : ""
        }
      >
        {getCurrencySymbol(currency)}
        {numberWithCommas(amount)}
      </Typography>
    );
  }

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);
  const date = {
    field: "date",
    headerName: "Date",
    renderCell: formatDate,
    valueGetter: formatDate,
    flex: 1,
  };
  const student = {
    field: "student",
    headerName: "Student",
    flex: 1,
    renderCell: getStudent,
  };
  const amount = {
    field: "amount",
    headerName: "Amount",
    renderCell: getAmount,
    flex: 1,
  };

  const INVOICE: GridColDef[] = [date, student, amount, getDescription];

  return (
    <>
      <IconButton aria-label="delete" onClick={handleOpenModal}>
        <DescriptionIcon />
      </IconButton>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>
          {`${first_name_yiddish} ${last_name_yiddish} - `}
          <ChodeshYear month_id={month} />
        </DialogTitle>
        <DialogContent>
          <DataGridPro
            autoHeight
            rows={invoice}
            columns={INVOICE}
            components={{
              Footer: CustomFooter,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "asc" }],
              },
            }}
            sx={{ m: "20px" }}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleCloseModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
