import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  ButtonGroup,
  InputAdornment,
} from "@mui/material";
import { AddIcon, DollarSignIcon, PercentIcon } from "../../../components";
import { getButtonVariant } from "../../../lib";
import {
  dscField,
  dscSelector,
} from "../CustomerPage/EditPaymentSettings/EditPaymentSettings.styles";
import { MonthsAutocomplete } from "./MonthsAutocomplete";

export function AddDiscountField({
  setFieldValue,
  values,
  disabled = false,
  onChange,
  firstPossibleEndMonth = undefined,
}: {
  firstPossibleEndMonth: undefined | number;
  setFieldValue;
  values;
  disabled;
  onChange;
}) {
  const [open, setOpen] = useState(false);
  return (
    <Box>
      {!open ? (
        <Button disabled={disabled} onClick={() => setOpen(true)}>
          <AddIcon /> add discount
        </Button>
      ) : (
        <Box
          sx={() => ({
            border: `1px solid rgba(50, 142, 218, .16)`,
            paddingX: 2,
            borderRadius: "5px",
          })}
        >
          <Box
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: 16,
              pt: 2,
            })}
          >
            Add discount
          </Box>
          <Box>
            <TextField
              name="discount"
              placeholder={"Discount"}
              onChange={(e) => {
                setFieldValue("discount", e.target.value);
                if (onChange) {
                  onChange(e);
                }
              }}
              sx={dscField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ButtonGroup sx={dscSelector}>
                      <Button
                        variant={getButtonVariant(
                          values.discount_type === "amount",
                        )}
                        onClick={() => setFieldValue("discount_type", "amount")}
                      >
                        <DollarSignIcon className={"dollar-icon"} />
                      </Button>

                      <Button
                        variant={getButtonVariant(
                          values.discount_type === "percentage",
                        )}
                        onClick={() =>
                          setFieldValue("discount_type", "percentage")
                        }
                      >
                        <PercentIcon />
                      </Button>
                    </ButtonGroup>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <MonthsAutocomplete
              value={values.end_month_id}
              label={"Charge in full starting in"}
              startMonthId={firstPossibleEndMonth}
              onChange={(_e, s, _r) => {
                setFieldValue("end_month_id", s?.value || "");
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
