import React from "react";

import { Grid, Typography, Box, Tab, Tabs, IconButton } from "@mui/material";

import {
  BottomSection,
  Page,
  TopSection,
} from "./styles/AddCustomerPage.styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AddCustomerFormCustomer } from "./components";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AddNewCustomerPage = () => {
  const navigate = useNavigate();

  // const [value, setValue] = useState(0);
  const value = 0;
  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  return (
    <Page>
      <TopSection>
        <Grid
          container
          spacing={12}
          direction="row"
          justifyContent="space-between"
          paddingBottom={3}
        >
          <Grid item>
            <Typography variant="h3">
              <IconButton
                color="primary"
                onClick={() => navigate("/admin/customer")}
              >
                <ArrowBackIcon />
              </IconButton>
              Add a customer
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} direction="row" justifyContent="flex-start">
          <Grid item>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
              >
                <Tabs
                  value={value}
                  // onChange={handleChange}
                  aria-label="basic tabs"
                >
                  <Tab
                    label="CUSTOMER"
                    {...a11yProps(0)}
                    sx={{
                      color: "#082B65",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                    }}
                  />
                  <Tab
                    label="STUDENT"
                    {...a11yProps(1)}
                    sx={{
                      color: "#082B65",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                    }}
                  />
                  <Tab
                    label="ENROLLMENT"
                    {...a11yProps(2)}
                    sx={{
                      color: "#082B65",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                    }}
                  />
                  <Tab
                    label="BILLING"
                    {...a11yProps(3)}
                    sx={{
                      color: "#082B65",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                    }}
                  />
                </Tabs>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </TopSection>
      <BottomSection>
        <TabPanel value={value} index={0}>
          <AddCustomerFormCustomer />
        </TabPanel>
        <TabPanel value={value} index={1}></TabPanel>
        <TabPanel value={value} index={2}></TabPanel>
        <TabPanel value={value} index={3}></TabPanel>
      </BottomSection>
    </Page>
  );
};
