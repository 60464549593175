import React, { FC, ReactNode } from "react";
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { backBtnStyle } from "./SimplePageHeader.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SystemStyleObject } from "@mui/system";

interface Props {
  title: ReactNode;
  onBackButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  controlsSx?: SxProps<Theme>;
  controls?: ReactNode;
}

const SimplePageHeader: FC<Props> = ({
  title,
  onBackButtonClick,
  controlsSx,
  controls,
}) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        {onBackButtonClick && (
          <IconButton
            color="primary"
            sx={backBtnStyle}
            onClick={onBackButtonClick}
          >
            <ArrowBackIcon />
          </IconButton>
        )}

        {typeof title === "string" ? (
          <Typography variant={"h3"}>{title}</Typography>
        ) : (
          title
        )}
      </Stack>

      {controls && (
        <Box
          sx={[
            { ml: "auto" } as SystemStyleObject,
            controlsSx as SystemStyleObject,
          ]}
        >
          {controls}
        </Box>
      )}
    </Stack>
  );
};

export default SimplePageHeader;
