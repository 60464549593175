import { authClient, uiActions } from "../states";
import { prizes } from "./state";
import { AppThunk } from "..";
import { flattenWithoutPrefix, getUploadFile } from "../../lib";

const { actions } = prizes;

export const prizeActions = {
  ...actions,
  getProducts(params, abortSignal: AbortSignal): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.get(`/prizes?${params}`, {
        signal: abortSignal,
      });

      if (status === 200) {
        dispatch(actions.setProducts(data));
      } else {
        dispatch(uiActions.showError("Get products failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getProduct(id: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/prizes/${id}`);

      if (status === 200) {
        const parsedData = flattenWithoutPrefix(data);

        dispatch(actions.setProduct(parsedData));
      } else {
        dispatch(uiActions.showError("Get products failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  searchProducts(q: string): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/prizes?q=${encodeURI(q)}`,
      );
      if (status !== 200) {
        dispatch(uiActions.showError("Get product list failed"));
      }

      return data;
    };
  },
  addRedemption(values): AppThunk {
    return async (dispatch) => {
      dispatch(this.setRedemptionSubmit(false));
      dispatch(uiActions.setLoading(true));
      const { id } = values;
      let res;
      let action = "add new redemption";
      if (id) {
        action = "update redemption";
        res = await authClient.put(`redemptions/${id}`, values);
      } else {
        res = await authClient.post(`/redemptions`, values);
      }
      const { status, data } = res;
      if (status === 200 || status === 204) {
        dispatch(uiActions.showSuccess(`${action} Successful`));
        dispatch(this.setRedemptionSubmit(true));
        dispatch(uiActions.setLoading(false));
        return data;
      } else {
        dispatch(uiActions.showError(`failed to ${action}`));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRedemptions(params, abortSignal: AbortSignal): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/redemptions?${params}`, {
        signal: abortSignal,
      });

      if (status === 200) {
        dispatch(actions.setRedemptions(data));
      } else {
        dispatch(uiActions.showError("Get redemptions failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRedemption(id) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/redemptions/limited?id=${id}`,
      );
      if (status === 200 || status === 304) {
        dispatch(this.setRedemption(data?.[0]));
      } else {
        dispatch(uiActions.showError("Get redemption failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getVendors(): AppThunk {
    return async (dispatch, getState) => {
      if (getState().prizes.vendorsLoading) {
        return;
      }
      dispatch(actions.setVendorsLoading(true));
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/vendors`);

      if (status === 200) {
        const state = {
          rows: data,
          count: data.count || 0,
        };

        dispatch(actions.setVendors(state));
      } else {
        dispatch(uiActions.showError("Get vendors failed"));
      }
      dispatch(uiActions.setLoading(false));
      dispatch(actions.setVendorsLoading(false));
    };
  },
  deleteProduct(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(`/prizes/${id}`);

      if (status !== 204 && data?.message) {
        if (status === 400) {
          dispatch(uiActions.showError(data.message));
        } else {
          dispatch(uiActions.showError("Delete product failed"));
        }
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  deleteRedemption(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/redemptions/${id}`);

      if (status !== 204) {
        dispatch(uiActions.showError("Delete redemption failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRedemptionItems(params, abortSignal: AbortSignal): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/redemption-items?${params}`,
        { signal: abortSignal },
      );

      if (status === 200) {
        dispatch(actions.setRedemptionItems(data));
      } else {
        dispatch(uiActions.showError("Get redemption items failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  uploadFile(file): AppThunk<Promise<{ image_url; image_link: string }>> {
    return async (
      dispatch,
    ): Promise<{ image_url: string; image_link: string }> => {
      file = getUploadFile(file);
      const formData = new FormData();
      formData.append("file", file, file.name);
      const config = {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data`,
        },
      };

      const { data, status } = await authClient.put(
        "/file-upload",
        formData,
        config,
      );
      if (status === 200) {
        return data;
      } else {
        dispatch(uiActions.showError("Upload image failed"));
        return { image_link: "", image_url: "" };
      }
    };
  },
  addProduct(values): AppThunk {
    return async (dispatch) => {
      dispatch(this.setProductSubmit(false));
      dispatch(uiActions.setLoading(true));
      const {
        image,
        outstanding: _outstanding,
        id,
        image_link: _image_link,
        ...params
      } = values;

      if (image) {
        const { image_url } = await dispatch(prizeActions.uploadFile(image));
        params.image_url = image_url;
      }
      let res;
      let action = "add new product";
      if (id) {
        action = "update product";
        res = await authClient.put(`/prizes/${id}`, params);
      } else {
        res = await authClient.post(`/prizes`, params);
      }
      const { status, data } = res;
      if (status === 204) {
        dispatch(uiActions.showSuccess(`${action} Successful`));
        dispatch(this.setProductSubmit(true));
        dispatch(uiActions.setLoading(false));
        return data;
      } else {
        dispatch(uiActions.showError(`failed to ${action}`));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateBulkRedemptionItemStatus(params, gridParams) {
    return async (dispatch) => {
      const { status } = await authClient.put("/redemption-items", params);
      if (status == 204) {
        dispatch(
          this.getRedemptionItems(gridParams, new AbortController().signal),
        );
      } else {
        dispatch(uiActions.showError(`failed to update status`));
      }
    };
  },
  updateBulkRedemptionStatus(params, reloadFunction): AppThunk {
    return async (dispatch) => {
      const { status } = await authClient.put("/redemptions", params);
      if (status == 204) {
        reloadFunction();
      } else {
        dispatch(uiActions.showError(`failed to update status`));
      }
    };
  },
  getAllPrizeNumbers() {
    return async (dispatch) => {
      const { status, data } = await authClient.get("/prize-number-list");
      if (status === 200) {
        dispatch(this.setPrizeNumbers(data));
      } else {
        console.error("failed to load prize numbers");
      }
    };
  },
};
