import React, { useEffect, useState } from "react";
import { Option } from "../../../../components/Select/Select";
import { useSelector } from "react-redux";
import { listsSelectors } from "../../../../state";
import { FormikProps } from "formik";
import { DetailsFields } from "../schema";
import { useGetPageMode } from "../utils";

export function useTabDetails(formik: FormikProps<DetailsFields>) {
  const { values, setFieldValue, errors, touched, handleChange, handleBlur } =
    formik;

  const [neighborhoods, setNeighborhoods] = useState<Option[]>([]);
  const [locations, setLocations] = useState<Option[]>([]);

  const _neighborhoods = useSelector(listsSelectors.neighborhoods);
  const _locations = useSelector(listsSelectors.locations);

  const { pageMode } = useGetPageMode();

  useEffect(() => {
    const n = _neighborhoods.map(
      ({ id, name }) => ({ label: name, value: id.toString() } as Option),
    );

    const l = _locations.map(
      ({ id, name }) => ({ label: name, value: id.toString() } as Option),
    );

    setNeighborhoods(n);
    setLocations(l);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChaburahType = (
    chaburah_type_id: number,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    setFieldValue("chaburah_type_id", chaburah_type_id);
  };

  const handleActive = (
    active: boolean,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    setFieldValue("active", active);
  };

  return {
    pageMode,
    values,
    neighborhoods,
    locations,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleChaburahType,
    handleActive,
  };
}
