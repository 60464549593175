/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { Option } from "../../../../components/Select/Select";
import {
  CustomerSearchItem,
  listsActions,
  listsSelectors,
  StudentSearchItem,
} from "../../../../state";
import { gematriya } from "../../../../lib";
import {
  AutocompleteChangeReason,
  AutocompleteValue,
  debounce,
} from "@mui/material";

import { FormikProps } from "formik";
import { StudentGeneralFields } from "../schema";
import {
  buildMonthsOptions,
  buildYearsOptions,
  useGetCurrentPageMode,
} from "../utils";

const DAYS_LIST = Array<Option>(30)
  .fill({} as Option)
  .map(
    (_, index) =>
      ({
        label: gematriya((index + 1).toString()),
        value: (index + 1).toString(),
      } as Option),
  );

interface OptionCustomer extends Option {
  original: CustomerSearchItem;
}

export const useTabGeneral = (formik: FormikProps<StudentGeneralFields>) => {
  const dispatch = useDispatch();

  const { pageMode } = useGetCurrentPageMode();
  const [englishBirthday, setEnglishBirthday] = useState<{
    month?: string;
    year?: number;
    day?: number;
    date?: Date;
  }>({});

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setValues,
    setFieldValue,
    setTouched,
  } = formik;

  const [searchCustomer, setSearchCustomer] = useState<string>("");
  const [customers, setCustomers] = useState<OptionCustomer[]>([]);
  const [customer, setCustomer] = useState<OptionCustomer | null>(null);

  const [schools, setSchools] = useState<Option[]>([]);
  const [schoolBuildings, setSchoolBuildings] = useState<Option[]>([]);
  const [grades, setGrades] = useState<Option[]>([]);
  const [years, setYears] = useState<Option[]>([]);
  const [months, setMonths] = useState<Option[]>([]);
  const [days, setDays] = useState<Option[]>([]);
  const [school, setSchool]: any = useState();
  const _neighborhoods = useSelector(listsSelectors.neighborhoodById);
  const _schools = useSelector(listsSelectors.schools);
  const _schoolBuildings = useSelector(listsSelectors.schoolBuildings);
  const _months = useSelector(listsSelectors.months);
  const _monthById = useSelector(listsSelectors.monthById);

  useEffect(() => {
    // Schools
    const schools = _schools.map(({ id, name, neighborhood_id }) => {
      const neighborhood = _neighborhoods[neighborhood_id]?.name ?? "";
      const isHebrewNeighborhood = /^[\u0590-\u05FF `’'",.-]*$/.test(
        neighborhood,
      );
      return {
        label: isHebrewNeighborhood
          ? `${neighborhood}  -  ${name}`
          : `${name}  -  ${neighborhood}`,
        value: id.toString(),
      } as Option;
    });

    // Grades
    const grades = Array<Option>(12)
      .fill({} as Option)
      .map(
        (_, index) =>
          ({
            label: gematriya((index + 1).toString()),
            value: (index + 1).toString(),
          } as Option),
      );

    setSchools(schools);
    setGrades(grades);
    setYears(buildYearsOptions(_months));

    if (values._customer) {
      setCustomer(values._customer as OptionCustomer);
    }
  }, [values._customer, _months, _schools, _neighborhoods]);

  useEffect(() => {
    const sb = _schoolBuildings
      .filter((b) => {
        if (values.school) {
          return b.school_id.toString() === values.school;
        } else {
          return true;
        }
      })
      .map((sb) => ({ label: sb.name, value: sb.id.toString() } as Option));

    // setValues({ ...values, school_building_id: "" });

    setSchoolBuildings(sb);
  }, [values.school, _schoolBuildings]);

  useEffect(() => {
    // setValues({ ...values, birth_month_id: "", birth_month_day: "" });
    setMonths(buildMonthsOptions(_months, values.year));
  }, [_months, values.year]);
  useEffect(() => {
    if (englishBirthday?.year) {
      setFieldValue("year", englishBirthday?.year.toString());
      setEnglishBirthday((b) => ({ ...b, year: undefined }));
    }
  }, [englishBirthday]);
  useEffect(() => {
    if (englishBirthday?.month) {
      setFieldValue(
        "birth_month_id",
        months.find((m) => m.label === englishBirthday?.month)?.value,
      );
      setTimeout(() => {
        setEnglishBirthday((b) => ({ ...b, month: undefined }));
      }, 0);
    }
  }, [months, englishBirthday.month]);
  useEffect(() => {
    if (englishBirthday?.day) {
      setFieldValue("birth_month_day", englishBirthday?.day.toString());
      setEnglishBirthday((b) => ({ ...b, day: undefined }));
    }
  }, [englishBirthday, days]);

  useEffect(() => {
    // setValues({ ...values, birth_month_day: "" });
    const days = _monthById[values.birth_month_id]?.day_count ?? 0;
    if (days) {
      setDays(DAYS_LIST.slice(0, days));
    }
  }, [_monthById, values.birth_month_id]);
  useEffect(() => {
    setValues({ ...values /*chaburah_id: "" teacher: ""*/ });
  }, [values.customer_id]);

  useEffect(() => {
    (async () => {
      if (searchCustomer) {
        const custmr = await dispatch(
          listsActions.getCustomerByName(searchCustomer),
        );

        if (custmr) {
          const co = (custmr as unknown as CustomerSearchItem[]).map(
            (ci) =>
              ({
                label: ci.name,
                value: ci.id.toString(),
                original: ci,
              } as OptionCustomer),
          );
          setCustomers(co);
        }
      }
    })();
  }, [dispatch, searchCustomer]);

  useEffect(() => {
    if (pageMode === "edit" && values._customer) {
      setCustomer(values._customer as OptionCustomer);
    }
  }, [pageMode, values._customer]);
  useEffect(() => {
    if (values.school) {
      const school = _schools.find(
        (s) => s.id.toString() === values.school.toString(),
      );
      if (school) {
        const neighborhood =
          _neighborhoods[school?.neighborhood_id]?.name ?? "";
        const isHebrewNeighborhood = /^[\u0590-\u05FF `’'",.-]*$/.test(
          neighborhood,
        );

        setSchool({
          label: isHebrewNeighborhood
            ? `${neighborhood}  -  ${school.name}`
            : `${school.name}  -  ${neighborhood}`,
          value: school.id.toString(),
        });
      } else {
        setSchool(undefined);
      }
    }
  }, [pageMode, values.school]);

  const handleSearchCustomer = useCallback(
    debounce((event: React.SyntheticEvent, search: string) => {
      event?.stopPropagation();
      search ? setSearchCustomer(search) : setCustomers([]);
    }, 300),
    [],
  );

  const handleSelectCustomer = (
    event: React.SyntheticEvent,
    selection: AutocompleteValue<Option, boolean, boolean, boolean>,
    reason: AutocompleteChangeReason,
  ) => {
    event?.stopPropagation();

    if (reason === "selectOption" && !!selection) {
      const option = selection as OptionCustomer;

      setSearchCustomer(option.label);
      setCustomer(option);

      setValues({
        ...values,
        customer_id: option.value,
        last_name: option.original.last_name,
        last_name_yiddish: option.original.last_name_yiddish,
        _customer: option,
      });
    }

    if (reason === "clear") {
      setCustomer(null);
      setCustomers([]);
      setSearchCustomer("");

      setValues({ ...values, customer_id: "", _customer: undefined });
    }
  };

  const handleStudentStatus = (
    status: number,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    setValues({ ...values, status_id: status });
  };

  const [students, setStudents] = useState<Option[]>([]);
  const [student, setStudent] = useState<Option | null>(null);
  const [searchStudent, setSearchStudent] = useState<string>("");

  useEffect(() => {
    if (values._referring_student) {
      setStudent(values._referring_student as Option);
    }
  }, [values._referring_student]);

  useEffect(() => {
    (async () => {
      if (searchStudent) {
        const u = await dispatch(listsActions.getStudentByName(searchStudent));

        if (u) {
          const co = (u as unknown as StudentSearchItem[]).map(
            ({ id, name }) => ({ label: name, value: id } as unknown as Option),
          );

          setStudents(co);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStudent]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchStudent = useCallback(
    debounce((event: React.SyntheticEvent, search: string) => {
      event?.stopPropagation();
      search ? setSearchStudent(search) : setStudents([]);
    }, 300),
    [],
  );

  const handleSelectStudent = (
    event: React.SyntheticEvent,
    selection: AutocompleteValue<Option, boolean, boolean, boolean>,
    reason: AutocompleteChangeReason,
  ) => {
    event?.stopPropagation();

    if (reason === "selectOption" && !!selection) {
      const option = selection as Option;

      setSearchStudent(option.label);
      setStudent(option);

      setValues({
        ...values,
        referring_student_id: option.value,
        _referring_student: option,
      });
    }

    if (reason === "clear") {
      setStudent(null);
      setStudents([]);
      setSearchStudent("");

      setValues({
        ...values,
        referring_student_id: "",
        _referring_student: undefined,
      });
    }
  };

  return {
    customer,
    customers,
    handleBlur,
    years,
    months,
    days,
    schools,
    schoolBuildings,
    grades,
    touched,
    setTouched,
    values,
    errors,
    handleSearchCustomer,
    handleSelectCustomer,
    handleStudentStatus,
    handleChange,
    setFieldValue,
    pageMode,
    school,
    setSchool,
    englishBirthday,
    setEnglishBirthday,
    students,
    student,
    handleSelectStudent,
    handleSearchStudent,
  };
};
