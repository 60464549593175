import React, { FC, useEffect, useState } from "react";
import { FormikProps } from "formik";
import { StudentBillingFields } from "../schema";
import { btnStyle, container } from "./TabBilling.styles";
import TabContainer from "../../components/TabContainer";
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BlockCash from "./BlockCash";
import BlockCreditCard from "./BlockCreditCard";
import { SystemStyleObject, Theme } from "@mui/system";
import {
  adminActions,
  adminSelectors,
  customerActions,
  customerSelectors,
  listsSelectors,
  PaymentMethod,
  useDispatch,
  useSelector,
} from "../../../../state";
import { formatMoney, gematriya, getButtonVariant } from "../../../../lib";
import { StudentStatusToggle } from "../StudentStatusToggle";
import { AddDiscountField } from "../../components/AddDiscountField";
import { AddCreditField } from "../../components/AddCreditField";
import CheckBox from "../../../../components/CheckBox";
import { ExpandLess, ExpandMore, InfoOutlined } from "@mui/icons-material";
import AdminPages from "../..";
import { LaunchIcon } from "../../../../components";
import { textField } from "../TabGeneral/TabGeneral.styles";

interface Props {
  formik: FormikProps<StudentBillingFields>;
  pageMode: string;
  onDeactivate;
  startMonth?;
  billingInfo;
  isCurrentMonth;
  hasEnrollement;
}

const TabBilling: FC<Props> = ({
  formik,
  pageMode,
  onDeactivate,
  startMonth,
  billingInfo,
  isCurrentMonth,
  hasEnrollement,
}) => {
  const { values, setValues, setFieldValue } = formik;
  const customer = useSelector(customerSelectors.customer);
  const student = useSelector(adminSelectors.student);
  const months = useSelector(listsSelectors.monthById);
  const monthViewing = months[student?.month_join || 0];
  const {
    remainingCredit,
    studentsTotalDue,
    chaburahPrice,
    credit,
    upcomingBill,
    studentsUpcomingFees,
    discount,
    discountReason,
    discountType,
    multi,
    paid,
    unpaid,
  } = billingInfo;
  let { customersTotalDue } = billingInfo;
  const dispatch = useDispatch();
  const [open, setOpen] = useState({});
  const isCredit = values?.default_payment_method === "credit";
  // where possible show the value already set in formik to give maximum possible transparency
  const studentsTotalToDisplay = isCredit ? values.amount : studentsTotalDue;

  useEffect(() => {
    if (isCredit) {
      setTimeout(() => {
        setFieldValue("amount", studentsTotalDue);
      }, 0);
    }
  }, [studentsTotalDue, isCredit, setFieldValue]);
  useEffect(() => {
    (async () => {
      if (student?.customer_id) {
        await dispatch(
          customerActions.getCustomer(student?.customer_id.toString()),
        );
        dispatch(
          customerActions.getCustomerBill(student?.id, student.month_join),
        );
      }
    })();
  }, [dispatch, student?.customer_id, student?.id, student?.month_join]);

  const handlePaymentMethod = (
    method: PaymentMethod,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    setValues({ ...values, default_payment_method: method });
  };
  useEffect(() => {
    if (!hasEnrollement) {
      return;
    }
    if (student?.id && !student?.month_join) {
      dispatch(adminActions.getStudent(student.id));
    }
  }, [dispatch, hasEnrollement, student?.id, student?.month_join]);

  if (!hasEnrollement || !student?.month_join) {
    return <></>;
  }

  return (
    <TabContainer sx={{ p: "32px 55px" }}>
      <Box display="flex">
        <Box mr={4}>
          <StudentStatusToggle
            onDeactivate={onDeactivate}
            student={student}
            studentId={student?.id}
            hideButton
          />

          <Box>
            <Box
              paddingTop={2}
              color="#5C738E"
              fontWeight="bold"
              fontSize="16px"
              marginRight={2}
            >
              <Typography variant={"h6"} sx={{ mb: 1 }}>
                Deposit due
              </Typography>
            </Box>
            <Box color="#6B8AAC" fontSize={14}>
              This charge will be applied to the first invoice when the student
              joins.
            </Box>
            <Box maxWidth={300}>
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Box color="#082B65">Monthly Chaburah price</Box>
                <Box>
                  {chaburahPrice
                    ? formatMoney(chaburahPrice, upcomingBill?.currency)
                    : ""}
                </Box>
              </Box>
              {!!multi && (
                <Box display="flex" justifyContent="space-between" mt={1}>
                  <Box color="#082B65">Multi student discount</Box>
                  <Box>-{formatMoney(multi, upcomingBill?.currency)}</Box>
                </Box>
              )}
              {paid && (
                <Box display="flex" justifyContent="space-between" mt={1}>
                  <Box color="#082B65">Previously paid</Box>
                  <Box>Yes</Box>
                </Box>
              )}{" "}
              {unpaid && (
                <Box display="flex" justifyContent="space-between" mt={1}>
                  <Box color="#082B65">Previously unpaid</Box>
                  <Box>{formatMoney(unpaid, upcomingBill?.currency)}</Box>
                </Box>
              )}
              {!!discount && (
                <Box display="flex" justifyContent="space-between" mt={1}>
                  <Box color="#082B65">{discountReason}</Box>
                  <Box>
                    -
                    {discountType === "percentage"
                      ? `${discount}%`
                      : formatMoney(discount, upcomingBill?.currency)}
                  </Box>
                </Box>
              )}
              {!!credit && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mt={1}
                  color="#128E56"
                >
                  <Box>Customer credit</Box>
                  <Box>-{formatMoney(credit, upcomingBill?.currency)}</Box>
                </Box>
              )}
              {!!remainingCredit && (
                <Box color="#8D98C2">
                  {formatMoney(remainingCredit, upcomingBill?.currency)} credit
                  remaining
                </Box>
              )}
              <Divider
                flexItem
                sx={{ border: "1px solid #DDE3EB", mt: 2, mb: 2 }}
              />
              <AddDiscountField
                setFieldValue={formik.setFieldValue}
                values={formik.values}
                firstPossibleEndMonth={+startMonth + 1}
                disabled={!!studentsUpcomingFees?.discount}
                onChange={() => {}}
              />
              <AddCreditField setFieldValue={formik.setFieldValue} />
              <Divider flexItem sx={{ border: "1px solid #DDE3EB", mt: 2 }} />
              <Box
                display="flex"
                justifyContent="space-between"
                mt={1}
                fontWeight="bold"
              >
                <Box>Total due</Box>
                <Box>
                  {studentsTotalToDisplay || studentsTotalToDisplay === 0
                    ? formatMoney(
                        studentsTotalToDisplay,
                        upcomingBill?.currency,
                      )
                    : ""}
                </Box>
              </Box>
            </Box>
          </Box>
          {!!isCurrentMonth && !!(values.credit_add || credit) && (
            <Box display="flex" padding={1} bgcolor="#FEE9D0" mt={2}>
              <Box>
                <InfoOutlined sx={{ color: "#F0921F", fontSize: "15px" }} />
              </Box>
              <Box fontSize={14} ml={2}>
                Note: Credit will not be automatically applied to the invoice.
                After saving the student, be sure to manually apply the credit
                to the invoice from the customer billing list.
              </Box>
            </Box>
          )}
          <Stack
            sx={{
              ...container,
              my: 4,
              border: "1px solid rgba(50, 142, 218, .16)",
              padding: 2,
              pb: 0,
              borderRadius: "3px",
            }}
          >
            <Typography variant={"h6"} sx={{ mb: 2 }}>
              Payment method
            </Typography>

            <ButtonGroup
              sx={[
                btnStyle as SystemStyleObject<Theme>,
                { mb: 6 } as SystemStyleObject<Theme>,
              ]}
            >
              <Button
                variant={getButtonVariant(
                  values.default_payment_method === "cash",
                )}
                onClick={handlePaymentMethod.bind(null, "cash")}
              >
                Cash
              </Button>

              <Button
                variant={getButtonVariant(
                  values.default_payment_method === "credit",
                )}
                onClick={handlePaymentMethod.bind(null, "credit")}
              >
                Credit card
              </Button>
            </ButtonGroup>

            {values.default_payment_method === "cash" && (
              <>
                <BlockCash formik={formik} pageMode={pageMode} />
              </>
            )}

            {values.default_payment_method === "credit" && (
              <BlockCreditCard formik={formik} pageMode={pageMode} />
            )}
          </Stack>

          <Box maxWidth="600px">
            {values.default_payment_method === "cash" && (
              <Box>
                <TextField
                  sx={[
                    textField as SystemStyleObject<Theme>,
                    { width: "100%" } as SystemStyleObject<Theme>,
                  ]}
                  rows={2}
                  multiline={true}
                  id="note"
                  name="note"
                  label="Notes"
                  fullWidth={true}
                  value={values.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.note && !!formik.errors.note}
                  helperText={formik.touched.note && formik.errors.note}
                />
              </Box>
            )}
          </Box>
          <Box mt={2} maxWidth={720}>
            <CheckBox
              labelPlacement="start"
              label={
                <Box
                  textAlign="justify"
                  sx={{ direction: "rtl" }}
                  paddingRight={2}
                  fontSize={16}
                  fontWeight={500}
                >
                  איך באשטעטיג אז מיין קארד וועט ווערן געטשארדזט יעדע "פערטן"
                  מאנטאג (צווישן כ'-כה לחודש לויט אידישע חדשים), פארן קומעדיגע
                  חודש, ביז איך וועל אריינרופן דאס צו אפשטעלן. אויב וויל מען
                  אפשטעלן די קארטל, מוז דאס ווערן געמאלדן ביז כ' לחודש, אויב
                  מ'רופט נאך כ' לחודש וועלן מיר דאס נישט קענען ריפאנדען.
                </Box>
              }
              size="medium"
              error={!!formik.touched?.terms && !!formik.errors?.terms}
              onChange={() => {
                formik.setFieldValue("terms", !formik.values.terms);
              }}
            />
          </Box>
        </Box>
        <Box minHeight="100%">
          <Box display="flex" justifyContent="space-between" minHeight="100%">
            <Box display="flex" alignItems="center" minHeight="100%">
              <Box
                width={350}
                borderLeft="1px solid #DDE3EB"
                padding={2}
                paddingLeft={3}
                minHeight="100%"
              >
                <Box fontSize={16} fontWeight="bold" color="#072556">
                  Total monthly charges for{" "}
                  {`${monthViewing?.hebrew || ""} ${
                    gematriya(monthViewing?.year_id.toString()) || ""
                  }`}
                </Box>
                <Box bgcolor="#F3F6F9" padding={2} mt={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <Box fontWeight="bold" fontSize={26}>
                        {customer.first_name_yiddish}{" "}
                        {customer.last_name_yiddish}
                      </Box>
                      <Box color="#5C738E" fontSize={12}>
                        Reb {customer.first_name} {customer.last_name}
                      </Box>
                    </Box>
                    <Box>
                      <IconButton
                        sx={(theme) => ({ color: theme.palette.primary.main })}
                        href={AdminPages.customer.path.replace(
                          ":id",
                          customer.id?.toString() || "",
                        )}
                        target="_blank"
                        rel="noopener"
                      >
                        {" "}
                        <LaunchIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  {upcomingBill?.fees?.map((f, i) => {
                    let subtotal = +f.own_price * -1 - (f.multi || 0);
                    let discount = f.discount?.discount;
                    if (
                      !discount &&
                      f.id === student?.id &&
                      formik.values.discount
                    ) {
                      if (formik.values.discount_type === "percentage") {
                        discount = subtotal * (formik.values.discount / 100);
                      } else {
                        discount = formik.values.discount;
                      }
                      customersTotalDue -= discount || 0;
                    }
                    subtotal -= discount || 0;

                    return (
                      <Box key={i}>
                        <ListItemButton
                          key={i}
                          onClick={() => {
                            setOpen({ ...open, [i]: !open[i] });
                          }}
                        >
                          <ListItemText
                            color="#072556"
                            sx={{
                              "& .MuiTypography-root": {
                                color: "#072556",
                                fontSize: "14px",
                                fontWeight: 600,
                              },
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              pb={0.5}
                            >
                              <Box>{f.first_name_yiddish}</Box>
                              <Box fontWeight="bold">
                                {formatMoney(subtotal, upcomingBill.currency)}
                              </Box>
                            </Box>
                          </ListItemText>
                          {open[i] ? (
                            <ExpandLess
                              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                                e.stopPropagation();
                                setOpen({ ...open, [i]: !open[i] });
                              }}
                            />
                          ) : (
                            <ExpandMore
                              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                                e.stopPropagation();
                                setOpen({ ...open, [i]: !open[i] });
                              }}
                            />
                          )}
                        </ListItemButton>

                        <Collapse in={open[i]} timeout="auto" unmountOnExit>
                          <Box paddingX={2} fontSize={14}>
                            {" "}
                            <Box ml={1}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                pb={0.5}
                              >
                                <Box>Chaburah price</Box>
                                <Box>
                                  {formatMoney(
                                    +f.own_price * -1,
                                    upcomingBill.currency,
                                  )}
                                </Box>
                              </Box>
                              {!!f.multi && (
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  pb={0.5}
                                >
                                  <Box>Multi-student discount</Box>
                                  <Box>
                                    -
                                    {formatMoney(
                                      +f.multi,
                                      upcomingBill.currency,
                                    )}
                                  </Box>
                                </Box>
                              )}
                              {!!discount && (
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  pb={0.5}
                                >
                                  <Box>{f.discount?.reason || "discount"}</Box>
                                  <Box>
                                    -
                                    {formatMoney(
                                      +discount,
                                      upcomingBill.currency,
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Collapse>
                      </Box>
                    );
                  })}
                  <Divider
                    flexItem
                    sx={{ border: "1px solid #DDE3EB", my: 1 }}
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    pb={0.5}
                    fontSize={16}
                    fontWeight="bold"
                    color="#072556"
                  >
                    <Box>New monthly total</Box>
                    <Box>
                      {customersTotalDue && (
                        <>
                          {formatMoney(
                            customersTotalDue,
                            upcomingBill.currency,
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </TabContainer>
  );
};

export default TabBilling;
