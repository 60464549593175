import React from "react";
import { ToggleButtonContainer, ToggleButtonOption } from "./Button.styles";

interface ButtonDetails {
  label: string;
  value: string;
  disabled?: boolean;
}

interface ButtonProps {
  buttonOptions: ButtonDetails[];
  value: any;
  size?: "large" | "medium" | "small" | undefined;
  onChange: any;
  toggleStyle?: any;
}
const _ToggleButtons = (props: ButtonProps) => {
  const {
    buttonOptions = [],
    value,
    size = "large",
    onChange,
    toggleStyle = {},
  } = props;

  return (
    <ToggleButtonContainer
      value={value}
      exclusive
      size={size}
      onChange={onChange}
    >
      {buttonOptions?.map((bd: ButtonDetails, index: number) => {
        return (
          <ToggleButtonOption
            key={index}
            value={bd.value}
            disabled={!!bd.disabled}
            style={toggleStyle}
          >
            {bd.label}
          </ToggleButtonOption>
        );
      })}
    </ToggleButtonContainer>
  );
};

export const ToggleButtons = React.memo(_ToggleButtons);
