import { gematriya, toFloat } from "../../../lib";
import { Option } from "../../../components/Select/Select";
import {
  Month,
  NewStudentAndEnrollment,
  NewStudentBilling,
  SemesterItem,
  StudentDetailsCustomer,
  StudentEnrollmentEdit,
  StudentGeneralEdit,
  UpdatedPaymentData,
} from "../../../state";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import AdminPages from "../index";
import {
  StudentBillingFields,
  StudentEnrollmentFields,
  StudentGeneralFields,
} from "./schema";
import { WebApplicationMonth } from "../../../state/student/state";

export function buildYearsOptions(
  months: (Month | SemesterItem | WebApplicationMonth)[],
) {
  return [...new Set(months.map((m) => m.year_id))].map(
    (y) =>
      ({
        value: y.toString(),
        label: gematriya(y.toString()),
      } as unknown as Option),
  );
}

export function buildMonthsOptions(
  months: (Month | WebApplicationMonth)[],
  yearId: string,
) {
  return months
    .filter((y) => y.year_id.toString() === yearId)
    .map(
      (y) =>
        ({
          label: y.hebrew,
          value: y.id.toString(),
        } as unknown as Option),
    );
}

export function useGetCurrentPageMode() {
  const params = useParams();
  const location = useLocation();
  const fromCustomer = location.pathname.includes("from-customer");
  const pageMode =
    location.pathname ===
    AdminPages.editStudent.path.replace(":id", params?.id ?? "")
      ? "edit"
      : "insert";

  return { pageMode, id: params.id, fromCustomer };
}

export function buildCustomerOption(customer: StudentDetailsCustomer): Option {
  const name_yiddish = customer
    ? `${customer.first_name_yiddish} ${customer.last_name_yiddish}`
    : "";

  const name_english = customer
    ? `${customer.first_name} ${customer.last_name}`
    : "";

  const homePhone =
    customer?.phone_numbers?.filter((numberObj) => {
      return numberObj.type === "home";
    })?.[0]?.number ?? "";

  return {
    value: customer?.id.toString() ?? "",
    label: [name_english, homePhone, name_yiddish].filter(Boolean).join(" - "),
  };
}

export function buildStudentEditGeneralData(
  values: StudentGeneralFields,
  student_id: string,
) {
  const {
    customer_id,
    first_name,
    last_name,
    first_name_yiddish,
    last_name_yiddish,
    school_building_id,
    grade,
    birth_month_id,
    birth_month_day,
    status_id,
    registration,
    transportation,
    _student_siyums,
    referring_student_id,
    // notes,
    _notes,
    completed,
  } = values;

  const data = {} as StudentGeneralEdit;
  data.customer_id = +customer_id;
  data.first_name = first_name;
  data.last_name = last_name;
  data.first_name_yiddish = first_name_yiddish;
  data.last_name_yiddish = last_name_yiddish;
  data.school_building_id = parseInt(school_building_id) || null;
  data.grade = +grade;
  data.birth_month_id = +birth_month_id;
  data.birth_month_day = +birth_month_day;
  data.status_id = status_id;
  data.completed = completed;
  (data.referring_student_id = +referring_student_id || null),
    (data.student_siyums = JSON.parse(
      JSON.stringify(_student_siyums),
    ) as StudentGeneralEdit["student_siyums"]);

  if (
    data.student_siyums &&
    Array.isArray(data.student_siyums) &&
    data.student_siyums.length > 0
  ) {
    data.student_siyums[0].registration = registration;
    data.student_siyums[0].transportation = transportation;
  } else {
    data.student_siyums = [
      {
        student_id: +student_id,
        semester_id: null,
        transportation,
        registration,
      },
    ];
  }

  data.student_notes = JSON.parse(
    JSON.stringify(_notes),
  ) as StudentGeneralEdit["student_notes"];

  // notes was never getting set on edit over here so was overriding the original notes
  // if (
  //   data.student_notes &&
  //   Array.isArray(data.student_notes) &&
  //   data.student_notes.length > 0
  // ) {
  //   data.student_notes[0].note = notes;
  // } else {
  //   data.student_notes = [
  //     {
  //       note: notes,
  //     },
  //   ];
  // }

  return data;
}

export function buildStudentEnrollmentData(values: StudentEnrollmentFields) {
  const {
    teacher_id,
    customer_id,
    first_name,
    last_name,
    first_name_yiddish,
    last_name_yiddish,
    school_building_id,
    grade,
    birth_month_id,
    birth_month_day,
    notes,
    chaburah_id,
    month_id,
    override,
    set_call_date,
  } = values;

  return {
    teacher_id: parseInt(teacher_id),
    customer_id: parseInt(customer_id),
    first_name: first_name || null,
    last_name: last_name || null,
    first_name_yiddish: first_name_yiddish,
    last_name_yiddish: last_name_yiddish,
    school_building_id: parseInt(school_building_id) || null,
    grade: parseInt(grade) || null,
    birth_month_id: parseInt(birth_month_id),
    birth_month_day: parseInt(birth_month_day),
    notes: notes || null,
    chaburah_id: +chaburah_id,
    month_id: +month_id === 0 ? undefined : +month_id,
    override: override,
    set_call_date: set_call_date,
  } as StudentEnrollmentEdit;
}

export function buildStudentBillingCreditData(values: StudentBillingFields) {
  const { discounts, default_payment_method, payment_method_id } = values;

  return {
    default_payment_method,
    payment_method_id,
    discounts:
      discounts?.map(({ discount, discount_type, reason }) => {
        return {
          discount_type,
          reason,
          discount: discount ? +discount : null,
        };
      }) ?? null,
  } as UpdatedPaymentData;
}

export function buildNewStudentData(
  general: StudentGeneralFields,
  enrollment: StudentEnrollmentFields,
) {
  const {
    customer_id,
    first_name,
    last_name,
    first_name_yiddish,
    last_name_yiddish,
    school_building_id,
    grade,
    birth_month_id,
    birth_month_day,
    notes,
    referring_student_id,
    draft,
  } = general;

  const { chaburah_id, month_id, teacher_id } = enrollment;

  return {
    customer_id: +customer_id,
    teacher_id: +teacher_id === 0 ? undefined : +teacher_id,
    first_name,
    last_name,
    first_name_yiddish,
    last_name_yiddish,
    school_building_id: parseInt(school_building_id) || null,
    grade: +grade,
    birth_month_id: +birth_month_id,
    birth_month_day: +birth_month_day,
    notes,
    chaburah_id: +chaburah_id,
    month_id: +month_id === 0 ? undefined : +month_id,
    referring_student_id: parseInt(referring_student_id) || null,
    draft,
  } as NewStudentAndEnrollment;
}

export function buildNewStudentBillingCreditData(values: StudentBillingFields) {
  const { discounts, default_payment_method } = values;

  return {
    default_payment_method,
    payment_method_id: 1,
    discount: toFloat(discounts?.[0]?.discount) || null,
    discount_type: discounts?.[0]?.discount_type ?? null,
    reason: "",
  } as NewStudentBilling;
}
