import { styled } from "@mui/material/styles";
import { ListItemText } from "@mui/material";

export const MainContentStyled = styled(`div`)(({ theme }) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: "calc(100vw - 240px)",
  marginLeft: 240,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 0,
    width: "100vw",
  },
}));

export const StyledListItemText = styled(ListItemText)(
  ({ theme, color = "primary.contrastText" }) => {
    return {
      ml: -24,
      "& .MuiTypography-root": {
        color: theme.palette[color],
      },
    };
  },
) as typeof ListItemText;
