import { SxProps, Theme } from "@mui/material";

export const textField: SxProps<Theme> = {
  width: "170px",
  mt: "0!important",
  mb: "0!important",
  ml: "30px",

  "& .MuiInputLabel-root": {
    top: -6,
    // transform: "translate(14px, -4px) scale(0.75)",
  },

  "& .MuiInputBase-input": {
    py: "10px",
  },

  "& textarea.MuiInputBase-input": {
    py: 0,
  },
};

export const removeBtnStyle: SxProps<Theme> = {
  backgroundColor: "transparent",
  opacity: 0.7,
  height: "43px",
  width: "43px",
  mr: "50px",

  "& > .MuiSvgIcon-root": {
    color: "#000000",
    height: "20px",
    width: "20px",
  },
};
