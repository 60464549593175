import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { rowToolBtnStyle } from "../../constants/styles";
import { customerActions, useDispatch } from "../../state";
import { DeleteIcon } from "../icons";
import DeleteDialog from "../../pages/admin/components/Modals/DeleteDialog";

interface DeleteButtonProps {
  linked_invoice_id: number;
  transaction_id: number;
}
const _DeletePaymentButton = (props: DeleteButtonProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  useEffect(() => {
    if (confirmDelete) {
      dispatch(
        customerActions.deleteCustomerPayment(
          props.transaction_id,
          props.linked_invoice_id,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, confirmDelete]);

  return (
    <>
      <IconButton
        color="primary"
        sx={rowToolBtnStyle}
        onClick={handleDeleteClick}
      >
        <DeleteIcon />
      </IconButton>
      <DeleteDialog
        showModal={!!open}
        name={"this"}
        subject={"payment"}
        onClose={() => setOpen(false)}
        deleteFunction={() => setConfirmDelete(true)}
      />
    </>
  );
};

export const DeletePaymentButton = React.memo(_DeletePaymentButton);
