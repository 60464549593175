import React from "react";
import {
  //
  Button,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
//Assets
// Local
import { Form, Navigation, useFormik, useLocation, yup } from "../../lib";
import { authActions, useAction } from "../../state";
import AuthPages from ".";

const validationSchema = yup.object({
  password1: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const ConfirmAccountPage = React.memo(
  /**
   *
   */
  function ConfirmAccountPage() {
    const confirmAccount = useAction(authActions.confirmAccount);
    const location = useLocation();

    const frm = useFormik({
      initialValues: {
        password1: "",
        password2: "",
      },
      validationSchema,
      async onSubmit(values) {
        // TODO: Validate passwords. See mailpake/prime client-app ResetPasswordPage.jsx
        await confirmAccount({
          newPassword: values.password1,
          email: location.query.email,
          token: location.query.token,
        });
        Navigation.replace(AuthPages.login);
      },
    });

    return (
      <Paper elevation={1}>
        <Typography variant="h3">Confirm Account</Typography>
        <Typography variant="body2">Enter your new password here.</Typography>
        <Form form={frm}>
          <TextField
            fullWidth
            id="password1"
            name="password1"
            label="New password"
            type="password"
            autoComplete="new-password"
            value={frm.values.password1}
            onChange={frm.handleChange}
            error={frm.touched.password1 && Boolean(frm.errors.password1)}
            helperText={frm.touched.password1 && frm.errors.password1}
          />
          <TextField
            fullWidth
            id="password2"
            name="password2"
            label="Repeat new password"
            type="password"
            autoComplete="reenter-new-password"
            value={frm.values.password2}
            onChange={frm.handleChange}
            error={frm.touched.password2 && Boolean(frm.errors.password2)}
            helperText={frm.touched.password2 && frm.errors.password2}
          />
          <div>
            <Button
              variant="contained"
              type="submit"
              disabled={frm.isSubmitting}
            >
              Signup
            </Button>
          </div>
        </Form>
      </Paper>
    );
  },
);
