import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const btnStyle: SxProps<Theme> = {
  minWidth: "120px",
};

export const backBtnStyle: SxProps<Theme> = {
  mr: 1,

  "& > .MuiSvgIcon-root": {
    color: "#8495B2",
  },
};
