import { array, object, string } from "yup";
import { floatRegExp } from "../../../lib/regexp";
import { Question } from "../../../state";

export interface Fields {
  id: number;
  currMonth: string;
  description: string;
  max_review_checks: string;
  review_bonus: string;
  max_attendance_checks: string;
  attendance_bonus: string;
  charge_date: string | null;
  test_date: Date | null;
  test_id: number | null;
  chaburah_month_id: number | null;
  test_questions: Question[] | null;
}

export const initialValues: Fields = {
  id: 0,
  currMonth: "",
  description: "",
  max_review_checks: "",
  review_bonus: "",
  max_attendance_checks: "",
  attendance_bonus: "",
  charge_date: null,
  test_date: null,
  test_id: null,
  chaburah_month_id: null,
  test_questions: null,
};

export const validationSchema = object().shape({
  description: string().required("Required").label("Curriculum"),
  max_review_checks: string()
    .test(
      "max_review_checks",
      "${label} must be a number",
      (val = "") => !val || floatRegExp.test(val),
    )
    .test(
      "max_review_checks",
      "${label} must be no less than 0.05",
      (val = "") => !val || +val >= 0.05,
    )
    .test(
      "max_review_checks",
      "${label} must be no greater than 10,000",
      (val = "") => !val || +val <= 10_000,
    )
    .label("Max checks"),
  review_bonus: string()
    .test(
      "review_bonus",
      "${label} must be a number",
      (val = "") => !val || floatRegExp.test(val),
    )
    .test(
      "review_bonus",
      "${label} must be no less than 0.05",
      (val = "") => !val || +val >= 0.05,
    )
    .test(
      "review_bonus",
      "${label} must be no greater than 10,000",
      (val = "") => !val || +val <= 10_000,
    )
    .label("Bonus points"),
  max_attendance_checks: string()
    .test(
      "max_attendance_checks",
      "${label} must be a number",
      (val = "") => !val || floatRegExp.test(val),
    )
    .test(
      "max_attendance_checks",
      "${label} must be no less than 0.05",
      (val = "") => !val || +val >= 0.05,
    )
    .test(
      "max_attendance_checks",
      "${label} must be no greater than 10,000",
      (val = "") => !val || +val <= 10_000,
    )
    .label("Max checks"),
  attendance_bonus: string()
    .test(
      "attendance_bonus",
      "${label} must be a number",
      (val = "") => !val || floatRegExp.test(val),
    )
    .test(
      "attendance_bonus",
      "${label} must be no less than 0.05",
      (val = "") => !val || +val >= 0.05,
    )
    .test(
      "attendance_bonus",
      "${label} must be no greater than 10,000",
      (val = "") => !val || +val <= 10_000,
    )
    .label("Bonus points"),

  test_questions: array()
    .nullable(true)
    .of(
      object().shape({
        answer: string()
          .test("amount", "${label} must be a number", (val = "") => {
            return !val || /^[0-9]+$/.test(val);
          })
          .label("Answer"),
      }),
    ),
});
