import { CSSProperties } from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const topSection: CSSProperties = {
  marginTop: 0,
  paddingTop: 24,
  height: "124px",
  borderBottom: "1px solid #DDE3EB",
};

export const tabsRoot: SxProps<Theme> = {
  "& .MuiTab-root": {
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    p: "10px 24px",
    color: "#5C738E",
    cursor: "default",

    "&.Mui-selected": {
      color: "#082B65",
    },
  },
};

export const tabsWrapper: SxProps<Theme> = {
  mt: "11px",
  mx: 6,
};

export const button: SxProps<Theme> = {
  minWidth: "180px",
};
