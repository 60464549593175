import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGridPro,
  GridColDef,
  GridRowParams,
  GridRowId,
  gridVisibleSortedRowIdsSelector,
} from "@mui/x-data-grid-pro";

import {
  adminActions,
  adminSelectors,
  listsSelectors,
  Student,
} from "../../state";
import {
  date_joined,
  date_left,
  date_rejoined,
  last_check_in,
  defaultPaymentMethod,
  email,
  first_name,
  first_name_yiddish,
  fullName,
  fullNameEnglish,
  fullNameYiddish,
  getLaunchLink,
  grade_yiddish,
  last_name,
  last_name_yiddish,
  launch_icon,
  onboardingStatus,
  onboardingStatusLabels,
  pin,
  STUDENT_ADDRESS_FIELDS,
  student_status_id,
  v_student_cell_phone,
  v_student_customer,
  v_student_home_phone,
} from "../../constants";
import {
  CheckIcon,
  CloseIcon,
  CustomFooter,
  CustomToolbar,
  EditIcon,
} from "../../components";
import { getYiddishDate, Navigation, yiddishDays } from "../../lib";
import AdminPages from "../../pages/admin";
import OnboardingModal from "../../pages/admin/components/Modals/OnboardingModal";
import { IconButton } from "@mui/material";
import { format } from "date-fns";
import { dataGridFilterStyles } from "../../themes";

const hideColumns = {
  fullNameYiddish: false,
  fullNameEnglish: false,
  first_name: false,
  last_name: false,
  last_name_yiddish: false,
  first_name_yiddish: false,
  address: false,
  address_2: false,
  email: false,
  kinyan: false,
  menahel: false,
  school_building_id: false,
  school: false,
  default_payment_method: false,
  country_name: false,
  city: false,
  state: false,
  zip: false,
  date_joined: false,
  date_left: false,
  month_start: false,
  track: false,
  transportation: false,
  completed: false,
  registration: false,
  onboarding: false,
  onboarded: false,
  meet_time: false,
  meet_time_english: false,
  referring_student_id: false,
  earned: false,
  used: false,
  balance: false,
  bar_mitzva: false,
  created_by: false,
};

function _StudentsGrid({ rows, apiRef }) {
  const neighborhoods = useSelector(listsSelectors.neighborhoodById);
  const _schoolBuildings = useSelector(listsSelectors.schoolBuildings);
  const _schoolBuildingsPlusSchool = useSelector(
    listsSelectors.schoolBuildingsPlusSchool,
  );
  const _allTeachersNames = useSelector(listsSelectors.allTeachersYiddish);
  const users = useSelector(listsSelectors.users);
  const _allChaburahs = useSelector(listsSelectors.allChaburahs);
  const _allNeighborhoods = useSelector(listsSelectors.allNeighborhoods);
  const _allSchools = useSelector(listsSelectors.allSchools);
  const _allSchoolBuildings = useSelector(listsSelectors.allSchoolBuildings);
  const _allPrincipals = useSelector(listsSelectors.allPrincipals);
  const _allTracks = useSelector(listsSelectors.allTracks);
  const studentsById = useSelector(adminSelectors.studentsById);
  const monthsWithYears = useSelector(listsSelectors.monthsWithYearsSelect);
  const [filterMonthsOptions, setFilterMonthsOptions] = useState<string[]>([]);
  const monthId = useSelector(adminSelectors.currentMonthId);
  const [onboardingModalStudent, setOnboardingModalStudent] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (monthsWithYears && monthId) {
      const filterOptions = monthsWithYears
        .filter((o) => o.value >= 171 && o.value <= Number(monthId) + 8)
        .map((m) => m.label);
      setFilterMonthsOptions(filterOptions);
    }
  }, [monthsWithYears, monthId]);

  const getMonthReturned = (params) => {
    const { month_return, month_start } = params.row;
    return month_return ?? month_start;
  };
  const YiddishDate = ({ englishDate }) => {
    const { weekday, parsha, time } = getYiddishDate(new Date(englishDate));
    const margin = time ? "4px" : "0px";
    return (
      <span style={{ display: "inline-flex" }}>
        <span style={{ marginRight: margin }}>{time}</span>
        <span>{`${parsha} - ${yiddishDays[weekday]}`}</span>{" "}
      </span>
    );
  };
  const columns: GridColDef[] = useMemo(() => {
    const getSchoolBuildingFromId = (params) => {
      const sb =
        _schoolBuildings.filter(
          (b) => b?.school_id === params.row?.school_building_id,
        )[0]?.name || "";
      return sb;
    };
    const getSchoolFromId = (params) => {
      const sb =
        _schoolBuildingsPlusSchool.filter(
          (b) => b?.schoolBuildingId === params.row?.school_building_id,
        )[0]?.name || "";
      return sb;
    };

    const getStudentNeighborhood = (params) => {
      const { neighborhood_id } = params.row;
      return neighborhoods[neighborhood_id]?.name || "";
    };

    return [
      student_status_id,
      pin,
      {
        field: "onboarding",
        headerName: "Onboarding status",
        type: "singleSelect",
        valueOptions: onboardingStatusLabels,
        width: 200,
        valueGetter: (params) =>
          params.row.onboarding ? onboardingStatus[params.row.onboarding] : "",
        renderCell: (params) => (
          <span
            style={{
              alignItems: "center",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {params.row.onboarding ? (
              <span> {onboardingStatus[params.row.onboarding]}</span>
            ) : (
              <span></span>
            )}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setOnboardingModalStudent(params.row);
              }}
            >
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </span>
        ),
      },
      {
        field: "onboarded",
        headerName: "1st onboarding done",
        type: "boolean",
        valueGetter: (params) => !!params.row.onboarded,
        renderCell: (params) =>
          params.row.onboarded ? <CheckIcon /> : <CloseIcon />,
      },
      {
        field: "meet_time_english",
        headerName: "Scheduled date english",
        type: "date",
        width: 150,
        valueGetter: (params) => params.row.meet_time,
        valueFormatter: (params) =>
          params.value ? format(new Date(params.value), "p MM/dd/yyyy") : "",
        renderCell: (params) =>
          params.row.meet_time ? (
            <span>
              {format(new Date(params.row.meet_time), "p MM/dd/yyyy")}
            </span>
          ) : (
            <span></span>
          ),
      },
      {
        field: "meet_time",
        headerName: "Scheduled date yiddish",
        type: "dateTime",
        width: 280,
        valueGetter: (params) => params.row.meet_time,
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          const { weekday, parsha, time } = getYiddishDate(
            new Date(params.value),
          );
          return `${time} ${parsha} - ${yiddishDays[weekday]}`;
        },
        renderCell: (params) =>
          params.row.meet_time ? (
            <YiddishDate englishDate={params.row.meet_time} />
          ) : (
            <span></span>
          ),
      },
      fullName,
      fullNameYiddish,
      fullNameEnglish,
      first_name,
      last_name,
      last_name_yiddish,
      first_name_yiddish,
      {
        field: "chaburah_name",
        headerName: "חבורה",
        flex: 1,
        type: "singleSelect",
        valueOptions: _allChaburahs,
      },
      grade_yiddish,
      v_student_home_phone,
      v_student_cell_phone,
      email,
      ...STUDENT_ADDRESS_FIELDS,
      v_student_customer,
      {
        field: "neighborhood_id",
        headerName: "Neighborhood",
        renderCell: getStudentNeighborhood,
        valueGetter: getStudentNeighborhood,
        flex: 1,
        type: "singleSelect",
        valueOptions: _allNeighborhoods,
      },
      {
        field: "rosh_kinyan",
        headerName: "ראש קנין",
        flex: 1.5,
        type: "singleSelect",
        valueOptions: _allTeachersNames,
      },
      {
        field: "created_by",
        headerName: "Created by",
        type: "singleSelect",
        valueOptions: users?.map((u) => u.name_yiddish) || [],
        valueGetter: (params) =>
          users?.find((u) => u.id === params.row.created_by)?.name_yiddish,
      },
      {
        field: "track",
        headerName: "קנין",
        flex: 1.5,
        type: "singleSelect",
        valueOptions: _allTracks,
      },
      {
        field: "school_building_id",
        headerName: "School building",
        valueGetter: getSchoolBuildingFromId,
        renderCell: getSchoolBuildingFromId,
        flex: 1,
        type: "singleSelect",
        valueOptions: _allSchoolBuildings,
      },
      {
        field: "school",
        headerName: "School",
        valueGetter: getSchoolFromId,
        renderCell: getSchoolFromId,
        flex: 1,
        type: "singleSelect",
        valueOptions: _allSchools,
      },
      {
        field: "menahel",
        headerName: "מנהל",
        flex: 1.5,
        type: "singleSelect",
        valueOptions: _allPrincipals,
      },

      {
        field: "month_start",
        headerName: "Start Month",
        type: "singleSelect",
        valueOptions: filterMonthsOptions,
        flex: 1,
      },
      {
        field: "month_return",
        headerName: "Month Joined (Latest)",
        valueOptions: filterMonthsOptions,
        type: "singleSelect",
        valueGetter: getMonthReturned,
        renderCell: getMonthReturned,
        flex: 1,
      },
      date_joined,
      date_rejoined,
      date_left,
      last_check_in,
      defaultPaymentMethod,
      {
        field: "registration",
        headerName: "Registration",
        valueGetter: (params) => params.row.student_siyum?.registration,
        flex: 1,
      },
      {
        field: "completed",
        headerName: "Completed",
        type: "boolean",
        valueGetter: (params) => !!params.row.completed,
        renderCell: (params) =>
          params.row.completed ? <CheckIcon sx={{ color: "#128E56" }} /> : "",
      },
      {
        field: "transportation",
        headerName: "Transportation",
        valueGetter: (params) => params.row.student_siyum?.transportation,
        flex: 1,
      },
      {
        field: "referring_student_id",
        headerName: "Referred by",
        valueGetter: (params) => {
          if (!params.row.referring_student_id) {
            return "";
          }
          const s = studentsById[params.row.referring_student_id];
          return [s?.first_name_yiddish, s?.last_name_yiddish]
            .filter((a) => !!a)
            .join(" ");
        },
      },
      {
        field: "earned",
        headerName: "Points earned",
        type: "number",
        valueGetter: (params) => {
          return +params.row.earned || "";
        },
      },
      {
        field: "used",
        headerName: "Points used",
        type: "number",
        valueGetter: (params) => {
          return +params.row.used || "";
        },
      },
      {
        field: "balance",
        headerName: "Points Balance",
        type: "number",
        valueGetter: (params) => {
          return +params.row.balance || "";
        },
      },
      {
        field: "bar_mitzva",
        headerName: "Bar mitzvah",
        valueGetter: (params) => {
          return params.row.bar_mitzva;
        },
      },

      {
        ...launch_icon,
        renderCell: (rowData) =>
          getLaunchLink("students", rowData.id, rowData.row?.max_month_id),
      },
    ];
  }, [
    _allChaburahs,
    _allNeighborhoods,
    _allPrincipals,
    _allSchoolBuildings,
    _allSchools,
    _allTeachersNames,
    _allTracks,
    _schoolBuildings,
    _schoolBuildingsPlusSchool,
    filterMonthsOptions,
    neighborhoods,
    studentsById,
    users,
  ]);
  const handleClick = (e: GridRowParams<Student>) => {
    const studentId = e.row.id;

    studentId &&
      Navigation.go(
        `${AdminPages.studentPage.path.replace(
          ":id",
          studentId.toString(),
        )}?month_id=${e.row.max_month_id}`,
      );
  };

  const [visibleRows, setVisibleRows] = useState<GridRowId[]>([]);

  return (
    <>
      <DataGridPro
        sx={{
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
        onStateChange={(state) => {
          const newRows = gridVisibleSortedRowIdsSelector(state);
          setVisibleRows(newRows);
        }}
        pagination={true}
        rowsPerPageOptions={[
          10,
          25,
          50,
          100,
          visibleRows?.length > 100 ? visibleRows.length : 150,
        ]}
        apiRef={apiRef}
        onRowClick={(e) => handleClick(e)}
        rows={rows}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: hideColumns,
          },
          pagination: {
            pageSize: 25,
          },
          sorting: {
            sortModel: [{ field: "fullName", sort: "asc" }],
          },
          filter: {
            filterModel: {
              items: [
                {
                  columnField: "status",
                  operatorValue: "isAnyOf",
                  value: ["Active"],
                },
              ],
            },
          },
        }}
        components={{
          Toolbar: () => CustomToolbar(true, null, "getStudents", "student"),
          Footer: () => CustomFooter(visibleRows.length),
        }}
        componentsProps={{
          toolbar: { printOptions: { disableToolbarButton: true } },
          panel: {
            sx: {
              ...dataGridFilterStyles,
              "& .MuiPaper-root": {
                minHeight: "350px",
              },
            },
          },
        }}
      />
      {!!onboardingModalStudent && (
        <OnboardingModal
          showModal={!!onboardingModalStudent}
          onClose={() => setOnboardingModalStudent(undefined)}
          student={onboardingModalStudent}
          onSubmit={(student) => {
            dispatch(
              adminActions.setStudents(
                rows.map((r) => (r.id === student?.id ? student : r)),
              ),
            );
          }}
        />
      )}
    </>
  );
}

export const StudentsGrid = React.memo(_StudentsGrid);
