import React, { useEffect, useState } from "react";
import {
  adminActions,
  authSelectors,
  listsSelectors,
  useDispatch,
  User,
} from "../../../state";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminPages from "../index";

interface UserData {
  nameYiddish: string;
  nameEnglish: string;
  address: string;
  neighborhood: string;
  phone: string;
  cell: string;
  roles: string;
}

export function useProfilePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, setUser] = useState<UserData | null>(null);

  const userId = useSelector(authSelectors.userId);
  const neighborhoods = useSelector(listsSelectors.neighborhoodById);

  useEffect(() => {
    (async () => {
      const data = await dispatch<
        boolean | User | ReturnType<typeof adminActions.getUser>
      >(adminActions.getUser(userId.toString()));

      if (data) {
        const {
          person: {
            first_name_yiddish = "",
            last_name_yiddish = "",
            first_name = "",
            last_name = "",
            address,
            phone_numbers,
          },
          roles,
        } = data as User;

        const user = {
          nameYiddish: `${first_name_yiddish} ${last_name_yiddish}`,
          nameEnglish: `${first_name} ${last_name}`,
          address: `${address?.address || ""} ${address?.address_2 || ""} ${
            address?.city || ""
          } ${address?.state || ""} ${address?.zip || ""}`,
          neighborhood:
            neighborhoods[address?.neighborhood_id ?? 0]?.name ?? "",
          phone:
            phone_numbers?.filter((p) => p.type === "home")?.[0]?.number ?? "",
          cell:
            phone_numbers?.filter((p) => p.type === "cell")?.[0]?.number ?? "",
          roles: roles?.map((r) => r.role).join(", ") ?? "",
        } as UserData;

        setUser(user);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId]);

  const handleEditBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    navigate(AdminPages.editUsers.path.replace(":id", userId.toString()));
  };

  const handleUpdateBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  return {
    user,
    handleEditBtn,
    handleUpdateBtn,
  };
}
