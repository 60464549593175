import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import {
  adminActions,
  adminSelectors,
  Group,
  listsSelectors,
} from "../../../state";
import AdminPages from "../index";

export function useGroupPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const apiRef = useGridApiRef();

  const [data, setData] = useState<Group[] | null>(null);

  const neighborhoods = useSelector(listsSelectors.neighborhoodById);
  const tracks = useSelector(listsSelectors.trackById);
  const locations = useSelector(listsSelectors.locationById);

  useEffect(() => {
    dispatch(adminActions.getGroups());
  }, [dispatch]);

  const _groups = useSelector(adminSelectors.groups);

  useEffect(() => {
    if (_groups) {
      const newGroups = _groups.map((group) => {
        return {
          ...group,
          __list: {
            locations,
            tracks,
            neighborhoods,
          },
        } as Group;
      });

      setData(newGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_groups]);

  const handleRowClick = (e: GridRowParams<Group>) => {
    const groupId = e.row.id;
    groupId &&
      navigate(AdminPages.groupDetails.path.replace(":id", groupId.toString()));
  };

  const handleExportClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    apiRef.current.exportDataAsCsv();
  };

  const handleAddGroup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigate(AdminPages.createGroup.path);
  };

  return {
    data: data ?? [],
    apiRef,
    handleExportClick,
    handleAddGroup,
    handleRowClick,
  };
}
