import React, { useEffect, useRef, useState } from "react";
import { FormikProps } from "formik/dist/types";
import { Fields } from "./schema";
import { Props } from "./AddCredit";
import { useDispatch } from "react-redux";
import { adminActions } from "../../../../../state";
import { PaymentDetail } from "../../../../../state";

export function useAddCredit({ showModal, onClose, id }: Omit<Props, "name">) {
  const [open, setOpen] = useState<boolean>(showModal);
  const innerRef = useRef<FormikProps<Fields>>(null);
  const dispatch = useDispatch();

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    onClose ? onClose() : setOpen(false);
  };

  const handleSubmit = async ({ amount, note, balanceItems }: Fields) => {
    const paymentDetails = balanceItems
      ?.filter((b) => b.isSelected)
      .map(
        (b) =>
          (({
            invoice_id: b.id,
            month: b.month,
            amount: Math.abs(Number(b.amount)).toString(),
          } as PaymentDetail) ?? null),
      );

    await dispatch(
      adminActions.addCredit(
        {
          amount: +amount,
          note,
          payment_details: paymentDetails,
        },
        id,
      ),
    );
    handleClose();
  };

  useEffect(() => {
    !showModal && innerRef?.current?.resetForm();

    setOpen(showModal);
  }, [showModal]);

  return {
    innerRef,
    open,
    handleSubmit,
    handleClose,
  };
}
