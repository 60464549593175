import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { authSelectors, uiSelectors, useSelector } from "../../state";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { People, ExpandLess, ExpandMore } from "@mui/icons-material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AdminPages from "../../pages/admin";
import AuthPages from "../../pages/auth";
import { useMobile } from "../../themes";

const ProfileMenu = () => {
  const userInfo = useSelector(authSelectors.userInfo);
  const menuOpen = useSelector(uiSelectors.menuOpen);
  const isMobile = useMobile();
  const [open, setOpen] = useState(false);
  const isAdmin = useSelector(authSelectors.isAdmin);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <List>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{
              background: "#ffffff",
              color: "primary.main",
              ml: 1.5,
              mr: 1.5,
            }}
          >
            {isAdmin && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={handleClick}
                component={Link}
                to={AdminPages.users.path}
              >
                <ListItemIcon sx={{ color: "#526B93", margin: -2 }}>
                  <People />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
            )}
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={handleClick}
              component={Link}
              to={AdminPages.profile.path}
            >
              <ListItemIcon sx={{ color: "#526B93", margin: -2 }}>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="My account" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={handleClick}
              component={Link}
              to={AuthPages.logout.path}
            >
              <ListItemIcon sx={{ color: "#526B93", margin: -2 }}>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <ListItemIcon>
            <Avatar sx={{ width: 30, height: 30, fontSize: 14 }}>
              {userInfo.first_name?.charAt(0)}
              {userInfo.last_name?.charAt(0)}
            </Avatar>
          </ListItemIcon>
          {(menuOpen || !isMobile) && (
            <ListItemText
              primary={`${userInfo?.first_name} ${userInfo?.last_name}`}
              primaryTypographyProps={{
                variant: "h6",
                sx: {
                  ml: -2,
                  color: "primary.contrastText",
                },
              }}
            />
          )}
          {open ? (
            <ExpandMore sx={{ color: "primary.contrastText" }} />
          ) : (
            <ExpandLess sx={{ color: "primary.contrastText" }} />
          )}
        </ListItemButton>
      </List>
    </>
  );
};

export default ProfileMenu;
