import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Transition } from "./Transition";
import AdminPages from "../..";
import { history } from "../../../../lib";
interface Props {
  showModal: boolean;
  data: any;
  onClose: () => void;
}

const CustomerExistsModal: FC<Props> = ({ showModal, data, onClose }) => {
  return (
    <Dialog
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Customer already exists"}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          A customer with this phone number already exists how would you like to
          proceed?
        </DialogContentText>
      </DialogContent>

      <Divider variant="middle" sx={{ border: "1px solid #DDE3EB", mb: 2 }} />

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(
              `${AdminPages.customer.path.replace(
                ":id",
                data.person_id.toString(10),
              )}`,
            );
          }}
        >
          Go to existing customer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerExistsModal;
