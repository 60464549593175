import React from "react";
import { motion } from "framer-motion";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Container,
  Typography,
  Stack,
  StackProps,
} from "@mui/material";
import {
  varFadeIn,
  varFadeInUp,
  varWrapEnter,
  varFadeInRight,
} from "../animate";

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.grey[400],
  [theme.breakpoints.up("md")]: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    display: "flex",
    position: "fixed",
    alignItems: "center",
  },
}));

const ContentStyle = styled((props: StackProps) => (
  <Stack spacing={5} {...props} />
))(({ theme }) => ({
  zIndex: 10,
  maxWidth: 520,
  margin: "auto",
  textAlign: "center",
  position: "relative",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up("md")]: {
    margin: "unset",
    textAlign: "left",
  },
}));

const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: "100%",
  margin: "auto",
  position: "absolute",
  [theme.breakpoints.up("lg")]: {
    right: "8%",
    width: "auto",
    height: "48vh",
  },
}));

// ----------------------------------------------------------------------

export default function LandingHero() {
  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <HeroOverlayStyle
          alt="overlay"
          src="/static/overlay.svg"
          variants={varFadeIn}
        />

        <HeroImgStyle
          alt="hero"
          src="/static/hero.png"
          variants={varFadeInUp}
        />

        <Container maxWidth="lg">
          <ContentStyle>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h1" sx={{ color: "common.white" }}>
                Welcome to the Bitbean bootstrap
              </Typography>
            </motion.div>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h2" sx={{ color: "common.white" }}>
                Lets get started!
              </Typography>
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Typography sx={{ color: "common.white" }}>
                We build custom software for ambitious companies striving to be
                industry leaders.
              </Typography>
            </motion.div>

            <Stack
              component={motion.div}
              variants={varFadeInRight}
              direction="row"
              spacing={1}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <img
                alt="Bitbean logo"
                src="https://www.bitbean.com/assets/images/bb-logo.svg"
                width={40}
                height={40}
              />

              <Link
                underline="always"
                href="https://www.bitbean.com"
                target="_blank"
                color="common.white"
                sx={{ typography: "h3" }}
              >
                Check us out!
              </Link>
            </Stack>

            <Stack
              direction="row"
              spacing={1.5}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <motion.img
                variants={varFadeInRight}
                src="/static/ic_sketch.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/ic_figma.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/ic_material.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/ic_react.svg"
              />
              <motion.img variants={varFadeInRight} src="/static/ic_js.svg" />
              <motion.img variants={varFadeInRight} src="/static/ic_ts.svg" />
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: "100vh" } }} />
    </>
  );
}
