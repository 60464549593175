import { authClient, uiActions } from "../states";
import { AppThunk } from "..";
import { student } from "./state";
import { AxiosRequestConfig } from "axios";

const { actions } = student;

export const studentActions = {
  ...actions,
  getStudentMonth(studentId: any, monthId: any): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const config: AxiosRequestConfig = {
        params: { month_id: monthId || null },
      };

      const { data, status } =
        (await authClient.get(`/students/${studentId}/monthly`, config)) || {};

      if (status === 200) {
        dispatch(studentActions.setStudentMonthData(data));
      } else if (status === 404) {
        dispatch(
          studentActions.setStudentMonthData({
            not_enrolled: true,
            id: studentId,
          }),
        );
      } else {
        dispatch(
          uiActions.showError("Get student months failed", data?.message),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getStudentRedemptions(id) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const studentId = id ?? getState().student.studentMonthData?.id;
      if (!studentId) {
        return;
      }
      const { data, status } = await authClient.get(
        `/redemptions/limited?student_id=${studentId}`,
      );
      if (status === 200 || status === 304) {
        dispatch(this.setStudentRedemptions(data));
      } else {
        dispatch(uiActions.showError("Get student redemptions failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getChaburahMonth(chaburahId, monthId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const config: AxiosRequestConfig = {
        params: { month_id: monthId || null },
      };
      const { data, status } = await authClient.get(
        `/chaburah/${chaburahId}/monthly`,
        config,
      );

      if (status === 200) {
        dispatch(studentActions.setChaburahMonthData(data));
      } else if (status === 404) {
        dispatch(studentActions.setChaburahMonthData({}));
        // dispatch(
        //   uiActions.showError("No chaburah data for this students month"),
        // );
      } else {
        dispatch(uiActions.showError("Get Chaburah months failed"));
      }
      dispatch(uiActions.setLoading(false));
      return data;
    };
  },
  getWebApplicationDetails(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.get(`chaburah_signup`);

      if (status === 200) {
        dispatch(studentActions.setWebApplicationDetails(data));
      } else {
        dispatch(uiActions.showError("Get web application details failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};
