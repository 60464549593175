import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, Product, Vendor, Redemption, RedemptionItem } from "../types";
export interface PrizesState {
  products: List<Product>;
  productSubmit: {
    success: boolean;
  };
  redemptionSubmit: {
    success: boolean;
  };
  product: Product | undefined;
  redemptions: List<Redemption>;
  redemption: Redemption | undefined;
  redemptionItems: List<RedemptionItem>;
  vendors: List<Vendor>;
  vendorsLoading: boolean;
  prizeNumbers: number[];
}

const initialState: PrizesState = {
  products: {
    count: 0,
    rows: [],
  },
  productSubmit: {
    success: false,
  },
  vendors: {
    count: 0,
    rows: [],
    uninitialized: true,
  },
  vendorsLoading: false,
  product: undefined,
  redemption: undefined,
  redemptions: {
    count: 0,
    rows: [],
  },
  redemptionSubmit: {
    success: false,
  },
  redemptionItems: {
    count: 0,
    rows: [],
  },
  prizeNumbers: [],
};

export const prizes = {
  ...createSlice({
    name: "prizes",
    initialState,
    reducers: {
      setProducts(state, { payload }: PayloadAction<List<Product>>) {
        state.products = payload;
      },
      setProduct(state, { payload }: PayloadAction<any>) {
        state.product = payload;
      },
      setProductSubmit(state, { payload }) {
        state.productSubmit.success = payload;
      },
      setVendors(state, { payload }: PayloadAction<List<Vendor>>) {
        state.vendors = payload;
      },
      setVendorsLoading(state, { payload }: PayloadAction<boolean>) {
        state.vendorsLoading = payload;
      },
      setRedemptions(state, { payload }: PayloadAction<List<Redemption>>) {
        state.redemptions = payload;
      },
      setRedemption(state, { payload }: PayloadAction<Redemption | undefined>) {
        state.redemption = payload;
      },
      setRedemptionSubmit(state, { payload }) {
        state.redemptionSubmit.success = payload;
      },
      setRedemptionItems(
        state,
        { payload }: PayloadAction<List<RedemptionItem>>,
      ) {
        state.redemptionItems = payload;
      },
      setPrizeNumbers(state, { payload }) {
        state.prizeNumbers = payload;
      },
    },
  }),
  persist: false,
};
