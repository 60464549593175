import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { Transition } from "./Transition";
import { Theme } from "@mui/system";
import {
  adminActions,
  OnboardingStudentEdit,
  useDispatch,
} from "../../../../state";
import Select from "../../../../components/Select/Select";

import { DateTimePicker } from "@mui/x-date-pickers";
import { isValid } from "date-fns";
import CheckBox from "../../../../components/CheckBox";
import { Formik } from "formik";

import { onboardingOptions } from "../../../../constants";
import { getYiddishDate, yiddishDays } from "../../../../lib";

const btnSx: SxProps<Theme> = {
  minWidth: "140px",
};

interface Props {
  showModal: boolean;
  onClose?: () => void;
  student: OnboardingStudentEdit;
  onSubmit?: (s?: OnboardingStudentEdit) => void;
}

const OnboardingModal: FC<Props> = ({
  showModal,
  onClose,
  student,
  onSubmit,
}) => {
  const [open, setOpen] = useState<boolean>(showModal);
  const [yiddishDate, setYiddishDate] = useState<string>();
  const dispatch = useDispatch();

  const { id, meet_time, onboarded, onboarding } = student;

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    if (meet_time) {
      const { weekday, parsha, time } = getYiddishDate(new Date(meet_time));
      setYiddishDate(`${time} ${parsha} - ${yiddishDays[weekday]}`);
    }
  }, [meet_time]);

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement> | undefined) => {
    e?.stopPropagation();
    onClose ? onClose() : setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>
        Onboarding {student.first_name_yiddish} {student.last_name_yiddish}
      </DialogTitle>
      <Formik
        initialValues={{
          meet_time,
          onboarded,
          onboarding: onboarding || "Not_Started",
        }}
        onSubmit={async ({ meet_time, onboarded, onboarding }) => {
          const updatedStudent = {
            ...student,
            meet_time,
            onboarded,
            onboarding,
          };
          if (id)
            await dispatch(
              adminActions.updateStudent(id.toString(), updatedStudent),
            );
          if (onSubmit) {
            await onSubmit(updatedStudent);
          }

          handleClose();
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <>
            <DialogContent>
              <DialogContentText component={"div"}>
                <div
                  style={{
                    display: "flex",
                    minWidth: "500px",
                    maxWidth: "100%",
                  }}
                >
                  <div style={{ width: "50%", marginRight: "20px" }}>
                    <Select
                      options={onboardingOptions}
                      label="Onboarding status"
                      value={values.onboarding}
                      onChange={(e) => {
                        setFieldValue("onboarding", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <DateTimePicker
                      componentsProps={{
                        actionBar: {
                          actions: ["clear", "accept", "cancel"],
                        },
                      }}
                      inputFormat="MM/dd/yyyy hh:mma"
                      label="Schedule date"
                      value={values.meet_time}
                      minDate={new Date()}
                      onChange={(date: any) => {
                        if (date && isValid(date)) {
                          setFieldValue("meet_time", date.toISOString());
                          const { weekday, parsha, time } =
                            getYiddishDate(date);
                          setYiddishDate(
                            `${time} ${parsha} - ${yiddishDays[weekday]}`,
                          );
                        } else {
                          setFieldValue("meet_time", null);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <CheckBox
                      label="1st Onboarding done"
                      checked={!!values.onboarded}
                      onChange={() => {
                        setFieldValue("onboarded", !values.onboarded);
                      }}
                    />
                  </div>
                  <div>
                    <Typography>{yiddishDate}</Typography>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>

            <Divider
              variant="middle"
              sx={{ border: "1px solid #DDE3EB", mb: 2 }}
            />

            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                sx={btnSx}
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color={"primary"}
                sx={btnSx}
                onClick={submitForm}
              >
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default OnboardingModal;
