import { AppState } from "../types";
import { createSelector } from "@reduxjs/toolkit";
import { arrayToEntityList } from "../../lib";

const _state = (state: AppState) => state.admin;

const _studentsFilter = (typeId: number) =>
  createSelector(_state, (admin) =>
    admin.students.filter(
      (r) => r.student_months[0]?.chaburah?.chaburah_type_id === typeId,
    ),
  );

const _studentMonthsFilter = (typeId: number) =>
  createSelector(_state, (admin) =>
    admin.studentMonths.filter(
      (r) => r.student_months[0]?.chaburah?.chaburah_type_id === typeId,
    ),
  );
const _studentsById = createSelector(
  _state,
  (state) => arrayToEntityList(state.students).entities,
);

const activeGroups = createSelector(_state, (admin) =>
  admin.groups?.filter((r) => r.active),
);

export const adminSelectors = {
  users(state: AppState) {
    return state.admin.users;
  },
  activeBonus(state: AppState) {
    return state.admin.activeBonus;
  },
  currentMonthId(state: AppState) {
    return state.admin.currentMonth;
  },
  students(state: AppState) {
    return state.admin.students;
  },
  studentsById: _studentsById,
  student(state: AppState) {
    return state.admin.student;
  },
  studentHistory(state: AppState) {
    return state.admin.studentHistory.rows;
  },
  studentHistoryDetails(state: AppState) {
    return state.admin.studentHistoryDetails.rows;
  },
  studentCalendar(state: AppState) {
    return state.admin.studentCalendar;
  },
  studentRewards(state: AppState) {
    return state.admin.studentRewards.rows;
  },
  activeEnrollmentPrices(state: AppState) {
    return state.admin.groups?.find(
      (r) => r.id?.toString() === state.admin.activeEnrollmentId?.toString(),
    )?.prices;
  },
  studentsLive(state: AppState) {
    return state.admin.students.filter((r) => r.type === "live");
  },
  studentsRemote(state: AppState) {
    return state.admin.students.filter((r) => r.type === "remote");
  },

  studentMonth(state: AppState) {
    return state.admin.studentMonths;
  },
  studentMonthsLive(state: AppState) {
    const filtered = state.admin.studentMonths.filter(
      (r) => r.student_months?.[0]?.v_chaburah?.type === "live",
    );
    return filtered;
  },
  studentMonthsRemote(state: AppState) {
    const filtered = state.admin.studentMonths.filter(
      (r) => r.student_months?.[0]?.v_chaburah?.type === "remote",
    );
    return filtered;
  },

  groups(state: AppState) {
    return state.admin.groups;
  },
  activeGroups,
  neighborhoods(state: AppState) {
    return state.admin.settings.neighborhoods;
  },
  cacheUpdatedAt(state: AppState) {
    return state.admin.cacheUpdatedAt;
  },
  editChaburahData(state: AppState) {
    return state.admin.editChaburahData;
  },
  getMonthDates(state: AppState) {
    return state.admin.monthDates;
  },
};
