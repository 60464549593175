import React, { FC } from "react";
import TabContainer from "../../components/TabContainer";
import { Button, Grid, Typography } from "@mui/material";
import { useTabCurriculum } from "./useTabCurriculum";
import { FormikProps } from "formik";
import { CurriculumFields } from "../schema";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { CURRICULUM_COLUMNS } from "../../../../constants";
import { gridStyle, gridSx, textField } from "./TabCurriculum.styles";
import { AddIcon } from "../../../../components";
import Select from "../../../../components/Select";
import { SystemStyleObject } from "@mui/system";
import { gridItem } from "../TabDetails/TabDetails.styles";
import { dataGridFilterStyles } from "../../../../themes";

export interface Props {
  formik: FormikProps<CurriculumFields>;
}

const TabCurriculum: FC<Props> = ({ formik }) => {
  const {
    values,
    touched,
    errors,
    years,
    tracks,
    semesterTypes,
    rows,
    handleChange,
    handleBlur,
    handleAddZman,
    pageMode,
  } = useTabCurriculum({ formik });

  return (
    <TabContainer sx={{ minHeight: "450px" }}>
      <Grid container direction="row" justifyContent="space-between">
        <Typography variant={"h6"}>Group begins</Typography>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={gridStyle}
        >
          <Grid item>
            <Select
              name="yearId"
              sx={[
                textField as SystemStyleObject,
                { width: "196px" } as SystemStyleObject,
              ]}
              disabled={pageMode === "edit"}
              width={"unset"}
              label={"שנה"}
              placeholder={"שנה"}
              options={years}
              value={values.yearId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.yearId && !!errors.yearId}
              helperText={touched.yearId && errors.yearId}
            />

            <Select
              name="semesterTypeId"
              sx={[
                textField as SystemStyleObject,
                { width: "196px", ml: 4 } as SystemStyleObject,
              ]}
              width={"unset"}
              label={"זמן"}
              disabled={pageMode === "edit"}
              placeholder={"זמן"}
              options={semesterTypes}
              value={values.semesterTypeId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.semesterTypeId && !!errors.semesterTypeId}
              helperText={touched.semesterTypeId && errors.semesterTypeId}
            />
          </Grid>
        </Grid>

        <Grid item sx={gridItem}>
          <Typography variant={"h6"}>Curriculum</Typography>

          <Select
            name="trackId"
            label={"קנין"}
            sx={textField}
            options={tracks}
            value={values.trackId}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.trackId && !!errors.trackId}
            helperText={touched.trackId && errors.trackId}
          />
        </Grid>

        <Grid item xs={10}>
          <DataGridPro
            sx={gridSx}
            rowHeight={74}
            getRowId={(row) => row.semester_id}
            disableSelectionOnClick={true}
            hideFooterRowCount={true}
            hideFooter={true}
            rows={rows ?? []}
            autoHeight={true}
            columns={CURRICULUM_COLUMNS}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
              },
              panel: {
                sx: {
                  ...dataGridFilterStyles,
                },
              },
            }}
          />

          <Button variant={"outlined"} sx={{ mt: 2 }} onClick={handleAddZman}>
            <AddIcon />
            zman
          </Button>
        </Grid>
      </Grid>
    </TabContainer>
  );
};

export default TabCurriculum;
