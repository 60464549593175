import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types";

export interface AuthState {
  dealerId?: number;
  roles?: string[];
  token?: string;
  userId?: number;
  userName?: string;
  user?: User;
}

const initialState: AuthState = {};

export const auth = {
  ...createSlice({
    name: "auth",
    initialState,
    reducers: {
      setAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) {
          return initialState;
        }
        Object.assign(state, payload);
      },
    },
  }),
  persist: true,
};
