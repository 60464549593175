import AutocompleteMui, { AutocompleteProps } from "@mui/material/Autocomplete";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import React, { forwardRef } from "react";
import { Option } from "../Select/Select";
import { TextField } from "@mui/material";
import { SystemStyleObject, Theme } from "@mui/system";

export interface Props {
  options?: Option[];
  placeholder?: string;
  error?: boolean;
  name?: string;
}

type CustomAutocompleteProps = Omit<
  AutocompleteProps<Option, boolean, boolean, boolean>,
  "renderInput"
>;

const Autocomplete = forwardRef(
  (
    {
      options,
      placeholder,
      sx,
      error,
      ...props
    }: Props & CustomAutocompleteProps,
    ref,
  ): JSX.Element => {
    return (
      <AutocompleteMui
        {...props}
        ref={ref}
        isOptionEqualToValue={(option, value: Option | string) => {
          return (
            option.value === (value as Option)?.value ||
            option.value === (value as string)
          );
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.label || ""
        }
        options={options}
        sx={[
          {
            "& .MuiFormControl-root": {
              "& > .MuiInputBase-root": {
                backgroundColor: "primary.contrastText",

                "& > .MuiInputBase-input": {
                  p: "3.5px 4px 3.5px 6px",
                },
                "& > .MuiOutlinedInput-notchedOutline": {
                  borderColor: error ? "red" : "auto",
                },
              },
            },
          } as SystemStyleObject<Theme>,
          sx as SystemStyleObject<Theme>,
        ]}
        renderInput={(params: TextFieldProps) => (
          <TextField placeholder={placeholder} {...params} />
        )}
      />
    );
  },
);

export default Autocomplete;
