import React from "react";
import { useSelector } from "react-redux";
import { customerSelectors } from "../../../state";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { CUSTOMER_LEGACY_PAYMENT } from "../../../constants";
import { CustomFooter } from "../../../components";

function _OldSystemPaymentTable() {
  const apiRef = useGridApiRef();
  const payments = useSelector(customerSelectors.customerLegacyPayments);

  return (
    <>
      {payments && (
        <DataGridPro
          autoHeight
          apiRef={apiRef}
          rows={payments}
          columns={CUSTOMER_LEGACY_PAYMENT}
          initialState={{
            columns: {
              columnVisibilityModel: { id: false, created_at: false },
            },
            pagination: {
              pageSize: 25,
            },
            sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
          }}
          components={{ Footer: () => CustomFooter(payments.length) }}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
          }}
        />
      )}
    </>
  );
}

export const OldSystemPaymentTable = React.memo(_OldSystemPaymentTable);
