import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { FormikProps } from "formik/dist/types";
import { Fields, initialValues } from "./schema";
import { Props } from "./RefundModal";
import { FormikHelpers } from "formik";
import { SelectOption } from "../../../../../components/Select";
import { adminActions, NewRefund, Refund } from "../../../../../state";
import { toFloat, toInt } from "../../../../../lib";

export function useRefundModal({ modal, onClose }: Props) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(!!modal);
  const [transactions, setTransactions] = useState<SelectOption[]>([]);

  const innerRef = useRef<FormikProps<Fields>>(null);

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    onClose ? onClose(null) : setOpen(false);
  };

  const handleSubmit = async (
    { transaction_id, amount, note }: Fields,
    { setSubmitting }: FormikHelpers<Fields>,
  ) => {
    const refund: NewRefund = {
      transaction_id: toInt(transaction_id),
      amount: toFloat(amount),
      note: note ?? "",
    };

    const result = await dispatch<
      boolean | ReturnType<typeof adminActions.createRefund>
    >(adminActions.createRefund(modal?.customerId!, refund));

    setSubmitting(false);

    if (result) {
      const data: typeof modal = {
        ...modal!,
        ...refund,
      };

      onClose?.(data);
    }
  };

  useEffect(() => {
    (async () => {
      if (modal?.customerId) {
        const rt = await dispatch<
          number | ReturnType<typeof adminActions.getRefundableTransactions>
        >(adminActions.getRefundableTransactions(modal?.customerId));

        if (rt) {
          const cardsNumbers: Fields["cardsNumbers"] = {};

          const trList = (rt as unknown as Refund[]).map(
            ({ id, description, transaction_response, amount }) => {
              cardsNumbers[id.toString()] = (
                transaction_response?.response?.xMaskedCardNumber ?? ""
              ).slice(-4);

              return {
                id: id.toString(),
                name: `${description ?? ""} - $${
                  typeof amount === "string"
                    ? parseFloat(amount).toFixed(2)
                    : amount
                }`,
              } as SelectOption;
            },
          );

          setTransactions(trList);

          innerRef?.current?.setFieldValue("cardsNumbers", cardsNumbers);
        }
      }
    })();
  }, [dispatch, modal?.customerId]);

  useEffect(() => {
    !modal && innerRef?.current?.resetForm({ values: initialValues });

    setOpen(!!modal);
  }, [modal]);

  const caption = modal
    ? `Refund ${modal?.name ?? ""} - ${modal?.nameYiddish ?? ""}`
    : "";

  return {
    innerRef,
    caption,
    transactions,
    open,
    handleClose,
    handleSubmit,
  };
}
