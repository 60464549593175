import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  listsSelectors,
  studentSelectors,
  uiActions,
} from "../../../state";
import { useParams } from "react-router";
import { gematriya, toInt } from "../../../lib";
import {
  BottomSection,
  MiddleSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import NameView from "./NameView";
import General from "./General";
import { Alert, Box, Button, Tab, Tabs } from "@mui/material";
import TabDetails from "./TabDetails";
import TabHistory from "./TabHistory";
import TabRewards from "./TabRewards";
import TabManage from "./TabManage";
import { NoteAddIcon } from "../../../components";
import AddNoteModal from "../components/Modals/AddNoteModal";
import Toolbar from "./Toolbar";
import { TestModalData } from "./EnterTestModal/types";
import { ModalData as MonthlyPointsModalData } from "../components/Modals/AddMonthlyPoints/types";
import AddMonthlyPoints from "../components/Modals/AddMonthlyPoints";
import EnterTestModal from "./EnterTestModal";

type TabType = "manage" | "details" | "history" | "rewards";

const StudentPage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const _months = useSelector(listsSelectors.monthById);
  const currentMonthId: any = useSelector(adminSelectors.currentMonthId);

  const [tab, setTab] = useState<TabType>("manage");
  const [noteModalShow, setNoteModalShow] = useState(false);
  const [monthlyPointsModal, setMonthlyPointsModal] =
    useState<MonthlyPointsModalData | null>(null);
  const [testModal, setTestModal] = useState<TestModalData | null>(null);
  const student = useSelector(adminSelectors.student);
  const studentDetails = useSelector(studentSelectors.studentMonthData);
  const chaburah = useSelector(studentSelectors.setChaburahMonthData);

  const handleTabChange = (event: React.SyntheticEvent, value: TabType) => {
    event.stopPropagation();
    setTab(value);
  };

  useEffect(() => {
    if (currentMonthId === null) {
      dispatch(adminActions.getCurrentMonth());
    }
    !!params.id &&
      toInt(params.id) &&
      dispatch(adminActions.getStudent(toInt(params.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.id]);

  const handleAddMonthlyPoints = useCallback(() => {
    if (
      studentDetails?.id &&
      !!studentDetails.student_months?.length &&
      studentDetails.student_months?.[0]?.month_id
    ) {
      const { first_name_yiddish, last_name_yiddish } = studentDetails;
      setMonthlyPointsModal({
        studentId: studentDetails.id,
        month_id: studentDetails.student_months[0].month_id,
        review_count: studentDetails.student_months[0].review_count,
        attendance_count: studentDetails.student_months[0].attendance_count,
        gathering: studentDetails.student_months[0].gathering,
        monthly_note: studentDetails.student_months[0].monthly_note,
        check_in: studentDetails.student_months[0].check_in,
        max_check_in: chaburah?.max_attendance_checks,
        max_review_count: chaburah?.max_review_checks,
        call_date: studentDetails.student_months[0]?.call_date,
        first_name_yiddish,
        last_name_yiddish,
      });
    } else {
      dispatch(uiActions.showError("Student not enrolled for this month"));
    }
  }, [
    studentDetails,
    chaburah?.max_attendance_checks,
    chaburah?.max_review_checks,
    dispatch,
  ]);
  const tabs: Record<TabType, ReactNode> = {
    manage: <TabManage openAddMonthlyPointsModal={handleAddMonthlyPoints} />,
    details: <TabDetails />,
    history: <TabHistory />,
    rewards: <TabRewards />,
  };
  const handleEnterTest = useCallback(() => {
    if (studentDetails?.student_months?.length) {
      setTestModal({
        chaburahId: studentDetails.student_months[0].chaburah_id,
        monthId: studentDetails.student_months[0].month_id,
        studentId: studentDetails.id,
        studentAnswers: studentDetails.answers,
        lookedIn: studentDetails.student_months?.[0].answers_looked_in,
      });
    } else {
      dispatch(uiActions.showError("Student not enrolled for this month"));
    }
  }, [
    dispatch,
    studentDetails.answers,
    studentDetails.id,
    studentDetails.student_months,
  ]);
  const handleTestModalClose = useCallback(() => {
    setTestModal(null);
  }, []);
  const handleMonthlyPointsModalClose = (_data: typeof monthlyPointsModal) => {
    setMonthlyPointsModal(null);
  };

  if (!student) {
    return null;
  }
  const { id, first_name, last_name, first_name_yiddish, last_name_yiddish } =
    student;

  return (
    <Page>
      <TopSection style={{ marginTop: 0, paddingTop: 24 }}>
        <NameView />
      </TopSection>

      <MiddleSection>
        <General />
        {!!student?.future_changes?.length && (
          <Alert severity="warning" sx={{ mt: 1 }}>
            {student.future_changes.map((change) => {
              const chodesh = _months[change.month_id].hebrew;
              const year = _months[change.month_id].year_id;
              const joining = !student.student_months?.some(
                (m) => m.month_id <= currentMonthId,
              );
              const rejoining =
                student.student_months?.some(
                  (m) => m.month_id < currentMonthId,
                ) &&
                (student.status_id === 3 ||
                  student.status_id === 4 ||
                  !student.student_months?.some(
                    (m) => m.month_id === currentMonthId,
                  ));
              return ` ${
                joining ? "Starting" : rejoining ? "Rejoining" : "Switching to"
              } ${chodesh} ${gematriya(year).slice(2)} - ${
                change["chaburah.name"]
              } `;
            })}
          </Alert>
        )}
      </MiddleSection>

      <BottomSection style={{ paddingTop: 24 }}>
        <Box
          sx={{ borderBottom: "1px solid #DDE3EB" }}
          display="flex"
          justifyContent="space-between"
        >
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Manage" value={"manage" as TabType} />
            <Tab label="Details & Notes" value={"details" as TabType} />
            <Tab label="Student path" value={"history" as TabType} />
            <Tab label="Rewards" value={"rewards" as TabType} />
          </Tabs>
          <Box display="inline-flex">
            <Button
              size="small"
              sx={{ fontSize: "14px" }}
              onClick={() => {
                setNoteModalShow(true);
              }}
            >
              <NoteAddIcon fontSize="small" sx={{ mr: 1 }} /> Add note
            </Button>
            <Toolbar
              onAddMonthlyPoints={handleAddMonthlyPoints}
              onEnterTest={handleEnterTest}
              studentId={student.id}
            />

            <AddNoteModal
              showModal={noteModalShow}
              name={`${first_name} ${last_name} - ${first_name_yiddish} ${last_name_yiddish}`}
              onClose={() => setNoteModalShow(false)}
              id={id}
              action={"Add"}
            />
            <EnterTestModal modal={testModal} onClose={handleTestModalClose} />
            <AddMonthlyPoints
              modal={monthlyPointsModal}
              onClose={handleMonthlyPointsModalClose}
            />
          </Box>
        </Box>

        {tabs[tab]}
      </BottomSection>
    </Page>
  );
};

export default StudentPage;
