import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { Transition } from "./Transition";
import { Theme } from "@mui/system";
import { adminActions, useDispatch } from "../../../../state";

const captionSx: SxProps<Theme> = {
  color: "#000000",
  fontSize: "14px",
  fontWeight: "bold",
  letterSpacing: "0",
  lineHeight: "16px",
};

const btnSx: SxProps<Theme> = {
  minWidth: "140px",
};

const noteSx: SxProps<Theme> = {
  p: 0,
  minWidth: "500px",
};

interface Props {
  showModal: boolean;
  name: string;
  note?: string;
  onClose?: () => void;
  id: number;
  action: string;
  item_id?: number;
  text?: string;
}

const AddNoteModal: FC<Props> = ({
  showModal,
  item_id,
  name,
  note: _note = "",
  onClose,
  id,
  action,
  text,
}) => {
  const [open, setOpen] = useState<boolean>(showModal);
  const [note, setNote] = useState<string>(_note);
  const dispatch = useDispatch();
  useEffect(() => {
    !showModal && setNote(text ? text : "");
    setOpen(showModal);
  }, [showModal, text]);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    onClose ? onClose() : setOpen(false);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    setNote(e.target.value);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{`${action} note`}</DialogTitle>

      <DialogContent>
        <DialogContentText component={"div"}>
          <Typography variant={"subtitle2"} sx={captionSx}>
            {action} a note for {name}
          </Typography>

          <TextField
            sx={noteSx}
            rows={4}
            multiline={true}
            label="Note"
            value={note}
            onChange={handleNoteChange}
          />
        </DialogContentText>
      </DialogContent>

      <Divider variant="middle" sx={{ border: "1px solid #DDE3EB", mb: 2 }} />

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          sx={btnSx}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color={"primary"}
          sx={btnSx}
          onClick={(e) => {
            e.stopPropagation();
            if (action === "Add") {
              dispatch(adminActions.addNote({ note: note }, id));
            } else {
              dispatch(adminActions.updateNote({ note: note }, item_id, id));
            }
            handleClose(e);
          }}
        >
          {action}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNoteModal;
