import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";
import Menu from "./Menu";
import logo from "../../assets/img/logo.png";
import { Link } from "react-router-dom";

const drawerWidth = 240;
const DrawerLogo = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  marginTop: 20,
  marginBottom: 60,
  marginLeft: 8,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function PermanentDrawerLeft() {
  return (
    <Box sx={{ display: "flex", "@media print": { display: "none" } }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "primary.main",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <DrawerLogo>
          <Link to="/admin/customer">
            <img src={logo} style={{ height: 77 }} alt="logo" />
          </Link>
        </DrawerLogo>
        <Menu />
      </Drawer>
    </Box>
  );
}
