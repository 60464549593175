import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Props } from "./AddMonthlyPoints";
import { FormikProps } from "formik/dist/types";
import { Fields } from "./schema";
import { FormikHelpers } from "formik";
import { adminActions, IndividualStudentsCheck } from "../../../../../state";
import { toInt } from "../../../../../lib";

export function useAddMonthlyPoints({ modal, onClose }: Props) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(!!modal);

  const innerRef = useRef<FormikProps<Fields>>(null);

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    onClose ? onClose(null) : setOpen(false);
  };

  const handleSubmit = async (
    {
      studentId,
      review_count,
      attendance_count,
      gathering,
      check_in,
      monthly_note,
      call_date,
    }: Fields,
    { setSubmitting }: FormikHelpers<Fields>,
  ) => {
    const check: IndividualStudentsCheck = {
      month_id: modal?.month_id!,
      review_count: toInt(review_count),
      attendance_count: toInt(attendance_count),
      gathering: gathering === "unset" ? null : gathering,
      check_in: check_in === "unset" ? null : check_in,
      monthly_note,
      call_date: call_date ? call_date.replaceAll("/", "-") : call_date,
    };

    const result = await dispatch<
      boolean | ReturnType<typeof adminActions.createIndividualStudentsCheck>
    >(adminActions.createIndividualStudentsCheck(studentId, check));

    setSubmitting(false);

    if (result) {
      const data: typeof modal = {
        ...modal!,
        ...check,
      };
      onClose?.(data);
    }
  };

  useEffect(() => {
    !modal && innerRef?.current?.resetForm();

    if (modal) {
      innerRef.current?.setValues({
        ...innerRef?.current?.initialValues!,
        studentId: modal.studentId,
        review_count: modal.review_count?.toString() ?? "",
        attendance_count: modal.attendance_count?.toString() ?? "",
        gathering: modal.gathering,
        check_in: modal.check_in,
        monthly_note: modal.monthly_note,
        max_review_count: modal.max_review_count,
        max_check_in: modal.max_check_in,
        call_date: modal.call_date?.replaceAll("-", "/"),
      });
    }

    setOpen(!!modal);
  }, [modal]);

  return {
    innerRef,
    open,
    handleClose,
    handleSubmit,
  };
}
