import {
  Box,
  IconButton,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
import AdminPages from "../index";
import { formatMoney } from "../../../lib";
import Select from "../../../components/Select/Select";
import { getYiddishDate, yiddishDays } from "../../../lib";
import CheckBox from "../../../components/CheckBox";
import { DateTimePicker } from "@mui/x-date-pickers";
import { isValid } from "date-fns";
import { InfoIcon, LaunchIcon } from "../../../components";
import { onboardingOptions } from "../../../constants";
import { AddCreditField } from "../components/AddCreditField";
import { MonthsAutocomplete } from "../components/MonthsAutocomplete";
import { AddDiscountField } from "../components/AddDiscountField";
import { useSelector } from "react-redux";
import { adminSelectors } from "../../../state";
import { InfoOutlined } from "@mui/icons-material";

export function ReactivateStudentSteps({
  formikGeneral,
  enrollmentHooks,
  formikBilling,
  onboardingFormik,
  setYiddishDate,
  yiddishDate,
  toggleStepDone,
  customersTotalDue,
  remainingCredit,
  studentsTotalDue,
  chaburahPrice,
  cardData,
  credit,
  upcomingBill,
  studentsUpcomingFees,
  studentsBillingInfo,
  setPaymentMethodOpen,
  setShouldSubmitDiscount,
  discount,
  discountReason,
  discountType,
  multi,
  paid,
  unpaid,
  student,
}) {
  const customerId = formikGeneral.values.customer_id;
  const currentMonth = useSelector(adminSelectors.currentMonthId) || 0;
  const isBeingAddedToCurrentMonth =
    +enrollmentHooks.values.month_id === currentMonth;
  return [
    {
      index: 0,
      name: "Enroll the student in a chaburah",
      setTouched: () => {
        enrollmentHooks.setTouched({
          ...enrollmentHooks.touched,
          month_id: true,
          chaburah_id: true,
          teacher_id: true,
        });
      },
      component: (
        <Box>
          <Box display="flex" alignItems="start">
            <Box display="inline-block" width="45%" marginRight={2}>
              <MonthsAutocomplete
                value={enrollmentHooks.values.month_id}
                onChange={enrollmentHooks.handleSelectMonth}
                startMonthId={+currentMonth - 3}
                error={
                  !!enrollmentHooks.touched?.month_id &&
                  !!enrollmentHooks.errors?.month_id
                }
              />
            </Box>
            <Box display="inline-block" width="50%">
              <Select
                id="chaburah_id"
                name="chaburah_id"
                sx={{
                  "& .MuiInputLabel-root": {
                    top: -4,
                  },
                  "& .MuiOutlinedInput-input": {
                    paddingY: 1.5,
                  },
                }}
                fullWidth
                label={"חבורה"}
                placeholder={"חבורה"}
                options={enrollmentHooks.chaburahs}
                value={enrollmentHooks.values.chaburah_id}
                onChange={enrollmentHooks.handleChange}
                onBlur={enrollmentHooks.handleBlur}
                error={
                  enrollmentHooks.touched.chaburah_id &&
                  !!enrollmentHooks.errors.chaburah_id
                }
                helperText={
                  enrollmentHooks.touched.chaburah_id &&
                  enrollmentHooks.errors.chaburah_id
                }
              />
            </Box>
          </Box>
          <Box>
            <Box width="45%">
              <Select
                id="teacher_id"
                name="teacher_id"
                sx={{
                  "& .MuiInputLabel-root": {
                    top: -4,
                  },
                  "& .MuiOutlinedInput-input": {
                    paddingY: 1.5,
                  },
                }}
                label="ראש קנין"
                placeholder={"ראש קנין"}
                options={enrollmentHooks.teachers}
                value={enrollmentHooks.values.teacher_id}
                onChange={enrollmentHooks.handleChange}
                onBlur={enrollmentHooks.handleBlur}
                error={
                  enrollmentHooks.touched.teacher_id &&
                  !!enrollmentHooks.errors.teacher_id
                }
                helperText={
                  enrollmentHooks.touched.teacher_id &&
                  enrollmentHooks.errors.teacher_id
                }
              />
            </Box>
            <Box></Box>
          </Box>
        </Box>
      ),
    },
    {
      index: 1,
      name: "Update onboarding status",
      component: (
        <Box>
          {" "}
          <div
            style={{
              display: "flex",
              minWidth: "500px",
              maxWidth: "100%",
            }}
          >
            <div style={{ width: "50%", marginRight: "20px" }}>
              <Select
                options={onboardingOptions}
                label="Onboarding status"
                value={onboardingFormik.values.onboarding}
                onChange={(e) => {
                  onboardingFormik.setFieldValue("onboarding", e.target.value);
                }}
              />
            </div>
            <div>
              <DateTimePicker
                componentsProps={{
                  actionBar: {
                    actions: ["clear", "accept", "cancel"],
                  },
                }}
                inputFormat="MM/dd/yyyy hh:mma"
                label="Schedule date"
                value={onboardingFormik.values.meet_time}
                minDate={new Date()}
                onChange={(date: any) => {
                  if (date && isValid(date)) {
                    onboardingFormik.setFieldValue(
                      "meet_time",
                      date.toISOString(),
                    );
                    const { weekday, parsha, time } = getYiddishDate(date);
                    setYiddishDate(
                      `${time} ${parsha} - ${yiddishDays[weekday]}`,
                    );
                  } else {
                    onboardingFormik.setFieldValue("meet_time", null);
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <CheckBox
                label="1st Onboarding done"
                checked={!!onboardingFormik.values.onboarded}
                onChange={() => {
                  onboardingFormik.setFieldValue(
                    "onboarded",
                    !onboardingFormik.values.onboarded,
                  );
                }}
              />
            </div>
            <div>
              <Typography>{yiddishDate}</Typography>
            </div>
          </div>
        </Box>
      ),
    },
    {
      index: 2,
      name: "First Rosh Kinyan phone call appointment",
      optional: true,
      component: (
        <Box>
          {" "}
          <Box>
            <CheckBox
              onChange={(e, checked) => {
                enrollmentHooks.setFieldValue(
                  "set_call_date",
                  !enrollmentHooks.values.set_call_date,
                );
                toggleStepDone(2, checked);
              }}
              label="Create Rosh Kinyan appointment for the current month"
            />
          </Box>
        </Box>
      ),
    },
    {
      index: 3,
      name: "Customer information",
      setTouched: () => {
        enrollmentHooks.setTouched({
          ...enrollmentHooks.touched,
          reviewed: true,
        });
      },
      component: (
        <Box>
          <Box>
            {" "}
            <Button
              onClick={() => {}}
              href={AdminPages.editCustomer.path.replace(
                ":id",
                formikGeneral.values.customer_id,
              )}
              target="_blank"
              rel="noopener"
            >
              View customer profile <LaunchIcon sx={{ ml: 1 }} />
            </Button>
          </Box>
          <Box height="100%">
            <CheckBox
              size="medium"
              error={!!enrollmentHooks.touched?.reviewed}
              label="I have reviewed the customer profile and confirm that all customer information is up to date."
              onChange={(e, checked) => {
                toggleStepDone(3, checked);
                enrollmentHooks.setTouched({
                  ...enrollmentHooks.touched,
                  reviewed: false,
                });
              }}
            />
          </Box>
        </Box>
      ),
    },
    {
      index: 4,
      name: "Billing information",
      optional: true,
      component: (
        <Box>
          <Box>
            Please confirm the student’s billing information is correct. If this
            customer was already billed for past months, those invoices will not
            be adjusted. Upon reactivation, the customer will be immediately
            invoiced and charged for the current month.
          </Box>

          <Box display="flex" alignItems="center" marginTop={2}>
            <Box
              color="#5C738E"
              fontWeight="bold"
              fontSize="16px"
              marginRight={2}
            >
              Payment method
            </Box>
            <Button onClick={() => setPaymentMethodOpen(true)}>
              Manage payment method <LaunchIcon />
            </Button>
          </Box>
          <Box>
            <Grid container direction="row" gap={6}>
              <Grid item>
                <Typography component={"span"}>
                  {studentsBillingInfo?.[0]?.default_payment_method === "credit"
                    ? "Credit Card"
                    : studentsBillingInfo?.[0]?.default_payment_method ===
                      "cash"
                    ? "Cash"
                    : "Please set payment method"}
                </Typography>
              </Grid>

              <Grid item>
                <Typography component={"span"}>
                  {cardData?.[0]?.cardType ? cardData[0].cardType : ""}
                </Typography>
              </Grid>

              <Grid item sx={{ mr: 2 }}>
                <Typography component={"span"}>
                  {cardData?.[0]?.maskedNumber ? cardData[0].maskedNumber : ""}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider flexItem sx={{ border: "1px solid #DDE3EB", mt: 2 }} />
          <Box maxWidth={300}>
            <Box
              paddingTop={2}
              color="#5C738E"
              fontWeight="bold"
              fontSize="16px"
              marginRight={2}
            >
              Monthly Chaburah price
            </Box>
            <Box display="flex" justifyContent="space-between" mt={1}>
              <Box color="#082B65">Monthly Chaburah price</Box>
              <Box>
                {chaburahPrice
                  ? formatMoney(chaburahPrice, upcomingBill?.currency)
                  : ""}
              </Box>
            </Box>
            {!!multi && (
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Box color="#082B65">Multi student discount</Box>
                <Box>-{formatMoney(multi, upcomingBill?.currency)}</Box>
              </Box>
            )}
            {!!discount && (
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Box color="#082B65">{discountReason}</Box>
                <Box>
                  -
                  {discountType === "percentage"
                    ? `${discount}%`
                    : formatMoney(discount, upcomingBill?.currency)}
                </Box>
              </Box>
            )}
            {paid && (
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Box color="#082B65">Previously paid</Box>
                <Box>Yes</Box>
              </Box>
            )}{" "}
            {unpaid && (
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Box color="#082B65">Previously unpaid</Box>
                <Box>{formatMoney(unpaid, upcomingBill?.currency)}</Box>
              </Box>
            )}
            {!!credit && (
              <Box
                display="flex"
                justifyContent="space-between"
                mt={1}
                color="#128E56"
              >
                <Box>Customer credit</Box>
                <Box>-{formatMoney(credit, upcomingBill?.currency)}</Box>
              </Box>
            )}
            {!!remainingCredit && (
              <Box color="#8D98C2">
                {formatMoney(remainingCredit, upcomingBill?.currency)} credit
                remaining
              </Box>
            )}
            <Divider flexItem sx={{ border: "1px solid #DDE3EB", my: 2 }} />
            <AddDiscountField
              setFieldValue={formikBilling.setFieldValue}
              values={formikBilling.values}
              firstPossibleEndMonth={+enrollmentHooks.values.month_id + 1}
              disabled={!!studentsUpcomingFees?.discount}
              onChange={(e) => {
                if (e.target.value) {
                  setShouldSubmitDiscount(true);
                }
              }}
            />
            <AddCreditField setFieldValue={formikBilling.setFieldValue} />
            <Divider flexItem sx={{ border: "1px solid #DDE3EB", mt: 2 }} />
            <Box
              display="flex"
              justifyContent="space-between"
              mt={1}
              fontWeight="bold"
            >
              <Box>Total due</Box>
              <Box>
                {studentsTotalDue || studentsTotalDue === 0
                  ? formatMoney(studentsTotalDue, upcomingBill?.currency)
                  : ""}
              </Box>
            </Box>{" "}
          </Box>
          {!!isBeingAddedToCurrentMonth &&
            !!(formikBilling.values.credit_add || credit) && (
              <Box display="flex" padding={1} bgcolor="#FEE9D0" mt={2}>
                <Box>
                  <InfoOutlined sx={{ color: "#F0921F", fontSize: "15px" }} />
                </Box>
                <Box fontSize={14} ml={2}>
                  Note: Credit will not be automatically applied to the invoice.
                  After saving the student, be sure to manually apply the credit
                  to the invoice from the customer billing list.
                </Box>
              </Box>
            )}
          <Divider flexItem sx={{ border: "1px solid #DDE3EB", mt: 2 }} />
          <Box paddingTop={2} color="#5C738E" fontWeight="bold" fontSize="16px">
            Customer monthly charges{" "}
            <IconButton
              sx={(theme) => ({ color: theme.palette.primary.main })}
              href={AdminPages.customer.path.replace(":id", customerId)}
              target="_blank"
              rel="noopener"
            >
              {" "}
              <LaunchIcon />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              Total monthly charges{" "}
              <Tooltip
                title={
                  <Box width={300}>
                    {upcomingBill?.fees?.map((f, i) => {
                      let subtotal = +f.own_price * -1 - (f.multi || 0);
                      let discount = f.discount?.discount;
                      if (
                        !discount &&
                        f.id === student?.id &&
                        formikBilling.values.discount
                      ) {
                        if (
                          formikBilling.values.discount_type === "percentage"
                        ) {
                          discount =
                            subtotal * (formikBilling.values.discount / 100);
                        } else {
                          discount = formikBilling.values.discount;
                        }
                      }
                      subtotal -= discount || 0;
                      customersTotalDue -= discount || 0;
                      return (
                        <Box key={i} padding={1} pr={2}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            pb={0.5}
                          >
                            <Box>{f.first_name_yiddish}</Box>
                            <Box fontWeight="bold">
                              {formatMoney(
                                +f.own_price * -1 -
                                  (f.multi || 0) -
                                  +(discount || 0),
                                upcomingBill.currency,
                              )}
                            </Box>
                          </Box>
                          <Box ml={1}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              pb={0.5}
                            >
                              <Box>Chaburah price</Box>
                              <Box>
                                {formatMoney(
                                  +f.own_price * -1,
                                  upcomingBill.currency,
                                )}
                              </Box>
                            </Box>
                            {!!f.multi && (
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                pb={0.5}
                              >
                                <Box>Multi-student discount</Box>
                                <Box>
                                  -
                                  {formatMoney(+f.multi, upcomingBill.currency)}
                                </Box>
                              </Box>
                            )}
                            {!!discount && (
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                pb={0.5}
                              >
                                <Box>{f.discount?.reason}</Box>
                                <Box>
                                  -
                                  {formatMoney(
                                    +discount,
                                    upcomingBill.currency,
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                }
              >
                <InfoIcon
                  sx={{
                    color: "rgba(92, 115, 142,.5)",
                    fontSize: "18px",
                    ml: 0.5,
                  }}
                />
              </Tooltip>
            </Box>
            <Box>
              {customersTotalDue && (
                <>{formatMoney(customersTotalDue, upcomingBill.currency)}</>
              )}
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      index: 5,
      name: "Terms and conditions",
      setTouched: () => {
        formikBilling.setTouched({
          ...formikBilling.touched,
          terms: true,
        });
      },
      component: (
        <CheckBox
          labelPlacement="start"
          label={
            <Box
              textAlign="justify"
              sx={{ direction: "rtl" }}
              paddingRight={2}
              fontSize={16}
              fontWeight={500}
            >
              איך באשטעטיג אז מיין קארד וועט ווערן געטשארדזט יעדע "פערטן" מאנטאג
              (צווישן כ'-כה לחודש לויט אידישע חדשים), פארן קומעדיגע חודש, ביז
              איך וועל אריינרופן דאס צו אפשטעלן. אויב וויל מען אפשטעלן די קארטל,
              מוז דאס ווערן געמאלדן ביז כ' לחודש, אויב מ'רופט נאך כ' לחודש וועלן
              מיר דאס נישט קענען ריפאנדען.
            </Box>
          }
          size="medium"
          error={
            !!formikBilling.touched?.terms && !!formikBilling.errors?.terms
          }
          onChange={(e, checked) => {
            formikBilling.setFieldValue("terms", !formikBilling.values.terms);
            toggleStepDone(5, checked);
          }}
        />
      ),
    },
  ] as {
    setTouched?: any;
    index: number;
    name: string;
    optional?: boolean;
    component: JSX.Element;
  }[];
}
