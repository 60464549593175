import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import { useFormikContext } from "formik";
import { labelForForms } from "../../lib";

export interface SelectOption {
  id: number | string;
  name: string;
}

interface SelectFieldProps {
  disabled?: boolean;
  label?: string;
  name: string;
  options: SelectOption[];
  textFieldProps?: TextFieldProps;
  formik?;
}

export const FormikSelect = ({
  label = "",
  name,
  options,
  disabled,
  textFieldProps = {},
  formik = undefined,
}: SelectFieldProps) => {
  const _label = label ? label : labelForForms(name);

  const { setFieldValue, values, touched, errors } =
    formik || useFormikContext<any>();
  const handleSelectChange = (event, setFieldValue) => {
    const {
      target: { value },
    } = event;
    setFieldValue([name], value);
  };

  return (
    <TextField
      fullWidth
      select
      value={values[name] ?? ""}
      onChange={(e) => handleSelectChange(e, setFieldValue)}
      label={_label}
      disabled={disabled}
      error={touched[name] && Boolean(errors[name])}
      helperText={touched[name] && errors[name]}
      {...textFieldProps}
    >
      {options.map((o) => (
        <MenuItem key={`select-${o.id}`} value={o.id}>
          {o.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
