import React, { FC } from "react";
import TabContainer from "../../components/TabContainer";
import { useTabMonths } from "./useTabMonths";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  contentStyles,
  dataStyles,
  fieldCaptionStyles,
  monthListStyles,
} from "./TabMonths.styles";
import { adminSelectors, useSelector } from "../../../../state";
import { Form, Formik } from "formik";
import { Fields, initialValues, validationSchema } from "./schema";
import { FormikInput, TodayIcon, WarningIcon } from "../../../../components";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextFieldProps } from "@mui/material/TextField/TextField";

const TabMonths: FC = () => {
  const { innerRef, monthSelector, handleSubmit } = useTabMonths();
  const dateValues = useSelector(adminSelectors.getMonthDates);

  const { charge_date, start_date, end_date } = dateValues || {};
  let startDate;
  let chargeDate;
  if (start_date) {
    startDate = new Date(start_date);
  }
  if (charge_date) {
    chargeDate = new Date(charge_date);
  }
  const updatedInitialValues = {
    ...initialValues,
    end_date,
    charge_date: chargeDate,
    start_date: startDate,
  };

  return (
    <TabContainer>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={contentStyles}
      >
        <Box sx={monthListStyles}>{monthSelector}</Box>

        <Box sx={dataStyles}>
          <Box sx={{ width: "550px" }}>
            {dateValues && (
              <Formik<Fields>
                innerRef={innerRef}
                initialValues={updatedInitialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form>
                      <Stack direction={"column"}>
                        <Typography variant={"h4"} sx={{ mb: 2 }}>
                          Setup{` ${values.currMonth}`}
                        </Typography>

                        <Alert
                          icon={<WarningIcon fontSize="inherit" />}
                          severity="error"
                        >
                          Updating these settings will update settings for all
                          groups. This can’t be undone.
                        </Alert>

                        <Typography
                          variant={"subtitle1"}
                          sx={fieldCaptionStyles}
                        >
                          Chazurah
                        </Typography>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={4}
                        >
                          <Grid item xs={6}>
                            <FormikInput
                              name="max_review_checks"
                              label="Max checks"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormikInput
                              name="review_bonus"
                              label="Bonus points"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Typography
                          variant={"subtitle1"}
                          sx={fieldCaptionStyles}
                        >
                          Attendance
                        </Typography>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={4}
                        >
                          <Grid item xs={6}>
                            <FormikInput
                              name="max_attendance_checks"
                              label="Max checks"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormikInput
                              name="attendance_bonus"
                              label="Bonus points"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Typography
                          variant={"subtitle1"}
                          sx={fieldCaptionStyles}
                        >
                          Day settings
                        </Typography>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={4}
                        >
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                inputFormat="MM/dd/yyyy"
                                renderInput={(params: TextFieldProps) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label={"Charge date"}
                                      variant="outlined"
                                      autoComplete={"off"}
                                      fullWidth={true}
                                    />
                                  );
                                }}
                                components={{
                                  OpenPickerIcon: () => <TodayIcon />,
                                }}
                                onChange={(value) => {
                                  setFieldValue("charge_date", value);
                                }}
                                value={values.charge_date}
                              />
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                inputFormat="MM/dd/yyyy"
                                renderInput={(params: TextFieldProps) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label={"Test date"}
                                      variant="outlined"
                                      autoComplete={"off"}
                                      fullWidth={true}
                                    />
                                  );
                                }}
                                components={{
                                  OpenPickerIcon: () => <TodayIcon />,
                                }}
                                onChange={(value) => {
                                  setFieldValue("test_date", value);
                                }}
                                value={values.test_date}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>

                        <Alert
                          icon={<WarningIcon fontSize="inherit" />}
                          severity="warning"
                        >
                          Updating the start date will change the previous
                          month's end date
                        </Alert>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={4}
                        >
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                inputFormat="MM/dd/yyyy"
                                renderInput={(params: TextFieldProps) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label={"Start date"}
                                      variant="outlined"
                                      autoComplete={"off"}
                                      fullWidth={true}
                                    />
                                  );
                                }}
                                components={{
                                  OpenPickerIcon: () => <TodayIcon />,
                                }}
                                onChange={(value) => {
                                  setFieldValue("start_date", value);
                                }}
                                value={values.start_date}
                              />
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              label={"End date"}
                              variant="outlined"
                              disabled={true}
                              fullWidth={true}
                              value={values.end_date}
                            />
                          </Grid>
                        </Grid>
                      </Stack>

                      <Divider sx={{ my: 1.5 }} />

                      <Button
                        variant="contained"
                        sx={{ mr: 1.5, minWidth: "180px" }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Box>
        </Box>
      </Stack>
    </TabContainer>
  );
};

export default TabMonths;
