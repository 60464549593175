import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Transition } from "./Transition";
import { CreditCardForm } from "../CreditCardForm";
import {
  adminSelectors,
  customerActions,
  customerSelectors,
  SaveCard,
  uiActions,
  uiSelectors,
} from "../../../../state";
import { FormikProps } from "formik/dist/types";

interface Fields {
  expiration: string;
  card_token?: string;
  cvv_token?: string;
}

const initialValues: Fields = {
  expiration: "",
  card_token: undefined,
  cvv_token: undefined,
};

const validateSchema = Yup.object().shape({
  expiration: Yup.string().required("Required"),
});

interface Props {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void;
  name?: string;
  submit?;
}

export const AddCardModal: FC<Props> = ({ handleClose, name, submit }) => {
  const dispatch = useDispatch();
  const FormRef: any = useRef(null);
  const hasScript = useRef(false);
  const innerRef = useRef<FormikProps<Fields>>(null);
  const customerName = name || useSelector(customerSelectors.customerName);
  const customer = useSelector(customerSelectors.customer);
  const student = useSelector(adminSelectors.student);
  const loading = useSelector(uiSelectors.loading);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const script = document.getElementById("cardknox_script");
    if (!script && !hasScript.current) {
      const tag = document.createElement("script");
      tag.src =
        "https://cdn.cardknox.com/ifields/2.13.2204.1401/ifields.min.js";
      tag.id = "cardknox_script";
      document.head.appendChild(tag);
      hasScript.current = true;
    }
  }, [hasScript]);

  const handleSubmit = async (values: any) => {
    setDisabled(true);
    let custId;
    if (!submit) {
      if (!customer.id) {
        if (!student?.customer_id) {
          alert("customer missing id");
          setDisabled(false);
          return;
        } else custId = student?.customer_id;
      } else custId = customer.id;
    }
    try {
      const validationError = FormRef.current.validateForm();

      if (validationError) {
        dispatch(uiActions.showError(validationError));
      } else {
        const { ccToken, cvvToken } = await FormRef.current.getCKTokens();

        (values.card_token = ccToken), (values.cvv_token = cvvToken);

        const data: SaveCard = {
          form: {
            card_token: ccToken,
            cvv_token: cvvToken,
            expiration: values.expiration,
            default: true,
          },
        };
        if (submit) {
          submit(data);
          setDisabled(false);
          return;
        }

        const res = await dispatch(
          customerActions.saveCustomerCard(custId, data),
        );

        !res && handleCloseForm();
      }
    } catch (error) {
      dispatch(
        uiActions.showError((error as Error)?.message ?? "Unknown error"),
      );
    }
    setDisabled(false);
  };

  const handleCloseForm = () => {
    FormRef.current.clearTokens();
    innerRef?.current?.resetForm();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseForm}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add a card"}</DialogTitle>
        <Formik<Fields>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validateSchema}
          innerRef={innerRef}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <DialogContent>
                  <DialogContentText>
                    Add a card for {customerName}
                  </DialogContentText>
                  <CreditCardForm FormRef={FormRef} />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCloseForm}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    disabled={disabled || loading}
                  >
                    Add card
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};
