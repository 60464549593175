import { CSSProperties } from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const topSectionStyle: CSSProperties = {
  marginTop: 0,
  paddingTop: 24,
  height: "200px",
};

export const bottomSectionStyle: CSSProperties = {
  marginTop: "-140px",
  paddingTop: 0,
};

export const customerSx: SxProps<Theme> = {
  mt: 3,
  mb: 2,
  width: "600px",
  backgroundColor: "primary.contrastText",
};

export const container: SxProps<Theme> = {
  width: "600px",
  pb: "22px",
  borderBottom: "1px solid #D5D8EC",
};

export const amountStyle: SxProps<Theme> = {
  width: "100%",
  border: "1px solid #DDE3EB",
  borderRadius: 1,
  backgroundColor: "primary.contrastText",
  p: "16px 24px",

  "& > .MuiTypography-root.caption": {
    color: "#B4B8C8",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
  },

  "& > .MuiFormControl-root": {
    mb: 0,

    "& .MuiInputBase-root": {
      "& > .MuiInputAdornment-root": {
        "& .MuiSvgIcon-root": {
          height: "32px",
          width: "32px",
          fill: "#222C64",
        },
      },

      "& > .MuiInputBase-input": {
        color: "#222C64",
        fontSize: "32px",
        letterSpacing: "2.43px",
        lineHeight: "38px",
        textAlign: "right",
        py: 0,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },

      "&.Mui-error": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "error.main",
        },
      },
    },
  },
};

export const gridItem: SxProps<Theme> = {
  width: "calc(50% - 20px)",
};

export const btnStyle: SxProps<Theme> = {
  "& .MuiButtonGroup-grouped": {
    height: "48px",
    minWidth: "140px",
  },
};

export const textField: SxProps<Theme> = {
  mt: "16px!important",
  mb: "12px!important",

  "& .MuiInputLabel-root": {
    top: -4,
  },

  "& .MuiInputBase-input": {
    py: "12.5px",
  },

  "& textarea.MuiInputBase-input": {
    py: 0,
  },
};

export const formControl: SxProps<Theme> = {
  "& .MuiFormControl-root": {
    my: 0,
  },
};

export const gridPayoffSx: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    "& > .MuiDataGrid-columnHeaders": {
      "& .MuiDataGrid-columnHeader": {
        "& .MuiCheckbox-root": {
          "& svg.MuiSvgIcon-root": {
            fontSize: "18px",
          },
        },
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      "& > .MuiDataGrid-virtualScrollerContent": {
        "& > .MuiDataGrid-virtualScrollerRenderZone": {
          "& > .MuiDataGrid-row": {
            "&.Mui-selected": {
              backgroundColor: "unset",
            },

            "&:not(.Mui-selected)": {
              "& .MuiDataGrid-cell:not(.MuiDataGrid-cellCheckbox)": {
                opacity: "0.5",
              },
            },

            "& .MuiDataGrid-cellCheckbox": {
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            },
          },
        },
      },
    },
  },
};

export const savedCards = (hasCards: boolean): SxProps<Theme> => {
  return {
    minHeight: hasCards ? "unset" : "152px",
    mb: hasCards ? 0 : "25px",
    borderTop: "1px solid #D5D8EC",
    borderBottom: "1px solid #D5D8EC",

    "& > .MuiTypography-root": {
      color: "rgba(0,0,0,0.78)",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "0.17px",
      lineHeight: "22px",
      textAlign: "center",
    },
  };
};

export const gridCardsSx: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    border: "unset",

    "& > .MuiDataGrid-columnHeaders": {
      display: "none",
    },
    "& .MuiDataGrid-virtualScroller": {
      marginTop: "0!important",
    },
  },
};

export const discountTextStyle: CSSProperties = {
  color: "#5C738E",
  fontSize: "13px",
  letterSpacing: "0",
  lineHeight: "14px",
};

export const gridPrepayPrepaymentSx: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    border: "unset",
    "& > .MuiDataGrid-columnHeaders": {
      "& .MuiDataGrid-columnHeader": {
        // could not hide the field in another way
        '&[data-field="__detail_panel_toggle__"]': {
          display: "none",
        },

        "& .MuiCheckbox-root": {
          "& svg.MuiSvgIcon-root": {
            fontSize: "18px",
          },
        },
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      "& > .MuiDataGrid-virtualScrollerContent": {
        "& > .MuiDataGrid-virtualScrollerRenderZone": {
          "& > .MuiDataGrid-row": {
            "& .MuiDataGrid-cell": {
              // could not hide the field in another way
              '&[data-field="__detail_panel_toggle__"]': {
                display: "none",
              },
            },

            "&.Mui-selected": {
              backgroundColor: "unset",
            },

            "&:not(.Mui-selected)": {
              "& .MuiDataGrid-cell:not(.MuiDataGrid-cellCheckbox)": {
                opacity: "1",
              },
            },

            "& .MuiDataGrid-cellCheckbox": {
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            },
          },
        },
      },
    },
  },

  "& .MuiDataGrid-footerContainer": {
    border: "unset",
    minHeight: "8px",
  },
};

export const gridPrepaymentSx: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    "& > .MuiDataGrid-columnHeaders": {
      "& .MuiDataGrid-columnHeader": {
        // could not hide the field in another way
        '&[data-field="__detail_panel_toggle__"]': {
          display: "none",
        },

        "& .MuiCheckbox-root": {
          "& svg.MuiSvgIcon-root": {
            fontSize: "18px",
          },
        },
      },
    },

    "& .MuiDataGrid-virtualScroller": {
      "& > .MuiDataGrid-virtualScrollerContent": {
        "& > .MuiDataGrid-virtualScrollerRenderZone": {
          "& > .MuiDataGrid-row": {
            "& .MuiDataGrid-cell": {
              // could not hide the field in another way
              '&[data-field="__detail_panel_toggle__"]': {
                display: "none",
              },
            },

            "&.Mui-selected": {
              backgroundColor: "unset",
            },

            "&:not(.Mui-selected)": {
              "& .MuiDataGrid-cell:not(.MuiDataGrid-cellCheckbox)": {
                opacity: "0.5",
              },
            },

            "& .MuiDataGrid-cellCheckbox": {
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
              },
            },
          },
        },
      },
    },
  },

  "& .MuiDataGrid-footerContainer": {
    borderTop: "unset",
    minHeight: "8px",
  },
};

export const panelWrapper: SxProps<Theme> = {
  p: "8px 16px 8px 40px",
};
