import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const tabsStyle: SxProps<Theme> = {
  px: 2,

  "& .MuiTabs-flexContainer": {
    "& > .MuiTab-root": {
      color: "#5C738E",
      fontSize: "16px",
      letterSpacing: "0.15px",
      lineHeight: "24px",

      "&.Mui-selected": {
        color: "#082B65",
      },
    },
  },
};
