import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../state";
import { Button, Menu, MenuItem, Fade, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminPages from "..";
import AddCredit from "./Modals/AddCredit";

interface Props {
  id: string;
  studentId?: string;
  onEditClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onDoARefundClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  fromCustomer?: boolean;
}

export const ActionsButton: FC<Props> = ({
  id,
  studentId,
  onEditClick,
  fromCustomer,
  onDoARefundClick,
}) => {
  const navigate = useNavigate();
  const isLimited = useSelector(authSelectors.isLimited);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditMenuClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    if (studentId) {
      navigate(`/admin/student/edit-student/${studentId}`);
    } else navigate(`/admin/customer/edit-customer/${id}`);
  };
  const [show, setShow] = useState<boolean>(false);

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        <MoreHorizIcon sx={{ mr: 1, ml: -0.5 }} /> Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {!isLimited && (
          <MenuItem
            onClick={() =>
              fromCustomer
                ? navigate(
                    AdminPages.addNewStudentFromCustomer.path.replace(
                      ":id",
                      id,
                    ),
                  )
                : navigate(
                    AdminPages.addNewStudent.path.replace(
                      ":id",
                      studentId ? studentId : id,
                    ),
                  )
            }
          >
            <AddIcon sx={{ marginRight: 2 }} color="primary" />
            <Typography variant="h6">ADD A STUDENT</Typography>
          </MenuItem>
        )}
        {!isLimited && (
          <MenuItem
            onClick={() => navigate(`/admin/customer/add-payment/${id}`)}
          >
            <AddIcon sx={{ marginRight: 2 }} color="primary" />
            <Typography variant="h6">ADD A PAYMENT</Typography>
          </MenuItem>
        )}
        {!isLimited && (
          <MenuItem onClick={() => setShow(true)}>
            <AddIcon sx={{ marginRight: 2 }} color="primary" />
            <Typography variant="h6">ADD A CREDIT</Typography>
          </MenuItem>
        )}
        {!isLimited && (
          <MenuItem
            onClick={(e: React.MouseEvent<HTMLLIElement>) => {
              handleClose();
              onDoARefundClick?.(e);
            }}
          >
            <AddIcon sx={{ marginRight: 2 }} color="primary" />
            <Typography variant="h6">DO A REFUND</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={onEditClick ? onEditClick : handleEditMenuClick}>
          <EditIcon sx={{ marginRight: 2 }} color="primary" />
          <Typography variant="h6">EDIT</Typography>
        </MenuItem>
      </Menu>

      {show && (
        <AddCredit showModal={show} onClose={() => setShow(false)} id={id} />
      )}
    </div>
  );
};
