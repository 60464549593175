import React, { FC } from "react";
import { Box } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { container } from "./TabContainer.styles";
import { BoxProps } from "@mui/material/Box/Box";

const TabContainer: FC<BoxProps> = ({ sx, children }) => {
  return (
    <Box sx={[container as SystemStyleObject, sx as SystemStyleObject]}>
      {children}
    </Box>
  );
};

export default TabContainer;
