import React, { FC, useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  detailsLeftSide,
  detailsNotesCaption,
  detailsRightSide,
} from "./TabDetails.styles";
import Section from "../Section";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  listsSelectors,
  StudentDetails,
} from "../../../../state";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { STUDENT_LOGS_COLUMNS } from "../../../../constants";
import { OpenInNewIcon } from "../../../../components";
import Note from "./Note";
import TabContainer from "../../components/TabContainer";
import { caption, container } from "../Section/Section.styles";
import CheckBox from "../../../../components/CheckBox";
import OnboardingModal from "../../components/Modals/OnboardingModal";
import { dataGridFilterStyles } from "../../../../themes";

const TabDetails: FC = () => {
  const student = useSelector(adminSelectors.student);

  const [data, setData] = useState<StudentDetails | null>(null);

  const neighborhoodPrincipal = useSelector(
    listsSelectors.neighborhoodPrincipalById,
  );

  const users = useSelector(listsSelectors.userById);

  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (student) {
      const nsd = { ...student };

      nsd.student_status_logs = nsd.student_status_logs.map((l) => {
        return {
          ...l,
          __list: {
            users,
          },
        };
      });

      setData(nsd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  if (!data) {
    return null;
  }

  const {
    grade_yiddish,
    student_months,
    school_building,
    bar_mitzva,
    student_siyums,
    student_status_logs,
    student_notes,
    customer: { address },
    id,
    first_name,
    last_name,
    first_name_yiddish,
    last_name_yiddish,
    onboarded,
    do_not_call,
    away,
  } = data!;

  const rosh_kinyan = student_months[0]?.v_rosh_kinyan;

  const siyums = student_siyums?.[0];

  const principal =
    neighborhoodPrincipal[address?.neighborhood_id]?.name_yiddish ?? "";
  const updateStudent = async (update) => {
    if (id) {
      const success = (await dispatch(
        adminActions.updateStudent(id.toString(), { ...data, ...update }),
      )) as unknown as boolean;
      if (success) {
        setData({ ...data, ...update });
      }
    }
  };

  return (
    <TabContainer>
      <Stack direction={"row"} gap={2}>
        <Box sx={detailsLeftSide}>
          <Box display={"flex"} justifyContent="space-between">
            <Section name={"Teachers"}>
              <Stack direction={"row"} gap={6}>
                <Box>
                  <Typography variant={"subtitle2"}>ראש קנין</Typography>
                  <Typography variant={"body2"}>{`${
                    rosh_kinyan?.first_name_yiddish ?? ""
                  } ${rosh_kinyan?.last_name_yiddish ?? ""}`}</Typography>
                </Box>

                <Box>
                  <Typography variant={"subtitle2"}>מנהל</Typography>
                  <Typography variant={"body2"}>{`${
                    principal ?? ""
                  }`}</Typography>
                </Box>
              </Stack>
            </Section>
            <Box marginRight="50px" sx={container}>
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => {
                  setShowOnboardingModal(true);
                }}
              >
                <Typography
                  variant={"body2"}
                  sx={caption}
                  alignItems="baseline"
                >
                  Onboarding
                  <OpenInNewIcon
                    sx={{ marginLeft: 1, position: "relative", top: "5px" }}
                    color="primary"
                  />
                </Typography>
              </Button>

              <Box marginLeft="8px">
                <CheckBox label="1st Onboarding done" checked={!!onboarded} />
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent="space-between">
            <Section name={"School"}>
              <Stack direction={"row"} gap={6}>
                <Box>
                  <Typography variant={"subtitle2"}>חדר</Typography>
                  <Typography variant={"body2"}>
                    {school_building?.school?.name ?? ""}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant={"subtitle2"}>בנין</Typography>
                  <Typography variant={"body2"}>
                    {school_building?.name ?? ""}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant={"subtitle2"}>כתה</Typography>
                  <Typography variant={"body2"}>
                    {`${grade_yiddish ?? ""}`}
                  </Typography>
                </Box>
              </Stack>
            </Section>
            <Box marginRight="50px" sx={container}>
              <Box>
                <Typography
                  variant={"body2"}
                  sx={caption}
                  alignItems="baseline"
                >
                  Settings
                </Typography>
              </Box>
              <Box>
                {" "}
                <Box marginLeft="8px">
                  <CheckBox
                    onClick={() => {
                      updateStudent({ do_not_call: !do_not_call });
                    }}
                    label="Do not call"
                    checked={!!do_not_call}
                  />
                </Box>
                <Box marginLeft="8px">
                  <CheckBox
                    onClick={() => {
                      updateStudent({ away: !away });
                    }}
                    label="Do not call summer"
                    checked={!!away}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Section name={"Additional"}>
            <Stack direction={"row"} gap={6}>
              <Box>
                <Typography variant={"subtitle2"}>בר מצוה</Typography>
                <Typography variant={"body2"}>
                  {" "}
                  {`${bar_mitzva ?? ""}`}
                </Typography>
              </Box>

              <Box>
                <Typography variant={"subtitle2"}>
                  Siyum registration
                </Typography>
                <Typography variant={"body2"}>
                  {siyums?.registration ? "Yes" : "No"}
                </Typography>
              </Box>

              <Box>
                <Typography variant={"subtitle2"}>Transportation</Typography>
                <Typography variant={"body2"}>
                  {siyums?.transportation ? "Yes" : "No"}
                </Typography>
              </Box>
            </Stack>
          </Section>

          <Section name={"Enrollment details"}>
            <Box>
              <DataGridPro
                sx={{
                  "& .MuiDataGrid-main": {
                    border: "unset",
                  },
                }}
                hideFooter={true}
                rows={student_status_logs}
                autoHeight={true}
                columns={STUDENT_LOGS_COLUMNS}
                componentsProps={{
                  toolbar: { printOptions: { disableToolbarButton: true } },
                  panel: {
                    sx: {
                      ...dataGridFilterStyles,
                    },
                  },
                }}
              />
            </Box>
          </Section>
        </Box>

        <Box sx={detailsRightSide}>
          <Typography variant={"subtitle2"} sx={detailsNotesCaption}>
            Notes
          </Typography>

          {student_notes.map((note) => (
            <Note
              key={note.id}
              note={note}
              name={`${first_name} ${last_name} - ${first_name_yiddish} ${last_name_yiddish}`}
            />
          ))}
          <OnboardingModal
            showModal={showOnboardingModal}
            onClose={() => setShowOnboardingModal(false)}
            student={data}
            onSubmit={async () => {
              await dispatch(adminActions.getStudent(id));
            }}
          />
        </Box>
      </Stack>
    </TabContainer>
  );
};

export default TabDetails;
