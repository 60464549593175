import { array, boolean, object, string } from "yup";
import {
  Discount,
  DepositType,
  PaymentMethod,
  PaymentMethodItem,
} from "../../../state";

export interface StudentGeneralFields {
  customer_id: string;
  first_name_yiddish: string;
  last_name_yiddish: string;
  first_name: string;
  last_name: string;
  school: string;
  school_building_id: string;
  grade: string;
  year: string; // do not send to BE
  birth_month_id: string;
  birth_month_day: string;
  notes: string;
  status_id: number; // 1- active, 3 - inactive
  registration: boolean;
  transportation: boolean;
  completed: boolean;
  bar_mitzva: string;
  // temp fields
  _customer: unknown; // do not send to BE
  _student_siyums: unknown; // do not send to BE
  _notes: unknown; // do not send to BE
  chaburah_id: string;
  month_id: string;
  _referring_student: unknown;
  referring_student_id: string;
  teacher_id: string;
  year_id: string;
  teacher: string;
  override: boolean;
  shouldNotNavigate?: boolean;
  draft?: boolean;
}

export const initStudentGeneralState: StudentGeneralFields = {
  customer_id: "",
  first_name_yiddish: "",
  last_name_yiddish: "",
  first_name: "",
  last_name: "",
  school: "",
  school_building_id: "",
  grade: "",
  year: "",
  birth_month_id: "",
  birth_month_day: "",
  notes: "",
  status_id: 3,
  registration: false,
  transportation: false,
  completed: false,
  bar_mitzva: "",
  _customer: undefined,
  _student_siyums: undefined,
  _notes: undefined,
  chaburah_id: "",
  month_id: "",
  _referring_student: "",
  teacher_id: "",
  year_id: "",
  teacher: "",
  referring_student_id: "",
  override: false,
};

// TODO: check if all those fields are required
export const validationStudentGeneralSchema = object().shape({
  // general
  customer_id: string().required("Required").label("Customer"),
  first_name_yiddish: string()
    .required("Required")
    .test(
      "first_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("ערשטע נאמען"),
  last_name_yiddish: string()
    .required("Required")
    .test(
      "last_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("לעצטע נאמען"),
  school_building_id: string().when("school", {
    is: (v) => !!v,
    then: string().required("Building is required"),
  }),
});

export interface StudentEnrollmentFields {
  chaburah_id: string;
  teacher_id: string;
  month_id: string; // do not send to BE
  year_id: string;
  referring_student_id: string;
  _referring_student: unknown; // do not send to BE
  customer_id: string;
  first_name: string;
  last_name: string;
  first_name_yiddish: string;
  last_name_yiddish: string;
  school_building_id: string;
  grade: string;
  birth_month_id: string;
  birth_month_day: string;
  notes: string;
  override: boolean;
  set_call_date?: boolean;
  shouldNotNavigate?: boolean;
}

export const initStudentEnrollmentState: StudentEnrollmentFields = {
  chaburah_id: "",
  year_id: "",
  month_id: "",
  teacher_id: "",
  referring_student_id: "",
  _referring_student: undefined,
  customer_id: "",
  first_name: "",
  last_name: "",
  first_name_yiddish: "",
  last_name_yiddish: "",
  school_building_id: "",
  grade: "",
  birth_month_id: "",
  birth_month_day: "",
  notes: "",
  override: false,
};

export const validationStudentEnrollmentSchema = object().shape({
  chaburah_id: string().required("Required").label("Chaburah"),
  month_id: string().required("Required").label("Month"),
});

export interface StudentBillingFields {
  default_payment_method: PaymentMethod;
  payment_method_id: number | null; // TODO: what is that and how to look up it
  amount: string | number;
  deposit_type: DepositType;
  date: Date | null;
  note: string | null;
  checkNumber: string | null;
  paymentMethods: PaymentMethodItem[] | null;
  discounts: Discount[] | null;
  credit_add?: number;
  terms?: boolean;
  discount_type: "amount" | "percentage";
  discount?: number;
}

export const initStudentBillingState: StudentBillingFields = {
  default_payment_method: "credit",
  terms: false,
  payment_method_id: null,
  amount: "",
  deposit_type: "regular",
  date: new Date(),
  note: null,
  checkNumber: null, // TODO: What is that
  paymentMethods: null,
  discounts: null,
  discount_type: "amount",
};

export const validationStudentBillingSchema = object().shape({
  // student_id: string().required("Required").label("Student ID"),
  default_payment_method: string()
    .required("Required")
    .label("Default payment method"),
  payment_method_id: string().nullable(true).label("Payment method id"),
  terms: boolean().required().oneOf([true], "You must accept terms"),
  discounts: array()
    .nullable(true)
    .of(
      object().shape({
        discount: string()
          .nullable()
          .test("discount", "${label} must be a number", (val = "") =>
            val ? /^\d*.?\d*$/.test(val) : true,
          )
          .label("Discount"),
        discount_type: string()
          .oneOf(["amount", "percentage"])
          .when("discount", {
            is: (discount) => !!discount,
            then: string().required("Discount type required"),
          }),
      }),
    ),
});
