import React, { FC, Dispatch, SetStateAction } from "react";
import { Button, ButtonGroup, SxProps, Theme } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";

export type NeighborhoodFilter = "all" | "masmidim" | "cd";

const btnStyle: SxProps<Theme> = {
  "& .MuiButtonGroup-grouped": {
    minWidth: "106px",
  },
};

interface Props {
  neighborhoodFilter?: NeighborhoodFilter;
  onSelect?: Dispatch<SetStateAction<NeighborhoodFilter>>;
}

const NeighborhoodSelector: FC<Props> = ({ neighborhoodFilter, onSelect }) => {
  const handleClick = (
    filter: NeighborhoodFilter,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    onSelect && onSelect(filter);
  };

  const getVariant = (isTrue: boolean): ButtonProps["variant"] =>
    isTrue ? "contained" : "outlined";

  return (
    <ButtonGroup sx={btnStyle}>
      <Button
        variant={getVariant(neighborhoodFilter === "all")}
        onClick={handleClick.bind(null, "all")}
      >
        All
      </Button>
      <Button
        variant={getVariant(neighborhoodFilter === "masmidim")}
        onClick={handleClick.bind(null, "masmidim")}
      >
        Masmidim
      </Button>
      <Button
        variant={getVariant(neighborhoodFilter === "cd")}
        onClick={handleClick.bind(null, "cd")}
      >
        CD
      </Button>
    </ButtonGroup>
  );
};

export default NeighborhoodSelector;
