import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { framedCaption } from "../GroupInfo.styles";
import { valueStyles } from "../InfoItem/InfoItem.styles";
import { SystemStyleObject } from "@mui/system";

interface Props {
  teacher: string;
  neighborhood: string;
}

const TeacherRow: FC<Props> = ({ teacher, neighborhood }) => {
  return (
    <Box>
      <Typography sx={framedCaption}>{teacher}</Typography>

      <Typography
        sx={[
          valueStyles as SystemStyleObject,
          { display: "inline-block", ml: 4 } as SystemStyleObject,
        ]}
      >
        {neighborhood}
      </Typography>
    </Box>
  );
};

export default TeacherRow;
