import { useSelector } from "react-redux";
import { gematriya } from "../../lib";
import { listsSelectors } from "../../state";

let months;

export const ChodeshYear = ({ month_id }) => {
  const _months = useSelector(listsSelectors.monthById);
  months = _months;
  if (!month_id) {
    return null;
  }
  const chodesh = _months[month_id].hebrew;
  const year = _months[month_id].year_id;

  return <>{`${chodesh} ${gematriya(year).slice(2)}`}</>;
};

export const ChodeshYearFormatted = ({ props }) => {
  //This is used in the export. By that time the months should be populated
  if (!months || !props) return "";
  const month_id = props.month_id;
  const chodesh = months[month_id]?.hebrew;
  const year = months[month_id]?.year_id;
  if (!chodesh || !year) return "";

  return `${chodesh} ${gematriya(year).slice(2)}`;
};
