import { Typography, Button, Stack } from "@mui/material";
import {
  sxCheckIcon,
  sxCheckText,
  sxCheckButton,
} from "../../constants/styles";
import { CheckCircleIcon } from "../icons";

export const ChargeCard = ({ useCard, setUseCard, isDisabled = false }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      width={"100%"}
    >
      {useCard ? (
        <>
          <CheckCircleIcon sx={sxCheckIcon} />
          <Typography sx={sxCheckText}>Charging this card</Typography>
        </>
      ) : (
        <Button
          variant={"text"}
          sx={sxCheckButton}
          onClick={setUseCard}
          disabled={isDisabled}
        >
          Charge this card
        </Button>
      )}
    </Stack>
  );
};
