import React from "react";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
  Button,
  Divider,
  Tooltip,
  Box,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { gematriya, getCurrencySymbol } from "../../../lib";
import { listsSelectors, useSelector } from "../../../state";
export const PaymentCards = ({
  student,
  billingDetails,
  currency,
  cardData,
  index,
  onEditPaymentClick,
}) => {
  const navigate = useNavigate();
  //TODO: eventually support multiple discounts
  const prePayDiscount =
    billingDetails && billingDetails[0]?.discounts?.find((d) => d.is_prepay);
  const nonPrePayDiscount =
    billingDetails && billingDetails[0]?.discounts?.find((d) => !d.is_prepay);
  const _months = useSelector(listsSelectors.months);
  const discountEndMonth = nonPrePayDiscount?.month_end
    ? _months.find((m) => m.id === nonPrePayDiscount.month_end)
    : undefined;

  const discountEndMonthDisplay = discountEndMonth
    ? `${discountEndMonth.hebrew || ""} ${
        gematriya(discountEndMonth.year_id.toString()) || ""
      }`
    : "";

  return (
    <Grid item sx={{ width: "50%" }} key={index}>
      <Card
        sx={{
          height: 325,
          border: "1px solid #DDE3EB",
          borderRadius: "8px",
        }}
      >
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item sx={{ ml: 2 }}>
              <Grid container direction="column" justifyContent="flex-start">
                <Grid item>
                  <Typography component={"span"} variant="h3">
                    {`${student.first_name_yiddish} ${student.last_name_yiddish} `}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    component={"span"}
                    variant="subtitle1"
                  >{`${student.first_name} ${student.last_name} `}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ mt: 1 }}>
              <Typography component={"span"} variant="body1">
                {student.student_months?.[0]?.chaburah.name}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                onClick={() => navigate(`/admin/student/${student.id}`)}
              >
                <OpenInNewIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ m: 2 }}
          >
            <Grid item sx={{ width: "0.33%" }}>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ height: 50, border: "1px solid #DDE3EB" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ ml: 2, mr: 2, minWidth: "45%" }}>
              <Grid container direction="column" justifyContent="space-evenly">
                <Grid item>
                  <Typography
                    component={"span"}
                    variant="caption"
                    fontWeight="bold"
                  >
                    Prepayment
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component={"span"} variant="body1">
                    {prePayDiscount ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ width: "0.33%" }}>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ height: 50, border: "1px solid #DDE3EB" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ ml: 2, mr: 2 }}>
              <Grid container direction="column" justifyContent="flex-start">
                <Grid item>
                  <Typography
                    component={"span"}
                    variant="caption"
                    fontWeight="bold"
                  >
                    Discount
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component={"span"} variant="body1">
                    {nonPrePayDiscount?.discount_type === "percentage" ? (
                      <Tooltip title={nonPrePayDiscount?.reason}>
                        <Box display="flex">
                          <Typography component={"span"} variant="body1">
                            {nonPrePayDiscount?.discount}%
                          </Typography>
                          <AutorenewIcon sx={{ ml: 1, mr: 0.5 }} />
                          {!!nonPrePayDiscount?.month_end && (
                            <Typography>
                              Until {discountEndMonthDisplay}{" "}
                            </Typography>
                          )}
                        </Box>
                      </Tooltip>
                    ) : billingDetails?.[0]?.discounts?.[0]?.discount_type ===
                      "amount" ? (
                      <Tooltip title={nonPrePayDiscount?.reason}>
                        <Box display="flex">
                          <Typography component={"span"} variant="body1">
                            {getCurrencySymbol(currency)}
                            {nonPrePayDiscount?.discount}
                          </Typography>
                          <AutorenewIcon sx={{ ml: 1, mr: 0.5 }} />
                          {!!nonPrePayDiscount?.month_end && (
                            <Typography>
                              Until {discountEndMonthDisplay}{" "}
                            </Typography>
                          )}
                        </Box>
                      </Tooltip>
                    ) : (
                      "None"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            flexItem
            sx={{ border: "1px solid #DDE3EB", mb: 2 }}
          />
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography
                component={"span"}
                variant="h6"
                color="#020F25"
                sx={{ ml: 2 }}
              >
                {billingDetails[0]?.default_payment_method === "credit"
                  ? "Credit Card"
                  : "Cash"}
              </Typography>
            </Grid>

            <Grid item>
              <Typography component={"span"} variant="h6" color="#020F25">
                {cardData?.[0]?.cardType ? cardData[0].cardType : ""}
              </Typography>
            </Grid>

            <Grid item sx={{ mr: 2 }}>
              <Typography component={"span"} variant="h6" color="#020F25">
                {cardData?.[0]?.maskedNumber ? cardData[0].maskedNumber : ""}
              </Typography>
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            flexItem
            sx={{ border: "1px solid #DDE3EB", mt: 2 }}
          />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: 5 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={onEditPaymentClick}
              >
                Edit payment method
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
