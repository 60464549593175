import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { authActions, store } from "./state";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { REACT_APP_MUI_LICENSE } from "./config";
import { defaultTheme } from "./themes";
import { App } from "./App";
import { init } from "./components";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "../src/assets/css/styles.css";

function main() {
  init();
  const dispatch: any = store.dispatch;
  dispatch(authActions.load({}));
  render();
}

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE!);

function render() {
  ReactDOM.render(
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ReduxProvider store={store}>
          <CssBaseline />
          <App />
        </ReduxProvider>
      </LocalizationProvider>
    </ThemeProvider>,
    document.getElementById("root"),
  );
}

/**
 * Service worker registration.
 *
 * Should be registered after a call to `render` **UNLESS you are using
 * `self.clients.claim()` within your service worker.** Otherwise, you want
 * to register late (after a call to `render`) to avoid problems on "low-end
 * mobile devices" (per the docs).
 *
 * 1. [CRA docs on PWA](https://create-react-app.dev/docs/making-a-progressive-web-app)
 * 2. [Reasons to register early](https://developers.google.com/web/fundamentals/primers/service-workers/registration#reasons_to_register_early)
 *
 */

main();
