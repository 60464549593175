import { styled } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";

export const ToggleButtonContainer = styled(ToggleButtonGroup)(({ theme }) => ({
  marginRight: 15,
  width: "100%",
  "& .MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: 4,
  },
  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
    marginBottom: 10,
  },
}));
export const ToggleButtonOption = styled(ToggleButton)(({ theme }) => ({
  toggleOption: {
    padding: 10,
    border: "1px solid #E0E0E0",
    [theme.breakpoints.down("sm")]: {
      minWidth: 70,
      marginBottom: 10,
    },
  },
}));
