export type ButtonType = "back" | "next" | "continue" | "save";

export type TabType = "details" | "roshKinyan" | "curriculum" | "months";

export type PageMode = "edit" | "insert";

export enum ChaburahType {
  Masmidim = 1,
  CD,
}

export type Storage = {
  [key in TabType]?: { [key: string]: unknown };
};
