import { SxProps, Theme } from "@mui/material";

export const rowStyle: SxProps<Theme> = {
  mb: "21px",

  "&:last-of-type": {
    mb: "unset",
  },

  "& > *": {
    maxWidth: "50%",
    width: "50%",
  },

  "& > p": {
    color: "#000000",
  },
};
