import { array, boolean, number, object, string } from "yup";
import { loginRegExp, passwordRegExp } from "../../../lib/regexp";
import { PageMode } from "../../../state";

export interface Fields {
  mode: PageMode;
  first_name_yiddish: string;
  last_name_yiddish: string;
  first_name: string | null;
  last_name: string | null;
  home_phone: string | null;
  home_phone_id: number | null;
  home_country_code_id: number | null;
  mobile_phone: string | null;
  mobile_phone_id: number | null;
  mobile_country_code_id: number | null;
  email: string | null;
  address_id: number | null;
  address: string;
  address_2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country_id: number | null | undefined;
  neighborhood_id: number | null;
  username: string;
  person_id: number;
  password?: string | null;
  role_ids: number[];
  active: boolean | null;
  add_password?: boolean;
}

export const initialValues: Fields = {
  mode: "",
  first_name_yiddish: "",
  last_name_yiddish: "",
  first_name: "",
  last_name: "",
  home_phone: "",
  home_phone_id: null,
  home_country_code_id: 1,
  mobile_phone: null,
  mobile_phone_id: null,
  mobile_country_code_id: null,
  email: null,
  address_id: null,
  address: "",
  address_2: null,
  city: null,
  state: null,
  zip: null,
  country_id: null,
  neighborhood_id: null,
  username: "",
  password: "",
  role_ids: [],
  active: true,
  person_id: 0,
  add_password: false,
};

export const validationSchema = object().shape({
  mode: string()
    .required()
    .oneOf(["insert", "edit"] as Array<PageMode>)
    .label("Mode"),
  first_name_yiddish: string()
    .required("Required")
    .test(
      "first_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("ערשטע נאמען"),
  last_name_yiddish: string()
    .required("Required")
    .test(
      "last_name_yiddish",
      "${label} must contain only Hebrew characters",
      (val = "") => /^[\u0590-\u05FF `’'",.-]*$/.test(val),
    )
    .label("לעצטע נאמען"),
  country_id: number().required("required").typeError("required"),
  home_phone: string().required("required").typeError("required"),
  home_country_code_id: string().required("required").typeError("required"),
  mobile_country_code_id: string().when("mobile_phone", {
    is: (val) => val?.length > 0,
    then: string().required("required").typeError("required"),
    otherwise: string().nullable().notRequired(),
  }),
  neighborhood_id: number().required("required").typeError("required"),
  email: string().nullable().email().label("Email"),
  username: string()
    .required("Required")
    .min(2, "${label} should contain at least ${min} character(s)")
    .max(32, "${label} should contain at most ${max} character(s)")
    .test(
      "username",
      "${label} must contain at least 1 letter. Dot, dash or @ are only allowed",
      (val = "") => loginRegExp.test(val),
    )
    .label("Username"),
  password: string().when("add_password", {
    is: true,
    then: string()
      .required("Required")
      .min(8, "${label} should contain at least ${min} character(s)")
      .max(32, "${label} should contain at most ${max} character(s)")
      .test(
        "password",
        "${label} must contain at least 1 uppercase letter, 1 lowercase letter, digits and a special character",
        (val = "") => passwordRegExp.test(val),
      )
      .label("Password"),
    otherwise: string()
      .test(
        "password",
        "${label} must contain at least 1 uppercase letter, 1 lowercase letter, digits and a special character",
        (val = "") => !val || passwordRegExp.test(val),
      )
      .min(8, "${label} should contain at least ${min} character(s)")
      .max(32, "${label} should contain at most ${max} character(s)")
      .label("Password"),
  }),
  role_ids: array()
    .required("Required")
    .of(number().required())
    .min(1)
    .label("Role(s)"),
  active: boolean()
    .required("Required")
    .test(
      "active",
      "${label} is not selected",
      (val) => typeof val === "boolean",
    )
    .label("Status"),
});
