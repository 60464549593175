import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { labelForForms } from "../../lib";
import { TextField as MuiTextField } from "@mui/material";

export const FormikInput = ({
  label = "",
  name,
  type = "text",
  formik = undefined,
  ...rest
}) => {
  const { touched, errors, handleChange, handleBlur, values } =
    (formik as any) || useFormikContext();

  const _label = label ? label : labelForForms(name);

  return formik ? (
    <MuiTextField
      id="first_name_yiddish"
      name={name}
      label={_label}
      fullWidth={true}
      value={values[name]}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched[name] && !!errors[name]}
      helperText={touched[name] && errors[name]}
      {...rest}
    />
  ) : (
    <Field
      component={TextField}
      fullWidth
      name={name}
      label={_label}
      type={type}
      error={touched[name] && Boolean(errors[name])}
      helperText={touched[name] && errors[name]}
      {...rest}
    />
  );
};
