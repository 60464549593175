import { Circle } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";

export const titleContainer: SxProps<Theme> = {
  backgroundColor: "primary.contrastText",
  p: "10px 24px",

  "& > div": {
    borderRight: "1px solid #DDE3EB",

    "&:last-of-type": {
      borderRight: "unset",
    },
  },
};
export const testContainer: SxProps<Theme> = {
  backgroundColor: "primary.contrastText",
  p: "10px 24px",
  borderBottom: "1px solid #DDE3EB",
  "& > div": {
    borderRight: "1px solid #DDE3EB",

    "&:last-of-type": {
      borderRight: "unset",
    },
  },
};
export const container: SxProps<Theme> = {
  backgroundColor: "primary.contrastText",
  p: "10px 24px",

  "& > div": {
    borderRight: "1px solid #DDE3EB",

    // "&:last-of-type": {
    //   borderRight: "unset",
    // },
  },
};
export const expandButton: SxProps<Theme> = {
  backgroundColor: "primary.contrastText",
  pl: "45%",
};
export const cardContainer: SxProps<Theme> = {
  border: "1px solid #DDE3EB",
  borderRadius: 1,
  backgroundColor: "primary.contrastText",
  mt: 2,
  mb: 3,
  p: "10px 24px",
};
export const notEnrolledContainer: SxProps<Theme> = {
  border: "1px solid #DDE3EB",
  color: "primary.main",
  fontSize: "20px",
  borderRadius: 1,
  backgroundColor: "primary.contrastText",
  mt: 2,
  minHeight: "calc(100vh - 67vh)",
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const dataGridContainer: SxProps<Theme> = {
  border: "1px solid #DDE3EB",
  borderRadius: 1,
  backgroundColor: "primary.contrastText",
  mt: 2,
  mb: 3,
  p: "10px",
  height: "100%",
};

export const pinStyle: SxProps<Theme> = {
  fontSize: "15px",
  color: "rgba(0,0,0,0.87)",
  mb: 2,

  "> span": {
    fontWeight: "bold",
    ml: 1,
  },
};

export const phoneStyle: SxProps<Theme> = {
  color: "rgba(0,0,0,0.87)",
  mb: 0.5,

  "> span": {
    color: "#000000",
    ml: 1,
  },
};

export const iconStyle: SxProps<Theme> = {
  height: "15px",
  width: "15px",
  color: "#97A7C1",
  mt: "3px",
};

export const pointsCaption: SxProps<Theme> = {
  color: "#082B65",
  fontFamily: "Noto Sans",
  fontSize: "16px",
  fontWeight: "600",
  letterSpacing: "0",
  mb: "26px",
};
export const manageLeftSide: SxProps<any> = {
  width: "50%",
  height: "100vh",
};

export const manageRightSide: SxProps<any> = {
  width: "50%",
  height: "100vh",
};

export const CircleIcon = styled(Circle)(() => ({
  marginLeft: 8,
  marginRight: 8,
  height: 8,
  width: 8,
  fill: "#00175C",
}));
