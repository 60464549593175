import React, { FC, useEffect, useState } from "react";
import { TabType } from "../types";
import { Transition } from "../../components/Modals/Transition";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";

export interface Props {
  modal: TabType | boolean | null;
  onClose?: (data: TabType | boolean | null) => void;
  message?: string;
}

const ConfirmModal: FC<Props> = ({ modal, onClose, message = undefined }) => {
  const [open, setOpen] = useState<boolean>(!!modal);

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    onClose ? onClose(null) : setOpen(false);
  };

  useEffect(() => {
    setOpen(!!modal);
  }, [modal]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose?.(null)}
    >
      <DialogTitle>You have unsaved changes</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {message || "Are you sure you want to continue?"}
        </DialogContentText>
      </DialogContent>

      <Divider variant="middle" sx={{ border: "1px solid #DDE3EB", mb: 2 }} />

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          sx={{ minWidth: "140px" }}
          onClick={handleClose}
        >
          No
        </Button>

        <Button
          variant="contained"
          color={"primary"}
          sx={{ minWidth: "140px" }}
          onClick={() => onClose?.(modal)}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
