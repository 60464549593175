import React, { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { container, valueStyles } from "./InfoItem.styles";

interface Props {
  caption: string;
  value: string | number | ReactNode;
}

const InfoItem: FC<Props> = ({ caption, value }) => {
  return (
    <Box sx={container}>
      <Typography variant={"subtitle2"} className={"caption"}>
        {caption}
      </Typography>

      {typeof value == "string" || typeof value === "number" ? (
        <Typography variant={"body2"} sx={valueStyles}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </Box>
  );
};

export default InfoItem;
