import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import {
  WideSearch,
  WideStyledInputBase,
} from "../pages/admin/styles/GridCardsSearch.styles";
import { useCallback } from "react";

export function SelectionToolbar({
  apiRef,
  bulkUpdateValues,
  options,
  selectionModel,
  setValue,
  value,
  placeholder = "Search…",
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((apiRef, v) => {
      apiRef.current.setQuickFilterValues([v]);
    }, 300),
    [],
  );

  return (
    <GridToolbarContainer>
      <Box
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          display: "flex",
        }}
      >
        <Box>
          <WideSearch>
            <WideStyledInputBase
              placeholder={placeholder}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                debouncedSearch(apiRef, e.target.value);
              }}
            />
            <SearchIcon
              sx={{ color: "#B4B8C8", height: 32, paddingTop: 1.5 }}
            />
          </WideSearch>
        </Box>
        <Box>
          <GridToolbarFilterButton />
          <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        </Box>
      </Box>
      {selectionModel.length ? (
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#ebedf3",
            display: "flex",
            borderRadius: 3,
            border: "1px solid #DDE3EB",
            marginTop: "30px",
          }}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ml: 3, color: "#5C738E" }} variant="body2">
              {`${selectionModel.length} selected`}
            </Typography>
          </Box>{" "}
          <Box
            sx={{
              width: "60%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ mr: 3, color: "#5C738E", fontWeight: "bold" }}
              variant="body1"
            >
              Set to
            </Typography>
            <Autocomplete
              value={value}
              onChange={(event: any, newValue) => {
                setValue(newValue);
              }}
              id="controllable-states-demo"
              options={options}
              sx={{ width: 250, mr: 3 }}
              size="small"
              renderInput={(params) => <TextField {...params} label="" />}
            />
            <Button
              sx={{ width: 140, height: 38, mr: 3 }}
              variant={"contained"}
              onClick={() => {
                bulkUpdateValues(value);
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </GridToolbarContainer>
  );
}
