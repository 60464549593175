import React, { FC } from "react";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import SimplePageHeader from "../../../components/SimplePageHeader";
import {
  bottomSectionStyle,
  gridStyles,
  topSectionStyle,
} from "./UsersPage.styles";
import { Button } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { USERS_LIST_COLUMNS } from "../../../constants";
import { useUsersPage } from "./useUsersPage";
import { dataGridFilterStyles } from "../../../themes";

const UsersPage: FC = () => {
  const { users, handleAddUser, handleRowClick } = useUsersPage();

  return (
    <Page>
      <TopSection style={topSectionStyle}>
        <SimplePageHeader
          title={"Users"}
          controls={
            <Button variant={"contained"} onClick={handleAddUser}>
              Add user
            </Button>
          }
        />
      </TopSection>

      <BottomSection style={bottomSectionStyle}>
        <DataGridPro
          sx={gridStyles}
          disableSelectionOnClick={true}
          rows={users}
          autoHeight={true}
          columns={USERS_LIST_COLUMNS}
          onRowClick={handleRowClick}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
            panel: {
              sx: {
                ...dataGridFilterStyles,
              },
            },
          }}
          initialState={{
            columns: { columnVisibilityModel: { active: false } },
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: "active",
                    operatorValue: "equals",
                    value: "1",
                  },
                ],
              },
            },
          }}
        />
      </BottomSection>
    </Page>
  );
};

export default UsersPage;
