import React, { FC } from "react";
import { ModalData } from "./types";
import { useRefundModal } from "./useRefundModal";
import { Transition } from "../Transition";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { Fields, initialValues, validationSchema } from "./schema";
import { content, container, btnSx, captionSx } from "./RefundModal.styles";
import { FormikSelect } from "../../../../../components/Select";
import { FormikInput } from "../../../../../components";

export interface Props {
  modal: ModalData | null;
  onClose?: (data: ModalData | null) => void;
}

const RefundModal: FC<Props> = ({ modal, onClose }) => {
  const { innerRef, open, caption, transactions, handleSubmit, handleClose } =
    useRefundModal({
      modal,
      onClose,
    });

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "860px",
        },
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose?.(null)}
    >
      <DialogTitle>Do a refund</DialogTitle>

      <Formik<Fields>
        innerRef={innerRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, submitForm }) => {
          const cardNumber = values.cardsNumbers[values.transaction_id] ?? "";

          return (
            <Form>
              <DialogContent sx={content}>
                <Typography sx={captionSx} mb={3}>
                  {caption}
                </Typography>

                <Grid container sx={container}>
                  <Grid item xs={8}>
                    <FormikSelect
                      label="Select transaction"
                      name="transaction_id"
                      options={transactions}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormikInput name="amount" label="Refund amount" />
                  </Grid>

                  <Grid item xs={8}>
                    <FormikInput name="note" label="Reason" />
                  </Grid>
                </Grid>

                {!!cardNumber && (
                  <Typography sx={captionSx} mt={3}>
                    {`Refunding to card ending ${cardNumber}`}
                  </Typography>
                )}
              </DialogContent>

              <Divider
                variant="middle"
                sx={{ border: "1px solid #DDE3EB", mb: 2 }}
              />

              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={btnSx}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color={"primary"}
                  sx={btnSx}
                  onClick={submitForm}
                >
                  refund
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default RefundModal;
