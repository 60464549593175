import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  ChaburahTeachersNeighborhoods,
  listsSelectors,
  uiActions,
  uiSelectors,
} from "../../../../state";
import { Option } from "../../../../components/Select/Select";
import { FormikProps } from "formik";
import { RoshKinyanFields } from "../schema";
import { useEffect, useState } from "react";
import { ModalData } from "./types";
import { useGetPageMode } from "../utils";

export function useTabRoshKinyan(formik: FormikProps<RoshKinyanFields>) {
  const dispatch = useDispatch();
  const { pageMode } = useGetPageMode();
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    formik;

  const [modal, setModal] = useState<ModalData | null>(null);
  const [users, setUsers] = useState<Option[]>([]);

  const control = useSelector(uiSelectors.control);
  const _users = useSelector(listsSelectors.users);

  const handleAddNewRoshKinyan = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setModal({
      mode: "insert",
      editGroup: values.editGroup,
      chaburah_id: values.chaburah_id,
    });
  };

  const handleEditChaburahRoshKinyan = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    const data = {
      user_id: +values.chaburah_teacher_id,
      chaburah_id: values.chaburah_id,
      neighborhood_id: null,
      default: true,
    };
    dispatch(adminActions.updateTeacher(data));
  };

  const handleModalClose = (data: typeof modal) => {
    if (data) {
      const arr = JSON.parse(
        JSON.stringify(values.chaburah_teachers_neighborhoods),
      ) as ChaburahTeachersNeighborhoods[];

      if (data!.mode === "insert") {
        arr.push({
          index: arr.length,
          chaburah_teacher_id: data!.chaburah_teacher_id!,
          user_id: data!.user_id!,
          user_name: data!.user_name!,
          neighborhood_id: data!.neighborhood_id!,
          neighborhood: data!.neighborhood!,
          default: data!.default!,
        });

        setFieldValue("chaburah_teachers_neighborhoods", arr);
      }

      if (data!.mode === "edit") {
        const idx = data!.index!;
        const {
          user_id,
          user_name,
          neighborhood_id,
          neighborhood,
          default: _default,
          chaburah_id,
          editGroup,
        } = data!;

        arr[idx].user_id = user_id!;
        arr[idx].user_name = user_name!;
        arr[idx].neighborhood_id = neighborhood_id!;
        arr[idx].neighborhood = neighborhood!;
        arr[idx].default = _default!;
        setFieldValue("editGroup", editGroup);
        setFieldValue("chaburah_id", chaburah_id);
        setFieldValue("chaburah_teachers_neighborhoods", arr);
      }
    }

    setModal(null);
  };

  useEffect(() => {
    if (control?.type === "remove") {
      const arr = JSON.parse(
        JSON.stringify(values.chaburah_teachers_neighborhoods),
      ) as ChaburahTeachersNeighborhoods[];

      const newArr = arr
        .filter((a) => a.index !== control.id)
        .map((a, index) => ({
          ...a,
          index,
        }));

      dispatch(uiActions.setControl(null));

      setFieldValue("chaburah_teachers_neighborhoods", newArr);
    }

    if (control?.type === "edit") {
      const { id, type } = control;

      dispatch(uiActions.setControl(null));

      setModal({
        chaburah_id: values.chaburah_id,
        editGroup: values.editGroup,
        mode: type,
        index: id,
        user_id: values.chaburah_teachers_neighborhoods[id].user_id,
        neighborhood_id:
          values.chaburah_teachers_neighborhoods[id].neighborhood_id,
        default: values.chaburah_teachers_neighborhoods[id].default,
        id: values.chaburah_teachers_neighborhoods[id].id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, control]);

  useEffect(() => {
    setUsers(() => {
      return (
        _users
          ?.filter((u) => u.role_id[3] || u.id === +values.chaburah_teacher_id)
          .map(
            (u) =>
              ({
                label: u.name_yiddish,
                value: u.id.toString(),
              } as Option),
          ) ?? []
      );
    });
  }, [_users, values]);

  return {
    users,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    modal,
    handleAddNewRoshKinyan,
    handleEditChaburahRoshKinyan,
    handleModalClose,
    pageMode,
  };
}
