import { SxProps, Theme } from "@mui/material";

export const caption: SxProps<Theme> = {
  color: "#000000",
  fontSize: "14px",
  fontWeight: "bold",
  letterSpacing: "0",
  lineHeight: "16px",
};

export const container: SxProps<Theme> = {
  minWidth: "500px",
};

export const content: SxProps<Theme> = {
  p: "32px 40px",
};

export const btnSx: SxProps<Theme> = {
  minWidth: "140px",
};
