import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  NeighborhoodWithPrincipals,
} from "../../../../state";
import { ModalData } from "./types";
import { GridRowParams } from "@mui/x-data-grid-pro";

export function useTabNeighborhoods() {
  const dispatch = useDispatch();

  const [modal, setModal] = useState<ModalData | null>(null);

  const neighborhoods = useSelector(adminSelectors.neighborhoods);

  const handleAddNeighborhood = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setModal({ mode: "insert" });
    },
    [],
  );

  const handleEditNeighborhood = (
    params: GridRowParams<NeighborhoodWithPrincipals>,
  ) => {
    setModal({
      mode: "edit",
      id: params.row.id,
      name: params.row.neighborhood,
      user_id: params.row.users?.[0]?.id?.toString() ?? "",
    });
  };

  useEffect(() => {
    dispatch(adminActions.getNeighborhoodWithPrincipals());
  }, [dispatch]);

  return {
    modal,
    setModal,
    neighborhoods,
    handleAddNeighborhood,
    handleEditNeighborhood,
  };
}
