import React, { FC, useEffect, useState } from "react";
import TabContainer from "../../components/TabContainer";
import { useParams } from "react-router";
import { gematriya, toInt } from "../../../../lib";
import {
  adminActions,
  adminSelectors,
  listsSelectors,
  StudentHistory,
} from "../../../../state";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { DataGridPro, GridRowParams, MuiEvent } from "@mui/x-data-grid-pro";
import { STUDENT_HISTORY_COLUMNS } from "../../../../constants";
import HistoryDetailView from "./HistoryDetailView";
import { dataGridFilterStyles } from "../../../../themes";

const TabHistory: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState<StudentHistory[]>([]);

  const [semesterName, setSemesterName] = useState<string>("");
  const [showSemesterView, setShowSemesterView] = useState<boolean>(false);
  const currentMonth = useSelector(adminSelectors.currentMonthId);
  const months = useSelector(listsSelectors.monthById);
  const [showFuture, setShowFuture] = useState(false);

  useEffect(() => {
    !!params.id &&
      toInt(params.id) &&
      dispatch(adminActions.getStudentHistory(toInt(params.id)));
  }, [dispatch, params.id]);

  const student = useSelector(adminSelectors.student);
  const currentSemesterId = currentMonth
    ? months[currentMonth]?.semester_id
    : 0 ?? 0;
  const studentHistory = useSelector(adminSelectors.studentHistory);
  const studentHistoryDetails = useSelector(
    adminSelectors.studentHistoryDetails,
  );

  const tracks = useSelector(listsSelectors.trackById);
  const chaburahTypes = useSelector(listsSelectors.chaburahTypesById);
  const locations = useSelector(listsSelectors.locationById);

  useEffect(() => {
    if (studentHistory) {
      const _studentHistory = showFuture
        ? studentHistory.map((h) => ({
            ...h,
            timePeriod:
              h.id < currentSemesterId
                ? "Past"
                : h.id === currentSemesterId
                ? "Current"
                : "Future",
          }))
        : studentHistory.filter((h) =>
            showFuture ? true : h.id <= currentSemesterId,
          );
      const studentHistorySorted = _studentHistory.sort((a, b) =>
        a.year_id > b.year_id ||
        (a.year_id === b.year_id && a.type === "summer")
          ? -1
          : 1,
      );
      // for calculating grade in "Grade" column in table
      const firstYearId = currentMonth ? months[currentMonth]?.year_id : 0 ?? 0;

      // injecting dictionaries (lists) into this structure to use them
      // for rendering table cells.
      // Using hook into render cell method raised an error
      const ns = studentHistorySorted.map((s) => {
        return {
          ...s,
          student_id: params.id,
          __list: {
            tracks,
            chaburahTypes,
            locations,
            extraData: {
              grade: student?.grade ?? 0,
              firstYearId,
            },
          },
        } as StudentHistory;
      });
      setData(ns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentHistory, showFuture]);

  const handleRowClick = (
    params: GridRowParams<StudentHistory>,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    event.stopPropagation();
    let semesterType;
    switch (params.row?.type) {
      case "winter":
        semesterType = "ווינטער";
        break;
      case "summer":
        semesterType = "זיממער";
        break;
      default:
        semesterType = "";
    }

    let year = "";

    if (params.row?.year_id) {
      const y = +params.row.year_id.toString(10).slice(2);
      year = gematriya(y.toString());
    }

    setSemesterName(`${semesterType} ${year}`);

    dispatch(
      adminActions.getStudentHistoryDetails(
        toInt(params.row.student_id),
        params.row.id,
      ),
    );

    setShowSemesterView(true);
  };

  if (!studentHistory) {
    return null;
  }

  return (
    <TabContainer sx={{ px: 0 }}>
      {studentHistoryDetails.length && showSemesterView ? (
        <HistoryDetailView
          setShowSemesterView={setShowSemesterView}
          semesterName={semesterName}
        />
      ) : (
        <Box>
          <Box paddingTop={2} paddingBottom={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showFuture}
                  onChange={() => {
                    setShowFuture(!showFuture);
                  }}
                />
              }
              label="Show future enrollment"
            />
          </Box>
          <DataGridPro
            sx={{
              "& .MuiDataGrid-main": {
                borderBottom: "1px solid #DDE3EB",
                borderRadius: 2,

                "& .MuiDataGrid-row": {
                  cursor: "pointer",
                },
              },
            }}
            // getRowId={(row) => {
            //   return row.chaburah_id;
            // }}
            onRowClick={handleRowClick}
            hideFooter={true}
            rows={data}
            autoHeight={true}
            columns={STUDENT_HISTORY_COLUMNS}
            initialState={{
              columns: {
                columnVisibilityModel: { "siyum.transportation": false },
              },
            }}
            componentsProps={{
              toolbar: { printOptions: { disableToolbarButton: true } },
              panel: {
                sx: {
                  ...dataGridFilterStyles,
                },
              },
            }}
          />
        </Box>
      )}
    </TabContainer>
  );
};

export default TabHistory;
