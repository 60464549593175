import { CSSProperties } from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export const topSectionStyle: CSSProperties = {
  marginTop: 0,
  paddingTop: 24,
  height: "120px",
};

export const bottomSectionStyle: CSSProperties = {
  marginTop: "-40px",
  paddingTop: 0,
};

export const gridStyles: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    "& > .MuiDataGrid-columnHeaders": {
      backgroundColor: "white",
    },

    "& .MuiDataGrid-row": {
      cursor: "pointer",
    },
  },
};
