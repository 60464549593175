import { CSSProperties } from "react";
import { SxProps, Theme } from "@mui/material";

export const topSectionStyle: CSSProperties = {
  marginTop: 0,
  paddingTop: 24,
  height: "120px",
  borderBottom: "1px solid #DDE3EB",
};

export const bottomSectionStyle: CSSProperties = {
  marginTop: "-60px",
  marginBottom: 0,
  padding: "24px 55px",
};

export const section: SxProps<Theme> = {
  border: "1px solid #DDE3EB",
  backgroundColor: "primary.contrastText",
  borderRadius: "8px",
  p: 4,
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: "50%",
  minHeight: "250px",
  display: "flex",
  flexDirection: "column",
};

export const nameEng: SxProps<Theme> = {
  opacity: 0.79,
  color: "#000000",
  fontSize: "12px",
  letterSpacing: "0",
  lineHeight: "13px",
  flexGrow: 1,
};

export const rolesStyle: SxProps<Theme> = {
  color: "#000000",
  fontSize: "12px",
  letterSpacing: "0.09px",
  lineHeight: "13px",
  flexGrow: 1,
};

export const btnStyle: SxProps<Theme> = {
  minWidth: "100px",
  mt: "auto",
  alignSelf: "start",
};
