import React, { FC } from "react";
import { useAddRoshKinyan } from "./useAddRoshKinyan";
import { Transition } from "../../../components/Modals/Transition";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import { Form, Formik } from "formik";
import { Fields, initialValues, validationSchema } from "./schema";
import { btnSx, container, content } from "./AddRoshKinyan.styles";
import { FormikSelect } from "../../../../../components/Select";
import CheckBox from "../../../../../components/CheckBox";
import { ModalData } from "../types";

export interface Props {
  modal: ModalData | null;
  onClose?: (data: ModalData | null) => void;
}

const AddRoshKinyan: FC<Props> = ({ modal, onClose }) => {
  const { innerRef, open, users, neighborhoods, handleClose, handleSubmit } =
    useAddRoshKinyan({ modal, onClose });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose?.(null)}
    >
      <DialogTitle>
        {modal?.mode === "edit" ? "Edit" : "Add"} a ראש קנין / מגיד שיעור
      </DialogTitle>

      <Formik<Fields>
        innerRef={innerRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, submitForm, handleChange }) => {
          return (
            <Form>
              <DialogContent sx={content}>
                <Grid container sx={container}>
                  <Grid item xs={8}>
                    <FormikSelect
                      label="ראש קנין / מגיד שיעור"
                      name="user_id"
                      options={users}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <FormikSelect
                      label="Neighborhood"
                      name="neighborhood_id"
                      options={neighborhoods}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <CheckBox
                      name={"default"}
                      label={"Default for this group"}
                      checked={values.default}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <Divider
                variant="middle"
                sx={{ border: "1px solid #DDE3EB", mb: 2 }}
              />

              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={btnSx}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color={"primary"}
                  sx={btnSx}
                  onClick={submitForm}
                >
                  {modal?.mode === "edit" ? "Save changes" : "Add"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddRoshKinyan;
