import React, { FC, useMemo } from "react";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import SimplePageHeader from "../../../components/SimplePageHeader";
import {
  bottomSectionStyle,
  contentStyles,
  controlsSx,
  dataStyles,
  fieldCaptionStyles,
  gridTestsSx,
  groupCaptionStyles,
  groupsStyles,
  monthCaptionStyles,
  monthListStyles,
  topSectionStyle,
} from "./GroupSettingsPage.styles";
import { useGroupSettingsPage } from "./useGroupSettingsPage";
import Select from "../../../components/Select";
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { Fields, initialValues, validationSchema } from "./schema";
import { FormikInput, TodayIcon } from "../../../components";
import { SystemStyleObject } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GROUP_TEST_COLUMNS } from "../../../constants";
import { dataGridFilterStyles } from "../../../themes";

const GroupSettingsPage: FC = () => {
  const {
    innerRef,
    groups,
    groupId,
    months,
    monthId,
    handleBackButtonClick,
    handleSelectGroup,
    handleSelectMonth,
    handleSubmit,
    handleAddNewTest,
  } = useGroupSettingsPage();

  const monthSelector = useMemo(() => {
    return (
      <List component={"div"} disablePadding={true}>
        {months.map(({ id, name }) => {
          if (id) {
            return (
              <ListItemButton
                key={`${id}:${name}`}
                autoFocus={id === +monthId}
                sx={monthCaptionStyles}
                selected={id === +monthId}
                onClick={handleSelectMonth.bind(null, id.toString())}
              >
                <ListItemText primary={name} />
              </ListItemButton>
            );
          } else {
            return (
              <ListItemText
                key={`${id}:${name}`}
                primary={name}
                sx={groupCaptionStyles}
              />
            );
          }
        })}
      </List>
    );
  }, [months, monthId, handleSelectMonth]);

  return (
    <Page>
      <TopSection style={topSectionStyle}>
        <SimplePageHeader
          title={"Group settings"}
          onBackButtonClick={handleBackButtonClick}
          controlsSx={controlsSx}
          controls={
            <Select
              sx={groupsStyles}
              options={groups}
              value={groupId}
              onChange={handleSelectGroup}
            />
          }
        />
      </TopSection>

      <BottomSection style={bottomSectionStyle}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={contentStyles}
        >
          <Box sx={monthListStyles}>{monthSelector}</Box>

          <Box sx={dataStyles}>
            <Box sx={{ width: "550px" }}>
              <Formik<Fields>
                innerRef={innerRef}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form>
                      <Stack direction={"column"}>
                        <Typography variant={"h4"} sx={{ mb: 4 }}>
                          Edit{` ${values.currMonth}`}
                        </Typography>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant={"subtitle1"}
                              sx={fieldCaptionStyles}
                            >
                              Curriculum - לימוד
                            </Typography>

                            <FormikInput
                              name="description"
                              label="Curriculum"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Typography
                          variant={"subtitle1"}
                          sx={fieldCaptionStyles}
                        >
                          חזרה פראגראם
                        </Typography>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={4}
                        >
                          <Grid item xs={6}>
                            <FormikInput
                              name="max_review_checks"
                              label="Max checks"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormikInput
                              name="review_bonus"
                              label="Bonus points"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Typography
                          variant={"subtitle1"}
                          sx={fieldCaptionStyles}
                        >
                          טעגליכע פראגראם
                        </Typography>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={4}
                        >
                          <Grid item xs={6}>
                            <FormikInput
                              name="max_attendance_checks"
                              label="Max checks"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormikInput
                              name="attendance_bonus"
                              label="Bonus points"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Typography
                          variant={"subtitle1"}
                          sx={fieldCaptionStyles}
                        >
                          Day settings
                        </Typography>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={4}
                        >
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                inputFormat="MM/dd/yyyy"
                                renderInput={(params: TextFieldProps) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label={"Charge date"}
                                      variant="outlined"
                                      autoComplete={"off"}
                                      fullWidth={true}
                                    />
                                  );
                                }}
                                components={{
                                  OpenPickerIcon: () => <TodayIcon />,
                                }}
                                onChange={(value) => {
                                  setFieldValue("charge_date", value);
                                }}
                                value={values.charge_date}
                              />
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                inputFormat="MM/dd/yyyy"
                                renderInput={(params: TextFieldProps) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label={"בחינה date"}
                                      variant="outlined"
                                      autoComplete={"off"}
                                      fullWidth={true}
                                    />
                                  );
                                }}
                                components={{
                                  OpenPickerIcon: () => <TodayIcon />,
                                }}
                                onChange={(value) => {
                                  setFieldValue("test_date", value);
                                }}
                                value={values.test_date}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>

                        <Typography
                          variant={"subtitle1"}
                          sx={[
                            fieldCaptionStyles as SystemStyleObject,
                            { mt: 6, mb: 2 } as SystemStyleObject,
                          ]}
                        >
                          Test - בחינה
                        </Typography>

                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={4}
                        >
                          <Grid item xs={12}>
                            <DataGridPro
                              sx={gridTestsSx}
                              rowHeight={74}
                              disableSelectionOnClick={true}
                              hideFooterRowCount={true}
                              hideFooter={true}
                              rows={values.test_questions ?? []}
                              autoHeight={true}
                              columns={GROUP_TEST_COLUMNS}
                              initialState={{
                                sorting: {
                                  sortModel: [{ field: "name", sort: "asc" }],
                                },
                              }}
                              componentsProps={{
                                toolbar: {
                                  printOptions: { disableToolbarButton: true },
                                },
                                panel: {
                                  sx: {
                                    ...dataGridFilterStyles,
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Stack>

                      <Button
                        variant="outlined"
                        sx={{ mb: 4, mt: 1, minWidth: "180px" }}
                        onClick={handleAddNewTest}
                      >
                        Add
                      </Button>

                      <Divider sx={{ my: 1.5 }} />

                      <Button
                        variant="contained"
                        sx={{ minWidth: "180px" }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Stack>
      </BottomSection>
    </Page>
  );
};

export default GroupSettingsPage;
