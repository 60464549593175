import { styled } from "@mui/styles";

export const Page = styled(`div`)(() => ({
  minWidth: "100%",
  minHeight: "100%",
}));

export const TopSection = styled(`div`)(() => ({
  height: 210,
  backgroundColor: "#F3F6F9",
  marginTop: 25,
  paddingLeft: 25,
  paddingRight: 25,
}));
export const BottomSection = styled(`div`)(() => ({
  paddingLeft: 25,
  paddingRight: 25,
  marginBottom: 25,
}));
