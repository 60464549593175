import {
  AppThunk,
  NewCustomerPayment,
  SaveCard,
  CustomersPayment,
  EditedCustomerPayment,
} from "..";
import { Navigation } from "../../lib";
import AdminPages from "../../pages/admin";
import { authClient, uiActions } from "../states";
import { customer } from "./state";

const { actions } = customer;

export const customerActions = {
  ...actions,
  getCustomers(getBalance = false): AppThunk {
    return async (dispatch, getState) => {
      const { customers } = getState().customer;
      dispatch(uiActions.setLoading(!getBalance && customers.count <= 0));
      const { data, status } = await authClient.get(
        `/customers?get_balance=${getBalance}`,
      );
      if (status === 200) {
        dispatch(actions.setCustomers(data));
      } else {
        dispatch(uiActions.showError("Get customers failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCustomersValidate(value, id?): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/customers/validate?value=${value}${id ? `&id=${id}` : ""}`,
      );
      if (status === 200) {
        return data;
      } else {
        dispatch(uiActions.showError("Validation for phone number failed"));
      }
    };
  },

  getCustomersTotal(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/customers/totals`);
      if (status === 200) {
        dispatch(actions.setCustomersTotals(data));
      } else {
        dispatch(uiActions.showError("Get customers totals failed"));
      }
    };
  },
  getCustomerBill(studentId, month_id): AppThunk {
    return async (dispatch) => {
      const url = `/customers/${studentId}/next_bill${
        month_id ? "?month_id=" + month_id : ""
      }`;

      const { data, status } = await authClient.get(url);
      if (status !== 200) {
        dispatch(uiActions.showError("Get Billing failed"));
      } else {
        dispatch(actions.setCustomerBillingNextMonth(data));
      }
    };
  },
  getCustomerBillingHistory(
    id,
    all: boolean | undefined = undefined,
  ): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const getAllRecords =
        all === undefined
          ? getState().customer.customerBillingIsNotFiltered
          : all;
      const { data, status } = await authClient.get(
        `/customers/${id}/billing_history?all=${getAllRecords}`,
      );
      if (status !== 200) {
        dispatch(uiActions.showError("Get Billing history failed"));
      } else {
        dispatch(actions.setCustomerBillingHistory(data));
      }
      dispatch(uiActions.setLoading(false));
      return data;
    };
  },
  getCustomerCardInfo(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/processor_transactions/${id}/list_payment_methods`,
      );
      if (status !== 200 && status !== 404) {
        dispatch(uiActions.showError("Get card info failed"));
      } else if (status === 200) {
        dispatch(actions.setCustomerCardInfo(data));
      } else {
        dispatch(actions.setCustomerCardInfo([]));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteCustomerCard(id, cardId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/processor_transactions/${id}/delete_card`,
        {
          paymentMethodId: cardId,
        },
      );

      if (status === 204) {
        dispatch(uiActions.showSuccess("Delete card succeeded"));
        dispatch(customerActions.getCustomerCardInfo(id));
      } else if (status === 500) {
        dispatch(uiActions.showError(data.message));
      } else {
        dispatch(uiActions.showError("Delete card failed", data.message));
      }

      dispatch(uiActions.setLoading(false));
      return data;
    };
  },
  saveCustomerCard(id: string | number, cardData: SaveCard): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.post(
        `/processor_transactions/${id}/save_card`,
        cardData,
      );

      if (status === 204) {
        dispatch(uiActions.showSuccess("Adding card succeeded"));
        dispatch(customerActions.getCustomerCardInfo(id));
      } else if (status === 500) {
        dispatch(uiActions.showError(data.message));
      } else {
        dispatch(uiActions.showError("Adding card failed", data.message));
      }

      dispatch(uiActions.setLoading(false));

      return data;
    };
  },
  getCustomer(id: string | number): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/customers/${id}`);
      if (status !== 200) {
        dispatch(uiActions.showError("Get customers failed"));
      } else {
        dispatch(
          actions.setCustomer(
            +id === getState().customer.customer?.id
              ? { ...getState().customer.customer, ...data }
              : data,
          ),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  acceptTerms(id) {
    return async (dispatch) => {
      const { status } = await authClient.put(
        `/customers/${id}/terms`,
        undefined,
      );
      if (status === 204) {
        await dispatch(this.getCustomer(id));
      }
    };
  },
  updateCustomer(id: string | number, values): AppThunk<Promise<boolean>> {
    return async (dispatch): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.put(`/customers/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("customer updated"));
        dispatch(customerActions.getCustomer(id));
      } else {
        if (data.message === "Validation fails for Invalid home phone format") {
          dispatch(
            uiActions.showError(
              "failed to save customer Invalid home phone number",
            ),
          );
        } else if (
          data.message === "Validation fails for Invalid cellphone format"
        ) {
          dispatch(
            uiActions.showError(
              "failed to save customer Invalid cellphone number",
            ),
          );
        } else {
          dispatch(
            uiActions.showError("failed to update customer", data.message),
          );
        }
        dispatch(uiActions.setLoading(false));
        return data;
      }
      dispatch(uiActions.setLoading(false));
      return status === 204;
    };
  },
  getCustomerPrepayOptions(personId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.get(
        `/customers/${personId}/prepay_options`,
      );

      if (status !== 200) {
        dispatch(
          uiActions.showError("Getting customers prepay options failed"),
        );
      } else {
        dispatch(actions.seCustomerPaymentOptions(data));
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  createCustomer(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      if (values.mobile_country_code_id === "") {
        delete values.mobile_country_code_id;
      }
      if (values.mobile_phone === "") {
        delete values.mobile_phone;
      }
      const { data, status } = await authClient.post(`/customers`, values);
      if (status === 200) {
        dispatch(uiActions.showSuccess("customer saved successfully"));
        Navigation.go(
          AdminPages.addNewStudentFromCustomer.path.replace(":id", data.id),
        );
      } else {
        if (data.message === "Validation fails for Invalid home phone format") {
          dispatch(
            uiActions.showError(
              "failed to save customer Invalid home phone number",
            ),
          );
        } else if (
          data.message === "Validation fails for Invalid cellphone format"
        ) {
          dispatch(
            uiActions.showError(
              "failed to save customer Invalid cellphone number",
            ),
          );
        } else {
          dispatch(
            uiActions.showError("failed to save customer", data.message),
          );
        }
        dispatch(uiActions.setLoading(false));
        return data;
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCustomerPaymentMethods(id: number): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/processor_transactions/${id}/list_payment_methods`,
      );
      if (status !== 200 && status !== 404) {
        dispatch(uiActions.showError("Get customer payment methods failed"));
      }
      dispatch(actions.setCustomerCardInfo(data));
      return data;
    };
  },
  getCustomerLegacyCharges(personId: number): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/billing/legacy-charges/${personId}`,
      );
      if (status === 200) {
        dispatch(actions.setCustomerLegacyCharges(data));
      } else {
        dispatch(uiActions.showError("Get customer legacy payments failed"));
      }
    };
  },
  getCustomerLegacyPayments(personId: number): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/billing/legacy-payments/${personId}`,
      );
      if (status === 200) {
        dispatch(actions.setCustomerLegacyPayment(data));
      } else {
        dispatch(uiActions.showError("Get customer legacy payments failed"));
      }
    };
  },
  getCustomerBillingDetails(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/customers/${id}/billing_details`,
      );
      if (status === 200) {
        dispatch(actions.setCustomerBillingDetails(data));
      } else {
        dispatch(
          uiActions.showError("Getting customer's billing details failed"),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCustomerMonthBalance(personId: string, params: string = ""): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        `/customers/${personId}/month_balance?${params}`,
      );

      if (status !== 200) {
        dispatch(uiActions.showError("Get customer's month balance failed"));
      } else {
        dispatch(actions.setCustomerMonthBalance(data));
      }
    };
  },
  createNewCustomerPayment(_data: NewCustomerPayment, nextCB): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { status, data } = await authClient.post(
        `/customers/payment`,
        _data,
      );
      if (status === 204) {
        //bug flashes the showSuccess tag twice
        dispatch(uiActions.showSuccess("New customer payment created"));
        dispatch(customerActions.getCustomer(_data.customer_id));
        nextCB();
      } else {
        dispatch(
          uiActions.showError(
            "Failed to create customer payment",
            data.message,
          ),
        );
      }

      dispatch(uiActions.setLoading(false));

      return (status === 204) as any;
    };
  },
  getCustomersPayments(transaction_id: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.get<CustomersPayment>(
        `/customers/payment/${transaction_id}`,
      );

      if (status === 200) {
        dispatch(customerActions.setCustomerPayments(data));
      } else {
        dispatch(uiActions.showError("failed to get customer's payment"));
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  deleteCustomerPayment(
    transaction_id: string | number,
    linked_invoice_id: string | number | null = null,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch, getState): Promise<boolean> => {
      const { customer } = getState().customer;
      dispatch(uiActions.setLoading(true));

      let url = `/customers/payment/${transaction_id}`;
      if (linked_invoice_id)
        url = `/customers/payment/${transaction_id}?invoice_id=${linked_invoice_id}`;
      const { status, data } = await authClient.delete(url);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Successfully deleted payment"));
        dispatch(customerActions.getCustomerBillingHistory(customer?.id));
      } else {
        dispatch(uiActions.showError("Failed to delete payment", data.message));
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  updateCustomerPayment(
    transaction_id: number | string,
    _data: EditedCustomerPayment,
  ): AppThunk<Promise<boolean>> {
    return async (dispatch, getState): Promise<boolean> => {
      dispatch(uiActions.setLoading(true));
      const { customer } = getState().customer;
      const { status, data }: any = await authClient.put<EditedCustomerPayment>(
        `/customers/payment/${transaction_id}`,
        _data,
      );

      if (status === 204) {
        dispatch(uiActions.showSuccess("Payment has been updated"));
        customer && dispatch(customerActions.getCustomer(customer.id));
      } else {
        dispatch(
          uiActions.showError(
            "Failed to update customer payment",
            data.message,
          ),
        );
      }

      dispatch(uiActions.setLoading(false));

      return status === 204;
    };
  },
  applicationFormSubmit(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { status, data } = await authClient.post(
        `/web-application`,
        values,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("Student enrollment created"));
        dispatch(uiActions.setLoading(false));
        return true;
      } else {
        dispatch(
          uiActions.showError("Failed to submit application.", data.message),
        );
      }

      dispatch(uiActions.setLoading(false));
      return (status === 204) as any;
    };
  },
};
