import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TabDetails from "./TabDetails";
import TabCurriculum from "./TabCurriculum";
import { gematriya, toFloat, toInt } from "../../../lib";
import {
  adminActions,
  adminSelectors,
  ChaburahTeacher,
  CurriculumSemester,
  EditedChaburah,
  EditedCurriculum,
  GroupCurriculum,
  GroupItem,
  listsActions,
  listsSelectors,
  NewChaburah,
  TeacherItem,
} from "../../../state";
import { useDispatch, useSelector } from "react-redux";
import { FormikHelpers, useFormik } from "formik";
import {
  CurriculumFields,
  CurriculumItem,
  DetailsFields,
  initCurriculumState,
  initDetailsState,
  initRoshKinyanState,
  RoshKinyanFields,
  validationCurriculumSchema,
  validationDetailsSchema,
  validationRoshKinyanSchema,
} from "./schema";
import { ButtonType, ChaburahType, Storage, TabType } from "./types";
import AdminPages from "../index";
import { useNavigate } from "react-router-dom";
import { useGetPageMode } from "./utils";
import TabRoshKinyan from "./TabRoshKinyan";

export function useManageGroup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasDataSet = useRef<boolean>(false);
  const lastChanges = useRef<Storage>({} as Storage);

  const [tab, setTab] = useState<TabType>("details");
  const [modal, setModal] = useState<TabType | null>(null);
  const ecd = useSelector(adminSelectors.editChaburahData);
  const _teacher = useSelector(listsSelectors.chaburahTeachers);

  const { pageMode, id } = useGetPageMode();

  const monthById = useSelector(listsSelectors.monthById);
  const semesters = useSelector(listsSelectors.semesterById);
  const semestersList = useSelector(listsSelectors.semesters);
  const userById = useSelector(listsSelectors.userById);
  const neighborhoodById = useSelector(listsSelectors.neighborhoodById);
  const subjectById = useSelector(listsSelectors.subjectById);
  const subjectByName = useSelector(listsSelectors.subjectByName);

  const handleDetailsSubmit = async (
    values: DetailsFields,
    { setSubmitting }: FormikHelpers<DetailsFields>,
  ) => {
    if (pageMode === "insert") {
      await formikRoshKinyan.setFieldValue(
        "chaburah_type_id",
        formikDetails.values.chaburah_type_id,
      );

      setSubmitting(false);
      setTab("roshKinyan");
    }

    if (pageMode === "edit") {
      const data: EditedChaburah = {
        name: values.name,
        location_id: values.location_id ? toInt(values.location_id) : null,
        price_usd: toFloat(values.priceUSD),
        price_gbp: toFloat(values.priceGBP),
        active: values.active,
      };

      const isOk = await dispatch<
        boolean | ReturnType<typeof adminActions.updateChaburah>
      >(adminActions.updateChaburah(id!, data));

      if (isOk) {
        updateLastChanges(values, null, null);
      }

      setSubmitting(false);
    }
  };

  const formikDetails = useFormik<DetailsFields>({
    initialValues: initDetailsState,
    enableReinitialize: true,
    validationSchema: validationDetailsSchema,
    onSubmit: handleDetailsSubmit,
  });

  const handleRoshKinyanSubmit = async (
    values: RoshKinyanFields,
    { setSubmitting }: FormikHelpers<RoshKinyanFields>,
  ) => {
    if (pageMode === "insert") {
      setSubmitting(false);

      setTab("curriculum");
      null;
    }
  };

  const formikRoshKinyan = useFormik<RoshKinyanFields>({
    initialValues: initRoshKinyanState,
    enableReinitialize: true,
    validationSchema: validationRoshKinyanSchema,
    onSubmit: handleRoshKinyanSubmit,
  });

  const handleCurriculumSubmit = async (
    values: CurriculumFields,
    { setSubmitting }: FormikHelpers<CurriculumFields>,
  ) => {
    if (pageMode === "insert") {
      const {
        name,
        chaburah_type_id,
        neighborhood_id,
        location_id,
        priceUSD,
        priceGBP,
        active,
      } = formikDetails.values;

      const { chaburah_teacher_id, chaburah_teachers_neighborhoods } =
        formikRoshKinyan.values;

      const semester_id =
        semestersList.filter(
          (sl) =>
            sl.year_id === +values.yearId && sl.name === values.semesterTypeId,
        )?.[0]?.id ?? -1;

      const data: NewChaburah = {
        name,
        chaburah_type_id,
        neighborhood_id:
          chaburah_type_id === ChaburahType.Masmidim
            ? toInt(neighborhood_id)
            : 0,
        track_id: toInt(values.trackId),
        price_usd: toFloat(priceUSD),
        price_gbp: toFloat(priceGBP),
        semester_id,
        active,
        chaburah_teacher_id:
          chaburah_type_id === ChaburahType.Masmidim
            ? toInt(chaburah_teacher_id)
            : 0,
        chaburah_teachers_neighborhoods: null,
        curriculum: null,
      };

      if (
        chaburah_type_id === ChaburahType.CD &&
        chaburah_teachers_neighborhoods.length
      ) {
        data.chaburah_teachers_neighborhoods =
          chaburah_teachers_neighborhoods.map((c) => {
            return {
              neighborhood_id: c.neighborhood_id,
              chaburah_teachers_id: c.chaburah_teacher_id,
              default: c.default,
            };
          });
      }
      if (chaburah_type_id === ChaburahType.Masmidim && location_id) {
        data.location_id = toInt(location_id);
      }
      if (values.semesters) {
        data.curriculum = values.semesters.map((s) => {
          return {
            id: s.subject_id!,
            semester_id: s.semester_id!,
            type: subjectByName[s.subject!]?.type,
          };
        });
      }

      // let result: boolean | ReturnType<typeof adminActions.createChaburah> =
      //   false;

      if (pageMode === "insert") {
        dispatch<boolean | ReturnType<typeof adminActions.createChaburah>>(
          adminActions.createChaburah(data),
        );
      }

      setSubmitting(false);

      // result && setTab("months");
    }

    if (pageMode === "edit") {
      const start_semester_id =
        semestersList.filter(
          (sl) =>
            sl.year_id === +values.yearId && sl.name === values.semesterTypeId,
        )?.[0]?.id ?? -1;

      const data: EditedCurriculum = {
        start_semester_id,
        curriculum:
          values.semesters?.map((s) => {
            return {
              id: s.subject_id!,
              semester_id: s.semester_id!,
              type: subjectByName[s.subject!]?.type,
            };
          }) ?? [],
      };

      const isOk = await dispatch<
        boolean | ReturnType<typeof adminActions.updateCurriculum>
      >(adminActions.updateCurriculum(id!, data));

      if (isOk) {
        updateLastChanges(null, null, values);
      }

      setSubmitting(false);
    }
  };

  const formikCurriculum = useFormik<CurriculumFields>({
    initialValues: initCurriculumState,
    enableReinitialize: true,
    validationSchema: validationCurriculumSchema,
    onSubmit: handleCurriculumSubmit,
  });

  const updateLastChanges = (
    details: DetailsFields | null,
    roshKinyan: RoshKinyanFields | null,
    curriculum: CurriculumFields | null,
  ) => {
    if (details) {
      const { name, location_id, priceUSD, priceGBP, active } = details!;

      lastChanges.current.details = {
        name,
        location_id,
        priceUSD,
        priceGBP,
        active,
      };
    }

    if (roshKinyan) {
      const { chaburah_teacher_id, chaburah_teachers_neighborhoods } =
        roshKinyan!;

      lastChanges.current.roshKinyan = {
        chaburah_teacher_id,
        chaburah_teachers_neighborhoods: JSON.parse(
          JSON.stringify(chaburah_teachers_neighborhoods),
        ),
      };
    }

    if (curriculum) {
      const { trackId, yearId, semesterTypeId, semesters } = curriculum!;

      lastChanges.current.curriculum = {
        trackId,
        yearId,
        semesterTypeId,
        semesters: JSON.parse(JSON.stringify(semesters)),
      };
    }
  };

  useEffect(() => {
    if (pageMode === "edit" && !!id && toInt(id)) {
      dispatch(adminActions.getChaburahEditData(id));
    }
  }, [pageMode, dispatch, id]);

  useEffect(() => {
    if (pageMode === "edit" && ecd?.length > 0 && id) {
      const { chaburah_type_id, neighborhood_id } = (
        ecd as unknown as GroupItem[]
      )[0];
      // Could not find other solution for getting chaburah teacher (Volodymyr)
      if (chaburah_type_id === ChaburahType.Masmidim && neighborhood_id) {
        dispatch<ChaburahTeacher | ReturnType<typeof listsActions.getTeacher>>(
          listsActions.getTeacher(neighborhood_id.toString(), id),
        );
      }
    }
  }, [pageMode, ecd, id, dispatch]);

  useEffect(() => {
    (async () => {
      if (pageMode === "edit" && ecd.length > 0 && !!id) {
        const {
          name,
          chaburah_type_id,
          neighborhood_id = 0,
          location_id = 0,
          track_id = 0,
          prices,
          active,
        } = (ecd as unknown as GroupItem[])[0];
        const priceUSD =
          prices?.filter((p) => p.currency === "USD")?.[0]?.price ?? "";

        const priceGBP =
          prices?.filter((p) => p.currency === "GBP")?.[0]?.price ?? "";

        let chaburah_teachers_neighborhoods: RoshKinyanFields["chaburah_teachers_neighborhoods"] =
          [];

        let chaburah_teacher_id = "";
        if (chaburah_type_id === ChaburahType.Masmidim && neighborhood_id) {
          chaburah_teacher_id = _teacher?.[0]?.user_id?.toString() ?? "";
        }

        if (chaburah_type_id === ChaburahType.CD) {
          const tchrs = await dispatch<
            TeacherItem[] | ReturnType<typeof listsActions.getTeachers>
          >(listsActions.getTeachers(id));

          if (Array.isArray(tchrs)) {
            chaburah_teachers_neighborhoods = tchrs.map((t, index) => {
              return {
                id: t.id,
                index,
                chaburah_teacher_id: t.chaburah_teacher.id ?? 0,
                user_id: t.chaburah_teacher.user_id ?? 0,
                user_name:
                  userById[t.chaburah_teacher.user_id ?? 0]?.name_yiddish ?? "",
                neighborhood_id: t.neighborhood_id ?? 0,
                neighborhood:
                  neighborhoodById[t.neighborhood_id ?? 0]?.name ?? "",
                default: t.default,
              } as RoshKinyanFields["chaburah_teachers_neighborhoods"][number];
            });
          }
        }

        let yearId = "";
        let semesterTypeId = "";
        let semestersList: CurriculumItem[] = [];

        const cc = await dispatch<
          | GroupCurriculum[]
          | ReturnType<typeof adminActions.getChaburahCurriculum>
        >(adminActions.getChaburahCurriculum(id));

        if (Array.isArray(cc) && cc.length) {
          const semester_id = cc[0].semester_id;

          // Year and semester type in "Group begins" section
          yearId = semesters[semester_id]?.year_id.toString() ?? "";
          semesterTypeId = semesters[semester_id]?.name ?? "";

          semestersList = cc.map((c, index) => {
            const semesterYear =
              semesters[c.semester_id]?.year_id.toString() ?? "";
            const startSemester = semesters[semester_id]?.name ?? "";

            // Calculating startYear, startYear and semesterYear is based on logic on lines 523, 530 and below
            let _semesterYear = yearId;

            if (semesterTypeId === "winter" && index !== 0 && index % 2 === 0) {
              _semesterYear = (parseInt(_semesterYear) + 1).toString();
            } else if (
              semesterTypeId === "summer" &&
              index !== 0 &&
              index % 2 !== 0
            ) {
              _semesterYear = (parseInt(_semesterYear) + 1).toString();
            }

            return {
              index,
              semester_id: c.semester_id,
              startYear: yearId,
              startSemester: semesterTypeId,
              semesterYear,
              semester: `${gematriya(semesterYear.toString().slice(2))} ${
                startSemester === "summer"
                  ? index % 2 === 0
                    ? "זיממער"
                    : "ווינטער"
                  : index % 2 === 0
                  ? "ווינטער"
                  : "זיממער"
              }`,
              subject_id: c.subject_id,
              subject: subjectById[c.subject_id ?? 0]?.name_hebrew,
              isDeletable: false,
            } as CurriculumItem;
          });
        }

        // Details tab
        const detailsData: DetailsFields = {
          name,
          chaburah_type_id,
          neighborhood_id:
            neighborhood_id && toInt(neighborhood_id)
              ? neighborhood_id.toString()
              : "",
          location_id:
            location_id && toInt(location_id) ? location_id.toString() : "",
          priceUSD,
          priceGBP,
          active,
        };
        await formikDetails.setValues(detailsData);

        // Rosh Kinyan tab
        const roshKinyanData: RoshKinyanFields = {
          editGroup: pageMode === "edit",
          chaburah_id: +id,
          chaburah_type_id,
          chaburah_teacher_id,
          chaburah_teachers_neighborhoods,
        };
        await formikRoshKinyan.setValues(roshKinyanData);

        // Curriculum tab
        const curriculumData: CurriculumFields = {
          trackId: !!track_id && toInt(track_id) ? track_id.toString() : "",
          yearId,
          semesterTypeId,
          semesters: semestersList,
          // curriculum?.map((c, index) => {
          //   return {
          //     index,
          //     semester_id: c.semester_id,
          //     startYear: c.startYear,
          //     startSemester: c.startSemester,
          //     semesterYear: c.semesterYear,
          //     semester: `${gematriya(c.semesterYear.toString().slice(2))} ${
          //       c.startSemester === "summer"
          //         ? index % 2 === 0
          //           ? "זיממער"
          //           : "ווינטער"
          //         : index % 2 === 0
          //         ? "ווינטער"
          //         : "זיממער"
          //     }`,
          //
          //     subject_id: c.id,
          //     subject: subjectById[c.id ?? 0]?.name_hebrew,
          //     isDeletable: false,
          //   } as CurriculumItem;
          // }) ?? [],
        };
        await formikCurriculum.setValues(curriculumData);

        updateLastChanges(detailsData, roshKinyanData, curriculumData);
      }

      if (pageMode === "insert") {
        const currMonthId = await dispatch<
          number | ReturnType<typeof adminActions.getCurrentMonth>
        >(adminActions.getCurrentMonth());

        if (currMonthId) {
          const yearId = monthById[currMonthId as number].year_id ?? 0;
          const semesterId = monthById[currMonthId as number].semester_id ?? 0;
          const semesterTypeId = semesters[semesterId].name ?? "";

          // Curriculum tab
          const curriculumData: CurriculumFields = {
            ...formikCurriculum.values,
            yearId,
            semesterTypeId,
          };

          await formikCurriculum.setValues(curriculumData);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecd, pageMode, id, _teacher]);

  useEffect(() => {
    (async () => {
      if (formikCurriculum.values.trackId) {
        if (pageMode === "edit") {
          if (!hasDataSet.current) {
            hasDataSet.current = true;

            return;
          }
        }
        const trackId: string = formikCurriculum.values.trackId;

        const result = await dispatch<
          | CurriculumSemester[]
          | null
          | ReturnType<typeof adminActions.getCurriculumValuesByMonth>
        >(adminActions.getCurriculumValuesByMonth(trackId));
        let s: CurriculumItem[] | null = null;
        const startYear = formikCurriculum.values.yearId;
        const startSemester = formikCurriculum.values.semesterTypeId;
        const semester_id =
          semestersList.filter(
            (sl) =>
              sl.year_id === +formikCurriculum.values.yearId &&
              sl.name === formikCurriculum.values.semesterTypeId,
          )?.[0]?.id ?? -1;

        let semesterYear = startYear;

        if (result) {
          s = (result as unknown as CurriculumSemester[]).map(
            ({ subject_id, subject }, index) => {
              if (
                startSemester === "winter" &&
                index !== 0 &&
                index % 2 === 0
              ) {
                semesterYear = (parseInt(semesterYear) + 1).toString();
              } else if (
                startSemester === "summer" &&
                index !== 0 &&
                index % 2 !== 0
              ) {
                semesterYear = (parseInt(semesterYear) + 1).toString();
              }
              return {
                index,
                semester_id: semester_id + index,
                startYear,
                startSemester,
                semesterYear,
                semester: `${gematriya(semesterYear.toString().slice(2))} ${
                  startSemester === "summer"
                    ? index % 2 === 0
                      ? "זיממער"
                      : "ווינטער"
                    : index % 2 === 0
                    ? "ווינטער"
                    : "זיממער"
                }`,
                subject_id,
                subject: subject?.name_hebrew ?? "",
                isDeletable: false,
              } as CurriculumItem;
            },
          );

          if (s?.length > 1) {
            s!.at(-1)!.isDeletable = true;
          }
        }
        await formikCurriculum.setValues({
          ...formikCurriculum.values,
          trackId,
          semesters: s,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formikCurriculum.values.trackId,
    formikCurriculum.values.yearId,
    formikCurriculum.values.semesterTypeId,
  ]);

  const TabComponent = useMemo(() => {
    switch (tab) {
      case "details":
        return <TabDetails formik={formikDetails} />;

      case "roshKinyan":
        return <TabRoshKinyan formik={formikRoshKinyan} />;

      case "curriculum":
        return <TabCurriculum formik={formikCurriculum} />;

      // case "months":
      //   return <TabMonths />;
    }
  }, [tab, formikDetails, formikRoshKinyan, formikCurriculum]);

  const handleTabChange = (event: React.SyntheticEvent, value: TabType) => {
    event.stopPropagation();

    if (pageMode === "edit") {
      setTab(value);
    }
  };

  const handleBtnClick = useCallback(
    async (type: ButtonType, e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      switch (type) {
        case "back":
          switch (tab) {
            case "roshKinyan":
              setTab("details");
              break;

            case "curriculum":
              setTab("roshKinyan");
              break;

            // case "months":
            //   setTab("curriculum");
            //   break;
          }
          break;

        case "next":
          await formikDetails.submitForm();
          break;

        case "continue":
          switch (tab) {
            case "roshKinyan":
              await formikRoshKinyan.submitForm();
              break;

            case "curriculum":
              await formikCurriculum.submitForm();
              break;
          }
          break;

        case "save":
          switch (tab) {
            case "details":
              await formikDetails.submitForm();
              break;

            case "roshKinyan":
              await formikRoshKinyan.submitForm();
              break;

            case "curriculum":
              await formikCurriculum.submitForm();
              break;
          }
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tab],
  );

  const handleBackButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      switch (pageMode) {
        case "edit":
          !!id &&
            toInt(id) &&
            navigate(AdminPages.groupDetails.path.replace(":id", id));
          break;

        case "insert":
          navigate(AdminPages.groups.path);
          break;
      }
    },
    [pageMode, id, navigate],
  );

  const handleModalClose = (data: typeof modal | boolean) => {
    if (data) {
      setTab(data as TabType);
    }

    setModal(null);
  };

  return {
    pageCaption: pageMode === "edit" ? "Edit group" : "Add a group",
    isInsertMode: pageMode === "insert",
    tab,
    modal,
    handleTabChange,
    TabComponent,
    handleBtnClick,
    handleBackButtonClick,
    handleModalClose,
  };
}
