import { CSSProperties } from "react";
import { SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/system";
import { styled } from "@mui/material/styles";

export const topSectionStyle: CSSProperties = {
  marginTop: 0,
  paddingTop: 24,
  height: "85px",
  borderBottom: "1px solid #DDE3EB",
};

export const ErrorMessageStyle = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: "bold",
  marginTop: -8,
  color: "#bb2018",
}));

export const bottomSectionStyle: CSSProperties = {
  marginBottom: 0,
  padding: "24px 55px",
};

export const btnStyle = (error?: boolean): SxProps<Theme> => {
  return {
    my: 3,
    border: `1px solid ${error ? "red" : "transparent"}`,

    "& .MuiButtonGroup-grouped": {
      height: "46px",
      minWidth: "140px",
    },
  };
};
