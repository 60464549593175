import React, { FC, useCallback, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { container, noteStyle, toolBtnStyle } from "./Note.styles";
import {
  adminActions,
  listsSelectors,
  StudentNote,
} from "../../../../../state";
import { DeleteIcon, EditIcon } from "../../../../../components";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import DeleteDialog from "../../../components/Modals/DeleteDialog";
import AddNoteModal from "../../../components/Modals/AddNoteModal";

interface Props {
  note: StudentNote;
  name: string;
}

const Note: FC<Props> = ({
  name,
  note: { note, created_at, created_by, item_id, id },
}) => {
  const dispatch = useDispatch();
  const users = useSelector(listsSelectors.userById);
  const user = users[created_by];
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState(false);

  const date = created_at ? format(new Date(created_at), "MMM dd, yyyy p") : "";

  const handleCloseDeleteModal = useCallback(() => {
    setOpen(false);
  }, []);

  const deleteFunction = () => {
    dispatch(adminActions.deleteNote(item_id, id));
  };
  return (
    <Box sx={container}>
      <Typography variant={"body2"} sx={noteStyle}>
        {note}
      </Typography>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={"10px"}
        gap={2}
      >
        <Typography variant={"body2"}>{user?.name_yiddish ?? ""}</Typography>

        <Typography variant={"body2"}>{date}</Typography>

        <Stack
          direction={"row"}
          gap={"10px"}
          flexGrow={1}
          justifyContent={"flex-end"}
        >
          <IconButton
            color="primary"
            onClick={() => {
              setShow(true);
            }}
            sx={toolBtnStyle}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="primary"
            sx={toolBtnStyle}
            onClick={() => {
              setOpen(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Stack>

      <DeleteDialog
        showModal={open}
        name={`this note - `}
        subject={`"${note}"`}
        onClose={handleCloseDeleteModal}
        deleteFunction={deleteFunction}
      />
      <AddNoteModal
        showModal={show}
        name={name}
        onClose={() => setShow(false)}
        id={id}
        item_id={item_id}
        text={note}
        action={"Edit"}
      />
    </Box>
  );
};

export default Note;
