import { SxProps, Theme } from "@mui/material";

export const container: SxProps<Theme> = {
  minWidth: "580px",
};

export const content: SxProps<Theme> = {
  p: "32px 40px",
};

export const btnSx: SxProps<Theme> = {
  minWidth: "140px",
};

export const captionSx: SxProps<Theme> = {
  color: "#000000",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  lineHeight: "17px",
};

export const gridTestsSx: SxProps<Theme> = {
  "& .MuiDataGrid-main": {
    border: "unset",
  },
};
