import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  listsSelectors,
  MonthlyValues,
  Season,
  uiActions,
} from "../../../../state";
import { gematriya, toInt } from "../../../../lib";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { groupCaptionStyles, monthCaptionStyles } from "./TabMonths.styles";
import { FormikProps } from "formik/dist/types";
import { Fields } from "./schema";
import { FormikHelpers } from "formik";
import { format } from "date-fns";

interface MonthItem {
  id: number;
  name: string;
  group: string;
}

export function useTabMonths() {
  const dispatch = useDispatch();

  const [monthId, setMonthId] = useState<string>("");
  const [months, setMonths] = useState<MonthItem[]>([]);

  const _months = useSelector(listsSelectors.months);
  const _monthById = useSelector(listsSelectors.monthById);
  const _semesterById = useSelector(listsSelectors.semesterById);

  const innerRef = useRef<FormikProps<Fields>>(null);

  const handleSelectMonth = useCallback(
    (id: string, e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setMonthId(id);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (
      {
        max_review_checks,
        max_attendance_checks,
        attendance_bonus,
        review_bonus,
        test_date,
        charge_date,
        start_date,
      }: Fields,
      { setSubmitting }: FormikHelpers<Fields>,
    ) => {
      if (
        !max_review_checks &&
        !max_attendance_checks &&
        !attendance_bonus &&
        !review_bonus &&
        !test_date &&
        !charge_date &&
        !start_date
      ) {
        setSubmitting(false);

        dispatch(
          uiActions.showError("Please, fill at least one of the fields!"),
        );

        return;
      }

      const data: MonthlyValues = {
        attendance_bonus: attendance_bonus ? toInt(attendance_bonus) : null,
        max_attendance_checks: max_attendance_checks
          ? toInt(max_attendance_checks)
          : null,
        max_review_checks: max_review_checks ? toInt(max_review_checks) : null,
        review_bonus: review_bonus ? toInt(review_bonus) : null,
        charge_date: charge_date ? format(charge_date, "yyyy-MM-dd") : null,
        test_date: test_date ? format(test_date, "yyyy-MM-dd") : null,
        start_date: start_date
          ? format(start_date as Date, "yyyy-MM-dd")
          : null,
      };

      !!monthId &&
        (await dispatch<
          | boolean
          | ReturnType<typeof adminActions.updateMonthlyValuesForAllChaburah>
        >(adminActions.updateMonthlyValuesForAllChaburah(+monthId, data)));

      setSubmitting(false);
    },
    [dispatch, monthId],
  );

  const monthSelector = useMemo(() => {
    if (!monthId || !months.length) {
      return null;
    }

    return (
      <List component={"div"} disablePadding={true}>
        {months.map(({ id, name }, index) => {
          if (id) {
            return (
              <ListItemButton
                key={index}
                sx={monthCaptionStyles}
                selected={id === +monthId}
                onClick={handleSelectMonth.bind(null, id.toString())}
              >
                <ListItemText primary={name} />
              </ListItemButton>
            );
          } else {
            return (
              <ListItemText
                key={index}
                primary={name}
                sx={groupCaptionStyles}
              />
            );
          }
        })}
      </List>
    );
  }, [months, monthId, handleSelectMonth]);

  useEffect(() => {
    (async () => {
      const currMonthId: any = await dispatch<
        number | ReturnType<typeof adminActions.getCurrentMonth>
      >(adminActions.getCurrentMonth());

      if (currMonthId) {
        const m = _months
          // Yossi Herz: Please display current month and the upcoming 11 months.
          .filter((m) => m.id >= currMonthId && m.id <= currMonthId + 17)
          .map((m) => {
            const year = m.name === "Tishrei" ? m.year_id - 1 : m.year_id;
            return {
              id: m.id,
              name: m.hebrew,
              group: `${gematriya(year.toString()).slice(2)} ${
                Season[_semesterById[m.semester_id].name] ?? ""
              }`,
            } as MonthItem;
          });

        let currGroup = "";
        const MonthList: MonthItem[] = [];

        for (const monthItem of m) {
          if (monthItem.group !== currGroup) {
            currGroup = monthItem.group;

            MonthList.push({
              id: 0,
              name: currGroup,
              group: "",
            });
          }

          MonthList.push({
            id: monthItem.id,
            name: monthItem.name,
            group: "",
          });
        }

        setMonths(MonthList);
        setMonthId(m[0]?.id?.toString() ?? "");
      }
    })();
  }, [dispatch, _monthById, _months, _semesterById]);

  useEffect(() => {
    const yearId = _monthById[monthId]?.year_id ?? 0;
    const month =
      months.filter((m) => m.id.toString() === monthId)?.[0]?.name ?? "";

    const val = yearId ? `${month} ${gematriya(yearId).slice(2)}` : "";

    innerRef?.current?.resetForm();
    innerRef?.current?.setFieldValue("currMonth", val);
    if (monthId) dispatch(adminActions.getChaburahMonthDates(monthId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthId]);

  return {
    innerRef,
    monthSelector,
    handleSubmit,
  };
}
