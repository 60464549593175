import { FieldProps } from "formik";
import React from "react";
import Select from "react-select";

interface CustomSelectProps extends FieldProps {
  options: any;
  isMulti?: boolean;
  className?: string;
  placeholder?: string;
}

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
}: CustomSelectProps) => {
  const onChange = (option) => {
    form.setFieldValue(
      field.name,
      isMulti ? option.map((item) => item.value) : option.value,
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value?.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as any);
    }
  };
  return (
    <Select
      className={className}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      styles={{
        // Fixes the overlapping problem of the component
        control: (provided) => ({
          ...provided,
          background: "#fff",
          borderColor: "#c4c4c4",
          minHeight: "56px",
          boxShadow: "#c4c4c4",
          "&:hover": {
            borderColor: "#c4c4c4",
            boxShadow: "#c4c4c4",
          },
        }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
    />
  );
};

export default CustomSelect;
