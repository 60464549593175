import React, { FC } from "react";
import TabContainer from "../../components/TabContainer";
import { useTabCurriculum } from "./useTabCurriculum";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GROUP_CURRICULUM_COLUMNS } from "../../../../constants";
import { GroupCurriculum } from "../../../../state";
import { gridStyles } from "./TabCurriculum.styles";

const TabCurriculum: FC = () => {
  const { data } = useTabCurriculum();

  return (
    <TabContainer sx={{ px: 0, height: "calc(100vh - 325px)" }}>
      <DataGridPro
        getRowId={(row: GroupCurriculum) => {
          return row.semester_id;
        }}
        sx={gridStyles}
        hideFooter={true}
        autoHeight={true}
        rows={data ?? []}
        columns={GROUP_CURRICULUM_COLUMNS}
        componentsProps={{
          toolbar: { printOptions: { disableToolbarButton: true } },
        }}
      />
    </TabContainer>
  );
};

export default TabCurriculum;
