import { SxProps, Theme } from "@mui/material";

export const rowToolBtnStyle: SxProps<Theme> = {
  backgroundColor: "transparent",
  opacity: 0.6,

  "& > .MuiSvgIcon-root": {
    color: "#000000",
    height: "19px",
    width: "19px",
  },
};

export const sxText: SxProps<Theme> = {
  color: "#000000",
  fontSize: "13px",
  letterSpacing: 0,
  lineHeight: "16px",
};

export const sxName: SxProps<Theme> = {
  color: "#000000",
  fontSize: "13px",
  letterSpacing: 0,
  lineHeight: "16px",
};

export const sxCheckIcon: SxProps<Theme> = {
  height: "24px",
  width: "24px",
  fill: "#128E56",
};

export const sxCheckText: SxProps<Theme> = {
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "1.25px",
  lineHeight: "16px",
  ml: 1,
  color: "#128E56",
};

export const sxCheckButton: SxProps<Theme> = {
  color: "#0F0190",
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "1.25px",
  lineHeight: "16px",
};

export const sxIcon: SxProps<Theme> = {
  height: "14px",
  width: "14px",
  fill: "#00A202",
};
export const graySxIcon: SxProps<Theme> = {
  height: "14px",
  width: "14px",
  fill: "#8395b2",
};

export const sxIconPoints: SxProps<Theme> = {
  height: "12px",
  width: "12px",
  fill: "#F6C07F",
  mr: "5px",
};

export const sxPoints: SxProps<Theme> = {
  lineHeight: "14px",
  color: "#F0921F",
  display: "inline",
};

export const sxIconGS: SxProps<Theme> = {
  height: "12px",
  width: "12px",
  fill: "#F6C07F",
  mr: "5px",
};

export const sxPointsGS: SxProps<Theme> = {
  lineHeight: "14px",
  color: "#F0921F",
  display: "inline",
};
