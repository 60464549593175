import {
  lightFormat as dateFormatter,
  isValid,
  parseISO,
  compareAsc,
  getDay,
  format,
} from "date-fns";
import { ButtonProps } from "@mui/material/Button/Button";
import { GridColDef } from "@mui/x-data-grid";
import { HDate, Sedra } from "@hebcal/core";
import { get } from "lodash";

export { arrayMoveMutable as arrayMove } from "array-move";

const dateInputKeyFilter = [
  "Backspace",
  "Delete",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

/**
 * Returns `value + addValue` if `value` is truthy, else `defaultValue`.
 * @param {any} value Value to evaluate.
 * @param {any} addValue Added to `value` if `value` not falsey.
 * @param {any} defaultValue The default value to return if `value` is falsey.
 */
export function addIf(value, addValue, defaultValue = "") {
  return value ? value + addValue : defaultValue;
}
/**
 * Returns `value + addValue` if `value` is truthy, else `defaultValue`.
 * @param {any} value Value to evaluate.
 * @param {any} addValue Added to `value` if `value` not falsey.
 * @param {any} defaultValue The default value to return if `value` is falsey.
 */
export function creditCardNumber(number) {
  return number.replace(/[^a-z0-9]+/gi, "").replace(/(.{4})/g, "$1 ");
}
/**
 * Returns an entity list from the given array.
 * @param {any[]} arr
 * @param {string} idField
 * @returns {{ids:number[],entities:{[id:string]:any}}}
 */
export function arrayToEntityList(arr, idField = "id") {
  const list = { ids: [], entities: {} };
  return (
    arr?.reduce((list, item) => {
      const id = item[idField];
      list.ids.push(id);
      list.entities[id] = item;
      return list;
    }, list) || list
  );
}
/** Returns 'yes' if `bool` is true, otherwise 'no'. */
export function boolYesNo(bool) {
  return bool ? "yes" : "no";
}
/**
 * Returns a click handler to show a confirmation then call the given handler.
 * @param {() => void} handler
 * @param {string} message `"Are you sure?"`
 */
export function confirmClickThen(handler, message = "Are you sure?") {
  return (...args) => {
    if (window.confirm(message)) {
      handler(...args);
    }
  };
}
/**
 * Crops the given `text` if it's longer than the `max` length.
 * Optionally adds a suffix to the cropped text.
 * @param {string} text
 * @param {number} max
 * @param {string} [suffix]
 */
export function cropText(text, max, suffix = "...") {
  if (text?.length > max) {
    return text.substr(0, max) + suffix;
  }
  return text;
}
/** Returns todays local date as a string, formatted as a US date by default. */
export function dateTodayLocal(format = "MM/dd/yyyy") {
  return dateFormatter(new Date(), format);
}
/** Returns todays local date as a string, formatted as an ISO date. */
export function dateTodayLocalISO() {
  return dateTodayLocal("yyyy-MM-dd");
}
/** Returns todays UTC date as a string in ISO format. */
export function dateTodayISO() {
  return new Date().toISOString().split("T")[0];
}
/**
 * Simple debounce function
 * @param {Function} fn Function to call after the `delay`.
 * @param {number} delay Time in milliseconds.
 */
export function debounce(fn, delay) {
  let timeoutId;
  return (...args) => {
    clearInterval(timeoutId);
    timeoutId = setTimeout(fn, delay, ...args);
  };
}
/**
 * Converts a decimal percentage to an integer percentage.
 * @param {number} value
 */
export function decimalToPercent(value) {
  return parseFloat(value) * 100;
}
/** An empty function. */
export function emptyHandler() {}
/**
 * Allows only the arrow keys to change a native date input.
 * @param {React.KeyboardEvent<HTMLInputElement>} e
 */
export function filterDateInputKeys(e) {
  if (dateInputKeyFilter.includes(e.key)) {
    e.preventDefault();
    e.stopPropagation();
  }
}
/**
 * @template T
 * @param {T[]} items
 * @param {any} id
 */
export function findById(items, id) {
  return items.find((it) => it.id === id);
}
/**
 * @template T
 * @param {T[]} items
 * @param {any} uid
 */
export function findByUid(items, uid) {
  return items.find((it) => it.uid === uid);
}
/**
 * @template T
 * @param {T[]} items
 * @param {string} fieldName
 * @param {any} value
 */
export function findByField(items, fieldName, value) {
  return items.find((it) => it[fieldName] === value);
}
/**
 * Finds the earliest ISO formatted date property in an object array.
 * @param {Record<string,string>[]} objects
 * @param {string} propName
 */
export function findLowestISODateProp(objects, propName) {
  const sorted = objects
    .map((it) => {
      const value = it[propName];
      return {
        value,
        valueAsDate: parseISO(value),
      };
    })
    .sort((a, b) => compareAsc(a.valueAsDate, b.valueAsDate));
  return sorted[0]?.value;
}
/** Flattens nested objects and arrays into a single dimension object.
 * See https://stackoverflow.com/questions/54896928/flattening-the-nested-object-in-javascript
 */
export function flatten(obj, prefix = "", res = {}, addPrefix = true) {
  if (typeof obj !== "object" || !obj) {
    return obj;
  }
  return Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`;
    if (typeof val === "object" && val) {
      flatten(val, addPrefix ? `${k}.` : "", r, addPrefix);
    } else {
      res[k] = val;
    }
    return r;
  }, res);
}
export const flattenWithoutPrefix = (obj) => flatten(obj, "", {}, false);

/**
 * Formats `amount` in standard USD format.
 * - This was used instead of `Intl.NumberFormat` since the polyfill for that is
 * huge and we don't want to use a third-party polyfill.io service for a
 * financial app.
 * - See https://stackoverflow.com/a/149099/16387
 * - Removed decimal option.
 * - Added dollar sign option.
 * - Converted options to a single object argument.
 * @param {number} amount
 * @param {{decimalCount:number,decimalIfNotWhole:boolean,dollarSign:string,thousands:string}} [options]
 * @param {number} [options.decimalCount] Number of decimals to display. (`2`)
 * @param {boolean} [options.decimalIfNotWhole] If should only show decimal if not a whole dollar amount
 * @param {string} [options.dollarSign] Dollar sign to display. (`"$"`)
 * @param {string} [options.thousands] Thousands separator. (`","`)
 */
export function formatDecimal(amount: number) {
  return (+amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}
export function formatMoney(amount: number, currency) {
  return `${getCurrencySymbol(currency)}${formatDecimal(amount)}`;
}

/**
 * Formats the given `date` to the given `format` (`"MM/dd/yyyy"`).
 * **WARNING: If you provide `date` as an ISO string without a timezone
 * specifier, this function will convert that date to UTC time.**
 */
export function formatDate(date, format = "MM/dd/yyyy") {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  if (!isValid(d)) {
    return "";
  }
  return dateFormatter(d, format);
}
/** Formats the given `date` to ISO-8601 date format.
 * **WARNING: If you provide `date` as an ISO string without a timezone
 * specifier, this function will convert that date to UTC time.**
 */
export function formatDateISO(date, format = "yyyy-MM-dd") {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  if (!isValid(d)) {
    return "";
  }
  return dateFormatter(d, format);
}
export function formatDateLong(date, format = "LLLL dd, yyyy") {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  if (!isValid(d)) {
    return "";
  }
  return dateFormatter(d, format);
}
/**
 * **WARNING: If you provide `date` as an ISO string without a timezone
 * specifier, this function will convert that date to UTC time.**
 */
export function formatDateTime(datetime, format = "MM/dd/yyyy h:mm aa") {
  return formatDate(datetime, format);
}

export function formatFullName({ firstName, middleName, lastName }) {
  if (middleName) {
    return `${firstName} ${middleName} ${lastName}`;
  }
  return `${firstName} ${lastName}`;
}
export function formatFirstLastName({ firstName, lastName }) {
  return `${firstName} ${lastName}`;
}
export function formatFirstLastNameInitials({ firstName, lastName }) {
  return (
    firstName.substr(0, 1).toUpperCase() + lastName.substr(0, 1).toUpperCase()
  );
}

export function formatHours(hours, suffix = "") {
  return (hours || 0).toFixed(2) + suffix;
  // return (hours || 0).toString() + suffix;
}
/**
 * Formats an ISO formatted `date` string (`"yyyy-mm-dd"`) as a local US date
 * (`"MM/dd/yyyy"`) without changing timezone, unlike `formatDate`.
 * @param {string} [isoDate]
 */
export function formatISODate(isoDate) {
  if (!isoDate || !isoDate.split) {
    return "";
  }
  const parts =
    // Split by "T" first, in case there is a time following the date.
    isoDate
      .split("T")[0]
      // Split by dash to get date parts.
      .split("-");
  if (parts.length < 3 || parts[0] === "0000") {
    return "";
  }
  return `${parts[1]}/${parts[2]}/${parts[0]}`;
}

export function formatTimeForInput(datetime, format = "HH:mm") {
  return formatDate(datetime, format);
}
/**
 * Returns the ordinal indicator text (e.g. 1st, 2nd, etc) for any number.
 * See https://english.stackexchange.com/questions/192804
 * @param {number} [num]
 */
export function formatOrdinal(num) {
  return `${num}${
    num % 10 === 1 && num % 100 !== 11
      ? "st"
      : num % 10 === 2 && num % 100 !== 12
      ? "nd"
      : num % 10 === 3 && num % 100 !== 13
      ? "rd"
      : "th"
  }`;
}
export function formatPercent(value, options) {
  let decimalCount = 2;
  if (options) {
    if (options.decimalCount) decimalCount = options.decimalCount;
  }
  if (isNaN(value)) {
    return "";
  }
  return `${value.toFixed(decimalCount)}%`;
} /** @param {string} value */
export function getPhoneNumbersOnly(value) {
  return ("" + (value || "")).replace(/\D/g, "");
}
/** @param {string} value The phone number. */
export function formatPhone(value) {
  const cleaned = getPhoneNumbersOnly(value);
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function normalizePhone(country: number, number: string, type: string) {
  if (type === "home") {
    let formatted: string = "";
    let error = false;
    const numeric = number.match(/\d+/g)?.join("");
    if (!numeric) error = true;
    else if (country === 1 || country === 2) {
      formatted = numeric.replace(/^[0-1]*/g, "");
      if (formatted.length !== 10) error = true;
    } else if ([3, 4, 5, 6, 7].includes(country)) {
      formatted = number[0] === "0" ? number : `0${number}`;
      if (country === 3 && formatted.length !== 11) error = true;
      else if (country === 4 && formatted.length !== 9) error = true;
      else if (country === 6 && formatted.length !== 9) error = true;
      else if (country === 5 && (formatted.length < 8 || formatted.length > 10))
        error = true;
    }
    if (error || !formatted) {
      return false;
    }

    return formatted;
  } else if (type === "cell") {
    let formatted: string = "";
    let error = false;
    const numeric = number.match(/\d+/g)?.join("");
    if (!numeric) error = true;
    else if (country === 1 || country === 2) {
      formatted = numeric.replace(/^[0-1]*/g, "");
      if (formatted.length !== 10) error = true;
    } else if ([3, 4, 5, 6, 7].includes(country)) {
      formatted = number[0] === "0" ? number : `0${number}`;
      if (country === 3 && formatted.length !== 11) error = true;
      else if (country === 4 && formatted.length !== 9) error = true;
      else if ((country === 5 || country === 6) && formatted.length !== 10)
        error = true;
    }
    if (error || !formatted) {
      return false;
    }

    return formatted;
  }
}

/**
 * Returns true if the given `date` is a valid `Date` object.
 * @param {Date} date
 */
export function isDateValid(d) {
  return d instanceof Date && !isNaN(d.getTime());
}
/** True if the given `str` is 'yes'. (Case insensitive) */
export function isYes(str) {
  return ("" + str).toLowerCase() === "yes" ? true : false;
}
/**
 * Converts the given value to lower camel case.
 * @param {string} value
 */
export function lowerCamelCase(value) {
  if (!value) {
    return "";
  }
  return value.substr(0, 1).toLowerCase() + value.substr(1);
}
/**
 * Converts the column name to a title - remove underscores & make first letter uppercase.
 * @param {string} value
 */
export function columnToTitle(column) {
  if (!column) {
    return "";
  }
  return column
    .split("_")
    .map((i) => i.substr(0, 1).toUpperCase() + i.substr(1))
    .join(" ");
}
/**
 * Returns an array of values from a map of values, by key.
 * The opposite of `arrayToObjById`.
 * @param {{ [key:string]:any }} obj Map of values by key.
 */
export function mapToArray(obj) {
  return Object.keys(obj).map((key) => obj[key]);
}
/**
 * Returns the given string value with numbers masked by an asterisk, if
 * `shouldMask` is true.
 * @param {boolean} shouldMask
 * @param {string} value
 */
export function maskNumbersIf(shouldMask, value) {
  return shouldMask ? ("" + value).replace(/[0-9]/g, "*") : value;
}
/**
 * Masks all characters up to the last 4.
 * @param {string} value
 * @param {number} [maskLen] Optional number of mask characters. If passed, this
 * number will be used instead of detecting how many characters came before the
 * last 4.
 */
export function maskUpToLast4(value, maskLen) {
  value = "" + value;
  const lengthBeforeLast4 = Math.max(0, value.length - 4);
  const last4 = value.substr(lengthBeforeLast4);
  const mask = "*".repeat(maskLen || lengthBeforeLast4);
  return mask + last4;
}

export function replaceNullProps(props, replace = "") {
  const newProps = {};
  Object.keys(props).forEach((prop) => {
    const value = props[prop];
    newProps[prop] = value === null ? replace : value;
  });
  return newProps;
}
/** Function that simply returns it's given argument. */
export function returnArg(arg) {
  return arg;
}
/** Function that returns true. */
export function returnTrue() {
  return true;
}
/**
 * Returns a CSS `hsl` color string hashed from the given `str`.
 * @param {string} str The input string.
 * @param {number} saturation Percentage of saturation (`0 - 100`).
 * Use a value around `30` for pastels.
 * @param {number} lightness Percentage of lightness (`0 - 100`).
 * Use a value around `80` for pastels.
 *
 * @see https://medium.com/%40pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
 * @see https://codepen.io/sergiopedercini/pen/RLJYLj/
 */
export function stringToHslColor(str, saturation, lightness) {
  const { length } = str || "";
  let hash = 0;
  for (let i = 0; i < length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = hash % 360;
  return `hsl(${color},${saturation}%,${lightness}%)`;
}
/**
 * Returns a pastel CSS `hsl` color string hashed from the given `str`.
 * @param {string} str The input string.
 * @see `stringToHslColor`
 */
export function stringToHslPastel(str) {
  return stringToHslColor(str, 30, 80);
}
/**
 * Asynchronously waits for the given amount of time in `ms`.
 * @param {number} [ms] Time to wait, in milliseconds.
 */
export function timeoutAsync(ms = 0) {
  return new Promise((resolve, _reject) => {
    setTimeout(resolve, ms);
  });
}
/**
 * Returns the first `collection` property value that matches `predicate`.
 * @template TCollection
 * @param {TCollection} collection
 * @param {(Pick<TCollection, keyof TCollection>)=>boolean} predicate
 * @returns {Pick<TCollection, keyof TCollection>}
 */
export function find(collection, predicate) {
  const key = Object.keys(collection).find((key) => predicate(collection[key]));
  return key !== undefined ? collection[key] : undefined;
}
/**
 * Maps over `obj` keys and returns values from the given `map` function.
 * @template T
 * @template R
 * @param {Record<string,T>} obj
 * @param {(value:T,key:string,obj:Record<string,T>)=>R} map
 * @returns {R}
 */
export function mapValues(obj, map) {
  return Object.keys(obj).map((key) => map(obj[key], key, obj));
}
/**
 * Reduce function for objects. Transforms `obj` to a new `accumulator` object
 * using the given `map` function.
 * @template T
 * @template {T} R
 * @param {Record<string,T>} obj
 * @param {(value:T,key:string,obj:Record<string,T>)=>R} map
 * @param {Record<string,T>} [accumulator]
 * @returns {Record<string,R>}
 */
export function transform(obj, map, accumulator = {}) {
  return Object.keys(obj).reduce((accumulator, key) => {
    accumulator[key] = map(obj[key], key, obj);
    return accumulator;
  }, accumulator);
}
/**
 * Converts `array` to a new object keyed by the given `key`.
 * @example reduceBy([{id:1},{id:2}],"id") // returns { 1:{id:1}, 2:{id:2} }
 * @example reduceBy(["a", "b"]) // returns { 0: "a", 1: "b" }
 * @template T
 * @param {T[]} [array] An array of values to convert.
 * @param {keyof T} [key] For an array of objects, key to use. If ommited, the
 * array index is used as the key.
 * @param {Record<string,T>} [obj] Optional object to convert into.
 * @returns {Record<string,T>}
 */
export function reduceBy(array, key, obj = {}) {
  if (!array) {
    return [];
  }
  return array.reduce((obj, it, i) => {
    const prop = key !== undefined ? it[key] : i;
    obj[prop] = it;
    return obj;
  }, obj);
}
export function shallowEqualsObj(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    const key = aKeys[i];

    if (
      objA[key] !== objB[key] ||
      !Object.prototype.hasOwnProperty.call(objB, key)
    ) {
      return false;
    }
  }

  return true;
}
/** Returns true if the given object, array or string value is empty. */
export function isEmpty(value) {
  return !value || Object.keys(value).length === 0;
}
/**
 * Returns true if any of the given object, array or string values are empty.
 */
export function allEmpty(...values) {
  const { length } = values;
  for (let i = 0; i < length; i++) {
    const value = values[i];
    if (!isEmpty(value)) {
      return false;
    }
  }
  return true;
}
export function getCurrencySymbol(currency: string) {
  if (currency === "GBP") {
    return "£";
  } else return "$";
}
export function numberWithCommas(num) {
  if (num > 0) {
    const numb = Math.abs(num).toFixed(2);
    return numb.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (num < 0) {
    const numb = Math.abs(num).toFixed(2);
    return numb.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0.00";
  }
}
export function numberWithCommasNoZeros(num) {
  if (num > 0) {
    const numb = Math.abs(num).toFixed(0);
    return numb.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0";
  }
}
export function numsWithCommasWithNeg(num) {
  const numb = Math.abs(num).toFixed(0);
  const formatted = numb.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${num < 0 ? "-" : ""}${formatted}`;
}
export function formatNumber(num) {
  if (num === 0) {
    return num;
  }
  if (!num) {
    return "";
  }
  return numsWithCommasWithNeg(num);
}
export const toFloat = (value: unknown, defaultValue: number = 0): number => {
  const result = parseFloat(value as string);

  return Number.isNaN(result) ? defaultValue : result;
};
export function negStringToPositiveNum(string) {
  const num = string.replace(/[^0-9.]/g, "");
  return parseFloat(num) || 0;
}
export const toInt = (value: unknown, defaultValue: number = 0): number => {
  const result = parseInt(value as string, 10);

  return Number.isNaN(result) ? defaultValue : result;
};

const GERESH = "׳";
const GERSHAYIM = "״";

function num2heb(num: number) {
  switch (num) {
    case 1:
      return "א";

    case 2:
      return "ב";

    case 3:
      return "ג";

    case 4:
      return "ד";

    case 5:
      return "ה";

    case 6:
      return "ו";

    case 7:
      return "ז";

    case 8:
      return "ח";

    case 9:
      return "ט";

    case 10:
      return "י";

    case 20:
      return "כ";

    case 30:
      return "ל";

    case 40:
      return "מ";

    case 50:
      return "נ";

    case 60:
      return "ס";

    case 70:
      return "ע";

    case 80:
      return "פ";

    case 90:
      return "צ";

    case 100:
      return "ק";

    case 200:
      return "ר";

    case 300:
      return "ש";

    case 400:
      return "ת";

    default:
      return "*INVALID*";
  }
}

function num2digits(num: number) {
  const digits: number[] = [];

  while (num > 0) {
    if (num === 15 || num === 16) {
      digits.push(9);
      digits.push(num - 9);
      break;
    }

    let incr = 100;
    let i;

    for (i = 400; i > num; i -= incr) {
      if (i === incr) {
        incr = incr / 10;
      }
    }

    digits.push(i);
    num -= i;
  }

  return digits;
}

export function gematriya(n: string) {
  const num = parseInt(n, 10);

  if (!num) {
    throw new TypeError(`invalid parameter to gematriya ${n}`);
  }

  let str = "";
  const thousands = Math.floor(num / 1000);

  if (thousands > 0 && thousands !== 5) {
    const tdigits = num2digits(thousands);

    for (let i = 0; i < tdigits.length; i++) {
      str += num2heb(tdigits[i]);
    }

    str += GERESH;
  }

  const digits = num2digits(num % 1000);

  if (digits.length === 1) {
    return str + num2heb(digits[0]) + GERESH;
  }

  for (let i = 0; i < digits.length; i++) {
    if (i + 1 === digits.length) {
      str += GERSHAYIM;
    }

    str += num2heb(digits[i]);
  }

  return str;
}

export function capitalizeFirstLetterOfEachWOrd(string) {
  if (!string) return null;
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
export const getButtonVariant = (isTrue: boolean): ButtonProps["variant"] =>
  isTrue ? "contained" : "outlined";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function labelForForms(string) {
  return capitalizeFirstLetter(string.replace(/_/g, " "));
}

export function optionsGenerator(arr, key, name?) {
  if (arr?.length) {
    const newArray = arr.map((item) => ({
      name: name ? item[name] : item.name ? item.name : item[key],
      id: item[key],
    }));
    return newArray;
  } else return arr;
}
export function optionsYearGenerator(arr, key, name?) {
  if (arr?.length) {
    const newArray = arr.map((item) => ({
      name: name ? gematriya(item[name]) : gematriya(item[key]),
      id: item[key],
    }));
    return newArray;
  } else return arr;
}

export function optionsZmanGenerator(_zman, arr, key) {
  if (arr?.length) {
    const newArray = arr.map((item) => ({
      name:
        _zman[item[key]].name === "winter"
          ? "ווינטער" + " - " + gematriya(_zman[item[key]].year_id).slice(2)
          : "זיממער" + " - " + gematriya(_zman[item[key]].year_id).slice(2),
      id: item[key],
    }));
    return newArray;
  } else return arr;
}
export function labelValueMonthGenerator(_months, arr, key) {
  if (arr?.length) {
    const newArray = arr.map((item) => ({
      label:
        _months[item[key]].hebrew +
        " - " +
        gematriya(_months[item[key]].year_id).slice(2),
      value: item[key],
    }));
    return newArray;
  } else return arr;
}

export const statesAsOptions = [
  { name: "New York", id: "NY" },
  { name: "New Jersey", id: "NJ" },
  { name: "Ontario", id: "ON" },
  { name: "Quebec", id: "QC" },
  { name: "Alabama", id: "AL" },
  { name: "Alaska", id: "AK" },
  { name: "Arizona", id: "AZ" },
  { name: "Arkansas", id: "AR" },
  { name: "California", id: "CA" },
  { name: "Colorado", id: "CO" },
  { name: "Connecticut", id: "CT" },
  { name: "Delaware", id: "DE" },
  { name: "Florida", id: "FL" },
  { name: "Georgia", id: "GA" },
  { name: "Hawaii", id: "HI" },
  { name: "Idaho", id: "ID" },
  { name: "Illinois", id: "IL" },
  { name: "Indiana", id: "IN" },
  { name: "Iowa", id: "IA" },
  { name: "Kansas", id: "KS" },
  { name: "Kentucky", id: "KY" },
  { name: "Louisiana", id: "LA" },
  { name: "Maine", id: "ME" },
  { name: "Maryland", id: "MD" },
  { name: "Massachusetts", id: "MA" },
  { name: "Michigan", id: "MI" },
  { name: "Minnesota", id: "MN" },
  { name: "Mississippi", id: "MS" },
  { name: "Missouri", id: "MO" },
  { name: "Montana", id: "MT" },
  { name: "Nebraska", id: "NE" },
  { name: "Nevada", id: "NV" },
  { name: "New Hampshire", id: "NH" },
  { name: "New Mexico", id: "NM" },
  { name: "North Carolina", id: "NC" },
  { name: "North Dakota", id: "ND" },
  { name: "Ohio", id: "OH" },
  { name: "Oklahoma", id: "OK" },
  { name: "Oregon", id: "OR" },
  { name: "Pennsylvania", id: "PA" },
  { name: "Rhode Island", id: "RI" },
  { name: "South Carolina", id: "SC" },
  { name: "South Dakota", id: "SD" },
  { name: "Tennessee", id: "TN" },
  { name: "Texas", id: "TX" },
  { name: "Utah", id: "UT" },
  { name: "Vermont", id: "VT" },
  { name: "Virginia", id: "VA" },
  { name: "Washington", id: "WA" },
  { name: "West Virginia", id: "WV" },
  { name: "Wisconsin", id: "WI" },
  { name: "Wyoming", id: "WY" },
  { name: "Alberta", id: "AB" },
  { name: "British Columbia", id: "BC" },
  { name: "Manitoba", id: "MB" },
  { name: "New Brunswick", id: "NB" },
  { name: "Newfoundland", id: "NL" },
  { name: "Northwest Territories", id: "NT" },
  { name: "Nova Scotia", id: "NS" },
  { name: "Nunavut", id: "NU" },
  { name: "Prince Edward Island", id: "PE" },
  { name: "Saskatchewan", id: "SK" },
  { name: "Yukon", id: "YT" },
];

export const transactionTypeLookup = {
  1: "payment_cash",
  2: "payment_credit",
  3: "reversed_payment",
  4: "invoice",
  5: "refund",
  6: "from_prepayment",
  7: "applied_payment",
  8: "failed_payment",
  9: "failed_refund",
  10: "cash_prepayment",
  11: "credit_prepayment",
  12: "credit",
};

export const deepCopy = (json: Record<string, any>) =>
  JSON.parse(JSON.stringify(json));

export const arrayOfObjToNameList = (array) => {
  if (!Array.isArray(array)) return [];
  else {
    return array.map((object) => object.name);
  }
};
export const arrayOfObjToYiddishNameList = (array) => {
  if (!Array.isArray(array)) return [];
  else {
    return array.map((object) => object.name_yiddish || object.name);
  }
};

export function googlePlaceToAddress(place) {
  if (!place || !place.address_components) return null;
  const {
    address_components: addressComponents,
    formatted_address: formattedAddress,
  } = place;
  const location: any = {
    googleAddressObject: addressComponents,
    formattedAddress,
  };

  let index = 0;
  while (!location.city && index < 3) {
    parseAddressComponents(addressComponents, location, index);
    index++;
  }

  delete location.locality;
  delete location.neighborhood;
  delete location.sublocality;

  return location;
}

function parseAddressComponents(addressComponents, location, index) {
  addressComponents.forEach((c) => {
    switch (c.types[index]) {
      case "street_number":
        location.address = c.short_name;
        break;
      case "route":
        location.address += `${" " + c.short_name}`;
        break;
      case "administrative_area_level_1": //  Note some countries don't have states
        location.state = c.short_name;
        break;
      case "postal_code":
        location.zip = c.short_name;
        break;
      case "country":
        location.country =
          c.long_name === "United Kingdom" ? "England" : c.long_name;
        break;

      // the rest of the cases deal with figuring out the city name

      case "locality":
        location.locality = c.short_name;
        location.city = c.short_name;
        break;
      case "neighborhood":
        location.neighborhood = c.short_name;
        location.city = !location.locality ? c.short_name : location.city;
        break;
      case "sublocality":
        location.sublocality = c.short_name;
        location.city =
          !location.neighborhood && !location.locality
            ? c.short_name
            : location.city;
        break;
      case "sublocality_level_1": // e.g., Brooklyn
        location.sublocality_level_1 = c.short_name;
        location.city =
          !location.locality && !location.sublocality
            ? c.short_name
            : location.city;
        break;
      case "administrative_area_level_3":
        location.city =
          !location.neighborhood && !location.locality && !location.sublocality
            ? c.short_name
            : location.city;
        break;
      case "postal_town":
        location.city =
          !location.neighborhood && !location.locality && !location.sublocality
            ? c.short_name
            : location.city;
        break;

      default:
    }
  });
  return location;
}
export function makeuuid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export function getUploadFile(file) {
  const guid = makeuuid(36);
  const name = `${guid}-${file.path ?? file.name}`;

  return new File([file], name);
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function generateVisibleColumnsModel(
  allColumns: GridColDef<any, any, any>[],
  hiddenColumnNames: string[],
) {
  const model = {};
  allColumns.forEach((column) => {
    model[column.field] = !hiddenColumnNames.some((h) => h === column.field);
  });
  return model;
}

export const yiddishDays = {
  "0": "זינטאג",
  "1": "מאנטאג",
  "2": "דינסטאג",
  "3": "מיטוואך",
  "4": "דאנערשטאג",
  "5": "פרייטאג",
  "6": "שבת",
};

export function getYiddishDate(englishDate: Date) {
  const date = new Date(englishDate);
  const hd = new HDate(date);
  const weekday = getDay(date);
  const yearId = hd.getFullYear();
  const sedra = new Sedra(yearId, false);
  const parsha = sedra.getString(hd, "he-x-NoNikud");
  const time = date.toLocaleTimeString("en-US").replace(":00 ", " ");
  const formattedDate = format(date, "MM/dd/yyyy");
  return { time, date: formattedDate, weekday, parsha };
}
export function getFormattedYiddishDate(date: Date, yiddishOnly = false) {
  const { weekday, parsha } = getYiddishDate(date);
  return `${
    !yiddishOnly ? format(date, "MM/dd/yyyy") + " - " : ""
  }${parsha} - ${yiddishDays[weekday]}`;
}

export function getYiddishDateString(dateString: string, yiddishOnly = false) {
  return getFormattedYiddishDate(
    new Date(dateString.replaceAll("-", "/")),
    yiddishOnly,
  );
}

/**
 *
 * @param dateString
 *
 * @returns empty string for undefined or date formatted as such:
 *  10:42AM מיטוואך מטות-מסעי
 */

export function formattedYiddishDateTimeString(dateString) {
  if (!dateString) {
    return "";
  }

  const { weekday, parsha, time } = getYiddishDate(dateString);

  return `${time.replace(/:\d\d\s/, "")} ${
    yiddishDays[weekday]
  } ${parsha.replace("פרשת ", "")}`;
}

export const assert = (object: Object, fields: string[]) => {
  let missingFields = false;
  fields.forEach((f) => {
    const prop = get(object, f);
    if (prop === undefined || prop === null) {
      missingFields = true;
      console.error(`No ${f}`);
    }
  });
  return !missingFields;
};
// I'm sure there has to be a better way for this but this task
// didn't have a budget for research
export function getHebrewMonth(hd: HDate) {
  return hd
    .render("he-x-NoNikud")
    .toString()
    .split(",")?.[0]
    .match(/[\u0590-\u05FF].*[\u0590-\u05FF]/)?.[0];
}
export function getHebrewBirthday(date) {
  const hd = new HDate(date);
  const year = hd.getFullYear();
  const day = hd.getDate();
  const month = getHebrewMonth(hd);
  return { year, month, day };
}
