import { Box, Grid, Typography } from "@mui/material";
import {
  listsActions,
  listsSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { FormikInput, FormikSelect } from "../../../components";
import { AddressDetails } from "./AddressDetails";
import { useEffect } from "react";

export const CustomerDetails = ({ formik }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listsActions.getCountries());
  }, [dispatch]);
  const countryCodes = useSelector(listsSelectors.optionsCountryCodes);
  return (
    <>
      <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>
        Customer information
      </Typography>
      <Typography fontSize={16} fontWeight="bold">
        Customer details
      </Typography>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <FormikInput
            sx={{ marginTop: 0 }}
            name="first_name_yiddish"
            label="*טאטעס ערשטע נאמען"
            size="small"
            formik={formik}
          />
        </Grid>
        <Grid item md={3}>
          <FormikInput
            name="last_name_yiddish"
            label="*טאטעס לעצטע נאמען"
            size="small"
            formik={formik}
          />
        </Grid>
        <Grid item md={3}>
          <FormikInput
            name="first_name"
            size="small"
            label="Father's first name"
            formik={formik}
          />
        </Grid>
        <Grid item md={3}>
          <FormikInput
            name="last_name"
            size="small"
            label="Father's last name*"
            formik={formik}
          />
        </Grid>
      </Grid>
      <Typography fontSize={16} fontWeight="bold">
        Contact
      </Typography>
      <Grid container spacing={4}>
        <Grid item md={4.5}>
          <Grid container>
            <Grid item md={3}>
              <FormikSelect
                name="home_country_code_id"
                label={"Code"}
                options={countryCodes}
                textFieldProps={{ size: "small" }}
                formik={formik}
              />
            </Grid>
            <Grid item md={9}>
              <FormikInput
                name="home_phone"
                size="small"
                label="Home phone*"
                formik={formik}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4.5}>
          <Grid container>
            <Grid item md={3}>
              <FormikSelect
                name="mobile_country_code_id"
                label={"Code"}
                options={countryCodes}
                textFieldProps={{
                  size: "small",
                }}
                formik={formik}
              />
            </Grid>
            <Grid item md={9}>
              <FormikInput
                name="mobile_phone"
                label="Cell phone*"
                size="small"
                formik={formik}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={3}>
          <FormikInput
            name="email"
            size="small"
            label="Email*"
            formik={formik}
          />
        </Grid>
      </Grid>
      <AddressDetails formik={formik} />
      <Box fontSize={16} fontWeight="bold">
        <Box display="inline-flex">?</Box>וויאזוי האסטו געהערט פון קנין משנה
      </Box>
      <Grid container spacing={4}>
        <Grid item md={4}>
          {" "}
          <FormikInput
            name="referring_name"
            label="נאמען"
            size="small"
            formik={formik}
          />
        </Grid>
        <Grid item md={4}>
          {" "}
          <FormikInput
            name="referring_phone"
            label="טעלעפאן נומבער"
            size="small"
            formik={formik}
          />
        </Grid>
      </Grid>
    </>
  );
};
