import React, { FC, useEffect, useState } from "react";
import { arrayToEntityList, gematriya } from "../../../../lib";
import {
  adminSelectors,
  listsSelectors,
  StudentReward,
  StudentSemesterHistoryMonth,
} from "../../../../state";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { STUDENT_SEMESTER_HISTORY_COLUMNS } from "../../../../constants";
import SemesterView from "./SemesterView";
import { dataGridFilterStyles } from "../../../../themes";

interface SemesterHistory {
  history: StudentSemesterHistoryMonth[];
}

interface Props {
  setShowSemesterView: React.Dispatch<React.SetStateAction<boolean>>;
  semesterName: string;
}

const HistoryDetailView: FC<Props> = ({
  setShowSemesterView,
  semesterName,
}: Props) => {
  const [semesterHistory, setSemesterHistory] =
    useState<SemesterHistory | null>(null);

  const studentHistoryDetails = useSelector(
    adminSelectors.studentHistoryDetails,
  );

  const months = useSelector(listsSelectors.monthById);

  useEffect(() => {
    const sm = arrayToEntityList(studentHistoryDetails, "month_id").entities;

    const shm: StudentSemesterHistoryMonth[] = studentHistoryDetails.map(
      (month) => {
        const m: StudentSemesterHistoryMonth =
          {} as StudentSemesterHistoryMonth;

        m.id = month.month_id;
        m.gathering = month.gathering;
        m.monthly_note = month.monthly_note;
        m.check_in = month.check_in;
        m.month = `${gematriya(months[m.id]?.year_id)} ${months[m.id]?.hebrew}`;
        m.subjectName = month?.student_chaburah_months?.chaburah?.name ?? "";
        m.monthDescription = month?.student_chaburah_months?.description ?? "";
        m.price = sm[m.id]?.price ? Math.abs(sm[m.id].price) : "";
        m.active = !!month?.student_chaburah_months;
        m.attendance = null;
        m.review = null;
        m.test = null;
        m.bonus = null;
        m.total = 0;
        if (m.active && sm[m.id]?.history_points) {
          sm[m.id]?.history_points.forEach((pt) => {
            switch (pt.type as StudentReward["type"]) {
              case "attendance":
                m.attendance += pt.points;
                m.total += pt.points;
                break;
              case "attendance_bonus":
                m.attendance += pt.points;
                m.total += pt.points;
                break;
              case "review":
                m.review += pt.points;
                m.total += pt.points;
                break;
              case "review_bonus":
                m.review += pt.points;
                m.total += pt.points;
                break;
              case "test":
                m.test += pt.points;
                m.total += pt.points;
                break;
              case "test_bonus":
                m.test += pt.points;
                m.total += pt.points;
                break;
              case "bonus":
                m.bonus += pt.points;
                m.total += pt.points;
                break;
              case "redemption":
                m.total += pt.points;
                break;
            }
          });
        }

        return m;
      },
    );

    setSemesterHistory({
      history: shm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentHistoryDetails]);

  const handleBackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowSemesterView(false);
    setSemesterHistory(null);
  };

  return (
    <>
      <SemesterView semester={semesterName} onBackBtnClick={handleBackClick} />

      <Box>
        <DataGridPro
          sx={{
            "& .MuiDataGrid-main": {
              borderBottom: "1px solid #DDE3EB",
              borderRadius: 2,
            },
          }}
          hideFooter={true}
          rows={semesterHistory?.history ?? []}
          autoHeight={true}
          columns={STUDENT_SEMESTER_HISTORY_COLUMNS}
          initialState={{
            columns: { columnVisibilityModel: { price: false } },
            sorting: {
              sortModel: [{ field: "fullName", sort: "asc" }],
            },
          }}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
            panel: {
              sx: {
                ...dataGridFilterStyles,
              },
            },
          }}
        />
      </Box>
    </>
  );
};

export default HistoryDetailView;
