import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { container } from "./BarMitzva.styles";

interface Props {
  str?: string;
}

const BarMitzva: FC<Props> = ({ str }) => {
  return (
    <Box sx={container}>
      <Typography variant={"body1"}>
        Bar Mitzva will be {str || "— — —"}
      </Typography>
    </Box>
  );
};

export default BarMitzva;
