import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Transition } from "./Transition";

interface Props {
  showModal: boolean;
  name: any;
  subject: string;
  onClose: () => void;
  deleteFunction: () => void;
}

const DeleteDialog: FC<Props> = ({
  showModal,
  name,
  subject,
  onClose,
  deleteFunction,
}) => {
  return (
    <Dialog
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Are you sure?"}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {name} {subject}?
        </DialogContentText>
      </DialogContent>

      <Divider variant="middle" sx={{ border: "1px solid #DDE3EB", mb: 2 }} />

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteFunction();
            onClose();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
