import { styled } from "@mui/styles";
export const Iframe = styled("iframe")(({ props }: any) => ({
  maxHeight: 58,
  borderRadius: 4,
  border: props.invalid ? "1px solid red" : "none",
  boxSizing: "border-box",
  outline: "none",
  width: "100%",
  marginTop: 20,
}));
