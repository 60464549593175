import { useEffect, useState } from "react";
import {
  adminSelectors,
  customerActions,
  customerSelectors,
  useDispatch,
  useSelector,
} from "../../../state";

export default function useBillingInfo(formikBilling) {
  const student = useSelector(adminSelectors.student);

  const billingInfo = useSelector(customerSelectors.student_billing);
  const upcomingBill = useSelector(customerSelectors.customerUpcomingBill);
  const { paid, unpaid } = upcomingBill || {};

  const studentsBillingInfo = billingInfo?.filter(
    (billingDetail) => billingDetail.id === student?.id,
  );
  const studentsUpcomingFees = upcomingBill?.fees?.find(
    (f) => f.id === student?.id,
  );
  const chaburahPrice = (+studentsUpcomingFees?.own_price || 0) * -1;
  const passedCredit = +upcomingBill?.credit || 0;
  const addedCredit = +(formikBilling.values?.credit_add || 0);
  const credit = passedCredit + addedCredit;
  const multi = studentsUpcomingFees?.multi || 0;
  const dispatch = useDispatch();

  const [invoiceMulti, setInvoiceMulti] = useState<number | undefined>();
  useEffect(() => {
    (async () => {
      if (!student?.customer_id) {
        return;
      }

      const _invoiceMulti = (
        (await dispatch(
          customerActions.getCustomerBillingHistory(
            student?.customer_id,
            false,
          ),
        )) as any
      )
        ?.map((b) => {
          const multi = b.invoice_items?.find(
            (i) =>
              i.student_id === student.id &&
              i.description === "Multi Student Discount",
          );

          return b.month_id === student.month_join && multi ? multi : undefined;
        })
        ?.find((m) => !!m)?.amount;
      setInvoiceMulti(+_invoiceMulti);
    })();
  }, [dispatch, student?.customer_id, student?.id, student?.month_join]);

  let discount = 0;
  let discountReason = "Discount";
  let discountType = "amount";

  let studentsSubtotal = chaburahPrice;
  let _unpaid;

  if (paid) {
    studentsSubtotal = 0;
  } else if (
    unpaid &&
    Math.abs(unpaid) + (invoiceMulti || 0) < Math.abs(studentsSubtotal)
  ) {
    studentsSubtotal = Math.abs(unpaid);
    _unpaid = Math.abs(unpaid);
    if (!invoiceMulti) {
      studentsSubtotal -= multi;
    }
  } else {
    studentsSubtotal -= multi;
  }

  if (studentsUpcomingFees?.discount?.discount) {
    discount = studentsUpcomingFees?.discount?.discount;
    discountReason = studentsUpcomingFees?.discount?.reason;

    if (studentsUpcomingFees?.discount?.discount_type === "percentage") {
      studentsSubtotal -= studentsSubtotal * (discount / 100);
      discountType = "percentage";
    } else {
      studentsSubtotal = studentsSubtotal - discount;
    }
  } else if (formikBilling.values.discount) {
    discount = formikBilling.values.discount;

    if (formikBilling.values.discount_type === "percentage") {
      discountType = "percentage";
      studentsSubtotal -= studentsSubtotal * (discount / 100);
    } else {
      studentsSubtotal = studentsSubtotal - discount;
    }
  }
  if (studentsSubtotal < 0) {
    studentsSubtotal = 0;
  }
  const appliedCredit = studentsSubtotal > credit ? credit : studentsSubtotal;
  const studentsTotalDue = studentsSubtotal - appliedCredit;
  const remainingCredit = credit - appliedCredit;

  const customersTotalDue = upcomingBill?.fees?.reduce((prev, curr) => {
    return (
      prev +
      +curr.own_price * -1 -
      (curr.multi || 0) -
      (curr?.discount?.discount || 0)
    );
  }, 0);

  return {
    customersTotalDue,
    remainingCredit,
    appliedCredit,
    studentsTotalDue,
    chaburahPrice,
    credit,
    upcomingBill,
    studentsUpcomingFees,
    studentsBillingInfo,
    discount,
    discountReason,
    discountType,
    multi,
    unpaid: _unpaid,
    paid,
  };
}
