import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Transition } from "../../../components/Modals/Transition";
import { Form, Formik } from "formik";
import { DeleteIcon, FormikInput } from "../../../../../components";
import { Fields, initialValues, validationSchema } from "./schema";
import { useAddNeighborhood } from "./useAddNeighborhood";
import { btnSx, container, content } from "./AddNeighborhood.styles";
import { FormikSelect } from "../../../../../components/Select";
import { ModalData } from "../types";
import { SystemStyleObject } from "@mui/system";
import DeleteDialog from "../../../components/Modals/DeleteDialog";

export interface Props {
  modal: ModalData | null;
  onClose?: (data: ModalData | null) => void;
}

const AddNeighborhood: FC<Props> = ({ modal, onClose }) => {
  const {
    innerRef,
    open,
    neighborhoodPrincipals,
    handleSubmit,
    handleClose,
    handleDelete,
    handleCloseDeleteModal,
    deleteData,
    deleteFunction,
  } = useAddNeighborhood({
    modal,
    onClose,
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose?.(null)}
    >
      <DialogTitle>{`${
        modal?.mode === "insert" ? "Add a" : "Edit"
      } neighborhood`}</DialogTitle>

      <Formik<Fields>
        innerRef={innerRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isValid }) => {
          return (
            <Form>
              <DialogContent sx={content}>
                <Grid container sx={container}>
                  <Grid item xs={8}>
                    <FormikInput name="name" label="Neighborhood name" />
                  </Grid>

                  <Grid item xs={8}>
                    <FormikSelect
                      label="מנהל"
                      name="user_id"
                      options={neighborhoodPrincipals}
                    />
                  </Grid>
                </Grid>
                <Typography color="#082B65">
                  The timezone for this neighborhood will be set to EST (New
                  York). Contact Bitbean if you need to adjust the timezone.
                </Typography>
              </DialogContent>

              <Divider
                variant="middle"
                sx={{ border: "1px solid #DDE3EB", mb: 2 }}
              />

              <DialogActions>
                {modal?.mode === "edit" && (
                  <Button
                    variant="text"
                    sx={[
                      btnSx as SystemStyleObject,
                      { mr: "auto", color: "#F62527" },
                    ]}
                    onClick={handleDelete}
                  >
                    <DeleteIcon sx={{ width: "20px", mr: 1 }} />
                    delete
                  </Button>
                )}

                <Button
                  variant="outlined"
                  color="primary"
                  sx={btnSx}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color={"primary"}
                  sx={btnSx}
                  onClick={submitForm}
                  disabled={!isValid}
                >
                  {modal?.mode == "insert" ? "Add" : "Save"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>

      <DeleteDialog
        showModal={!!deleteData}
        name={"this"}
        subject={"neighborhood"}
        onClose={handleCloseDeleteModal}
        deleteFunction={deleteFunction}
      />
    </Dialog>
  );
};

export default AddNeighborhood;
