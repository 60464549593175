import React, { useCallback, useMemo, useState } from "react";
import { TabType } from "./types";
import TabNeighborhoods from "./TabNeighborhoods";
import TabMonths from "./TabMonths";

export function useSettingsPage() {
  const [tab, setTab] = useState<TabType>("neighborhoods");

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, value: TabType) => {
      event.stopPropagation();
      setTab(value);
    },
    [],
  );

  const TabComponent = useMemo(() => {
    switch (tab) {
      case "neighborhoods":
        return <TabNeighborhoods />;

      case "months":
        return <TabMonths />;
    }
  }, [tab]);

  return {
    tab,
    TabComponent,
    handleTabChange,
  };
}
