import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import useAddNewPaymentPage from "./useAddNewPaymentPage";
import SimplePageHeader from "../../../components/SimplePageHeader";
import {
  amountStyle,
  bottomSectionStyle,
  btnStyle,
  container,
  customerSx,
  discountTextStyle,
  formControl,
  gridItem,
  gridPayoffSx,
  gridPrepaymentSx,
  textField,
  topSectionStyle,
} from "./AddNewPaymentPage.styles";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DollarSignIcon,
  PaymentSavedCardBlock,
  PoundSignIcon,
  TodayIcon,
} from "../../../components";
import { SystemStyleObject, Theme } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { getButtonVariant } from "../../../lib";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { PAYOFF_COLUMNS, PREPAYMENT_COLUMNS } from "../../../constants";
import { MappedMode } from "./utils";
import Buttons from "./Buttons";
import Select from "../../../components/Select";
import { customerActions, customerSelectors, Prepay } from "../../../state";
import DeleteDialog from "../components/Modals/DeleteDialog";
import { useParams } from "react-router";
import { ApplyPaymentFooter } from "./ApplyPaymentFooter";
import { dataGridFilterStyles } from "../../../themes";

const AddNewPaymentPage: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    (async () => {
      if (params.id) {
        const id = +params.id;
        await dispatch(customerActions.getCustomer(id));
        dispatch(customerActions.getCustomerCardInfo(id));
      }
    })();
  }, [dispatch, params.id]);

  const payment_methods = useSelector(
    customerSelectors.customer_payment_methods,
  );
  const customerName = useSelector(customerSelectors.customerName);

  const {
    apiRef,
    pageMode,
    years,
    semesterTypes,
    handleBackButtonClick,
    handlePaymentType,
    handleDatePick,
    handleAmount,
    handlePaymentMethod,
    handleBtnClick,
    handleSelectionPayoffChange,
    selected,
    handleSelectionPrepaymentChange,
    formik,
    balanceItems,
    getDetailPanelHeight,
    getDetailPanelContent,
    deleteData,
    handleCloseDeleteModal,
    deleteFunction,
    chargedCard,
    cardType,
  } = useAddNewPaymentPage();

  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    formik;

  useEffect(() => {
    const defaultPmtMethod = payment_methods.find((p) => !!p.default);

    if (defaultPmtMethod) {
      setFieldValue("payment_method_id", defaultPmtMethod.payment_method_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_methods]);

  const CustomFooter = () => (
    <ApplyPaymentFooter
      amount={formik.values.amount}
      balanceItems={balanceItems}
    />
  );
  const isDisabled = pageMode === "edit" && values.paymentMethod === "credit";

  return (
    <Page>
      <TopSection style={topSectionStyle}>
        <SimplePageHeader
          title={`${MappedMode[pageMode]} a payment`}
          onBackButtonClick={handleBackButtonClick}
        />
      </TopSection>

      <BottomSection style={bottomSectionStyle}>
        {pageMode === "insert" && (
          <Typography variant={"h6"} sx={{ mt: 3, mb: 2, ml: 1 }}>
            Add a payment for - {customerName}
          </Typography>
        )}

        {pageMode === "edit" && (
          <TextField value={customerName} sx={customerSx} disabled={true} />
        )}

        <Stack sx={container}>
          <Box sx={amountStyle}>
            <Typography variant={"subtitle1"} className={"caption"}>
              Amount
            </Typography>

            <TextField
              name="amount"
              value={values.amount}
              onChange={handleAmount}
              onBlur={handleBlur}
              fullWidth={true}
              disabled={isDisabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {values.currency === "USD" && (
                      <DollarSignIcon
                        sx={{
                          opacity: isDisabled ? 0.5 : 1,
                        }}
                      />
                    )}

                    {values.currency === "GBP" && (
                      <PoundSignIcon
                        sx={{
                          opacity: isDisabled ? 0.5 : 1,
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              error={touched.amount && !!errors.amount}
              helperText={touched.amount && errors.amount}
            />
          </Box>

          <Typography variant={"h6"} sx={{ mt: 5, mb: 2 }}>
            Payment type
          </Typography>

          <Grid container direction="row" justifyContent="space-between">
            <Grid item sx={gridItem}>
              <ButtonGroup
                sx={btnStyle}
                disabled={
                  isDisabled ? values.paymentType === "prepayment" : false
                }
              >
                <Button
                  variant={getButtonVariant(values.paymentType === "regular")}
                  onClick={handlePaymentType.bind(null, "regular")}
                >
                  For months
                </Button>
                <Button
                  variant={getButtonVariant(values.paymentType === "other")}
                  onClick={handlePaymentType.bind(null, "other")}
                >
                  For other
                </Button>

                {/* <Button
                  variant={getButtonVariant(
                    values.paymentType === "prepayment",
                  )}
                  onClick={handlePaymentType.bind(null, "prepayment")}
                >
                  Prepayment
                </Button> */}
              </ButtonGroup>
            </Grid>

            <Grid
              item
              sx={[
                gridItem as SystemStyleObject<Theme>,
                formControl as SystemStyleObject<Theme>,
              ]}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disabled={isDisabled}
                  InputProps={{
                    sx: {
                      "& > .MuiInputBase-input": {
                        py: "12.5px",
                      },
                    },
                  }}
                  className={"datePicker"}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: TextFieldProps) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete={"off"}
                        fullWidth={true}
                        error={!!errors.date}
                        helperText={!!errors.date && errors.date}
                      />
                    );
                  }}
                  components={{
                    OpenPickerIcon: () => <TodayIcon />,
                  }}
                  onChange={handleDatePick}
                  value={values.date}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          {values.paymentType === "other" && (
            <>
              <Typography variant={"h6"} sx={{ mt: 5, mb: 2 }}>
                Payment for
              </Typography>

              <TextField
                sx={[textField as SystemStyleObject<Theme>]}
                name="payment_for"
                label="Payment for"
                value={values.payment_for}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.payment_for && !!errors.payment_for}
                helperText={touched.payment_for && errors.payment_for}
                disabled={isDisabled}
              />
            </>
          )}

          {values.paymentType === "prepayment" && (
            <>
              <Typography variant={"h6"} sx={{ mt: 5, mb: 2 }}>
                Payment for
              </Typography>

              <Grid container direction="row" justifyContent="flex-start">
                <Grid item>
                  <Select
                    name="yearId"
                    sx={[
                      textField as SystemStyleObject,
                      { width: "196px" } as SystemStyleObject,
                    ]}
                    width={"unset"}
                    label={"שנה"}
                    placeholder={"שנה"}
                    options={years}
                    value={values.yearId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.yearId && !!errors.yearId}
                    helperText={touched.yearId && errors.yearId}
                  />

                  <Select
                    name="semesterTypeId"
                    sx={[
                      textField as SystemStyleObject,
                      { width: "196px", ml: 4 } as SystemStyleObject,
                    ]}
                    width={"unset"}
                    label={"זמן"}
                    placeholder={"זמן"}
                    options={semesterTypes}
                    value={values.semesterTypeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.semesterTypeId && !!errors.semesterTypeId}
                    helperText={touched.semesterTypeId && errors.semesterTypeId}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {values.paymentType === "regular" && (
            <>
              <Typography variant={"h6"} sx={{ mt: 5, mb: 2 }}>
                Pay off months
              </Typography>
              <Grid container direction="row" justifyContent="space-between">
                <DataGridPro
                  sx={gridPayoffSx}
                  keepNonExistentRowsSelected
                  rowHeight={74}
                  disableSelectionOnClick={true}
                  checkboxSelection={true}
                  hideFooterSelectedRowCount={true}
                  hideFooterRowCount={true}
                  rows={balanceItems}
                  autoHeight={true}
                  columns={PAYOFF_COLUMNS}
                  onSelectionModelChange={handleSelectionPayoffChange}
                  selectionModel={selected}
                  components={{
                    Footer: CustomFooter,
                  }}
                  componentsProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                    panel: {
                      sx: {
                        ...dataGridFilterStyles,
                      },
                    },
                  }}
                />
              </Grid>
            </>
          )}

          <Typography variant={"h6"} sx={{ mt: 5, mb: 2 }}>
            Payment method
          </Typography>

          <Grid container direction="row" justifyContent="start">
            <Grid item xs={12}>
              <ButtonGroup
                sx={[
                  btnStyle as SystemStyleObject<Theme>,
                  { mb: 6 } as SystemStyleObject<Theme>,
                ]}
                disabled={pageMode === "edit"}
              >
                <Button
                  variant={getButtonVariant(values.paymentMethod === "cash")}
                  onClick={handlePaymentMethod.bind(null, "cash")}
                >
                  Cash
                </Button>

                <Button
                  variant={getButtonVariant(values.paymentMethod === "credit")}
                  onClick={handlePaymentMethod.bind(null, "credit")}
                >
                  Credit card
                </Button>
              </ButtonGroup>
            </Grid>
            {chargedCard && (
              <Box width="100%" mb={6}>
                <Box>
                  <Typography variant={"h6"} sx={{ mb: 2 }}>
                    Card charged
                  </Typography>
                </Box>
                <Box>
                  <Divider />
                  <Box padding="20px">
                    <span style={{ marginRight: "50px" }}>{cardType}</span>
                    <span>{chargedCard}</span>
                  </Box>
                  <Divider />
                </Box>
              </Box>
            )}

            <Grid item xs={12}>
              {values.paymentMethod === "cash" && (
                <TextField
                  sx={[
                    textField as SystemStyleObject<Theme>,
                    { width: "50%" } as SystemStyleObject<Theme>,
                  ]}
                  name="checkNumber"
                  label="Check number (optional)"
                  value={values.checkNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.checkNumber && !!errors.checkNumber}
                  helperText={touched.checkNumber && errors.checkNumber}
                  disabled={isDisabled}
                />
              )}

              {values.paymentMethod === "credit" && pageMode === "insert" && (
                <PaymentSavedCardBlock formik={formik} />
              )}
            </Grid>

            {values.paymentType === "prepayment" && (
              <Grid item xs={12} mb={6}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mb={2}
                >
                  <Typography variant={"h6"}>
                    Prepayment and discount
                  </Typography>

                  <Typography variant={"h6"} sx={discountTextStyle}>
                    {`Credit card prepayment  - ${
                      (values.paymentMethod === "cash"
                        ? values.prepayOptions?.discount?.prepay_discount_cash
                        : values.prepayOptions?.discount
                            ?.prepay_discount_credit) ?? 0
                    }% discount`}
                  </Typography>
                </Stack>

                <DataGridPro
                  apiRef={apiRef}
                  sx={gridPrepaymentSx}
                  getRowId={(row: Prepay) => {
                    return row.student.id ?? 0;
                  }}
                  checkboxSelection={true}
                  hideFooterSelectedRowCount={true}
                  hideFooterRowCount={true}
                  rows={values.prepayOptions?.prepay ?? []}
                  autoHeight={true}
                  columns={PREPAYMENT_COLUMNS}
                  onSelectionModelChange={handleSelectionPrepaymentChange}
                  componentsProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                    panel: {
                      sx: {
                        ...dataGridFilterStyles,
                      },
                    },
                  }}
                  getDetailPanelHeight={getDetailPanelHeight}
                  getDetailPanelContent={getDetailPanelContent}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                sx={[
                  textField as SystemStyleObject<Theme>,
                  { width: "100%" } as SystemStyleObject<Theme>,
                ]}
                rows={2}
                multiline={true}
                name="note"
                label="Note"
                fullWidth={true}
                value={values.note}
                onChange={handleChange}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </Stack>

        <Buttons
          pageMode={pageMode}
          handleBtnClick={handleBtnClick}
          paymentMethod={values.paymentMethod}
        />
      </BottomSection>

      <DeleteDialog
        showModal={!!deleteData}
        name={"this"}
        subject={"payment"}
        onClose={handleCloseDeleteModal}
        deleteFunction={deleteFunction}
      />
    </Page>
  );
};

export default AddNewPaymentPage;
