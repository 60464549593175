import React from "react";

import { Typography, Card, CardContent, Box } from "@mui/material";

export const AdminCards = ({ title, title2, caption }) => {
  return (
    <Card
      sx={{
        height: 84,
        width: 272,
        border: "1px solid #DDE3EB",
        borderRadius: "8px",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h6" sx={{ ml: 4 }}>
            {title2}
          </Typography>
        </Box>
        <Typography variant="subtitle2">{caption.toUpperCase()}</Typography>
      </CardContent>
    </Card>
  );
};
