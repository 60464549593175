import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors, Group } from "../../../state";
import React, { useEffect, useState } from "react";
import AdminPages from "../index";

export type TabType = "students" | "curriculum";

export function useGroupDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const groups = useSelector(adminSelectors.groups);

  const [group, setGroup] = useState<Group | null>(null);

  const [tab, setTab] = useState<TabType>("students");

  const handleTabChange = (event: React.SyntheticEvent, value: TabType) => {
    event.stopPropagation();
    setTab(value);
  };

  useEffect(() => {
    if (groups && params?.id) {
      const grp = groups.filter((g) => g.id.toString() === params.id);

      if (grp.length) {
        setGroup(grp[0]);
      } else {
        navigate(AdminPages.groups.path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, params.id]);

  useEffect(() => {
    // in case if we open the page directly - need to get all groups
    if (!groups) {
      dispatch(adminActions.getGroups());
    }
  }, [groups, dispatch]);

  return {
    tab,
    group,
    handleTabChange,
  };
}
