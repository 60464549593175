import React, { memo } from "react";
import { Routes, Route } from "react-router-dom";
import { renderPageRoutes } from "./lib";
import { AuthLayout, MainLayout, AdminLayout } from "./layouts";
import { MainPages } from "./pages/main";
import { AuthPages } from "./pages/auth";
import AdminPages from "./pages/admin";
import { HistoryRouter } from "./lib/routing/HistoryRouter";

export const App = memo(function App() {
  return (
    <HistoryRouter>
      <Routes>
        <Route path="/admin" element={<AdminLayout />}>
          {renderPageRoutes(AdminPages)}
        </Route>
        <Route path="/auth" element={<AuthLayout />}>
          {renderPageRoutes(AuthPages)}
        </Route>
        <Route path="/" element={<MainLayout />}>
          {renderPageRoutes(MainPages)}
        </Route>
      </Routes>
    </HistoryRouter>
  );
});
