import React, { ForwardedRef, forwardRef } from "react";
import { Checkbox, FormControlLabel, SxProps } from "@mui/material";
import { checkBox } from "./CheckBox.styles";
import { FormControlLabelProps } from "@mui/material/FormControlLabel/FormControlLabel";
import { CheckboxProps } from "@mui/material/Checkbox/Checkbox";
import { Theme } from "@mui/system";

interface Props extends Omit<FormControlLabelProps, "label" | "control"> {
  label?: any;
  labelSx?: SxProps<Theme>;
  error?: boolean;
}

const CheckBox = forwardRef(
  (
    {
      label = "",
      labelPlacement = undefined,
      labelSx = {},
      error,
      ...props
    }: Props & CheckboxProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ): JSX.Element => {
    if (error && labelSx) {
      labelSx = {
        ...labelSx,
        "& .MuiTypography-root": { color: "#bb2018 !important" },
      };
    }

    return (
      <FormControlLabel
        labelPlacement={labelPlacement}
        sx={{
          ...checkBox,
          ...(labelSx as any),
        }}
        control={
          <Checkbox
            ref={ref}
            {...props}
            sx={{
              ...(props.sx || {}),
              color: error ? "#bb2018" : (props?.sx as any)?.color,
            }}
          />
        }
        label={label}
      />
    );
  },
);

export default CheckBox;
