import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
export const ContainerStyle = styled(`div`)(() => ({
  // backgroundImage: `url(${pattern})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center right",
  backgroundSize: "auto 100%",
  display: "flex",
  flexDirection: "column",
  // padding: "40px 280px 40px 80px",
  position: "relative",
  // top: -80,
  // [theme.breakpoints.down("sm")]: {
  //   minWidth: "100%",
  // },
}));
export const FormStyle = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  margin: "40px 0px",
}));
export const TextFlexStyle = styled(`div`)(() => ({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  margin: "7px 0px 20px 0px",
}));
export const CenterElementStyle = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  margin: "16px 0px 0px 0px",
}));

export const useStyles: any = makeStyles(
  () => ({
    titleText: {
      fontSize: "4vh",
      fontWeight: 400,
      //color: theme.palette.primary.main,
    },
    subtitleText: {
      //color: theme.palette.primary.main,
      fontSize: "1.8vh",
      fontWeight: 400,
      marginBottom: "2vh",
    },
    errorText: {
      //color: theme.palette.error.main,
    },
    form: {},
    flexJustifySpace: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      // [theme.breakpoints.down("md")]: {
      //   flexDirection: "column",
      // },
    },
    buttonBox: {
      marginTop: "1.7vh",

      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      // [theme.breakpoints.down("md")]: {
      //   flexDirection: "column",
      // },
    },
    buttonBoxSingle: {
      justifyContent: "center",
    },
    forgotPassBox: {
      marginTop: 16,
      "& a": {
        //color: theme.palette.primary.main,
      },
    },
  }),
  {
    classNamePrefix: "AuthPage",
  },
);
