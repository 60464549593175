import React, { useState } from "react";
import { Typography, Grid, Divider, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { customerActions, listsSelectors } from "../../../state";
import { customerInitialState, customerValidationSchema } from "./utils";
import { FormikSelect } from "../../../components/Select";
import { capitalizeFirstLetterOfEachWOrd } from "../../../lib/utils";
import { FormikInput } from "../../../components";
import CustomerExistsModal from "./Modals/CustomerExistsModal";
import { AddressForm } from "./AddressForm";

export const AddCustomerFormCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countryCodes = useSelector(listsSelectors.optionsCountryCodes);
  const [data, setData] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors }: any,
  ) => {
    const formatValues = await {
      ...values,
      email: values.email || null,
      first_name: capitalizeFirstLetterOfEachWOrd(values.first_name) || null,
      last_name: capitalizeFirstLetterOfEachWOrd(values.last_name) || null,
      city: capitalizeFirstLetterOfEachWOrd(values.city) || null,
      home_phone: values.home_phone || null,
      mobile_phone: values.mobile_phone || null,
      address_2: values.address_2 || null,
      state: values.state || null,
      zip: values.zip.toUpperCase(),
      notes: values.notes?.trim() || null,
    };
    const submit: any = await dispatch(
      customerActions.createCustomer(formatValues),
    );
    if (submit.message === "Validation fails for Invalid home phone format") {
      setErrors({ home_phone: "Invalid format for the country selected." });
    } else if (
      submit.message === "Validation fails for Invalid cellphone format"
    ) {
      setErrors({ mobile_phone: "Invalid format for the country selected." });
    }

    setSubmitting(false);
  };

  const validatePhone = async (e) => {
    const validate: any = await dispatch(
      customerActions.getCustomersValidate(e.target.value),
    );
    if (validate?.id) {
      setData(validate);
      setShowModal(true);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={customerInitialState}
        validationSchema={customerValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
              Customer details
            </Typography>
            <Grid container spacing={4}>
              <Grid item md={3}>
                <FormikInput name="first_name_yiddish" label="ערשטע נאמען" />
              </Grid>
              <Grid item md={3}>
                <FormikInput name="last_name_yiddish" label="לעצטע נאמען" />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item md={3}>
                <FormikInput name="first_name" />
              </Grid>
              <Grid item md={3}>
                <FormikInput name="last_name" />
              </Grid>
            </Grid>
            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
              Contact
            </Typography>
            <Grid container spacing={4}>
              <Grid item md={3}>
                <Grid container>
                  <Grid item md={3}>
                    <FormikSelect
                      name="home_country_code_id"
                      label={"Code"}
                      options={countryCodes}
                    />
                  </Grid>
                  <Grid item md={9}>
                    <FormikInput name="home_phone" onBlur={validatePhone} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Grid container>
                  <Grid item md={3}>
                    <FormikSelect
                      name="mobile_country_code_id"
                      label={"Code"}
                      options={countryCodes}
                    />
                  </Grid>
                  <Grid item md={9}>
                    <FormikInput name="mobile_phone" onBlur={validatePhone} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item md={3}>
                <FormikInput name="email" />
              </Grid>
            </Grid>
            <AddressForm />

            <Grid container spacing={4}>
              <Grid item md={6}>
                <FormikInput name="notes" multiline />
              </Grid>
            </Grid>
            <Divider sx={{ m: 1.5 }} />
            <Button
              variant="contained"
              color="secondary"
              sx={{ mr: 1.5, minWidth: "180px" }}
              onClick={() => navigate("/admin/customer")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ m: 1.5, minWidth: "180px" }}
              type="submit"
            >
              Continue
            </Button>
            <CustomerExistsModal
              showModal={showModal}
              data={data}
              onClose={() => {
                setFieldValue("home_phone", "");
                setFieldValue("mobile_phone", "");
                setShowModal(false);
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
